import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IRawSequence, ISequence } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SequenceBuilder } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusCreateActionContext } from '../../action-create/context';
import { Task } from '../../action-create/types';
import { SetParametersType } from '../types';
import { mapTask } from '../utils/set-action-parameters';

export const useActionSetParameters = () => {
  const { setConditionList, setTaskList, setName, setIcon, setBuilder, setChannels } = useExalusCreateActionContext();
  const { scenesApi } = useExalusServicesContext();

  const getTasks = (seq: ISequence, channels: IDeviceChannel[]) => {
    const list: Task[] = [];

    seq.Tasks.ControllerTasks.forEach((task) => {
      const mappedTask = mapTask(task, channels);
      if (mappedTask) list.push(mappedTask);
    });

    if (list.length) setTaskList(list);
  };

  const setActionParameters = <T,>(setParametersType: SetParametersType, channels: IDeviceChannel[], data: T) => {
    setChannels([]);
    setConditionList([]);
    setTaskList([]);

    if (!data) return;

    const conditions: ISequence[] = [];
    let b: SequenceBuilder;

    if (setParametersType === SetParametersType.Edit) {
      b = scenesApi.GetSequenceBuilder();
      b.EditCurrentSequence(data as unknown as IRawSequence);
    } else {
      b = data as unknown as SequenceBuilder;
    }

    setBuilder(b);
    let seq = b.GetNext();
    if (seq) {
      getTasks(seq, channels);
      setName(seq.Name);
      setIcon(seq.Icon);
      conditions.push(seq);
    }

    while (seq !== null) {
      seq = b.GetNext();
      if (seq) {
        conditions.push(seq);
        getTasks(seq, channels);
      }
    }

    setConditionList(conditions);
  };

  return {
    setActionParameters,
  };
};
