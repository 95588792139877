import { useTranslation } from 'react-i18next';
import { ActionError, ActionErrorDictionary } from '../../data-access/gql-types/graphql';
import { toastError } from '../../utils/toast';

export const useActionErrors = () => {
  const { t: tr } = useTranslation('errors');

  const handleErrors = (errors: ActionError[]) => {
    if (errors.length > 0) {
      const errorItem = errors[0];

      switch (errorItem.error) {
        case ActionErrorDictionary.Undefined: {
          toastError({
            content: tr(`ActionErrorDictionary.${ActionErrorDictionary.Undefined}`),
          });
          break;
        }
        case ActionErrorDictionary.UserDoesNotHavePermission: {
          toastError({
            content: tr(`ActionErrorDictionary.${ActionErrorDictionary.UserDoesNotHavePermission}`),
          });
          break;
        }
        case ActionErrorDictionary.ActionDoesNotExist: {
          toastError({
            content: tr(`ActionErrorDictionary.${ActionErrorDictionary.ActionDoesNotExist}`),
          });
          break;
        }
        case ActionErrorDictionary.CannotRemoveCentralAction: {
          toastError({
            content: tr(`ActionErrorDictionary.${ActionErrorDictionary.CannotRemoveCentralAction}`),
          });
          break;
        }
        case ActionErrorDictionary.InvalidActionParameters: {
          toastError({
            content: tr(`ActionErrorDictionary.${ActionErrorDictionary.InvalidActionParameters}`),
          });
          break;
        }
        default: {
          toastError({ content: errorItem.message });
          break;
        }
      }
    }
  };

  return {
    handleErrors,
  };
};
