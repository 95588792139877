import React from 'react';
import { ControlWrapper, OnOffControl } from '../../../components';
import { LightToggleValueInternal } from '../../../data-access/gql-types/graphql';
import { useLightAction } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { ActionTimesActivations } from '../components/action-times-activation';

interface PropsInterface {
  channels: ChannelInterface[];
}
const LightAction: React.FC<PropsInterface> = ({ channels }) => {
  const { handleControlsOn } = useLightAction(channels);

  return (
    <>
      <ControlWrapper>
        <OnOffControl
          actionOn={() => handleControlsOn(LightToggleValueInternal.On)}
          actionOff={() => handleControlsOn(LightToggleValueInternal.Off)}
        />
      </ControlWrapper>
      <ActionTimesActivations channels={channels} />
    </>
  );
};

export default LightAction;
