export const hsbToRgb = ([h, s, v]: number[]): number[] => {
  // s and v are numbers in range from 0 to 1
  const f = (n: number, k = (n + h / 60) % 6) => Math.round((v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)) * 255);
  return [f(5), f(3), f(1)];
};

export const rgbToHsb = (values: string[]): number[] => {
  const r = Number(values[0]) / 255;
  const g = Number(values[1]) / 255;
  const b = Number(values[2]) / 255;

  const v = Math.max(r, g, b),
    n = v - Math.min(r, g, b);
  const h = n === 0 ? 0 : n && v === r ? (g - b) / n : v === g ? 2 + (b - r) / n : 4 + (r - g) / n;
  return [60 * (h < 0 ? h + 6 : h), v && (n / v) * 100, v * 100];
};

export const hexToRgb = (hex: string): string[] | [] => {
  const hexValue = hex.replace('#', '');
  const matched = hexValue.match(/.{1,2}/g);
  return matched
    ? [String(parseInt(matched[0], 16)), String(parseInt(matched[1], 16)), String(parseInt(matched[2], 16))]
    : [];
};
