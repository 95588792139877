import React, { useEffect } from 'react';
import { hideSplashScreen } from '../../utils/helpers/splashscreen';

export const SplashScreen: React.FC = () => {
  useEffect(() => {
    return () => {
      hideSplashScreen();
    };
  }, []);

  return <div className="splash-screen" />;
};
