import React, { useMemo } from 'react';
import { DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import ChannelControls from '../controls';
import { RecoveryInfoDetails } from '../recovery';
import { RecoveryModeWrapper } from '../recovery/wrapper';

interface PropsInterface {
  channel: ChannelInterface;
}

const DetailsBody: React.FC<PropsInterface> = ({ channel }) => {
  const { channelState } = useChannelsState();

  const recoveryActive = useMemo(
    () => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
    [channelState[channel.id]?.deviceConnectionState],
  );

  return (
    <>
      <RecoveryInfoDetails channel={channel} />
      <RecoveryModeWrapper active={recoveryActive}>
        <ChannelControls channel={channel} />
      </RecoveryModeWrapper>
    </>
  );
};

export default DetailsBody;
