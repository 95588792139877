import React, { useMemo } from 'react';
import { availableActionAdvancedIcons } from '../edit-icon/available-icons';
import { getIcon } from '../../../modules/exalus/utils/icons';

interface PropsInterface {
  iconName: string;
}

export const ActionAdvancedIcon: React.FC<PropsInterface> = ({ iconName }) => {
  const AdvancedIcon = useMemo(() => {
    const icon = getIcon(availableActionAdvancedIcons, iconName);
    if (icon) return icon.component;

    return availableActionAdvancedIcons[0].component;
  }, [iconName]);

  return <>{AdvancedIcon}</>;
};
