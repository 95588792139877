import { ChannelType, GateType, LightType, SensorType } from '../../../../enums';
import {
  switchIcons,
  blindIcons,
  facadeIcons,
  cameraIcons,
  entryPhoneIcons,
  meterIcons,
  gateIcons,
  remoteIcons,
  unknownIcons,
  dimmerIcons,
  rgbIcons,
  windIcons,
  movementIcons,
  floodIcons,
  humidityIcons,
  reedIcons,
  pressureIcons,
  measuredBrightnessIcons,
  temperatureIcons,
} from './icons';
import { gatePreciseIcons } from './icons/gate-precise';

export const availableIcons = {
  [ChannelType.Switch]: switchIcons(),
  [ChannelType.Blind]: blindIcons(),
  [ChannelType.Facade]: facadeIcons(),
  [ChannelType.Camera]: cameraIcons(),
  [ChannelType.EntryPhone]: entryPhoneIcons(),
  [ChannelType.Meter]: meterIcons(),
  [GateType.Gate]: gateIcons(),
  [GateType.GatePrecise]: gatePreciseIcons(),
  [ChannelType.Remote]: remoteIcons(),
  [ChannelType.Unknown]: unknownIcons(),
  [LightType.Dimmer]: dimmerIcons(),
  [LightType.Rgb]: rgbIcons(),
  [SensorType.Temperature]: temperatureIcons(),
  [SensorType.Wind]: windIcons(),
  [SensorType.Movement]: movementIcons(),
  [SensorType.Flood]: floodIcons(),
  [SensorType.Humidity]: humidityIcons(),
  [SensorType.MeasuredBrightness]: measuredBrightnessIcons(),
  [SensorType.Reed]: reedIcons(),
  [SensorType.Pressure]: pressureIcons(),
};
