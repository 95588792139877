import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getParameterByName } from '../../../../../../../utils/location';
import './index.scss';
import AutoCalibrationBlind0 from './step0';
import AutoCalibrationBlind1 from './step1';
import AutoCalibrationBlind2 from './step2';
import AutoCalibrationBlindSuccess from './success';

const BlindAutoCalibration: React.FC = () => {
  const location = useLocation();
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    const step = getParameterByName('step');
    if (step) setStep(parseInt(step));
    else setStep(0);
  }, [location.search]);

  const renderCurrentView = () => {
    switch (step) {
      case 0:
        return <AutoCalibrationBlind0 setStep={setStep} />;
      case 1:
        return <AutoCalibrationBlind1 setStep={setStep} />;
      case 2:
        return <AutoCalibrationBlind2 setStep={setStep} />;
      case 3:
        return <AutoCalibrationBlindSuccess />;
      default:
        return <AutoCalibrationBlind0 setStep={setStep} />;
    }
  };

  return <>{renderCurrentView()}</>;
};

export default BlindAutoCalibration;
