import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { DeviceTasksInfo } from 'lavva.exalushome/build/js/Services/Devices/IDevice';

interface ExalusDevicesProviderInterface {
  deviceTasks: DeviceTasksInfo[];
  setDevicesTasks: Dispatch<SetStateAction<DeviceTasksInfo[]>>;
}

const initialState: ExalusDevicesProviderInterface = {
  deviceTasks: [],
  setDevicesTasks: () => null,
};

export const ExalusDevicesContext = createContext<ExalusDevicesProviderInterface>(initialState);

export const useExalusDevicesContext = (): ExalusDevicesProviderInterface => useContext(ExalusDevicesContext);

export const ExalusDevicesContextProvider: React.FC = ({ children }) => {
  const [deviceTasks, setDevicesTasks] = useState<DeviceTasksInfo[]>([]);

  const values: ExalusDevicesProviderInterface = {
    deviceTasks,
    setDevicesTasks,
  };

  return <ExalusDevicesContext.Provider value={values}>{children}</ExalusDevicesContext.Provider>;
};
