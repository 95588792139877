import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../components';
import { environment } from '../../environment';
import SupportContent from './content';

const SupportPage: React.FC = () => {
  const { t } = useTranslation('support');

  return (
    <Page
      header={
        <>
          <NavHeader />
          <Header title={t('supportHeader')} isUnderline />
        </>
      }
    >
      <SupportContent type={environment.INTEGRATION_DEFAULT} image />
    </Page>
  );
};

export default SupportPage;
