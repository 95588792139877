import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconSquareMeter: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-square-meter"
      width="29"
      height="34"
      viewBox="0 0 29 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.33398"
        y="1.71429"
        width="26.9167"
        height="30.7619"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="15.2007"
        cy="19.6132"
        r="1.89988"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {isOn ? (
        <path
          d="M16.9918 18.6113L21.334 15.8652"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M13.3001 18.6634L9.02539 15.8135"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <path
        d="M5.17889 17.6108C5.17889 17.1265 5.21513 16.6507 5.28503 16.186M24.1777 17.6108C24.1777 13.5176 21.5889 10.029 17.9592 8.69319M10.8785 8.90181C11.4818 8.63826 12.1176 8.43553 12.7784 8.3014C13.242 8.2073 13.7179 8.14695 14.2033 8.12305M7.7246 11.1389C7.36423 11.5259 7.03579 11.9431 6.74347 12.3861C6.62072 12.5722 6.50433 12.7628 6.39463 12.9577"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
