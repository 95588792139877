import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DialogConfirmation, SubmitButton } from '../../../components';
import { InvitationStatus } from '../../../data-access/gql-types/graphql';
import { toastSuccess } from '../../../utils/toast';
import { useInstallationShare } from '../hooks/use-installation-share';
import SummaryShareInvitation from './components/summary';
import './index.scss';

const InvitationSummary: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { id, status } = useParams<{ id?: string; status?: string }>();
  const { methods, cancelInvLoading } = useInstallationShare();
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  const cancelInvitation = () => {
    if (id) {
      methods.cancel(id, () => {
        setDeleteDialog(false);
        toastSuccess({ content: t('share_path.cancel_invitation_success') })
        history.goBack();
      });
    }
  };

  const openDeleteDialog = () => setDeleteDialog(true);

  return (
    <SummaryShareInvitation>
      <>
        {status === InvitationStatus.Pending && (
          <SubmitButton type="button" onClick={openDeleteDialog} tranparent className="button--secondary">
            {t('share_path.cancel_invitation')}
          </SubmitButton>
        )}
        <DialogConfirmation
          show={deleteDialog}
          setShow={setDeleteDialog}
          primaryBtnDisabled={cancelInvLoading}
          header={t('share_path.cancel_invitation_confirm.header')}
          content={t('share_path.cancel_invitation_confirm.content')}
          primaryBtnText={!cancelInvLoading ? t('share_path.cancel') : tc('buttons.loading')}
          secondaryBtnText={tc('buttons.cancel')}
          primaryBtnAction={cancelInvitation}
          secondaryBtnAction={() => setDeleteDialog(false)}
        />
      </>
    </SummaryShareInvitation>
  );
};

export default InvitationSummary;
