import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconRepeat } from '../../../../components';
import { TriggerTimeConditionResponse } from '../../../../data-access/gql-types/graphql';

interface PropsInterface {
  timeCondition: TriggerTimeConditionResponse;
}

export const TriggerRemainingExecutionCount: React.FC<PropsInterface> = ({ timeCondition }) => {
  const { t } = useTranslation('action');

  const isVisible = useMemo(() => {
    return (
      typeof timeCondition?.targetExecutionCount === 'number' &&
      typeof timeCondition?.currentExecutionCount === 'number'
    );
  }, [timeCondition]);

  const remainingExecutionCount = useMemo(() => {
    if (isVisible) return (timeCondition?.targetExecutionCount || 0) - (timeCondition?.currentExecutionCount || 0);
  }, [timeCondition]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="trigger-details__execution--remaining">
      <IconRepeat />
      <p>
        {t('trigger.remainingExecutionCount')}: {remainingExecutionCount}
      </p>
    </div>
  );
};
