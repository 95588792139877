import React from 'react';

export const IconGoogle: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.102051" y="-0.00012207" width="31.7959" height="32.0001" rx="4" fill="white" />
    <path
      d="M16.1021 14.2399V18.1126H21.5937C21.3526 19.3581 20.6289 20.4127 19.5436 21.1218L22.8553 23.64C24.7848 21.8946 25.898 19.3309 25.898 16.2854C25.898 15.5764 25.833 14.8945 25.7124 14.24L16.1021 14.2399Z"
      fill="#4285F4"
    />
    <path
      d="M10.5875 17.9032L9.84062 18.4635L7.19678 20.4817C8.87582 23.7453 12.3171 25.9999 16.306 25.9999C19.061 25.9999 21.3709 25.109 23.0592 23.5817L19.7475 21.0635C18.8384 21.6635 17.6789 22.0272 16.306 22.0272C13.6529 22.0272 11.3988 20.2726 10.5917 17.909L10.5875 17.9032Z"
      fill="#34A853"
    />
    <path
      d="M7.1966 11.5179C6.5009 12.8634 6.10205 14.3816 6.10205 15.9997C6.10205 17.6179 6.5009 19.1361 7.1966 20.4815C7.1966 20.4905 10.5919 17.8997 10.5919 17.8997C10.3878 17.2997 10.2671 16.6634 10.2671 15.9996C10.2671 15.3359 10.3878 14.6996 10.5919 14.0996L7.1966 11.5179Z"
      fill="#FBBC05"
    />
    <path
      d="M16.3062 9.98159C17.809 9.98159 19.1448 10.4907 20.2116 11.4725L23.1337 8.6089C21.3619 6.99073 19.0614 5.99976 16.3062 5.99976C12.3174 5.99976 8.87582 8.24522 7.19678 11.518L10.5919 14.0998C11.3989 11.7361 13.6531 9.98159 16.3062 9.98159Z"
      fill="#EA4335"
    />
  </svg>
);
