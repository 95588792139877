import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusScreen } from '../../components';
import { ROUTES } from '../../routes';
import { StatusTypeEnum } from '../../types';
import './index.scss';

const Migration: React.FC = () => {
  const { t } = useTranslation('profile');

  const handleCreateAccount = () => {
    localStorage.removeItem('local-app');
    window.location.href = `${ROUTES.Root()}?noMigPage=true`;
  };

  const handleMaybeLater = () => {
    localStorage.setItem('local-app', 'true');
    window.location.href = ROUTES.Installation();
  };

  return (
    <StatusScreen
      header={t('exalus.migration.header')}
      subTitle={t('exalus.migration.content')}
      type={StatusTypeEnum.MIGRATION}
      primaryBtnText={t('exalus.migration.createAccount')}
      onPrimaryBtnClick={handleCreateAccount}
      secondaryBtnText={t('exalus.migration.maybeLater')}
      onSecondaryBtnClick={handleMaybeLater}
      secondaryBackground
      noBackButton
    />
  );
};

export default Migration;
