import React from 'react';

export const IconInstallation1: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M5.5 19.4165C5.5 18.3119 6.39543 17.4165 7.5 17.4165L18.1667 17.4165L18.1667 34.8332H7.5C6.39543 34.8332 5.5 33.9377 5.5 32.8332L5.5 19.4165Z"
      stroke="#15141D"
    />
    <path
      d="M18.167 5.1665C18.167 4.06193 19.0624 3.1665 20.167 3.1665L32.0003 3.1665C33.1049 3.1665 34.0003 4.06193 34.0003 5.1665L34.0003 32.8332C34.0003 33.9377 33.1049 34.8332 32.0003 34.8332H18.167L18.167 5.1665Z"
      stroke="#15141D"
    />
    <path d="M11.8335 23.75V28.5" stroke="#15141D" />
    <path d="M26.083 9.5V14.25" stroke="#15141D" />
    <path d="M26.083 23.75V28.5" stroke="#15141D" />
  </svg>
);
