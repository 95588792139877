import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { StatusTypeEnum } from '../../types';
import { NavHeader } from '../nav-header';
import { Page } from '../page';
import { StatusIcon } from '../status-icon';
import './index.scss';

interface PropsInterface {
  header: string;
  primaryBtnText: string;
  secondaryBtnText?: string;
  type: StatusTypeEnum;
  onPrimaryBtnClick: () => void;
  note?: string;
  secondaryBackground?: boolean;
  subTitle?: string;
  onSecondaryBtnClick?: () => void;
  primaryLoading?: boolean;
  noBackButton?: boolean;
}

export const StatusScreen: React.FC<PropsInterface> = ({
  header,
  subTitle,
  primaryBtnText,
  secondaryBtnText,
  note,
  type,
  secondaryBackground,
  primaryLoading,
  onSecondaryBtnClick,
  onPrimaryBtnClick,
  noBackButton,
}) => {
  const { t } = useTranslation('common');

  return (
    <Page
      className={classNames('info-status-screen__page', {
        'info-status-screen--secondary-background__page': secondaryBackground,
      })}
      {...(!noBackButton ? { header: <NavHeader noBackButton={noBackButton} /> } : {})}
    >
      <div className="info-status-screen">
        <div className="info-status-screen__content">
          <StatusIcon type={type} />
          <h2 className="info-status-screen__title">{header}</h2>
          <h3 className="info-status-screen__subtitle">{subTitle}</h3>
        </div>
        <div className="info-status-screen__buttons">
          <button className="button" onClick={onPrimaryBtnClick} disabled={primaryLoading}>
            {!primaryLoading ? primaryBtnText : t('buttons.loading')}
          </button>
          {secondaryBtnText ? (
            <button className="button button--secondary" onClick={onSecondaryBtnClick}>
              {secondaryBtnText}
            </button>
          ) : (
            <div className="info-status-screen__empty-block" />
          )}
          {note && <p className="info-status-screen__note">{note}</p>}
        </div>
      </div>
    </Page>
  );
};
