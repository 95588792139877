import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconPadlock } from '../../../../../../../components';
import { useReedControl } from '../../../hooks/use-reed-control';
import './index.scss';

interface ComponentProps {
  channel: IDeviceChannel;
  details?: boolean;
}

const ReedControl: React.FC<ComponentProps> = ({ channel, details = false }) => {
  const { t } = useTranslation('common');
  const { reed } = useReedControl(channel);

  if (reed === null) return null;

  return (
    <div
      className={classNames('reed-control', {
        'reed-control--details': details,
      })}
    >
      <IconPadlock isOn={!reed} />
      <span>{!reed ? t('opened') : t('closed')}</span>
    </div>
  );
};

export default ReedControl;
