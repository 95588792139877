import { IconSignalState } from '../../../../../../../components/icons';
import { AvailableIcon } from '../../../../../../../types';

export const remoteIcons = (signal?: number): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconSignalState signal={signal !== undefined ? signal : 100} />,
    oldIconName: 'DEVICE_ICON_36',
  },
];
