import React from 'react';

export const IconLaundry: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-laundry"
  >
    <path
      d="M33.0127 16.4187L29.6492 30.39H9.28932L5.92578 16.4187"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 16C4 14.3431 4.61371 13 5.37076 13H33.6292C34.3863 13 35 14.3431 35 16"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.11523 16.165H34.8233" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.751 20H11.5144C11.2303 20 11 20.2303 11 20.5144V21.9946C11 22.2787 11.2303 22.509 11.5144 22.509H14.751C15.0351 22.509 15.2654 22.2787 15.2654 21.9946V20.5144C15.2654 20.2303 15.0351 20 14.751 20Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0166 20H17.78C17.4959 20 17.2656 20.2303 17.2656 20.5144V21.9946C17.2656 22.2787 17.4959 22.509 17.78 22.509H21.0166C21.3007 22.509 21.531 22.2787 21.531 21.9946V20.5144C21.531 20.2303 21.3007 20 21.0166 20Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.2822 20H24.0457C23.7616 20 23.5312 20.2303 23.5312 20.5144V21.9946C23.5312 22.2787 23.7616 22.509 24.0457 22.509H27.2822C27.5663 22.509 27.7967 22.2787 27.7967 21.9946V20.5144C27.7967 20.2303 27.5663 20 27.2822 20Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2654 26.9946C15.2654 27.2788 15.0352 27.509 14.751 27.509H11.8362C11.552 27.509 11.3218 27.2788 11.3218 26.9946L11 25.5144C11 25.2302 11.2302 25 11.5144 25H14.751C15.0352 25 15.2654 25.2302 15.2654 25.5144V26.9946Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0166 25H17.78C17.4959 25 17.2656 25.2303 17.2656 25.5144V26.9946C17.2656 27.2787 17.4959 27.509 17.78 27.509H21.0166C21.3007 27.509 21.531 27.2787 21.531 26.9946V25.5144C21.531 25.2303 21.3007 25 21.0166 25Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.4427 26.9946C27.4427 27.2788 27.2125 27.509 26.9283 27.509H24.0457C23.7614 27.509 23.5312 27.2788 23.5312 26.9946V25.5144C23.5312 25.2302 23.7614 25 24.0457 25H27.2822C27.5665 25 27.7967 25.2302 27.7967 25.5144L27.4427 26.9946Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12.8813C7 9.63344 9.23871 7 12 7C14.7613 7 17 9.63344 17 12.8813C17 12.9207 17 12.9607 16.9992 13"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 8C21.5 6.49998 27.501 9.85517 27.501 12"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 8C28 5.50002 32.9994 8.68584 32.9994 12"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
