export enum DeviceModel {
  NXBIDI = 'c708827f-2716-4d44-94a9-25d3fe20634e',
  PXBIDI = 'b14de46c-c9a3-4554-8b38-823b96d8deb8',
  RCK21 = 'becbb229-1637-4b0f-a9f0-d43fa38ef76b',
  RCT22 = 'e1a613db-84ee-4c8c-ab2c-10ecf68970b2',
}
// VB-BIDI 42015ac8-4a84-47b2-855d-795ee8edc06f
// ROB-21 7b794533-a040-4637-90d2-17b410a16efa
// ROP-21 e3fbfa59-2e57-465d-a46c-44b5ce30e475
// ROP-22 2520103f-5622-4015-be85-e6dea8640829
// SLR-22 13538389-988f-4327-93d3-518e757c8cc9
// RNM-24 d8fea502-541e-42d9-8ece-a1bcfaaa93f9
// ROM-22 1f599666-be23-49c1-b42d-17a8db54e11a
// ROM-24 554f0414-df02-4525-94e9-47f36015c5d6
// RDP-21 db54c107-b406-4c99-8cfa-0455c9089d44
// SRP-22 d57de594-768b-4745-97bd-56b70b8be253
// EX-BIDI d1b4cfc7-e92c-47d1-9c7c-9c9dd3351832
// SSR-01 84c1bb42-1a27-46bb-ae1e-14a790e96a53
// SSR-02 e7e6b193-0144-4550-93f0-9050bbbaf91d
// RNK-22P 90e73979-f0eb-40af-9879-5a73b65e2532
// PXZF-BIDI b786d88e-ef61-4a32-ae71-aaa4ebd8c83f
// NXZF-BIDI f81ed618-df63-4e98-b4aa-1250dffd45e4
// RNK-22T a1cddc2c-5627-4eb2-8464-6e25b68f9754
// RNK-24P d19efeee-4b76-47d2-8dc9-6f6a1b810ecb
// RNK-24T 1769d9c3-1040-47f5-9b33-2ac2983bfde9
// RCT-21 785997bf-f89f-4a54-8a58-ce30ca229e6b
// P-457/2 1d69f5c5-1a1b-47c6-adb7-1349334c3c2f
// P-457/4 31e4e5f6-f727-4249-b601-d5ba7ca75239
// RNP-21 afea26ac-d5de-4aa9-8e74-ff69679d097b
// RNP-22 908a71ec-ca46-4431-9504-05b7801e77f8
// P-456/8 a789447a-e6c5-48f6-8414-c1f9555c22ec
// P-456/36 a2adc7e0-00c6-4b67-9608-6f75f368f5e8
// MEM-21 21b42a19-91fb-47dd-847a-26e895bfa21d
// ROG-21 1502d701-2433-4bdb-911e-b0976a995398
// RCM-21 d7f88fcb-19f7-469b-b489-a239762023e5
// RCR-21 4688be64-c07c-4508-8e7a-b49bc4bbc78e
// Rop-27 d4638681-2fac-4f3c-a520-281573e431fa
// REP-21 952ddc14-76c4-4f47-b0bc-5d106c1f9b02
// WSZF-BIDI f48eab61-047a-4241-a1e6-aee89062cd69
