import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import {
  Coordinates,
  CoordinatesWithSourceInfo,
  SetGeolocationResponseCode,
} from 'lavva.exalushome/build/js/Services/Controller/IGeolocationService';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { useBackdropContext, useInstallation } from '../../../hooks';
import { toastError } from '../../../utils/toast';
import { useExalusContext } from '../context';
import { useExalusServicesContext } from '../context/services';

export const useExalusSetGeolocation = (): UseMutationResult<
  ResponseResult<SetGeolocationResponseCode>,
  unknown,
  Coordinates
> => {
  const { geolocationApi } = useExalusServicesContext();
  const { turnOffBackdrop } = useBackdropContext();

  return useMutation<ResponseResult<SetGeolocationResponseCode>, unknown, Coordinates>(
    ['exalus-set-geolocation'],
    async (body) => {
      return await geolocationApi.SetControllerGeolocationAsync(body);
    },
    {
      onError: () => turnOffBackdrop(),
    },
  );
};

export const useExalusGeolocation = (): UseQueryResult<Status | CoordinatesWithSourceInfo> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { geolocationApi } = useExalusServicesContext();
  const { integrationType } = useInstallation();

  return useQuery<Status | CoordinatesWithSourceInfo>(
    ['exalus-get-geolocation', selectedExalusCredentials?.id, synchronized],
    async () => {
      return await geolocationApi.GetControlllerGeolocationAsync();
    },
    {
      enabled:
        connected && !!selectedExalusCredentials?.id && synchronized && integrationType === IntegrationType.Exalus,
      retry: false,
    },
  );
};

export const useHandleGeolocationError = () => {
  const { t } = useTranslation('common');
  const { turnOffBackdrop } = useBackdropContext();

  const handleGeolocationError = (result: SetGeolocationResponseCode) => {
    turnOffBackdrop();

    switch (result) {
      case SetGeolocationResponseCode.UnknownError: {
        toastError({ content: t('exalus.responseStatus.unknownError') });
        break;
      }
      case SetGeolocationResponseCode.NoPermissions: {
        toastError({ content: t('exalus.responseStatus.noPermissionsToCallGivenResource') });
        break;
      }
      case SetGeolocationResponseCode.UserIsNotLoggedIn: {
        toastError({ content: t('exalus.responseStatus.userIsNotLoggedIn') });
        break;
      }
      default: {
        toastError({ content: t('errors.somethingWentWrong') });
        break;
      }
    }
  };

  return {
    handleGeolocationError,
  };
};
