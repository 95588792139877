import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetTariffs } from '../../../../../../api/modules/optimizer/optimizer.hooks';
import { InputSelect, SelectOptionInterface } from '../../../../../../components';
import HeaderWithHelp from '../../../../../../components/header-with-help';
import { ChannelTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { leadingZero } from '../../../../../../utils/helpers';
import { HoursRangeFront, TariffBody, TariffFormDefault } from '../../types';
import { TariffName, TariffOperator } from '../../types/tariff-kind';
import { CheckboxController } from '../checkbox-controller';
import { TariffHours } from '../tariff-hours';
import { TariffHelp } from './tariff-help';

interface ComponentProps {
  type: ChannelTypeInternal;
  customTariff?: boolean;
  dynamicTariff?: boolean;
}

export const TariffForm: React.FC<ComponentProps> = ({ type, customTariff = true, dynamicTariff = true }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const [defaultOperator, setDefaultOperator] = useState<string>('');
  const [defaultTariffName, setDefaultTariffName] = useState<string>('');
  const [defaultTariffParams, setDefaultTariffParams] = useState<TariffFormDefault>({
    hoursRanges: [],
    wholeWeekendCheaper: false,
    wholeHolidaysCheaper: false,
    ignoreSummerTimeChanges: false,
  });
  const { control, watch, setValue } = useFormContext<TariffBody>();
  const { data: allTariffs } = useGetTariffs(type);
  const { operator, tariffName, hoursRanges } = watch();

  useEffect(() => {
    if (allTariffs?.length) {
      const foundOperator = allTariffs?.find((x) => x.operator.toLowerCase() === operator);

      if (foundOperator) {
        const foundTariff = foundOperator.tariffs.find((x) => x.name.toLowerCase() === tariffName);
        if (foundTariff) {
          const hours = foundTariff.hoursRanges.map((x) => ({
            ...x,
            from: `${leadingZero(x.from)}:00`,
            to: `${leadingZero(x.to)}:00`,
          }));
          const wholeWeekendCheaper = !!foundTariff.wholeWeekendCheaper;
          const wholeHolidaysCheaper = !!foundTariff.wholeHolidaysCheaper;
          const ignoreSummerTimeChanges = !!foundTariff.ignoreSummerTimeChanges;

          setValue('hoursRanges', hours);
          setValue('wholeWeekendCheaper', wholeWeekendCheaper);
          setValue('wholeHolidaysCheaper', wholeHolidaysCheaper);
          setValue('ignoreSummerTimeChanges', ignoreSummerTimeChanges);
          setDefaultTariffName(tariffName);
          setDefaultOperator(operator);
          setDefaultTariffParams({
            hoursRanges: hours,
            wholeWeekendCheaper,
            wholeHolidaysCheaper,
            ignoreSummerTimeChanges,
          });
        } else {
          setValue('hoursRanges', []);
          setValue('wholeWeekendCheaper', false);
          setValue('wholeHolidaysCheaper', false);
          setValue('ignoreSummerTimeChanges', false);
        }
      }
    }
  }, [operator, tariffName, allTariffs]);

  const handleWatchForCustom = (values: TariffBody) => {
    if (defaultOperator && defaultTariffName) {
      const toCompare = {
        hoursRanges: values.hoursRanges,
        wholeWeekendCheaper: values.wholeWeekendCheaper,
        wholeHolidaysCheaper: values.wholeHolidaysCheaper,
        ignoreSummerTimeChanges: values.ignoreSummerTimeChanges,
      };

      if (JSON.stringify(toCompare) !== JSON.stringify(defaultTariffParams)) {
        setValue('operator', TariffOperator.Custom);
        setValue('tariffName', TariffName.Custom);
      } else {
        setValue('operator', defaultOperator);
        setValue('tariffName', defaultTariffName);
      }
    }
  };

  const operators: SelectOptionInterface<string>[] = useMemo(() => {
    const operators = (allTariffs || []).map((x) => ({ label: x.operator, value: x.operator.toLowerCase() }));

    return [
      ...operators,
      { label: t('optimizer.configuration.tariff.custom'), value: TariffOperator.Custom, disabled: true },
    ];
  }, [allTariffs]);

  const operatorTariffs: SelectOptionInterface<string>[] = useMemo(() => {
    const tariffs =
      allTariffs
        ?.find((x) => x.operator.toLowerCase() === operator)
        ?.tariffs.map((y) => ({ label: y.name, value: y.name.toLowerCase() })) || [];

    if (dynamicTariff) {
      tariffs.push({ label: t('optimizer.configuration.tariff.dynamic'), value: TariffName.Dynamic });
    }

    return customTariff
      ? [...tariffs, { label: t('optimizer.configuration.tariff.custom'), value: TariffName.Dynamic, disabled: true }]
      : [...tariffs];
  }, [allTariffs, operator, customTariff, dynamicTariff]);

  return (
    <HeaderWithHelp
      kind="sub-header"
      name="tariff"
      headerTitle={t('optimizer.configuration.tariff.label')}
      helpTitle={t('optimizer.configuration.tariff.label')}
      helpContent={<TariffHelp type={type} />}
    >
      <div className="grid-list-24">
        <Controller
          name="operator"
          control={control}
          rules={{
            required: tc('isRequired') as string,
          }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              onChange={field.onChange}
              value={field.value}
              options={operators}
              label={t('optimizer.configuration.tariff.operator')}
              name={field.name}
              {...(error ? { errorMessage: error.message } : {})}
            />
          )}
        />

        {operator && (
          <Controller
            name="tariffName"
            control={control}
            rules={{
              required: tc('isRequired') as string,
            }}
            render={({ field, fieldState: { error } }) => (
              <InputSelect
                onChange={field.onChange}
                value={field.value}
                disabled={operator === TariffOperator.Custom}
                options={operatorTariffs}
                label={t('optimizer.configuration.tariff.label')}
                name={field.name}
                {...(error ? { errorMessage: error.message } : {})}
              />
            )}
          />
        )}
      </div>

      {tariffName && (
        <>
          {hoursRanges.map((range: HoursRangeFront, index: number) => (
            <TariffHours
              key={index}
              index={index}
              range={range}
              timeZoneKind={range.timeZoneKind}
              handleWatchForCustom={handleWatchForCustom}
              editEnabled={customTariff}
            />
          ))}
          {tariffName !== TariffName.Dynamic && (
            <>
              <CheckboxController
                name="wholeWeekendCheaper"
                label={t('optimizer.configuration.tariff.wholeWeekendCheaper')}
                handleWatchForCustom={handleWatchForCustom}
              />
              <CheckboxController
                name="wholeHolidaysCheaper"
                label={t('optimizer.configuration.tariff.wholeHolidaysCheaper')}
                handleWatchForCustom={handleWatchForCustom}
              />
              <CheckboxController
                name="ignoreSummerTimeChanges"
                label={t('optimizer.configuration.tariff.ignoreSummerTimeChanges')}
                handleWatchForCustom={handleWatchForCustom}
              />
            </>
          )}
        </>
      )}
    </HeaderWithHelp>
  );
};
