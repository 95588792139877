import React from 'react';

export const IconApple: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1968 10.5235L12.992 11.245C13.1259 11.283 13.2678 11.283 13.4017 11.245L13.1968 10.5235ZM1.75657 22.0862L2.48963 21.9278C2.48676 21.9145 2.48353 21.9013 2.47995 21.8882L1.75657 22.0862ZM13.1265 28.9642L13.7123 28.4958C13.5733 28.3219 13.3642 28.2188 13.1416 28.2143C12.919 28.2098 12.706 28.3045 12.5601 28.4726L13.1265 28.9642ZM24.6893 22.0862L23.9659 21.8882C23.9623 21.9013 23.9591 21.9145 23.9562 21.9277L24.6893 22.0862ZM13.4017 9.80204C12.0452 9.41687 10.3883 9.1326 8.71411 9.13577C7.04529 9.13893 5.30846 9.42797 3.83482 10.2371C2.33832 11.0587 1.14962 12.399 0.593642 14.4084C0.0449947 16.3914 0.126329 18.9723 1.0332 22.2843L2.47995 21.8882C1.61387 18.7251 1.58836 16.4384 2.03933 14.8084C2.48297 13.205 3.40034 12.1869 4.55674 11.5519C5.73599 10.9045 7.19888 10.6386 8.71695 10.6358C10.2297 10.6329 11.7468 10.8914 12.992 11.245L13.4017 9.80204ZM1.02351 22.2447C1.84683 26.0527 4.28321 28.6073 6.83793 29.8554C8.1119 30.4777 9.43865 30.7868 10.6326 30.747C11.8212 30.7074 12.9469 30.3155 13.693 29.4557L12.5601 28.4726C12.1695 28.9227 11.4983 29.2173 10.5827 29.2478C9.67258 29.2781 8.58799 29.0409 7.49635 28.5076C5.31986 27.4443 3.20796 25.2501 2.48963 21.9278L1.02351 22.2447ZM13.4017 11.245C14.6465 10.8915 16.167 10.6329 17.6848 10.6358C19.208 10.6386 20.6781 10.9045 21.8643 11.5527C23.0279 12.1884 23.951 13.2073 24.3988 14.8102C24.8541 16.4396 24.8318 18.7256 23.9659 21.8882L25.4126 22.2843C26.3196 18.9718 26.3977 16.3902 25.8435 14.4066C25.282 12.3966 24.0859 11.0572 22.5835 10.2363C21.1039 9.42788 19.3611 9.13892 17.6877 9.13577C16.0089 9.1326 14.3487 9.41679 12.992 9.80204L13.4017 11.245ZM23.9562 21.9277C23.2408 25.2363 21.048 27.4345 18.7939 28.5039C17.6641 29.0398 16.5471 29.2782 15.6249 29.2478C14.6918 29.217 14.0512 28.9196 13.7123 28.4958L12.5408 29.4325C13.2493 30.3186 14.385 30.7077 15.5754 30.747C16.7767 30.7866 18.1304 30.4788 19.4368 29.8591C22.0548 28.6171 24.596 26.0665 25.4223 22.2447L23.9562 21.9277Z"
        fill="#15141D"
      />
      <path
        d="M13.1266 10.4641C12.8054 7.18796 12.2338 0.702277 20.0964 1.01062C20.0964 6.69867 15.1118 6.69867 12.9787 7.40968"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.64589 17.5482L4.91936 20.0725C5.05657 21.3391 6.17043 22.2708 7.44135 22.1819C8.42487 22.1131 9.26313 21.4427 9.54644 20.4984L9.66962 20.0878C9.96388 19.1069 9.73579 18.0436 9.06509 17.2697L8.0287 16.0739C7.57659 15.5522 6.88063 15.3112 6.20274 15.4416C5.21463 15.6316 4.53751 16.5479 4.64589 17.5482Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
