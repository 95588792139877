import React from 'react';
import './index.scss';

export const IconToastSuccess: React.FC = () => {
  return (
    <div className={'icon-toast-success'}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11" fill="#29D058" />
        <path d="M7 11.5L11 15L17 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </div>
  );
};
