import React from 'react';

export const IconShower: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-shower"
  >
    <path d="M9.35645 26.499V27.5704" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.9277 26.499V27.5704" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.35645 32.9287V34.0001"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9277 32.9287V34.0001"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.6426 26.499V27.5704" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.6426 32.9287V34.0001"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22.2144 26.499V27.5704" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M22.2144 32.9287V34.0001"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M33.9982 4H18.999" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.7837 7.2141C15.7837 5.43735 17.221 4 18.9978 4"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.14258 18.9975C6.14258 15.462 9.03527 12.5693 12.5708 12.5693H18.999C22.5345 12.5693 25.4272 15.462 25.4272 18.9975"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6426 12.5716V9.3575C13.6426 8.76825 14.1247 8.28613 14.7139 8.28613H16.8567C17.4459 8.28613 17.9281 8.76825 17.9281 9.3575V12.5716"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2846 18.999H25.963C26.8467 18.999 27.5701 19.7224 27.5701 20.6061C27.5701 21.4897 26.8467 22.2131 25.963 22.2131H5.60705C4.72339 22.2131 4 21.4897 4 20.6061C4 19.7224 4.72339 18.999 5.60705 18.999H18.9992"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
