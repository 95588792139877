import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLavvaCreateActionContext } from '../context';
import { Task } from '../types';

export const useTaskEdit = () => {
  const history = useHistory();
  const { addTaskToList, taskList, editDeviceTask } = useLavvaCreateActionContext();
  const [task, setTask] = useState<Task | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    if (id) {
      const index = taskList.findIndex((x) => x.id === id);

      if (index !== -1) {
        setTask(taskList[index]);
      }
    }
  }, []);

  const finalizeDeviceTaskSubmit = (taskBody: Task, back: boolean) => {
    if (!task) {
      addTaskToList(taskBody);
      if (back) history.go(-3);
    } else {
      editDeviceTask(task.id, taskBody.taskParams.state);
      history.goBack();
    }
  };

  return {
    task,
    finalizeDeviceTaskSubmit,
  };
};
