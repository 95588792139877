import React from 'react';

export const IconExalusFloodSensor: React.FC = () => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23111_32127)">
      <path
        d="M35.3436 39.1662C35.3436 43.3112 31.9112 46.6713 27.6772 46.6713C23.4431 46.6713 20.0107 43.3112 20.0107 39.1662C20.0107 35.0213 22.3107 32.207 27.6772 26.6123C32.9043 32.2752 35.3436 35.0213 35.3436 39.1662Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M32.2932 38.2754C32.2932 39.5897 31.8191 40.7956 31.0292 41.7381M26.7129 43.7383C27.7552 43.7383 28.7308 43.4585 29.5656 42.9715"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M40.6057 41.1494C41.3758 40.425 41.8551 39.4064 41.8551 38.2783C41.8551 37.1255 41.3546 36.0871 40.5547 35.3601"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.3963 41.1494C14.6262 40.425 14.1469 39.4064 14.1469 38.2783C14.1469 37.1255 14.6474 36.0871 15.4473 35.3601"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.7877 16.2079H16.4198C16.0558 16.2079 15.7607 15.9129 15.7607 15.5488V12.8935C15.7607 12.5295 16.0558 12.2344 16.4198 12.2344H21.4495M21.7877 16.2079V20.3083C21.7877 21.0363 22.3779 21.6264 23.1059 21.6264H31.724C32.452 21.6264 33.0422 21.0363 33.0422 20.3083V16.2079M21.7877 16.2079H33.0422M33.0422 16.2079H37.8566C38.2206 16.2079 38.5157 15.9129 38.5157 15.5488V12.8935C38.5157 12.5295 38.2206 12.2344 37.8566 12.2344H32.827"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23111_32127">
        <rect width="38" height="38" fill="white" transform="translate(9 9.85938)" />
      </clipPath>
    </defs>
  </svg>
);
