import React from 'react';

export const IconExalusMovementSensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.158203" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M41.8172 35.6455C43.1567 34.3718 43.9904 32.5811 43.9904 30.5977C43.9904 28.5708 43.1198 26.7452 41.7285 25.4669"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14.4972 35.5488C13.1577 34.2328 12.3241 32.3826 12.3241 30.3333C12.3241 28.2392 13.1946 26.3529 14.5859 25.0322"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="29.0066" cy="15.4431" r="3.16675" stroke="#15141D" strokeWidth="1.5" />
    <path
      d="M29.4374 31.6248L29.9336 29.4499L30.0103 29.5839C30.0645 29.6785 30.1387 29.7602 30.2277 29.8232L33.4445 32.1015C34.2404 32.6651 35.3501 32.405 35.8125 31.5464C36.1937 30.8387 35.9997 29.9586 35.3563 29.4767L32.6427 27.4445C32.5962 27.3179 32.5452 27.1551 32.4873 26.9516C32.4378 26.7779 32.3901 26.6003 32.3385 26.4079C32.2986 26.2593 32.2564 26.1018 32.2091 25.9305C32.003 25.1841 31.7338 24.2971 31.3227 23.5116C30.851 22.6102 29.9896 21.9678 29.2114 21.5815C28.8143 21.3843 28.4103 21.2393 28.0437 21.1572C27.7066 21.0818 27.2973 21.0353 26.9441 21.1405C26.826 21.1757 26.7183 21.2395 26.6307 21.3262L21.727 26.1784C21.6242 26.2801 21.5531 26.4092 21.522 26.5504L20.5399 31.0152C20.3227 32.0025 20.9756 32.9708 21.9724 33.1396C22.4445 33.2196 22.9046 33.1046 23.2702 32.8532L22.3979 36.7859L19.6924 41.5141C19.1212 42.5123 19.4127 43.7819 20.3621 44.4309C21.4177 45.1526 22.8638 44.8285 23.5104 43.7253L26.7696 38.1644C26.8072 38.1003 26.835 38.0311 26.8522 37.9588L27.3431 35.8962L29.3131 38.1221L30.1663 43.1269C30.3565 44.2426 31.3867 45.0141 32.5109 44.8827C33.7322 44.74 34.5787 43.5946 34.3567 42.3851L33.2555 36.3865C33.2323 36.2604 33.1773 36.1424 33.0955 36.0437L29.4374 31.6248Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
