import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChannelBoxSimple,
  Header,
  IconChevron,
  Input,
  InputSelect,
  NavHeader,
  Page,
  SelectOptionInterface,
  SubmitButton,
} from '../../../../../../components';
import { DialogValuePicker } from '../../../../../../components/dialog-value-picker';
import { ChannelTypeInternal, TriggerConditionType } from '../../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../../../hooks';
import { useTriggerFormContext } from '../../../../context';
import { TriggerStateValueBlind, TriggerStateValueSwitch } from '../../enums';
import './index.scss';

interface Props {
  channelId: string;
  setChannelId: (id: string) => void;
  selectedChannel: string;
  setSelectedChannel: (value: string | null) => void;
  goToChannels?: () => void;
  goToSummary?: () => void;
}

export const ConditionCreator: React.FC<Props> = ({
  channelId,
  setChannelId,
  selectedChannel,
  setSelectedChannel,
  goToChannels,
  goToSummary,
}) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { channel } = useDevicesAndChannels({ channelId: selectedChannel });
  const { addStateCondition, actions, stateConditions } = useTriggerFormContext();
  const stateCondition = useMemo(() => {
    return stateConditions.find((condition) => condition.id === selectedChannel);
  }, [stateConditions, channelId]);
  const [showConditionValuePicker, setShowConditionValuePicker] = useState<boolean>(false);
  const [conditionValue, setConditionValue] = useState<number>(stateCondition?.integerValue ?? 30);

  const blindConditionOptions: SelectOptionInterface<TriggerStateValueBlind>[] = useMemo(
    () => [
      {
        value: TriggerStateValueBlind.GREATER_THAN,
        label: t('trigger.conditions.state.blind.greaterThanOrEqual'),
      },
      {
        value: TriggerStateValueBlind.LESS_THAN,
        label: t('trigger.conditions.state.blind.lessThanOrEqual'),
      },
      { value: TriggerStateValueBlind.CLOSED, label: t('trigger.conditions.state.blind.closed') },
      { value: TriggerStateValueBlind.OPENED, label: t('trigger.conditions.state.blind.opened') },
    ],
    [],
  );

  const switchConditionOptions: SelectOptionInterface<TriggerStateValueSwitch>[] = useMemo(
    () => [
      { value: TriggerStateValueSwitch.ON, label: t('trigger.conditions.state.switch.on') },
      { value: TriggerStateValueSwitch.OFF, label: t('trigger.conditions.state.switch.off') },
    ],
    [],
  );

  const lightConditionOptions: SelectOptionInterface<TriggerStateValueSwitch>[] = useMemo(
    () => [
      { value: TriggerStateValueSwitch.ON, label: t('trigger.conditions.state.light.on') },
      { value: TriggerStateValueSwitch.OFF, label: t('trigger.conditions.state.light.off') },
    ],
    [],
  );

  const conditionOptions: SelectOptionInterface<TriggerStateValueSwitch | TriggerStateValueBlind>[] = useMemo(() => {
    switch (channel?.data.type) {
      case ChannelTypeInternal.Switch:
        return switchConditionOptions;
      case ChannelTypeInternal.Light:
        return lightConditionOptions;
      case ChannelTypeInternal.Blind:
      case ChannelTypeInternal.Gate:
        return blindConditionOptions;
      default:
        return switchConditionOptions;
    }
  }, [channel]);

  const selectedSwitchCondition = useMemo(() => {
    if (stateCondition) {
      switch (stateCondition.booleanValue) {
        case true:
          return TriggerStateValueSwitch.ON;
        case false:
        default:
          return TriggerStateValueSwitch.OFF;
      }
    }
    return TriggerStateValueSwitch.ON;
  }, []);

  const selectedBlindCondition = useMemo(() => {
    if (stateCondition) {
      switch (stateCondition.conditionType) {
        case TriggerConditionType.BlindPositionIsGreaterThanOrEqualTo:
        case TriggerConditionType.GatePositionIsGreaterThanOrEqualTo: {
          if (stateCondition.integerValue === 100) {
            return TriggerStateValueBlind.CLOSED;
          }
          return TriggerStateValueBlind.GREATER_THAN;
        }
        case TriggerConditionType.BlindPositionIsLessThanOrEqualTo:
        case TriggerConditionType.GatePositionIsLessThanOrEqualTo:
          if (stateCondition.integerValue === 0) {
            return TriggerStateValueBlind.OPENED;
          }
          return TriggerStateValueBlind.LESS_THAN;
      }
    }
    return TriggerStateValueBlind.CLOSED;
  }, []);

  const conditionOnChange = useCallback((value) => {
    setSelectedCondition(conditionOptions.find((option) => option.value === value) ?? conditionOptions[0]);
  }, []);
  const defaultValue = useMemo(() => {
    switch (channel?.data.type) {
      case ChannelTypeInternal.Switch:
      case ChannelTypeInternal.Light:
        return selectedSwitchCondition;
      case ChannelTypeInternal.Blind:
      case ChannelTypeInternal.Gate:
        return selectedBlindCondition;
    }
  }, []);
  const [selectedCondition, setSelectedCondition] = useState(
    conditionOptions.find((option) => option.value === defaultValue) ?? conditionOptions[0],
  );

  const showConditionValueSelect = useMemo(() => {
    return (
      selectedCondition.value === TriggerStateValueBlind.GREATER_THAN ||
      selectedCondition.value === TriggerStateValueBlind.LESS_THAN
    );
  }, [selectedCondition]);

  const onSubmit = useCallback(() => {
    const switchCondition = (selectedCondition: SelectOptionInterface<string>) => {
      switch (selectedCondition.value) {
        case TriggerStateValueSwitch.ON: {
          return {
            conditionType: TriggerConditionType.SwitchStateIs,
            booleanValue: true,
          };
        }
        case TriggerStateValueSwitch.OFF: {
          return {
            conditionType: TriggerConditionType.SwitchStateIs,
            booleanValue: false,
          };
        }
        default:
          return {
            conditionType: TriggerConditionType.SwitchStateIs,
            booleanValue: true,
          };
      }
    };

    const lightCondition = (selectedCondition: SelectOptionInterface<string>) => {
      switch (selectedCondition.value) {
        case TriggerStateValueSwitch.ON: {
          return {
            conditionType: TriggerConditionType.LightStateIs,
            booleanValue: true,
          };
        }
        case TriggerStateValueSwitch.OFF: {
          return {
            conditionType: TriggerConditionType.LightStateIs,
            booleanValue: false,
          };
        }
        default:
          return {
            conditionType: TriggerConditionType.LightStateIs,
            booleanValue: true,
          };
      }
    };

    const blindCondition = (selectedCondition: SelectOptionInterface<string>, type: ChannelTypeInternal) => {
      switch (selectedCondition.value) {
        case TriggerStateValueBlind.CLOSED: {
          return {
            conditionType:
              type === ChannelTypeInternal.Gate
                ? TriggerConditionType.GatePositionIsGreaterThanOrEqualTo
                : TriggerConditionType.BlindPositionIsGreaterThanOrEqualTo,
            integerValue: 100,
          };
        }
        case TriggerStateValueBlind.OPENED: {
          return {
            conditionType:
              type === ChannelTypeInternal.Gate
                ? TriggerConditionType.GatePositionIsLessThanOrEqualTo
                : TriggerConditionType.BlindPositionIsLessThanOrEqualTo,
            integerValue: 0,
          };
        }
        case TriggerStateValueBlind.GREATER_THAN: {
          return {
            conditionType:
              type === ChannelTypeInternal.Gate
                ? TriggerConditionType.GatePositionIsGreaterThanOrEqualTo
                : TriggerConditionType.BlindPositionIsGreaterThanOrEqualTo,
            integerValue: conditionValue,
          };
        }
        case TriggerStateValueBlind.LESS_THAN: {
          return {
            conditionType:
              type === ChannelTypeInternal.Gate
                ? TriggerConditionType.GatePositionIsLessThanOrEqualTo
                : TriggerConditionType.BlindPositionIsLessThanOrEqualTo,
            integerValue: conditionValue,
          };
        }
        default:
          return {
            conditionType:
              type === ChannelTypeInternal.Gate
                ? TriggerConditionType.GatePositionIsLessThanOrEqualTo
                : TriggerConditionType.BlindPositionIsLessThanOrEqualTo,
            integerValue: 100,
          };
      }
    };

    const channelCondition = (selectedCondition: SelectOptionInterface<string>, type: ChannelTypeInternal) => {
      switch (type) {
        case ChannelTypeInternal.Switch:
          return switchCondition(selectedCondition);
        case ChannelTypeInternal.Light:
          return lightCondition(selectedCondition);
        default:
          return blindCondition(selectedCondition, type);
      }
    };

    addStateCondition({
      id: selectedChannel,
      ...channelCondition(selectedCondition, channel?.data.type || ChannelTypeInternal.Unknown),
    });

    setSelectedChannel(null);
    setChannelId('');

    if (goToChannels && goToSummary) {
      if (!actions.length) {
        goToChannels();
      } else {
        goToSummary();
      }
    }
  }, [selectedCondition, conditionValue]);

  return (
    <Page
      header={
        <>
          <NavHeader
            onClick={() => {
              setSelectedChannel(null);
              setChannelId('');
              if (channelId && goToSummary) {
                goToSummary();
              }
            }}
          />
          <Header title={t('trigger.conditions.state.determineCondition')} isUnderline />
        </>
      }
    >
      <div className="condition-creator">
        <div className="condition-creator__internal">
          <Header title={t('trigger.conditions.state.device')} subheader titleClassName="m-t-8" />
          {channel && <ChannelBoxSimple channel={channel} />}
          <hr className="m-b-24" />
          <Header title={t('trigger.conditions.state.condition')} subheader />
          <InputSelect
            onChange={conditionOnChange}
            value={selectedCondition.value}
            options={conditionOptions}
            label={t('trigger.conditions.state.condition')}
          />
          {showConditionValueSelect ? (
            <>
              <div onClick={() => setShowConditionValuePicker(true)}>
                <Input
                  defaultValue={`${conditionValue}%`}
                  value={`${conditionValue}%`}
                  required
                  readOnly
                  endIcon={<IconChevron direction={'right'} noSpace />}
                  label={t('triggerParameters.blindPosition')}
                ></Input>
              </div>

              <DialogValuePicker
                open={showConditionValuePicker}
                setOpen={setShowConditionValuePicker}
                title={t('triggerParameters.blindPosition')}
                onSave={(value) => setConditionValue(value)}
                value={conditionValue}
                unit={'%'}
              />
            </>
          ) : null}
        </div>
      </div>
      <SubmitButton onClick={onSubmit}>{tc('buttons.next')}</SubmitButton>
    </Page>
  );
};
