import { gql } from '@apollo/client';

export const ON_INSTALLATION_DETACH = gql`
  subscription OnInstallationDetach($installationId: UUID!) {
    onInstallationDetach(installationId: $installationId) {
      installationId
      newBrokerUrl
    }
  }
`;
