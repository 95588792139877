import React from 'react';
import '../index.scss';
import './index.scss';

export const IconUserAccess: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--user-access"
  >
    <path
      d="M6.59521 16.4664H4.04144C3.46627 16.4664 3 16.0002 3 15.425V15.2879C3 12.2203 5.48676 9.73358 8.55435 9.73358H9.21493"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="9.21567" cy="6.36642" r="3.36642" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <circle
      cx="14.8862"
      cy="11.0518"
      r="1.98983"
      transform="rotate(45 14.8862 11.0518)"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2151 12.1949L9.60882 15.8012L11.8076 18"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.7203 14.2178L13.2156 15.7131" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
