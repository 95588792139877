import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import {
  InputModeDimmer as InputModeDimmerType,
  InputOutputDimmerParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

interface ComponentProps {
  device: IDevice;
  inputMode: InputOutputDimmerParameters | undefined;
}

const InputModeForm: React.FC<ComponentProps> = ({ device, inputMode }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<InputOutputDimmerParameters>();

  const { InputModeDimmer } = form.watch();

  useEffect(() => {
    if (inputMode !== undefined) form.setValue('InputModeDimmer', inputMode.InputModeDimmer);
  }, [inputMode]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (inputMode) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRdp21ConfigService>(
        Rdp21ConfigService.ServiceName,
      );

      const result = await configService.SetDimmerInputOutputParamAsync(device, {
        Channel: inputMode.Channel,
        InputModeDimmer: values.InputModeDimmer,
      });
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeInputModeDimmer = (value: InputModeDimmerType) => {
    form.setValue('InputModeDimmer', value);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeInputModeDimmer}
          value={InputModeDimmer}
          options={[
            { label: t('exalus.params.OnOffParams.inputModeSteps.monostable'), value: InputModeDimmerType.Monostable },
            { label: t('exalus.params.OnOffParams.inputModeSteps.time'), value: InputModeDimmerType.Time },
            { label: t('exalus.params.OnOffParams.inputModeSteps.bistable'), value: InputModeDimmerType.Bistable },
          ]}
          label={t('exalus.params.OnOffParams.InputModeParams')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default InputModeForm;
