import React from 'react';
import { FormChannelsContextProvider } from '../context';
import { Edit } from './edit';

export const EditGroup: React.FC = () => (
  <FormChannelsContextProvider>
    <Edit />
  </FormChannelsContextProvider>
);

export default EditGroup;
