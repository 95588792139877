import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Header, IconLogoWisniowskiSmall, NavHeader, Page } from '../../../../../components';
import { useBluetoothContext } from '../../../context';
import { DriveFlowType } from '../../types';
import './index.scss';

const WisniowskiDriveAddTutorialStep2: React.FC = () => {
  const { t } = useTranslation('drive-add');
  const { type } = useParams<{ type: DriveFlowType }>();
  const { checkIfConnected } = useBluetoothContext();

  useEffect(() => {
    checkIfConnected();
  }, []);

  return (
    <Page
      contentClassName="drive-add tutorial"
      containerClassName="p-t-0"
      isStickyHeader
      header={
        <>
          <NavHeader title={t('navbarHeader')} />
        </>
      }
    >
      <div className="tutorial--content">
        <Header title={t(`tutorial.pairing${type === DriveFlowType.Remote ? 'Remote' : ''}`)} center />
        <img src={`/images/drive-${type === DriveFlowType.Remote ? 'remote' : 'radio'}.png`} alt="drive-radio" />
        <IconLogoWisniowskiSmall className="m-t-48" />
      </div>
    </Page>
  );
};

export default WisniowskiDriveAddTutorialStep2;
