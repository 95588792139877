import React from 'react';

export const IconHeart: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="32"
      height="29"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6598 3.387C27.9341 2.63025 27.0725 2.02994 26.1242 1.62037C25.1758 1.21081 24.1593 1 23.1328 1C22.1062 1 21.0898 1.21081 20.1414 1.62037C19.1931 2.02994 18.3314 2.63025 17.6057 3.387L16.0996 4.95678L14.5935 3.387C13.1277 1.85914 11.1395 1.00079 9.06646 1.00079C6.9934 1.00079 5.00526 1.85914 3.53939 3.387C2.07352 4.91486 1.25 6.98708 1.25 9.1478C1.25 11.3085 2.07352 13.3807 3.53939 14.9086L5.04548 16.4784L16.0996 28L27.1538 16.4784L28.6598 14.9086C29.3859 14.1522 29.9618 13.2541 30.3548 12.2657C30.7477 11.2772 30.95 10.2178 30.95 9.1478C30.95 8.07785 30.7477 7.01838 30.3548 6.02992C29.9618 5.04147 29.3859 4.14339 28.6598 3.387V3.387Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
