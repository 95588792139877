import React, { ChangeEvent, UIEvent, useEffect, useMemo, useRef, useState } from 'react';
import c from 'classnames';
import classNames from 'classnames';
import { Input } from '../../input';
import { Dialog } from '../base';
import './confirmation.scss';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  header?: string;
  headerValue?: string;
  content?: string;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  primaryBtnAction?: (event?: UIEvent) => void;
  secondaryBtnAction?: (event?: UIEvent) => void;
  direction?: 'row' | 'column' | 'column-reverse';
  primaryBtnDisabled?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  headerIcon?: React.ReactNode;
  contentClassName?: string;
  timer?: boolean;
  timerCancel?: boolean;
  confirmInput?: { label: string; value: string };
}

export const DialogConfirmation: React.FC<Props> = ({
  header,
  headerValue,
  content,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnAction,
  secondaryBtnAction,
  show,
  setShow,
  direction = 'row',
  children,
  icon,
  headerIcon,
  primaryBtnDisabled,
  contentClassName,
  timer,
  timerCancel,
  confirmInput,
}) => {
  const interval = useRef<NodeJS.Timeout | null>(null);
  const intervalCancel = useRef<NodeJS.Timeout | null>(null);
  const [time, setTime] = useState<number>(0);
  const [timeCancel, setTimeCancel] = useState<number>(0);
  const [confirm, setConfirm] = useState<string>('');

  const removeInterval = () => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  };

  const removeIntervalCancel = () => {
    if (intervalCancel.current) {
      clearInterval(intervalCancel.current);
      intervalCancel.current = null;
    }
  };

  useEffect(() => {
    const letCount = () =>
      setTime((prev) => {
        if (prev === 0) {
          removeInterval();
          return prev;
        }

        return prev - 1;
      });

    if (show && timer) {
      setTime(9);
      interval.current = setInterval(letCount, 1000);
    } else {
      removeInterval();
    }

    return () => {
      removeInterval();
    };
  }, [timer, show]);

  useEffect(() => {
    const letCount = () =>
      setTimeCancel((prev) => {
        if (prev === 0) {
          removeIntervalCancel();
          return prev;
        }

        return prev - 1;
      });

    if (show && timerCancel) {
      setTimeCancel(9);
      intervalCancel.current = setInterval(letCount, 1000);
    } else {
      removeIntervalCancel();
    }

    return () => {
      removeIntervalCancel();
    };
  }, [timerCancel, show]);

  const primaryButtonDisabled = useMemo(() => {
    if (!confirmInput) {
      return primaryBtnDisabled || !!time;
    } else {
      return primaryBtnDisabled || !!time || confirmInput.value !== confirm;
    }
  }, [primaryBtnDisabled, confirmInput, time, confirm]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setConfirm(event.target.value);

  return (
    <Dialog show={show} setShow={setShow} backdropCloseDisabled>
      <div className={`dialog-confirmation dialog-confirmation--direction-${direction}`}>
        {icon && icon}
        {headerValue ? (
          <div className="dialog-confirmation__header dialog-confirmation__header--container">
            <span>{header}:</span>
            <span>{headerValue}</span>
          </div>
        ) : (
          <h4
            className={classNames('dialog-confirmation__header', {
              'with-icon': !!headerIcon,
              'm-b-0': !primaryBtnText && !secondaryBtnText,
            })}
          >
            {header}
            {headerIcon ? headerIcon : null}
          </h4>
        )}
        <div
          className={c('dialog-confirmation__content', {
            [contentClassName as string]: contentClassName,
          })}
        >
          {content || children}
        </div>
        {confirmInput && (
          <div className="m-t-24">
            <Input
              defaultValue={confirm}
              value={confirm}
              placeholder={confirmInput.label}
              onChange={handleInputChange}
              reverse
            />
          </div>
        )}
        {primaryBtnText || secondaryBtnText ? (
          <div className="dialog-confirmation__buttons-wrapper">
            {secondaryBtnText && (
              <button
                className="dialog-confirmation__button dialog-confirmation__button--secondary"
                onClick={secondaryBtnAction}
                disabled={!!timeCancel}
              >
                {`${secondaryBtnText}${timeCancel ? ` (${timeCancel}s)` : ''}`}
              </button>
            )}
            {direction === 'row' && primaryBtnText && secondaryBtnText && secondaryBtnText && (
              <div className="dialog-confirmation__button-divider" />
            )}
            {primaryBtnText && (
              <button
                className="dialog-confirmation__button dialog-confirmation__button--primary"
                disabled={primaryButtonDisabled}
                onClick={primaryBtnAction}
              >
                {`${primaryBtnText}${time ? ` (${time}s)` : ''}`}
              </button>
            )}
          </div>
        ) : null}
      </div>
    </Dialog>
  );
};
