import React from 'react';
import '../index.scss';

export const IconRubber: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--rubber"
  >
    <path d="M10 16.6667H17.5" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.84183 10.2132L3.2943 9.66566L10.2526 2.70735C10.6431 2.31683 11.2763 2.31683 11.6668 2.70735L15.6758 6.71629C16.0663 7.10681 16.0655 7.74075 15.675 8.13127C13.1067 10.6996 11.4667 12.3395 8.71745 15.0888L8.0917 14.4631M3.84183 10.2132L2.60808 11.4469C2.02229 12.0327 2.02229 12.9825 2.60808 13.5683L4.73663 15.6968C5.32242 16.2826 6.27217 16.2826 6.85795 15.6968L8.0917 14.4631M3.84183 10.2132L8.0917 14.4631"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.92374 9.77444L11.1664 5.5318" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.69144 11.5422L12.9341 7.29956" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
