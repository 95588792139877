import React from 'react';

export const IconNotebook: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-notebook"
  >
    <path
      d="M6 30.5747C6 28.1208 8.66809 28.0621 8.76805 28.0608C8.77123 28.0607 8.77252 28.0607 8.7757 28.0607H25.6809C26.7855 28.0607 27.6809 27.1653 27.6809 26.0607V4C27.6809 2.89543 26.7855 2 25.6809 2H9.45249C7.54595 2 6 3.54595 6 5.45249V30.5747ZM6 30.5747C6 31.9967 7.15286 33.1486 8.57388 33.1486H29.7946C30.8992 33.1486 31.7946 32.2531 31.7946 31.1486V8.40931C31.7946 7.30474 30.8992 6.40931 29.7946 6.40931H29.624"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 7L10.2118 6.96975C14.7144 6.32651 19.2856 6.32651 23.7882 6.96975L24 7"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 9L10.2118 8.96975C14.7144 8.32651 19.2856 8.32651 23.7882 8.96975L24 9"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.24219 36.855L10.748 35.3111L13.2538 36.855V30.661H8.24219V36.855Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
