import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconSchedule } from '../../../../../../components';
import { VectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { measurementParameters } from '../../../../measurement/utils';

interface PropsInterface {
  averageValue: number;
  minValue: number;
  maxValue: number;
  selectedParameter: VectorParameterTypeInternal;
  isActivePowerSelected: boolean;
  supportedScalarMin: boolean;
  supportedScalarMax: boolean;
  supportedScalarAverage: boolean;
}

export const CurrentAbsoluteValues: React.FC<PropsInterface> = ({
  averageValue,
  minValue,
  maxValue,
  selectedParameter,
  isActivePowerSelected,
  supportedScalarMin,
  supportedScalarMax,
  supportedScalarAverage,
}) => {
  const { t } = useTranslation('channel-details');
  const fixed = measurementParameters[selectedParameter].fixed;
  const unit = measurementParameters[selectedParameter].unit2 || measurementParameters[selectedParameter].unit;

  return (
    <div className="summary summary--background-element">
      <div className="summary__caption p-l-0 p-r-0">
        <IconSchedule />
        <span className="summary__caption-text">{t('energyMeasurements.summary')}</span>
      </div>
      <div className="summary__section p-t-0 p-b-0 p-l-0 p-r-0 max-width-desktop">
        {supportedScalarAverage && (
          <div className="summary__section-row">
            <span>{t('summary.average')}</span>
            <span className="summary__section-value">
              {+(averageValue / (isActivePowerSelected ? 1000 : 1)).toFixed(fixed)} {unit}
            </span>
          </div>
        )}
        {supportedScalarMax && (
          <div className="summary__section-row">
            <span>{t('summary.max')}</span>
            <span className="summary__section-value">
              {+(maxValue / (isActivePowerSelected ? 1000 : 1)).toFixed(fixed)} {unit}
            </span>
          </div>
        )}
        {supportedScalarMin && (
          <div className="summary__section-row">
            <span>{t('summary.min')}</span>
            <span className="summary__section-value">
              {+(minValue / (isActivePowerSelected ? 1000 : 1)).toFixed(fixed)} {unit}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
