import React from 'react';

export const IconTv: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3889 18.7778H3C1.89543 18.7778 1 17.8823 1 16.7778V3C1 1.89543 1.89543 1 3 1H22.2222C23.3268 1 24.2222 1.89543 24.2222 3V16.7778C24.2222 17.8823 23.3268 18.7778 22.2222 18.7778H13.8333M11.3889 18.7778V23H13.8333M11.3889 18.7778H13.8333M13.8333 23V18.7778M13.8333 23H19.8299M13.8333 23H5.40527"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
