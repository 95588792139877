import React from 'react';

export const IconLock: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="22"
      height="27"
      viewBox="0 0 22 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 10.1055H11.0149H17.8122C19.469 10.1055 20.8122 11.4486 20.8122 13.1055V15.0516V21.8254C20.8122 22.9325 20.3724 23.9943 19.5895 24.7771C18.8067 25.5599 17.7449 25.9997 16.6378 25.9997H5.42434C4.31724 25.9997 3.25548 25.5599 2.47264 24.7771C1.6898 23.9943 1.25 22.9325 1.25 21.8254V13.1055C1.25 11.4486 2.59314 10.1055 4.25 10.1055Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.53125 10.1054L5.57094 6.40507C5.60305 3.4106 8.03964 1 11.0343 1V1V1C14.0722 1 16.535 3.46274 16.535 6.50069V10.1054"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M11.0312 18.9976V16.6634"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
