import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  className?: string;
}

export const IconLocation3: React.FC<PropsInterface> = ({ className }) => {
  return (
    <div
      className={classNames('icon-location', {
        [className as string]: className,
      })}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.25 9.5C19.25 12.4741 17.2771 15.797 15.1617 18.4723C14.121 19.7885 13.0783 20.9081 12.2952 21.6988C11.9824 22.0147 11.7117 22.2774 11.5 22.4785C11.2883 22.2774 11.0176 22.0147 10.7048 21.6988C9.92169 20.9081 8.87905 19.7885 7.83831 18.4723C5.72292 15.797 3.75 12.4741 3.75 9.5C3.75 5.21979 7.21979 1.75 11.5 1.75C15.7802 1.75 19.25 5.21979 19.25 9.5Z"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle cx="11.5" cy="9.5" r="2.75" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </div>
  );
};
