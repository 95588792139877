import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import { InstallationItem } from '../../../components';
import { UserInstallation } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';

export const InstallationListSection: React.FC = () => {
  const { installationList } = useInstallation();

  const orderedInstallationList = useMemo(
    () =>
      orderBy(installationList, 'order').map((installation: UserInstallation) => (
        <InstallationItem key={installation.installationId} installation={installation} />
      )),
    [installationList],
  );

  return <>{orderedInstallationList}</>;
};
