import React from 'react';

export const IconScrewDriver: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-screwdriver"
  >
    <path
      d="M16.2109 16.7345L16.7212 16.2241C16.9615 15.9839 17.3521 15.9839 17.5924 16.2241L21.948 20.5798C22.1882 20.82 22.1882 21.2107 21.948 21.4509L21.4376 21.9612C21.2062 22.1927 20.893 22.322 20.5665 22.322H19.845C19.5185 22.322 19.2053 22.4522 18.9739 22.6828L18.8243 22.8324C18.5928 23.0638 18.4635 23.377 18.4635 23.7035V24.425C18.4635 24.7515 18.3341 25.0647 18.1027 25.2962L9.20669 34.8469C7.60083 36.5706 4.88538 36.619 3.2188 34.9533C1.5531 33.2876 1.60062 30.5713 3.32527 28.9654L12.876 20.0694C13.1074 19.838 13.4206 19.7086 13.7471 19.7086H14.4686C14.7951 19.7086 15.1083 19.5784 15.3398 19.3479L15.4893 19.1983C15.7208 18.9669 15.8501 18.6536 15.8501 18.3272V17.6056C15.8501 17.2792 15.9795 16.9659 16.2109 16.7345Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 18.5L32.2405 7.50002C32.3267 7.41378 32.4358 7.35571 32.5555 7.33195L34.2714 6.98878C34.4482 6.95358 34.6013 6.84183 34.6893 6.68433L36.194 3.6796C36.3277 3.43938 36.2855 3.13932 36.091 2.94486L35.0061 1.85991C34.8116 1.66545 34.5116 1.62321 34.2714 1.75696L31.3157 3.3107C31.1573 3.39869 31.0464 3.55092 31.0112 3.72866L30.6681 5.44451C30.6443 5.56418 30.5854 5.67329 30.5 5.75953L19 17"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
