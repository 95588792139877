import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import c from 'classnames';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import {
  CustomBackdrop,
  DialogConfirmation,
  EmptyStateBox,
  Header,
  IconWarning,
  NavHeader,
  Page,
  SubmitButton,
  Tabs,
} from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import {
  InstallationAccessType,
  Invitation,
  InvitationStatus,
  ModifyShareInfoChannelInput,
  ModifyShareInfoItemBaseInput,
  ShareInfoModificationType,
  SharingItemType,
} from '../../../data-access/gql-types/graphql';
import { useApi, useProfile } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { SharedItemType, SharingType } from '../../../types';
import { leadingZero } from '../../../utils/helpers';
import { getParameterByName, pathnameWithParameters } from '../../../utils/location';
import UserBox from '../components/user-box';
import { Member } from '../context/types';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import { useModify } from '../hooks/use-modify';
import './index.scss';

const title = {
  UNDEFINED: 'sharingTitle',
  INSTALLATION: 'sharingTitle',
  GROUP: 'access_granted_groups',
  CHANNEL: 'access_granted_channels',
};

interface User {
  userId: string;
  userName: string;
}

const SharedInstallation: React.FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('installation');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    getParameterByName('tab') ? parseFloat(getParameterByName('tab')) : 0,
  );
  const { state, members, sharingInfosloading, shareInfos, installationOwner, methods } = useInstallationShare();
  const { modyfyGroupAccess, modifyUserAccessesLoading, modyfyChannelAccess } = useModify();
  const { refetchSharingInfo } = useMember();
  const { allInvitations, allInvitationsLoading, refetchInvitations } = useApi();
  const { user: userProfile } = useProfile();
  const [user, setUser] = useState<User>({ userId: '', userName: '' });
  const [removeAccessPopup, setRemoveAccessPopup] = useState<boolean>(false);

  useEffect(() => {
    if (refetchInvitations) refetchInvitations();

    const stateParam = new URLSearchParams(search).get('state');

    if (stateParam) {
      methods.setFullState(JSON.parse(stateParam));
    }
  }, []);

  useEffect(
    () =>
      history.replace(
        pathnameWithParameters(pathname, search, {
          key: 'tab',
          value: activeTabIndex,
        }),
      ),
    [activeTabIndex],
  );

  const tabs = useMemo(() => [{ label: t('access_granted') }, { label: t('invitations') }], [t]);

  const openSummary = (inv: Invitation) => {
    methods.handleSetInvitationDate(format(new Date(inv.createdAt), 'dd.MM.yyyy'));
    methods.handleSetShareEmail(inv.invitedUserEmail);
    methods.handleSetSharePermission(inv.sharingPayload.accessType);

    if (inv.sharingPayload.sharedItems.length > 0) {
      methods.handleSetShareQuantity(inv.sharingPayload.sharedItems[0].grantedUses || 0);

      if (inv.sharingPayload.sharedItems[0].grantedUses) methods.handleSetShareAccess(SharingType.Quantitative);
      if (inv.sharingPayload.sharedUntil) methods.handleSetShareAccess(SharingType.Timed);

      if (inv.sharingPayload.sharedItems[0].itemType === SharingItemType.Channel) {
        methods.handleSetShareSubject(SharedItemType.Channel);
        const channels = (inv.sharingPayload.sharedItems || []).map((item) => ({
          id: item.id,
          name: item.name || 'N/A',
        }));
        methods.handleUpdateChannels(channels);
      } else if (inv.sharingPayload.sharedItems[0].itemType === SharingItemType.Group) {
        methods.handleSetShareSubject(SharedItemType.Group);
        const groups = (inv.sharingPayload.sharedItems || []).map((item) => ({
          id: item.id,
          name: item.name || 'N/A',
        }));
        methods.handleUpdateGroups(groups);
      } else {
        methods.handleSetShareSubject(SharedItemType.Installation);
        methods.handleSetShareAccess(SharingType.Permanent);
      }
    } else {
      methods.handleSetShareSubject(SharedItemType.Installation);
      methods.handleSetShareAccess(SharingType.Permanent);
    }

    history.push(ROUTES.InvitationSummary(inv.status, inv.id));
  };

  const invitationList = useMemo(() => {
    const invitations = orderBy(
      allInvitations.filter(
        (x) => x.createdByUserId === userProfile.profile.id && x.installationId === state.installationId,
      ),
      'createdAt',
      'desc',
    ).filter(
      (x) =>
        x.status === InvitationStatus.Pending ||
        x.status === InvitationStatus.TimedOut ||
        x.status === InvitationStatus.Rejected,
    );
    if (invitations.length === 0) {
      return <EmptyStateBox content={t('invitations_empty')} icon={<IconWarning />} />;
    }

    return invitations.map((inv) => (
      <ArrowButton
        key={inv.id}
        title={inv.invitedUserEmail}
        onClick={() => openSummary(inv)}
        upper={
          <p className={c('status', inv.status.toLowerCase())}>
            {`${t('invitation_status')}: `} <span>{t(inv.status.toLowerCase())}</span>
          </p>
        }
        subTitle={`${t('invitation_sent')} ${format(new Date(inv.createdAt), 'dd.MM.yyyy')}`}
      />
    ));
  }, [allInvitations, userProfile, state.installationId]);

  const deletePopup = (userId: string, userName: string) => {
    setUser({ userId, userName });
    setRemoveAccessPopup(true);
  };

  const removeAccess = () => {
    if (state.subject === SharedItemType.Group) {
      const groups: ModifyShareInfoItemBaseInput[] = [
        { id: state.shareItem.itemId, shareInfoModificationType: ShareInfoModificationType.Remove },
      ];
      modyfyGroupAccess(user.userId, groups, () => {
        setUser({ userId: '', userName: '' });
        setRemoveAccessPopup(false);
        refetchSharingInfo();
      });
    } else if (state.subject === SharedItemType.Channel) {
      const channels: ModifyShareInfoChannelInput[] = [
        { id: state.shareItem.itemId, shareInfoModificationType: ShareInfoModificationType.Remove },
      ];
      modyfyChannelAccess(user.userId, channels, () => {
        setUser({ userId: '', userName: '' });
        setRemoveAccessPopup(false);
        refetchSharingInfo();
      });
    }
  };

  const editAccess = (userId: string) => {
    switch (state.subject) {
      case SharedItemType.Group: {
        const foundShareItem = shareInfos.find((x) => x.sharedToUserId === userId);

        if (foundShareItem) {
          const foundGroup = foundShareItem.groups.find((x) => x.id === state.shareItem.itemId);

          if (foundGroup) {
            methods.handleSetModificationType(ShareInfoModificationType.Modify);
            methods.handleSetShareSubject(SharedItemType.Group);
            methods.handleSetUserId(userId);
            methods.handleSetEditedId(foundGroup.id);

            if (foundGroup.sharedFrom && foundGroup.sharedUntil) {
              methods.handleSetShareAccess(SharingType.Timed);

              const dateStart = new Date(foundGroup.sharedFrom);
              methods.handleSetShareDateStart(dateStart);
              methods.handleSetShareTimeStart(
                [dateStart.getHours(), dateStart.getMinutes()].map(leadingZero).join(':'),
              );

              const dateEnd = new Date(foundGroup.sharedUntil);
              methods.handleSetShareDateEnd(dateEnd);
              methods.handleSetShareTimeEnd([dateEnd.getHours(), dateEnd.getMinutes()].map(leadingZero).join(':'));
            } else methods.handleSetShareAccess(SharingType.Permanent);

            history.push(ROUTES.InstallationShareRestriction());
          }
        }
        break;
      }
      case SharedItemType.Channel: {
        const foundShareItem = shareInfos.find((x) => x.sharedToUserId === userId);

        if (foundShareItem) {
          const foundChannel = foundShareItem.channels.find((x) => x.id === state.shareItem.itemId);

          if (foundChannel) {
            methods.handleSetModificationType(ShareInfoModificationType.Modify);
            methods.handleSetShareSubject(SharedItemType.Channel);
            methods.handleSetUserId(userId);
            methods.handleSetEditedId(foundChannel.id);

            if (foundChannel.sharedFrom && foundChannel.sharedUntil) {
              methods.handleSetShareAccess(SharingType.Timed);

              const dateStart = new Date(foundChannel.sharedFrom);
              methods.handleSetShareDateStart(dateStart);
              methods.handleSetShareTimeStart(
                [dateStart.getHours(), dateStart.getMinutes()].map(leadingZero).join(':'),
              );

              const dateEnd = new Date(foundChannel.sharedUntil);
              methods.handleSetShareDateEnd(dateEnd);
              methods.handleSetShareTimeEnd([dateEnd.getHours(), dateEnd.getMinutes()].map(leadingZero).join(':'));
            } else if (foundChannel.grantedUses) {
              methods.handleSetShareAccess(SharingType.Quantitative);
              methods.handleSetShareQuantity(foundChannel.grantedUses);
            } else methods.handleSetShareAccess(SharingType.Permanent);
            history.push(ROUTES.InstallationShareRestriction());
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleNext = () => {
    const st = state.shareItem;

    if (state.subject === SharedItemType.Group || state.subject === SharedItemType.Channel) {
      methods.handleResetShareInstallation();
      methods.handleSetModificationType(ShareInfoModificationType.Add);
      methods.handleSetShareSubject(state.subject);
      methods.handleSetShareItem({ itemId: st.itemId, name: st.name });
      methods.handleUpdateChannels([{ id: st.itemId, name: st.name }]);
      methods.handleSetSharePermission(InstallationAccessType.User);
      history.push(ROUTES.InstallationShareEmail());
    } else {
      history.push(ROUTES.InstallationShareEmail());
    }
  };

  const memberList = useMemo(() => {
    let filteredMembers = [...members];

    if (state.subject === SharedItemType.Group) {
      filteredMembers = [...members]
        .filter((x) => x.accessType !== InstallationAccessType.Owner)
        .filter((member) =>
          shareInfos.find(
            (shareInfo) =>
              member.id === shareInfo.sharedToUserId &&
              (shareInfo.groups.find((x) => x.id === state.shareItem.itemId) ||
                shareInfo.installation.wholeInstallationShare),
          ),
        );
    }

    if (state.subject === SharedItemType.Channel) {
      filteredMembers = [...members]
        .filter((x) => x.accessType !== InstallationAccessType.Owner)
        .filter((member) =>
          shareInfos.find(
            (shareInfo) =>
              member.id === shareInfo.sharedToUserId &&
              (shareInfo.channels.find((x) => x.id === state.shareItem.itemId) ||
                shareInfo.installation.wholeInstallationShare),
          ),
        );
    }

    if (filteredMembers.length === 0) return <p className="empty_member_list">{t('access_granted_empty')}</p>;

    return filteredMembers.map((member: Member) => (
      <UserBox
        key={member.id}
        id={member.id}
        name={member.firstName}
        email={member.email}
        img={member.image}
        accessType={member.accessType}
        sharedItemType={member.sharedItemType}
        background
        redirect={!state.shareItem.itemId}
        access={!!state.shareItem.itemId}
        deletePopup={deletePopup}
        editAccess={editAccess}
      />
    ));
  }, [members, state.shareItem, state.subject]);

  return (
    <Page
      className="installation-shared"
      carouselContent
      isStickyHeader
      header={
        <>
          <NavHeader title={`${t(title[state.subject])} ${state.shareItem.itemId ? state.shareItem.name : ''}`} />
          <Header column isUnderline>
            {installationOwner && (
              <UserBox
                id={installationOwner?.id || ''}
                name={installationOwner?.firstName || ''}
                email={installationOwner?.email || ''}
                img={installationOwner?.profileImage?.imageUrl || ''}
                accessType={InstallationAccessType.Owner}
              />
            )}
          </Header>
        </>
      }
    >
      {!state.shareItem.itemId && (
        <Tabs
          tabList={tabs}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          tabsType="pills"
          isDivider={false}
        />
      )}
      {activeTabIndex === 0 ? (
        <>
          {sharingInfosloading ? (
            <CustomBackdrop loading={sharingInfosloading} />
          ) : (
            <div className="grid-list-24">{memberList}</div>
          )}
          <SubmitButton type="button" onClick={handleNext}>
            {t(`share_${state.subject.toLowerCase()}`)}
          </SubmitButton>
        </>
      ) : (
        <>
          {!allInvitationsLoading ? (
            <div className="grid-list-24">{invitationList}</div>
          ) : (
            <CustomBackdrop loading={allInvitationsLoading} />
          )}
        </>
      )}
      <DialogConfirmation
        show={removeAccessPopup}
        setShow={setRemoveAccessPopup}
        header={t(`shared_user.remove_${state.subject.toLowerCase()}_access`)}
        content={t(`shared_user.remove_${state.subject.toLowerCase()}_access_confirm`, {
          user: user.userName,
          item: state.shareItem.name,
        })}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={modifyUserAccessesLoading ? tc('buttons.loading') : t('shared_user.remove_access')}
        secondaryBtnAction={() => setRemoveAccessPopup(false)}
        primaryBtnAction={removeAccess}
        direction="column"
      />
    </Page>
  );
};

export default SharedInstallation;
