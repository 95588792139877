import { PointerEvent } from 'react';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceTask } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import {
  BlindActionEnum,
  SetBlindMicroventilation,
  SetBlindPosition,
} from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useNative } from '../../../../../hooks';
import { useExalusServicesContext } from '../../../context/services';
import { CentralActionBlindControlHook } from '../types';

export const useCentralActionBlindControl = (channels: IDeviceChannel[]): CentralActionBlindControlHook => {
  const { devicesApi } = useExalusServicesContext();
  const { fireVibration } = useNative();

  const open = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetBlindPosition();
      task.Action = BlindActionEnum.Open;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const close = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetBlindPosition();
      task.Action = BlindActionEnum.Close;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const stop = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetBlindPosition();
      task.Action = BlindActionEnum.Stop;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const handleAction = async (action: BlindActionEnum, position?: number) => {
    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetBlindPosition();
      task.Action = action;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;
      if (typeof position === 'number' && position >= 0) {
        task.Position = position;
      }

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    handleAction(BlindActionEnum.Percentage, Number((event.target as HTMLInputElement).value));
  };

  const handleMicroventilation = async (position: number) => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetBlindMicroventilation();
      task.Position = position;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  return {
    open,
    close,
    stop,
    handleAction,
    handleSliderEvent,
    handleMicroventilation
  };
};
