import React, { ReactNode } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import LavvaDetailsWrapper from '../../../../../components/details-wrapper';
import { useHistoryState } from '../hooks/use-history-state';
import SensorHistory from '../sensor-history';

interface ComponentProps {
  channel: IDeviceChannel;
  children: ReactNode;
  active?: boolean;
}

const SensorDetailsWrapper: React.FC<ComponentProps> = ({ channel, children, active }) => {
  const { tabs } = useHistoryState(channel);

  return (
    <LavvaDetailsWrapper tabs={tabs} additionalTabs={[<SensorHistory key={2} channel={channel} active={active} />]}>
      <div className="p-l-24 p-r-24">{children}</div>
    </LavvaDetailsWrapper>
  );
};

export default SensorDetailsWrapper;
