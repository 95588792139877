import { IconHandMovement, IconHumanMovement } from '../../../../../../../components/icons';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const movementIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconHumanMovement isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_25',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_HAND_MOVEMENT,
    component: <IconHandMovement isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_26',
  },
];
