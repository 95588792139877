import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { IconCkeckMarks, IconRubber } from '../../../components/popup-nav/icons';
import { useApi, useBackdropContext } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { toastError } from '../../../utils/toast';

export const useNotificationList = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation('notifications');
  const { t: tc } = useTranslation('common');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [showClearAllDialog, setShowClearAllDialog] = useState<boolean>(false);
  const { notificationList, notificationsLoading, markAsDisplayed, markAsHidden } = useApi();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  useEffect(() => {
    const tabIndex = new URLSearchParams(search).get('tab');
    if (tabIndex) setActiveTabIndex(parseInt(tabIndex));
  }, []);

  const handleMarkAllAsDisplayed = useCallback(() => {
    const correlationIds = notificationList.filter((x) => !x.wasDisplayed).map((x) => x.id);
    if (correlationIds.length > 0) {
      markAsDisplayed(correlationIds, {
        onSuccess: () => turnOffBackdrop(),
        onError: () => {
          turnOffBackdrop();
          toastError({ content: tc('errors.somethingWentWrong') });
        },
      });
    }
  }, [notificationList]);

  const popUpNavLinks = useMemo(
    () => [
      { label: t('clearAll'), onClick: () => setShowClearAllDialog(true), icon: <IconRubber /> },
      { label: t('markAllAsDisapled'), onClick: handleMarkAllAsDisplayed, icon: <IconCkeckMarks /> },
    ],
    [],
  );

  const tabs = [{ label: t('allNotifications') }, { label: t('unread') }];

  const onClearAllCancel = useCallback(() => setShowClearAllDialog(false), []);

  const onClearAll = useCallback(() => {
    const ids = notificationList.map((notification) => notification.id);
    turnOnBackdrop();
    setShowClearAllDialog(false);
    markAsHidden(ids, {
      onSuccess: () => turnOffBackdrop(),
      onError: () => {
        turnOffBackdrop();
        toastError({ content: tc('errors.somethingWentWrong') });
      },
    });
  }, [notificationList]);

  const handleChangeTab = useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    history.replace(`${ROUTES.Notification()}?tab=${tabIndex}`);
  }, []);

  return {
    popUpNavLinks,
    activeTabIndex,
    showClearAllDialog,
    notificationList,
    tabs,
    notificationsLoading,
    onClearAll,
    onClearAllCancel,
    handleChangeTab,
    setShowClearAllDialog,
  };
};
