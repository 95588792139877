import React from 'react';

export const IconExalusReedSensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.158203" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M34 11H14.9962C13.8938 11 13 11.8938 13 12.9962V38.5422C13 39.6447 13.8938 40.5385 14.9962 40.5385H19.4615M34 11V39.1627C34 39.9911 33.4885 40.7333 32.7144 41.0282L22.1684 45.0457C20.8618 45.5435 19.4615 44.5785 19.4615 43.1803V40.5385M34 11L21.5499 14.9524C20.3063 15.3472 19.4615 16.5017 19.4615 17.8064V40.5385"
      stroke="#15141D"
      strokeWidth="1.49719"
      strokeLinejoin="round"
    />
    <path
      d="M23.7695 28.3359V32.9513"
      stroke="#15141D"
      strokeWidth="1.49719"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="26" y="25" width="17.7931" height="20" rx="2" fill="white" />
    <path
      d="M28.5517 43L34.6061 43C34.9106 43 35.1575 42.7533 35.1578 42.4488L35.1719 27.5523C35.1722 27.2474 34.9251 27 34.6202 27L31.5702 27L28.5517 27C28.247 27 28 27.247 28 27.5517L28 42.4483C28 42.753 28.247 43 28.5517 43Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.9306 43.0003L41.2321 43.0003C41.5367 43.0003 41.7836 42.7536 41.7839 42.4491L41.7922 33.0698C41.7925 32.7649 41.5454 32.5176 41.2405 32.5176L39.576 32.5176L37.9306 32.5176C37.6259 32.5176 37.3789 32.7646 37.3789 33.0693L37.3789 42.4486C37.3789 42.7533 37.6259 43.0003 37.9306 43.0003Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
