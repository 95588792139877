import React, { ReactNode } from 'react';
import { Controller, useFormContext, ValidateResult } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOptionInterface } from '../../dialog';
import { InputSelect } from '../../input';

interface ComponentProps<T> {
  name: string;
  label: string;
  options: SelectOptionInterface<T>[];
  value?: string;
  disabled?: boolean;
  isRequired?: boolean;
  inputComponent?: ReactNode;
  handleChange?: (val) => void;
  validation?: (val: string) => ValidateResult | Promise<ValidateResult>
}

export const SelectInputController = <T,>({
  name,
  label,
  options,
  value,
  disabled,
  isRequired,
  inputComponent,
  validation,
  handleChange,
}: ComponentProps<T>) => {
  const { t: tc } = useTranslation('common');
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...(isRequired ? { required: tc('isRequired') as string } : {}),
        ...(validation
          ? {
              validate: validation,
            }
          : {}),
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputSelect
            onChange={handleChange || field.onChange}
            value={value !== undefined ? value : field.value}
            options={options}
            label={label}
            name={field.name}
            inputComponent={inputComponent}
            {...(disabled !== undefined ? { disabled: disabled } : {})}
            {...(error ? { errorMessage: error.message } : {})}
          />
        );
      }}
    />
  );
};
