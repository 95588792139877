import React from 'react';
import classNames from 'classnames';
import {
  BlindRemoteButtonState,
  BlindRemoteButtonStateEnum,
  FacadeRemoteButtonState,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { IconChevron, IconPause } from '../../../../../components';

interface ComponentProps {
  close: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  stop: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  open: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  remoteButtonState?: IChannelState<BlindRemoteButtonState | FacadeRemoteButtonState>;
  isListItem?: boolean;
}

export const BlindControlButtons: React.FC<ComponentProps> = ({ close, stop, open, remoteButtonState, isListItem }) => (
  <div
    className={classNames('cover-control-box', {
      'cover-control-box--list-view': isListItem,
    })}
  >
    <div className="cover-control-box__controls">
      <div
        className={classNames('cover-control-box__control cover-control-box__control--cover', {
          'cover-control-box__control--active':
            remoteButtonState?.Data.State === BlindRemoteButtonStateEnum.ClosePressed,
        })}
        onClick={close}
      >
        <IconChevron withCircle direction="down" />
      </div>
      <div
        className={classNames('cover-control-box__control cover-control-box__control--cover', {
          'cover-control-box__control--active':
            remoteButtonState?.Data.State === BlindRemoteButtonStateEnum.StopPressed,
        })}
        onClick={stop}
      >
        <IconPause />
      </div>
      <div
        className={classNames('cover-control-box__control cover-control-box__control--cover', {
          'cover-control-box__control--active':
            remoteButtonState?.Data.State === BlindRemoteButtonStateEnum.OpenPressed,
        })}
        onClick={open}
      >
        <IconChevron withCircle direction="up" />
      </div>
    </div>
  </div>
);
