import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PopUpNavLinkInterface } from '../../../components';
import { IconGroups, IconSettings, IconShare } from '../../../components/popup-nav/icons';
import { DashboardItemType, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { useChannelDetailsContext } from '../../../hooks/channel-details';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { useShareRedirect } from '../../installation-share/hooks/use-share-redirect';
import { RouteParams } from '../types';
import { useModifyMultipleGroups } from './use-modify-multiple-groups';

export const useChannelDetails = () => {
  const history = useHistory();
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('configuration');
  const { channelId } = useParams<RouteParams>();
  const { channel } = useChannelDetailsContext();
  const { selectedInstallation } = useInstallation();
  const { permissions } = usePermissionsContext();
  const { shareChannel } = useShareRedirect();
  const { groupList, groupPopup, handleSaveGroup, toggleGroup, setGroupPopup } = useModifyMultipleGroups({
    itemId: channelId,
    itemType: DashboardItemType.Channel,
  });

  const popUpNavLinks = useMemo(() => {
    const links: PopUpNavLinkInterface[] = [
      {
        onClick: () => history.push(ROUTES.ChannelConfiguration(channel?.data.type, channel?.id)),
        label: tc('channelSettings'),
        icon: <IconSettings />,
      },
      ...(permissions[UserPermissionType.AddRemoveChannelForMultipleGroups] ||
      permissions[UserPermissionType.AddRemoveChannelForMultiplePublicGroups]
        ? [
            {
              onClick: () => setGroupPopup(true),
              label: t('groupAddRemove'),
              icon: <IconGroups />,
              hasLineBelow: true,
            },
          ]
        : []),
      ...(permissions[UserPermissionType.ChannelShare]
        ? [
            {
              onClick: () => shareChannel(channel),
              label: t('shareChannel'),
              icon: <IconShare />,
            },
          ]
        : []),
    ];

    return links;
  }, [channel, selectedInstallation?.accessType, permissions]);

  return {
    popUpNavLinks,
    channel,
    groupPopup,
    groupList,
    setGroupPopup,
    handleSaveGroup,
    toggleGroup,
  };
};
