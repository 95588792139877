import React from 'react';

export const IconBook: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-book"
  >
    <path
      d="M19 7.08522C24.9114 4.17403 30.4521 4.43897 35 7.08522V29.7705C30.0384 27.5913 23.7312 27.5913 19 29.7705C14.2698 27.5913 7.96263 27.5913 3 29.7705V7.08522C7.54689 4.43897 13.0876 4.17403 19 7.08522Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 29.7704L4.65979 32.4314C4.6001 31.8596 11.7838 29.9265 19 32.5874C19.9687 31.8962 29.23 30.7202 33.3109 32.5874L35 29.7715"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19 29.7704V32.5874" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 7.08521V29.7705" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 10V10C9.32155 9.66835 11.6785 9.66835 14 10V10" stroke="#15141D" strokeLinecap="round" />
    <path d="M7 12V12C9.32155 11.6684 11.6785 11.6684 14 12V12" stroke="#15141D" strokeLinecap="round" />
  </svg>
);
