import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import { InstallationAccessType, ShareInfoModificationType } from '../../../data-access/gql-types/graphql';
import { SharedItemType, SharingType } from '../../../types';
import {
  SET_SHARE_INSTALLATION_ID,
  SET_SHARE_EMAIL,
  SET_USER_ID,
  SET_SHARE_SUBJECT,
  SET_SHARE_PERMISSION,
  SET_SHARE_RESTRICTION_ACCESS,
  SET_SHARE_QUANTITY,
  UPDATE_SHARE_GROUPS,
  UPDATE_SHARE_CHANNELS,
  SET_SHARE_TIME_START,
  SET_SHARE_TIME_END,
  SET_SHARE_DATE_START,
  SET_SHARE_DATE_END,
  SET_SHARE_ITEM,
  SET_SHARE_INVITATION_DATE,
  SET_EDITED_ID,
  SET_MODIFICATION_TYPE,
  RESET_SHARE_INSTALLATION,
  SET_FULL_STATE,
  SetShareInstallationIdAction,
  SetShareEmailAction,
  SetUserIdAction,
  SetShareSubjectAction,
  SetSharePermissionAction,
  SetShareRestrictionAccessAction,
  SetShareQuantityAction,
  UpdateShareGroupsAction,
  UpdateShareChannelsAction,
  SetShareTimeStartAction,
  SetShareTimeEndAction,
  SetShareDateStartAction,
  SetShareDateEndAction,
  SetShareItemAction,
  SetShareInvitationDate,
  ResetShareInstallationAction,
  SetModificationTypeAction,
  SetEditedIdAction,
  SetFullStateAction,
} from './constants';
import { ShareChannel, ShareGroup, ShareItem } from './types';

export type StateShareInstallationType = {
  installationId: string;
  email: string;
  userId: string;
  subject: SharedItemType;
  permission: InstallationAccessType;
  access: SharingType;
  quantity: number;
  groups: ShareGroup[];
  channels: ShareChannel[];
  timeStart: string;
  timeEnd: string;
  dateStart: Date;
  dateEnd: Date;
  shareItem: ShareItem;
  invitationDate: string;
  editedId: string;
  shareInfoModificationType: ShareInfoModificationType;
};

type ActionType =
  | SetShareInstallationIdAction
  | SetShareEmailAction
  | SetUserIdAction
  | SetShareSubjectAction
  | SetSharePermissionAction
  | SetShareRestrictionAccessAction
  | SetShareQuantityAction
  | UpdateShareGroupsAction
  | UpdateShareChannelsAction
  | SetShareTimeStartAction
  | SetShareTimeEndAction
  | SetShareDateStartAction
  | SetShareDateEndAction
  | SetShareItemAction
  | SetShareInvitationDate
  | SetEditedIdAction
  | SetModificationTypeAction
  | ResetShareInstallationAction
  | SetFullStateAction;

type ContextType = {
  state: StateShareInstallationType;
  dispatch: Dispatch<ActionType>;
};

export const initialState: StateShareInstallationType = {
  installationId: '',
  userId: '',
  email: '',
  subject: SharedItemType.Undefined,
  permission: InstallationAccessType.Undefined,
  access: SharingType.Undefined,
  quantity: 0,
  groups: [],
  channels: [],
  timeStart: '',
  timeEnd: '',
  dateStart: new Date(),
  dateEnd: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  shareItem: { itemId: '', name: '' },
  invitationDate: '',
  editedId: '',
  shareInfoModificationType: ShareInfoModificationType.Undefined,
};

const ShareInstallationContext = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

const shareInstallationReducer = (state: StateShareInstallationType = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_SHARE_INSTALLATION_ID: {
      return {
        ...state,
        installationId: action.payload,
      };
    }
    case SET_SHARE_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }
    case SET_USER_ID: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case SET_SHARE_SUBJECT: {
      return {
        ...state,
        subject: action.payload,
      };
    }
    case SET_MODIFICATION_TYPE: {
      return {
        ...state,
        shareInfoModificationType: action.payload,
      };
    }
    case SET_SHARE_PERMISSION: {
      return {
        ...state,
        permission: action.payload,
      };
    }
    case SET_SHARE_RESTRICTION_ACCESS: {
      return {
        ...state,
        access: action.payload,
      };
    }
    case SET_SHARE_QUANTITY: {
      return {
        ...state,
        quantity: action.payload,
      };
    }
    case UPDATE_SHARE_GROUPS: {
      return {
        ...state,
        groups: action.payload as ShareGroup[],
      };
    }
    case UPDATE_SHARE_CHANNELS: {
      return {
        ...state,
        channels: action.payload as ShareChannel[],
      };
    }
    case SET_SHARE_TIME_START: {
      return {
        ...state,
        timeStart: action.payload,
      };
    }
    case SET_SHARE_TIME_END: {
      return {
        ...state,
        timeEnd: action.payload,
      };
    }
    case SET_SHARE_DATE_START: {
      return {
        ...state,
        dateStart: action.payload,
      };
    }
    case SET_SHARE_DATE_END: {
      return {
        ...state,
        dateEnd: action.payload,
      };
    }
    case SET_SHARE_ITEM: {
      return {
        ...state,
        shareItem: action.payload,
      };
    }
    case SET_SHARE_INVITATION_DATE: {
      return {
        ...state,
        invitationDate: action.payload,
      };
    }
    case SET_EDITED_ID: {
      return {
        ...state,
        editedId: action.payload,
      };
    }
    case RESET_SHARE_INSTALLATION: {
      return {
        ...initialState,
        installationId: state.installationId,
      };
    }
    case SET_FULL_STATE: {
      return {
        ...initialState,
        ...action.payload,
        dateStart: new Date(action.payload.dateStart),
        dateEnd: new Date(action.payload.dateEnd),
      };
    }
    default: {
      return state;
    }
  }
};

export const useShareInstallationContext = (): ContextType => useContext(ShareInstallationContext);

export const ShareInstallationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(shareInstallationReducer, initialState);
  return <ShareInstallationContext.Provider value={{ state, dispatch }}>{children}</ShareInstallationContext.Provider>;
};
