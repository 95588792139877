import { Dispatch, SetStateAction } from 'react';
import { SelectOptionInterface } from '../../../../../components';
import { CheckboxesOptions } from '../../../../../components/checkbox-select';
import {
  AggregateVectorParameterTypeInternal,
  GetAggregateVectorMeasurementResponse,
  Maybe,
  MeasuredBy,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface, MeasurementRange, ValueEntry } from '../../../../../types';
import { SelectedPhase } from '../../../measurement/types';

export interface ChartDataItem {
  values: ChartDataValues[];
  time?: string;
  tooltipTime?: string;
  date?: number;
}

export interface ChartDataValues extends ValueEntry {
  color: string;
  index: number;
  empty?: boolean;
}

export interface AnalysisHook {
  data: Maybe<GetAggregateVectorMeasurementResponse> | undefined;
  isLoading: boolean;
  selectedPhases: SelectedPhase[] | undefined;
  setSelectedPhases: (value: SelectedPhase[]) => void;
  mappedChartData: ChartDataItem[];
  onClickActiveMeasurementRange: (value: MeasurementRange) => void;
  handleSetAggregatedParameters: (options: CheckboxesOptions<AggregateVectorParameterTypeInternal>[]) => void;
  aggregatedParameters: CheckboxesOptions<AggregateVectorParameterTypeInternal>[];
  activeMeasurementRange: MeasurementRange;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  timeRanges: SelectOptionInterface<MeasurementRange>[];
  isStandalone: boolean;
}

export interface AnalysisHookParams {
  channel: ChannelInterface;
  measurementChannel?: MeasuredBy;
}

export enum AvailableShortFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'EEEEEE',
  MONTHLY = 'dd.LL',
  YEARLY = 'LL',
  TEN_YEARS = 'yyyy',
}

export enum AvailableLongFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'eeee, dd.MM',
  MONTHLY = 'dd.LL.yyyy',
  YEARLY = 'LLLL',
  TEN_YEARS = 'yyyy',
}
