import React from 'react';

export const IconExalusFlushMountedTransceiver: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.449219" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M15.7 44L37.2487 44C38.7376 44 39.9455 42.7946 39.9487 41.3057L39.9943 19.7057C39.9974 18.2123 38.7877 17 37.2943 17L26.4397 17L15.7 17C14.2088 17 13 18.2088 13 19.7L13 41.3C13 42.7912 14.2088 44 15.7 44Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="18.9434"
      cy="23.1504"
      r="1.91379"
      transform="rotate(90 18.9434 23.1504)"
      stroke="#15141D"
      strokeWidth="2.32759"
    />
    <circle
      cx="18.9434"
      cy="30.7256"
      r="1.91379"
      transform="rotate(90 18.9434 30.7256)"
      stroke="#15141D"
      strokeWidth="2.32759"
    />
    <circle
      cx="18.9434"
      cy="38.3008"
      r="1.91379"
      transform="rotate(90 18.9434 38.3008)"
      stroke="#15141D"
      strokeWidth="2.32759"
    />
    <path
      d="M45.2828 16.8291C45.2481 15.3281 44.6268 13.8538 43.4347 12.7805C42.2165 11.6837 40.6443 11.2233 39.1169 11.3746"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M43.1855 17.2171C43.1618 16.1901 42.7366 15.1814 41.921 14.4471C41.0875 13.6966 40.0118 13.3816 38.9667 13.4851"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
