import {
  ChannelTypeInternal,
  GenericInputChannelStateResponse,
  UserChannel,
} from '../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum, ChannelInterface, ChannelStateType } from '../../../types';

export const parseGenericInput = (userChannel: UserChannel): ChannelInterface => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.ICON_VERTICAL_GATE,
  deviceId: userChannel.deviceId,
  data: {
    type: ChannelTypeInternal.GenericInput,
    genericInputType: (userChannel.payload as GenericInputChannelStateResponse)?.genericInputType,
    monostableMode: (userChannel.payload as GenericInputChannelStateResponse)?.monostableMode || undefined,
    bistableMode: (userChannel.payload as GenericInputChannelStateResponse)?.bistableMode || undefined,
  },
});

export const parseGenericInputStateChannel = (userChannel: UserChannel): ChannelStateType => ({
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  status: null,
});
