import { TimeZone } from '../../../api/modules/installation/installation.types';
import { SelectOptionInterface } from '../../../components';

export const mapTimeZonesToOptions = (data: TimeZone[] | undefined, search: string): SelectOptionInterface<string>[] => {
  const options: SelectOptionInterface<string>[] = [];

  try {
    data
      ?.filter(
        (x) =>
          x.id.toLowerCase().includes(search.toLowerCase()) ||
          x.offsets[0].toLowerCase().includes(search.toLowerCase()),
      )
      .map((zone: TimeZone) => {
        options.push({ label: `${zone.id} (GMT${zone.offsets[0]})`, value: zone.id });
      });

    return options;
  } catch {
    return options;
  }
};
