import React from 'react';

export const IconBriefcase: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="35"
      height="31"
      viewBox="0 0 35 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.92041 15.9393C2.92041 15.5251 2.58462 15.1893 2.17041 15.1893C1.7562 15.1893 1.42041 15.5251 1.42041 15.9393H2.92041ZM2.17041 29.9999H1.42041C1.42041 30.4142 1.7562 30.7499 2.17041 30.7499V29.9999ZM32.8302 29.9999V30.7499C33.2444 30.7499 33.5802 30.4142 33.5802 29.9999H32.8302ZM33.5802 15.5C33.5802 15.0858 33.2444 14.75 32.8302 14.75C32.416 14.75 32.0802 15.0858 32.0802 15.5H33.5802ZM1.42041 15.9393V29.9999H2.92041V15.9393H1.42041ZM2.17041 30.7499H32.8302V29.2499H2.17041V30.7499ZM33.5802 29.9999V15.5H32.0802V29.9999H33.5802Z"
        fill="#cbcbd1"
      />
      <path d="M1.29395 12.405V5.83335H12.6819V3.6479C12.6819 2.18551 13.8674 1 15.3298 1H19.2319C20.6943 1 21.8798 2.18551 21.8798 3.6479V5.83335H33.7057V11.6403C33.7057 13.5667 33.0319 15.4324 31.801 16.9143C30.2329 18.802 27.9061 19.894 25.452 19.894H8.78296C6.79261 19.894 4.88413 19.1017 3.47899 17.6921C2.07969 16.2883 1.29395 14.3871 1.29395 12.405Z" />
      <path
        d="M1.29395 5.83335V5.08335C0.879732 5.08335 0.543945 5.41914 0.543945 5.83335H1.29395ZM33.7057 5.83335H34.4557C34.4557 5.41914 34.1199 5.08335 33.7057 5.08335V5.83335ZM31.801 16.9143L31.224 16.435L31.801 16.9143ZM3.47899 17.6921L4.01017 17.1626H4.01017L3.47899 17.6921ZM0.543945 5.83335V12.405H2.04395V5.83335H0.543945ZM8.78296 20.644H25.452V19.144H8.78296V20.644ZM34.4557 11.6403V5.83335H32.9557V11.6403H34.4557ZM12.6819 5.08335H1.29395V6.58335H12.6819V5.08335ZM13.4319 5.83335V3.6479H11.9319V5.83335H13.4319ZM15.3298 1.75H19.2319V0.25H15.3298V1.75ZM33.7057 5.08335H21.8798V6.58335H33.7057V5.08335ZM21.8798 5.08335H12.6819V6.58335H21.8798V5.08335ZM21.1298 3.6479V5.83335H22.6298V3.6479H21.1298ZM19.2319 1.75C20.2801 1.75 21.1298 2.59972 21.1298 3.6479H22.6298C22.6298 1.77129 21.1085 0.25 19.2319 0.25V1.75ZM32.3779 17.3935C33.7207 15.777 34.4557 13.7418 34.4557 11.6403H32.9557C32.9557 13.3917 32.3431 15.0879 31.224 16.435L32.3779 17.3935ZM25.452 20.644C28.1291 20.644 30.6673 19.4527 32.3779 17.3935L31.224 16.435C29.7984 18.1512 27.6831 19.144 25.452 19.144V20.644ZM2.94782 18.2216C4.49368 19.7724 6.59329 20.644 8.78296 20.644V19.144C6.99194 19.144 5.27459 18.4311 4.01017 17.1626L2.94782 18.2216ZM13.4319 3.6479C13.4319 2.59972 14.2816 1.75 15.3298 1.75V0.25C13.4532 0.25 11.9319 1.77129 11.9319 3.6479H13.4319ZM0.543945 12.405C0.543945 14.5856 1.40838 16.6772 2.94782 18.2216L4.01017 17.1626C2.751 15.8994 2.04395 14.1886 2.04395 12.405H0.543945Z"
        fill="#cbcbd1"
      />
      <ellipse cx="10.4922" cy="19.4547" rx="3.06598" ry="3.07577" fill="white" stroke="#cbcbd1" strokeWidth="1.5" />
      <ellipse cx="24.5074" cy="19.4547" rx="3.06598" ry="3.07577" fill="white" stroke="#cbcbd1" strokeWidth="1.5" />
    </svg>
  );
};
