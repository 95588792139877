import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ControlWrapper } from '../../../../../components';
import OTPInput from '../../../../../components/otp-input';
import { useGate } from '../../../../../hooks/use-gate';
import { ChannelInterface } from '../../../../../types';
import { ChannelGateInterface } from '../../../../../types/channel/gate';
import { useGateSupportedFeatures } from '../../hooks/use-gate-supported';
import { ElectricalStrike } from './electrical-strike';
import './index.scss';
import { GateKindsControl } from './kinds';

interface PropsInterface {
  channel: ChannelInterface;
}

const isBlockedByPin = false;

export const GateStepByStep: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [quickControl, setQuickControl] = useState<boolean>(false);
  const [unblocked, setUnblocked] = useState<boolean>(false);
  const [pinVisible, setPinVisible] = useState<boolean>(false);
  const { handleStepByStep, handleSetElectricStrikeState, handleQuickControl } = useGate(channel);
  const { stepByStepSupported, electricStrikeSupported } = useGateSupportedFeatures(channel);

  useEffect(() => {
    if (channel) setQuickControl((channel.data as ChannelGateInterface).isQuickControl);
  }, [(channel?.data as ChannelGateInterface)?.isQuickControl]);

  const gateKind = useMemo(
    () => (channel.data as ChannelGateInterface).gateKind,
    [(channel.data as ChannelGateInterface).gateKind],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuickControl(e.target.checked);
    handleQuickControl(e.target.checked);
  };

  const handleAction = () => {
    if (!isBlockedByPin) {
      handleStepByStep();
    } else {
      if (!unblocked) setPinVisible(true);
      else handleStepByStep();
    }
  };

  const handlePinAction = (pin: string) => {
    if (pin.length === 4) {
      handleStepByStep();
      setPinVisible(false);
      setUnblocked(true);
    }
  };

  return (
    <>
      {!pinVisible ? (
        <>
          <ControlWrapper>
            {stepByStepSupported && (
              <GateKindsControl gateKind={gateKind} channel={channel} handleAction={handleAction} />
            )}
          </ControlWrapper>
          <div className="p-l-24 p-r-24 m-t-24">
            <Checkbox checked={quickControl} onChange={handleChange} reverse>
              <p style={{ fontSize: 16, marginLeft: 12 }}>{t('quickControl')}</p>
            </Checkbox>
            {electricStrikeSupported && (
              <ElectricalStrike channelId={channel.id} handleChange={handleSetElectricStrikeState} />
            )}
          </div>
        </>
      ) : (
        <div className="otp-container">
          <OTPInput onAction={handlePinAction} />
        </div>
      )}
    </>
  );
};
