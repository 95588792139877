import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISequence } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { DialogConfirmation, EmptyStatePage, Header, NavHeader, Page } from '../../../../../components';
import FixedTwoButtons from '../../../../../components/fixed-two-buttons';
import { StatusTypeEnum } from '../../../../../types';
import { useExalusChannels } from '../../../hooks';
import ConditionItem from '../components/condition-item';
import { useExalusCreateActionContext } from '../context';
import { useActionConditions } from '../hooks/use-action-conditions';
import './index.scss';

const ExalusActionCreateConditions: React.FC = () => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { conditionList } = useExalusCreateActionContext();
  const { data: channels } = useExalusChannels();
  const { dialogOpen, addNewCondition, handleNextStep, handleDialogClose } = useActionConditions();

  const conditions = useMemo(
    () =>
      conditionList.map((seq: ISequence, index: number) => (
        <div key={seq.Guid} className="exalus-condition-item">
          <ConditionItem seq={seq} channels={channels || []} canBeRemoved={index !== 0} />
          {index !== conditionList.length - 1 && <p className="or-label">{t('action.create.conditions.andThen')}</p>}
        </div>
      )),
    [conditionList, channels],
  );

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.conditions.header')} isUnderline />
        </>
      }
    >
      <>
        {conditionList.length ? (
          <div className="action-conditions">{conditions}</div>
        ) : (
          <EmptyStatePage
            type={StatusTypeEnum.INTEGRATION}
            caption={t('action.create.conditions.conditionList.empty')}
          />
        )}
        <FixedTwoButtons
          titlePrimary={t('action.create.conditions.conditionList.button')}
          titleSecondary={tc('buttons.continue')}
          handleClickPrimary={addNewCondition}
          handleClickSecondary={handleNextStep}
          disabledSecondary={!conditionList.length}
        />
      </>
      <DialogConfirmation
        show={dialogOpen}
        setShow={handleDialogClose}
        header={t('action.create.conditions.noConditionsDialog.header')}
        content={t('action.create.conditions.noConditionsDialog.content')}
        primaryBtnText={tc('buttons.close')}
        primaryBtnAction={handleDialogClose}
      />
    </Page>
  );
};

export default ExalusActionCreateConditions;
