import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, IconAdd, NavHeader, Page, Tabs } from '../../../../components';
import { Tip } from '../../../../components/tip';
import { LearningScenarios } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import ExalusDevices from '../../components/devices';
import { useExalusStorageContext } from '../../context/storage';
import { useLoggedUser } from '../../hooks/common/logged-user';
import { useDevices } from './hooks/use-devices';

const DeviceList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-list');
  const { selectedInstallation } = useInstallation();
  const { groupedDevices, isFetched } = useDevices();
  const { activeDeviceTabIndex, setActiveDeviceTabIndex } = useExalusStorageContext();
  const { canAddRemoveConfigureDevice } = useLoggedUser();

  return (
    <Page
      isStickyHeader
      containerClassName="p-t-32"
      header={
        <>
          <NavHeader>
            {canAddRemoveConfigureDevice && (
              <button onClick={() => history.push(ROUTES.AddExalusDevices())}>
                <IconAdd />
              </button>
            )}
          </NavHeader>
          <Header title={t('devices')} />
          {selectedInstallation && (
            <Tabs
              tabList={groupedDevices}
              activeTabIndex={activeDeviceTabIndex}
              setActiveTabIndex={setActiveDeviceTabIndex}
              tabsType="pills"
              moreButton="normal"
              moreListFullHeight
            />
          )}
        </>
      }
    >
      <ExalusDevices itemGroupsList={groupedDevices} isFetched={isFetched} />
      <Tip tip={t('DeviceAddTip')} learningKey={LearningScenarios.AddDevice} point={<IconAdd />} top={26} right={20} />
    </Page>
  );
};

export default DeviceList;
