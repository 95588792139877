import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { AuthorizeControllerBody } from '../../exalus/types/auth.types';

export const SET_INSTALLATION_NAME = 'SET_INSTALLATION_NAME';
export const SET_ICON_NAME = 'SET_ICON_NAME';
export const SET_TIME_ZONE = 'SET_TIME_ZONE';
export const SET_LOCATION_LAT = 'SET_LOCATION_LAT';
export const SET_LOCATION_LONG = 'SET_LOCATION_LONG';
export const SET_HEX_COLOR = 'SET_HEX_COLOR';
export const SET_INTEGRATION_TYPE = 'SET_INTEGRATION_TYPE';
export const SET_EXALUS_CREDENTIALS = 'SET_EXALUS_CREDENTIALS';
export const SET_CREATE_LOADING = 'SET_CREATE_LOADING';
export const RESET_INSTALLATION_CREATE = 'RESET_INSTALLATION_CREATE';

export type SetInstallationNameAction = {
  type: typeof SET_INSTALLATION_NAME;
  payload: string;
};

export type SetIconNameAction = {
  type: typeof SET_ICON_NAME;
  payload: string;
};

export type SetTimeZoneAction = {
  type: typeof SET_TIME_ZONE;
  payload: string;
};

export type SetLocationLatAction = {
  type: typeof SET_LOCATION_LAT;
  payload: number | null;
};

export type SetLocationLongAction = {
  type: typeof SET_LOCATION_LONG;
  payload: number | null;
};

export type SetHexColorAction = {
  type: typeof SET_HEX_COLOR;
  payload: string;
};

export type SetIntegrationType = {
  type: typeof SET_INTEGRATION_TYPE;
  payload: IntegrationType;
};

export type SetExalusCredentials = {
  type: typeof SET_EXALUS_CREDENTIALS;
  payload: AuthorizeControllerBody;
};

export type SetCreateLoadingAction = {
  type: typeof SET_CREATE_LOADING;
  payload: boolean;
};

export type ResetInstallationCreateAction = {
  type: typeof RESET_INSTALLATION_CREATE;
};
