import React from 'react';
import './index.scss';

export const IconInitiate: React.FC = () => {
  return (
    <svg
      className="icon-initiate"
      stroke="#CBCBD1"
      strokeWidth="2.85798"
      strokeLinecap="round"
      strokeLinejoin="round"
      width="42"
      height="46"
      viewBox="0 0 42 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.2308 11.8538C36.8476 14.4714 38.6294 17.8061 39.3511 21.4363C40.0727 25.0665 39.7017 28.8292 38.285 32.2486C36.8683 35.668 34.4696 38.5905 31.392 40.6467C28.3145 42.7029 24.6963 43.8004 20.9951 43.8004C17.2938 43.8004 13.6757 42.7029 10.5981 40.6467C7.52056 38.5905 5.12179 35.668 3.70511 32.2486C2.28842 28.8292 1.91744 25.0665 2.63908 21.4363C3.36071 17.8061 5.14255 14.4714 7.7593 11.8538"
        vectorEffect="non-scaling-stroke"
        stroke="#CBCBD1"
      />
      <path d="M21.006 2.20386V22.9985" stroke="#CBCBD1" />
    </svg>
  );
};
