import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputSwitcher } from '../../../../components';
import { useChatSettings } from '../../hooks/use-chat-settings';

export const ReadingChatSettings: React.FC = () => {
  const { t } = useTranslation('profile');
  const { options, defaultOptionIndex, handleChange } = useChatSettings();

  return (
    <InputSwitcher
      options={options}
      defaultOptionIndex={defaultOptionIndex}
      label={t('reading-chat.label')}
      onChange={(inputElement) => handleChange(inputElement.value)}
    />
  );
};
