export const compareVersions = (version1: string, version2: string) => {
  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  const length = Math.max(v1.length, v2.length);

  for (let i = 0; i < length; i++) {
    const num1 = v1[i] || 0;
    const num2 = v2[i] || 0;

    if (num1 > num2) return 1; // version1 is newest
    if (num1 < num2) return -1; // version2 is newest
  }

  return 0; // are equal
};
