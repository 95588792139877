import { useTranslation } from 'react-i18next';
import { InstallationError, InstallationErrorDictionary } from '../../data-access/gql-types/graphql';
import { toastError } from '../../utils/toast';

export const useInstallationErrors = () => {
  const { t: tr } = useTranslation('errors');

  const handleErrors = (errors: InstallationError[]) => {
    if (errors.length > 0) {
      const errorItem = errors[0];

      switch (errorItem.error) {
        case InstallationErrorDictionary.Undefined: {
          toastError({
            content: tr(`InstallationErrorDictionary.${InstallationErrorDictionary.Undefined}`),
          });
          break;
        }
        case InstallationErrorDictionary.UserDoesNotHavePermission: {
          toastError({
            content: tr(`InstallationErrorDictionary.${InstallationErrorDictionary.UserDoesNotHavePermission}`),
          });
          break;
        }
        case InstallationErrorDictionary.UserInstallationDoesNotExist: {
          toastError({
            content: tr(`InstallationErrorDictionary.${InstallationErrorDictionary.UserInstallationDoesNotExist}`),
          });
          break;
        }
        default: {
          toastError({ content: errorItem.message });
          break;
        }
      }
    }
  };

  return {
    handleErrors,
  };
};
