import React from 'react';

export const IconGuitar: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="18"
      height="39"
      viewBox="0 0 18 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99982 1.5H8.49982H9.27246C10.377 1.5 11.2725 2.39543 11.2725 3.5V6.75C11.2725 7.16421 10.9367 7.5 10.5225 7.5C10.1082 7.5 9.77246 7.83579 9.77246 8.25V16.5C12.4391 16.6667 16.9725 18.6 13.7725 25C16.6058 28.8333 19.6725 36.7 9.2725 37.5H7.99978C-2.40022 36.7 0.666476 28.8333 3.49982 25C0.299821 18.6 4.83315 16.6667 7.49982 16.5V8.25C7.49982 7.83579 7.16403 7.5 6.74982 7.5C6.33561 7.5 5.99982 7.16421 5.99982 6.75V3.5C5.99982 2.39543 6.89525 1.5 7.99982 1.5Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.25 24C11.25 25.5188 10.0188 26.75 8.5 26.75C6.98122 26.75 5.75 25.5188 5.75 24C5.75 22.4812 6.98122 21.25 8.5 21.25C10.0188 21.25 11.25 22.4812 11.25 24Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path d="M5 32H12" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
