import React from 'react';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { ChannelInterface, ChannelLightStateInterface } from '../../../../types';

interface PropsInterface {
  channel: ChannelInterface;
}

export const ChannelBoxPreName: React.FC<PropsInterface> = ({ channel }) => {
  const { channelState } = useChannelsState();

  if (channel.data.type === ChannelTypeInternal.Light) {
    const color = `${(channelState[channel.id] as ChannelLightStateInterface)?.color?.r}, ${
      (channelState[channel.id] as ChannelLightStateInterface)?.color?.g
    }, ${(channelState[channel.id] as ChannelLightStateInterface)?.color?.b}`;

    if (!color) return null;

    return <span className="rgb-value" style={{ backgroundColor: `rgb(${color})` }}></span>;
  }

  return null;
};
