import { useQuery, UseQueryResult } from 'react-query';
import { Api } from 'lavva.exalushome';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { INetworkData, INetworkService } from 'lavva.exalushome/build/js/INetworkService';
import { useExalusContext } from '../context';

export const useExalusNetworkSettings = (): UseQueryResult<INetworkData | null> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();

  return useQuery<INetworkData | null>(
    ['exalus-network-settings', selectedExalusCredentials?.id, synchronized],
    async () => {
      const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
      return await networkService.GetIpConfigurationAsync();
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusDNSAddresses = (): UseQueryResult<string[] | null> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();

  return useQuery<string[] | null>(
    ['exalus-dns-addresses', selectedExalusCredentials?.id, synchronized],
    async () => {
      const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
      return await networkService.GetDnsServersAsync();
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};
