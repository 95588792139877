import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, NavHeader, Page } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { ActionType } from '../../../../../../action-create/types';
import { CentralActionDetailsChannels } from '../../../../../components/central-action/details-channels';
import { useExalusCreateActionContext } from '../../../context';
import { TaskVariant } from '../../../types';
import { BlindSettings } from './blind';
import { DimSettings } from './dim';
import { FacadeSettings } from './facade';
import './index.scss';
import { MicroventilationSettings } from './microventilation';
import { OnOffSettings } from './on-off';
import { RGBWSettings } from './rgbw';

const ExalusActionChannelSettings: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { actionType, channels, taskVariant } = useExalusCreateActionContext();

  useEffect(() => {
    if (actionType === ActionType.Undefined) history.replace(ROUTES.ActionList());
  }, []);

  const parametersView = useMemo(() => {
    switch (taskVariant) {
      case TaskVariant.OnOff:
        return <OnOffSettings />;
      case TaskVariant.SetFacade:
        return <FacadeSettings />;
      case TaskVariant.SetMicrowave:
        return <MicroventilationSettings />;
      case TaskVariant.SetRGBW:
        return <RGBWSettings />;
      case TaskVariant.SetBlind:
        return <BlindSettings />;
      case TaskVariant.Dim:
        return <DimSettings />;
      default:
        return null;
    }
  }, [taskVariant]);

  return (
    <Page
      isStickyHeader
      className="parameter-settings"
      header={
        <>
          <NavHeader />
          <Header title={t(`action.create.tasks.variants.${taskVariant}`)} />
        </>
      }
    >
      <div className="details-page">{parametersView}</div>
      <CentralActionDetailsChannels channels={channels} />
    </Page>
  );
};

export default ExalusActionChannelSettings;
