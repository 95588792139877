import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { ComfortPositionParameters } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { ISrp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp22ConfigService';
import { ISrp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp30ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

interface ComponentProps {
  device: IDevice;
  comfort: ComfortPositionParameters | undefined;
  channel: IDeviceChannel;
}

const ComfortForm: React.FC<ComponentProps> = ({ device, comfort, channel }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { configService: serviceName, handleSubmitResponse } = useConfigurationService(device);
  const form = useForm<ComfortPositionParameters>();

  useEffect(() => {
    if (comfort !== undefined) {
      form.setValue('Position1', comfort.Position1);
      form.setValue('Position2', comfort.Position2);
    }
  }, [comfort]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
      ISrp30ConfigService | ISrp22ConfigService
    >(serviceName);

    const result = await configService.SetComfortPositionAsync(device, {
      Position1: values.Position1,
      Position2: values.Position2,
      Channel: channel.Number,
    });
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="Position1"
          label={t('exalus.params.SrpParams.Position1')}
          placeholder={t('exalus.params.SrpParams.Position1')}
          inputType="number"
          min={0}
          max={100}
        />
        <ConfigurationInput
          name="Position2"
          label={t('exalus.params.SrpParams.Position2')}
          placeholder={t('exalus.params.SrpParams.Position2')}
          inputType="number"
          min={0}
          max={100}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default ComfortForm;
