import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetMeterAmpTransformers } from '../../../../../api/modules/meter/meter.hooks';
import { AmpTransformValueRequest } from '../../../../../api/modules/meter/meter.types';
import { InputSelect } from '../../../../../components';
import { AmpTransformersInternal } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../hooks';
import { useChannelDetailsContext } from '../../../../../hooks/channel-details';
import { ChannelMeterInterface } from '../../../../../types';
import Info from '../info';

interface ComponentProps {
  setTimeoutError: () => void;
}

const AmpTransformers: React.FC<ComponentProps> = ({ setTimeoutError }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelId: string }>();
  const { channel } = useChannelDetailsContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate } = useSetMeterAmpTransformers();

  const ampTransformers = useMemo(
    () => (channel?.data as ChannelMeterInterface).ampTransformers,
    [(channel?.data as ChannelMeterInterface).ampTransformers],
  );

  const onChangeAmpTransformers = (value: AmpTransformersInternal) => {
    if (ampTransformers !== value) {
      turnOnBackdrop();

      mutate(
        { channelId, value: AmpTransformValueRequest[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  return (
    <>
      <Info title={t('ampTransformers.ampTransormer')} />
      <InputSelect
        value={ampTransformers}
        onChange={onChangeAmpTransformers}
        options={[
          { label: t('ampTransformers.oneHundredAmps'), value: AmpTransformersInternal.OneHundredAmps },
          { label: t('ampTransformers.twoHundredsAmps'), value: AmpTransformersInternal.TwoHundredsAmps },
          { label: t('ampTransformers.fourHundredsAmps'), value: AmpTransformersInternal.FourHundredsAmps },
          { label: t('ampTransformers.oneThousandAmps'), value: AmpTransformersInternal.OneThousandAmps },
        ]}
        label={''}
      />
    </>
  );
};

export default AmpTransformers;
