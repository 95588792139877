import { PointerEvent } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindActionEnum,
  SetBlindMicroventilation,
  SetBlindPosition,
} from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useBackdropContext, useNative } from '../../../../../hooks';
import { CoverControlHook } from '../types';

export const useBlindControl = (channel?: IDeviceChannel, loader?: boolean): CoverControlHook => {
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { fireVibration } = useNative();

  const open = async () => {
    if (loader) turnOnBackdrop();
    const task = new SetBlindPosition();
    task.Action = BlindActionEnum.Open;
    fireVibration();
    await channel?.ExecuteTaskAsync(task);

    if (loader) turnOffBackdrop();
  };

  const close = async () => {
    if (loader) turnOnBackdrop();

    const task = new SetBlindPosition();
    task.Action = BlindActionEnum.Close;
    fireVibration();
    await channel?.ExecuteTaskAsync(task);

    if (loader) turnOffBackdrop();
  };

  const stop = async () => {
    if (loader) turnOnBackdrop();

    const task = new SetBlindPosition();
    task.Action = BlindActionEnum.Stop;
    fireVibration();
    await channel?.ExecuteTaskAsync(task);

    if (loader) turnOffBackdrop();
  };

  const handleAction = async (action: BlindActionEnum, position?: number) => {
    const task = new SetBlindPosition();
    task.Action = action;
    if (typeof position === 'number' && position >= 0) {
      task.Position = position;
    }
    await channel?.ExecuteTaskAsync(task);
  };

  const handleMicroventilation = async (position: number) => {
    const task = new SetBlindMicroventilation();
    task.Position = position;
    fireVibration();
    await channel?.ExecuteTaskAsync(task);
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    fireVibration();
    handleAction(BlindActionEnum.Percentage, Number((event.target as HTMLInputElement).value));
  };

  return {
    open,
    close,
    stop,
    handleAction,
    handleSliderEvent,
    handleMicroventilation,
  };
};
