import { useTranslation } from 'react-i18next';
import { UserProfileError, UserProfileErrorDictionary } from '../../data-access/gql-types/graphql';
import { toastError } from '../../utils/toast';

export const useUserProfileErrors = () => {
  const { t: tr } = useTranslation('errors');

  const handleErrors = (errors: UserProfileError[]) => {
    if (errors.length > 0) {
      const errorItem = errors[0];

      switch (errorItem.error) {
        case UserProfileErrorDictionary.Undefined: {
          toastError({
            content: tr(`UserProfileErrorDictionary.${UserProfileErrorDictionary.Undefined}`),
          });
          break;
        }
        case UserProfileErrorDictionary.UserDoesNotExist: {
          toastError({
            content: tr(`UserProfileErrorDictionary.${UserProfileErrorDictionary.UserDoesNotExist}`),
          });
          break;
        }
        case UserProfileErrorDictionary.UnsupportedLanguage: {
          toastError({
            content: tr(`UserProfileErrorDictionary.${UserProfileErrorDictionary.UnsupportedLanguage}`),
          });
          break;
        }
        default: {
          toastError({ content: errorItem.message });
          break;
        }
      }
    }
  };

  return {
    handleErrors,
  };
};
