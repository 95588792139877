import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, NavHeader, Page, PopUpNav, Switch } from '../../../../components';
import ActionHeader from '../../../../components/action/action-header';
import { ActionAdvancedIcon } from '../../../../components/action/advanced-icon';
import { IconDelete, IconDuplicate, IconEdit } from '../../../../components/popup-nav/icons';
import { ActionAdvancedIconTypeEnum } from '../../../../types';
import { useExalusCreateActionContext } from '../action-create/context';
import { useActionDetails } from './hooks/use-action-details';
import { useActionDetailsPresentation } from './hooks/use-action-details-presentation';
import './index.scss';

const ExalusActionDetails: React.FC = () => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const {
    data,
    isAction,
    duplicateScenario,
    goToEditScenario,
    deletePopup,
    impossiblePopup,
    checked,
    usedInScenarios,
    setDeletePopup,
    setImpossiblePopup,
    handleDeletePopupClose,
    submitDelete,
    toggleActive,
  } = useActionDetails();
  const { conditionList } = useExalusCreateActionContext();
  const { detailsConditionList, detailsTaskList } = useActionDetailsPresentation();

  const conditionsAvailable = useMemo(
    () => !!conditionList.filter((x) => x.LeftArgumentType !== 'Null').length,
    [conditionList],
  );

  const closeImpossibleDialog = () => setImpossiblePopup(false);

  return (
    <Page
      isStickyHeader
      className="exalus-action-details"
      containerClassName="p-t-0 p-r-0 p-b-0 p-l-0 page__container--flex-height"
      headerClassName="p-t-24 p-r-24 p-b-0 p-l-24"
      contentClassName="page__content--background-element page__content--unset-height p-r-24 p-b-48 p-l-24"
      header={
        <>
          <NavHeader>
            {data && (
              <PopUpNav
                className="popup-nav--reverse"
                links={[
                  { label: t('edit'), onClick: goToEditScenario, icon: <IconEdit /> },
                  { label: t('duplicate'), onClick: duplicateScenario, hasLineBelow: true, icon: <IconDuplicate /> },
                  { label: t('remove'), onClick: () => setDeletePopup(true), icon: <IconDelete /> },
                ]}
                dark
              />
            )}
          </NavHeader>
          <ActionHeader
            actionName={data?.Name || 'N/A'}
            actionType={data ? t(`action.${isAction ? 'action' : 'automation'}`) : ''}
            iconAdvanced={<ActionAdvancedIcon iconName={data?.Icon || ActionAdvancedIconTypeEnum.DEFAULT} />}
            isAdvanced
          />
        </>
      }
    >
      <div className="active-toggle">
        {!isAction && (
          <>
            <p>{t('trigger.active')}</p>
            <Switch checked={checked} onChange={toggleActive} />
          </>
        )}
      </div>
      {!isAction && <hr className="m-b-24" />}
      {conditionsAvailable && (
        <>
          <h2 className="m-b-24">{t('trigger.if')}</h2>
          <div className="action-conditions">{detailsConditionList}</div>
          <hr className="m-b-24" />
          <h2 className="m-b-24">{t('trigger.then')}</h2>
        </>
      )}
      <div className="action-conditions">{detailsTaskList}</div>

      <DialogConfirmation
        show={deletePopup}
        setShow={setDeletePopup}
        header={t('action.delete.deleting')}
        content={t(`action.delete.deleteConfirmation${isAction ? 'Action' : 'Automation'}`)}
        primaryBtnText={t('remove')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={submitDelete}
        secondaryBtnAction={handleDeletePopupClose}
      />
      <DialogConfirmation
        show={impossiblePopup}
        setShow={setImpossiblePopup}
        header={t('action.delete.impossibleHeader')}
        content={t(`action.delete.delete${isAction ? 'Action' : 'Automation'}Impossible`, { names: usedInScenarios })}
        primaryBtnText={tc('buttons.understand')}
        primaryBtnAction={closeImpossibleDialog}
      />
    </Page>
  );
};

export default ExalusActionDetails;
