import React from 'react';

export const IconMeter: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-meter"
  >
    <g clipPath="url(#clip0_24319_29450)">
      <path
        d="M1.86328 9.15954C1.86328 7.88932 2.89094 6.86166 4.16115 6.86166"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3311 9.15954C36.3311 7.88932 35.3034 6.86166 34.0332 6.86166"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.86328 29.8405C1.86328 31.1107 2.89094 32.1383 4.16115 32.1383"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3311 29.8405C36.3311 31.1107 35.3034 32.1383 34.0332 32.1383"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.86328 9.15955V29.8404"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.332 9.15955V29.8404"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0344 32.1383H4.16211"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16211 6.86166H34.0344"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1387 16.0532L29.4365 13.7554"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7354 16.0532L29.4375 13.7554"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1387 22.9468L29.4365 25.2447"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7354 22.9468L29.4375 25.2447"
        stroke="#15141D"
        strokeWidth="1.7234"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96946 25V23.5724L11.7567 19.8597C12.119 19.494 12.4208 19.169 12.6623 18.8849C12.9038 18.6009 13.0849 18.3256 13.2056 18.0593C13.3263 17.793 13.3867 17.5089 13.3867 17.207C13.3867 16.8626 13.3086 16.5678 13.1523 16.3228C12.9961 16.0742 12.7812 15.8825 12.5078 15.7475C12.2344 15.6126 11.9237 15.5451 11.5756 15.5451C11.217 15.5451 10.9027 15.6197 10.6328 15.7688C10.3629 15.9144 10.1534 16.1222 10.0043 16.392C9.85866 16.6619 9.78587 16.9833 9.78587 17.3562H7.90554C7.90554 16.6637 8.06357 16.0618 8.37962 15.5504C8.69567 15.0391 9.13068 14.6431 9.68466 14.3626C10.2422 14.082 10.8814 13.9418 11.6023 13.9418C12.3338 13.9418 12.9766 14.0785 13.5305 14.3519C14.0845 14.6254 14.5142 15 14.8196 15.4759C15.1286 15.9517 15.283 16.495 15.283 17.1058C15.283 17.5142 15.2049 17.9155 15.0487 18.3097C14.8924 18.7038 14.6172 19.1406 14.223 19.62C13.8324 20.0994 13.2837 20.68 12.5771 21.3619L10.6967 23.2741V23.3487H15.4482V25H7.96946ZM21.723 14.0909V25H19.7468V16.0138H19.6829L17.1314 17.6438V15.8327L19.8427 14.0909H21.723Z"
        fill="#15141D"
      />
    </g>
    <defs>
      <clipPath id="clip0_24319_29450">
        <rect width="36.1915" height="27" fill="white" transform="translate(1 6)" />
      </clipPath>
    </defs>
  </svg>
);
