import React from 'react';
import classNames from 'classnames';
import {
  ActionType,
  ChannelTypeInternal,
  DeviceConnectionState,
  LightToggleValueInternal,
  SwitchStateInternal,
  UserAction,
} from '../../../data-access/gql-types/graphql';
import { useAdvancedAction, useChannelsState, useLightAction, useSwitchAction } from '../../../hooks';
import { ChannelInterface, CoverKindParam, DashboardUserActionInterface, GateKindParam } from '../../../types';
import { CoverControlBox, GateControlBox } from '../../channel';
import { IconPlay } from '../../icons';
import { OnOffControl } from '../../on-off-control';
import './index.scss';

interface PropsInterface {
  channels: ChannelInterface[];
  userAction: DashboardUserActionInterface | UserAction;
  isListItem: boolean;
}

export const Control: React.FC<PropsInterface> = ({ channels, userAction, isListItem }) => {
  const { setActionOn } = useAdvancedAction(userAction);
  const { handleControlsOn: switchOn } = useSwitchAction(channels);
  const { handleControlsOn: lightOn } = useLightAction(channels);
  const { channelState } = useChannelsState();

  switch (userAction.action.actionType) {
    case ActionType.Advanced:
      return (
        <button
          onClick={setActionOn}
          className={classNames('action-control__advanced', {
            'action-control__advanced--disabled':
              !channels.length ||
              channels.every(
                (actionChannel) =>
                  channelState[actionChannel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
              ),
          })}
        >
          <IconPlay size={28} />
        </button>
      );
    case ActionType.Central:
    case ActionType.Basic:
      switch (userAction.action.channelType) {
        case ChannelTypeInternal.Blind:
          return (
            <div className="action-control__cover">
              <CoverControlBox channels={channels} isListItem={isListItem} kind={CoverKindParam.Action} />
            </div>
          );
        case ChannelTypeInternal.Gate:
          return (
            <div className="action-control__cover">
              <GateControlBox channels={channels} isListItem={isListItem} kind={GateKindParam.Action} />
            </div>
          );
        case ChannelTypeInternal.Light:
          return (
            <OnOffControl
              actionOn={() => lightOn(LightToggleValueInternal.On)}
              actionOff={() => lightOn(LightToggleValueInternal.Off)}
              isDetailView={false}
            />
          );
        case ChannelTypeInternal.Switch:
          return (
            <OnOffControl
              actionOn={() => switchOn(SwitchStateInternal.On)}
              actionOff={() => switchOn(SwitchStateInternal.Off)}
              isDetailView={false}
            />
          );
        default:
          return null;
      }
    default:
      return (
        <OnOffControl
          actionOn={() => switchOn(SwitchStateInternal.On)}
          actionOff={() => switchOn(SwitchStateInternal.Off)}
          isDetailView={false}
        />
      );
  }
};
