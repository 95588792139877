import {
  GlobalTimeParameters,
  IInputOutputSwitchParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';

export enum RopParamsEnum {
  INPUT_MODE = 'InputModeParams',
  TURN_ON_BEHAVIOUR = 'TurnOnBehaviourParams',
  GLOBAL_TIME = 'GlobalTimeParams',
  ENABLE_TIME = 'EnableTimeParams',
}

export interface RopConfigParams {
  [RopParamsEnum.INPUT_MODE]: IInputOutputSwitchParameters[] | null;
  [RopParamsEnum.TURN_ON_BEHAVIOUR]: StateOfTurnOnSwitchDeviceParameters | null;
  [RopParamsEnum.GLOBAL_TIME]: GlobalTimeParameters | null;
  [RopParamsEnum.ENABLE_TIME]: GlobalTimeParameters | null;
}

export const initialConfig: RopConfigParams = {
  [RopParamsEnum.INPUT_MODE]: null,
  [RopParamsEnum.TURN_ON_BEHAVIOUR]: null,
  [RopParamsEnum.GLOBAL_TIME]: null,
  [RopParamsEnum.ENABLE_TIME]: null,
};
