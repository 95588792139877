import { IconDoor, IconPadlock } from '../../../../../../../components/icons';
import { IconPlug } from '../../../../../../../components/icons-group';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const reedIcons = (position?: number): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconDoor position={position !== undefined ? position : 100} />,
    oldIconName: 'DEVICE_ICON_21',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_PADLOCK,
    component: <IconPadlock isOn={position !== undefined ? position === 100 : true} />,
    oldIconName: 'DEVICE_ICON_4',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_PLUG,
    component: <IconPlug isOn={position !== undefined ? position === 100 : true} />,
    oldIconName: 'DEVICE_ICON_PLUG',
  },
];
