import { useTranslation } from 'react-i18next';
import { ChannelTypeInternal, UserAction } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { ChannelInterface } from '../../../types';

export const useCentralAction = () => {
  const { t } = useTranslation('common');
  const { selectedInstallationId } = useInstallation();
  const hasCentralAction = [ChannelTypeInternal.Switch, ChannelTypeInternal.Blind, ChannelTypeInternal.Light];

  const prepareCentralAction = (type: ChannelTypeInternal, channels: ChannelInterface[]) => {
    return {
      id: type,
      iconName: '',
      action: {
        installationId: selectedInstallationId,
        actionType: 'CENTRAL',
        channelType: type,
        name: t(`typesPlural.${type}`),
        id: type,
        sourceGroupId: '',
        channelActions: channels.map((channel) => ({
          channelId: channel.id,
          channelType: channel.data.type,
          activityType: 'CLIENT_CONTROLLED',
          value: '',
        })),
      },
    } as UserAction;
  };

  const createCentralAction = (type: ChannelTypeInternal, channels: ChannelInterface[]) => {
    if (hasCentralAction.includes(type) && channels.length > 1) {
      return prepareCentralAction(type, channels);
    }
  };

  return {
    createCentralAction,
    hasCentralAction,
  };
};
