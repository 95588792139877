import React from 'react';

export const IconTree: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-tree"
  >
    <path d="M19.5 8.97852V36.9367" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19.5 1C23.4987 1 26.7682 4.27146 26.7682 8.27012V8.57741C29.8431 7.63835 33 9.92874 33 13.1887C33 15.2558 31.6983 17.0194 29.8698 17.7047C31.4864 18.9491 32.5285 20.9036 32.5285 23.1024C32.5285 26.8624 29.4804 29.9106 25.7203 29.9106C23.5616 29.9106 21.6377 28.9047 20.3895 27.3377C19.7978 27.4102 19.2023 27.4102 18.6087 27.3377C17.3623 28.9047 15.4384 29.9106 13.2797 29.9106C9.5177 29.9106 6.46952 26.8624 6.46952 23.1024C6.46952 20.9036 7.51165 18.9491 9.1302 17.7047C7.30169 17.0194 6 15.2558 6 13.1887C6 9.92874 9.15505 7.63835 12.2299 8.57741V8.27012C12.2299 4.27146 15.5013 1 19.5 1Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8721 18.8189L23.0786 15.6123"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9025 22.8448L14.8408 18.7812"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
