import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlindCalibrate } from '../../../../api/modules/blind/blind.hooks';
import { useGateCalibrate } from '../../../../api/modules/gate/gate.hooks';
import { EmptyStateBox, IconWarning } from '../../../../components';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { ChannelCoverStateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../../types';
import './index.scss';

interface PropsInterface {
  channel: ChannelInterface;
}

const CalibrationInfoDetails: React.FC<PropsInterface> = <
  T extends ChannelCoverStateInterface | ChannelGateStateInterface,
>({
  channel,
}) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const calibrationBlind = useBlindCalibrate();
  const calibrationGate = useGateCalibrate();
  const { channelState } = useChannelsState();

  const content = useMemo(() => {
    if ((channelState[channel.id] as T)?.calibrateState?.progress) {
      return `${t('calibrationProgress')} ${(channelState[channel.id] as T)?.calibrateState?.progress}%`;
    } else {
      return t('calibrationNeeded');
    }
  }, [(channelState[channel.id] as T)?.calibrateState]);

  const handleCalibrate = useCallback(() => {
    if (channel) {
      const variables = { deviceId: channel.deviceId, channelId: channel.id };

      if (channel.data.type === ChannelTypeInternal.Blind) {
        calibrationBlind.mutate(variables);
      } else if (channel.data.type === ChannelTypeInternal.Gate) {
        calibrationGate.mutate(variables);
      }
    }
  }, [channel]);

  return (
    <div className="calibration-window p-l-24 p-r-24 m-b-24">
      <EmptyStateBox
        header={tc('status.calibrate')}
        btnText={tc('status.calibrate')}
        content={content}
        icon={<IconWarning />}
        {...(!(channelState[channel.id] as T)?.calibrateState?.progress ? { onClick: handleCalibrate } : {})}
      />
    </div>
  );
};

export default CalibrationInfoDetails;
