import React, { ReactNode } from 'react';
import * as dndCore from '@dnd-kit/core';
import { UniqueIdentifier } from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';

type Props = {
  onDragStart: (event: dndCore.DragStartEvent) => void;
  onDragEnd: (event: dndCore.DragEndEvent) => void;
  items: (
    | UniqueIdentifier
    | {
        id: UniqueIdentifier;
      }
  )[];
  children: ReactNode;
};

export const DragAndDrop: React.FC<Props> = ({ onDragEnd, onDragStart, items, children }) => {
  const dndSensors = dndCore.useSensors(
    dndCore.useSensor(dndCore.PointerSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 10,
      },
    }),
  );

  return (
    <dndCore.DndContext
      sensors={dndSensors}
      collisionDetection={dndCore.closestCorners}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <dndSortable.SortableContext items={items} strategy={dndSortable.rectSortingStrategy}>
        {children}
      </dndSortable.SortableContext>
    </dndCore.DndContext>
  );
};
