import React from 'react';
import './index.scss';

export const IconSearch: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-search"
  >
    <path
      d="M10.9551 19.1116C15.3734 19.1116 18.9551 15.5299 18.9551 11.1116C18.9551 6.69329 15.3734 3.11157 10.9551 3.11157C6.53686 3.11157 2.95514 6.69329 2.95514 11.1116C2.95514 15.5299 6.53686 19.1116 10.9551 19.1116Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9551 21.1115L16.6051 16.7615"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
