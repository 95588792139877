import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { PortosService } from 'lavva.exalushome.portos';
import { IPortos433ConfigService } from 'lavva.exalushome.portos/build/js/Devices/DeviceServices/Portos433Mhz/IPortos433ConfigService';
import { Portos433ConfigService } from 'lavva.exalushome.portos/build/js/Devices/DeviceServices/Portos433Mhz/Portos433ConfigService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconChevron, IconPause } from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useBlindControl } from '../../../../components/channel/hooks/use-blind-control';
import { useHandleDataFrameErrors } from '../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const Config433Form: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleError } = useHandleDataFrameErrors();
  const { open, close, stop } = useBlindControl(channel, true);
  const { DiagnosticButton, DiagnosticResultPopup, firmwareService, UserManualButton } = useConfigurationService(
    device,
    channel,
  );
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const handleProgramming = useCallback(async () => {
    if (channel) {
      turnOnBackdrop();
      const portosService = Api.Get<PortosService>(PortosService.ServiceName);

      const configService = await portosService.GetDeviceServiceByServiceType<IPortos433ConfigService>(
        Portos433ConfigService.ServiceName,
      );

      const result: Status = await configService.SendP2PairingSignalAsync(device, channel.Number);

      if (result !== Status.OK) {
        handleError(result);
      }

      turnOffBackdrop();
    }
  }, [device, channel?.Number]);

  return (
    <>
      {channel ? (
        <>
          <ArrowButton className="m-b-24" title={t('exalus.params.433Params.p2')} onClick={handleProgramming} />
          <ArrowButton
            className="m-b-24"
            title={t('exalus.params.433Params.up')}
            onClick={open}
            arrowIcon={<IconChevron withCircle direction="up" />}
          />
          <ArrowButton
            className="m-b-24"
            title={t('exalus.params.433Params.down')}
            onClick={close}
            arrowIcon={<IconChevron withCircle direction="down" />}
          />
          <ArrowButton
            className="m-b-24"
            title={t('exalus.params.433Params.stop')}
            onClick={stop}
            arrowIcon={<IconPause />}
          />
        </>
      ) : (
        <>
          {firmwareService && VersionDeviceButton}
          {firmwareService && CheckUpdateDeviceButton}
          {DiagnosticButton}
          {UserManualButton}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Config433Form;
