import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconCheckbox } from '../../../../../../components';
import { Dialog } from '../../../../../../components/dialog/base';
import { DayOfWeekInternal } from '../../../../../../data-access/gql-types/graphql';
import './index.scss';
import { useSelectedDays } from '../../../../hooks/use-selected-days';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  selected: DayOfWeekInternal[];
  onSave: (data: DayOfWeekInternal[]) => void;
  setShowRepeatPicker: (show: boolean) => void;
}

export const SelectedDaysDialog: React.FC<Props> = ({ show, setShow, selected, onSave, setShowRepeatPicker }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const [selectedDays, setSelectedDays] = useState<DayOfWeekInternal[]>(selected);
  const { daysOfWeek } = useSelectedDays();

  useEffect(() => {
    setSelectedDays(selected);
  }, [show]);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowRepeatPicker(true);
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;

      if (checked && !selectedDays.includes(value as DayOfWeekInternal)) {
        const values = [...selectedDays, value as DayOfWeekInternal];
        setSelectedDays(values);
      }

      if (!checked && selectedDays.includes(value as DayOfWeekInternal)) {
        const values = selectedDays.filter((v) => v !== value);
        setSelectedDays(values);
      }
    },
    [selectedDays],
  );

  const handleSave = useCallback(() => {
    if (selectedDays.length) {
      onSave(selectedDays);
      setShow(false);
    }
  }, [selectedDays]);

  return (
    <Dialog show={show} setShow={setShow}>
      <div className="selected-days-select">
        <div className="dialog-select">
          <h2 className="dialog-select__header">{t('trigger.conditions.time.repeatingOnSelectedDays')}</h2>
          <ul>
            {daysOfWeek.map((option) => {
              const isSelected = selectedDays.includes(option.value);

              return (
                <li
                  key={option.label}
                  className={classNames('dialog-select__option-item', {
                    'dialog-select__option-item--selected': isSelected,
                  })}
                >
                  <div
                    className={classNames('checkbox checkbox--color-accent checkbox--label-light m-b-0 p-b-0', {
                      'checkbox--full-width': false,
                    })}
                  >
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      id={option.value}
                      checked={isSelected}
                      value={option.value}
                    />
                    <label htmlFor={option.value}>
                      <div className="checkbox__icon">
                        <IconCheckbox isChecked={isSelected} isDisabled={false} />
                      </div>
                      <div className="checkbox__label p-b-24 p-t-24">{option.label}</div>
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="dialog-confirmation__buttons-wrapper">
            <button className="dialog-confirmation__button" onClick={handleClose}>
              {tc('buttons.cancel')}
            </button>
            <button onClick={handleSave} className="dialog-confirmation__button dialog-confirmation__button--primary">
              {tc('buttons.save')}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
