import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { useTemperatureControl } from '../../../hooks/use-temperature-control';
import { SensorControlBox } from '../../../sensor-control-box';

interface ComponentProps {
  channel: IDeviceChannel;
  isListItem?: boolean;
}

const TemperatureControl: React.FC<ComponentProps> = ({ channel, isListItem }) => {
  const { temperature } = useTemperatureControl(channel);

  return (
    <SensorControlBox isListItem={isListItem} value={temperature?.toFixed(1) || '-'} unit={'\u2103'} unitAsDegree />
  );
};

export default TemperatureControl;
