import { gql } from '@apollo/client';

export const INTRODUCE = gql`
  mutation Introduce {
    introduce {
      id
    }
  }
`;

export const INVITATION_ACCEPT = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          error
          message
        }
      }
    }
  }
`;

export const INVITATION_REJECT = gql`
  mutation RejectInvitation($input: RejectInvitationInput!) {
    rejectInvitation(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          error
          message
        }
      }
    }
  }
`;

export const CANCEL_INVITATION = gql`
  mutation CancelInvitation($input: CancelInvitationInput!) {
    cancelInvitation(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          error
          message
        }
      }
    }
  }
`;
