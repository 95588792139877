import React from 'react';

export const IconInstallation11: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M14.9997 34.8335L35.583 34.8335L35.583 19.0001L35.583 11.8752L25.2913 17.4168L25.2913 11.0835L14.208 17.4168"
      stroke="#15141D"
    />
    <path d="M22.1253 23.75H18.167" stroke="#15141D" />
    <path d="M31.6253 23.75H27.667" stroke="#15141D" />
    <path d="M5.50016 3.1665L11.8335 3.1665L15.0002 34.8332H2.3335L5.50016 3.1665Z" stroke="#15141D" />
  </svg>
);
