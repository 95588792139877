import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rgbToHex } from '@mui/material';
import { RgbSceneIconTypeEnum } from '../../../types';
import { RgbSceneFormView } from '../enums';

export interface RgbSceneColor {
  r: number;
  g: number;
  b: number;
  hex?: string;
  brightness?: number;
  swap?: number;
  id: string;
}

interface RgbSceneProviderInterface {
  colors: RgbSceneColor[];
  setColors: (colors: RgbSceneColor[]) => void;
  addColor: (color: RgbSceneColor) => void;
  updateColor: (color: RgbSceneColor) => void;
  name: string;
  setName: (name: string) => void;
  icon: RgbSceneIconTypeEnum;
  setIcon: (name: RgbSceneIconTypeEnum) => void;
  selectedView: RgbSceneFormView;
  setSelectedView: (view: RgbSceneFormView) => void;
  activeColorId: string | null;
  setActiveColorId: (index: string | null) => void;
}

const initialState: RgbSceneProviderInterface = {
  colors: [],
  setColors: () => null,
  addColor: () => null,
  updateColor: () => null,
  name: '',
  setName: () => null,
  icon: RgbSceneIconTypeEnum.DEFAULT,
  setIcon: () => null,
  selectedView: RgbSceneFormView.SET_COLOR,
  setSelectedView: () => null,
  activeColorId: null,
  setActiveColorId: () => null,
};

export const RgbSceneFormContext = createContext<RgbSceneProviderInterface>(initialState);

export const useRgbSceneFormContext = (): RgbSceneProviderInterface => useContext(RgbSceneFormContext);

export const RgbSceneFormContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('channel-details');
  const [colors, setColors] = useState(() => initialState.colors);
  const [name, setName] = useState<string>(initialState.name);
  const [icon, setIcon] = useState<RgbSceneIconTypeEnum>(initialState.icon);
  const [selectedView, setSelectedView] = useState<RgbSceneFormView>(initialState.selectedView);
  const [activeColorId, setActiveColorId] = useState<string | null>(initialState.activeColorId);

  useEffect(() => {
    setName(t('rgbScenes.defaultName'));
  }, []);

  const addColor = (color: RgbSceneColor) => {
    const colorWithHex = {
      ...color,
      hex: rgbToHex(`rgb(${color.r}, ${color.g}, ${color.b})`),
    };
    setColors([...colors, colorWithHex]);
  };

  const updateColor = useCallback(
    (color: RgbSceneColor) => {
      const newColors = [...colors];
      const colorIndex = newColors.findIndex((colorItem) => colorItem.id === color.id);

      if (colorIndex > -1) {
        newColors[colorIndex] = {
          ...color,
          hex: rgbToHex(`rgb(${color.r}, ${color.g}, ${color.b})`),
        };
      }

      setColors(newColors);
    },
    [colors],
  );

  const values: RgbSceneProviderInterface = {
    colors,
    setColors,
    addColor,
    updateColor,
    name,
    setName,
    icon,
    setIcon,
    selectedView,
    setSelectedView,
    activeColorId,
    setActiveColorId,
  };

  return <RgbSceneFormContext.Provider value={values}>{children}</RgbSceneFormContext.Provider>;
};
