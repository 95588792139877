import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from '../../../components';
import InstallationShareConfirmation from '../components/confirmation';
import { useInstallationShare } from '../hooks/use-installation-share';
import SummaryShareInvitation from './components/summary';
import './index.scss';

const ShareSummary: React.FC = () => {
  const { t } = useTranslation('installation');
  const { shareLoading, methods } = useInstallationShare();
  const [confirmationScreen, setConfirmationScreen] = useState<boolean>(false);

  const sendInvitation = () => {
    methods.share(() => setConfirmationScreen(true));
  };

  if (confirmationScreen) return <InstallationShareConfirmation />;

  return (
    <SummaryShareInvitation>
      <SubmitButton type="button" onClick={sendInvitation} tranparent isLoading={shareLoading}>
        {t('share_path.send')}
      </SubmitButton>
    </SummaryShareInvitation>
  );
};

export default ShareSummary;
