import { gql } from '@apollo/client';

export const METER_CURRENT_MEASUREMENTS = gql`
  query MeterCurrentMeasurements($installationId: UUID!, $input: GetCurrentMeasurementsRequestInput!) {
    meterCurrentMeasurements(installationId: $installationId, input: $input) {
      data {
        scalarMeasurements {
          type
          value
        }
        vectorMeasurements {
          type
          index
          value
        }
      }
      error {
        errorCode
        description
      }
    }
  }
`;

export const METER_AGGREGATED_MEASUREMENTS = gql`
  query MeterAggregatedMeasurements($installationId: UUID!, $input: GetAggregateMeasurementsRequestInput!) {
    meterAggregatedMeasurements(installationId: $installationId, input: $input) {
      data {
        aggregateVectorMeasurements {
          type
          range
          values
          index
        }
      }
      error {
        errorCode
        description
      }
    }
  }
`;

export const METER_COMMON_MEASUREMENTS = gql`
  query MeterCommonMeasurements($installationId: UUID!, $input: GetCommonMeasurementsRequestInput!) {
    meterCommonMeasurements(installationId: $installationId, input: $input) {
      data {
        vectorMeasurements {
          type
          value
          index
        }
        scalarMeasurements {
          type
          value
        }
      }
      error {
        errorCode
        description
      }
    }
  }
`;

export const MEASUREMENT_MAP = gql`
  query MeasurementMap {
    measurementMap {
      meter {
        kind
        scalars {
          type
          value
        }
        vectors {
          type
          value
        }
        aggregates {
          type
          value
        }
      }
      optimizer {
        kind
        scalars {
          type
          value
        }
        vectors {
          type
          value
        }
        aggregates {
          type
          value
        }
      }
    }
  }
`;
