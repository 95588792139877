export interface BatchChannel {
  deviceId: string;
  channelId: string;
  $type: TypeOfActionType;
}

export interface SwitchLightOnOffBatchChannel extends BatchChannel {
  value: boolean;
}

export interface SwitchLightSetOnTimeBatchChannel extends BatchChannel {
  timeInMilliseconds: number;
}

export interface CoverPositionBatchChannel extends BatchChannel {
  position: number;
}

export interface CoverDirectionBatchChannel extends BatchChannel {
  direction: number;
}

export interface LightBrightnessBatchChannel extends BatchChannel {
  brightness: number;
}

export interface LightRGBBatchChannel extends BatchChannel {
  r: number;
  g: number;
  b: number;
}

export interface GatePositionBatchChannel extends BatchChannel {
  position: number;
}

export interface GateDirectionBatchChannel extends BatchChannel {
  direction: number;
}

export interface ActionBatchBody {
  controls:
    | BatchChannel[]
    | SwitchLightOnOffBatchChannel[]
    | CoverPositionBatchChannel[]
    | CoverDirectionBatchChannel[]
    | LightBrightnessBatchChannel[]
    | LightRGBBatchChannel[]
    | GatePositionBatchChannel[]
    | GateDirectionBatchChannel[];
}

export enum TypeOfActionType {
  BatchLightToggleOnOffRequest = 'BatchLightToggleOnOffRequest',
  BatchLightSetTemperatureRequest = 'BatchLightSetTemperatureRequest',
  BatchLightSetRgbRequest = 'BatchLightSetRgbRequest',
  BatchLightSetBrightnessRequest = 'BatchLightSetBrightnessRequest',
  BatchLightSetOnTimeRequest = 'BatchLightSetOnTimeRequest',
  BatchSwitchSetOnTimeRequest = 'BatchSwitchSetOnTimeRequest',
  BatchSwitchToggleOnOffRequest = 'BatchSwitchToggleOnOffRequest',
  BatchBlindSetPositionRequest = 'BatchBlindSetPositionRequest',
  BatchBlindSetDirectionRequest = 'BatchBlindSetDirectionRequest',
  BatchGateTiltRequest = 'BatchGateTiltRequest',
  BatchGateSetDirectionRequest = 'BatchGateSetDirectionRequest',
  BatchGateSetPositionRequest = 'BatchGateSetPositionRequest',
  BatchGateStepByStepRequest = 'BatchGateStepByStepRequest',
  BatchBaseRequestConverter = 'BatchBaseRequestConverter',
}
