import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  unknown?: boolean;
}

export const IconNoConnection: React.FC<PropsInterface> = ({ unknown }) => (
  <div className={classNames('icon-no-connection', { unknown: unknown })}>
    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="20.5" width="5" height="9" rx="2.5" stroke="#009EE3" strokeLinecap="round" />
      <rect x="9.5" y="16.5" width="5" height="13" rx="2.5" stroke="#009EE3" strokeLinecap="round" />
      <rect x="17.5" y="12.5" width="5" height="17" rx="2.5" stroke="#009EE3" strokeLinecap="round" />
      <rect x="25.5" y="8.5" width="5" height="21" rx="2.5" stroke="#009EE3" strokeLinecap="round" />
      <rect x="33.5" y="4.5" width="5" height="25" rx="2.5" stroke="#009EE3" strokeLinecap="round" />
      {!unknown && <circle cx="35" cy="26" r="5" fill="#CC0909" />}
      <path
        d="M36.5212 24.6096L33.1937 27.9372"
        stroke="white"
        strokeWidth="0.761719"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5212 27.9373L33.1937 24.6097"
        stroke="white"
        strokeWidth="0.761719"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
