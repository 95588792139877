import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWakeLock } from 'react-screen-wake-lock';
import { CalibrationStatusData } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { FacadeConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../../../../../routes';
import { toastInfo } from '../../../../../../../utils/toast';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useConfigurationService } from '../../../../device-details/hooks/use-configuration-service';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

interface ComponentProps {
  setStep: Dispatch<SetStateAction<number>>;
}

const ZfBidiCalibrationStep2: React.FC<ComponentProps> = ({ setStep }) => {
  const { t: td } = useTranslation('device-add');
  const { channel, history, t, turnOnBackdrop, turnOffBackdrop, getZfBidiConfigService } = useCalibration();
  const { handleError } = useHandleDataFrameErrors();
  const [loading, setLoading] = useState<boolean>(false);
  const [calibrationSuccess, showCalibrationSuccess] = useState<boolean>(false);
  const { handleGetFacadeParamsResponse } = useConfigurationService();
  const { request, release } = useWakeLock();
  usePreventBack(loading ? t('exalus.params.ZfBidiParams.calibrationFlow.noBackMessage') : '');

  useEffect(() => {
    request();

    return () => {
      release();
    };
  }, []);

  const handleClick = useCallback(() => {
    if (channel) {
      setStep(3);
      history.push(`${ROUTES.FacadeCalibration(channel.ChannelId)}?step=3`);
    }
  }, [channel]);

  const handleStart = useCallback(async () => {
    if (channel) {
      setLoading(true);
      const configService = await getZfBidiConfigService();
      const result: CalibrationStatusData | ResponseResult<FacadeConfigurationErrorCode> =
        await configService.RunAutomaticLamelaPositionCalibrationAsync(channel?.GetDevice(), channel.Number);
      handleGetFacadeParamsResponse<CalibrationStatusData>(
        result,
        () => {
          if (
            (result as CalibrationStatusData).IsCurrentPositionKnown &&
            (result as CalibrationStatusData).LamellaTiltCalibration &&
            (result as CalibrationStatusData).TravelTimeCalibration
          ) {
            showCalibrationSuccess(true);
          }
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    }
  }, [channel]);

  const handleStop = useCallback(async () => {
    if (channel) {
      turnOnBackdrop();
      const configService = await getZfBidiConfigService();
      const result: Status = await configService.StopCalibrationAsync(channel?.GetDevice(), channel.Number);

      if (result === Status.OK) {
        setLoading(false);
        toastInfo({ content: t('exalus.params.ZfBidiParams.calibrationFlow.autoCalibrationStop') });
      } else {
        handleError(result);
      }

      turnOffBackdrop();
    }
  }, [channel]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="facade-icon" />}
      <p className="info m-t-40 m-b-40">{t('exalus.params.ZfBidiParams.calibrationFlow.startAutoCalibrate')}</p>
      <button className="button button--secondary m-t-24" onClick={handleStart} disabled={loading}>
        {t('exalus.params.ZfBidiParams.calibrationFlow.start')}
      </button>
      {calibrationSuccess && (
        <p className="success-message">{t('exalus.params.ZfBidiParams.calibrationFlow.autoCalibrationSuccess')}</p>
      )}
      <SubmitButton type="button" onClick={handleClick} tranparent disabled={loading}>
        {t('exalus.params.ZfBidiParams.calibrationFlow.next')}
      </SubmitButton>
      {loading && <CustomBackdrop loading btnTitle={td('exalus.searchStop')} onClick={handleStop} />}
    </Page>
  );
};

export default ZfBidiCalibrationStep2;
