import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { meanBy, sumBy } from 'lodash';
import { IconArrowLink } from '../../../../../../components';
import { WRONG_MEASUREMENT_VALUE } from '../../../../../../const';
import { OptimizerVectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { measurementParametersOptimizer } from '../../../../measurement/utils';
import { OptimizerCurrentDataMap } from '../../types';
import './index.scss';

interface CommonProps {
  optimizerData: Record<OptimizerVectorParameterTypeInternal, OptimizerCurrentDataMap[]> | undefined;
  selectedParameter: OptimizerVectorParameterTypeInternal;
}

interface FullBalanceProps extends CommonProps {
  kind: 'full';
  workMode: string;
  activeTabIndex: number;
  temperature: number | undefined;
}

interface OnlyPowerbalanceProps extends CommonProps {
  kind: 'only-power';
}

type ComponentProps = FullBalanceProps | OnlyPowerbalanceProps;

export const Balance: React.FC<ComponentProps> = (props) => {
  const { kind, optimizerData, selectedParameter } = props;
  const { t } = useTranslation('channel-details');

  const sumPower = useMemo(
    () => (optimizerData ? sumBy(optimizerData[selectedParameter], (x) => x.value) : null),
    [optimizerData, selectedParameter],
  );

  const averageLoad = useMemo(
    () => (optimizerData ? meanBy(optimizerData[selectedParameter], (x) => x.value) : null),
    [optimizerData, selectedParameter],
  );

  const renderPowerBalance = () =>
    sumPower ? (
      <div className={classNames('balance-value', { up: sumPower < 0, start: kind === 'only-power' })}>
        <span>{`${t(`optimizer.configuration.${sumPower < 0 ? 'giving' : 'downloading'}`)}:`}</span>&nbsp;
        <span>{`${Math.abs(sumPower / 1000).toFixed(measurementParametersOptimizer[selectedParameter].fixed)} ${
          measurementParametersOptimizer[selectedParameter].unit
        }`}</span>
        &nbsp;
        <IconArrowLink className="icon-arrow-link--accent" size={22} direction={sumPower < 0 ? 'up' : 'down'} />
      </div>
    ) : null;

  const renderLoadBalance = () =>
    averageLoad !== null ? (
      <div className={classNames('balance-value', { up: true })}>
        <span>{`${t(`optimizer.configuration.average`)}:`}</span>&nbsp;
        <span>{`${averageLoad.toFixed(measurementParametersOptimizer[selectedParameter].fixed)}${
          measurementParametersOptimizer[selectedParameter].unit
        }`}</span>
        &nbsp;
        <IconArrowLink className="icon-arrow-link--accent" size={22} direction="up" />
      </div>
    ) : null;

  return (
    <div className="optimizer-balance">
      {kind === 'full' && (
        <div className="row-container flex-end m-t-24">
          <div>
            <p className="mode">
              {`${t('optimizer.configuration.workMode')}: `}
              <span>{props.workMode || 'N/A'}</span>
            </p>
            <p className="mode temperature">
              {`${t('optimizer.configuration.temperature')}: `}
              <span
                className={classNames('', {
                  blue: props.temperature && props.temperature <= 20,
                  green: props.temperature && props.temperature > 20 && props.temperature <= 50,
                  yellow: props.temperature && props.temperature > 50 && props.temperature <= 70,
                  red: (props.temperature && props.temperature > 70) || props.temperature === WRONG_MEASUREMENT_VALUE,
                })}
              >
                {props.temperature !== WRONG_MEASUREMENT_VALUE
                  ? `${props.temperature !== undefined ? props.temperature.toFixed(1) : '-'} °C`
                  : t('optimizer.configuration.noTemperatureSensor')}
              </span>
            </p>
          </div>
          {props.activeTabIndex === 1 && renderPowerBalance()}
          {props.activeTabIndex === 2 && renderLoadBalance()}
        </div>
      )}
      {kind === 'only-power' && renderPowerBalance()}
    </div>
  );
};
