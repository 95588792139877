import { IconThermometer } from '../../../../../../../components/icons';
import { AvailableIcon } from '../../../../../../../types';

export const temperatureIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconThermometer isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_23',
  },
];
