import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, CustomBackdrop, InputSelect, Summary } from '../../../../components';
import { CheckboxSelect } from '../../../../components/checkbox-select';
import { ChannelInterface } from '../../../../types';
import { PhasesButtonGroup } from '../../measurement/components/phases-button-group';
import { AnalysisChart } from './components/chart';
import { RangeHeader } from './components/chart/range-header';
import { useMeterAnalysis } from './hooks/use-meter-analysis';
import { getDataByRange } from './utils';

interface PropsInterface {
  channel: ChannelInterface;
}

export const MeterAnalysis: React.FC<PropsInterface> = ({ channel }) => {
  const { t: tch } = useTranslation('channel-details');
  const { t, i18n } = useTranslation('common');
  const {
    data,
    isLoading,
    setSelectedPhases,
    mappedChartData,
    onClickActiveMeasurementRange,
    aggregatedParameters,
    activeMeasurementRange,
    page,
    setPage,
    timeRanges,
    handleSetAggregatedParameters,
    isStandalone,
  } = useMeterAnalysis({ channel });

  const chartData = useMemo(
    () => getDataByRange(activeMeasurementRange, mappedChartData, page, i18n.language),
    [activeMeasurementRange, mappedChartData, page, i18n.language],
  );

  const parametersAvailable = useMemo(
    () => aggregatedParameters.filter((x) => !x.disabled).length > 0,
    [aggregatedParameters],
  );

  const selectedParameters = useMemo(
    () => aggregatedParameters.filter((x) => !x.disabled && x.checked),
    [aggregatedParameters],
  );

  return (
    <>
      <ControlWrapper className="control-wrapper--column  control-wrapper--reverse p-l-24 p-r-24 p-b-16">
        {parametersAvailable ? (
          <div className="grid-list-24">
            <CheckboxSelect
              options={aggregatedParameters}
              onSave={handleSetAggregatedParameters}
              className="input--reverse"
              multipleLabel={t('parameters')}
            />
            <InputSelect
              options={timeRanges}
              value={activeMeasurementRange}
              onChange={onClickActiveMeasurementRange}
              className="input--reverse"
              label={''}
            />
          </div>
        ) : null}
        <RangeHeader
          prevAvailable={chartData.prevAvailable}
          nextAvailable={chartData.nextAvailable}
          range={chartData.range}
          setPage={setPage}
        />
        <AnalysisChart
          data={chartData.result}
          mappedChartData={mappedChartData}
          activeMeasurementRange={activeMeasurementRange}
          isLoading={isLoading}
        />
        {selectedParameters.length === 1 ? (
          <PhasesButtonGroup
            onSelectedPhases={setSelectedPhases}
            defaultPhases={[0, 1, 2, ...(isStandalone ? [3] : [])]}
            itemPrefix={tch(isStandalone ? 'phase' : 'channel')}
          />
        ) : null}
      </ControlWrapper>
      <Summary summaryData={chartData.result} activeMeasurementRange={activeMeasurementRange} />
      <CustomBackdrop loading={isLoading && !data} />
    </>
  );
};
