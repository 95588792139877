import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../../components';

interface ComponentProps {
  name: string;
  label: string;
  setDisabled?: Dispatch<SetStateAction<boolean>>;
  errorMessage?: string;
  inputType?: string;
  theSame?: string;
  required?: boolean;
}

const FormInput: React.FC<ComponentProps> = ({ name, label, errorMessage, inputType, theSame, required }) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...(required
          ? {
              required: {
                value: true,
                message: t('isRequired'),
              },
            }
          : {}),
        ...(theSame
          ? {
              validate: {
                theSame: (value: string) => value === theSame || t('errors.theSamePasswords'),
              },
            }
          : {}),
      }}
      render={({ field, fieldState: { error } }) => (
        <Input
          defaultValue={field.value}
          onChange={field.onChange}
          label={label}
          {...(inputType ? { inputType } : {})}
          {...(error ? { errorMessage: error.message } : {})}
          {...(errorMessage ? { errorMessage } : {})}
        />
      )}
    />
  );
};

export default FormInput;
