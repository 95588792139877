import React from 'react';

export const IconExalusFlushMountedFacadeController: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.580078" y="0.579102" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23191_242810)">
      <path
        d="M44.1865 14.4697H11.9551V17.5891H44.1865V14.4697Z"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4172 21.6953C15.4172 21.6953 14.3794 22.2721 14.0332 23.4258H18.7536H42.3981C42.052 22.2721 41.0142 21.6953 41.0142 21.6953"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4172 28.3271C15.4172 28.3271 14.3794 28.8789 14.0332 29.9824H18.7536H42.3981C42.052 28.8789 41.0142 28.3271 41.0142 28.3271"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4172 34.8838C15.4172 34.8838 14.3794 35.4355 14.0332 36.539H18.7536H42.3981C42.052 35.4355 41.0142 34.8838 41.0142 34.8838"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4172 41.4404C15.4172 41.4404 14.3794 41.9671 14.0332 43.0204H18.7536H42.3981C42.052 41.9671 41.0142 41.4404 41.0142 41.4404"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7344 17.5889V19.6685"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7344 17.5889V42.9695"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.7344 17.5889V42.7979"
        stroke="#15141D"
        strokeWidth="1.62857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="26.8613" y="26" width="19.7188" height="19.7188" rx="2.07566" fill="white" />
      <path
        d="M30.528 43.6426L42.8849 43.6426C43.762 43.6426 44.4736 42.9326 44.4754 42.0555L44.5016 29.6691C44.5034 28.7894 43.7908 28.0752 42.9111 28.0752L36.6865 28.0752L30.528 28.0752C29.6496 28.0752 28.9375 28.7873 28.9375 29.6657L28.9375 42.0521C28.9375 42.9305 29.6496 43.6426 30.528 43.6426Z"
        fill="white"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect width="9.34046" height="0.778372" transform="matrix(0 1 -1 0 32.7949 31.1885)" fill="white" />
      <circle
        cx="32.3531"
        cy="31.6303"
        r="1.0918"
        transform="rotate(90 32.3531 31.6303)"
        stroke="#15141D"
        strokeWidth="1.3"
      />
      <circle
        cx="32.3531"
        cy="36.0922"
        r="1.0918"
        transform="rotate(90 32.3531 36.0922)"
        stroke="#15141D"
        strokeWidth="1.3"
      />
      <circle
        cx="32.3531"
        cy="40.5541"
        r="1.0918"
        transform="rotate(90 32.3531 40.5541)"
        stroke="#15141D"
        strokeWidth="1.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_23191_242810">
        <rect width="38" height="38" fill="white" transform="translate(9.58008 9.5791)" />
      </clipPath>
    </defs>
  </svg>
);
