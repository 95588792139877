import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNative } from '../../../hooks';
import { useErrors } from '../../../hooks/use-errors';
import { useRestClient } from '../../clients/rest-client';
import { DeviceResponse } from '../device/device.types';
import {
  LightBrightnessBody,
  LightReturnBehaviourBody,
  LightRgbBody,
  LightSetOnTimeBody,
  LightTemperatureBody,
  LightToggleBody,
} from './light.types';

export const useLightToggleOn = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, LightToggleBody> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, LightToggleBody>(
    ['light-toggleOn'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/light/toggleOn', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useLightToggleOff = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, LightToggleBody> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, LightToggleBody>(
    ['light-toggleOff'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/light/toggleOff', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useLightSetOnTime = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  LightSetOnTimeBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, LightSetOnTimeBody>(
    ['light-setOnTime'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/light/setOnTime', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useLightSetBrightness = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  LightBrightnessBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, LightBrightnessBody>(
    ['light-setBrightness'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/light/setBrightness', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useLightSetTemperature = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  LightTemperatureBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, LightTemperatureBody>(
    ['light-setTemperature'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/light/setTemperature', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useLightSetRgb = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, LightRgbBody> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, LightRgbBody>(
    ['light-setRgb'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/light/setRgb', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useLightSupplyReturnBehaviour = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  LightReturnBehaviourBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, LightReturnBehaviourBody>(
    ['light-supplyReturnBehavior'],
    async (body) => await restClient.post<DeviceResponse>('/light/supplyReturnBehavior', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
