import React from 'react';

export const IconPdf: React.FC = () => (
  <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.3183 0C0.991841 0 0 1.0744 0 2.4V29.6C0 30.9256 0.991841 32 2.3183 32H23.5152C24.8417 32 26 30.9256 26 29.6V5.6L20.3964 0H2.3183Z"
      fill="#F65A63"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2505 8.05563C13.0867 8.09083 12.7819 8.15643 12.7624 8.94843C12.7442 9.69083 12.986 10.714 13.387 11.8316C13.6685 10.1636 13.7335 8.82683 13.4865 8.21083C13.4215 8.04683 13.3753 8.02843 13.2505 8.05563ZM13.6782 15.0444C13.3623 16.3028 12.9775 17.5996 12.5511 18.842C13.4533 18.3892 14.3692 17.9948 15.2324 17.6932C14.687 16.8924 14.1553 15.9844 13.6782 15.0444ZM17.0667 18.3684C17.5438 18.8988 17.9923 19.2604 18.368 19.37C18.6436 19.4492 18.7788 19.366 18.9049 19.0332C19.1096 18.4924 18.9848 18.4148 18.9107 18.3684C18.576 18.1596 17.9123 18.1804 17.0667 18.3684ZM10.5602 21.2644C9.06388 22.226 7.85033 23.2796 7.43238 24.2084C7.26078 24.5908 7.30238 24.7764 7.60983 25.0036C7.86138 25.19 8.22343 25.022 8.68233 24.4996C9.30958 23.7892 9.95308 22.6388 10.5602 21.2644ZM7.82628 26.282C7.53768 26.282 7.29783 26.1724 7.10868 26.0324C6.35143 25.4732 6.15383 24.574 6.57958 23.6276C7.16133 22.334 9.07038 20.7972 11.2641 19.5356C11.9739 17.6468 12.5953 15.546 13.0237 13.6516C12.2599 11.8852 11.7575 10.1412 11.788 8.91243C11.8153 7.78763 12.2879 7.04443 13.0828 6.87323C13.6379 6.75563 14.1072 7.04683 14.3607 7.67963C14.7676 8.69403 14.5824 10.9012 14.0474 13.4508C14.6844 14.8556 15.4846 16.27 16.2711 17.3676C17.6237 16.998 18.7541 16.9236 19.3527 17.2988C19.5965 17.4516 20.3472 18.0636 19.7889 19.5396C19.4678 20.3852 18.8698 20.7484 18.1424 20.538C17.5041 20.3508 16.7339 19.6572 15.9545 18.6764C14.7306 19.0732 13.3084 19.6972 11.9726 20.4284C11.1465 22.5596 10.2189 24.3892 9.34273 25.382C8.74603 26.0588 8.23708 26.282 7.82628 26.282Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7295 5.6H25.9999L20.3999 0V3.2704C20.3999 4.596 21.4039 5.6 22.7295 5.6Z"
      fill="#A4222A"
    />
  </svg>
);
