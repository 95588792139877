import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface Props {
  isLarge?: boolean;
  size?: number;
  circle?: boolean;
}

export const IconPlay: React.FC<Props> = ({ isLarge, size = 32, circle = true }) => {
  return (
    <svg
      className={classNames('icon-play', { 'icon-play__large': isLarge })}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#15141D"
    >
      {!isLarge && circle && <circle cx="16" cy="16" r="15.25" stroke="white" vectorEffect="non-scaling-stroke" />}
      <path
        stroke="white"
        d="M3.42857 5.9108C3.42857 3.63363 5.86601 2.18669 7.86513 3.27712L20.6001 10.2235C22.6847 11.3605 22.6847 14.3538 20.6001 15.4909L7.86513 22.4372C5.866 23.5277 3.42857 22.0807 3.42857 19.8036V5.9108Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
