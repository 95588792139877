import { HoursRangeConfig } from '../../../../../api/modules/optimizer/optimizer.types';
import { TariffKindInternal, TimeZoneKindInternal } from '../../../../../data-access/gql-types/graphql';
import { getTimeParts } from '../../../../exalus/pages/action-create/utils/time';
import { HoursRangeFront, PrepareRelaysParams } from '../types';
import { tariffsForRequest } from './tariff-kind';

export const prepareRelaysToSend = ({
  relayConfig1,
  relayConfig2,
  relayConfig3,
  relayConfig4,
}: PrepareRelaysParams) => {
  return [
    {
      enabled: relayConfig1.enabled,
      index: relayConfig1.index,
      thresholdValue: relayConfig1.thresholdValue,
      timeOn: getSecondsByTime(relayConfig1.timeOn),
      timeOff: getSecondsByTime(relayConfig1.timeOff),
    },
    {
      enabled: relayConfig2.enabled,
      index: relayConfig2.index,
      thresholdValue: relayConfig2.thresholdValue,
      timeOn: getSecondsByTime(relayConfig2.timeOn),
      timeOff: getSecondsByTime(relayConfig2.timeOff),
    },
    {
      enabled: relayConfig3.enabled,
      index: relayConfig3.index,
      thresholdValue: relayConfig3.thresholdValue,
      timeOn: getSecondsByTime(relayConfig3.timeOn),
      timeOff: getSecondsByTime(relayConfig3.timeOff),
    },
    ...(relayConfig4
      ? [
          {
            enabled: relayConfig4.enabled,
            index: relayConfig4.index,
            thresholdValue: relayConfig4.thresholdValue,
            timeOn: getSecondsByTime(relayConfig4.timeOn),
            timeOff: getSecondsByTime(relayConfig4.timeOff),
          },
        ]
      : []),
  ];
};

export const prepareWorkingHoursToSend = (from: string, to: string) => {
  return { from: `${from}:00`, to: `${to}:00` };
};

const getSecondsByTime = (time: string) => {
  const timeParts = getTimeParts(time);
  return timeParts.hours * 3600 + timeParts.minutes * 60 + timeParts.seconds;
};

export const timeZoneKindMap = {
  [TimeZoneKindInternal.Unknown]: 0,
  [TimeZoneKindInternal.Summer]: 1,
  [TimeZoneKindInternal.Winter]: 2,
  [TimeZoneKindInternal.All]: 3,
};

export const prepareHoursRangesToSend = (hoursRanges: HoursRangeFront[]): HoursRangeConfig[] => {
  return hoursRanges.map((range) => ({
    from: parseInt(range.from.replace(':00', '')),
    to: parseInt(range.to.replace(':00', '')),
    timeZoneKind: timeZoneKindMap[range.timeZoneKind],
  }));
};

export const prepareOperatorAndTariffForRequest = (operator: string, tariffName: string): number => {
  return (
    tariffsForRequest.find((x) => x.operator === operator.toLowerCase() && x.tariff === tariffName.toLowerCase())
      ?.kind || 0
  );
};

export const getTariffOperatorEnum = (operator: string, tariffName: string): TariffKindInternal | string => {
  return (
    tariffsForRequest.find((x) => x.operator === operator.toLowerCase() && x.tariff === tariffName.toLowerCase())
      ?.enum || ''
  );
};
