import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DayOfWeekInternal } from '../../../data-access/gql-types/graphql';

export const useSelectedDays = () => {
  const { t } = useTranslation('action');

  const daysOfWeek = useMemo(
    () => [
      {
        label: t('trigger.daysOfWeek.monday'),
        value: DayOfWeekInternal.Monday,
        short: t('trigger.daysOfWeekShort.monday'),
      },
      {
        label: t('trigger.daysOfWeek.tuesday'),
        value: DayOfWeekInternal.Tuesday,
        short: t('trigger.daysOfWeekShort.tuesday'),
      },
      {
        label: t('trigger.daysOfWeek.wednesday'),
        value: DayOfWeekInternal.Wednesday,
        short: t('trigger.daysOfWeekShort.wednesday'),
      },
      {
        label: t('trigger.daysOfWeek.thursday'),
        value: DayOfWeekInternal.Thursday,
        short: t('trigger.daysOfWeekShort.thursday'),
      },
      {
        label: t('trigger.daysOfWeek.friday'),
        value: DayOfWeekInternal.Friday,
        short: t('trigger.daysOfWeekShort.friday'),
      },
      {
        label: t('trigger.daysOfWeek.saturday'),
        value: DayOfWeekInternal.Saturday,
        short: t('trigger.daysOfWeekShort.saturday'),
      },
      {
        label: t('trigger.daysOfWeek.sunday'),
        value: DayOfWeekInternal.Sunday,
        short: t('trigger.daysOfWeekShort.sunday'),
      },
    ],
    [],
  );

  return {
    daysOfWeek,
  };
};
