import { IconEntryPhone, IconPhotoCamera, IconSmallCamera } from '../../../../../../../components/icons';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const entryPhoneIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconEntryPhone isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_43',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_PHOTO_CAMERA,
    component: <IconPhotoCamera />,
    oldIconName: 'DEVICE_ICON_42',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_SMALL_CAMERA,
    component: <IconSmallCamera isOn={isOn !== undefined ? isOn : false} />,
    oldIconName: 'DEVICE_ICON_38',
  },
];
