import React from 'react';

export const IconExalusWallMountedGateController: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0273438" y="1" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23186_57535)">
      <g clipPath="url(#clip1_23186_57535)">
        <path
          d="M17.002 20.9238L28.4811 16.2764L28.4811 40.443L17.002 40.443L17.002 20.9238Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.4863 19.2969L22.4863 39.978"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.9609 20.9238L28.4818 16.2764L28.4818 40.443L39.9609 40.443L39.9609 20.9238Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5703 18.6934V40.4434"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0024 27.4297H15.2363"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.727 27.4297H39.9609"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0024 33.9365H15.2363"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.727 33.9365H39.9609"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="24.0273" y="31.8594" width="21" height="11" fill="white" />
      <path
        d="M42.8163 34.5264V37.1504V44.5648C42.8163 44.5697 42.8124 44.5737 42.8075 44.5737H26.0362C26.0313 44.5737 26.0273 44.5697 26.0273 44.5648V34.5264C26.0273 34.5215 26.0313 34.5175 26.0362 34.5175H42.8075C42.8124 34.5175 42.8163 34.5215 42.8163 34.5264Z"
        fill="white"
      />
      <path
        d="M44.913 29.8594V37.1415C44.913 37.1464 44.9091 37.1504 44.9042 37.1504H42.8163M42.8163 37.1504V34.5264C42.8163 34.5215 42.8124 34.5175 42.8075 34.5175H26.0362C26.0313 34.5175 26.0273 34.5215 26.0273 34.5264V44.5648C26.0273 44.5697 26.0313 44.5737 26.0362 44.5737H42.8075C42.8124 44.5737 42.8163 44.5697 42.8163 44.5648V37.1504Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M27.0273 44.8594V46.8505C27.0273 46.8554 27.0313 46.8594 27.0362 46.8594H30.0185C30.0234 46.8594 30.0273 46.8554 30.0273 46.8505V44.8594"
        stroke="#15141D"
        strokeWidth="1.2"
      />
      <path
        d="M33.0273 44.8594V46.8505C33.0273 46.8554 33.0313 46.8594 33.0362 46.8594H36.0185C36.0234 46.8594 36.0273 46.8554 36.0273 46.8505V44.8594"
        stroke="#15141D"
        strokeWidth="1.2"
      />
      <path
        d="M39.0273 44.8594V46.8505C39.0273 46.8554 39.0313 46.8594 39.0362 46.8594H42.0185C42.0234 46.8594 42.0273 46.8554 42.0273 46.8505V44.8594"
        stroke="#15141D"
        strokeWidth="1.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_23186_57535">
        <rect width="38" height="38" fill="white" transform="translate(9.02734 9.85938)" />
      </clipPath>
      <clipPath id="clip1_23186_57535">
        <rect width="29" height="29" fill="white" transform="translate(14.0273 13.8594)" />
      </clipPath>
    </defs>
  </svg>
);
