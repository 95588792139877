import {
  IconBulb,
  IconBulbGU10,
  IconFluorescentLamp,
  IconSmallBulb,
  IconSun,
} from '../../../../../../../components/icons';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const measuredBrightnessIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconSun isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_0',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BULB,
    component: <IconBulb isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_1',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_SMALL_BULB,
    component: <IconSmallBulb isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_2',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BULB_GU10,
    component: <IconBulbGU10 isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_5',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_FLUORESCENT_LAMP,
    component: <IconFluorescentLamp isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_6',
  },
];
