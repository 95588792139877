import React from 'react';

export const IconProjector: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-projector"
  >
    <path
      d="M17.5061 26.6753H4.16917C2.41888 26.6753 1 25.2564 1 23.5062V20.6011C1 18.8508 2.41889 17.4319 4.16917 17.4319H18.0343"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1836 17.4319H32.958C34.7083 17.4319 36.1272 18.8508 36.1272 20.6011V23.5062C36.1272 25.2564 34.7083 26.6753 32.958 26.6753H30.1836"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1076 29.4483C28.1916 29.4483 31.5023 26.1376 31.5023 22.0536C31.5023 17.9696 28.1916 14.6589 24.1076 14.6589C20.0236 14.6589 16.7129 17.9696 16.7129 22.0536C16.7129 26.1376 20.0236 29.4483 24.1076 29.4483Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4121 22.0536C20.4121 20.0118 22.0677 18.3562 24.1095 18.3562"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.373 10.1692V7.00006"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5977 11.2256L33.8796 8.05644"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0908 11.2256L14.8652 8.05644"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.92383 30.3727H7.46987"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.201 30.3727H29.6543"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.69727 30.3727V26.6753"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.4277 30.3727V26.6753"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
