import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ControlWrapper, LightSlider, RgbColor } from '../../../../../../components';
import { COLOR_LIST } from '../../../../../../const';

interface PropsInterface {
  rgbValue: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  brightness: number;
  handleBrightness: (event: React.PointerEvent<HTMLInputElement>) => void;
  handleControlRgb: (value: string, brightness: number) => void;
  colors?: string[];
  darkBorder?: boolean;
}

const ColorComponent: React.FC<PropsInterface> = ({
  rgbValue,
  brightness,
  handleChange,
  handleBrightness,
  handleControlRgb,
  colors = COLOR_LIST,
  darkBorder = false,
}) => {
  const [activeColor, setActiveColor] = useState<string>(rgbValue);

  useEffect(() => {
    setActiveColor(rgbValue);
  }, [rgbValue]);

  const isMatchedColor = useMemo(() => colors.find((color) => color === activeColor), [colors, activeColor]);

  const handleColor = useCallback(
    (color: string) => {
      setActiveColor(color);
      handleControlRgb(color, brightness);
    },
    [setActiveColor, brightness],
  );

  return (
    <>
      <ControlWrapper className="control-wrapper--medium  control-wrapper--column p-l-20 p-r-20">
        <RgbColor
          className="rgb-color--details"
          colors={colors}
          onClick={handleColor}
          activeColor={activeColor}
          darkBorder={darkBorder}
        />
      </ControlWrapper>
      <div className="details-page__controls-slider  p-l-24 p-r-24">
        <LightSlider
          value={brightness}
          onChange={handleChange}
          onPointerUp={handleBrightness}
          isBasic={!isMatchedColor}
          gradientColor={isMatchedColor && activeColor}
        />
      </div>
    </>
  );
};

export default ColorComponent;
