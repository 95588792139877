import React from 'react';

export const IconBall: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.667" cy="15" r="14.25" stroke="#15141D" strokeWidth="1.5" />
      <path d="M15.667 1.25V28.75" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M26.728 23.5017C22.4182 18.6532 22.4182 11.3468 26.728 6.49827L25.6069 5.50173C20.7919 10.9186 20.7919 19.0814 25.6069 24.4983L26.728 23.5017Z"
        fill="#15141D"
      />
      <path
        d="M5.74019 24.9831C5.47338 25.2999 5.00024 25.3405 4.6834 25.0737C4.36657 24.8069 4.32601 24.3337 4.59282 24.0169L5.74019 24.9831ZM4.59282 24.0169L4.8364 23.7277L5.98376 24.6939L5.74019 24.9831L4.59282 24.0169ZM4.8364 23.7277C9.04961 18.7245 8.95147 11.387 4.60595 6.49827L5.72706 5.50173C10.5676 10.9474 10.6769 19.1207 5.98376 24.6939L4.8364 23.7277Z"
        fill="#15141D"
      />
    </svg>
  );
};
