import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomBackdrop, EmptyStateBox, IconWarning, NavHeader, Page, SubmitButton } from '../../../../components';
import { useBackdropContext } from '../../../../hooks';
import { toastSuccess } from '../../../../utils/toast';
import GroupedChannels from '../group/components/grouped-channels';
import { useGroupChannels } from '../group/hooks/use-group-channels';
import { GroupedDeviceChannelsCheckbox } from '../group/types';

const ChannelsVisibility: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('channel-list');
  const [items, setItems] = useState<GroupedDeviceChannelsCheckbox[]>([]);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { isFetched } = useGroupChannels(items, setItems, true);

  const saveVisibility = async () => {
    turnOnBackdrop();
    for (let i = 0; i < items.length; i++) {
      for (let j = 0; j < items[i].devices.length; j++) {
        for (let k = 0; k < items[i].devices[j].channels.length; k++) {
          if (items[i].devices[j].channels[k].checked !== items[i].devices[j].channels[k].defaultChecked) {
            if (items[i].devices[j].channels[k].checked) await items[i].devices[j].channels[k].channel.ShowAsync();
            else await items[i].devices[j].channels[k].channel.HideAsync();
          }
        }
      }
    }

    toastSuccess({ content: t('visibilityChangedSuccessfully') });
    turnOffBackdrop();
    history.goBack();
  };

  return (
    <Page
      header={
        <>
          <NavHeader />
          <h3 className="m-b-24">{t('visibilityEditing')}</h3>
        </>
      }
    >
      <>
        {isFetched ? (
          items.length > 0 ? (
            <>
              <GroupedChannels items={items} setItems={setItems} visibility />
              <SubmitButton onClick={saveVisibility}>{tc('buttons.save')}</SubmitButton>
            </>
          ) : (
            <EmptyStateBox
              header={t('visibilityEditing')}
              content={t('channelsEmptyState.content')}
              icon={<IconWarning />}
            />
          )
        ) : (
          <CustomBackdrop loading />
        )}
      </>
    </Page>
  );
};

export default ChannelsVisibility;
