import { useTranslation } from 'react-i18next';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { ScenesServiceErrorCode } from 'lavva.exalushome/build/js/Services/Scenes/IScenesService';
import { toastError } from '../../../../../utils/toast';

export const useScenariosErrors = () => {
  const { t } = useTranslation('action');

  const handleScenariosErrors = (data: ResponseResult<ScenesServiceErrorCode>) => {
    switch ((data as ResponseResult<ScenesServiceErrorCode>).Type) {
      case ScenesServiceErrorCode.NoData: {
        toastError({ content: t('action.errors.noData') });
        break;
      }
      case ScenesServiceErrorCode.OtherError: {
        toastError({ content: t('action.errors.otherError') });
        break;
      }
      case ScenesServiceErrorCode.FatalError: {
        toastError({ content: t('action.errors.fatalError') });
        break;
      }
    }
  };

  return {
    handleScenariosErrors,
  };
};
