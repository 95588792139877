import snackbar, { ToastProps } from '../../components/snackbar';

export const toastError = (toastContent: ToastProps): void => {
  snackbar.error(toastContent);
};

export const toastSuccess = (toastContent: ToastProps): void => {
  snackbar.success(toastContent);
};

export const toastInfo = (toastContent: ToastProps): void => {
  snackbar.info(toastContent);
};
