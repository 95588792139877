import React from 'react';
import './index.scss';

export const IconQr: React.FC = () => (
  <svg className="icon-qr" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1667 4.6665H5.16675V9.6665" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M19.8333 9.6665L19.8333 4.6665L14.8333 4.6665" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M14.8333 19.3335L19.8333 19.3335L19.8333 14.3335" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M5.16675 14.3335L5.16675 19.3335L10.1667 19.3335" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M10.7499 7.3335H7.83325V10.2502" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M17.1665 10.2502L17.1665 7.3335L14.2498 7.3335" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M14.2498 16.667L17.1665 16.667L17.1665 13.7503" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M7.83325 13.7503L7.83325 16.667L10.7499 16.667" stroke="#15141D" strokeWidth="1.33333" />
    <path d="M4.5 12L20.5 12" stroke="#15141D" />
  </svg>
);
