import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getParameterByName } from '../../../../utils/location';
import ImportExportConfiguration from './import-export';
import './index.scss';
import LocationMap from './location-map';
import ExalusControllerMainSettings from './main-settings';
import ControllerUpdates from './updates';
import UpdatesSettings from './updates-settings';

interface ComponentProps {
  onlyContent?: boolean;
}

const ExalusControllerSettings: React.FC<ComponentProps> = ({ onlyContent }) => {
  const location = useLocation();
  const [view, setView] = useState<string>('');

  useEffect(() => {
    const viewParam = getParameterByName('view');
    if (viewParam) setView(viewParam);
    else setView('');
  }, [location.search]);

  const page = useMemo(() => {
    switch (view) {
      case 'updates':
        return <ControllerUpdates />;
      case 'location':
        return <LocationMap />;
      case 'updates-settings':
        return <UpdatesSettings />;
      case 'import-export':
        return <ImportExportConfiguration />;
      default:
        return <ExalusControllerMainSettings header={!onlyContent} setView={setView} />;
    }
  }, [view, onlyContent]);

  return <>{page}</>;
};

export default ExalusControllerSettings;
