import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';
import { EmptyStateBox, Header, IconPdf, IconWarning, NavHeader, Page } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import { useBackdropContext } from '../../../../hooks';
import { useExalusSupportedDeviceById } from '../../hooks/supported-devices.hooks';
import './index.scss';

const StoreDeviceDetails: React.FC = () => {
  const { t } = useTranslation('store');
  const { t: tc } = useTranslation('common');
  const { modelId } = useParams<{ modelId: string }>();
  const { data } = useExalusSupportedDeviceById(modelId);
  const { backdropOpen } = useBackdropContext();

  const slideLinks = useMemo(() => {
    let urls = (data?.Pictures || []).map((x) => ({ url: x, type: 'image' }));
    if (data?.PromoVideo) urls = [...urls, { url: data.PromoVideo, type: 'video' }];
    return urls;
  }, [data]);

  return (
    <Page
      className="store-device-details"
      containerClassName="page__container--no-padding-top"
      whiteContent
      isStickyHeader
      header={<NavHeader />}
    >
      {data ? (
        <>
          <div className="store-device-details--pictures">
            <Carousel
              className="device-carousel-pictures"
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              infiniteLoop={false}
              swipeable
              centerMode
              centerSlidePercentage={100}
            >
              {slideLinks.map((slide, i) => (
                <div key={i} className="slide-item">
                  {slide.type === 'image' ? (
                    <img src={slide.url} alt={data.Name} />
                  ) : (
                    <iframe
                      width="100%"
                      height="200"
                      src={slide.url}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              ))}
            </Carousel>
          </div>
          <p className="store-device-details--title">{data.Name}</p>
          <p className="store-device-details--model">{data.ModelName}</p>
          {data.WhereToBuy.length ? (
            <div className="buy-section">
              <hr className="m-t-24 m-b-24" />
              <Header title={t('buy')} />
              <div className="grid-list-24">
                {data.WhereToBuy.map((x, i) => (
                  <ArrowButton
                    key={x.Id}
                    title={x.Name}
                    dark
                    small
                    onClick={() => window.open(x.Link, '_blank')}
                    {...(i < data.WhereToBuy.length - 1 ? { className: 'm-b-16' } : {})}
                  />
                ))}
              </div>
            </div>
          ) : null}

          <hr className="m-t-24 m-b-24" />
          <Header title={t('description')} />
          <div className="store-device-details--description">{data.Description}</div>

          <hr className="m-t-24 m-b-24" />
          <Header title={t('parameters')} />
          <div className="store-device-details--parameters">
            <p>
              {`${t('manufacturer')}:`} <span>{data.ManufacturerName}</span>
            </p>
            <p>
              {`${t('name')}:`} <span>{data.Name}</span>
            </p>
            <p>
              {`${t('model')}:`} <span>{data.ModelName}</span>
            </p>
            <p>
              {`${t('controllerRequired')}:`} <span>{tc(`buttons.yes`)}</span>
            </p>
          </div>

          {data.ManualsAndDocuments.length ? (
            <>
              <hr className="m-t-24 m-b-24" />
              <Header title={t('deviceManual')} />

              {data.ManualsAndDocuments.map((x) => (
                <div key={x.Id} className="m-b-24">
                  <div className="store-device-details--pdf-box">
                    <div className="upper">
                      <IconPdf />
                      <p>{x.Title}</p>
                    </div>

                    <div className="pdf-list">
                      <a
                        key={x.Id}
                        href={data.ManualsAndDocuments[0].Link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="m-b-12"
                      >
                        {t('downloadPDF')}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </>
      ) : (
        <>
          {!backdropOpen ? (
            <EmptyStateBox content={t('exalus.params.UserManual.notFoundDevice')} icon={<IconWarning />} />
          ) : null}
        </>
      )}
    </Page>
  );
};

export default StoreDeviceDetails;
