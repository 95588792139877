import React from 'react';
import {
  IconBedsideLamp,
  IconBinaryState,
  IconBulb,
  IconBulbGU10,
  IconCeilingLamp,
  IconDeskLamp,
  IconFLoorLamp,
  IconFluorescentLamp,
  IconLightSwitch,
  IconPlafonLamp,
  IconRGBCircles,
  IconSmallBulb,
  IconSun,
  IconWallSconce,
} from '../../../../../../components/icons';
import { IconPlug } from '../../../../../../components/icons-group';
import { AvailableIcon, ChannelIconTypeEnum } from '../../../../../../types';

export const lightIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: ChannelIconTypeEnum.LIGHT,
    component: <IconSun isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_0',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BULB,
    component: <IconBulb isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_1',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_SMALL_BULB,
    component: <IconSmallBulb isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_2',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_LIGHT_SWITCH,
    component: <IconLightSwitch isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_3',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BULB_GU10,
    component: <IconBulbGU10 isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_5',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_FLUORESCENT_LAMP,
    component: <IconFluorescentLamp isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_6',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_CEILING_LAMP,
    component: <IconCeilingLamp isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_7',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_FLOOR_LAMP,
    component: <IconFLoorLamp isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_8',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_DESK_LAMP,
    component: <IconDeskLamp isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_9',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BEDSIDE_LAMP,
    component: <IconBedsideLamp isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_10',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_PLAFON_LAMP,
    component: <IconPlafonLamp isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_11',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_WALL_SCONCE,
    component: <IconWallSconce isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_12',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_RGB_CIRCLES,
    component: <IconRGBCircles isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_13',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BINARY_STATE,
    component: <IconBinaryState isOn={isOn !== undefined ? isOn : false} />,
    oldIconName: 'DEVICE_ICON_32',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_PLUG,
    component: <IconPlug isOn={isOn !== undefined ? isOn : false} />,
    oldIconName: 'DEVICE_ICON_PLUG',
  },
];
