import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from '../modules/login';
import MigrationPage from '../modules/migration';
import PrivacyPage from '../modules/privacy';
import RodoPage from '../modules/rodo';
import SupportPage from '../modules/support';
import TermsPage from '../modules/terms';
import { ROUTES } from './routes';

export const UnauthenticatedRouter: React.FC = () => (
  <Router>
    <Switch>
      <Route path={ROUTES.Terms()} component={TermsPage} exact />
      <Route path={ROUTES.Privacy()} component={PrivacyPage} exact />
      <Route path={ROUTES.Support()} component={SupportPage} exact />
      <Route path={ROUTES.Rodo()} component={RodoPage} exact />
      <Route path={ROUTES.Root()} component={LoginPage} exact />
      <Route path={ROUTES.Migration()} component={MigrationPage} exact />
      <Redirect to={ROUTES.Root()} />
    </Switch>
  </Router>
);
