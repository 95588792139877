import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { SelectOptionInterface } from '../../../../../components';
import { CheckboxesOptions } from '../../../../../components/checkbox-select';
import { PHASES_COLORS } from '../../../../../const';
import {
  AggregateVectorMeasurementResponseItem,
  AggregateVectorParameterTypeInternal,
  ChannelTypeInternal,
  MeasurementChannelKind,
  MeasurementDataRangeInternal,
  MeterAggregatedMeasurementsQueryVariables,
  MeterTypeInternal,
  Query,
} from '../../../../../data-access/gql-types/graphql';
import { METER_AGGREGATED_MEASUREMENTS } from '../../../../../data-access/queries/meter';
import { useApi, useInstallation } from '../../../../../hooks';
import { ChannelMeterInterface, MeasurementRange } from '../../../../../types';
import { SelectedPhase } from '../../../measurement/types';
import { AnalysisHook, AnalysisHookParams, ChartDataItem } from '../types';
import { predictionRangesAvailable } from '../utils';
import { parameterColor, predictionParameters } from '../utils/helpers';

export const useMeterAnalysis = ({ channel }: AnalysisHookParams): AnalysisHook => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId } = useInstallation();
  const [selectedPhases, setSelectedPhases] = useState<SelectedPhase[]>();
  const { convertMeasurementToNumber, measurementMap } = useApi();
  const [aggregatedParameters, setAggregatedParameters] = useState<
    CheckboxesOptions<AggregateVectorParameterTypeInternal>[]
  >([]);
  const [activeMeasurementRange, setActiveMeasurementRange] = useState<MeasurementRange>(MeasurementRange.DayInHours);
  const [page, setPage] = useState<number>(0);
  const [getAggregatedMeasurements, { data, loading }] = useLazyQuery<Query, MeterAggregatedMeasurementsQueryVariables>(
    METER_AGGREGATED_MEASUREMENTS,
  );

  const supported = useMemo(() => {
    return (channel?.data as ChannelMeterInterface)?.supportedAggregateVectorParameters;
  }, [(channel?.data as ChannelMeterInterface)?.supportedAggregateVectorParameters]);

  const checkIfSupported = (param: AggregateVectorParameterTypeInternal) =>
    supported.includes(convertMeasurementToNumber(MeasurementChannelKind.Meter)('aggregates', param));

  useEffect(() => {
    if (supported.length && !aggregatedParameters.length && measurementMap) {
      setAggregatedParameters([
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ForwardActiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
          checked: true,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ForwardActiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ReverseActiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ReverseActiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ForwardReactiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ForwardReactiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ReverseReactiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ReverseReactiveEnergy),
        },
        {
          label: t(
            `energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy}`,
          ),
          value: AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy),
        },
      ]);
    }
  }, [supported, t, convertMeasurementToNumber, measurementMap, aggregatedParameters]);

  const timeRanges: SelectOptionInterface<MeasurementRange>[] = useMemo(
    () => [
      { value: MeasurementRange.DayInHours, label: t('dateAbbreviation.hours') },
      { value: MeasurementRange.MonthInDays, label: t('dateAbbreviation.days') },
      { value: MeasurementRange.WeekInDays, label: t('dateAbbreviation.weeks') },
      { value: MeasurementRange.YearInMonths, label: t('dateAbbreviation.months') },
      { value: MeasurementRange.TenYearsInYears, label: t('dateAbbreviation.years') },
    ],
    [t],
  );

  const relativeActiveMeasurementRange = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours: {
        return MeasurementDataRangeInternal.InHours;
      }
      case MeasurementRange.YearInMonths: {
        return MeasurementDataRangeInternal.InMonths;
      }
      case MeasurementRange.TenYearsInYears: {
        return MeasurementDataRangeInternal.InYears;
      }
      default: {
        return MeasurementDataRangeInternal.InDays;
      }
    }
  }, [activeMeasurementRange]);

  useEffect(() => {
    const aggregateVectorRequestItems = aggregatedParameters
      .filter((x) => !x.disabled && x.checked)
      .map((param) => ({
        type: param.value,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      }));

    if (
      aggregateVectorRequestItems.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy) &&
      checkIfSupported(AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy) &&
      predictionRangesAvailable.includes(relativeActiveMeasurementRange)
    ) {
      aggregateVectorRequestItems.push({
        type: AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      });
    }

    if (
      aggregateVectorRequestItems.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy) &&
      checkIfSupported(AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) &&
      relativeActiveMeasurementRange === MeasurementDataRangeInternal.InHours
    ) {
      aggregateVectorRequestItems.push({
        type: AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      });
    }

    if (
      aggregateVectorRequestItems.find(
        (x) => x.type === AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
      ) &&
      checkIfSupported(AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy) &&
      predictionRangesAvailable.includes(relativeActiveMeasurementRange)
    ) {
      aggregateVectorRequestItems.push({
        type: AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      });
    }

    getAggregatedMeasurements({
      variables: {
        installationId: selectedInstallationId,
        input: {
          channelId: channel.id,
          deviceId: channel.deviceId,
          indices: selectedPhases?.length ? selectedPhases.map((x) => x.value) : [0],
          aggregateVectorRequestItems,
        },
      },
    });
  }, [aggregatedParameters, relativeActiveMeasurementRange, supported, selectedPhases]);

  const aggregatedMeasurements = useMemo(() => {
    return (data?.meterAggregatedMeasurements.data?.aggregateVectorMeasurements || []).filter((measurement) => {
      return !(predictionParameters.includes(measurement.type) && !measurement.values.length);
    });
  }, [data?.meterAggregatedMeasurements]);

  const mappedChartData: ChartDataItem[] = useMemo(() => {
    const itemsData: ChartDataItem[] = [];

    if (aggregatedMeasurements.length) {
      const obj = groupBy(aggregatedMeasurements, 'type') as Record<string, AggregateVectorMeasurementResponseItem[]>;

      Object.keys(obj).forEach((key) => {
        obj[key].forEach((measurement) => {
          for (let i = 0; i < measurement.values.length; i++) {
            if (!itemsData[i]) {
              itemsData[i] = { values: [] };
            }

            if (measurement.values[i] !== undefined) {
              itemsData[i].values.push({
                type: measurement.type,
                index: measurement.index,
                value:
                  measurement.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice
                    ? measurement.values[i]
                    : measurement.values[i] / 1000,
                color: measurement.index ? PHASES_COLORS[measurement.index] : parameterColor[measurement.type],
              });
            }
          }
        });
      });

      return itemsData;
    }

    return [];
  }, [activeMeasurementRange, aggregatedMeasurements]);

  const onClickActiveMeasurementRange = useCallback((value: MeasurementRange) => {
    setActiveMeasurementRange(value);
    setPage(0);
  }, []);

  const handleSetAggregatedParameters = (options: CheckboxesOptions<AggregateVectorParameterTypeInternal>[]) => {
    if (options.filter((x) => !x.disabled && x.checked).length > 1) {
      setSelectedPhases([{ value: 0, label: t('sumOfPhases'), color: PHASES_COLORS[0] }]);
    }

    setAggregatedParameters(options);
  };

  const isStandalone = useMemo(() => {
    return channel?.data.type === ChannelTypeInternal.Meter && channel?.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  return {
    data: data?.meterAggregatedMeasurements?.data,
    isLoading: loading,
    selectedPhases,
    setSelectedPhases,
    mappedChartData,
    onClickActiveMeasurementRange,
    handleSetAggregatedParameters,
    aggregatedParameters,
    activeMeasurementRange,
    page,
    setPage,
    timeRanges,
    isStandalone,
  };
};
