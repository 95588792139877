import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { uniqBy } from 'lodash';
import { Accordion, CustomBackdrop, EmptyStateBox, IconWarning } from '../../../../components';
import { useBackdropContext } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { GroupedDevice } from '../device-list/types';
import FoundDevice from './components/found-device/found';
import { useFoundDevices } from './hooks/use-found-devices';
import { PairedPage } from './types';

const FoundDevices: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-add');
  const { groupedDevices, fetching, registerDevice, searchAgainDevices, stopSearching } = useFoundDevices();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const { backdropOpen } = useBackdropContext();

  const redirectToManually = () => {
    history.push(`${ROUTES.AddExalusDevices()}?page=${PairedPage.ManuallyPaired}`);
  };

  return (
    <>
      <div className="grouped-list">
        {groupedDevices.length > 0 ? (
          groupedDevices.map((groupedDevice: GroupedDevice, i: number) => (
            <Accordion
              kind="controlled"
              key={groupedDevice.label}
              title={groupedDevice.label}
              hideExpandedBorder
              expanded={i === expandedIndex}
              handleExpand={setExpandedIndex}
              position={i}
            >
              <div className="grid-list-24">
                {uniqBy(groupedDevice.devices, (device: IDevice) => device.Guid).map((device: IDevice) => (
                  <FoundDevice key={device.Guid} device={device} registerDevice={registerDevice} />
                ))}
              </div>
            </Accordion>
          ))
        ) : (
          <>
            {!backdropOpen && !fetching && (
              <EmptyStateBox content={t('exalus.emptyListContent')} icon={<IconWarning />} />
            )}
          </>
        )}
      </div>
      <div className="buttons">
        <hr className="m-t-24 m-b-24" />
        <button className="button button--secondary m-b-24" onClick={searchAgainDevices}>
          {t('exalus.search')}
        </button>
        <button className="button" onClick={redirectToManually}>
          {t('exalus.addManually')}
        </button>
      </div>
      {fetching && <CustomBackdrop loading btnTitle={t('exalus.searchStop')} onClick={stopSearching} />}
    </>
  );
};

export default FoundDevices;
