import React from 'react';

export const IconInstallation3: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="8.16699" y="19" width="23.75" height="14.25" stroke="#15141D" />
    <path
      d="M9.75032 6.3335L20.042 6.3335L30.3337 6.3335L36.667 19.0002L3.41699 19.0002L9.75032 6.3335Z"
      stroke="#15141D"
    />
  </svg>
);
