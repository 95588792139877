import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ReedStateDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ReedControlHook } from '../types';

export const useReedControl = (channel?: IDeviceChannel, disabled?: boolean): ReedControlHook => {
  if (disabled) return { reed: null, time: '' };

  const reedData = useMemo(() => {
    const reedState = channel?.States?.find((state) => state.TypeAsEnum === ChannelResponseType.ReedState) as
      | ReedStateDeviceState
      | undefined;

    return reedState?.Data;
  }, [channel?.States]);

  const time = useMemo(() => (reedData?.Time ? format(Date.parse(reedData.Time), 'dd.MM, HH:mm') : ''), [reedData]);

  return {
    reed: reedData?.State ?? null,
    time,
  };
};
