import { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { LightRGBWDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import {
  ChannelOnOffState,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { LightRGBW, SetLightBrightness, TurnOff, TurnOn } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useNative } from '../../../../../hooks';

export const useRgbControl = (channel: IDeviceChannel) => {
  const { fireVibration } = useNative();

  const state = useMemo(() => {
    return (
      channel.States?.find(
        (state) => state.TypeAsEnum === ChannelResponseType.LightRGBWState,
      ) as LightRGBWDeviceState | null
    )?.Data;
  }, [channel.States]);

  const isOn = useMemo(() => {
    return (
      channel.States?.find(
        (state) => state.TypeAsEnum === ChannelResponseType.ChannelOnOffState,
      ) as IChannelState<ChannelOnOffState>
    )?.Data.State;
  }, [channel.States]);

  const handleControlOn = async () => {
    fireVibration();
    await channel.ExecuteTaskAsync(new TurnOn());
  };

  const handleControlOff = async () => {
    fireVibration();
    await channel.ExecuteTaskAsync(new TurnOff());
  };

  const handleControlBrightness = async (value: number) => {
    const task = new SetLightBrightness();
    task.DeviceGuid = channel.GetDevice().Guid;
    task.Channel = channel.Number;
    task.Brightness = value;
    fireVibration();

    await channel.ExecuteTaskAsync(task);
  };

  const handleControlRgb = async (value: string, brightness: number) => {
    const rgb = value.split(',');

    const task = new LightRGBW();
    task.DeviceGuid = channel.GetDevice().Guid;
    task.Channel = channel.Number;
    task.Data.R = parseInt(rgb[0]);
    task.Data.G = parseInt(rgb[1]);
    task.Data.B = parseInt(rgb[2]);
    task.Data.Brightness = brightness;

    fireVibration();
    await channel.ExecuteTaskAsync(task);
  };

  return {
    handleControlOn,
    handleControlOff,
    handleControlBrightness,
    handleControlRgb,
    state,
    isOn: !isOn,
  };
};
