import React, { useCallback } from 'react';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useCalibration } from '../hooks/use-calibration';
import './index.scss';

const AutoCalibrationBlindSuccess: React.FC = () => {
  const { channel, history, t } = useCalibration();

  const handleClick = useCallback(() => history.go(-4), []);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="blind-icon" />}
      <p className="channel-name">{channel?.Name}</p>
      <p className="info m-t-40">{t('exalus.params.SrpParams.calibrationFlow.calibrationSuccess')}</p>

      <SubmitButton type="button" onClick={handleClick} tranparent>
        OK
      </SubmitButton>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default AutoCalibrationBlindSuccess;
