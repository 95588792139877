import React from 'react';

export const IconMusic: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.8978 16.4446C26.3923 18.0088 25.0437 20.1935 22.8908 21.3311C20.738 22.4687 18.5901 22.1196 18.1006 20.5554C17.6111 18.9912 18.9548 16.8065 21.1076 15.6689C23.2605 14.5313 25.4083 14.8804 25.8978 16.4446Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.89785 19.4446C9.39226 21.0088 8.04387 23.1935 5.89144 24.3311C3.739 25.4687 1.59156 25.1196 1.10215 23.5554C0.607736 21.9912 1.95613 19.8065 4.10856 18.6689C6.261 17.5313 8.40844 17.8804 8.89785 19.4446Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.96484 20.4059V5L26 1V17"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
