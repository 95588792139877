import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { IconDuplicate } from '../popup-nav/icons';
import './index.scss';

interface ComponentProps {
  text: string;
}

export const CopyToClipboardButton: React.FC<ComponentProps> = ({ text }) => {
  const { t } = useTranslation('common');
  const [copied, setCopied] = useState<boolean>(false);

  const handleClick = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1200);
  };

  return (
    <CopyToClipboard text={text} options={{ format: 'text/plain' }}>
      <IconButton className="copy-to-clipboard-btn" onClick={handleClick}>
        <IconDuplicate clearCard />
        {copied && <p className="copy-msg">{t('copied')}</p>}
      </IconButton>
    </CopyToClipboard>
  );
};
