import React from 'react';
import '../index.scss';

export const IconChannelsOrder: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--channels-order"
  >
    <path
      d="M8.62264 10.4385H2.99649C2.60593 10.4385 2.28931 10.7551 2.28931 11.1457V16.7718C2.28931 17.1624 2.60593 17.479 2.99649 17.479H8.62264C9.01321 17.479 9.32983 17.1624 9.32983 16.7718V11.1457C9.32983 10.7551 9.01321 10.4385 8.62264 10.4385Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9686 2.28955H11.3424C10.9519 2.28955 10.6353 2.60617 10.6353 2.99674V8.62288C10.6353 9.01345 10.9519 9.33007 11.3424 9.33007H16.9686C17.3592 9.33007 17.6758 9.01345 17.6758 8.62288V2.99674C17.6758 2.60617 17.3592 2.28955 16.9686 2.28955Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8206 17.4791H12.7733C14.6556 17.4791 16.1814 15.9533 16.1814 14.071V12.5693"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6628 13.4223L16.1867 11.8984L17.7106 13.4223"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.18236 2.28955H7.22964C5.34734 2.28955 3.82153 3.81536 3.82153 5.69765V7.19934"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.30688 6.26465L3.83028 7.78853L5.35416 6.26465"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
