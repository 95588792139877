import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Header, Input } from '../../../../../../components';
import { useChannelDetailsContext } from '../../../../../../hooks/channel-details';

export const OptimizerConfigurationMaxActivePower: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { control } = useFormContext();
  const { optimizerConfigValidation } = useChannelDetailsContext();

  return (
    <>
      <Header title={t('optimizer.configuration.maxActivePower.short')} className="m-b-16" />

      <Controller
        name="powerLimit"
        control={control}
        rules={{ required: tc('isRequired') as string }}
        render={({ field, fieldState: { error } }) => (
          <Input
            defaultValue={field.value}
            value={field.value}
            onChange={field.onChange}
            label={t('optimizer.configuration.maxActivePower.long')}
            inputType="number"
            required
            {...(optimizerConfigValidation ? { min: optimizerConfigValidation.powerLimit.min } : {})}
            {...(optimizerConfigValidation ? { max: optimizerConfigValidation.powerLimit.max } : {})}
            {...(optimizerConfigValidation?.powerLimit.step
              ? { step: optimizerConfigValidation.powerLimit.step.toString() }
              : {})}
            {...(error ? { errorMessage: error.message } : {})}
          />
        )}
      />
    </>
  );
};
