import React, { ReactNode } from 'react';
import c from 'classnames';
import './index.scss';

interface PropsInterface {
  setOpen?: (open: boolean) => void;
  children: ReactNode;
  caption?: string;
  subCaption?: string;
  noBackground?: boolean;
  activeColor?: string;
  colorAccent?: boolean;
  disabled?: boolean;
}

export const EditIconControl: React.FC<PropsInterface> = ({
  setOpen,
  children,
  caption,
  subCaption,
  noBackground,
  activeColor,
  colorAccent = true,
  disabled,
}) => {
  const handleClick = () => setOpen && setOpen(true);

  return (
    <button
      type={'button'}
      onClick={handleClick}
      disabled={disabled}
      className={c('edit-icon-control', {
        'edit-icon-control--preview': !setOpen,
      })}
    >
      {caption && <span className="edit-icon-control__caption m-b-8">{caption}</span>}
      <div
        className={c('edit-icon-control__icon-wrapper', {
          'edit-icon-control__icon-wrapper--accent': colorAccent,
        })}
      >
        <div
          className={c('edit-icon-control__icon', {
            'edit-icon-control__icon--accent': colorAccent,
            'no-background': noBackground,
          })}
        >
          {children}
        </div>
        {subCaption && !disabled && (
          <span
            className="edit-icon-control__caption m-l-16"
            {...(activeColor ? { style: { color: activeColor } } : {})}
          >
            {subCaption}
          </span>
        )}
      </div>
    </button>
  );
};
