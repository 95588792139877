import React from 'react';

export const IconLimit: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_12815_16315" fill="white">
      <path d="M32.9461 26.0703C33.2699 26.2283 33.4057 26.6198 33.2334 26.9362C31.6176 29.9026 29.0124 32.2184 25.8613 33.4724C22.5077 34.807 18.7769 34.8428 15.3983 33.5729C12.0197 32.3029 9.23616 29.8185 7.59191 26.6054C5.94767 23.3923 5.56091 19.6814 6.50726 16.1983C7.45361 12.7151 9.66501 9.71016 12.7092 7.77082C15.7533 5.83149 19.4113 5.09723 22.968 5.71159C26.5248 6.32594 29.7245 8.24474 31.9417 11.0929C34.025 13.769 35.1001 17.0848 34.9927 20.4609C34.9812 20.8211 34.666 21.09 34.3067 21.0624C33.9474 21.0347 33.6802 20.7209 33.6901 20.3607C33.7736 17.3088 32.7957 14.3143 30.912 11.8945C28.8943 9.30271 25.9825 7.5566 22.7459 6.99754C19.5093 6.43848 16.1805 7.10666 13.4103 8.87145C10.6402 10.6362 8.62778 13.3708 7.76661 16.5404C6.90543 19.7101 7.25738 23.087 8.75364 26.0109C10.2499 28.9349 12.7829 31.1957 15.8574 32.3513C18.932 33.507 22.327 33.4744 25.3788 32.2599C28.228 31.1261 30.5868 29.0381 32.0592 26.3635C32.2329 26.0478 32.6222 25.9124 32.9461 26.0703Z" />
    </mask>
    <path
      d="M32.9461 26.0703C33.2699 26.2283 33.4057 26.6198 33.2334 26.9362C31.6176 29.9026 29.0124 32.2184 25.8613 33.4724C22.5077 34.807 18.7769 34.8428 15.3983 33.5729C12.0197 32.3029 9.23616 29.8185 7.59191 26.6054C5.94767 23.3923 5.56091 19.6814 6.50726 16.1983C7.45361 12.7151 9.66501 9.71016 12.7092 7.77082C15.7533 5.83149 19.4113 5.09723 22.968 5.71159C26.5248 6.32594 29.7245 8.24474 31.9417 11.0929C34.025 13.769 35.1001 17.0848 34.9927 20.4609C34.9812 20.8211 34.666 21.09 34.3067 21.0624C33.9474 21.0347 33.6802 20.7209 33.6901 20.3607C33.7736 17.3088 32.7957 14.3143 30.912 11.8945C28.8943 9.30271 25.9825 7.5566 22.7459 6.99754C19.5093 6.43848 16.1805 7.10666 13.4103 8.87145C10.6402 10.6362 8.62778 13.3708 7.76661 16.5404C6.90543 19.7101 7.25738 23.087 8.75364 26.0109C10.2499 28.9349 12.7829 31.1957 15.8574 32.3513C18.932 33.507 22.327 33.4744 25.3788 32.2599C28.228 31.1261 30.5868 29.0381 32.0592 26.3635C32.2329 26.0478 32.6222 25.9124 32.9461 26.0703Z"
      stroke="#15141D"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      mask="url(#path-1-inside-1_12815_16315)"
    />
    <mask id="path-2-inside-2_12815_16315" fill="white">
      <path d="M7.02654 31.7123C6.75666 31.9469 6.34665 31.9189 6.12162 31.641C3.36866 28.2408 1.90519 23.968 2.00476 19.5803C2.10924 14.9764 3.92706 10.5769 7.10283 7.24203C10.2786 3.90715 14.584 1.8766 19.1773 1.54734C23.7706 1.21809 28.3216 2.61382 31.9406 5.46166C35.5595 8.30949 37.9862 12.4047 38.7463 16.9466C39.5063 21.4886 38.5451 26.1507 36.0505 30.0216C33.5558 33.8925 29.7072 36.6938 25.2569 37.878C21.0156 39.0065 16.5185 38.5888 12.5663 36.7125C12.2433 36.5591 12.1197 36.1672 12.2843 35.8497C12.4488 35.5322 12.8392 35.4095 13.1627 35.562C16.8283 37.2904 20.9942 37.6721 24.924 36.6265C29.0627 35.5253 32.6419 32.92 34.9619 29.3201C37.2819 25.7202 38.1759 21.3844 37.469 17.1604C36.7622 12.9364 34.5053 9.12783 31.1397 6.47934C27.7741 3.83085 23.5417 2.53283 19.2699 2.83903C14.9981 3.14523 10.9941 5.03365 8.04063 8.13509C5.08716 11.2365 3.39659 15.328 3.29943 19.6097C3.20717 23.6751 4.55776 27.6345 7.09964 30.7909C7.32393 31.0694 7.29643 31.4777 7.02654 31.7123Z" />
    </mask>
    <path
      d="M7.02654 31.7123C6.75666 31.9469 6.34665 31.9189 6.12162 31.641C3.36866 28.2408 1.90519 23.968 2.00476 19.5803C2.10924 14.9764 3.92706 10.5769 7.10283 7.24203C10.2786 3.90715 14.584 1.8766 19.1773 1.54734C23.7706 1.21809 28.3216 2.61382 31.9406 5.46166C35.5595 8.30949 37.9862 12.4047 38.7463 16.9466C39.5063 21.4886 38.5451 26.1507 36.0505 30.0216C33.5558 33.8925 29.7072 36.6938 25.2569 37.878C21.0156 39.0065 16.5185 38.5888 12.5663 36.7125C12.2433 36.5591 12.1197 36.1672 12.2843 35.8497C12.4488 35.5322 12.8392 35.4095 13.1627 35.562C16.8283 37.2904 20.9942 37.6721 24.924 36.6265C29.0627 35.5253 32.6419 32.92 34.9619 29.3201C37.2819 25.7202 38.1759 21.3844 37.469 17.1604C36.7622 12.9364 34.5053 9.12783 31.1397 6.47934C27.7741 3.83085 23.5417 2.53283 19.2699 2.83903C14.9981 3.14523 10.9941 5.03365 8.04063 8.13509C5.08716 11.2365 3.39659 15.328 3.29943 19.6097C3.20717 23.6751 4.55776 27.6345 7.09964 30.7909C7.32393 31.0694 7.29643 31.4777 7.02654 31.7123Z"
      stroke="#15141D"
      strokeWidth="3"
      strokeLinecap="round"
      mask="url(#path-2-inside-2_12815_16315)"
    />
    <path
      d="M14.8702 26V16.056L12.7022 17.368V15.776L14.8702 14.48H16.3182V26H14.8702ZM23.2502 26.24C22.5035 26.24 21.8369 26.072 21.2502 25.736C20.6689 25.3947 20.2075 24.9333 19.8662 24.352C19.5302 23.7653 19.3622 23.0987 19.3622 22.352V18.128C19.3622 17.3813 19.5302 16.7147 19.8662 16.128C20.2075 15.5413 20.6689 15.08 21.2502 14.744C21.8369 14.408 22.5035 14.24 23.2502 14.24C23.9969 14.24 24.6635 14.408 25.2502 14.744C25.8369 15.08 26.2982 15.5413 26.6342 16.128C26.9702 16.7147 27.1382 17.3813 27.1382 18.128V22.352C27.1382 23.0987 26.9702 23.7653 26.6342 24.352C26.2982 24.9333 25.8369 25.3947 25.2502 25.736C24.6635 26.072 23.9969 26.24 23.2502 26.24ZM23.2502 24.872C23.7035 24.872 24.1142 24.7627 24.4822 24.544C24.8502 24.3253 25.1435 24.032 25.3622 23.664C25.5809 23.2907 25.6902 22.88 25.6902 22.432V18.04C25.6902 17.5867 25.5809 17.176 25.3622 16.808C25.1435 16.44 24.8502 16.1467 24.4822 15.928C24.1142 15.7093 23.7035 15.6 23.2502 15.6C22.8022 15.6 22.3915 15.7093 22.0182 15.928C21.6502 16.1467 21.3569 16.44 21.1382 16.808C20.9195 17.176 20.8102 17.5867 20.8102 18.04V22.432C20.8102 22.88 20.9195 23.2907 21.1382 23.664C21.3569 24.032 21.6502 24.3253 22.0182 24.544C22.3915 24.7627 22.8022 24.872 23.2502 24.872Z"
      fill="#15141D"
    />
  </svg>
);
