import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { debounce, groupBy } from 'lodash';
import { Header, Input, NavHeader, Page } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { DeviceChannelsBox } from '../../../../../components/channel/device-channels-box';
import { useChannels } from '../../../../channel-list/hooks/use-channels';
import { DeviceChannelsGrouped } from '../../../../channel-list/types';
import { useExalusCreateActionContext } from '../../../context';
import { useActionConditions } from '../../../hooks/use-action-conditions';
import { ConditionSourceType } from '../../../types';

const ExalusDeviceSource: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const [search, setSearch] = useState<string>('');
  const { sourceType, setChannels } = useExalusCreateActionContext();
  const { getSourceChannels } = useActionConditions();
  const { groupedChannels } = useChannels();

  const onChangeSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value);
  };

  const debouncedResults = useMemo(() => debounce(onChangeSearch, 300), []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const devicesChannelsGrouped = useMemo(() => {
    const channelList = getSourceChannels(groupedChannels).filter((x) =>
      x.Name.toLowerCase().includes(search.toLowerCase()),
    );
    const grouped: Record<string, IDeviceChannel[]> = groupBy(channelList, (x) => x.GetDevice().Guid);

    return Object.entries(grouped).map(([id, channels]) => ({
      id,
      deviceName: channels[0].GetDevice().Name,
      channels: channels,
    }));
  }, [sourceType, groupedChannels, search]);

  // const devicesChannelsNotGrouped: IDeviceChannel[] = useMemo(() => {
  //   const channelList = getSourceChannels(groupedChannels).filter((x) =>
  //     x.Name.toLowerCase().includes(search.toLowerCase()),
  //   );
  //   return channelList.filter((x) => !x.GetDevice().ShouldChannelsBeGrouped);
  // }, [sourceType, groupedChannels, search]);

  const handleExalusChannelItemClick = (channel: IDeviceChannel) => {
    setChannels([channel]);
    const id = new URLSearchParams(window.location.search).get('id');
    history.push(`${ROUTES.ExalusDeviceParameters()}${id ? `?id=${id}` : ''}`);
  };

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader title={t('action.create.conditions.sources.header')} />
          <Header column isUnderline>
            <Input
              defaultValue={search}
              value={search}
              autoFocus
              onChange={debouncedResults}
              placeholder={tc('search')}
              noBottomMargin
            />
          </Header>
        </>
      }
    >
      <ul className="grid-list-24">
        {/* {devicesChannelsNotGrouped.map((channel: IDeviceChannel) => (
          <li key={channel.ChannelId} className="items-list__item">
            <ChannelBox
              channel={channel}
              handleExalusChannelItemClick={handleExalusChannelItemClick}
              isListItem
              hideControls
            />
          </li>
        ))} */}
        {devicesChannelsGrouped.map((deviceChannelsGrouped: DeviceChannelsGrouped) => (
          <li key={deviceChannelsGrouped.id} className="items-list__item">
            <DeviceChannelsBox
              deviceChannelsGrouped={deviceChannelsGrouped}
              isListItem
              handleExalusChannelItemClick={handleExalusChannelItemClick}
              allCollapsedOpen={!!search}
              hideControls
              isChannelNumber={
                sourceType === ConditionSourceType.Remotes ||
                sourceType === ConditionSourceType.BlindRemotes ||
                sourceType === ConditionSourceType.FacadeRemotes
              }
            />
          </li>
        ))}
      </ul>
    </Page>
  );
};

export default ExalusDeviceSource;
