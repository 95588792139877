import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { useUpdateChannel } from '../../api/modules/channels';
import { PublishStatus } from '../../types';
import { toastError } from '../../utils/toast';
import { useBackdropContext } from '../use-backdrop';
import { HandleErrorsHook, LavvaResolve } from './types';

const errorsPartForReports = ['HC0018', 'EXEC', 'LEAF'];

const reportUnhandledErrors = (message: string) => {
  if (errorsPartForReports.some((errorPart) => message.includes(errorPart))) {
    Sentry.captureMessage(message, { level: 'error' });
  }
};

export const useErrors = (): HandleErrorsHook => {
  const { t } = useTranslation('common');
  const { t: tv } = useTranslation('backend-validation');
  const { turnOffBackdrop } = useBackdropContext();
  const { setChannelAsDisconnected } = useUpdateChannel();

  const handleGraphQLError = ({ graphQLErrors, networkError, operation }: ErrorResponse) => {
    if (operation.operationName === 'AddMultipleInstallationsAfterMigration') return;

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const code: string = error.extensions?.code as string;

        switch (code) {
          case 'AUTH_NOT_AUTHENTICATED': {
            showError(tv('AUTH_NOT_AUTHENTICATED'));
            break;
          }
          case 'installation_not_attached': {
            console.log('INSTALLATION NOT ATTACHED');
            break;
          }
          default: {
            const errorCode: string = error.extensions?.code as string;

            if (errorCode) {
              if (errorCode.includes('MAXIMUM_LENGTH')) {
                showError(`${tv('MAXIMUM_LENGTH').replace('#', errorCode.split('_')[2])}`);
              } else if (errorCode.includes('NOT_GREATER_THAN')) {
                showError(`${tv('NOT_GREATER_THAN').replace('#', errorCode.split('_')[3])}`);
              } else {
                showError(tv(errorCode) || error.message);
              }
            } else {
              showError(error.message);
            }

            break;
          }
        }
      });
    }

    if (networkError) {
      // showError('Network error');
    }
  };

  const handleRestErrors = (error: AxiosError, errorMessage?: string) => {
    turnOffBackdrop();
    if (error.code === 'ERR_CANCELED') return;

    const status = error.response?.status;

    if (status) {
      switch (status) {
        case 401: {
          window.location.reload();
          break;
        }
        case 429: {
          showError(t('errors.tooManyRequests'));
          break;
        }
        case 500: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        case 501: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        case 502: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        case 503: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        default: {
          if (errorMessage) toastError({ content: errorMessage });
          else if (typeof error.response?.data === 'string') {
            showError(error.response?.data || t('errors.somethingWentWrong'));
          } else {
            const errorData = error.response?.data as any;
            if (errorData) {
              console.log(errorData || 'Error');

              if ('ErrorCode' in errorData) {
                showError(tv(errorData.ErrorCode));
              } else if ('errorCode' in errorData) showError(tv(errorData.errorCode));
            }
          }
          break;
        }
      }
    } else showError(t('errors.somethingWentWrong'));
  };

  const showError = (message: string) => {
    toastError({ content: message });
    reportUnhandledErrors(message);
  };

  const handleLavvaResolve = (params: LavvaResolve) => {
    turnOffBackdrop();

    switch (params.status) {
      case PublishStatus.Ok: {
        if (params.onSuccess) params.onSuccess();
        break;
      }
      case PublishStatus.DeviceOrChannelNotFound: {
        toastError({ content: t(`publishStatus.${PublishStatus[PublishStatus.DeviceOrChannelNotFound]}`) });
        break;
      }
      case PublishStatus.DeviceDisconnected: {
        toastError({ content: t(`publishStatus.${PublishStatus[PublishStatus.DeviceDisconnected]}`) });
        if (params.deviceId) setChannelAsDisconnected(params.deviceId);
        break;
      }
      case PublishStatus.ContractNotSupported: {
        toastError({ content: t(`publishStatus.${PublishStatus[PublishStatus.ContractNotSupported]}`) });
        break;
      }
      default: {
        break;
      }
    }
  };

  return { handleGraphQLError, handleRestErrors, handleLavvaResolve };
};
