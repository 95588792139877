import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconHumanMovement: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-human-movement"
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isOn ? (
        <>
          <path
            d="M30.659 24.7858C31.9985 23.5121 32.8322 21.7213 32.8322 19.7379C32.8322 17.7111 31.9616 15.8855 30.5703 14.6072"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M3.33903 24.6891C1.99954 23.3731 1.16589 21.5229 1.16589 19.4736C1.16589 17.3794 2.03644 15.4932 3.42773 14.1724"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </>
      ) : null}
      <circle cx="17.8484" cy="4.5835" r="3.16675" stroke="#15141D" strokeWidth="1.5" />
      <path
        d="M18.2792 20.7656L18.7754 18.5908L18.8521 18.7248C18.9063 18.8194 18.9805 18.9011 19.0695 18.9641L22.2863 21.2424C23.0822 21.806 24.1919 21.5459 24.6543 20.6873C25.0355 19.9796 24.8415 19.0995 24.1981 18.6176L21.4845 16.5853C21.438 16.4588 21.387 16.296 21.3291 16.0925C21.2796 15.9188 21.2319 15.7412 21.1803 15.5488C21.1404 15.4001 21.0982 15.2427 21.0509 15.0714C20.8448 14.325 20.5756 13.438 20.1645 12.6525C19.6928 11.7511 18.8314 11.1087 18.0532 10.7224C17.6561 10.5252 17.2521 10.3801 16.8855 10.2981C16.5484 10.2227 16.1391 10.1762 15.7859 10.2814C15.6678 10.3166 15.5601 10.3804 15.4725 10.4671L10.5688 15.3193C10.466 15.4209 10.3949 15.5501 10.3638 15.6913L9.38171 20.156C9.16453 21.1434 9.81741 22.1117 10.8142 22.2805C11.2863 22.3605 11.7464 22.2455 12.112 21.994L11.2397 25.9267L8.53416 30.655C7.96298 31.6532 8.25447 32.9227 9.20387 33.5718C10.2595 34.2935 11.7056 33.9694 12.3522 32.8662L15.6114 27.3053C15.649 27.2412 15.6768 27.1719 15.694 27.0997L16.1849 25.0371L18.1549 27.2629L19.0081 32.2678C19.1983 33.3835 20.2285 34.155 21.3527 34.0236C22.574 33.8808 23.4205 32.7355 23.1985 31.526L22.0973 25.5274C22.0741 25.4013 22.0191 25.2833 21.9373 25.1845L18.2792 20.7656Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
