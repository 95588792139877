export enum ChannelType {
  Switch = 'Switch',
  Blind = 'Blind',
  Facade = 'Facade',
  Sensor = 'Sensor',
  Transmitter = 'Transmitter',
  Remote = 'Remote',
  Light = 'Light',
  Gate = 'Gate',
  Camera = 'Camera',
  EntryPhone = 'EntryPhone',
  Meter = 'Meter',
  Unknown = 'Unknown',
}

export enum SensorType {
  Temperature = 'Temperature',
  Wind = 'Wind',
  Movement = 'Movement',
  Flood = 'Flood',
  Humidity = 'Humidity',
  MeasuredBrightness = 'MeasuredBrightness',
  Reed = 'Reed',
  Pressure = 'Pressure',
  Unknown = 'Unknown',
}

export enum LightType {
  Dimmer = 'Dimmer',
  Rgb = 'Rgb'
}

export enum GateType {
  Gate = 'Gate',
  GatePrecise = 'GatePrecise'
}