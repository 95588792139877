import { IDeviceChannel } from "lavva.exalushome/build/js/Services/Devices/IDeviceChannel";
import { PHASES_COLORS } from "../../../../../../../const";
import { useMemo } from "react";
import { SelectedPhase } from "../../../../../../channel-details/measurement/types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../../routes";

export const useMeterPhases = (channel?: IDeviceChannel) => {
  const history = useHistory();
  const { t } = useTranslation('channel-details');

  const mappedPhases: SelectedPhase[] = useMemo(() => {
    if (channel) {
      return channel?.GetDevice().Channels?.map((channel) => {
        const label =
          'PowerMeterPhasesSummary' in channel.Configurations ? t('sumOfPhases') : `${t('phase')} ${channel.Number}`;
        return {
          value: channel.Number,
          label,
          color: PHASES_COLORS[channel.Number - 1],
        };
      });
    }

    return [];
  }, [channel?.GetDevice().Channels.length]);

  const handlePhaseChange = (val: number) => {
    const channelId = channel?.GetDevice().Channels.find((x) => x.Number === val)?.ChannelId;

    if (channelId) {
      history.replace(ROUTES.ChannelDetails(channelId));
    }
  };

  return {
    mappedPhases,
    handlePhaseChange,
  }
}