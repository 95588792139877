import React, { ReactNode, useState, MouseEvent } from 'react';
import classNames from 'classnames';
import { Popover } from '@mui/material';
import './index.scss';

interface ComponentProps {
  buttonContent: ReactNode;
  children: ReactNode;
  id: string;
  className?: string;
}

const PopoverCustom: React.FC<ComponentProps> = ({ buttonContent, children, id, className = '' }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <button className="popover-button" onClick={handleClick} aria-describedby={id}>
        {buttonContent}
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        disablePortal
        classes={{ paper: classNames('popover-custom--content', className) }}
        className="popover-custom"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default PopoverCustom;
