const CustomizedTick = (props) => {
  const { x, y, payload } = props;
  const valueParts = payload.value.split(', ');

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666">
        <tspan textAnchor="middle" x="0">
          {valueParts[0]}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20">
          {valueParts[1]}
        </tspan>
      </text>
    </g>
  );
};

export default CustomizedTick;
