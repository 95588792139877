import React from 'react';

export const IconBag: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.59961"
        y="7"
        width="28.8"
        height="18"
        rx="2"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9 10V4C9 2.34315 10.3431 1 12 1H20C21.6569 1 23 2.34315 23 4V10"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
