import React from 'react';
import { TriggerEvent } from '../../../../../types';

interface ComponentProps {
  event: TriggerEvent;
}

export const TriggerEventItem: React.FC<ComponentProps> = ({ event }) => {

  // const parsedTimeOfDay = (time: string) => {
  //   const { hours, minutes } = duration.parse(time);
  //   const timeRepeatAsDate = new Date();
  //   timeRepeatAsDate.setHours(Number(leadingZero(hours)));
  //   timeRepeatAsDate.setMinutes(Number(leadingZero(minutes)));

  //   return formatInTimeZone(
  //     `${format(timeRepeatAsDate, 'yyyy-MM-dd')}T${leadingZero(hours)}:${leadingZero(minutes)}Z`,
  //     selectedInstallation?.payload?.timeZone || 'Europe/Warsaw',
  //     'HH:mm',
  //   );
  // };

  // const time = useMemo(() => {
  //   if (!event.timeCondition) return null;

  //   if (event.timeCondition.atSunset) {
  //     return t('trigger.sunActionOptionsShort.sunset');
  //   } else if (event.timeCondition.atSunrise) {
  //     return t('trigger.sunActionOptionsShort.sunrise');
  //   } else if (event.timeCondition.timeOfDay) {
  //     return parsedTimeOfDay(event.timeCondition.timeOfDay);
  //   }
  // }, [event.timeCondition]);

  return (
    <div className="trigger-event-list__item trigger-event-list__item--background">
      <div className="trigger-event-list__item-icon">{event.icon}</div>
      {/* {time && <div className="channel-value-badge">{time}</div>} */}
    </div>
  );
};
