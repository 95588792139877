import { useCallback } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { EntryPhoneControlHook } from '../types';

export const useEntryPhoneControl = (channel?: IDeviceChannel): EntryPhoneControlHook => {
  const openGateway = useCallback(async () => {
    alert('COMING SOON');
  }, [channel]);

  const openGate = useCallback(async () => {
    alert('COMING SOON');
  }, [channel]);

  const call = useCallback(async () => {
    alert('COMING SOON');
  }, [channel]);

  return {
    openGateway,
    openGate,
    call,
  };
};
