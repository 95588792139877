import React from 'react';

export const IconDocument: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9101 25.4167H3.38037C2.2758 25.4167 1.38037 24.5212 1.38037 23.4167V23.0794V2.875C1.38037 1.77043 2.2758 0.875 3.38037 0.875H10.7141H11.1203C11.6206 0.875 12.1029 1.06257 12.4717 1.40069L18.2616 6.70807C18.6748 7.08689 18.9101 7.62177 18.9101 8.18238V10.8085V21.3264V23.4167C18.9101 24.5212 18.0147 25.4167 16.9101 25.4167Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.05518 9.63965H13.6514"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.05518 14.3145H13.6514"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.05518 18.9893H10.7298"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
