import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface ComponentProps {
  label: string;
  value: string | number;
  big?: boolean;
  reverse?: boolean;
  className?: string;
  inputLabel?: boolean;
  column?: boolean;
}

const TextInfo: React.FC<ComponentProps> = ({ label, value, big, reverse, column, className, inputLabel }) => (
  <div className={classNames('text-info', className, { big: big, reverse: reverse, column: column })}>
    <p className={classNames('label', { 'input-label': inputLabel })}>{label}</p>
    <p className="value">{value}</p>
  </div>
);

export default TextInfo;
