import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface PropsInterface {
  children?: ReactNode;
  width?: number;
  isActive?: boolean;
  className?: string;
}

export const CarouselItem: React.FC<PropsInterface> = ({ children, width, isActive = false, className }) => {
  return (
    <div
      className={classNames('carouselCustom-item', {
        'carouselCustom-item--active': isActive,
        [className as string]: className,
      })}
      style={{ width }}
    >
      {isActive ? children : null}
    </div>
  );
};
