import React from 'react';
import { ChannelInterface } from '../../../types';
import { ChannelDetailsLightRgb } from './components/rgb';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsLight: React.FC<PropsInterface> = ({ channel }) => {
  return <ChannelDetailsLightRgb channel={channel} />;
};

export default ChannelDetailsLight;
