import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { EmptyStateBox, IconWarning, NavHeader, Page, Tabs } from '../../../../components';
import { useBackdropContext } from '../../../../hooks';
import { MoreListItem } from './components/more-list-item';
import { ProductListItem } from './components/product-list-item';
import { useStore } from './hooks/use-store';
import './index.scss';

const ExalusStore: React.FC = () => {
  const { t } = useTranslation('store');
  const { data, activeTabIndex, tabs, channelsPerSelectedType, channelsSeeMore, setActiveTabIndex } = useStore();
  const { backdropOpen } = useBackdropContext();

  return (
    <Page
      className={classNames('exalus-store', { empty: !data?.length })}
      contentClassName="page__content--flex"
      header={
        <>
          <NavHeader title={t('ourProducts')} />
        </>
      }
    >
      <>
        {data?.length ? (
          <>
            <Tabs
              tabList={tabs}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
              tabsType="pills"
              isDivider={false}
            />
            <div className="product-list">
              {channelsPerSelectedType.map((device, i) => (
                <ProductListItem key={i} device={device} />
              ))}
            </div>
            {channelsSeeMore.length ? (
              <div className="see-more">
                <p className="see-more--header">{t('seeMore')}</p>
                <div className="seeMore-list">
                  {channelsSeeMore.map((device, i) => (
                    <MoreListItem key={i} device={device} />
                  ))}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>{!backdropOpen ? <EmptyStateBox content={t('notFoundDevices')} icon={<IconWarning />} /> : null}</>
        )}
      </>
    </Page>
  );
};

export default ExalusStore;
