import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Header, Input, NavHeader, Page, SubmitButton } from '../../../../components';
import { IconSearch } from '../../../../components/icons/icon-search';
import RadiosGroup from '../../../../components/radios-group';
import { useTimeZones } from '../../hooks/use-time-zones';

type ComponentProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  handleClose: () => void;
  handleSave: () => void;
};

const TimeZonesList: React.FC<ComponentProps> = ({ onChange, value, handleClose, handleSave }) => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { search, setSearch, timeZonesOptions } = useTimeZones();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value);

  const debouncedResults = useMemo(() => debounce(handleChange, 300), []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <Page
      kind="above"
      header={
        <>
          <NavHeader onClick={handleClose} />
          <Header title={t('time_zone')} />
        </>
      }
    >
      <Input defaultValue={search} placeholder={tc('search')} onChange={debouncedResults} startIcon={<IconSearch />} />
      <RadiosGroup options={timeZonesOptions} value={value} onChange={onChange} transparent />
      <SubmitButton type="button" onClick={handleSave} />
    </Page>
  );
};

export default TimeZonesList;
