import React from 'react';

export const IconSofa: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-sofa"
  >
    <path
      d="M5.28516 19.8544V9.57018C5.28516 8.1489 6.43754 7 7.85533 7H16.4284C17.8497 7 18.9986 8.15239 18.9986 9.57018C18.9986 8.1489 20.151 7 21.5688 7H30.1419C31.5632 7 32.7121 8.15239 32.7121 9.57018V19.8544"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M18.9976 9.56641V19.8541" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M35.2854 19.8545H2.71463C1.76767 19.8545 1 20.6222 1 21.5691V26.713C1 27.6599 1.76767 28.4276 2.71463 28.4276H35.2854C36.2323 28.4276 37 27.6599 37 26.713V21.5691C37 20.6222 36.2323 19.8545 35.2854 19.8545Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M33.5708 28.4248V30.995" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M4.4292 28.4248V30.995" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>
);
