import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconThreeArrowsPressure: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-three-arrows-pressure"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.16602 21.1213L3.74749 21.8986C12.773 24.6163 22.3989 24.6163 31.4244 21.8986L34.0055 21.1213M1.16602 28.2125L3.74749 28.9898C12.773 31.7074 22.3989 31.7074 31.4244 28.9897L34.0055 28.2125"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      {isOn ? (
        <>
          <path
            d="M8.46325 1.58344C8.46325 1.16922 8.12746 0.833435 7.71325 0.833435C7.29904 0.833435 6.96325 1.16922 6.96325 1.58344L8.46325 1.58344ZM7.71325 15.492L7.17344 16.0126C7.45756 16.3072 7.92538 16.3196 8.22472 16.0405L7.71325 15.492ZM11.993 12.5269C12.296 12.2444 12.3126 11.7699 12.0301 11.4669C11.7476 11.164 11.273 11.1474 10.9701 11.4298L11.993 12.5269ZM4.86403 11.4577C4.57647 11.1596 4.10167 11.151 3.80354 11.4386C3.50541 11.7261 3.49685 12.2009 3.78441 12.499L4.86403 11.4577ZM6.96325 1.58344L6.96325 15.492H8.46325L8.46325 1.58344L6.96325 1.58344ZM8.22472 16.0405L11.993 12.5269L10.9701 11.4298L7.20178 14.9434L8.22472 16.0405ZM8.25306 14.9713L4.86403 11.4577L3.78441 12.499L7.17344 16.0126L8.25306 14.9713Z"
            fill="#15141D"
          />
          <path
            d="M18.3578 4.97791C18.3578 4.56369 18.022 4.22791 17.6078 4.22791C17.1936 4.22791 16.8578 4.56369 16.8578 4.97791L18.3578 4.97791ZM17.6078 18.8864L17.068 19.4071C17.3521 19.7017 17.8199 19.7141 18.1193 19.435L17.6078 18.8864ZM21.8876 15.9214C22.1905 15.6389 22.2071 15.1643 21.9246 14.8614C21.6422 14.5584 21.1676 14.5418 20.8646 14.8243L21.8876 15.9214ZM14.7586 14.8522C14.471 14.554 13.9962 14.5455 13.6981 14.833C13.3999 15.1206 13.3914 15.5954 13.6789 15.8935L14.7586 14.8522ZM16.8578 4.97791L16.8578 18.8864H18.3578L18.3578 4.97791L16.8578 4.97791ZM18.1193 19.435L21.8876 15.9214L20.8646 14.8243L17.0963 18.3379L18.1193 19.435ZM18.1476 18.3658L14.7586 14.8522L13.6789 15.8935L17.068 19.4071L18.1476 18.3658Z"
            fill="#15141D"
          />
          <path
            d="M28.0394 1.58319C28.0394 1.16898 27.7036 0.833191 27.2894 0.833191C26.8752 0.833191 26.5394 1.16898 26.5394 1.58319L28.0394 1.58319ZM27.2894 15.4917L26.7496 16.0124C27.0337 16.307 27.5016 16.3194 27.8009 16.0403L27.2894 15.4917ZM31.5692 12.5267C31.8721 12.2442 31.8887 11.7696 31.6063 11.4667C31.3238 11.1637 30.8492 11.1471 30.5463 11.4296L31.5692 12.5267ZM24.4402 11.4575C24.1526 11.1593 23.6778 11.1508 23.3797 11.4383C23.0816 11.7259 23.073 12.2007 23.3606 12.4988L24.4402 11.4575ZM26.5394 1.58319L26.5394 15.4917H28.0394L28.0394 1.58319L26.5394 1.58319ZM27.8009 16.0403L31.5692 12.5267L30.5463 11.4296L26.778 14.9432L27.8009 16.0403ZM27.8292 14.971L24.4402 11.4575L23.3606 12.4988L26.7496 16.0124L27.8292 14.971Z"
            fill="#15141D"
          />
        </>
      ) : null}
    </svg>
  );
};
