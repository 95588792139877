import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as storage from '../../../utils/storage/lavva';
import { toastInfo, toastSuccess } from '../../../utils/toast';

export const useAboutSettings = () => {
  const { t } = useTranslation('profile');
  const [showAppDataClearDialogConfirmation, setShowAppDataClearDialogConfirmation] = useState<boolean>(false);
  const [debug, setDebug] = useState<number>(0);

  const handleDebug = () => {
    const TOGGLE_DEBUG_AFTER_CLICKS = 3;

    if (debug < TOGGLE_DEBUG_AFTER_CLICKS - 1) {
      setDebug(debug + 1);
    }
    if (debug >= TOGGLE_DEBUG_AFTER_CLICKS - 1) {
      const wasDebugModeOn = storage.getItem('debugMode');
      storage.setItem('debugMode', !wasDebugModeOn);
      toastInfo({ content: `Debug mode: ${!wasDebugModeOn}` });
      setDebug(0);
    }
  };

  const handleClearAppData = () => {
    localStorage.clear();
    toastSuccess({ content: t('clear-app-data.toast') });
    setShowAppDataClearDialogConfirmation(false);
  };

  return {
    handleClearAppData,
    handleDebug,
    showAppDataClearDialogConfirmation,
    setShowAppDataClearDialogConfirmation,
  };
};
