import React, { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { pathnameWithParameters } from '../../utils/location';
import { Header } from '../header';
import { NavHeader } from '../nav-header';
import { Page } from '../page';
import './index.scss';

interface CommonProps {
  children: ReactNode;
  name: string;
  headerTitle?: string;
  helpTitle: string;
  headerSubtitle?: string;
  noBackButton?: boolean;
  helpContent?: ReactNode;
}

interface MainHeaderProps extends CommonProps {
  kind: 'main';
  handleClose: () => void;
}

interface SubHeaderProps extends Omit<CommonProps, 'kind'> {
  kind: 'sub-header';
}

type ComponentProps = MainHeaderProps | SubHeaderProps;

const HeaderWithHelp: React.FC<ComponentProps> = (props) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { children, headerTitle, helpTitle, headerSubtitle, helpContent } = props;
  const { t } = useTranslation('common');
  const [helpVisible, setHelpVisible] = useState<string>('');

  useEffect(() => {
    const help = new URLSearchParams(search).get('help');
    setHelpVisible(help || '');
  }, [search]);

  const openHelpContent = () => {
    history.push(pathnameWithParameters(pathname, search, { key: 'help', value: props.name }));
  };

  const closeHelpContent = () => history.goBack();

  return (
    <>
      {helpVisible !== props.name ? (
        <Page
          className="header-with-help"
          {...(props.kind === 'sub-header' ? { containerClassName: 'no-padding' } : {})}
          header={
            <>
              <>
                {props.kind === 'main' && <NavHeader onClick={props.handleClose} noBackButton={props.noBackButton} />}
                <Header title={headerTitle}>
                  <button className="header-with-help__icon" type="button" onClick={openHelpContent}>
                    ?
                  </button>
                </Header>
              </>
              {headerSubtitle && (
                <h5 className="header__title-subtitle m-b-24 header__title-subtitle--negative-margin">
                  {headerSubtitle}
                </h5>
              )}
            </>
          }
        >
          {children}
        </Page>
      ) : (
        <Page
          kind="above"
          header={
            <>
              <NavHeader onClick={closeHelpContent} />
              <Header title={helpTitle} isUnderline />
            </>
          }
        >
          {helpContent || <Trans>{t('dialogWorkInProgress.header')}</Trans>}
        </Page>
      )}
    </>
  );
};

export default HeaderWithHelp;
