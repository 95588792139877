import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Accordion, Input, Tabs } from '../../../../../../components';
import { useGroupChannels } from '../../hooks/use-group-channels';
import { DeviceChannelsCheck, GroupedDeviceChannelsCheckbox } from '../../types';
import { DeviceChannelsGrouped } from './grouped-channel-box';
import './index.scss';

interface ComponentProps {
  items: GroupedDeviceChannelsCheckbox[];
  setItems: Dispatch<SetStateAction<GroupedDeviceChannelsCheckbox[]>>;
  visibility?: boolean;
}

const GroupedChannels: React.FC<ComponentProps> = ({ items, setItems, visibility }) => {
  const { t: tc } = useTranslation('common');
  const [search, setSearch] = useState<string>('');
  const [activeTabs, setActiveTabs] = useState<number>(0);
  const { tabs, handleChannelOnChange } = useGroupChannels(items, setItems, visibility);

  const onChangeSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value);
  };

  const debouncedResults = useMemo(() => debounce(onChangeSearch, 300), []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const filteredItems = useMemo(
    () =>
      (
        items.map((groupedDeviceChannels: GroupedDeviceChannelsCheckbox) => ({
          ...groupedDeviceChannels,
          devices: groupedDeviceChannels.devices
            .filter(
              (dev: DeviceChannelsCheck) =>
                dev.channels.filter((ch) => ch.channel.Name.toLowerCase().includes(search.toLowerCase())).length > 0,
            )
            .map((dev) => ({
              ...dev,
              channels: dev.channels.filter((ch) => ch.channel.Name.toLowerCase().includes(search.toLowerCase())),
            })),
        })) as GroupedDeviceChannelsCheckbox[]
      ).filter((groupedDeviceChannels: GroupedDeviceChannelsCheckbox) => groupedDeviceChannels.devices.length > 0),
    [items, search],
  );

  return (
    <div className="exalus-grouped-device-channels">
      {!visibility && (
        <Tabs
          tabList={tabs}
          activeTabIndex={activeTabs}
          setActiveTabIndex={setActiveTabs}
          tabsType="default"
          autoScrollDisabled
        />
      )}
      <Input
        defaultValue={search}
        value={search}
        onChange={debouncedResults}
        placeholder={tc('search')}
        noBottomMargin
        autoFocus
      />
      <div className="m-t-16">
        {filteredItems.map((groupedDeviceChannels: GroupedDeviceChannelsCheckbox) => (
          <Accordion
            kind="custom"
            key={groupedDeviceChannels.label}
            title={groupedDeviceChannels.label}
            selectedItems={groupedDeviceChannels.devices.flatMap((x) => x.channels).filter((y) => y.checked).length}
            totalItems={groupedDeviceChannels.devices.flatMap((x) => x.channels).length}
            expanded={!!search}
          >
            <ul className="grid-list-16">
              {groupedDeviceChannels.devices.map((deviceChannels) => (
                <DeviceChannelsGrouped
                  key={deviceChannels.device.Guid}
                  deviceChannels={deviceChannels}
                  groupLabel={groupedDeviceChannels.label}
                  handleChannelOnChange={handleChannelOnChange}
                  visibility={visibility}
                />
              ))}
            </ul>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default GroupedChannels;
