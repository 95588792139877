import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DialogConfirmation, IconBullet, NavHeader, Page, SubmitButton } from '../../../components';
import {
  DeleteInstallationMutation,
  DeleteInstallationMutationVariables,
  InstallationAccessType,
  InstallationGetSettingsQueryVariables,
  IntegrationType,
  Query,
  RemoveUserFromInstallationMutation,
  RemoveUserFromInstallationMutationVariables,
} from '../../../data-access/gql-types/graphql';
import { DELETE_INSTALLATION, REMOVE_USER_FROM_INSTALLATION } from '../../../data-access/mutations/installations';
import { INSTALLATION_GET_SETTINGS } from '../../../data-access/queries/installations';
import { useBackdropContext, useInstallation, useProfile } from '../../../hooks';
import { useInstallationErrors } from '../../../hooks/backend-errors/use-installation-errors';
import { useInvitationErrors } from '../../../hooks/backend-errors/use-invitation-errors';
import { ROUTES } from '../../../routes';
import { isLocalApp } from '../../../utils/helpers/local-app';
import * as exalusStorage from '../../../utils/storage/exalus';
import { toastError } from '../../../utils/toast';
import './installation-remove.scss';

const InstallationRemove: React.FC = () => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { installationId, name } = useParams<{ installationId: string; name: string }>();
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState<number>(10);
  const { setSelectedInstallationId, updateInstallationList, installationList } = useInstallation();
  const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
  const { user } = useProfile();
  const installationErrors = useInstallationErrors();
  const { handleErrors } = useInvitationErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { data, loading } = useQuery<Query, InstallationGetSettingsQueryVariables>(INSTALLATION_GET_SETTINGS, {
    variables: { id: installationId },
    fetchPolicy: 'no-cache', // In case of settings we always want to have fresh data
    skip: isLocalApp,
    onError: () => toastError({ content: tc('errors.downloadData') }),
  });
  const [removeUserFromInstallation, { loading: removeUserFromInstallationLoading }] = useMutation<
    RemoveUserFromInstallationMutation,
    RemoveUserFromInstallationMutationVariables
  >(REMOVE_USER_FROM_INSTALLATION);
  const [deleteInstallation, { loading: deleteInstallationLoading }] = useMutation<
    DeleteInstallationMutation,
    DeleteInstallationMutationVariables
  >(DELETE_INSTALLATION);

  const installationData = useMemo(() => data?.allUserInstallations[0], [data]);

  const onRemoveInstallation = () => {
    if (installationData) {
      turnOnBackdrop();
      if (installationData.accessType === InstallationAccessType.Owner) {
        deleteInstallation({
          variables: {
            input: {
              installationId,
            },
          },
          onCompleted: (data) => {
            turnOffBackdrop();
            if (data.deleteInstallation.result?.ok) {
              const list = Array.from(installationList);
              const index = list.findIndex((x) => x.installationId === installationId);
              if (index !== -1) list.splice(index, 1);
              updateInstallationList(list);

              setRemoveConfirmation(true);
            } else {
              installationErrors.handleErrors(data.deleteInstallation.errors || []);
            }
          },
          onError: () => turnOffBackdrop(),
        });
      } else {
        removeUserFromInstallation({
          variables: {
            input: {
              userId: user.profile.id,
              installationId,
            },
          },
          onCompleted: (data) => {
            turnOffBackdrop();
            if (data.removeUserFromInstallation.result?.ok) {
              setRemoveConfirmation(true);
            } else {
              handleErrors(data.removeUserFromInstallation.errors || []);
            }
          },
          onError: () => turnOffBackdrop(),
        });
      }
    }
  };

  useEffect(() => {
    const end = Date.now() + 10 * 1000;
    const timer = setInterval(() => {
      const duration = end - Date.now();
      const durationInSecond = Math.floor(duration / 1000);

      if (durationInSecond < 0) {
        clearInterval(timer);
      } else if (durationInSecond !== timeLeft) {
        setTimeLeft(durationInSecond);
      }
    }, 200);

    return () => clearInterval(timer);
  }, []);

  const integrationType = useMemo(() => installationData?.integrationType, [installationData]);

  const hideRemoveConfirmation = () => setRemoveConfirmation(false);

  const understandClick = () => {
    hideRemoveConfirmation();
    exalusStorage.clearOneCredentials(installationId);
    setSelectedInstallationId(undefined);

    if (installationData) {
      if (integrationType === IntegrationType.Exalus) {
        window.location.href = ROUTES.Root();
      } else {
        history.go(-2);
      }
    }
  };

  return (
    <Page
      header={
        <>
          <NavHeader />
          <div className="installation-remove__header">
            <div className="installation-remove__header-title">{t('removeInstallationPage.header')}</div>
            <div className="installation-remove__header-subtitle">{t('removeInstallationPage.subtitle', { name })}</div>
          </div>
          <hr />
        </>
      }
    >
      {installationData && (
        <div className="installation-remove__rules">
          <span className="installation-remove__rules-text">{t('removeInstallationPage.rules.header')}</span>
          {integrationType !== IntegrationType.Exalus && (
            <div className="installation-remove__rules-element">
              <div className="installation-remove__rules-bullet">
                <IconBullet />
              </div>
              {t('removeInstallationPage.rules.point1')}
            </div>
          )}
          <div className="installation-remove__rules-element">
            <div className="installation-remove__rules-bullet">
              <IconBullet />
            </div>
            {t(`removeInstallationPage.rules.${integrationType !== IntegrationType.Exalus ? 'point2' : 'point3'}`)}
          </div>
        </div>
      )}
      <hr />
      <SubmitButton
        isLoading={removeUserFromInstallationLoading || deleteInstallationLoading}
        onClick={onRemoveInstallation}
        type="button"
        disabled={timeLeft > 0 || loading}
      >{`${t('removeInstallation')} ${timeLeft > 0 ? `(${timeLeft}s)` : ''}`}</SubmitButton>
      <DialogConfirmation
        show={removeConfirmation}
        setShow={hideRemoveConfirmation}
        content={t('installationRemoveDialog.content', {
          name: name,
        })}
        header={t('installationRemoveDialog.header')}
        primaryBtnText={`${tc('buttons.understand')}`}
        primaryBtnAction={understandClick}
      />
    </Page>
  );
};
export default InstallationRemove;
