import { ActionAdvancedIconTypeEnum, AvailableIcon, ChannelIconTypeEnum, GroupIconTypeEnum } from '../../../types';
import * as icons from '../../icons';
import * as iconsGroup from '../../icons-group';
import {
  IconHand,
  IconAlarm,
  IconApple,
  IconBag,
  IconBall,
  IconBird,
  IconBriefcase,
  IconCar,
  IconCart,
  IconCat,
  IconCinema,
  IconCloset,
  IconCoffee,
  IconDocument,
  IconDog,
  IconDrink,
  IconGuitar,
  IconGym,
  IconHeart,
  IconInside,
  IconLeaf,
  IconLock,
  IconMargarita,
  IconMessage,
  IconMusic,
  IconNight,
  IconOutside,
  IconPhone,
  IconPhoto,
  IconPlay,
  IconPuzzle,
  IconRain,
  IconShopping,
  IconSport,
  IconSun,
  IconTowel,
  IconTravel,
  IconTv,
} from '../../icons/scenario';

export const availableActionAdvancedIcons: AvailableIcon[] = [
  { iconName: ChannelIconTypeEnum.ICON_WOODEN_BLIND, component: <icons.IconWoodenBlind position={30} /> },
  { iconName: ChannelIconTypeEnum.ICON_ROLLER_BLIND, component: <icons.IconRollerBlind position={30} /> },
  { iconName: ChannelIconTypeEnum.ICON_FACADE_BLIND, component: <icons.IconFacadeBlind position={60} /> },
  { iconName: ChannelIconTypeEnum.ICON_SUN, component: <icons.IconSun isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_BULB, component: <icons.IconBulb isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_SMALL_BULB, component: <icons.IconSmallBulb isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_LIGHT_SWITCH, component: <icons.IconLightSwitch isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_BULB_GU10, component: <icons.IconBulbGU10 isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_FLUORESCENT_LAMP, component: <icons.IconFluorescentLamp isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_CEILING_LAMP, component: <icons.IconCeilingLamp isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_FLOOR_LAMP, component: <icons.IconFLoorLamp isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_DESK_LAMP, component: <icons.IconDeskLamp isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_BEDSIDE_LAMP, component: <icons.IconBedsideLamp isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_PLAFON_LAMP, component: <icons.IconPlafonLamp isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_WALL_SCONCE, component: <icons.IconWallSconce isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_RGB_CIRCLES, component: <icons.IconRGBCircles isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_WOODEN_GATE, component: <icons.IconWoodenGate position={90} /> },
  { iconName: ChannelIconTypeEnum.ICON_CURTAIN_BLIND, component: <icons.IconCurtainBlind position={100} /> },
  { iconName: ChannelIconTypeEnum.ICON_HORIZONTAL_GATE, component: <icons.IconHorizontalGate position={90} /> },
  { iconName: ChannelIconTypeEnum.ICON_VERTICAL_GATE, component: <icons.IconVerticalGate position={90} /> },
  { iconName: ChannelIconTypeEnum.ICON_HANDLE, component: <icons.IconHandle /> },
  { iconName: ChannelIconTypeEnum.ICON_PADLOCK, component: <icons.IconPadlock isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_SQUARE_METER, component: <icons.IconSquareMeter isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_CIRCLE_METER, component: <icons.IconCircleMeter isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_PHOTO_CAMERA, component: <icons.IconPhotoCamera /> },
  { iconName: ChannelIconTypeEnum.ICON_ENTRY_PHONE, component: <icons.IconEntryPhone isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_SIGNAL_STATE, component: <icons.IconSignalState signal={100} /> },
  { iconName: ChannelIconTypeEnum.ICON_DOOR, component: <icons.IconDoor /> },
  { iconName: ChannelIconTypeEnum.ICON_THERMOMETER, component: <icons.IconThermometer isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_LEAK, component: <icons.IconLeak isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_HUMAN_MOVEMENT, component: <icons.IconHumanMovement isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_HAND_MOVEMENT, component: <icons.IconHandMovement isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_BIG_DROP, component: <icons.IconBigDrop isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_THREE_DROPS, component: <icons.IconThreeDrops isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_WIND_FAN, component: <icons.IconWindFan isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_BORDER_FAN, component: <icons.IconBorderFan isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_ONE_ARROW_PRESSURE, component: <icons.IconOneArrowPressure isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_BINARY_STATE, component: <icons.IconBinaryState isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_THUNDER, component: <icons.IconThunder isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_THREE_ARROWS_PRESSURE, component: <icons.IconThreeArrowsPressure isOn /> },
  { iconName: ChannelIconTypeEnum.ICON_SMALL_CAMERA, component: <icons.IconSmallCamera isOn /> },
  { iconName: ActionAdvancedIconTypeEnum.DEFAULT, component: <IconHand /> },
  { iconName: ActionAdvancedIconTypeEnum.OUTSIDE, component: <IconOutside /> },
  { iconName: ActionAdvancedIconTypeEnum.HEART, component: <IconHeart /> },
  { iconName: ActionAdvancedIconTypeEnum.BALL, component: <IconBall /> },
  { iconName: ActionAdvancedIconTypeEnum.SPORT, component: <IconSport /> },
  { iconName: ActionAdvancedIconTypeEnum.NIGHT, component: <IconNight /> },
  { iconName: ActionAdvancedIconTypeEnum.BRIEFCASE, component: <IconBriefcase /> },
  { iconName: ActionAdvancedIconTypeEnum.DRINK, component: <IconDrink /> },
  { iconName: ActionAdvancedIconTypeEnum.TV, component: <IconTv /> },
  { iconName: ActionAdvancedIconTypeEnum.DOCUMENT, component: <IconDocument /> },
  { iconName: ActionAdvancedIconTypeEnum.BAG, component: <IconBag /> },
  { iconName: ActionAdvancedIconTypeEnum.SHOPPING, component: <IconShopping /> },
  { iconName: ActionAdvancedIconTypeEnum.LOCK, component: <IconLock /> },
  { iconName: ActionAdvancedIconTypeEnum.CAR, component: <IconCar /> },
  { iconName: ActionAdvancedIconTypeEnum.CLOSET, component: <IconCloset /> },
  { iconName: ActionAdvancedIconTypeEnum.MUSIC, component: <IconMusic /> },
  { iconName: ActionAdvancedIconTypeEnum.INSIDE, component: <IconInside /> },
  { iconName: ActionAdvancedIconTypeEnum.GYM, component: <IconGym /> },
  { iconName: ActionAdvancedIconTypeEnum.COFFEE, component: <IconCoffee /> },
  { iconName: ActionAdvancedIconTypeEnum.APPLE, component: <IconApple /> },
  { iconName: ActionAdvancedIconTypeEnum.TRAVEL, component: <IconTravel /> },
  { iconName: ActionAdvancedIconTypeEnum.TOWEL, component: <IconTowel /> },
  { iconName: ActionAdvancedIconTypeEnum.ALARM, component: <IconAlarm /> },
  { iconName: ActionAdvancedIconTypeEnum.PHONE, component: <IconPhone /> },
  { iconName: ActionAdvancedIconTypeEnum.PHOTO, component: <IconPhoto /> },
  { iconName: ActionAdvancedIconTypeEnum.LEAF, component: <IconLeaf /> },
  { iconName: ActionAdvancedIconTypeEnum.CART, component: <IconCart /> },
  { iconName: ActionAdvancedIconTypeEnum.RAIN, component: <IconRain /> },
  { iconName: ActionAdvancedIconTypeEnum.CAT, component: <IconCat /> },
  { iconName: ActionAdvancedIconTypeEnum.DOG, component: <IconDog /> },
  { iconName: ActionAdvancedIconTypeEnum.BIRD, component: <IconBird /> },
  { iconName: ActionAdvancedIconTypeEnum.PUZZLE, component: <IconPuzzle /> },
  { iconName: ActionAdvancedIconTypeEnum.GUITAR, component: <IconGuitar /> },
  { iconName: ActionAdvancedIconTypeEnum.MESSAGE, component: <IconMessage /> },
  { iconName: ActionAdvancedIconTypeEnum.SUN, component: <IconSun /> },
  { iconName: ActionAdvancedIconTypeEnum.PLAY, component: <IconPlay /> },
  { iconName: ActionAdvancedIconTypeEnum.MARGARITA, component: <IconMargarita /> },
  { iconName: ActionAdvancedIconTypeEnum.CINEMA, component: <IconCinema /> },
];

export const groupIcons: AvailableIcon[] = [
  { iconName: GroupIconTypeEnum.SOFA, component: <iconsGroup.IconSofa /> },
  { iconName: GroupIconTypeEnum.TV_2, component: <iconsGroup.IconTV2 /> },
  { iconName: GroupIconTypeEnum.OVEN, component: <iconsGroup.IconOven /> },
  { iconName: GroupIconTypeEnum.HOOD, component: <iconsGroup.IconHood /> },
  { iconName: GroupIconTypeEnum.FRIDGE, component: <iconsGroup.IconFridge /> },
  { iconName: GroupIconTypeEnum.TABLE_SETTING, component: <iconsGroup.IconTableSetting /> },
  { iconName: GroupIconTypeEnum.TABLE_CHAIRS, component: <iconsGroup.IconTableChairs /> },
  { iconName: GroupIconTypeEnum.CUTLERY, component: <iconsGroup.IconCutlery /> },
  { iconName: GroupIconTypeEnum.SHOWER, component: <iconsGroup.IconShower /> },
  { iconName: GroupIconTypeEnum.SINK, component: <iconsGroup.IconSink /> },
  { iconName: GroupIconTypeEnum.BED, component: <iconsGroup.IconBed /> },
  { iconName: GroupIconTypeEnum.WARDROBE, component: <iconsGroup.IconWardrobe /> },
  { iconName: GroupIconTypeEnum.BOTTLE, component: <iconsGroup.IconBottle /> },
  { iconName: GroupIconTypeEnum.BABY_BED, component: <iconsGroup.IconBabyBed /> },
  { iconName: GroupIconTypeEnum.BABY, component: <iconsGroup.IconBaby /> },
  { iconName: GroupIconTypeEnum.DESK, component: <iconsGroup.IconDesk /> },
  { iconName: GroupIconTypeEnum.LAPTOP, component: <iconsGroup.IconLaptop /> },
  { iconName: GroupIconTypeEnum.MIRROR, component: <iconsGroup.IconMirror /> },
  { iconName: GroupIconTypeEnum.GARAGE, component: <iconsGroup.IconGarage /> },
  { iconName: GroupIconTypeEnum.SHED, component: <iconsGroup.IconShed /> },
  { iconName: GroupIconTypeEnum.CAR_SMALL, component: <iconsGroup.IconCarSmall /> },
  { iconName: GroupIconTypeEnum.WRENCH_TOOL, component: <iconsGroup.IconWrenchTool /> },
  { iconName: GroupIconTypeEnum.PLANT, component: <iconsGroup.IconPlant /> },
  { iconName: GroupIconTypeEnum.LEAF_2, component: <iconsGroup.IconLeaf /> },
  { iconName: GroupIconTypeEnum.TREE, component: <iconsGroup.IconTree /> },
  { iconName: GroupIconTypeEnum.BARREL, component: <iconsGroup.IconBarrel /> },
  { iconName: GroupIconTypeEnum.CHEST, component: <iconsGroup.IconChest /> },
  { iconName: GroupIconTypeEnum.ARMCHAIR, component: <iconsGroup.IconArmchair /> },
  { iconName: GroupIconTypeEnum.UMBRELLA, component: <iconsGroup.IconUmbrella /> },
  { iconName: GroupIconTypeEnum.BOOK, component: <iconsGroup.IconBook /> },
  { iconName: GroupIconTypeEnum.NOTEBOOK, component: <iconsGroup.IconNotebook /> },
  { iconName: GroupIconTypeEnum.PUZZLE_2, component: <iconsGroup.IconPuzzle2 /> },
  { iconName: GroupIconTypeEnum.BLOCKS, component: <iconsGroup.IconBlocks /> },
  { iconName: GroupIconTypeEnum.WASHING_MACHINE, component: <iconsGroup.IconWashingMachine /> },
  { iconName: GroupIconTypeEnum.LAUNDRY, component: <iconsGroup.IconLaundry /> },
  { iconName: GroupIconTypeEnum.PROJECTOR, component: <iconsGroup.IconProjector /> },
  { iconName: GroupIconTypeEnum.HAMMER, component: <iconsGroup.IconHammer /> },
  { iconName: GroupIconTypeEnum.SCREWDRIVER, component: <iconsGroup.IconScrewDriver /> },
  { iconName: GroupIconTypeEnum.FURNACE, component: <iconsGroup.IconFurnace /> },
  { iconName: GroupIconTypeEnum.METER, component: <iconsGroup.IconMeter /> },
  { iconName: GroupIconTypeEnum.MELODY, component: <iconsGroup.IconMelody /> },
  { iconName: GroupIconTypeEnum.BATH, component: <iconsGroup.IconBath /> },
  { iconName: GroupIconTypeEnum.GRILL, component: <iconsGroup.IconGrill /> },
  { iconName: GroupIconTypeEnum.MODULE_DEVICE, component: <iconsGroup.IconModuleDevice /> },
  { iconName: GroupIconTypeEnum.PLUG, component: <iconsGroup.IconPlug isOn={false} /> },
  { iconName: GroupIconTypeEnum.SOCKET, component: <iconsGroup.IconSocket /> },
];

export const iconsBackwardCompatibility: AvailableIcon[] = [
  { iconName: 'DEVICE_ICON_0', component: <icons.IconSun isOn /> },
  { iconName: 'DEVICE_ICON_1', component: <icons.IconBulb isOn /> },
  { iconName: 'DEVICE_ICON_2', component: <icons.IconSmallBulb isOn /> },
  { iconName: 'DEVICE_ICON_3', component: <icons.IconLightSwitch isOn /> },
  { iconName: 'DEVICE_ICON_4', component: <icons.IconPadlock isOn /> },
  { iconName: 'DEVICE_ICON_5', component: <icons.IconBulbGU10 isOn /> },
  { iconName: 'DEVICE_ICON_6', component: <icons.IconFluorescentLamp isOn /> },
  { iconName: 'DEVICE_ICON_7', component: <icons.IconCeilingLamp isOn /> },
  { iconName: 'DEVICE_ICON_8', component: <icons.IconFLoorLamp isOn /> },
  { iconName: 'DEVICE_ICON_9', component: <icons.IconDeskLamp isOn /> },
  { iconName: 'DEVICE_ICON_10', component: <icons.IconBedsideLamp isOn /> },
  { iconName: 'DEVICE_ICON_11', component: <icons.IconPlafonLamp isOn /> },
  { iconName: 'DEVICE_ICON_12', component: <icons.IconWallSconce isOn /> },
  { iconName: 'DEVICE_ICON_13', component: <icons.IconRGBCircles isOn /> },
  { iconName: 'DEVICE_ICON_14', component: <icons.IconWoodenBlind /> },
  { iconName: 'DEVICE_ICON_15', component: <icons.IconRollerBlind /> },
  { iconName: 'DEVICE_ICON_16', component: <icons.IconWoodenGate /> },
  { iconName: 'DEVICE_ICON_17', component: <icons.IconCurtainBlind position={100} /> },
  { iconName: 'DEVICE_ICON_18', component: <icons.IconHorizontalGate position={0} /> },
  { iconName: 'DEVICE_ICON_20', component: <icons.IconHandle /> },
  { iconName: 'DEVICE_ICON_21', component: <icons.IconDoor /> },
  { iconName: 'DEVICE_ICON_23', component: <icons.IconThermometer isOn /> },
  { iconName: 'DEVICE_ICON_24', component: <icons.IconLeak isOn /> },
  { iconName: 'DEVICE_ICON_25', component: <icons.IconHumanMovement isOn /> },
  { iconName: 'DEVICE_ICON_26', component: <icons.IconHandMovement isOn /> },
  { iconName: 'DEVICE_ICON_27', component: <icons.IconBigDrop isOn /> },
  { iconName: 'DEVICE_ICON_28', component: <icons.IconThreeDrops isOn /> },
  { iconName: 'DEVICE_ICON_29', component: <icons.IconWindFan isOn /> },
  { iconName: 'DEVICE_ICON_30', component: <icons.IconBorderFan isOn /> },
  { iconName: 'DEVICE_ICON_31', component: <icons.IconOneArrowPressure isOn /> },
  { iconName: 'DEVICE_ICON_32', component: <icons.IconBinaryState isOn={false} /> },
  { iconName: 'DEVICE_ICON_33', component: <icons.IconSquareMeter isOn /> },
  { iconName: 'DEVICE_ICON_34', component: <icons.IconCircleMeter isOn /> },
  { iconName: 'DEVICE_ICON_35', component: <icons.IconThunder isOn /> },
  { iconName: 'DEVICE_ICON_36', component: <icons.IconSignalState signal={100} /> },
  { iconName: 'DEVICE_ICON_37', component: <icons.IconThreeArrowsPressure isOn /> },
  { iconName: 'DEVICE_ICON_38', component: <icons.IconSmallCamera isOn={false} /> },
  { iconName: 'DEVICE_ICON_40', component: <icons.IconFacadeBlind position={0} /> },
  { iconName: 'DEVICE_ICON_41', component: <icons.IconVerticalGate position={0} /> },
  { iconName: 'DEVICE_ICON_42', component: <icons.IconPhotoCamera /> },
  { iconName: 'DEVICE_ICON_43', component: <icons.IconEntryPhone /> },
];

export const availableGroupIcons = [
  { iconName: '', component: <div /> },
  ...groupIcons,
  ...availableActionAdvancedIcons,
];
