import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, LoaderDots, NavHeader, Page, PopUpNav, SubmitButton } from '../../../../components';
import { IconPointer } from '../../../../components/icons/icon-pointer';
import { IconDelete } from '../../../../components/popup-nav/icons';
import { IntegrationType } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';
import { useLocationCoords } from '../../hooks/use-location-coords';
import { LocationCoords } from '../../types';
import { useLocationContext } from '../location/context/location.context';
import MapComponent from '../map';
import RemoveLocation from '../remove-location';
import './index.scss';

type ComponentProps = {
  handleLocationSet: (coords: LocationCoords) => void;
};

const LocationMap: React.FC<ComponentProps> = ({ handleLocationSet }) => {
  const { t } = useTranslation('installation');
  const [loader, setLoader] = useState<boolean>(false);
  const [removePopup, setRemovePopup] = useState<boolean>(false);
  const { setCoords, coords, goBack, goHome } = useLocationContext();
  const { getCoords } = useLocationCoords(setLoader);
  const { integrationType } = useInstallation();

  const saveLocation = () => {
    handleLocationSet({ latitude: coords.latitude, longitude: coords.longitude });
    goHome();
  };

  const removeLocation = () => {
    setCoords({ latitude: null, longitude: null });
    setRemovePopup(false);
    handleLocationSet({ latitude: null, longitude: null });
    goHome();
  };

  const showRemovePopup = (value: boolean) => setRemovePopup(value);

  return (
    <Page
      kind="above"
      className="location_map_page"
      header={
        <>
          <NavHeader onClick={goBack}>
            {integrationType !== IntegrationType.Exalus && (
              <PopUpNav
                dark
                links={[{ label: t('locationRemove'), onClick: () => showRemovePopup(true), icon: <IconDelete /> }]}
              />
            )}
          </NavHeader>
          <Header title={t('installationLocation')} />
        </>
      }
    >
      <MapComponent />
      <button className="my_location" onClick={getCoords} type="button">
        {loader ? <LoaderDots /> : <IconPointer />}
      </button>
      <div className="submit_location">
        <p className="address">{coords.latitude && coords.longitude && `${coords.latitude}, ${coords.longitude}`}</p>
        <SubmitButton type="button" onClick={saveLocation} />
      </div>
      <RemoveLocation show={removePopup} setShow={() => showRemovePopup(false)} onConfirm={removeLocation} />
    </Page>
  );
};

export default LocationMap;
