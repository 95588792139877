import React from 'react';
import { Markup } from 'react-render-markup';
import { useFetchDocuments } from '../../../hooks/documents/use-fetch-documents';

const Terms: React.FC = () => {
  const { content } = useFetchDocuments('terms');

  return <Markup markup={content} />;
};

export default Terms;
