import { useMemo } from 'react';
import { ChannelTypeInternal, LightModeTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useLight } from '../../../../hooks';
import { ChannelInterface, ChannelLightInterface, RGBType } from '../../../../types';

export const useRgb = (channel: ChannelInterface) => {
  const { handleControlOn, isOn } = useLight(channel);

  const rgbType = useMemo(() => {
    if (channel.data.type === ChannelTypeInternal.Light) {
      const supported = (channel.data as ChannelLightInterface)?.supportedModes || [];

      if (!supported.find((x) => x === LightModeTypeInternal.Colors)) {
        return supported.find((x) => x === LightModeTypeInternal.Temperature) ? RGBType.WW : RGBType.WHITE;
      } else {
        if (supported.find((x) => x === LightModeTypeInternal.Temperature)) {
          return RGBType.RGBW;
        }

        return RGBType.RGB;
      }
    }

    return null;
  }, [channel.data]);

  const hasBrightness = useMemo(() => {
    return (
      channel.data.type === ChannelTypeInternal.Light &&
      ((channel.data as ChannelLightInterface)?.supportedModes || []).includes(LightModeTypeInternal.Brightness)
    );
  }, [channel.data]);

  const hasRGB = useMemo(() => {
    return (
      channel.data.type === ChannelTypeInternal.Light &&
      ((channel.data as ChannelLightInterface)?.supportedModes || []).includes(LightModeTypeInternal.Colors)
    );
  }, [channel.data]);

  return {
    rgbType,
    isOn,
    handleControlOn,
    hasBrightness,
    hasRGB,
  };
};
