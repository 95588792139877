import React, { Dispatch, SetStateAction } from 'react';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { ISrp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp30ConfigService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../../../../../routes';
import { useBlindControl } from '../../../../../components/channel/hooks/use-blind-control';
import { BlindDetailsSimpleControlButtons } from '../../../../../components/control-buttons/blind-details/simple';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useTasks } from '../../../../channel-list/hooks/use-tasks';
import { useConfigurationService } from '../../../../device-details/hooks/use-configuration-service';
import { useCalibration } from '../hooks/use-calibration';
import './index.scss';

interface ComponentProps {
  setStep: Dispatch<SetStateAction<number>>;
}

const AutoCalibrationBlind1: React.FC<ComponentProps> = ({ setStep }) => {
  const { channel, history, backdropOpen, t, turnOnBackdrop, turnOffBackdrop } = useCalibration();
  const { configService: serviceName } = useConfigurationService(channel?.GetDevice());
  const { open, stop, close } = useBlindControl(channel);
  const { handleError } = useHandleDataFrameErrors();
  const { active } = useTasks(channel);
  usePreventBack(backdropOpen ? t('exalus.params.ZfBidiParams.calibrationFlow.noBackMessage') : '');

  const handleClick = () => {
    if (channel) {
      setStep(2);
      history.push(`${ROUTES.BlindAutoCalibration(channel.ChannelId)}?step=2`);
    }
  };

  const changeDirections = async () => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISrp30ConfigService>(serviceName);

      const result: Status = await configService.SwapRelaysAsync(channel?.GetDevice(), channel.Number);

      if (result !== Status.OK) handleError(result);
      turnOffBackdrop();
    }
  };

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      <div>
        <BlindDetailsSimpleControlButtons open={open} stop={stop} close={close} active={active} />
        <p className="info m-t-40">{t('exalus.autoCalibrationFlow.checkDirections')}</p>
        <button className="button button--secondary m-t-24" onClick={changeDirections}>
          {t('exalus.autoCalibrationFlow.changeDirections')}
        </button>
        <SubmitButton type="button" onClick={handleClick} tranparent>
          {t('exalus.autoCalibrationFlow.next')}
        </SubmitButton>
      </div>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default AutoCalibrationBlind1;
