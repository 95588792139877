import React from 'react';

export const IconFridge: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-fridge"
  >
    <path
      d="M10.5 34.7092V34.7092C9.67169 34.7092 9.00021 34.0377 9.00021 33.2094L9.00001 3.50001C9.00001 2.39544 9.89544 1.5 11 1.5H27C28.1046 1.5 29 2.39543 29 3.5L29 32.7092C29 33.8138 28.1046 34.7092 27 34.7092V34.7092M10.5 34.7092V37H14.5V34.7092M10.5 34.7092H14.5M14.5 34.7092H22.8188M27 34.7092V37H22.8188V34.7092M27 34.7092H22.8188"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.14062 11.3123H28.8458"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0781 4.22229V8.47628"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0781 19.6429V24.6059"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
