import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { useLavvaCreateActionContext } from '../context';
import { NewActionForm } from '../types';

export const useActionCreate = () => {
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const { setName, setIcon } = useLavvaCreateActionContext();

  useEffect(() => {
    setName('');
    setIcon('');
  }, []);

  const onSubmitNameIcon = (formData: NewActionForm) => {
    setName(formData.name);
    setIcon(formData.icon_name);

    history.push(!actionId ? ROUTES.ActionCreateTasks() : ROUTES.ActionEditTasks(actionId));
  };

  return {
    onSubmitNameIcon,
  };
};
