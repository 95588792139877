import { gql } from '@apollo/client';

export const SHARE = gql`
  mutation Share($input: ShareInput!) {
    share(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          error
          message
        }
      }
    }
  }
`;

export const PASS_OWNERSHIP_TO_INSTALLATION = gql`
  mutation PassOwnershipToInstallation($input: PassOwnershipToInstallationInput!) {
    passOwnershipToInstallation(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          error
          message
        }
      }
    }
  }
`;

export const CHANGE_USER_ROLE = gql`
  mutation changeUserRole($input: ChangeUserRoleInput!) {
    changeUserRole(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          error
          message
        }
      }
    }
  }
`;

export const MODIFY_USER_ACCESSES = gql`
  mutation ModifyUserAccesses($input: ModifyUserAccessesInput!) {
    modifyUserAccesses(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          message
          error
        }
      }
    }
  }
`;
