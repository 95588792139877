import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useRouter = () => {
  const history = useHistory();
  const location = useLocation();

  const isBackAvailable = useMemo(() => !!location.key, [location]);

  const handleReplaceRedirect = useCallback((route: string) => {
    history.replace(route);
  }, []);

  return {
    isBackAvailable,
    handleReplaceRedirect,
  };
};
