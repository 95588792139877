import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { useQuery, useSubscription } from '@apollo/client';
import {
  ChannelTypeInternal,
  DevicesGetAllQueryVariables,
  OnConfigurationTaintedSubscription,
  OnConfigurationTaintedSubscriptionVariables,
  OnDeviceNameChangedSubscription,
  OnDeviceNameChangedSubscriptionVariables,
  Query,
  UserDevice,
} from '../../data-access/gql-types/graphql';
import { DEVICES_GET_ALL } from '../../data-access/queries/devices';
import {
  ON_CONFIGURATION_TAINTED,
  ON_DEVICE_CONNECTION_STATE_CHANGE,
} from '../../data-access/subscriptions/lavva-devices';
import { ON_DEVICE_NAME_CHANGED } from '../../data-access/subscriptions/on-device-name-changed';
import { DeviceInterface } from '../../types';
import { useUpdateCommonLavvaState } from '../../utils/channels/subscriptions/common-lavva-state';
import { convertErrorsToOneString } from '../../utils/helpers';
import { isDevelopment } from '../../utils/helpers/environment';
import { toastError, toastSuccess } from '../../utils/toast';
import { useDevicesAndChannels } from '../devices-and-channels';
import { useInstallation } from '../installation';

interface HookInterface {
  devicesLoading: boolean;
}

export default function (): HookInterface {
  const { t } = useTranslation('channel-settings');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { setDeviceList, editDeviceName, channelList } = useDevicesAndChannels();
  const { onDeviceConnectionStateChange } = useUpdateCommonLavvaState();
  const { data: devicesData, loading: devicesLoading } = useQuery<Query, DevicesGetAllQueryVariables>(DEVICES_GET_ALL, {
    variables: { installationId: selectedInstallationId },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onError: () => toastError({ content: tc('errors.downloadData') }),
    skip: skipLavvaFetch,
  });
  const { data } = useSubscription<OnDeviceNameChangedSubscription, OnDeviceNameChangedSubscriptionVariables>(
    ON_DEVICE_NAME_CHANGED,
    {
      variables: { installationId: selectedInstallationId },
      skip: skipLavvaFetch,
    },
  );
  const { data: configurationTainted } = useSubscription<
    OnConfigurationTaintedSubscription,
    OnConfigurationTaintedSubscriptionVariables
  >(ON_CONFIGURATION_TAINTED, {
    variables: { installationId: selectedInstallationId },
    skip: skipLavvaFetch,
  });

  const { data: deviceConnection } = useSubscription(ON_DEVICE_CONNECTION_STATE_CHANGE, {
    variables: { installationId: selectedInstallationId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (deviceConnection) onDeviceConnectionStateChange(deviceConnection.onDeviceConnectionStateChange);
  }, [deviceConnection]);

  useEffect(() => {
    if (data?.onDeviceNameChanged) {
      const { deviceId, name } = data.onDeviceNameChanged;
      editDeviceName(deviceId, name);
      toastSuccess({ content: t('toast.editNameSuccess') });
    }
  }, [data]);

  useEffect(() => {
    if (configurationTainted?.onConfigurationTaintedPayload && isDevelopment) {
      const { channelId, failures } = configurationTainted.onConfigurationTaintedPayload;

      console.log('CONFIGURATION TAINTED', { channelId, failures });

      const channel = channelList.find((x) => x.id === channelId);
      toastError({
        content: `${t('configurationTainted', { alias: channel?.alias })}: \r\n${convertErrorsToOneString(failures)}`,
      });
    }
  }, [configurationTainted]);

  const getType = (userDevice: UserDevice) => {
    const types = groupBy(
      userDevice.payload?.channelInfos?.filter((x) => x?.channelType !== ChannelTypeInternal.GenericInput),
      'channelType',
    );

    if (Object.keys(types).includes(ChannelTypeInternal.Blind)) {
      return ChannelTypeInternal.Blind;
    }
    if (Object.keys(types).includes(ChannelTypeInternal.Gate)) {
      return ChannelTypeInternal.Gate;
    }
    if (Object.keys(types).includes(ChannelTypeInternal.Switch)) {
      return ChannelTypeInternal.Switch;
    }
    if (Object.keys(types).includes(ChannelTypeInternal.Light)) {
      return ChannelTypeInternal.Light;
    }
    if (Object.keys(types).includes(ChannelTypeInternal.Meter)) {
      return ChannelTypeInternal.Meter;
    }
    if (Object.keys(types).includes(ChannelTypeInternal.Optimizer)) {
      return ChannelTypeInternal.Optimizer;
    }

    return ChannelTypeInternal.Unknown;
  };

  useEffect(() => {
    if (devicesData?.allUserDevices) {
      if (!selectedInstallationId) return;
      const updatedDevices: DeviceInterface[] = [];

      for (const userDevice of devicesData.allUserDevices) {
        if (userDevice.installationId !== selectedInstallationId) return;

        const newDevice: DeviceInterface = {
          id: userDevice.id,
          installationId: userDevice.installationId,
          type: getType(userDevice),
          isUpdating: false,
          payload: userDevice.payload
            ? { ...userDevice.payload }
            : {
                __typename: 'DeviceStateModel',
                channelInfos: [],
                connectedToSsid: '',
                macAddress: '',
                currentFirmwareVersion: '',
                deviceId: '',
                isUpdateAvailable: false,
                model: '',
                name: '',
                newestFirmwareVersion: '',
                timeZone: '',
                lastConnectedAtUtc: '',
                registeredAtUtc: '',
              },
        };

        updatedDevices.push(newDevice);
      }

      setDeviceList(updatedDevices);
    }
  }, [devicesData]);

  return { devicesLoading };
}
