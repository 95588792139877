import { gql } from '@apollo/client';

export const CHANNEL_VISIBILITY = gql`
  mutation ChangeChannelsVisibility($input: ChangeChannelsVisibilityInput!) {
    changeChannelsVisibility(input: $input) {
      result {
        ok
      }
      errors {
        ... on ChannelError {
          error
          message
        }
      }
    }
  }
`;

export const CHANNEL_SET_FAVOURITE_POSITIONS = gql`
  mutation SetFavouriteChannelPositions($input: SetChannelFavouritePositionRequestInput!) {
    setFavouriteChannelPositions(input: $input) {
      ok
    }
  }
`;

export const SET_CHANNEL_ICON_NAME = gql`
  mutation SetChannelIconName($channelId: UUID!, $iconName: String!) {
    setChannelIconName(channelId: $channelId, iconName: $iconName) {
      ok
    }
  }
`;

export const EDIT_USER_CHANNEL = gql`
  mutation EditUserChannel($input: EditUserChannelInput!) {
    editUserChannel(input: $input) {
      result {
        ok
      }
      errors {
        ... on ChannelError {
          error
          message
        }
      }
    }
  }
`;

export const SET_QUICK_CONTROL = gql`
  mutation SetQuickControl($input: SetQuickControlInput!) {
    setQuickControl(input: $input) {
      result {
        ok
      }
      errors {
        ... on ChannelError {
          error
          message
        }
      }
    }
  }
`;

