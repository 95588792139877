import React from 'react';
import { IconType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { availableIcon } from './available-icons';

// import {
//   IconExalusDeviceDefault,
//   IconExalusDeviceNxPxBidi,
//   IconExalusDeviceRnk,
//   IconExalusDeviceTr7,
// } from '../../../../../components/icons/device/exalus';
// import { DeviceModel } from '../../../enums';

interface PropsInterface {
  iconType?: IconType;
}

export const DeviceIcon: React.FC<PropsInterface> = ({ iconType }) =>
  iconType ? availableIcon[iconType] : availableIcon[IconType.Unknown];
