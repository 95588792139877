import React, { useState } from 'react';
import { StepComponentProps } from '../../steps';
import { ConditionCreator } from './components/condition-creator';
import { ChannelList } from './components/channel-list';

interface PropsInterface extends StepComponentProps {
  channelId: string;
  setChannelId: (id: string) => void;
}

export const StateCondition: React.FC<PropsInterface> = ({
  channelId,
  setChannelId,
  setConditionType,
  goToChannels,
  goToSummary,
}) => {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(channelId || null);

  if (selectedChannel) {
    return (
      <ConditionCreator
        channelId={channelId}
        setChannelId={setChannelId}
        selectedChannel={selectedChannel}
        goToChannels={goToChannels}
        goToSummary={goToSummary}
        setSelectedChannel={setSelectedChannel}
      />
    );
  }
  return <ChannelList setConditionType={setConditionType} setSelectedChannel={setSelectedChannel} />;
};
