import { gql } from '@apollo/client';

export const SET_FAVOURITE_ACTION_POSITIONS = gql`
  mutation SetFavouriteActionPositions($input: SetActionFavouritePositionRequestInput!) {
    setFavouriteActionPositions(input: $input) {
      ok
    }
  }
`;

export const CREATE_BASIC_ACTION = gql`
  mutation CreateBasicAction($input: CreateBasicActionRequestInput!) {
    createBasicAction(input: $input) {
      id
    }
  }
`;

export const CREATE_ADVANCED_ACTION = gql`
  mutation CreateAdvancedAction($input: CreateAdvancedActionRequestInput!) {
    createAdvancedAction(input: $input) {
      id
    }
  }
`;

export const EDIT_ACTION = gql`
  mutation EditAction($input: EditActionInput!) {
    editAction(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on ActionError {
          error
          message
        }
      }
    }
  }
`;

export const DELETE_ACTION = gql`
  mutation DeleteAction($input: DeleteActionInput!) {
    deleteAction(input: $input) {
      result {
        ok
      }
      errors {
        ... on ActionError {
          error
          message
        }
      }
    }
  }
`;
