import React, { createContext, useContext, Dispatch, SetStateAction, useState, useCallback } from 'react';
import { ActionType, DashboardItemType, GroupItemRequestItemInput } from '../../../data-access/gql-types/graphql';
import { useApi } from '../../../hooks';

export type StateType = {
  selectedChannels: string[];
  setSelectedChannels: Dispatch<SetStateAction<string[]>>;
  selectedActions: string[];
  setSelectedActions: Dispatch<SetStateAction<string[]>>;
  isPristine: boolean;
  setIsPristine: Dispatch<SetStateAction<boolean>>;
  prepareGroupItems: () => GroupItemRequestItemInput[];
};

const FormChannelsContext = createContext<StateType | undefined>(undefined);

export const useFormChannelsContext = (): StateType => useContext(FormChannelsContext) as StateType;

export const FormChannelsContextProvider: React.FC = ({ children }) => {
  const { userActions } = useApi();
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [isPristine, setIsPristine] = useState<boolean>(true);

  const prepareGroupItems = useCallback(() => {
    return [...selectedChannels, ...selectedActions].map((itemId, index) => {
      const getType = (itemId: string) => {
        const userAction = userActions.find((userAction) => userAction && userAction.action?.id === itemId);

        if (userAction) {
          return userAction.action.actionType === ActionType.Central
            ? DashboardItemType.Central
            : DashboardItemType.Action;
        }

        return DashboardItemType.Channel;
      };

      return {
        itemId,
        order: index,
        type: getType(itemId),
      };
    });
  }, [selectedChannels, selectedActions, userActions]);

  const values = {
    selectedChannels,
    setSelectedChannels,
    selectedActions,
    setSelectedActions,
    isPristine,
    setIsPristine,
    prepareGroupItems,
  };

  return <FormChannelsContext.Provider value={{ ...values }}>{children}</FormChannelsContext.Provider>;
};
