import React from 'react';
import { defaultFavouritePositionList } from '../../../const';
import { ActionType, ChannelTypeInternal, GateModeInternal, UserAction } from '../../../data-access/gql-types/graphql';
import { ChannelActionDetailsInterface, FavouritePosition } from '../../../types';
import { ChannelGateInterface } from '../../../types/channel/gate';
import AdvancedActionSwitch from '../advanced-action-switch';
import CoverAction from '../cover';
import GateAction from '../gate';
import LightAction from '../light';
import SwitchAction from '../switch';

interface PropsInterface {
  userAction: UserAction;
  channels: ChannelActionDetailsInterface[];
}
const ActionControl: React.FC<PropsInterface> = ({ userAction, channels }) => {
  if (userAction.action.actionType === ActionType.Advanced) {
    return <AdvancedActionSwitch action={userAction} />;
  }

  switch (userAction.action.channelType) {
    case ChannelTypeInternal.Blind:
      return (
        <CoverAction
          action={userAction.action}
          channels={channels}
          favouritePositions={
            (userAction.favouritePositions?.length
              ? userAction.favouritePositions
              : defaultFavouritePositionList) as FavouritePosition | null
          }
        />
      );
    case ChannelTypeInternal.Gate:
      return (
        <GateAction
          channels={channels.filter((x) => (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp)}
        />
      );
    case ChannelTypeInternal.Light:
      return <LightAction channels={channels} />;
    case ChannelTypeInternal.Switch:
      return <SwitchAction channels={channels} />;
    default:
      return null;
  }
};

export default ActionControl;
