import React from 'react';
import './index.scss';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconFluorescentLamp: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-switch-bigger icon-fluorescent-lamp"
    >
      <path
        d="M13.5 31.5H11.5C10.9477 31.5 10.5 31.0523 10.5 30.5V29.625M13.5 31.5V24M13.5 31.5H41.5M13.5 24H11.5C10.9477 24 10.5 24.4477 10.5 25V25.875M13.5 24H41.5M41.5 24H44C44.5523 24 45 24.4477 45 25V25.875M41.5 24V31.5M41.5 31.5H44C44.5523 31.5 45 31.0523 45 30.5V29.625M10.5 25.875V27.75V29.625M10.5 25.875H9M10.5 25.875V29.625M10.5 29.625H9M45 25.875V27.75V29.625M45 25.875H46.5M45 25.875V29.625M45 29.625H46.5"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {isOn && (
        <>
          <path
            d="M27.75 15.3335L27.75 10.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.75 15.3335L27.75 10.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.75 45.417L27.75 40.667"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.7065 36.9565L40.0653 40.3153"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4346 15.6846L18.7933 19.0433"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.7935 36.9565L15.4347 40.3153"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.0654 15.6846L36.7067 19.0433"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </svg>
  );
};
