import { useEffect, useState } from 'react';
import { IControllerChatMessage } from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/ControllerChat/IControllerChat';
import { ChatType } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusControllerChatMessages } from '../../../hooks/chat.hooks';
import { ChatMessage } from '../types';
import { useChat } from './use-chat';

export const useControllerChat = () => {
  const [controllerChat, setControllerChat] = useState<ChatMessage<IControllerChatMessage>[]>([]);
  const { data } = useExalusControllerChatMessages();
  const { chatApi } = useExalusServicesContext();
  const { mapMessages, messageHandler, sendMessage } = useChat<IControllerChatMessage>(controllerChat);

  useEffect(() => {
    const chat = chatApi.GetChatImplementation(ChatType.ControllerChat);

    chat.SubscribeToMessages<IControllerChatMessage>((message) =>
      messageHandler<IControllerChatMessage>(message, setControllerChat),
    );
    return () => {
      chat.UnsubscribeFromMessages<IControllerChatMessage>((message) =>
        messageHandler<IControllerChatMessage>(message, setControllerChat),
      );
    };
  }, []);

  useEffect(() => {
    if (data) setControllerChat(mapMessages<IControllerChatMessage>(data));
  }, [data]);

  return {
    controllerChat,
    sendControllerMessage: sendMessage,
  };
};
