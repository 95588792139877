import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceType, IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { uniq } from 'lodash';
import { useExalusDevices } from '../../../hooks';
import { GroupedDevice, HookDevices } from '../types';

export const useDevices = (hideAll?: boolean): HookDevices => {
  const { t: tc } = useTranslation('common');
  const { data: devices, isFetched } = useExalusDevices();

  const sortedDeviceTypes = [
    DeviceType[DeviceType.Driver],
    DeviceType[DeviceType.Receiver],
    DeviceType[DeviceType.Sensor],
    DeviceType[DeviceType.Controller],
    DeviceType[DeviceType.Remote],
    DeviceType[DeviceType.Scene],
    DeviceType[DeviceType.Multipurpose],
    DeviceType[DeviceType.Unknown],
  ];

  const typeList = useMemo(() => {
    return devices?.length ? uniq(devices?.map((device) => device && DeviceType[device.DeviceType])) : [];
  }, [devices]);

  const groupedDevices: GroupedDevice[] = useMemo(() => {
    const groups = typeList
      .sort(
        (a: string, b: string) =>
          sortedDeviceTypes.findIndex((f) => f === a) - sortedDeviceTypes.findIndex((f) => f === b),
      )
      .map((type: string) => ({
        label: tc(`typesPlural.exalus.${type}`),
        devices: devices?.filter((device: IDevice) => DeviceType[device.DeviceType] === type) || [],
      }));

    return [...(!hideAll ? [{ label: tc('all'), devices: [] }] : []), ...groups];
  }, [typeList, devices, hideAll]);

  return {
    groupedDevices: groupedDevices || [],
    isFetched: isFetched && !!devices,
  };
};
