import {
  DeviceResponseType as ChannelResponseType,
  DeviceTaskType as ChannelTaskType,
  IDeviceResponseTypeInfo as IChannelResponseTypeInfo,
  IDeviceTaskTypeInfo as IChannelTaskTypeInfo,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { isEmpty } from 'lodash';

export const canChannelDo = (channel: IDeviceChannel, taskTypes: ChannelTaskType[]) => {
  if (isEmpty(channel)) return false;

  if (channel.AvailableTaskTypes.length) {
    return taskTypes.every((taskType) => {
      return channel.AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === taskType);
    });
  }

  return taskTypes.every((taskType) => {
    return channel.GetDevice().AvailableTaskTypes?.any((a: IChannelTaskTypeInfo) => a.Type === taskType);
  });
};

export const canChannelReturnAny = (channel: IDeviceChannel, responseTypes: ChannelResponseType[]) => {
  if (isEmpty(channel)) return false;
  return responseTypes.any((responseType) => {
    return (
      channel.AvailableResponseTypes?.any((a: IChannelResponseTypeInfo) => a.Type === responseType) ||
      channel.GetDevice().AvailableResponseTypes?.any((a: IChannelResponseTypeInfo) => a.Type === responseType)
    );
  });
};

export const canOnlyChannelReturnAny = (channel: IDeviceChannel, responseTypes: ChannelResponseType[]) => {
  if (isEmpty(channel)) return false;
  return responseTypes.any((responseType) => {
    return channel.AvailableResponseTypes?.any((a: IChannelResponseTypeInfo) => a.Type === responseType);
  });
};
