import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconEco } from '../../../../../../components';
import { ecoLevels, ecoLevelsState } from '../../utils';
import './index.scss';

interface PropsInterface {
  value: number;
}

export const EcoState: React.FC<PropsInterface> = ({ value }) => {
  const { t } = useTranslation('channel-details');

  const matchedEcoState = useMemo(
    () => ecoLevelsState.find((level) => value <= level.maxValue) || ecoLevelsState[0],
    [ecoLevels, value],
  );

  const ecoBoxStyle = useMemo(() => {
    return {
      '--background': matchedEcoState.value,
    } as React.CSSProperties;
  }, [matchedEcoState]);

  const iconStyle = useMemo(
    () =>
      ({
        '--stroke': '#FFFFFF',
      } as React.CSSProperties),
    [matchedEcoState],
  );

  return (
    <div className="eco-state">
      <h2 className="eco-state__heading">{t(matchedEcoState.heading)}</h2>
      <div className="eco-state__eco" style={ecoBoxStyle}>
        {matchedEcoState.iconCount.map((value) => {
          return <IconEco key={value} style={iconStyle} />;
        })}
      </div>
    </div>
  );
};
