import React from 'react';
import '../index.scss';

export const IconPadlock: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--padlock"
  >
    <rect
      x="4.00024"
      y="8.09521"
      width="12.9524"
      height="9.90476"
      rx="1.92481"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.28564 5.84962C6.28564 3.72353 8.00918 2 10.1353 2H10.817C12.9431 2 14.6666 3.72354 14.6666 5.84962L14.6666 8.09524L6.28564 8.09524V5.84962Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.4762 11.1428V14.9523" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
