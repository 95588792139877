import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useConfiguration } from '../../hooks/use-configuration';
import { ChannelGeneralConfiguration, ConfigurationFormHookParams } from '../types';

export const useConfigurationForm = ({ channel }: ConfigurationFormHookParams) => {
  const { editChannel } = useConfiguration({
    channel,
  });

  const formMethods = useForm<ChannelGeneralConfiguration>({
    defaultValues: {
      name: '',
      icon_name: '',
      hidden: false,
    },
  });

  useEffect(() => {
    if (channel) {
      formMethods.setValue('name', channel.alias);
      formMethods.setValue('hidden', !channel.isVisible);
      if (channel.iconName) formMethods.setValue('icon_name', channel.iconName);
    }
  }, [channel]);

  const onSubmit = formMethods.handleSubmit((values) => {
    editChannel(values);
  });

  const { hidden, name, icon_name } = formMethods.watch();

  const handleChangeVisibility = () => formMethods.setValue('hidden', !hidden);

  const submitActive = useMemo(() => {
    if (channel) {
      return name !== channel.alias || icon_name !== channel.iconName || !hidden !== channel.isVisible;
    }

    return false;
  }, [channel?.alias, channel?.iconName, channel?.isVisible, name, icon_name, hidden]);

  return {
    formMethods,
    submitActive,
    onSubmit,
    hiddenValue: hidden,
    handleChangeVisibility,
  };
};
