import React, { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FacadeTypeDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { uniq } from 'lodash';
import { ControlWrapper } from '../../../../../../../components';
import { BlindDetailsSimpleControlButtons } from '../../../../control-buttons/blind-details/simple';
import { FacadeDetailsSpecificControlButtons } from '../../../../control-buttons/facade-details/specific';
import { useCentralActionFacadeControl } from '../../../hooks/use-central-action-facade-control';

interface ComponentProps {
  channels: IDeviceChannel[];
}

export const CentralActionFacadeDetailsControlBox: React.FC<ComponentProps> = ({ channels }) => {
  const { open, close, stop, handleAction, handleSliderEvent, handleTiltEvent } =
    useCentralActionFacadeControl(channels);

  const facadeTypes = useMemo(() => {
    return uniq(
      channels.map((channel) => {
        const facadeType = channel.States?.find(
          (state) => state.TypeAsEnum === ChannelResponseType.FacadeType,
        ) as FacadeTypeDeviceState;
        return facadeType?.Data.FType;
      }),
    );
  }, [channels]);

  return (
    <>
      {facadeTypes.length === 1 && facadeTypes[0] !== undefined ? (
        <FacadeDetailsSpecificControlButtons
          close={close}
          stop={stop}
          open={open}
          handleAction={handleAction}
          handleSliderEvent={handleSliderEvent}
          handleTiltEvent={handleTiltEvent}
          facadeType={facadeTypes[0]}
        />
      ) : (
        <ControlWrapper>
          <BlindDetailsSimpleControlButtons open={open} stop={stop} close={close} />
        </ControlWrapper>
      )}
    </>
  );
};
