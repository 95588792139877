export enum TariffOperator {
  Custom = 'custom',
  Tauron = 'tauron',
  Enea = 'enea',
  Pge = 'pge',
  Eon = 'eon',
  Energa = 'energa',
}

export enum TariffName {
  Custom = 'custom',
  G11 = 'g11',
  G12 = 'g12',
  G12W = 'g12w',
  G12AS = 'g12as',
  G12R = 'g12r',
  G13 = 'g13',
  Dynamic = 'dynamic',
}
