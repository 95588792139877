import React from 'react';
import { Page } from '../../../../components';
import { ExalusGroups } from '../dashboard/components/groups';
import { GroupSortHeader } from './components/header';

const GroupSort: React.FC = () => {
  return (
    <Page isStickyHeader header={<GroupSortHeader />} className="p-b-48">
      <ExalusGroups isDraggable />
    </Page>
  );
};

export default GroupSort;
