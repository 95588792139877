import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../../components';

interface ComponentProps {
  label: string;
  placeholder: string;
  disabled?: boolean;
}

export const Name: React.FC<ComponentProps> = ({ label, placeholder, disabled }) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();

  return (
    <Controller
      name="name"
      control={control}
      rules={{
        required: {
          value: true,
          message: t('isRequired'),
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <Input
          defaultValue={field.value}
          onChange={field.onChange}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          {...(error ? { errorMessage: error.message } : {})}
        />
      )}
    />
  );
};
