import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface IconMoreProps {
  onClick?: () => void;
  shape?: 'circle' | 'square';
}

export const IconMore: React.FC<IconMoreProps> = ({ onClick, shape = 'circle' }) => {
  return (
    <div className={classNames('icon-more', `icon-more--shape-${shape}`, { clickable: onClick })} onClick={onClick}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        {shape === 'circle' && (
          <path
            d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
            fill="white"
          />
        )}
        {shape === 'square' && (
          <path
            d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z"
            fill="white"
          />
        )}
        <circle cx="10" cy="16" r="1.5" />
        <circle cx="16" cy="16" r="1.5" />
        <circle cx="22" cy="16" r="1.5" />
      </svg>
    </div>
  );
};
