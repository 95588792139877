import React from 'react';

export const IconExalusDinTransceiver: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.158203" y="0.579102" width="56" height="56" rx="12" fill="white" />
    <path
      d="M16.5312 14.5078H39.7848"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.3691 17.0908V40.3444"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.7848 42.9297H16.5312"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9473 40.3444V17.0908"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1563 37.7599C33.1507 37.7599 37.1994 33.7112 37.1994 28.7169C37.1994 23.7225 33.1507 19.6738 28.1563 19.6738C23.162 19.6738 19.1133 23.7225 19.1133 28.7169C19.1133 33.7112 23.162 37.7599 28.1563 37.7599Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2812 28.7178H24.2821"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.8613 28.7178H31.8622"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9473 17.0915C13.9473 15.6634 15.1028 14.5078 16.531 14.5078"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.3689 17.0915C42.3689 15.6634 41.2133 14.5078 39.7852 14.5078"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9473 40.3457C13.9473 41.7739 15.1028 42.9294 16.531 42.9294"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.3689 40.3457C42.3689 41.7739 41.2133 42.9294 39.7852 42.9294"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="31.1582" y="29.7197" width="7.32088" height="10.3158" rx="1.26452" fill="white" />
    <path
      d="M32.4219 35.4618L35.2534 30.9844V34.0991H37.2137L34.3822 38.7711L34.6 35.4618H32.4219Z"
      stroke="#15141D"
      strokeWidth="0.948387"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
