import { sortBy } from 'lodash';
import { leadingZero } from '../../../../../utils/helpers';
import { TimeItem } from '../types';

export const getTimeParts = (time: string) => {
  const hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);
  const seconds = Number(time.split(':')[2]);

  return { hours, minutes, seconds };
};

export const sunTimeArray = () => {
  const list: TimeItem[] = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 100; j++) {
      const floatValue = parseFloat(`${i}.${leadingZero(j)}`);

      list.push({
        hour: floatValue,
        height: (-1 / 24) * Math.pow(floatValue, 2) + floatValue,
      });
    }
  }

  return sortBy(list, 'hour');
};
