import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, NavHeader, Page } from '../../../../../components';
import { ROUTES } from '../../../../../routes';
import { ActionChannelList } from '../../../components/action-channel-list';
import { useLavvaCreateActionContext } from '../../../context';
import { TaskVariant } from '../../../types';
import { BlindSettings } from './blind';
import { DimSettings } from './dim';
import { GateSettings } from './gate';
import { OnOffSettings } from './on-off';
import { RGBWSettings } from './rgbw';
import { VentilationSettings } from './ventilation';

const LavvaActionChannelSettings: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { name, channels, taskVariant } = useLavvaCreateActionContext();

  useEffect(() => {
    if (!name) history.replace(ROUTES.ActionList());
  }, []);

  const parametersView = useMemo(() => {
    switch (taskVariant) {
      case TaskVariant.OnOff:
        return <OnOffSettings />;
      case TaskVariant.SetRGBW:
        return <RGBWSettings />;
      case TaskVariant.SetBlind:
        return <BlindSettings />;
      case TaskVariant.SetGate:
        return <GateSettings />;
      case TaskVariant.Dim:
        return <DimSettings />;
      case TaskVariant.SetVentilation:
        return <VentilationSettings />;
      default:
        return null;
    }
  }, [taskVariant]);

  return (
    <Page
      isStickyHeader
      className="parameter-settings"
      header={
        <>
          <NavHeader />
          <Header title={t(`action.create.tasks.variants.${taskVariant}`)} />
        </>
      }
    >
      <div className="details-page">{parametersView}</div>
      <ActionChannelList channels={channels} />
    </Page>
  );
};

export default LavvaActionChannelSettings;
