import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { debounce, groupBy } from 'lodash';
import { ROUTES } from '../../../../../../../routes';
import DeviceVariants from '../../../../../../action-create/components/device-variants';
import { useChannels } from '../../../../channel-list/hooks/use-channels';
import { DeviceChannelsGrouped } from '../../../../group/components/grouped-channels/grouped-channel-box';
import { DeviceChannelsCheck } from '../../../../group/types';
import { useExalusCreateActionContext } from '../../../context';
import { useActionTaskVariants } from '../../../hooks/use-action-task-variants';

const ExalusDeviceVariants: React.FC = () => {
  const history = useHistory();
  const [search, setSearch] = useState<string>('');
  const [devicesChannelsGrouped, setDevicesChannelsGrouped] = useState<DeviceChannelsCheck[]>([]);
  const { taskVariant, setChannels } = useExalusCreateActionContext();
  const { getChannelsByVariant } = useActionTaskVariants();
  const { channels } = useChannels();
  const [selectedChannels, setSelectedChannels] = useState<IDeviceChannel[]>([]);

  const onChangeSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value);
  };

  const debouncedResults = useMemo(() => debounce(onChangeSearch, 300), []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    const channelList = getChannelsByVariant(channels || []).filter((x) =>
      x.Name.toLowerCase().includes(search.toLowerCase()),
    );
    const grouped: Record<string, IDeviceChannel[]> = groupBy(
      channelList.filter((x) => !x.GetDevice().ShouldChannelsBeGrouped),
      (x) => x.GetDevice().Guid,
    );

    setDevicesChannelsGrouped(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(grouped).map(([_, channels]) => ({
        device: channels[0].GetDevice(),
        channels: channels.map((ch) => ({
          id: ch.ChannelId,
          channel: ch,
          checked: selectedChannels.some((x) => x.ChannelId === ch.ChannelId),
        })),
      })),
    );
  }, [taskVariant, channels, search, selectedChannels]);

  const handleNext = () => {
    setChannels(selectedChannels);
    history.push(ROUTES.ActionChannelSettings());
  };

  const handleChannelOnChange = useCallback(
    (id: string) => {
      const channel = devicesChannelsGrouped.flatMap((x) => x.channels).find((x) => x.id === id);

      if (channel) {
        const foundIndex = selectedChannels.findIndex((x) => x.ChannelId === channel.channel.ChannelId);

        if (foundIndex === -1) {
          setSelectedChannels([...selectedChannels, channel.channel]);
        } else {
          setSelectedChannels(selectedChannels.filter((_, index) => index !== foundIndex));
        }
      }
    },
    [devicesChannelsGrouped, selectedChannels],
  );

  return (
    <DeviceVariants handleNext={handleNext} search={search} debouncedResults={debouncedResults}>
      <ul className="grid-list-24">
        {devicesChannelsGrouped.map((deviceChannels: DeviceChannelsCheck) => (
          <li key={deviceChannels.device.Guid} className="items-list__item">
            <DeviceChannelsGrouped
              deviceChannels={deviceChannels}
              groupLabel={deviceChannels.device.Name}
              handleChannelOnChange={handleChannelOnChange}
            />
          </li>
        ))}
      </ul>
    </DeviceVariants>
  );
};

export default ExalusDeviceVariants;
