import React from 'react';

export const IconCart: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="2.25" transform="matrix(-1 0 0 1 10.2979 28)" stroke="#15141D" strokeWidth="1.5" />
      <circle r="2.25" transform="matrix(-1 0 0 1 25.2979 28)" stroke="#15141D" strokeWidth="1.5" />
      <path
        d="M1.00025 1H2.87861C3.79953 1 4.60142 1.62886 4.82095 2.52324L6.23316 8.2766M6.23316 8.2766L9.24346 20.5406C9.463 21.435 10.2649 22.0638 11.1858 22.0638H23.4409C24.3277 22.0638 25.1086 21.4799 25.3593 20.6292L28.2439 10.842C28.6215 9.56081 27.6612 8.2766 26.3255 8.2766H6.23316Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
