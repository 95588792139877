import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Input, Header, NavHeader, Page, SubmitButton } from '../../components';
import {
  CreateBasicActionMutation,
  CreateBasicActionMutationVariables,
  ChannelTypeInternal,
  CreateBasicActionRequestInput, // ErrorCreateBasicActionResult,
} from '../../data-access/gql-types/graphql';
import { CREATE_BASIC_ACTION } from '../../data-access/mutations/action';
import { useInstallation, useBackdropContext } from '../../hooks';
import { useRefetchData } from '../../hooks/refresh-data';
import { ROUTES } from '../../routes';
import { toastSuccess } from '../../utils/toast';
import ActionChannels from './action-channels';
import ActionChannelsType from './action-channels-type';
import './index.scss';

const ActionCreateBasic: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('action');
  const history = useHistory();
  const { selectedInstallationId } = useInstallation();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchActions } = useRefetchData();
  const formMethods = useForm<CreateBasicActionRequestInput>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      installationId: selectedInstallationId,
    },
  });

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [channelsType, setChannelsType] = useState<ChannelTypeInternal>(ChannelTypeInternal.Blind);

  const [createActionBasic, { loading }] = useMutation<CreateBasicActionMutation, CreateBasicActionMutationVariables>(
    CREATE_BASIC_ACTION,
    {
      onCompleted: async (data) => {
        if (data?.createBasicAction?.id) {
          await refetchActions();
          turnOffBackdrop();
          toastSuccess({ content: t('basic.createSuccess') });
          history.push({
            pathname: ROUTES.ActionDetails(data?.createBasicAction?.id),
            state: { back: 2 },
          });
        } else turnOffBackdrop();
      },
      onError: () => turnOffBackdrop(),
    },
  );

  const onChooseType = useCallback(
    (type: ChannelTypeInternal) => {
      setChannelsType(type);
      setStepIndex((index) => index + 1);
    },
    [setChannelsType, setStepIndex],
  );

  useEffect(() => {
    formMethods.reset();
  }, [stepIndex]);

  const onSubmit = useCallback(
    ({ name, channels, installationId }) => {
      turnOnBackdrop();

      const parsedChannels = Object.keys(channels)
        .map((obj) => ({
          id: obj,
          value: channels[obj],
        }))
        .filter((el) => el.value)
        .map((element, index) => {
          let channelType: ChannelTypeInternal;
          switch (channelsType) {
            default:
              channelType = channelsType as unknown as ChannelTypeInternal;
          }
          return { channelId: element.id, channelType, order: index };
        });

      const input: CreateBasicActionRequestInput = {
        name,
        installationId,
        channels: parsedChannels,
        iconName: '',
      };

      createActionBasic({ variables: { input } });
    },
    [channelsType],
  );

  const goStepBack = useCallback(() => setStepIndex((value) => value - 1), [setStepIndex]);

  if (stepIndex === 0) return <ActionChannelsType onChooseType={onChooseType} />;

  return (
    <Page
      header={
        <>
          <NavHeader onClick={goStepBack} />
          <Header title={t('basic.creatorHeaderStep2')} />
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={formMethods.control}
            rules={{ required: tc('isRequired') as string }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label={t('basicActionForm.label')}
                placeholder={t('basicActionForm.label')}
                {...(error ? { errorMessage: error.message } : {})}
                name={field.name}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <hr className="m-t-40 m-b-24" />
          <ActionChannels channelsType={channelsType} />
          <SubmitButton isLoading={loading} />
        </form>
      </FormProvider>
    </Page>
  );
};

export default ActionCreateBasic;
