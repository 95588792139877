import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Tabs } from '../../../../../components';
import { useExalusContext } from '../../../context';
import { useExalusStorageContext } from '../../../context/storage';

export const GroupSortHeader: React.FC = () => {
  const { t } = useTranslation('groups');
  const { setActiveGroup, activeGroupIndex } = useExalusStorageContext();
  const { allGroups } = useExalusContext();

  return (
    <>
      <NavHeader isIconClose />
      <Header title={t('channelsOrder')} />
      {allGroups.length > 0 && (
        <Tabs
          tabList={allGroups.map((group) => ({ label: group.Name }))}
          activeTabIndex={activeGroupIndex}
          setActiveTabIndex={setActiveGroup}
          tabsType="pills"
          isDivider={false}
        />
      )}
    </>
  );
};
