import { IconOneArrowPressure, IconThreeArrowsPressure } from '../../../../../../../components/icons';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const pressureIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconThreeArrowsPressure isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_37',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_ONE_ARROW_PRESSURE,
    component: <IconOneArrowPressure isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_31',
  },
];
