import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toastInfo } from '../../utils/toast';

export const usePreventBack = (preventMessage?: string) => {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((_, action) => {
      if (action === 'POP' && preventMessage) {
        // Prevent going back by forcing navigation forward
        history.go(1);
        toastInfo({ content: preventMessage });
        return false; // Cancel the navigation
      }
      return true; // Allow other actions
    });

    return () => {
      unblock();
    };
  }, [preventMessage, history]);
};
