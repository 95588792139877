import {
  ChannelTypeInternal,
  OptimizerChannelStateResponse,
  UserChannel,
} from '../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum, ChannelInterface, ChannelStateType } from '../../../types';

export const parseOptimizerChannel = (userChannel: UserChannel): ChannelInterface => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.METER,
  deviceId: userChannel.deviceId,
  data: {
    type: ChannelTypeInternal.Optimizer,
    payload: userChannel.payload as OptimizerChannelStateResponse,
    supportedVectorParameters: (userChannel.payload as OptimizerChannelStateResponse)?.supportedVectorParameters || [],
    supportedScalarParameters: (userChannel.payload as OptimizerChannelStateResponse)?.supportedScalarParameters || [],
    supportedAggregateVectorParameters:
      (userChannel.payload as OptimizerChannelStateResponse)?.supportedAggregateVectorParameters || [],
  },
});

export const parseOptimizerStateChannel = (userChannel: UserChannel): ChannelStateType => ({
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
