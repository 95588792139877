import { IconPhotoCamera, IconSmallCamera } from '../../../../../../../components/icons';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const cameraIcons = (): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconSmallCamera isOn={false} />,
    oldIconName: 'DEVICE_ICON_38',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_PHOTO_CAMERA,
    component: <IconPhotoCamera />,
    oldIconName: 'DEVICE_ICON_42',
  },
];
