import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FloodSensorDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { FloodControlHook } from '../types';

export const useFloodControl = (channel?: IDeviceChannel, disabled?: boolean): FloodControlHook => {
  if (disabled) return { flood: null, time: '' };

  const floodData = useMemo(() => {
    const floodState = channel?.States?.find((state) => state.TypeAsEnum === ChannelResponseType.FloodSensorState) as
      | FloodSensorDeviceState
      | undefined;

    return floodState?.Data;
  }, [channel?.States]);

  const time = useMemo(() => (floodData?.Time ? format(Date.parse(floodData.Time), 'dd.MM, HH:mm') : ''), [floodData]);

  return {
    flood: floodData?.State ?? null,
    time,
  };
};
