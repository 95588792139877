import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../../../../components';
import { useExalusCreateActionContext } from '../../../context';
import { ConditionSourceType } from '../../../types';
import { AstronomicalSource } from './astronomical-source';
import { DaysOfWeekSource } from './days-of-week-source';
import './index.scss';
import { TimeSource } from './time-source';
import { TimerSource } from './timer-source';

const ExalusScheduleSource: React.FC = () => {
  const { t } = useTranslation('action');
  const { sourceType } = useExalusCreateActionContext();

  const renderCurrentView = () => {
    switch (sourceType) {
      case ConditionSourceType.Time:
        return <TimeSource />;
      case ConditionSourceType.DaysOfWeek:
        return <DaysOfWeekSource />;
      case ConditionSourceType.Timer:
        return <TimerSource />;
      case ConditionSourceType.Astronomical:
        return <AstronomicalSource />;
      default:
        return null;
    }
  };

  return (
    <Page
      isStickyHeader
      className="schedule-source"
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.conditions.sources.schedule.triggerParameters')} />
        </>
      }
    >
      {renderCurrentView()}
    </Page>
  );
};

export default ExalusScheduleSource;
