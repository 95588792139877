import React from 'react';
import '../index.scss';

export const IconExit: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--exit"
  >
    <path d="M3.00012 4.15344V14.8872" stroke="#15141D" strokeLinecap="round" />
    <path d="M10.5398 14.5994L5.74437 9.7229L10.5398 4.84644" stroke="#15141D" strokeLinecap="round" />
    <path d="M6 9.84644H18" stroke="#15141D" strokeLinecap="round" />
  </svg>
);
