import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  FieldChangeResult,
  FieldChangeResultType,
  ResponseResult,
} from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { useExalusDevice, useExalusDeviceChangeName } from '../../../hooks';

export const useDeviceConfiguration = (setDisabled?: Dispatch<SetStateAction<boolean>>) => {
  const { t } = useTranslation('device-info');
  const { deviceId } = useParams<{ deviceId?: string }>();
  const { data: device, refetch } = useExalusDevice(deviceId || '');
  const { mutate } = useExalusDeviceChangeName(device);

  const changeName = (name?: string) => {
    if (name) {
      mutate(name, {
        onSuccess: async (data) => {
          if (data)
            handleFieldChangeErrors(data, () => {
              if (setDisabled) setDisabled(true);
              refetch();
            });
        },
      });
    }
  };

  const handleFieldChangeErrors = (
    data: FieldChangeResult | ResponseResult<FieldChangeResultType>,
    onSuccess: () => void,
  ) => {
    switch (data?.Type) {
      case FieldChangeResultType.Changed: {
        toastSuccess({ content: t('exalus.configurationResult.deviceChangedSuccessfully') });
        onSuccess();
        break;
      }
      case FieldChangeResultType.ControllerResponseTimeout: {
        toastError({ content: t('exalus.configurationResult.controllerResponseTimeout') });
        break;
      }
      case FieldChangeResultType.Failed: {
        toastError({ content: t('exalus.configurationResult.failed') });
        break;
      }
      case FieldChangeResultType.NoPermissions: {
        toastError({ content: t('exalus.configurationResult.noPermissions') });
        break;
      }
      case FieldChangeResultType.Unknown: {
        toastError({ content: t('exalus.configurationResult.unknown') });
        break;
      }
      case FieldChangeResultType.UnknownError: {
        toastError({ content: t('exalus.configurationResult.unknownError') });
        break;
      }
      case FieldChangeResultType.WrongData: {
        toastError({ content: t('exalus.configurationResult.wrongData') });
        break;
      }
    }
  };

  return {
    changeName,
    handleFieldChangeErrors,
  };
};
