import React from 'react';

export const IconExalusImpulseCounter: React.FC = () => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.859375" width="56" height="56" rx="12" fill="white" />
    <rect x="10.8809" y="19.0303" width="33.5" height="19.5" rx="1.25" stroke="black" strokeWidth="1.5" />
    <path
      d="M15.1309 29.7803H22.6309L24.6309 22.2803L27.1309 34.7803L28.6309 29.7803H31.1309L32.1309 24.7803L33.6309 29.7803H38.1309H40.6309"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
