import React from 'react';
import { QrReader } from 'react-qr-reader';
import { ViewFinder } from './view-finder';

interface PropsInterface {
  onHandleScan: (data: string) => void;
}

export const QRCodeReader: React.FC<PropsInterface> = ({ onHandleScan }) => {
  const handleScan = (data: string | null) => {
    if (data) onHandleScan(data);
  };

  const handleError = (err: Error) => console.error(err);

  return (
    <QrReader
      constraints={{
        facingMode: 'environment',
      }}
      ViewFinder={ViewFinder}
      scanDelay={300}
      videoContainerStyle={{ width: '100%', height: 'calc(100vh - 160px)', paddingTop: '0' }}
      videoStyle={{ width: '100%', height: 'calc(100vh - 160px)' }}
      onResult={(result, error) => {
        if (result) {
          handleScan(result?.toString());
        }
        if (error) handleError(error);
      }}
    />
  );
};
