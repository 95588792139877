import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../../../routes';
import { ActionType } from '../../../../action-create/types';
import { useExalusScenarios } from '../../../hooks/scenarios.hooks';
import { useExalusCreateActionContext } from '../../action-create/context';

export const useActions = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation('action');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data: actions, isFetched, isLoading } = useExalusScenarios();
  const { setNewBuilder } = useExalusCreateActionContext();

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, [search]);

  const tabs = useMemo(() => [{ label: t('action.tabs.actions') }, { label: t('action.tabs.automations') }], [t]);

  const addNewAction = () => {
    setNewBuilder();
    if (activeTabIndex === 0) history.push(`${ROUTES.ActionCreate()}?type=${ActionType.Action}`);
    else history.push(`${ROUTES.ActionCreate()}?type=${ActionType.Automation}`);
  };

  const setTab = (index: number) => {
    setActiveTabIndex(index);
    history.replace(`${ROUTES.ActionList()}?tab=${index}`);
  };

  return {
    tabs,
    actions,
    isFetched: isFetched && !isLoading,
    activeTabIndex,
    setTab,
    addNewAction,
  };
};
