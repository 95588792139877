import { UseQueryResult, useQuery } from 'react-query';
import { ControllerChat } from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/ControllerChat/ControllerChat';
import { IControllerChatMessage } from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/ControllerChat/IControllerChat';
import { FamillyChat } from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/FamillyChat/FamillyChat';
import { GptChat } from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/GptChat/GptChat';
import { ChatServiceErrorCode } from 'lavva.exalushome/build/js/Services/Chat/ChatService';
import { IMessage } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { useBackdropContext } from '../../../hooks';
import { useExalusContext } from '../context';
import { ChatGPTHook } from '../pages/chat/types';

export const useExalusControllerChatMessages = (): UseQueryResult<IControllerChatMessage[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<IControllerChatMessage[]>(
    ['exalus-controller-chat', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const chat = new ControllerChat();
      const data: IControllerChatMessage[] | ResponseResult<ChatServiceErrorCode> = await chat.GetCachedMessagesAsync();
      turnOffBackdrop();

      if ((data as ResponseResult<ChatServiceErrorCode>).Type) {
        console.log(data);
      } else {
        return data as IControllerChatMessage[];
      }

      return [];
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => turnOffBackdrop(),
    },
  );
};

export const useExalusFamilyChatMessages = (): UseQueryResult<IMessage[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<IMessage[]>(
    ['exalus-family-chat', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const chat = new FamillyChat();
      const data: IMessage[] | ResponseResult<ChatServiceErrorCode> = await chat.GetCachedMessagesAsync();
      turnOffBackdrop();

      if ((data as ResponseResult<ChatServiceErrorCode>).Type) {
        console.log(data);
      } else {
        return data as IMessage[];
      }

      return [];
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => turnOffBackdrop(),
    },
  );
};

export const useExalusGPTChatMessages = (): UseQueryResult<ChatGPTHook> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<ChatGPTHook>(
    ['exalus-gpt-chat', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const chat = new GptChat();
      const gptAvailable: boolean = await chat.IsGptChatAvailableAsync();
      turnOffBackdrop();

      return { gptAvailable };
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => turnOffBackdrop(),
    },
  );
};
