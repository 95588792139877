import { AmpTransformersInternal, MeterPurposeInternal } from '../../../data-access/gql-types/graphql';

export enum MeasurementChannelKindNumber {
  UNKNOWN = 0,
  METER = 1,
  OPTIMIZER = 2,
}

export const AmpTransformValueRequest = {
  [AmpTransformersInternal.Unknown]: 0,
  [AmpTransformersInternal.OneHundredAmps]: 1,
  [AmpTransformersInternal.TwoHundredsAmps]: 2,
  [AmpTransformersInternal.FourHundredsAmps]: 3,
  [AmpTransformersInternal.OneThousandAmps]: 4,
};

export const PurposeValueRequest = {
  [MeterPurposeInternal.Unknown]: 0,
  [MeterPurposeInternal.Main]: 1,
  [MeterPurposeInternal.Pv]: 2,
  [MeterPurposeInternal.EnergyStorage]: 3,
  [MeterPurposeInternal.Consumption]: 4,
};

export interface MeasurementsResetBody {
  channelId: string;
  kind: MeasurementChannelKindNumber;
}

export interface MeterErrorGlobal {
  description: string;
  errorCode: string;
}

export interface MeterAmpTransformers {
  channelId: string;
  value: number;
}

export interface MeterPurpose {
  channelId: string;
  value: number;
}

export interface MeterHoursRangeConfig {
  from: number;
  to: number;
  timeZoneKind: number;
  pricingTier: number;
}

export interface MeterTariffBody {
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  hoursRanges: MeterHoursRangeConfig[];
  kind: number;
}

export interface MeterTariffConfigRequest {
  channelId: string;
  tariff: MeterTariffBody;
}
