import React from 'react';

export const IconSun: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.1651"
        cy="15.1651"
        r="6.31938"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.165 5.05469L15.165 1.26306"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.165 5.05469L15.165 1.26306"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.165 29.0684L15.165 25.2767"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2764 15.165L29.068 15.165"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.2627 15.165L5.05432 15.165"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3145 22.3145L24.9955 24.9955"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 5.33398L8.01507 8.01507"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.0166 22.3145L5.33552 24.9955"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9971 5.33398L22.316 8.01507"
        stroke="#15141D"
        strokeWidth="1.70623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
