import React from 'react';

export const IconInstallation8: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="5" y="7.9165" width="30.0833" height="25.3333" stroke="#15141D" />
    <rect x="11.333" y="15.8335" width="17.4167" height="17.4167" stroke="#15141D" />
  </svg>
);
