import {
  RequestAckPayload,
  DeviceConnectionStateChangePayload,
  DeviceFirmwareUpdatedPayload,
  RecoveryDeviceStatusRecievedPayload,
} from '../../../data-access/gql-types/graphql';
import { useChannelsState, useDevicesAndChannels } from '../../../hooks';
import { DeviceInterface } from '../../../types';

export const useUpdateCommonLavvaState = () => {
  const { channelList, deviceList, setDeviceList } = useDevicesAndChannels();
  const { setChannelState } = useChannelsState();

  const onRequestAckReceived = (receivedData: RequestAckPayload, callback?: () => void) => {
    if (receivedData.status === 'TIMEOUT') {
      if (callback) callback();
    }
  };

  const onDeviceFirmwareUpdated = (receivedData: DeviceFirmwareUpdatedPayload) => {
    const newDeviceList = Array.from(deviceList);
    const deviceIndex = newDeviceList.findIndex((device) => device?.id === receivedData.deviceId);

    if (deviceIndex !== -1) {
      newDeviceList[deviceIndex].isUpdating = false;
      (newDeviceList[deviceIndex] as DeviceInterface).payload.newestFirmwareVersion = '';
      (newDeviceList[deviceIndex] as DeviceInterface).payload.currentFirmwareVersion =
        receivedData.currentFirmwareVersion;
      (newDeviceList[deviceIndex] as DeviceInterface).payload.isUpdateAvailable = false;
      setDeviceList(newDeviceList);
    }
  };

  const setDeviceAsUpdating = (deviceId: string) => {
    setDeviceList((prev) => {
      const newDeviceList = Array.from(prev);
      const deviceIndex = newDeviceList.findIndex((device) => device?.id === deviceId);

      if (deviceIndex !== -1) {
        newDeviceList[deviceIndex].isUpdating = true;
      }

      return newDeviceList;
    });
  };

  const onRecoveryFirmwareUpdated = (receivedData: RecoveryDeviceStatusRecievedPayload) => {
    const newDeviceList = Array.from(deviceList);
    const deviceIndex = newDeviceList.findIndex((device) => device?.id === receivedData.deviceId);

    if (deviceIndex !== -1) {
      (newDeviceList[deviceIndex] as DeviceInterface).recoveryMode = {
        status: receivedData.recoveryDeviceStatus,
        progress: receivedData.progress || undefined,
      };

      setDeviceList(newDeviceList);
    }
  };

  const onDeviceConnectionStateChange = (deviceStateData: DeviceConnectionStateChangePayload) => {
    const newChannelList = Array.from(channelList);
    const channelsToUpdate = newChannelList.filter((channel) => channel?.deviceId === deviceStateData.deviceId);

    setChannelState((oldState) => {
      const newState = { ...oldState };

      for (let i = 0; i < channelsToUpdate.length; i++) {
        newState[channelsToUpdate[i].id] = {
          ...newState[channelsToUpdate[i].id],
          deviceConnectionState: deviceStateData.deviceConnectionState,
        };
      }

      return newState;
    });
  };

  const usesDepleted = (id: string) => {
    if (id) {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[id] = {
          ...newState[id],
          grantedUses: 0,
          usesCounter: 0,
        };

        return newState;
      });
    }
  };

  return {
    onRequestAckReceived,
    onDeviceConnectionStateChange,
    usesDepleted,
    onDeviceFirmwareUpdated,
    onRecoveryFirmwareUpdated,
    setDeviceAsUpdating,
  };
};
