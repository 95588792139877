import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  LamelaManualCalibration,
  LamelaTiltCalibration,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { getParameterByName } from '../../../../../../../utils/location';
import './index.scss';
import ZfBidiCalibrationStep0 from './step0';
import ZfBidiCalibrationStep1 from './step1';
import ZfBidiCalibrationStep2 from './step2';
import ZfBidiCalibrationStep3 from './step3';
import ZfBidiCalibrationStep4_5 from './step4_5';
import ZfBidiCalibrationSuccess from './success';

const ZfBidiCalibration: React.FC = () => {
  const location = useLocation();
  const [step, setStep] = useState<number>(0);
  const [movementService, setMovementService] = useState<LamelaManualCalibration | undefined>(undefined);
  const [tiltService, setTiltService] = useState<LamelaTiltCalibration | undefined>(undefined);

  useEffect(() => {
    const step = getParameterByName('step');
    if (step) setStep(parseInt(step));
    else setStep(0);
  }, [location.search]);

  const renderCurrentView = () => {
    switch (step) {
      case 0:
        return <ZfBidiCalibrationStep0 setStep={setStep} />;
      case 1:
        return <ZfBidiCalibrationStep1 setStep={setStep} />;
      case 2:
        return <ZfBidiCalibrationStep2 setStep={setStep} />;
      case 3:
        return (
          <ZfBidiCalibrationStep3
            setStep={setStep}
            setTiltService={setTiltService}
            setMovementService={setMovementService}
          />
        );
      case 4:
        return (
          <ZfBidiCalibrationStep4_5
            step={step}
            setStep={setStep}
            movementService={movementService}
            tiltService={tiltService}
          />
        );
      case 5:
        return (
          <ZfBidiCalibrationStep4_5
            step={step}
            setStep={setStep}
            movementService={movementService}
            tiltService={tiltService}
          />
        );
      case 6:
        return <ZfBidiCalibrationSuccess />;
      default:
        return <ZfBidiCalibrationStep0 setStep={setStep} />;
    }
  };

  return <>{renderCurrentView()}</>;
};

export default ZfBidiCalibration;
