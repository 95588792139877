import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconEco } from '../../../../../../../components';
import { ecoLevels } from '../../../utils';
import './index.scss';

interface PropsInterface {
  totalValue: number | undefined;
  percentageRatioActualToMaximum: number;
  unit?: string;
}

export const EnergyConsumptionTotalValue: React.FC<PropsInterface> = ({
  totalValue,
  percentageRatioActualToMaximum,
  unit,
}) => {
  const { t } = useTranslation('channel-details');

  const style = useMemo(() => {
    return {
      '--stroke': ecoLevels.find((level) => percentageRatioActualToMaximum <= level.maxValue)?.value,
    } as React.CSSProperties;
  }, [percentageRatioActualToMaximum]);

  return (
    <div className="energy-consumption-total-value">
      <div className="energy-consumption-total-value__text">{t('total')}</div>
      <div className="energy-consumption-total-value__value m-r-4">
        {totalValue !== undefined ? totalValue.toFixed(2) : '-'} {unit || t('kwh')}
      </div>
      <IconEco size={20} style={style} />
    </div>
  );
};
