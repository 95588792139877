import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { AllActionsQueryVariables, Query, UserAction } from '../../data-access/gql-types/graphql';
import { ALL_ACTIONS } from '../../data-access/queries/actions';
import { toastError } from '../../utils/toast';
import { useInstallation } from '../installation';

interface HookInterface {
  userActions: UserAction[];
}

export default function (): HookInterface {
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { data } = useQuery<Query, AllActionsQueryVariables>(ALL_ACTIONS, {
    variables: { installationId: selectedInstallationId },
    fetchPolicy: 'network-only',
    onError: () => toastError({ content: tc('errors.downloadData') }),
    skip: skipLavvaFetch,
  });

  const userActions = useMemo(() => data?.allUserActions, [data?.allUserActions]);

  return {
    userActions: userActions || [],
  };
}
