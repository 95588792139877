import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StatusScreen } from '../../components';
import { ROUTES } from '../../routes';
import { StatusTypeEnum } from '../../types';

interface Props {
  creatorName: string;
}
const InvitationReceivedConfirmation: React.FC<Props> = ({ creatorName }) => {
  const { t } = useTranslation('invitation');
  const history = useHistory();

  return (
    <StatusScreen
      header={t('confirmation.title')}
      subTitle={t('confirmation.message', { creatorName })}
      secondaryBackground
      type={StatusTypeEnum.WAIT}
      primaryBtnText={t('confirmation.action')}
      onPrimaryBtnClick={() => history.replace(ROUTES.Root())}
    />
  );
};

export default InvitationReceivedConfirmation;
