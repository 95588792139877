import React from 'react';

export const IconBed: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-bed"
  >
    <path
      d="M29.7523 3H21.5027C20.6741 3 20.0024 3.67167 20.0024 4.50021V8.2492C20.0024 9.07774 20.6741 9.74941 21.5027 9.74941H29.7523C30.5808 9.74941 31.2525 9.07774 31.2525 8.2492V4.50021C31.2525 3.67167 30.5808 3 29.7523 3Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5023 3H7.25265C6.42411 3 5.75244 3.67167 5.75244 4.50021V8.2492C5.75244 9.07774 6.42411 9.74941 7.25265 9.74941H15.5023C16.3308 9.74941 17.0025 9.07774 17.0025 8.2492V4.50021C17.0025 3.67167 16.3308 3 15.5023 3Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M17.002 5.25H20.0024" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M31.2495 5.25H33.4983C34.3263 5.25 34.9985 5.92219 34.9985 6.75021V34.4995C34.9985 35.3275 34.3263 35.9997 33.4983 35.9997H3.50021C2.67219 35.9997 2 35.3275 2 34.4995V6.75021C2 5.92219 2.67219 5.25 3.50021 5.25H5.74899"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M35.0024 11.999L2.00391 17.236L6.52286 22.8732C7.3906 23.9549 8.89081 24.3001 10.1466 23.7104L35.0024 11.999Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinejoin="bevel"
    />
  </svg>
);
