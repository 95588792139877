import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { GateActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { FieldChangeResult, FieldChangeResultType } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { CoverButton, IconFavorite } from '../../../../../../../../../components';
import { defaultFavouritePositionList } from '../../../../../../../../../const';
import { CustomChannelDataEnum } from '../../../../../../../enums/custom-channel-data';
import { getValueFromChannelCustomData } from '../../../../../../../utils';
import { useGatePreciseControl } from '../../../../../hooks/use-gate-precise-control';

interface ComponentProps {
  channel: IDeviceChannel;
  position: number;
  setTargetValue: Dispatch<SetStateAction<number>>;
}

const Favourites: React.FC<ComponentProps> = ({ channel, position, setTargetValue }) => {
  const [favouritePositionList, setFavouritePositionList] = useState<number[]>(defaultFavouritePositionList);
  const { handleAction } = useGatePreciseControl(channel);

  const isFovouritesMemoryAvailable = useMemo(() => channel.IsCustomDataAndRolesSupported(), [channel]);

  useEffect(() => {
    const positionList = getValueFromChannelCustomData(CustomChannelDataEnum.favouritePositions, channel);
    if (positionList) setFavouritePositionList(JSON.parse(positionList));
  }, []);

  const onFavouriteClick = useCallback(
    (favoritePositionIndex) => {
      setTargetValue(favouritePositionList[favoritePositionIndex]);
      handleAction(GateActionEnum.Percentage, favouritePositionList[favoritePositionIndex]);
    },
    [favouritePositionList],
  );

  const onFavouritePositionHold = useCallback(
    async (fieldIndex: number) => {
      const newFavouritePositionList = favouritePositionList.map((el, index) =>
        index !== fieldIndex ? el : position !== null ? position : defaultFavouritePositionList[index],
      );

      const result: FieldChangeResult = await channel.SetCustomDataAsync(
        CustomChannelDataEnum.favouritePositions,
        JSON.stringify(newFavouritePositionList),
      );

      if (result.Type === FieldChangeResultType.Changed) setFavouritePositionList(newFavouritePositionList);
    },
    [channel, position, favouritePositionList],
  );

  return (
    <div className="favourite p-l-24 p-r-24">
      <div className="favourite__buttons favourite__buttons--cover flex">
        {[0, 1, 2].map((index) => (
          <CoverButton
            key={index}
            lastPosition={position}
            position={favouritePositionList[index]}
            onClick={() => onFavouriteClick(index)}
            {...(isFovouritesMemoryAvailable ? { onHold: () => onFavouritePositionHold(index) } : {})}
          >
            <IconFavorite />
            <span>{favouritePositionList[index]}%</span>
          </CoverButton>
        ))}
      </div>
    </div>
  );
};

export default Favourites;
