import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { Checkbox, Header, IconEdit, NavHeader, Page, SubmitButton } from '../../../../../components';
import { useBackdropContext } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import Info from '../../../../configuration/configuration-form/components/info';
import { useExalusContext } from '../../../context';
import { useExalusServicesContext } from '../../../context/services';
import { useHandleDataFrameErrors } from '../../../hooks';
import { GroupChecked } from '../types';
import './index.scss';

interface ComponentProps {
  channel: IDeviceChannel;
  editGroups: boolean;
  setEditGroups: Dispatch<SetStateAction<boolean>>;
  refetchChannel: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<IDeviceChannel | undefined, unknown>>;
}

const ChannelGroups: React.FC<ComponentProps> = ({ channel, editGroups, setEditGroups, refetchChannel }) => {
  const { t } = useTranslation('configuration');
  const { allGroups, refetchAllGroups } = useExalusContext();
  const { groupsApi, configurationApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleError } = useHandleDataFrameErrors();
  const [groups, updateGroups] = useState<GroupChecked[]>([]);

  useEffect(() => {
    if (allGroups) {
      const mappedGroups = allGroups.map((group: IChannelsGroup) => ({
        group,
        checked: !!channel.ChannelGroups.find((channelGroupId) => channelGroupId === group.Guid),
      }));

      updateGroups(mappedGroups);
    }
  }, [allGroups, channel.ChannelGroups]);

  const channelGroups = useMemo(
    () =>
      allGroups
        .filter((group: IChannelsGroup) =>
          channel.ChannelGroups.find((channelGroupId) => channelGroupId === group.Guid),
        )
        .map((group: IChannelsGroup) => (
          <div className="group-item m-b-24" key={group.Guid}>
            {group.Name}
          </div>
        )),
    [allGroups, channel.ChannelGroups],
  );

  const toggleChecked = (id: string) => {
    const updatedGroups = Array.from(groups);

    const foundIndex = updatedGroups.findIndex((checkedGroup: GroupChecked) => checkedGroup.group.Guid === id);

    if (foundIndex !== -1) {
      updatedGroups[foundIndex].checked = !updatedGroups[foundIndex].checked;
    }

    updateGroups(updatedGroups);
  };

  const groupList = useMemo(
    () =>
      groups.map((checkedGroup: GroupChecked) => (
        <div className="group-item m-b-24" key={checkedGroup.group.Guid}>
          <Checkbox
            key={checkedGroup.group.Guid}
            id={checkedGroup.group.Guid}
            checked={checkedGroup.checked}
            onChange={() => toggleChecked(checkedGroup.group.Guid)}
          >
            {checkedGroup.group.Name}
          </Checkbox>
        </div>
      )),
    [groups],
  );

  const handleBack = () => {
    refetchAllGroups();
    refetchChannel();
    setEditGroups(false);
  };

  const handleSave = async () => {
    turnOnBackdrop();
    await configurationApi.EnterConfigurationModeAsync();

    for (let i = 0; i < groups.length + 1; i++) {
      if (i === groups.length) {
        turnOffBackdrop();
        toastSuccess({ content: t('addToGroupsSuccess') });
        handleBack();
      } else {
        const groupChannels = await groups[i].group.GetDevicesChannelsInGroupAsync(false);
        const updatedChannels = Array.from(groupChannels);

        updatedChannels.filter((groupChannel: IDeviceChannel) => groupChannel.ChannelId === channel.ChannelId);

        const foundIndex = updatedChannels.findIndex(
          (groupChannel: IDeviceChannel) => groupChannel.ChannelId === channel.ChannelId,
        );

        if (groups[i].checked) {
          if (foundIndex === -1) {
            updatedChannels.push(channel);
            const result = await groupsApi.UpdateGroupWithDevicesChannelsAsync(groups[i].group.Guid, updatedChannels);

            if (result !== Status.OK) {
              handleError(result);
              handleBack();
            }
          }
        } else {
          if (foundIndex !== -1) {
            updatedChannels.splice(foundIndex, 1);
            const result = await groupsApi.UpdateGroupWithDevicesChannelsAsync(groups[i].group.Guid, updatedChannels);

            if (result !== Status.OK) {
              handleError(result);
              handleBack();
            }
          }
        }
      }
    }
  };

  const openEditPage = () => setEditGroups(true);

  return (
    <>
      {!editGroups ? (
        <div className="channel-groups">
          <Info title={t('groups')} onClick={openEditPage} icon={<IconEdit />} />
          <div className="grid-list-24">{channelGroups}</div>
        </div>
      ) : (
        <Page
          kind="above"
          header={
            <>
              <NavHeader onClick={handleBack} />
              <Header title={t('addToGroups')} />
            </>
          }
        >
          <div className="channel-groups grid-list-24">{groupList}</div>
          <SubmitButton type="button" onClick={handleSave} />
        </Page>
      )}
    </>
  );
};

export default ChannelGroups;
