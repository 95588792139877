export const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

export const getBrowserName = () => {
  const agent = window.navigator.userAgent.toLowerCase();

  switch (true) {
    case /iphone/.test(agent):
      return 'iPhone';
    case /ipad/.test(agent):
      return 'iPad';
    case /ipod/.test(agent):
      return 'iPod';
    case agent.indexOf('android') > -1:
      return 'Android';
    case agent.indexOf('edge') > -1:
      return 'MS Edge';
    case agent.indexOf('edg/') > -1:
      return 'Edge (chromium based)';
    case agent.indexOf('opr') > -1 && !!window.opr:
      return 'Opera';
    case agent.indexOf('chrome') > -1 && !!window.chrome:
      return 'Chrome';
    case agent.indexOf('trident') > -1:
      return 'MS IE';
    case agent.indexOf('firefox') > -1:
      return 'Mozilla Firefox';
    case agent.indexOf('safari') > -1:
      return 'Safari';
    default:
      return 'other';
  }
};

export const isWebView = () => {
  const navigator = window.navigator;
  const userAgent = navigator.userAgent;
  const normalizedUserAgent = userAgent.toLowerCase();

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const isAndroid = /android/.test(normalizedUserAgent);
  const isSafari = /safari/.test(normalizedUserAgent);
  const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !isSafari);

  return isWebview;
};

export const isMac = /(Mac)/i.test(navigator.platform);

export const isIosWebView = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad/.test(agent);
  // TODO AFTER lavva.webview UPDATE
  const webView = window.webkit?.messageHandlers?.webViewLoaded !== undefined;

  return ios && webView;
};
