import React from 'react';

export const IconGrill: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-grill"
  >
    <path
      d="M6.38281 4.64075C6.38281 12.2864 12.5721 18.4757 20.2178 18.4757C27.8634 18.4757 34.0528 12.2864 34.0528 4.64075V3.9126H6.38281V4.64075Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4805 11.1942C13.4756 13.8447 16.6504 15.5632 20.2183 15.5632"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.2282 36C29.4481 36 30.437 35.0111 30.437 33.7913C30.437 32.5715 29.4481 31.5826 28.2282 31.5826C27.0084 31.5826 26.0195 32.5715 26.0195 33.7913C26.0195 35.0111 27.0084 36 28.2282 36Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.525 18.2136L10 35.5" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M27.3106 31.7282L22.8105 18.2282"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.666 27.2136H25.7971" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.38281 5.36896H1.99931V6.82527"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
