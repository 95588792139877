import React from 'react';

export const IconOven: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-oven"
  >
    <path
      d="M35 3H4V34H35V3Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.9499 17.0869H11.0435V28.3617H27.9499V17.0869Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 11.4551H35"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1567 7.22684C20.1567 7.58938 19.8628 7.88329 19.5002 7.88329C19.1376 7.88329 18.8438 7.58939 18.8438 7.22684C18.8438 6.86423 19.1377 6.57031 19.5002 6.57031C19.8628 6.57031 20.1567 6.86425 20.1567 7.22684Z"
      fill="#15141D"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.206 7.22684C27.206 7.59135 26.9123 7.88329 26.5495 7.88329C26.1887 7.88329 25.8931 7.58761 25.8931 7.22684C25.8931 6.86601 26.1888 6.57031 26.5495 6.57031C26.9103 6.57031 27.206 6.86603 27.206 7.22684Z"
      fill="#15141D"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1128 7.22684C13.1128 7.59136 12.8191 7.88329 12.4563 7.88329C12.0955 7.88329 11.7998 7.5876 11.7998 7.22684C11.7998 6.86603 12.0955 6.57031 12.4563 6.57031C12.817 6.57031 13.1128 6.86601 13.1128 7.22684Z"
      fill="#15141D"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
