import React from 'react';

export const IconCinema: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="28"
      height="38"
      viewBox="0 0 28 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 34H4C2.34315 34 1 32.6569 1 31V4C1 2.34315 2.34315 1 4 1H24C25.6569 1 27 2.34314 27 4V31C27 32.6569 25.6569 34 24 34H21M7 34V37M7 34H21M21 34V37"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="14" cy="22.25" r="6.72" stroke="#15141D" strokeWidth="1.44" />
      <circle cx="14" cy="22.25" r="3.75" stroke="#15141D" strokeWidth="1.5" />
      <circle cx="14" cy="8" r="3.75" stroke="#15141D" strokeWidth="1.5" />
    </svg>
  );
};
