import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dialog } from '../dialog/base';

interface PropsInterface {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  onSave: (data: number) => void;
  value: number;
  unit: string;
  min?: number;
  max?: number;
  step?: number;
}

export const DialogValuePicker: React.FC<PropsInterface> = ({
  open,
  setOpen,
  title,
  onSave,
  value,
  unit,
  min = 0,
  max = 100,
  step = 1,
}) => {
  const { t: tc } = useTranslation('common');
  const [selectedValue, setSelectedValue] = useState<number>(value);
  const values = useMemo(
    () => Array.from({ length: (max - min) / step + 1 }, (_, index) => min + index * step),
    [min, max, step],
  );

  useEffect(() => {
    if (open) setSelectedValue(value);
  }, [open]);

  const onClose = () => setOpen(false);

  const onSubmit = () => {
    onSave(selectedValue);
    onClose();
  };

  const initialSlide = useMemo(() => {
    return values.findIndex((value) => value === selectedValue);
  }, [values, selectedValue]);

  return (
    <Dialog setShow={setOpen} show={open} className="dialog--full-width">
      <div className="dialog-time-picker">
        <div className="dialog-time-picker__header">
          <h3 className="dialog-time-picker__heading">{title}</h3>
        </div>
        <div className="dialog-time-picker__time-wrapper">
          <div className="time-picker-wrapper">
            <div className="picker">
              <Swiper
                initialSlide={initialSlide}
                slidesPerView={3}
                direction="vertical"
                grabCursor
                centeredSlides
                loop
                slideToClickedSlide
                onSlideChange={(e) => {
                  setSelectedValue(values[e.realIndex]);
                }}
              >
                {values.map((value) => {
                  return (
                    <SwiperSlide key={value}>
                      <div className="time">{value}</div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="time-picker-unit">{unit}</div>
          </div>
        </div>
        <div className="dialog-time-picker__buttons">
          <button className="dialog-time-picker__button" onClick={onClose}>
            {tc('buttons.cancel')}
          </button>
          <button onClick={onSubmit} className="dialog-time-picker__button dialog-time-picker__button--active">
            {tc('buttons.save')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
