import React from 'react';

export const IconPhoto: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="32"
      height="23"
      viewBox="0 0 32 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" y="0.75" width="30.5" height="21.5" rx="3.25" stroke="#15141D" strokeWidth="1.5" />
      <circle cx="20.5" cy="11.5" r="6.75" stroke="#15141D" strokeWidth="1.5" />
      <rect x="4.75" y="4.75" width="4.5" height="2.5" rx="1.25" stroke="#15141D" strokeWidth="1.5" />
    </svg>
  );
};
