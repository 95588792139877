import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import {
  CustomBackdrop,
  EmptyStateBox,
  Header,
  IconAdd,
  IconSortHandle,
  NavHeader,
  Page,
} from '../../../../../components';
import { ROUTES } from '../../../../../routes';
import { useExalusContext } from '../../../context';
import { GroupItem } from '../components/group-item';

const GroupList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('groups');
  const { allGroups, groupsFetched } = useExalusContext();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader>
            <button onClick={() => history.push(ROUTES.GroupCreate())}>
              <IconAdd />
            </button>
            <button className="p-l-0 p-r-0" onClick={() => history.push(ROUTES.GroupSort())}>
              <IconSortHandle />
            </button>
          </NavHeader>
          <Header title={t('allGroups')} />
        </>
      }
    >
      <>
        {groupsFetched ? (
          <>
            {allGroups.length > 0 ? (
              <div className="group-list grid-list-16">
                {allGroups.map((group: IChannelsGroup) => (
                  <GroupItem key={group.Guid} group={group} />
                ))}
              </div>
            ) : (
              <EmptyStateBox
                header={t('groups')}
                content={t('descriptionEmptyList')}
                btnText={t('create')}
                linkTo={ROUTES.GroupCreate()}
              />
            )}
          </>
        ) : (
          <CustomBackdrop loading />
        )}
      </>
    </Page>
  );
};

export default GroupList;
