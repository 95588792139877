import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ChannelBox } from '../../../box';
import { ChannelBoxIcon } from '../../../box-icon';
import { useReedControl } from '../../../hooks/use-reed-control';
import { PowerState } from '../../../power';
import SensorDetailsWrapper from '../../../sensor-details-wrapper';
import ReedControl from '../controls';

interface ComponentProps {
  channel: IDeviceChannel;
}

export const ReedDetailsControls: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { reed, time } = useReedControl(channel);

  return (
    <SensorDetailsWrapper channel={channel}>
      <div className="details-controls grid-list-16">
        <div>
          <ChannelBox channel={channel} isListItem className="device-box--details">
            <div className="device-box__head">
              {reed === null ? (
                <>
                  <ChannelBoxIcon channel={channel} />
                  <div className="sensor-control-box">
                    <span className="sensor-control-box__status">{t('exalus.sensor.noData')}</span>
                  </div>
                </>
              ) : (
                <ReedControl channel={channel} details />
              )}
            </div>
          </ChannelBox>
          {time && <div className="details-controls__detection-time">{`${t('exalus.checked')} ${time}`}</div>}
        </div>
        <PowerState channel={channel} />
      </div>
    </SensorDetailsWrapper>
  );
};
