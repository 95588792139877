import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import classNames from 'classnames';
import { ChatType } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { Header, IconMicrophone, IconSendMessage, Page, Tabs, TextArea } from '../../../../components';
import { scrollBottom } from '../../../../utils/helpers/scroll';
import { ChatList } from './components/chat-list';
import { useControllerChat } from './hooks/use-controller-chat';
import { useFamilyChat } from './hooks/use-family-chat';
import { useGPTChat } from './hooks/use-gpt-chat';
import './index.scss';

const Chat: React.FC = () => {
  const { i18n, t } = useTranslation('chat');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const { controllerChat, sendControllerMessage } = useControllerChat();
  const { familyChat, sendFamilyMessage } = useFamilyChat();
  const { gptChat, sendGPTMessage, gptAvailable } = useGPTChat();
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

  useEffect(() => {
    return () => SpeechRecognition.stopListening();
  }, []);

  useEffect(() => scrollBottom(), [activeTabIndex]);

  useEffect(() => {
    setMessage(transcript);
  }, [transcript]);

  const handleTabChange = (tabIndex: number) => setActiveTabIndex(tabIndex);

  const tabs = useMemo(
    () => [{ label: t('home') }, { label: t('family') }, ...(gptAvailable ? [{ label: 'GPT' }] : [])],
    [t, gptAvailable],
  );

  const handleSetValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value);

  const handleKeyboardEvent = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') handleSendMessage();
  };

  const handleMicrophoneClick = () => {
    if (!listening) SpeechRecognition.startListening({ language: i18n.language });
    else SpeechRecognition.stopListening();
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      switch (activeTabIndex) {
        case 0:
          sendControllerMessage(message, setMessage, ChatType.ControllerChat);
          break;
        case 1:
          sendFamilyMessage(message, setMessage, ChatType.FamilyChat);
          break;
        case 2:
          sendGPTMessage(message, setMessage, ChatType.GptChat);
          break;
        default:
          break;
      }
    }
  };

  const displayingChat = useMemo(() => {
    switch (activeTabIndex) {
      case 0:
        return controllerChat;
      case 1:
        return familyChat;
      case 2:
        return gptChat;
      default:
        return [];
    }
  }, [activeTabIndex, controllerChat, familyChat, gptChat]);

  return (
    <Page
      className="chat-page"
      isStickyHeader
      containerClassName="page__container--flex-height page__container--no-padding"
      header={
        <>
          <Header title={t('chat')}>
            <Tabs
              tabList={tabs}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={handleTabChange}
              tabsType="pills"
              isDivider={false}
            />
          </Header>
        </>
      }
    >
      <>
        <div className={classNames('chat-view max-width-desktop', { empty: !displayingChat.length })}>
          <ChatList chat={displayingChat} activeTabIndex={activeTabIndex} />
        </div>
        <div className="row-container max-width-desktop">
          <TextArea
            defaultValue={message}
            value={message}
            label={''}
            placeholder={t('writeMessage')}
            onChange={handleSetValue}
            onKeyDown={handleKeyboardEvent}
          />
          {listening ? (
            <button onClick={handleSendMessage} className={classNames('action-btn', { active: true })}>
              <IconMicrophone active />
            </button>
          ) : message || !browserSupportsSpeechRecognition ? (
            <button onClick={handleSendMessage} className="action-btn">
              <IconSendMessage />
            </button>
          ) : (
            <button onClick={handleMicrophoneClick} className="action-btn">
              <IconMicrophone />
            </button>
          )}
        </div>
      </>
    </Page>
  );
};

export default Chat;
