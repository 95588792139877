import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toastSuccess } from '../../../utils/toast';
import { useExalusStorageContext } from '../../exalus/context/storage';

export const useChatSettings = () => {
  const { t } = useTranslation('profile');
  const { readingChat, toggleReadingChat } = useExalusStorageContext();

  const options = [
    { value: 'true', label: t('reading-chat.on') },
    { value: 'false', label: t('reading-chat.off') },
  ];

  const defaultOptionIndex = useMemo(() => {
    const optionIndex = options.findIndex((option) => (option.value === 'true') === readingChat);
    return optionIndex < 0 ? 0 : optionIndex;
  }, [options, readingChat]);

  const handleChange = (vibrationValue: string) => {
    toggleReadingChat();

    if (vibrationValue === 'true') {
      toastSuccess({ content: t('reading-chat.reading-on') });
    } else {
      toastSuccess({ content: t('reading-chat.reading-off') });
    }
  };

  return {
    options,
    defaultOptionIndex,
    handleChange,
  };
};
