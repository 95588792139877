import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Input } from '../../../../components';
import { ROUTES } from '../../../../routes';

const FormName: React.FC = () => {
  const { control } = useFormContext();
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { pathname } = useLocation();

  return (
    <Controller
      name="name"
      control={control}
      rules={{
        required: tc('isRequired') as string,
      }}
      render={({ field, fieldState: { error } }) => (
        <Input
          label={t('name')}
          name={field.name}
          defaultValue={field.value}
          autoFocus={pathname === ROUTES.GroupCreate()}
          onChange={field.onChange}
          {...(error ? { errorMessage: error.message } : {})}
        />
      )}
    />
  );
};

export default FormName;
