import React from 'react';

export const IconBath: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-bath"
  >
    <path
      d="M35.9744 19.5C35.9744 20.6327 35.0561 21.551 33.9234 21.551H5.07639C3.94365 21.551 3.02539 20.6327 3.02539 19.5V19.5C3.02539 18.3673 3.9437 17.449 5.07595 17.449H33.9238C35.0561 17.449 35.9744 18.3673 35.9744 19.5V19.5Z"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4 22L5.02889 26.7999C6.28997 28.7613 8.93637 30 11.7557 30H27.3132C30.1325 30 32.7105 28.7613 33.9716 26.7999L35 22"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M5.07606 27.1406V30.2166C5.07606 31.9159 3.69881 33.2927 2 33.2927"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.9238 27.1406V30.2166C33.9238 31.9159 35.3011 33.2927 36.9999 33.2927"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.7715 10.2714C27.7715 8.57256 29.1487 7.19531 30.848 7.19531C32.5472 7.19531 33.924 8.57256 33.924 10.2714V17.449"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31 17C31 15.5274 28.8578 14.3335 26.216 14.3335C25.5353 14.3335 24.8508 14.4127 24.2413 14.5555C23.778 14.6641 23.2807 14.4884 22.9153 14.1836C22.061 13.4709 20.6143 13 18.9602 13C17.3058 13 15.8594 13.471 15.005 14.1839C14.6397 14.4886 14.1431 14.6629 13.6806 14.5514C13.0992 14.4112 12.4581 14.3335 11.784 14.3335C9.14169 14.3335 7 15.5274 7 17"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.102 10.0506C10.102 11.1833 9.18369 12.1011 8.051 12.1011C6.91831 12.1011 6 11.1833 6 10.0506C6 8.91788 6.91831 8 8.051 8C9.18369 8 10.102 8.91788 10.102 10.0506Z"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M15.102 5.05057C15.102 6.18326 14.1837 7.10113 13.051 7.10113C11.9183 7.10113 11 6.18326 11 5.05057C11 3.91788 11.9183 3 13.051 3C14.1837 3 15.102 3.91788 15.102 5.05057Z"
      stroke="#15141D"
      strokeWidth="1.41892"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
