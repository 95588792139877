import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  onClick?: () => void;
  className?: string;
}

export const IconFavorite: React.FC<PropsInterface> = ({ onClick, className }) => {
  return (
    <div
      className={classNames('icon-favorite', {
        clickable: onClick,
        [className as string]: className,
      })}
      onClick={onClick}
    >
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M11 1.49017L13.908 6.48292L14.0744 6.76851L14.3974 6.83846L20.0444 8.0613L16.1946 12.3698L15.9744 12.6163L16.0077 12.9451L16.5897 18.6936L11.3024 16.3637L11 16.2304L10.6976 16.3637L5.41026 18.6936L5.99229 12.9451L6.02559 12.6163L5.80538 12.3698L1.95561 8.0613L7.60263 6.83846L7.92564 6.76851L8.09198 6.48292L11 1.49017Z"
          stroke="#15141D"
          fill="none"
        />
      </svg>
    </div>
  );
};
