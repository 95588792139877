import { useOptimizerSetMode, useOptimizerSetTestModeLoad } from '../../../../../api/modules/optimizer/optimizer.hooks';
import { OptimizerMode } from '../../../../../api/modules/optimizer/optimizer.types';
import { OptimizerModeInternal } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../hooks';
import { ChannelInterface } from '../../../../../types';
import { getEnumKeyByValue } from '../../../../../utils/helpers';
import { TestFormBody } from '../types';

export const useOptimizerTest = (channel: ChannelInterface) => {
  const { mutate: setMode } = useOptimizerSetMode();
  const { mutate: setModeLoad } = useOptimizerSetTestModeLoad();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const submitConfiguration = (mode: OptimizerModeInternal, formValues: TestFormBody) => {
    turnOnBackdrop();
    setMode(
      {
        deviceId: channel.deviceId,
        channelId: channel.id,
        mode: OptimizerMode[getEnumKeyByValue(OptimizerModeInternal, mode)],
      },
      {
        onSuccess: ({ data }) => {
          if (data) {
            turnOnBackdrop();

            setModeLoad(
              {
                deviceId: channel.deviceId,
                channelId: channel.id,
                scr: [formValues.phase1, formValues.phase2, formValues.phase3],
              },
              {
                onSuccess: () => {
                  turnOffBackdrop();
                },
              },
            );
          } else {
            turnOffBackdrop();
          }
        },
      },
    );
  };

  return {
    submitConfiguration,
  };
};
