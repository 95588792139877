import React from 'react';

export const IconInstallation10: React.FC = () => (
  <svg
    className="icon-installation"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="34.8335" y="19" width="15.8333" height="31.6667" transform="rotate(90 34.8335 19)" stroke="#15141D" />
    <path d="M11.8748 26.9165H7.9165" stroke="#15141D" />
    <path d="M20.5833 26.9165H16.625" stroke="#15141D" />
    <path d="M29.2918 26.9165H25.3335" stroke="#15141D" />
    <path d="M7.91683 3.1665L14.2502 3.1665L15.8335 18.9998H6.3335L7.91683 3.1665Z" stroke="#15141D" />
  </svg>
);
