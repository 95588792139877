import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { IconButton, Popover } from '@mui/material';
import { IconMore } from '../icons';
import './index.scss';

export interface PopUpNavLinkInterface {
  label: string;
  onClick: () => void;
  hasLineBelow?: boolean;
  icon: ReactNode;
}

type PopUpNavProps = {
  links: PopUpNavLinkInterface[];
  className?: string;
  dark?: boolean;
  icon?: ReactNode;
};

export const PopUpNav: React.FC<PopUpNavProps> = ({ links, className, dark, icon = <IconMore /> }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const onClick = (link: PopUpNavLinkInterface) => {
    handleClose();
    if (link.onClick) link.onClick();
  };

  return (
    <div>
      {links.length ? (
        <IconButton
          className={classNames('popup-nav', { [className as string]: className, dark })}
          onClick={handleClick}
        >
          {icon && icon}
        </IconButton>
      ) : null}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ root: 'popup-nav__menu-root', paper: 'popup-nav__menu-paper' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ul className="popup-nav__menu">
          {links?.map((link: PopUpNavLinkInterface, linkIndex: number) => {
            return (
              link.label && (
                <React.Fragment key={linkIndex}>
                  <li className="popup-nav__menu-item">
                    <button className="popup-nav__menu-item-label" onClick={() => onClick(link)}>
                      {link.icon && <div className="icon">{link.icon}</div>}
                      {link.label}
                    </button>
                  </li>
                  {link.hasLineBelow && linkIndex + 1 !== links.length && (
                    <div className="popup-nav__divider">
                      <hr />
                    </div>
                  )}
                </React.Fragment>
              )
            );
          })}
        </ul>
      </Popover>
    </div>
  );
};
