import React from 'react';

export const IconExalusDinPowerMeter: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0273438" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M18.5039 18.5234V14.7139"
      stroke="#1D1D1B"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2656 18.5234V14.7139"
      stroke="#1D1D1B"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5039 14.7139H23.2658"
      stroke="#1D1D1B"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.7891 18.5234V14.7139"
      stroke="#1D1D1B"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.5508 18.5234V14.7139"
      stroke="#1D1D1B"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.7891 14.7139H37.551"
      stroke="#1D1D1B"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.7244 42H17.3303C16.0584 42 15.0273 40.9689 15.0273 39.697V21.303C15.0273 20.0311 16.0584 19 17.3303 19H38.7244C39.9963 19 41.0273 20.0311 41.0273 21.303V39.697C41.0273 40.9689 39.9963 42 38.7244 42Z"
      stroke="#15141D"
      strokeWidth="1.5"
    />
    <path
      d="M35.8273 29H20.2273C20.1169 29 20.0273 28.9105 20.0273 28.8V23.2C20.0273 23.0895 20.1169 23 20.2273 23H35.8273C35.9378 23 36.0273 23.0895 36.0273 23.2V28.8C36.0273 28.9105 35.9378 29 35.8273 29Z"
      stroke="#15141D"
      strokeWidth="1.5"
    />
    <path d="M41.0273 37.5H15.0273" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="23.7416" cy="26.143" r="0.714286" fill="#1D1D1B" />
    <circle cx="29.4565" cy="26.143" r="0.714286" fill="#1D1D1B" />
    <circle cx="32.3119" cy="26.143" r="0.714286" fill="#1D1D1B" />
    <circle cx="26.5991" cy="26.143" r="0.714286" fill="#1D1D1B" />
  </svg>
);
