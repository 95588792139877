import {
  GenericInput,
  GenericInputBistableMode,
  GenericInputMonostableMode,
} from '../../../../api/modules/generic-input/generic-input.types';
import {
  GenericInputBistableModeInternal,
  GenericInputMonostableModeInternal,
  GenericInputTypeInternal,
} from '../../../../data-access/gql-types/graphql';

export const inputTypeForRequest = {
  [GenericInputTypeInternal.Monostable]: GenericInput.Monostable,
  [GenericInputTypeInternal.Bistable]: GenericInput.Bistable,
};

export const monostableModeForRequest = {
  [GenericInputMonostableModeInternal.Normal]: GenericInputMonostableMode.Normal,
  [GenericInputMonostableModeInternal.Reversed]: GenericInputMonostableMode.Reversed,
};

export const bistableModeForRequest = {
  [GenericInputBistableModeInternal.Normal]: GenericInputBistableMode.Normal,
  [GenericInputBistableModeInternal.TwoWay]: GenericInputBistableMode.TwoWay,
};
