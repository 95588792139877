import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { PressureSensorDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { PressureControlHook } from '../types';

export const usePressureControl = (channel?: IDeviceChannel, disabled?: boolean): PressureControlHook => {
  if (disabled) return { pressure: null, time: '' };
  
  const pressureData = useMemo(() => {
    const pressureState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.PressureSensorState,
    ) as PressureSensorDeviceState;

    if (pressureState) {
      return pressureState.Data;
    }

    return null;
  }, [channel?.States]);

  const time = useMemo(() => {
    if (pressureData?.Time) return format(Date.parse(pressureData.Time), 'dd.MM, HH:mm');
    return '';
  }, [pressureData]);

  return {
    pressure: pressureData,
    time,
  };
};
