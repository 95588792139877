import { useTranslation } from 'react-i18next';
import { useActionBatch } from '../../api/modules/action/action.hooks';
import {
  CoverDirectionBatchChannel,
  CoverPositionBatchChannel,
  TypeOfActionType,
} from '../../api/modules/action/action.types';
import { ChannelInterface, CoverDirection } from '../../types';
import { toastInfo } from '../../utils/toast';

export const useCoverAction = (channels: ChannelInterface[]) => {
  const { t: ta } = useTranslation('action');
  const actionBatch = useActionBatch();

  const handleAction = (value: string | number) => {
    if (!channels.length) {
      toastInfo({ content: ta('trigger.validationErrors.deviceNotFound') });
      return;
    }

    if (typeof value === 'string') {
      actionBatch.mutate({
        controls: channels.map((channel) => ({
          channelId: channel.id,
          deviceId: channel.deviceId,
          direction: CoverDirection[value],
          $type: TypeOfActionType.BatchBlindSetDirectionRequest,
        })) as CoverDirectionBatchChannel[],
      });
    } else {
      actionBatch.mutate({
        controls: channels.map((channel) => ({
          channelId: channel.id,
          deviceId: channel.deviceId,
          position: value,
          $type: TypeOfActionType.BatchBlindSetPositionRequest,
        })) as CoverPositionBatchChannel[],
      });
    }
  };

  return {
    handleAction,
  };
};
