import React from 'react';
import { useTranslation } from 'react-i18next';
import { Markup } from 'react-render-markup';
import { Header, NavHeader, Page } from '../../components';
import { useFetchDocuments } from '../../hooks/documents/use-fetch-documents';
import './index.scss';

const RodoPage: React.FC = () => {
  const { t } = useTranslation('rodo');
  const { content } = useFetchDocuments('rodo');

  return (
    <Page
      className="rodo"
      header={
        <>
          <NavHeader />
          <Header title={t('rodoHeader')} />
        </>
      }
    >
      <Markup markup={content} />
    </Page>
  );
};

export default RodoPage;
