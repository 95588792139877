import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  MovementSensorDeviceState,
  TamperProtectionDeviceState,
  TamperProtectionStatus,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { MovementControlHook } from '../types';

export const useMovementControl = (channel?: IDeviceChannel, disabled?: boolean): MovementControlHook => {
  if (disabled) return { movement: null, isTamperProtection: false, time: '' };

  const movementData = useMemo(() => {
    const movementState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.MovementSensorState,
    ) as MovementSensorDeviceState | undefined;

    return movementState?.Data;
  }, [channel?.States]);

  const tamperProtection = useMemo(() => {
    const tamperState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.TamperProtectionState,
    ) as TamperProtectionDeviceState | undefined;

    return tamperState?.Data.State;
  }, [channel?.States]);

  const time = useMemo(
    () => (movementData?.Time ? format(Date.parse(movementData.Time), 'dd.MM, HH:mm') : ''),
    [movementData?.Time],
  );

  return {
    movement: movementData?.Movement ?? null,
    isTamperProtection: tamperProtection === TamperProtectionStatus.Breach || false,
    time,
  };
};
