import React from 'react';

export const IconExalusSecurityCamera: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.607422" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M41.6078 15.8594H20.607C18.9504 15.8594 17.6074 17.0846 17.6074 18.5959V26.1228C17.6074 27.6342 18.9504 28.8594 20.607 28.8594H41.6078C43.2645 28.8594 44.6074 27.6342 44.6074 26.1228V18.5959C44.6074 17.0846 43.2645 15.8594 41.6078 15.8594Z"
      stroke="#343840"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.6074 18.8594H41.0359C41.3516 18.8594 41.6074 19.4291 41.6074 20.1324V24.5864C41.6074 25.2897 41.3516 25.8594 41.0359 25.8594H39.6072"
      stroke="#343840"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6074 26.0905L12.6074 26.8594V16.8594L15.6074 17.6282V26.0905Z"
      stroke="#343840"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.5195 28.8291L35.6074 37.3004H46.0499"
      stroke="#343840"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46.0508 32.3594V42.243" stroke="#343840" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
