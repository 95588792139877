import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hours, Minutes, Seconds } from 'lavva.exalushome/build/js/Helpers';
import { TimeSpanParam } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { DelayTask } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { TimeInput } from '../../../components/time-input';
import { useTaskEdit } from '../../../hooks/use-task-edit';
import { DelayTaskParams } from '../../../types';
import { getTimeParts } from '../../../utils/time';

const DelayView: React.FC = () => {
  const { t } = useTranslation('action');
  const [timespan, setTimeSpan] = useState<string>('00:00:00');
  const { task, finalizeDelayTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      setTimeSpan((task.taskParams as DelayTaskParams).delay);
    }
  }, [task]);

  const handleSubmit = () => {
    const task = new DelayTask();
    const timeParts = getTimeParts(timespan);
    const hours = timeParts.hours as Hours;
    const minutes = timeParts.minutes as Minutes;
    const seconds = timeParts.seconds as Seconds;
    task.Delay = new TimeSpanParam(seconds, minutes, hours, 0);

    finalizeDelayTaskSubmit(task);
  };

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.tasks.delay')} />
        </>
      }
    >
      <div className="grid-list-24">
        <TimeInput time={timespan} setTime={setTimeSpan} label={t('action.create.tasks.delay')} />
        <SubmitButton onClick={handleSubmit} />
      </div>
    </Page>
  );
};

export default DelayView;
