import React from 'react';

export const IconExalusWallMountedThermoregulator: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.738281" y="1" width="56" height="56" rx="12" fill="white" />
    <path
      d="M28.178 31.876C28.7536 32.2512 29.2684 32.7119 29.7045 33.2399C30.669 34.4079 31.2485 35.9056 31.2485 37.5387C31.2485 41.2694 28.2242 44.2938 24.4934 44.2938C20.7627 44.2938 17.7383 41.2694 17.7383 37.5387C17.7383 35.1673 18.9602 33.0813 20.8088 31.876"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1406 31.4413V25.2942L21.1406 16.353C21.1406 14.5012 22.6418 13 24.4936 13V13C26.3454 13 27.8466 14.5012 27.8466 16.353V25.2942L27.8466 31.4413"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="24.4931"
      cy="37.5878"
      r="2.23531"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4922 34.7934L24.4922 16.5938"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5332 20.6152L38.0343 17.1163L34.5353 20.6152"
      stroke="#15141D"
      strokeWidth="1.37931"
      strokeLinecap="round"
    />
    <path
      d="M34.5352 25.6152L38.0341 29.1142L41.533 25.6152"
      stroke="#15141D"
      strokeWidth="1.37931"
      strokeLinecap="round"
    />
    <path d="M38.1465 17.448V28.8369" stroke="#15141D" strokeWidth="1.37931" strokeLinecap="round" />
  </svg>
);
