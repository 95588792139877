import React from 'react';

export const IconCloset: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="41"
      height="25"
      viewBox="0 0 41 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.4852 23.7211H4.45193C2.97495 23.7211 1.7793 22.5063 1.7793 21.0037C1.7793 19.9679 2.36114 19.0216 3.27546 18.5612L20.4686 10.019L37.6617 18.5676C38.576 19.0216 39.1578 19.9679 39.1578 21.0101C39.1578 22.5126 37.9622 23.7275 36.4852 23.7275V23.7211Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.083 3.70173C18.083 2.19278 19.4385 1.00352 20.9794 1.32961C21.881 1.52143 22.6099 2.26312 22.7953 3.17744C23.051 4.41785 22.3733 5.53677 21.3503 5.95237C20.8324 6.16337 20.4679 6.64291 20.4679 7.21196V10.0125"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
