import React from 'react';

export const IconExalusMultisensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.898438" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23111_32100)">
      <path
        d="M17.0856 10.4145C17.0865 10.4153 17.0876 10.4161 17.0888 10.4171C17.0772 10.4091 17.0754 10.4067 17.0856 10.4145ZM17.4602 10.7907C17.6281 10.9725 17.8359 11.2076 18.0747 11.4891C18.7657 12.3036 19.6913 13.4755 20.6172 14.7887C21.5444 16.1037 22.4624 17.547 23.1458 18.9065C23.8404 20.288 24.2484 21.4983 24.2484 22.3737C24.2484 26.1569 21.1816 29.2237 17.3984 29.2237C13.6153 29.2237 10.5484 26.1569 10.5484 22.3737C10.5484 21.5008 10.9548 20.3178 11.6482 18.9706C12.3309 17.6444 13.2481 16.2403 14.1761 14.9499C15.1024 13.6619 16.0301 12.4998 16.727 11.6592C17.0177 11.3084 17.2678 11.0142 17.4602 10.7907Z"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M21.534 23.0312C21.534 23.9258 21.2275 24.7466 20.7167 25.3881M17.9258 26.7496C18.5998 26.7496 19.2306 26.5591 19.7703 26.2276"
        stroke="#15141D"
        strokeWidth="1.3"
      />
      <circle
        cx="37.8991"
        cy="19.86"
        r="4.16667"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8984 13.1934L37.8984 10.6934"
        stroke="#15141D"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8984 13.1934L37.8984 10.6934"
        stroke="#15141D"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8984 29.0264L37.8984 26.5264"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5664 19.8604L47.0664 19.8604"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7324 19.8604L31.2324 19.8604"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.6133 24.5742L44.381 26.342"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.418 13.3779L33.1857 15.1457"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1855 24.5742L31.4178 26.342"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3809 13.3779L42.6131 15.1457"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9522 28V36.8255M27.9522 36.8255L31.584 33.1776M27.9522 36.8255L24.3203 33.1776"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38 45L16.5 45C14.567 45 13 43.433 13 41.5V41.5C13 39.567 14.567 38 16.5 38H17.3421"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M23 41H39.5C41.433 41 43 39.433 43 37.5V37.5C43 35.567 41.433 34 39.5 34H38.4795"
        stroke="#15141D"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23111_32100">
        <rect width="38" height="38" fill="white" transform="translate(9.89844 9.85938)" />
      </clipPath>
    </defs>
  </svg>
);
