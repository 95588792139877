import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import c from 'classnames';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { rgbToHex } from '@mui/material';
import { lightOrDark, rgbToHsb } from '../../utils/helpers';
import { InitiateButton } from '../initiate-button';
import './index.scss';

interface Props {
  onColorPicked: (hue: number, saturation: number) => void;
  rgbValue: string;
  handleLeftClick?: () => void;
  handleRightClick?: () => void;
  handleButtonClick?: (status: boolean) => void;
  isOn?: boolean;
  activeColor?: string;
  pickerCircleRadius?: number;
  innerRadius?: number;
  setPreventSwipeViews?: Dispatch<SetStateAction<boolean>>;
}

export const ColorPicker: React.FC<Props> = ({
  onColorPicked,
  rgbValue,
  handleLeftClick,
  handleRightClick,
  handleButtonClick,
  isOn,
  activeColor,
  pickerCircleRadius = 16,
  innerRadius = 48,
  setPreventSwipeViews,
}) => {
  const [pointX, setPointX] = useState<number>(54);
  const [pointY, setPointY] = useState<number>(54);
  const [quartet, setQuartet] = useState<number>(1);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const getAlpha = useCallback(
    (hue: number) => {
      if (hue < 91) {
        setQuartet(1);
        return hue;
      }

      if (hue < 181) {
        setQuartet(2);
        return 180 - hue;
      }

      if (hue < 271) {
        setQuartet(3);
        return hue - 180;
      }

      setQuartet(4);
      return 360 - hue;
    },
    [setQuartet],
  );

  useEffect(() => {
    if (rgbValue && colorPickerRef && colorPickerRef.current) {
      const width = 240;
      const height = 240;
      const radius = Math.min(width, height) / 2;

      const hsb = rgbToHsb(rgbValue?.split(','));

      const tz = Math.pow(hsb[1] / 100, 0.625) * radius;
      const alpha = getAlpha(hsb[0]);

      const sin = Math.sin(degreeToRadian(alpha));
      const cos = Math.cos(degreeToRadian(alpha));

      const halfWidth = width / 2;
      const halfHeight = height / 2;

      const iz = Math.min(tz, radius - pickerCircleRadius);
      let ix = 0,
        iy = 0;

      if (quartet === 1) {
        // top right quarter
        ix = sin * iz;
        iy = Math.sqrt(iz ** 2 - ix ** 2);
        iy = halfHeight - iy;
        ix = halfWidth + ix;
      }
      if (quartet === 2) {
        // bottom right quarter
        iy = cos * iz;
        ix = Math.sqrt(iz ** 2 - iy ** 2);
        ix = halfWidth + ix;
        iy = halfHeight + iy;
      }
      if (quartet === 3) {
        // bottom left quarter
        ix = sin * iz;
        iy = Math.sqrt(iz ** 2 - ix ** 2);
        ix = halfWidth - ix;
        iy = halfHeight + iy;
      }
      if (quartet === 4) {
        // top left quarter
        iy = cos * iz;
        ix = Math.sqrt(iz ** 2 - iy ** 2);
        ix = halfWidth - ix;
        iy = halfHeight - iy;
      }

      // TEST
      // if ((handleLeftClick && handleRightClick) || handleButtonClick) {
      //   const centerOfCircle = { x: halfWidth, y: halfHeight };
      //   const centerOfPickedValue = { x: ix, y: iy };

      //   const h = centerOfCircle.x - centerOfPickedValue.x;
      //   const v = centerOfCircle.y - centerOfPickedValue.y;
      //   const result = Math.sqrt(h ** 2 + v ** 2);

      //   if (result <= innerRadius + pickerCircleRadius) {
      //     const radian = alpha * (Math.PI / 180);
      //     ix = (innerRadius + pickerCircleRadius) * Math.cos(radian);
      //     iy = (innerRadius + pickerCircleRadius) * Math.sin(radian);
      //     ix = ix + halfWidth;
      //     iy = iy + halfHeight;
      //   }
      // }

      setPointX(ix);
      setPointY(iy);
    }
  }, [getAlpha, setPointX, setPointY, rgbToHsb, quartet, colorPickerRef.current]);

  const style = useMemo(
    () =>
      ({
        '--point-x': `${pointX}px`,
        '--point-y': `${pointY}px`,
        '--circle-size': `${pickerCircleRadius * 2}px`,
        '--margin': `-${pickerCircleRadius}px 0 0 -${pickerCircleRadius}px`,
      } as React.CSSProperties),
    [pointX, pointY],
  );

  const buttonStyle = useMemo(() => {
    if (!activeColor) {
      return undefined;
    }

    return {
      '--button-background-color': rgbToHex(`rgb(${activeColor})`),
      '--button-icon-color': lightOrDark(`rgb(${activeColor})`) === 'light' ? '#15141D' : '#FFFFFF',
    } as React.CSSProperties;
  }, [activeColor]);

  const radianToDegrees = (radian: number) => (radian * 180) / Math.PI;
  const degreeToRadian = (degree: number) => (degree * Math.PI) / 180;

  const handledColorPicker = useCallback(
    (x, y, width, height, pointerUp) => {
      const radius = Math.min(width, height) / 2;
      const halfWidth = width / 2;
      const halfHeight = height / 2;
      const rx = x - halfWidth;
      const ry = y - halfHeight;
      const tx = Math.abs(rx);
      const ty = Math.abs(ry);
      const tz = Math.hypot(tx, ty);
      const iz = Math.min(tz, radius - pickerCircleRadius);
      let ix = x,
        iy = y;

      let angle = 0;
      if (rx >= 0 && ry <= 0) {
        // top right quarter
        angle = Math.atan2(tx, ty);
        ix = Math.sin(angle) * iz;
        iy = Math.sqrt(iz ** 2 - ix ** 2);
        iy = halfHeight - iy;
        ix = halfWidth + ix;
        angle = radianToDegrees(angle);
      } else if (rx >= 0 && ry >= 0) {
        // bottom right quarter
        angle = Math.atan2(ty, tx);
        iy = Math.sin(angle) * iz;
        ix = Math.sqrt(iz ** 2 - iy ** 2);
        ix = halfWidth + ix;
        iy = halfHeight + iy;
        angle = radianToDegrees(angle) + 90;
      } else if (rx <= 0 && ry >= 0) {
        // bottom left quarter
        angle = Math.atan2(tx, ty);
        ix = Math.sin(angle) * iz;
        iy = Math.sqrt(iz ** 2 - ix ** 2);
        iy = halfHeight + iy;
        ix = halfWidth - ix;
        angle = radianToDegrees(angle) + 180;
      } else if (rx <= 0 && ry <= 0) {
        // top left quarter
        angle = Math.atan2(ty, tx);
        iy = Math.sin(angle) * iz;
        ix = Math.sqrt(iz ** 2 - iy ** 2);
        ix = halfWidth - ix;
        iy = halfHeight - iy;
        angle = radianToDegrees(angle) + 270;
      }

      if ((handleLeftClick && handleRightClick) || handleButtonClick) {
        const centerOfCircle = { x: halfWidth, y: halfHeight };
        const centerOfPickedValue = { x: ix, y: iy };

        const h = centerOfCircle.x - centerOfPickedValue.x;
        const v = centerOfCircle.y - centerOfPickedValue.y;
        const result = Math.sqrt(h ** 2 + v ** 2);

        if (result <= innerRadius + pickerCircleRadius) {
          const radian = (angle - 90) * (Math.PI / 180);
          ix = (innerRadius + pickerCircleRadius) * Math.cos(radian);
          iy = (innerRadius + pickerCircleRadius) * Math.sin(radian);
          ix = ix + halfWidth;
          iy = iy + halfHeight;
        }
      }

      setPointX(ix);
      setPointY(iy);

      if (pointerUp) onColorPicked(angle, Math.pow(Math.min(1, tz / radius), 1.6));
    },
    [onColorPicked, setPointX, setPointY],
  );

  const onPointerUp = useCallback(
    debounce((event: React.PointerEvent<HTMLDivElement>, offsetX: number, offsetY: number) => {
      handledColorPicker(
        offsetX,
        offsetY,
        (event.target as HTMLDivElement).scrollWidth,
        (event.target as HTMLDivElement).scrollHeight,
        true,
      );
    }, 300),
    [handledColorPicker],
  );

  const onTouchMove = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (setPreventSwipeViews) setPreventSwipeViews(true);

      if (colorPickerRef && colorPickerRef.current) {
        const element = colorPickerRef.current.getBoundingClientRect();
        const x = event.targetTouches[0].clientX - element.left;
        const y = event.targetTouches[0].clientY - element.top;

        handledColorPicker(
          x,
          y,
          (event.target as HTMLDivElement).scrollWidth,
          (event.target as HTMLDivElement).scrollHeight,
          false,
        );
      }
    },
    [colorPickerRef, handledColorPicker],
  );

  return (
    <div className="color-picker-container">
      <div
        ref={colorPickerRef}
        style={style}
        className={classNames('color-picker', { 'turn-on': isOn })}
        onPointerUp={(e) => onPointerUp(e, e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
        onTouchMove={onTouchMove}
      />
      {handleLeftClick && handleRightClick && (
        <div
          ref={buttonRef}
          className={c('power-button-container', {
            'power-button-container--with-inner-ring': innerRadius,
          })}
        >
          <div className="circle-container double" {...(isOn ? { style: buttonStyle } : {})}>
            <button className="on" onClick={handleLeftClick}>
              ON
            </button>
            <div className="separator" />
            <button className="off" onClick={handleRightClick}>
              OFF
            </button>
          </div>
        </div>
      )}
      {handleButtonClick && typeof isOn === 'boolean' && (
        <div
          ref={buttonRef}
          className={c('power-button-container', {
            'power-button-container--with-inner-ring': innerRadius,
          })}
        >
          <div className="circle-container circle-container--single-button" style={buttonStyle}>
            <InitiateButton isOn={isOn} isSmall onClickControl={handleButtonClick} />
          </div>
        </div>
      )}
    </div>
  );
};
