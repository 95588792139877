import React from 'react';

export const IconDoorEntryCircle: React.FC = () => {
  return (
    <svg
      className="icon-door-entry-circle"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.25" stroke="white" strokeWidth="1.5" />
      <path
        d="M9.57227 9.38379V22.9747"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7268 8.02441L9.57227 9.38351"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7268 24.3337L9.57227 22.9746"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7266 8.02441V24.3335"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8038 22.9746H17.7266"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7266 9.38379H21.8038"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8047 22.9747V9.38379"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6875 15.499V16.8581"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
