import React, { ReactNode } from 'react';
import c from 'classnames';
import './index.scss';

interface PropsInterface {
  children: ReactNode;
  active: boolean;
}

export const RecoveryModeWrapper: React.FC<PropsInterface> = ({ children, active }) => (
  <div
    className={c('recovery-mode-wrapper', {
      'recovery-mode-wrapper--active': active,
    })}
  >
    {children}
  </div>
);
