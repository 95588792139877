import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DialogTimePicker, Header, IconEdit, Input, NavHeader, Page, SubmitButton } from '../../../components';
import Calendar from '../../../components/calendar';
import {
  ModifyShareInfoChannelInput,
  ModifyShareInfoItemBaseInput,
  ShareInfoModificationType,
} from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { SharedItemType, SharingType } from '../../../types';
import { leadingZero } from '../../../utils/helpers';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import { useModify } from '../hooks/use-modify';
import { prepareShareDateTime } from '../utils';
import './index.scss';

const ShareAccessTime: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('installation');
  const [showTimePicker, setShowTimePicker] = useState<'start' | 'end' | ''>('');
  const { state, methods, hasInstallation } = useInstallationShare();
  const { modyfyGroupAccess, modyfyChannelAccess, modifyUserAccessesLoading } = useModify();
  const { refetchSharingInfo } = useMember();
  const [dateStart, setDateStart] = useState<Date>(state.dateStart);
  const [dateEnd, setDateEnd] = useState<Date>(state.dateEnd);
  const [timeStart, setTimeStart] = useState<string>(
    state.timeStart || [new Date().getHours(), new Date().getMinutes()].map(leadingZero).join(':'),
  );
  const [timeEnd, setTimeEnd] = useState<string>(
    state.timeEnd || [new Date().getHours(), new Date().getMinutes()].map(leadingZero).join(':'),
  );

  const handleCloseTimeDialog = () => setShowTimePicker('');

  const setTime = (value: string) => {
    if (showTimePicker === 'start') setTimeStart(value);
    else setTimeEnd(value);
  };

  const setDate = (date: Date, type: string) => {
    if (type === 'start') setDateStart(date);
    else setDateEnd(date);
  };

  const handleNext = () => {
    methods.handleSetShareDateStart(dateStart);
    methods.handleSetShareTimeStart(timeStart);
    methods.handleSetShareDateEnd(dateEnd);
    methods.handleSetShareTimeEnd(timeEnd);

    const sharedFrom = prepareShareDateTime(dateStart, timeStart);
    const sharedUntil = prepareShareDateTime(dateEnd, timeEnd);

    switch (state.shareInfoModificationType) {
      case ShareInfoModificationType.Undefined: {
        history.push(ROUTES.InstallationSummaryShare());
        break;
      }
      case ShareInfoModificationType.Modify: {
        if (state.subject === SharedItemType.Group) {
          const groups: ModifyShareInfoItemBaseInput[] = [
            {
              id: state.editedId,
              shareInfoModificationType: ShareInfoModificationType.Modify,
              sharedFrom,
              sharedUntil,
            },
          ];

          modyfyGroupAccess(state.userId, groups, () => {
            refetchSharingInfo();
            history.go([SharingType.Timed, SharingType.Quantitative].includes(state.access) ? -2 : -3);
          });
        } else {
          const channels: ModifyShareInfoChannelInput[] = [
            {
              id: state.editedId,
              shareInfoModificationType: ShareInfoModificationType.Modify,
              sharedFrom,
              sharedUntil,
            },
          ];

          modyfyChannelAccess(state.userId, channels, () => {
            refetchSharingInfo();
            history.go([SharingType.Timed, SharingType.Quantitative].includes(state.access) ? -2 : -3);
          });
        }

        break;
      }
      case ShareInfoModificationType.Add: {
        if (!hasInstallation) {
          history.push(ROUTES.InstallationSummaryShare());
        } else {
          if (state.subject === SharedItemType.Group) {
            const groups: ModifyShareInfoItemBaseInput[] = state.groups.map((g) => ({
              id: g.id,
              sharedFrom,
              sharedUntil,
              shareInfoModificationType: ShareInfoModificationType.Add,
            }));
            modyfyGroupAccess(state.userId, groups, () => {
              refetchSharingInfo();
              history.go(-3);
            });
          } else {
            const channels: ModifyShareInfoChannelInput[] = state.channels.map((d) => ({
              id: d.id,
              sharedFrom,
              sharedUntil,
              shareInfoModificationType: ShareInfoModificationType.Add,
            }));
            modyfyChannelAccess(state.userId, channels, () => {
              refetchSharingInfo();
              history.go(-3);
            });
          }
        }
        break;
      }
    }
  };

  const submitBtn = useMemo(() => {
    if (
      (state.shareInfoModificationType === ShareInfoModificationType.Modify && state.access === SharingType.Timed) ||
      (state.shareInfoModificationType === ShareInfoModificationType.Add &&
        state.access === SharingType.Timed &&
        [SharedItemType.Group, SharedItemType.Channel].includes(state.subject) &&
        hasInstallation)
    ) {
      return { title: tc('buttons.save'), loading: modifyUserAccessesLoading };
    }

    return { title: tc('buttons.next'), loading: false };
  }, [state.access, state.shareInfoModificationType, modifyUserAccessesLoading, hasInstallation]);

  return (
    <Page
      className="share-time"
      header={
        <>
          <NavHeader />
          <Header title={`${t('share_path.access')} ${t('share_path.access_timed')}`} isUnderline column>
            <div className="share-count-info">{t('share_path.access_timed_info')}</div>
          </Header>
        </>
      }
    >
      <p className="period_label">{t('share_path.start')}</p>
      <div className="date_time">
        <Calendar date={dateStart} label={t('share_path.date')} onChange={(date) => setDate(date, 'start')} />
        <Input
          defaultValue={timeStart}
          value={timeStart}
          required
          readOnly
          label={t('share_path.hour')}
          onClickInput={() => setShowTimePicker('start')}
          endIcon={<IconEdit />}
        />
      </div>
      <p className="period_label">{t('share_path.end')}</p>
      <div className="date_time">
        <Calendar date={dateEnd} label={t('share_path.date')} onChange={(date) => setDate(date, 'end')} />
        <Input
          defaultValue={timeEnd}
          value={timeEnd}
          required
          readOnly
          label={t('share_path.hour')}
          onClickInput={() => setShowTimePicker('end')}
          endIcon={<IconEdit />}
        />
      </div>
      <SubmitButton type="button" onClick={handleNext} isLoading={submitBtn.loading}>
        {submitBtn.title}
      </SubmitButton>
      <DialogTimePicker
        header={t('share_path.hour')}
        open={!!showTimePicker}
        setOpen={handleCloseTimeDialog}
        onSave={(value) => setTime(value)}
        time={showTimePicker === 'start' ? timeStart : timeEnd}
      />
    </Page>
  );
};

export default ShareAccessTime;
