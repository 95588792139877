import React from 'react';

export const IconInstallation4: React.FC = () => (
  <svg
    className="icon-installation"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3.16699" y="15.8335" width="33.25" height="19" stroke="#15141D" />
    <path d="M19.792 6.3335L36.417 15.8335L3.16699 15.8335L19.792 6.3335Z" stroke="#15141D" />
    <path d="M14.25 17.4165L14.25 33.2498" stroke="#15141D" />
    <path d="M25.333 17.4165L25.333 33.2498" stroke="#15141D" />
    <path d="M14.25 16.625L25.3333 33.25" stroke="#15141D" />
  </svg>
);
