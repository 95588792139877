import React from 'react';

export const IconExalusRemote: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0273438" y="0.71875" width="56" height="56" rx="12" fill="white" />
    <rect x="16.0273" y="12.8594" width="24.1949" height="31.7559" rx="2.64632" fill="white" />
    <path
      d="M35.7837 41.9691H20.4663C19.4763 41.9691 18.6738 41.1666 18.6738 40.1766V17.2983C18.6738 16.3084 19.4763 15.5059 20.4663 15.5059H35.7837C36.7736 15.5059 37.5761 16.3084 37.5761 17.2983V40.1766C37.5761 41.1666 36.7736 41.9691 35.7837 41.9691Z"
      stroke="#15141D"
      strokeWidth="1.5"
    />
    <path
      d="M27.0746 21.4534L23.6559 23.2575C22.0656 24.0968 22.6577 26.5215 24.453 26.5215H32.3532C34.1485 26.5215 34.7406 24.0968 33.1503 23.2575L29.7316 21.4534C28.8996 21.0142 27.9067 21.0142 27.0746 21.4534Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.7316 35.9197L33.1503 34.1155C34.7406 33.2762 34.1485 30.8516 32.3532 30.8516H24.453C22.6577 30.8516 22.0656 33.2762 23.6559 34.1155L27.0746 35.9197C27.9067 36.3588 28.8996 36.3588 29.7316 35.9197Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
