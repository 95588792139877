import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ExalusInstallationPayload, IntegrationType } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { getExalusInstallationCredentials } from '../../exalus/utils';

export const useInstallationListHeader = () => {
  const history = useHistory();
  const { installationList, integrationType, selectedInstallationId, selectedInstallation } = useInstallation();

  const sortButton = useMemo(() => installationList.length > 0 && !isLocalApp, [installationList]);

  const goToDashboard = () => {
    if (integrationType === IntegrationType.Exalus) {
      const exalusCredentials = getExalusInstallationCredentials(selectedInstallationId);
      if (exalusCredentials) history.replace(ROUTES.Root());
      else {
        if (selectedInstallation) {
          const { externalPayload, installationId } = selectedInstallation;

          history.push(
            ROUTES.ExalusLogin(
              (externalPayload as ExalusInstallationPayload).serialNumber,
              (externalPayload as ExalusInstallationPayload).pin,
              installationId,
            ),
          );
        }
      }
    } else history.replace(ROUTES.Root());
  };

  return {
    sortButton,
    goToDashboard,
  };
};
