import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IControllerChatMessage } from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/ControllerChat/IControllerChat';
import { ChatType, IMessage } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { cloneDeep, groupBy } from 'lodash';
import { scrollBottom, scrollTop } from '../../../../../utils/helpers/scroll';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusStorageContext } from '../../../context/storage';
import { useHandleDataFrameErrors } from '../../../hooks';
import { ChatMessage } from '../types';

export const useChat = <T,>(chatList: ChatMessage<T>[]) => {
  const { i18n, t } = useTranslation('chat');
  const { chatApi } = useExalusServicesContext();
  const { handleError } = useHandleDataFrameErrors();
  const { readingChat } = useExalusStorageContext();

  useEffect(() => {
    return () => {
      scrollTop();
    };
  }, []);

  useEffect(() => scrollBottom(), [chatList]);

  const mapMessages = <T,>(data: T[]) => {
    const groupMessages = groupBy(data, (x) => format(new Date((x as IMessage).DateTime), 'dd.MM'));
    const chat: ChatMessage<T>[] = Object.keys(groupMessages).map((key) => ({
      dateGroup: key !== format(new Date(), 'dd.MM') ? key : t('today'),
      messages: groupMessages[key],
    }));

    return chat;
  };

  const messageHandler = <T,>(message: T, setValue: Dispatch<SetStateAction<ChatMessage<T>[]>>) => {
    setValue((chat) => {
      const chatMessagesTemp = cloneDeep(chat);
      const index = chatMessagesTemp.findIndex((x) => x.dateGroup === t('today'));

      if (index !== -1) {
        const dotsIndex = chatMessagesTemp[index].messages.findIndex((msg) => (msg as IMessage).MessageText === 'DOTS');
        if (dotsIndex !== -1) chatMessagesTemp[index].messages.splice(dotsIndex, 1);

        chatMessagesTemp[index].messages.push(message);
      } else chatMessagesTemp.push({ dateGroup: t('today'), messages: [message] });

      return chatMessagesTemp;
    });

    if ('speechSynthesis' in window && (message as IControllerChatMessage).TextToSpeechMessage && readingChat) {
      const msg = new SpeechSynthesisUtterance();
      msg.text = (message as IControllerChatMessage).TextToSpeechMessage;
      msg.lang = i18n.language;
      window.speechSynthesis.speak(msg);
    }
  };

  const sendMessage = async (message: string, setMessage: Dispatch<SetStateAction<string>>, chatType: ChatType) => {
    const chat = chatApi.GetChatImplementation(chatType);
    const result = await chat.SendMessageAsync(message);

    if (result !== Status.OK) {
      handleError(result);
    } else {
      setMessage('');
    }
  };

  return {
    mapMessages,
    messageHandler,
    sendMessage,
  };
};
