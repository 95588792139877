import { useCallback } from 'react';
import {
  useLightSetBrightness,
  useLightSetRgb,
  useLightSetTemperature,
  useLightToggleOff,
  useLightToggleOn,
} from '../../api/modules/light/light.hooks';
import { ChannelTypeInternal, LightToggleValueInternal } from '../../data-access/gql-types/graphql';
import { ChannelInterface, ChannelLightStateInterface } from '../../types';
import { useChannelsState } from '../channels-state';

interface UseLightActionInterface {
  handleControlOn: (status: boolean) => void;
  handleControlBrightness: (value: number) => void;
  handleControlTemperature: (value: number) => void;
  handleControlRgb: (value: string) => void;
  isOn: boolean;
}

export const useLight = (channel: ChannelInterface): UseLightActionInterface => {
  const toggleOn = useLightToggleOn();
  const toggleOff = useLightToggleOff();
  const setBrightness = useLightSetBrightness();
  const setTemperature = useLightSetTemperature();
  const setColor = useLightSetRgb();
  const { channelState } = useChannelsState();

  const isOn =
    channel.data.type === ChannelTypeInternal.Light
      ? (channelState[channel.id] as ChannelLightStateInterface)?.toggleValue === LightToggleValueInternal.On
      : false;

  const handleControlOn = (status: boolean) => {
    if (!status) {
      toggleOff.mutate({ deviceId: channel.deviceId, channelId: channel.id });
    } else {
      toggleOn.mutate({ deviceId: channel.deviceId, channelId: channel.id });
    }
  };

  const handleControlBrightness = (value: number) => {
    setBrightness.mutate({ deviceId: channel.deviceId, channelId: channel.id, brightness: value });
  };

  const handleControlTemperature = useCallback(
    (value: number) => {
      setTemperature.mutate({ deviceId: channel.deviceId, channelId: channel.id, temperature: value });
    },
    [channel],
  );

  const handleControlRgb = (value: string) => {
    setColor.mutate({
      deviceId: channel.deviceId,
      channelId: channel.id,
      r: Number(value.split(',')[0]),
      g: Number(value.split(',')[1]),
      b: Number(value.split(',')[2]),
    });
  };

  return { handleControlOn, handleControlBrightness, handleControlTemperature, handleControlRgb, isOn };
};
