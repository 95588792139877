import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { CustomBackdrop, EmptyStateBox, Page } from '../../../../../components';
import { ROUTES } from '../../../../../routes';
import { useExalusContext } from '../../../context';
import { GroupSortBody } from './components/body';
import { GroupSortHeader } from './components/header';
import { GroupSortable } from './types';

const GroupSort: React.FC = () => {
  const { t } = useTranslation('groups');
  const { allGroups, groupsFetched, refetchAllGroups } = useExalusContext();
  const [sortableGroups, setSortableGroups] = useState<GroupSortable[]>([]);

  useEffect(() => {
    if (allGroups) {
      const groups: GroupSortable[] = allGroups.map((group: IChannelsGroup) => ({ group, id: group.Guid }));
      setSortableGroups(groups);
    }
  }, [allGroups]);

  return (
    <Page isStickyHeader header={<GroupSortHeader />}>
      <>
        {groupsFetched ? (
          <>
            {sortableGroups.length > 0 ? (
              <GroupSortBody
                sortableGroups={sortableGroups}
                setSortableGroups={setSortableGroups}
                refetchAllGroups={refetchAllGroups}
              />
            ) : (
              <EmptyStateBox
                header={t('groups')}
                content={t('descriptionEmptyList')}
                btnText={t('create')}
                linkTo={ROUTES.GroupCreate()}
              />
            )}
          </>
        ) : (
          <CustomBackdrop loading />
        )}
      </>
    </Page>
  );
};

export default GroupSort;
