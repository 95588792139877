import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { meanBy, sumBy } from 'lodash';
import { groupBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { CustomBackdrop, EmptyStateBox, IconWarning, Switch } from '../../../../../components';
import {
  ChannelTypeInternal,
  MeasurementChannelKind,
  MeterCommonMeasurementsQueryVariables,
  MeterTypeInternal,
  Query,
  ScalarParameterTypeInternal,
  VectorParameterTypeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { METER_COMMON_MEASUREMENTS } from '../../../../../data-access/queries/meter';
import { useApi, useInstallation } from '../../../../../hooks';
import { ChannelInterface } from '../../../../../types';
import { MeasurementItem, MeasurementGroup } from '../../../measurement/components/measurement-item';
import { PhasesButtonGroup } from '../../../measurement/components/phases-button-group';
import {
  averageSummaryMeasurements,
  basicVectorMeasurementsTypes,
  filteredPhasesSumMeasurements,
  filteredSummaryMeasurements,
  measurementParameters,
} from '../../../measurement/utils';
import { sortedParameters, sortedSummary } from '../../analysis/utils/helpers';
import '../index.scss';

interface PropsInterface {
  channel: ChannelInterface;
}

export const MeterMeasurements: React.FC<PropsInterface> = ({ channel }) => {
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { t } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [advancedParameters, setAdvancedParameters] = useState<boolean>(false);
  const { convertNumberToMeasurement } = useApi();
  const kind = MeasurementChannelKind.Meter;

  const { data, loading } = useQuery<Query, MeterCommonMeasurementsQueryVariables>(METER_COMMON_MEASUREMENTS, {
    variables: {
      installationId: selectedInstallationId,
      input: {
        channelId: channel.id,
        deviceId: channel.deviceId,
        kind,
        indices: [],
      },
    },
    skip: skipLavvaFetch,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 60 * 1000,
  });

  const phaseTabs = useMemo(() => {
    const phases = groupBy(data?.meterCommonMeasurements.data?.vectorMeasurements, 'index');
    return Object.keys(phases).map((phase) => Number(phase));
  }, [data]);

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  const vectorMeasurements = useMemo(() => {
    let measurements = data?.meterCommonMeasurements.data?.vectorMeasurements || [];
    if (isStandalone) {
      measurements = measurements?.filter((x) =>
        !advancedParameters
          ? basicVectorMeasurementsTypes.includes(x.type)
          : !basicVectorMeasurementsTypes.includes(x.type) || x.type === 10,
      );
    }

    return measurements;
  }, [data?.meterCommonMeasurements.data?.vectorMeasurements, isStandalone, advancedParameters]);

  const phaseMeasurementsGroupedByPhase = useMemo(
    () => groupBy(sortedParameters(vectorMeasurements), 'index'),
    [vectorMeasurements],
  );

  const tariffsQuantity = useMemo(() => {
    const period1 = !!vectorMeasurements.find((x) => x.type === 15);
    const period2 = !!vectorMeasurements.find((x) => x.type === 16);
    const period3 = !!vectorMeasurements.find((x) => x.type === 17);
    return +period1 + +period2 + +period3;
  }, [vectorMeasurements]);

  const phaseMeasurementsGroupedByType = useMemo(() => groupBy(vectorMeasurements || [], 'type'), [vectorMeasurements]);

  if (loading && !data) return <CustomBackdrop loading />;

  if (
    !data?.meterCommonMeasurements.data?.scalarMeasurements.length &&
    !data?.meterCommonMeasurements.data?.vectorMeasurements.length
  ) {
    return (
      <div className="p-t-16 p-l-24 p-r-24">
        <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
      </div>
    );
  }

  const handleControl = (e: ChangeEvent<HTMLInputElement>) => setAdvancedParameters(e.target.checked);

  return (
    <div className="list-measurement p-l-24 p-r-24">
      <div className="list-measurement__tab-container">
        <PhasesButtonGroup
          onSelectedPhases={(phases) => setActiveTabIndex(phases[0]?.value)}
          defaultPhases={[0, ...(phaseTabs || [])]}
          className="m-t-0 m-b-16 phases-button-group--background-element"
          singleChoice
          {...(!isStandalone ? { itemPrefix: t('outputShort') } : {})}
        />
      </div>
      {isStandalone && (
        <div className="row-container m-t-16">
          <Switch checked={advancedParameters} onChange={handleControl} />
          <p>{t('advancedParameters')}</p>
        </div>
      )}
      <div className="list-measurement__wrapper grid-list-16">
        {activeTabIndex === 0 ? (
          <>
            {sortedSummary(phaseMeasurementsGroupedByType)
              ?.filter((phaseMeasurementGroupType) =>
                filteredPhasesSumMeasurements.includes(
                  convertNumberToMeasurement(kind)(
                    'vectors',
                    phaseMeasurementGroupType[0],
                  ) as VectorParameterTypeInternal,
                ),
              )
              .map((phaseMeasurementGroupType) => {
                const type = convertNumberToMeasurement(kind)(
                  'vectors',
                  phaseMeasurementGroupType[0],
                ) as VectorParameterTypeInternal;
                const value = averageSummaryMeasurements.includes(type)
                  ? meanBy(phaseMeasurementGroupType[1], 'value')
                  : sumBy(phaseMeasurementGroupType[1], 'value');

                if (measurementParameters[type])
                  return (
                    <MeasurementItem
                      key={type}
                      channelId={channel.id}
                      measurement={type}
                      measurementGroup={MeasurementGroup.SinglePhase}
                      text={t(`energyMeasurements.types.summary.${type}`)}
                      value={value}
                      parameters={measurementParameters[type]}
                      kind={kind}
                      tariffsQuantity={tariffsQuantity}
                    />
                  );
              })}
            <>
              {(!isStandalone || (isStandalone && advancedParameters)) &&
                data?.meterCommonMeasurements.data?.scalarMeasurements
                  ?.filter((absoluteMeasurement) =>
                    filteredSummaryMeasurements.includes(
                      convertNumberToMeasurement(kind)(
                        'scalars',
                        absoluteMeasurement.type,
                      ) as ScalarParameterTypeInternal,
                    ),
                  )
                  .map((absoluteMeasurement) => {
                    const type = convertNumberToMeasurement(kind)(
                      'scalars',
                      absoluteMeasurement.type,
                    ) as ScalarParameterTypeInternal;
                    if (measurementParameters[type]) {
                      return (
                        <MeasurementItem
                          key={type}
                          channelId={channel.id}
                          measurement={type}
                          measurementGroup={MeasurementGroup.Absolute}
                          text={t(`energyMeasurements.types.summary.${type}`)}
                          value={absoluteMeasurement.value}
                          parameters={measurementParameters[type]}
                          kind={kind}
                        />
                      );
                    }
                  })}
            </>
          </>
        ) : (
          phaseMeasurementsGroupedByPhase[activeTabIndex]?.map((phaseMeasurement) => {
            if (measurementParameters[convertNumberToMeasurement(kind)('vectors', phaseMeasurement.type)]) {
              return (
                <MeasurementItem
                  key={phaseMeasurement.type}
                  channelId={channel.id}
                  measurement={
                    convertNumberToMeasurement(kind)('vectors', phaseMeasurement.type) as VectorParameterTypeInternal
                  }
                  measurementGroup={MeasurementGroup.SinglePhase}
                  text={t(
                    `energyMeasurements.types.phase.${convertNumberToMeasurement(kind)(
                      'vectors',
                      phaseMeasurement.type,
                    )}`,
                  )}
                  value={phaseMeasurement.value}
                  parameters={measurementParameters[convertNumberToMeasurement(kind)('vectors', phaseMeasurement.type)]}
                  phase={activeTabIndex}
                  kind={kind}
                  tariffsQuantity={tariffsQuantity}
                />
              );
            }
          })
        )}
      </div>
    </div>
  );
};
