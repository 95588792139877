import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, SubmitButton } from '../../../../components';
import { patterns } from '../../../../utils/validation';
import { useTriggerFormContext } from '../../context';
import { useTrigger } from '../../hooks';
import { StepComponentProps } from '../../steps';

export const TriggerNameStep: React.FC<StepComponentProps> = ({ isEdit, goToSummary }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { timeCondition, name, setName } = useTriggerFormContext();
  const { handleSaveTrigger, isLoading } = useTrigger();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange', defaultValues: { name } });

  const onSubmit = useCallback(
    (formData) => {
      setName(formData.name);

      if (isEdit && goToSummary) {
        goToSummary();
        return;
      }

      handleSaveTrigger(formData.name);
    },
    [timeCondition, name],
  );

  return (
    <div className="m-t-8">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: tc('isRequired') as string,
            maxLength: {
              value: 40,
              message: tc('tooLongName'),
            },
            pattern: {
              value: patterns.name,
              message: tc('invalidName'),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Input
              defaultValue={name}
              label={t('trigger.name')}
              placeholder={t('trigger.name')}
              {...(error ? { errorMessage: error.message } : {})}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        <SubmitButton isLoading={isLoading} disabled={!isValid}>
          {isEdit ? tc('buttons.next') : tc('buttons.save')}
        </SubmitButton>
      </form>
    </div>
  );
};
