import React from 'react';

export const IconTravel: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="25"
      height="33"
      viewBox="0 0 25 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3354 9.24444V24.1794C23.3354 26.3895 21.5437 28.1812 19.3336 28.1812H5.27227C3.06216 28.1812 1.27051 26.3895 1.27051 24.1794V9.24443C1.27051 7.03432 3.06216 5.24268 5.27227 5.24268H9.03004H19.3336C21.5437 5.24268 23.3354 7.03433 23.3354 9.24444Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M6.99902 0.25C6.58481 0.25 6.24902 0.585786 6.24902 1C6.24902 1.41421 6.58481 1.75 6.99902 1.75V0.25ZM17.6032 1.75C18.0174 1.75 18.3532 1.41421 18.3532 1C18.3532 0.585786 18.0174 0.25 17.6032 0.25V1.75ZM14.4967 5.07308C14.4967 5.4873 14.8325 5.82308 15.2467 5.82308C15.6609 5.82308 15.9967 5.4873 15.9967 5.07308H14.4967ZM8.60551 5.07308C8.60551 5.4873 8.9413 5.82308 9.35551 5.82308C9.76972 5.82308 10.1055 5.4873 10.1055 5.07308H8.60551ZM15.2467 1.75H17.6032V0.25H15.2467V1.75ZM14.4967 1V5.07308H15.9967V1H14.4967ZM6.99902 1.75H9.35551V0.25H6.99902V1.75ZM9.35551 1.75H15.2467V0.25H9.35551V1.75ZM8.60551 1V5.07308H10.1055V1H8.60551Z"
        fill="#15141D"
        className="fill"
      />
      <rect
        x="6.94546"
        y="10.2814"
        width="9.99621"
        height="1.42803"
        rx="0.714015"
        stroke="#15141D"
        strokeWidth="1.42803"
      />
      <path
        d="M10.5353 28.9253C10.5353 29.4051 10.4351 29.8798 10.2411 30.3197C10.047 30.7597 9.76326 31.1553 9.40746 31.4821C9.05167 31.8088 8.6315 32.0596 8.17318 32.2188C7.71487 32.378 7.22825 32.4422 6.7437 32.4073C6.25916 32.3724 5.78709 32.2393 5.35698 32.0161C4.92687 31.793 4.54794 31.4847 4.24385 31.1105C3.93976 30.7363 3.71704 30.3042 3.5896 29.8411C3.46216 29.3781 3.43273 28.894 3.50315 28.4193L5.04951 28.643C5.01023 28.9079 5.02665 29.1779 5.09774 29.4362C5.16883 29.6945 5.29308 29.9356 5.46271 30.1443C5.63235 30.3531 5.84374 30.5251 6.08368 30.6495C6.32362 30.774 6.58696 30.8483 6.85727 30.8677C7.12757 30.8872 7.39903 30.8514 7.65471 30.7626C7.91038 30.6738 8.14477 30.5339 8.34326 30.3516C8.54174 30.1693 8.70004 29.9486 8.80829 29.7032C8.91655 29.4578 8.97241 29.193 8.97241 28.9253H10.5353Z"
        fill="#15141D"
        className="fill"
      />
      <path
        d="M21.0789 28.28C21.1698 28.7577 21.1588 29.249 21.0464 29.7222C20.9341 30.1955 20.7229 30.6404 20.4264 31.0284C20.13 31.4164 19.7547 31.739 19.3247 31.9755C18.8947 32.212 18.4195 32.3572 17.9294 32.4018C17.4393 32.4464 16.9452 32.3894 16.4787 32.2345C16.0121 32.0796 15.5835 31.8302 15.2203 31.5022C14.857 31.1743 14.5671 30.775 14.369 30.33C14.171 29.8849 14.0693 29.404 14.0703 28.9179L15.6339 28.9212C15.6333 29.1922 15.6901 29.4604 15.8005 29.7086C15.9109 29.9568 16.0726 30.1794 16.2752 30.3623C16.4777 30.5452 16.7168 30.6843 16.9769 30.7707C17.2371 30.8571 17.5126 30.8888 17.7859 30.864C18.0592 30.8391 18.3242 30.7581 18.564 30.6262C18.8038 30.4944 19.0131 30.3145 19.1784 30.0981C19.3437 29.8817 19.4615 29.6336 19.5241 29.3697C19.5868 29.1058 19.593 28.8319 19.5422 28.5655L21.0789 28.28Z"
        fill="#15141D"
        className="fill"
      />
    </svg>
  );
};
