import React from 'react';

export const IconCarSmall: React.FC = () => (
  <svg
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-car-small"
  >
    <g clipPath="url(#clip0_24319_28846)">
      <path d="M24.5918 24.6939H14.2539" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M33.985 25.6679C34.5103 23.0999 32.8543 20.5923 30.2863 20.0671C27.7183 19.5418 25.2107 21.1978 24.6855 23.7658C24.1602 26.3338 25.8161 28.8413 28.3841 29.3666C30.9521 29.8919 33.4597 28.2359 33.985 25.6679Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.2051 25.7176C14.7577 23.1553 13.1285 20.6303 10.5662 20.0777C8.00396 19.5251 5.47889 21.1543 4.92631 23.7165C4.37373 26.2788 6.0029 28.8039 8.56516 29.3565C11.1274 29.909 13.6525 28.2799 14.2051 25.7176Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.2598 15.9557C10.674 15.9557 11.0098 15.6199 11.0098 15.2057C11.0098 14.7915 10.674 14.4557 10.2598 14.4557L10.2598 15.9557ZM10.2598 14.4557L4.32404 14.4557L4.32404 15.9557L10.2598 15.9557L10.2598 14.4557ZM0.750053 18.0297L0.750008 23.6368L2.25001 23.6369L2.25005 18.0297L0.750053 18.0297ZM2.559 25.4459H4.75997V23.9459H2.559V25.4459ZM0.750008 23.6368C0.75 24.6359 1.55992 25.4459 2.559 25.4459V23.9459C2.38835 23.9459 2.25001 23.8075 2.25001 23.6369L0.750008 23.6368ZM4.32404 14.4557C2.35019 14.4557 0.750069 16.0558 0.750053 18.0297L2.25005 18.0297C2.25006 16.8843 3.17861 15.9557 4.32404 15.9557L4.32404 14.4557Z"
        fill="#15141D"
      />
      <path
        d="M28.8484 15.2057L26.6936 11.0379C25.4207 8.5757 22.8804 7.02937 20.1087 7.02936L10.9252 7.02936C10.1243 7.02936 9.3919 7.48112 9.03248 8.19684L5.51172 15.2077"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M34.0822 24.6939H36.773C37.1749 24.6939 37.5008 24.3681 37.5008 23.9661V23.9661C37.5008 19.143 33.5909 15.2331 28.7678 15.2331H16.8093C14.9022 15.2331 13.3574 13.6883 13.3574 11.7813V7"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_24319_28846">
        <rect width="38" height="38" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
