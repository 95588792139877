import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { IconToastError, IconToastSuccess, NavHeader, Page } from '../../../components';
import FixedTwoButtons from '../../../components/fixed-two-buttons';
import { usePreventBack } from '../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../routes';
import { useBluetoothContext } from '../context';
import './index.scss';

interface ComponentProps {
  redoRegister: () => void;
  setCheckListOpen: Dispatch<SetStateAction<boolean>>;
}

const DeviceAddBluetoothCheckList: React.FC<ComponentProps> = ({ redoRegister, setCheckListOpen }) => {
  const history = useHistory();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { checkList, registerErrorMessage, redoRegisteringDisabled, setRedoRegisteringDisabled, errorCode } =
    useBluetoothContext();
  usePreventBack(redoRegisteringDisabled ? t('bluetooth.noBackMessageAdd') : '');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRedoRegisteringDisabled(false);
    }, 1000 * 60);

    return () => clearTimeout(timeout);
  }, []);

  const handleBack = () => setCheckListOpen(false);

  const checkListItems = useMemo(
    () =>
      checkList.map((x, i) => (
        <li key={x}>
          <div className="status">
            {i + 1 !== checkList.length ? (
              <IconToastSuccess />
            ) : !registerErrorMessage ? (
              <CircularProgress color="inherit" size={22} />
            ) : (
              <IconToastError />
            )}
          </div>
          <span>{x}</span>
        </li>
      )),
    [checkList, registerErrorMessage],
  );

  const handlePrimaryButton = () => {
    if (!registerErrorMessage) redoRegister();
    else history.push(ROUTES.DeviceAddBluetoothUpdate());
  };

  return (
    <Page kind="above" header={<NavHeader title={t('bluetooth.deviceRegistering')} hasLineBelow noBackButton />}>
      <ul className="check-list">
        {checkListItems}
        {registerErrorMessage && (
          <li key="error-message" className="error-message">
            <div className="status" />
            <span>{registerErrorMessage}</span>
          </li>
        )}
        {registerErrorMessage && (
          <button className="button max-width-desktop" onClick={redoRegister}>
            {tc('buttons.tryAgain')}
          </button>
        )}
      </ul>
      <FixedTwoButtons
        titlePrimary={!registerErrorMessage ? tc('buttons.tryAgain') : t('addDeviceUpdate.update')}
        titleSecondary={tc('buttons.return')}
        {...(errorCode !== 'WIFI' && errorCode !== 'INTERNET' ? { handleClickPrimary: handlePrimaryButton } : {})}
        handleClickSecondary={handleBack}
        disabledPrimary={redoRegisteringDisabled}
        disabledSecondary={redoRegisteringDisabled}
      />
    </Page>
  );
};

export default DeviceAddBluetoothCheckList;
