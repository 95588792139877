import React, { ReactNode } from 'react';
import './index.scss';

interface ComponentProps {
  label: string;
  value?: string;
  children?: ReactNode;
}

const SummaryItem: React.FC<ComponentProps> = ({ label, value, children }) => (
  <div className="summary-item">
    <div className="text label">{label}</div>
    <div className="text value">{value || children}</div>
  </div>
);

export default SummaryItem;
