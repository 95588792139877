import { gql } from '@apollo/client';

export const CREATE_GROUP = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      idResponse {
        id
      }
      errors {
        ... on GroupError {
          error
          message
        }
      }
    }
  }
`;

export const ADD_TO_FAVOURITES = gql`
  mutation AddToFavourites($input: FavouritesItemRequestInput!) {
    addToFavourites(input: $input) {
      id
    }
  }
`;

export const REMOVE_FROM_FAVOURITES = gql`
  mutation RemoveFromFavourites($input: FavouritesItemRequestInput!) {
    removeFromFavourites(input: $input) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      result {
        ok
      }
      errors {
        ... on GroupError {
          error
          message
        }
      }
    }
  }
`;

export const REMOVE_GROUP = gql`
  mutation RemoveGroup($input: RemoveGroupInput!) {
    removeGroup(input: $input) {
      result {
        ok
      }
      errors {
        ... on GroupError {
          error
          message
        }
      }
    }
  }
`;

export const CHANGE_GROUPS_ORDER = gql`
  mutation ChangeGroupsOrder($input: ChangeGroupOrderRequestInput!) {
    changeGroupsOrder(input: $input) {
      id
    }
  }
`;

export const CHANGE_GROUP_ITEMS_ORDER = gql`
  mutation ChangeGroupItemsOrder($input: ChangeGroupItemsOrderRequestInput!) {
    changeGroupItemsOrder(input: $input) {
      id
    }
  }
`;

export const MODIFY_GROUP_ITEM_IN_MULTIPLE_GROUPS = gql`
  mutation ModifyGroupItemInMultipleGroups($input: ModifyGroupItemInMultipleGroupsInput!) {
    modifyGroupItemInMultipleGroups(input: $input) {
      result {
        ok
      }
      errors {
        ... on GroupError {
          error
          message
        }
      }
    }
  }
`;
