import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader } from '../../../../../../components';

export const GroupSortHeader: React.FC = () => {
  const { t } = useTranslation('groups');

  return (
    <>
      <NavHeader isIconClose />
      <Header title={t('sort')} />
    </>
  );
};
