import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import { availableGroupIcons } from '../../../../../components/action/edit-icon/available-icons';
import { EditIcon } from '../../../../../components/edit-icon';
import GroupName from '../components/group-name';
import GroupedChannels from '../components/grouped-channels';
import { useGroups } from '../hooks/use-groups';
import { GroupedDeviceChannelsCheckbox } from '../types';

const Create: React.FC = () => {
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { addNewGroup, error } = useGroups();
  const [items, setItems] = useState<GroupedDeviceChannelsCheckbox[]>([]);
  const form = useForm({
    defaultValues: {
      name: '',
      iconName: '',
    },
  });
  const { iconName } = form.watch();

  const onSubmit = form.handleSubmit(({ name, iconName }) => {
    addNewGroup({ name, iconName, groupedChannels: items });
  });

  const handleChangeIcon = (value: string) => form.setValue('iconName', value);

  return (
    <>
      <Page
        isStickyHeader
        header={
          <>
            <NavHeader />
            <Header title={t('create')} />
          </>
        }
      >
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <GroupName errorMessage={error} />
            <EditIcon currentIcon={iconName} iconList={availableGroupIcons} handleChangeIcon={handleChangeIcon} />
            <hr className="m-t-24 m-b-28" />
            <GroupedChannels items={items} setItems={setItems} />
            <SubmitButton>{tc('buttons.create')}</SubmitButton>
          </form>
        </FormProvider>
      </Page>
    </>
  );
};

export default Create;
