import React from 'react';

export const IconWashingMachine: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-washing-machine"
  >
    <path
      d="M29.334 2H9C7.89543 2 7 2.89543 7 4V33.4702C7 34.5748 7.89543 35.4702 9 35.4702H29.334C30.4386 35.4702 31.334 34.5748 31.334 33.4702V4C31.334 2.89543 30.4386 2 29.334 2Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.18359 8.43559H31.0323"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9097 26.6149C28.0823 23.4422 28.0823 18.2983 24.9097 15.1256C21.737 11.9529 16.5931 11.9529 13.4204 15.1256C10.2477 18.2983 10.2477 23.4422 13.4204 26.6149C16.5931 29.7875 21.737 29.7875 24.9097 26.6149Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6034 25.6676C24.6142 25.0374 23.8984 23.1943 23.8984 21.0193C23.8984 18.637 24.7569 16.6535 25.8924 16.2245"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8191 6.6031C22.538 6.6031 23.1207 6.02038 23.1207 5.30155C23.1207 4.58272 22.538 4 21.8191 4C21.1003 4 20.5176 4.58272 20.5176 5.30155C20.5176 6.02038 21.1003 6.6031 21.8191 6.6031Z"
      stroke="#15141D"
      strokeWidth="1.33403"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.0594 6.6031C27.7782 6.6031 28.3609 6.02038 28.3609 5.30155C28.3609 4.58272 27.7782 4 27.0594 4C26.3405 4 25.7578 4.58272 25.7578 5.30155C25.7578 6.02038 26.3405 6.6031 27.0594 6.6031Z"
      stroke="#15141D"
      strokeWidth="1.33403"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.0586 2.6732V8.43565" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
