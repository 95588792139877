import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SequenceRequestData } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { useBackdropContext } from '../../../../../hooks';
import { ROUTES } from '../../../../../routes';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { ActionType } from '../../../../action-create/types';
import { useExalusServicesContext } from '../../../context/services';
import { useHandleDataFrameErrors } from '../../../hooks';
import { useExalusCreateActionContext } from '../context';
import { CreateTaskValidateMethod } from '../types';

export const useActionTasks = () => {
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { builder, actionType, setTaskType } = useExalusCreateActionContext();
  const [validateDialog, setValidateDialog] = useState<boolean>(false);
  const [validateErrors, setValidateErrors] = useState<string[]>([]);
  const { handleError } = useHandleDataFrameErrors();
  const { scenesApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  useEffect(() => {
    if (actionType === ActionType.Undefined) history.replace(ROUTES.ActionList());
  }, []);

  const addNewTask = () => history.push(ROUTES.ActionTasksTypes());

  const selectTaskType = (taskType: SceneTaskType) => {
    setTaskType(taskType);

    switch (taskType) {
      case SceneTaskType.DeviceTask:
        history.push(ROUTES.ActionTasksVariants());
        break;
      case SceneTaskType.DelayTask:
        history.push(ROUTES.ExalusActionTaskDelay());
        break;
      case SceneTaskType.LockExecution:
        history.push(ROUTES.ExalusActionTaskLock());
        break;
      default:
        break;
    }
  };

  const finalizeSubmit = async (sequence: SequenceRequestData) => {
    let result: Status;

    if (!actionId) {
      result = await scenesApi.CreateSequenceAsync(sequence);
    } else {
      result = await scenesApi.EditSequenceAsync(sequence);
    }

    turnOffBackdrop();

    if (result === Status.OK) {
      setValidateDialog(false);

      toastSuccess({
        content: t(
          `action.${!actionId ? 'create.create' : 'edit.edit'}Success${
            actionType === ActionType.Action ? 'Action' : 'Automation'
          }`,
        ),
      });
      history.replace(`${ROUTES.ActionList()}?tab=${actionType === ActionType.Action ? 0 : 1}`);
    } else {
      handleError(result);
    }
  };

  const handleSubmit = async (validateMethod?: CreateTaskValidateMethod) => {
    turnOnBackdrop();
    const commitBuilder = builder?.Commit();

    if (commitBuilder) {
      const sceneValidation = commitBuilder.ValidateScene();

      switch (validateMethod) {
        case CreateTaskValidateMethod.Ignore: {
          const sequence = sceneValidation.Ignore().Build();
          finalizeSubmit(sequence);
          break;
        }
        case CreateTaskValidateMethod.TryFix: {
          const sequence = sceneValidation.TryFix().Build();
          finalizeSubmit(sequence);
          break;
        }
        default: {
          setValidateErrors([]);
          turnOffBackdrop();

          if (sceneValidation.IsValid) {
            const sequence = commitBuilder.Build();
            finalizeSubmit(sequence);
          } else {
            if (sceneValidation.Errors.length) {
              setValidateErrors(sceneValidation.Errors.map((e) => t(`action.create.conditions.validate.${e}`)));
              setValidateDialog(true);
            }
          }
          break;
        }
      }
    } else {
      turnOffBackdrop();
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  return {
    handleSubmit,
    addNewTask,
    selectTaskType,
    validateDialog,
    setValidateDialog,
    validateErrors,
  };
};
