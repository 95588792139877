import { IconFacadeBlind } from '../../../../../../../components/icons';
import { AvailableIcon } from '../../../../../../../types';

interface Params {
  position?: number;
  tilt?: number;
  fullRange?: boolean;
}

export const facadeIcons = (params?: Params): AvailableIcon[] => [
  {
    iconName: '',
    component: (
      <IconFacadeBlind
        position={params?.position !== undefined ? params.position : 100}
        tilt={params?.tilt !== undefined ? params.tilt : 50}
        fullRange={params?.fullRange !== undefined ? params.fullRange : false}
      />
    ),
    oldIconName: 'DEVICE_ICON_40',
  },
];
