import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconCircleMeter: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-circle-meter"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="16.625"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="18"
        cy="23.9375"
        rx="2.375"
        ry="2.375"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {isOn ? (
        <path
          d="M19.6243 22.0516L23.9375 17.2931"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M15.625 22.75L10.2812 19.1875"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <path
        d="M6.125 18.0001C6.125 17.3947 6.1703 16.7999 6.25769 16.2189M29.875 18.0001C29.875 12.1937 25.7077 7.36063 20.2009 6.32862M17.4063 6.13965C16.7994 6.16953 16.2045 6.24497 15.625 6.36261C14.799 6.53028 14.0041 6.7837 13.25 7.11317C12.7263 7.34198 12.2223 7.60748 11.7412 7.90641M9.30733 9.90969C8.85685 10.3935 8.44627 10.9149 8.08084 11.4688C7.76532 11.947 7.48346 12.4494 7.23863 12.9726"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
