import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useBackdropContext } from '../../../hooks';
import { useErrors } from '../../../hooks/use-errors';
import { useLogoutLavva } from '../../../modules/settings/profile/hooks/use-logout-lavva';
import { toastSuccess } from '../../../utils/toast';
import { useRestClient } from '../../clients/rest-client';

export const useDeleteUser = () => {
  const { t } = useTranslation('profile');
  const restClient = useRestClient();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleLogoutLavva } = useLogoutLavva();
  const { handleRestErrors } = useErrors();

  return useMutation(
    ['user-delete'],
    async () => {
      turnOnBackdrop();
      return await restClient.delete('/users/delete');
    },
    {
      onSuccess: () => {
        turnOffBackdrop();
        toastSuccess({ content: t('deleteProfile.deleteSuccessfull') });
        handleLogoutLavva();
      },
      onError: (error: AxiosError) => {
        turnOffBackdrop();
        handleRestErrors(error);
      },
    },
  );
};
