import * as Sentry from '@sentry/react';
import { environment } from './environment';
import { EnvironmentName } from './types';
import { isDev, isLocalhost, isProd, isStag } from './utils/helpers/environment';
import { isLocalApp } from './utils/helpers/local-app';

const getEnvironment = () => {
  if (isDev) return EnvironmentName.Dev;
  if (isStag) return EnvironmentName.Stage;
  if (isProd) return EnvironmentName.Prod;

  return EnvironmentName.Localhost;
};

export const initSentry = () => {
  if (isLocalApp || isLocalhost) return;

  Sentry.init({
    dsn: environment.SENTRY_DSN,
    tracesSampleRate: 1.0,
    integrations: [],
    environment: getEnvironment(),
    release: environment.BUILD_ID,
    denyUrls: [/node_modules/],
    beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
      console.log('SENTRY EVENT: ', { event, hint });
      return event;
    },
  });
};
