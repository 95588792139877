import React, { useMemo } from 'react';
import LavvaDetailsWrapper from '../../../../../components/details-wrapper';
import { ChannelType } from '../../../enums';
import { CentralActionType } from '../../../pages/dashboard/types';
import { CentralActionBlindDetailsControlBox } from './blind/control-box';
import { CentralActionFacadeDetailsControlBox } from './facade/control-box';
import { CentralActionLightDetailsControlBox } from './light/control-box';
import { CentralActionSwitchDetailsControlBox } from './switch/control-box';

interface PropsInterface {
  centralAction: CentralActionType;
}

export const CentralActionDetailsControl: React.FC<PropsInterface> = ({ centralAction }) => {
  const actionControl = useMemo(() => {
    switch (centralAction.type) {
      case ChannelType.Blind:
        return <CentralActionBlindDetailsControlBox channels={centralAction.items} />;
      case ChannelType.Facade:
        return <CentralActionFacadeDetailsControlBox channels={centralAction.items} />;
      case ChannelType.Switch:
        return <CentralActionSwitchDetailsControlBox channels={centralAction.items} />;
      case ChannelType.Light:
        return <CentralActionLightDetailsControlBox channels={centralAction.items} />;
      default:
        return null;
    }
  }, [centralAction]);

  return (
    <div className="action-control">
      <LavvaDetailsWrapper>{actionControl}</LavvaDetailsWrapper>
    </div>
  );
};
