import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  className?: string;
}

export const IconWeather: React.FC<PropsInterface> = ({ className }) => {
  return (
    <div
      className={classNames('icon-weather', {
        [className as string]: className,
      })}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.6251 9C12.6715 9 10.9896 10.1413 10.1993 11.7894C9.85751 11.6178 9.47278 11.519 9.06417 11.519C7.81031 11.519 6.77165 12.4294 6.56874 13.6232C5.07544 14.1343 4 15.5441 4 17.2082C4 19.3013 5.7002 20.9982 7.79725 21H19.5343C21.4501 20.9901 23 19.4378 23 17.5243C23 15.6077 21.4464 14.054 19.5269 14.0485C19.5281 13.9979 19.5343 13.9492 19.5343 13.8985C19.5343 11.1931 17.3364 9 14.6251 9Z"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.54916 12.077C6.03552 11.4114 5.72998 10.5769 5.72998 9.67112C5.72998 7.49422 7.49471 5.72949 9.6716 5.72949C11.2509 5.72949 12.6133 6.65833 13.2423 7.99954C13.2423 7.99954 13.5 8.49954 13.5 8.99954"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.67139 3.36523L9.67139 1.00026"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.67139 3.36523L9.67139 1.00026"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 9.67188L3.36497 9.67188"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.53955 3.54004L5.21184 5.21233"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8032 3.54004L14.1309 5.21233"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
