import { useMutation } from '@apollo/client';
import {
  ModifyShareInfoChannelInput,
  ModifyShareInfoItemBaseInput,
  ModifyUserAccessesMutation,
  ModifyUserAccessesMutationVariables,
} from '../../../data-access/gql-types/graphql';
import { MODIFY_USER_ACCESSES } from '../../../data-access/mutations/share';
import { useBackdropContext } from '../../../hooks';
import { useInvitationErrors } from '../../../hooks/backend-errors/use-invitation-errors';
import { useShareInstallationContext } from '../context';

interface Hook {
  modyfyGroupAccess: (userId: string, groups: ModifyShareInfoChannelInput[], onSuccess: () => void) => void;
  modyfyChannelAccess: (userId: string, channels: ModifyShareInfoChannelInput[], onSuccess: () => void) => void;
  modifyUserAccessesLoading: boolean;
}

export const useModify = (): Hook => {
  const { state } = useShareInstallationContext();
  const { handleErrors } = useInvitationErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [modifyUserAccesses, { loading: modifyUserAccessesLoading }] = useMutation<
    ModifyUserAccessesMutation,
    ModifyUserAccessesMutationVariables
  >(MODIFY_USER_ACCESSES);

  const modyfyGroupAccess = (userId: string, groups: ModifyShareInfoItemBaseInput[], onSuccess: () => void) => {
    turnOnBackdrop();

    modifyUserAccesses({
      variables: {
        input: {
          installationId: state.installationId,
          sharedToUserId: userId,
          groups,
          channels: [],
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data.modifyUserAccesses?.result?.ok) {
          onSuccess();
        } else {
          handleErrors(data.modifyUserAccesses?.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  const modyfyChannelAccess = (userId: string, channels: ModifyShareInfoChannelInput[], onSuccess: () => void) => {
    turnOnBackdrop();

    modifyUserAccesses({
      variables: {
        input: {
          installationId: state.installationId,
          sharedToUserId: userId,
          channels,
          groups: [],
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data.modifyUserAccesses?.result?.ok) {
          onSuccess();
        } else {
          handleErrors(data.modifyUserAccesses?.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  return {
    modyfyGroupAccess,
    modyfyChannelAccess,
    modifyUserAccessesLoading,
  };
};
