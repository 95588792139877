import { gql } from '@apollo/client';

export const ON_DEVICE_NAME_CHANGED = gql`
  subscription OnDeviceNameChanged($installationId: UUID!) {
    onDeviceNameChanged (installationId: $installationId) {
      deviceId
      name
    }
  }
`;
