import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProductType,
  SupportedDevice,
} from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { uniq } from 'lodash';
import { TabInterface } from '../../../../../components';
import { useExalusSupportedDevices } from '../../../hooks/supported-devices.hooks';

export const useStore = () => {
  const { t } = useTranslation('store');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data } = useExalusSupportedDevices();

  const allTypes = useMemo(() => {
    return uniq(
      (data || []).reduce((acc: number[], obj: SupportedDevice) => {
        return acc.concat(obj.ProductTypes);
      }, []),
    ).sort((a, b) => a - b);
  }, [data]);

  const tabs: TabInterface[] = useMemo(
    () => allTypes.map((x) => ({ label: t(`types.${ProductType[x]}`), data: x })),
    [allTypes, t],
  );

  const selectedTabType = useMemo(
    () => (tabs.length ? (tabs[activeTabIndex].data as ProductType) : null),
    [tabs, activeTabIndex],
  );

  const channelsPerSelectedType = useMemo(() => {
    return selectedTabType ? (data || []).filter((x) => x.ProductTypes.includes(selectedTabType)) : [];
  }, [data, selectedTabType]);

  const channelsSeeMore = useMemo(() => {
    return selectedTabType ? (data || []).filter((x) => !x.ProductTypes.includes(selectedTabType)) : [];
  }, [data, selectedTabType]);

  return {
    data,
    activeTabIndex,
    tabs,
    channelsPerSelectedType,
    channelsSeeMore,
    setActiveTabIndex,
  };
};
