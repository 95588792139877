import React from 'react';

export const IconSink: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-sink"
  >
    <path
      d="M24.5054 32.57H13.2279C7.57928 32.57 3 27.9907 3 22.3421C3 20.1554 4.7719 18.3835 6.95855 18.3835H30.7719C32.9586 18.3835 34.7305 20.1554 34.7305 22.3421C34.7305 27.9907 30.1512 32.57 24.5026 32.57H24.5054Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6621 18.3835V9.05003C18.6621 7.3656 20.0277 6 21.7121 6C23.3966 6 24.7622 7.3656 24.7622 9.05003V9.41118"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.9672 14.5237H24.5273"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6934 18.3835V14.5237"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.6934 18.3835V14.5237"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
