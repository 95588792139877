import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface ComponentProps {
  fillYellow?: boolean;
  fillRed?: boolean;
  yellow?: boolean;
}

export const IconWarning: React.FC<ComponentProps> = ({ fillYellow, fillRed, yellow }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('icon-warning', { 'fill-yellow': fillYellow, 'fill-red': fillRed, yellow: yellow })}
    >
      <circle cx="12" cy="12" r="10.25" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12 13.5V6" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="12" cy="17" r="0.5" stroke="#15141D" strokeLinecap="round" />
    </svg>
  );
};
