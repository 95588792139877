import React from 'react';

export const IconTableSetting: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-table-setting"
  >
    <path
      d="M8.00006 8.00316V12.6773C8.00006 13.9593 6.43502 15.0001 4.50725 15.0001C2.57948 15.0001 1 13.9561 1 12.6678V8"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.49316 8.48047V29.4843"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0002 29.4843V8.48047C36.0002 8.48047 32.5005 10.7856 32.5005 15.0006C32.5005 19.2157 36.0002 18.9838 36.0002 18.9838"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0043 18.9822C29.0043 13.5665 24.6097 9.17188 19.194 9.17188C13.7784 9.17188 9.39795 13.5665 9.39795 18.9822C9.39795 24.3978 13.7755 28.7925 19.194 28.7925C24.6125 28.7925 29.0043 24.3978 29.0043 18.9822Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
