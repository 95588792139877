import React from 'react';
import IconButton from '@mui/material/IconButton';
import { IconHeart } from '../../../components';
import { ChannelInterface } from '../../../types';
import { useFavourite } from '../hooks/use-favourite';

interface PropsInterface {
  channel: ChannelInterface;
}

const Favourite: React.FC<PropsInterface> = ({ channel }) => {
  const { isChannelInGroup, favouriteGroup, toggleFavouriteGroup } = useFavourite(channel);

  if (!channel.id || !favouriteGroup) return null;

  return (
    <IconButton aria-label="favourite" size="large" onClick={toggleFavouriteGroup}>
      <IconHeart isEmpty={!isChannelInGroup} />
    </IconButton>
  );
};

export default Favourite;
