import { gql } from '@apollo/client';

export const ON_MEASUREMENT = gql`
  subscription OnMeasurement($input: SubscribeForMeasurementInput!) {
    onMeasurement(input: $input) {
      data {
        ... on MeasurementSubscriptionError {
          unixTimestamp
          errorCode
          description
        }
        ... on MeasurementSubscriptionResponse {
          unixTimestamp
          scalarMeasurements {
            type
            value
          }
          vectorMeasurements {
            type
            index
            value
          }
        }
      }
    }
  }
`;
