import React from 'react';
import './index.scss';

export const IconCircleStop: React.FC = () => (
  <svg
    className="icon-circle-stop"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15.25" stroke="#15141D" strokeWidth="1.5" />
    <rect x="10.75" y="10.75" width="10.5" height="10.5" rx="2.25" stroke="#15141D" strokeWidth="1.5" />
  </svg>
);
