import React from 'react';

export const IconLavva: React.FC = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1667 19H14.9725L8.101 0.770245H11.9258L19.1667 19ZM8.58856 9.21607L4.68457 19H0.833355L6.70251 4.25216L8.58856 9.21607Z"
      fill="#FF4016"
    />
  </svg>
);
