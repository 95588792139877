import { useMemo } from 'react';
import { VersionedFeatureType } from '../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../../hooks';
import useVersionMap from '../../../../../hooks/api/version-map';
import { ChannelInterface } from '../../../../../types';

export const useOptimizerSupport = (channel: ChannelInterface) => {
  const { checkSupport, versionMapData } = useVersionMap();
  const { device } = useDevicesAndChannels({ deviceId: channel.deviceId });

  const tariffSupported = useMemo(
    () =>
      checkSupport({
        feature: VersionedFeatureType.OptimizerTariffs,
        supportKey: 'Optimizer',
        currentVersion: device?.payload.currentFirmwareVersion,
      }),
    [device?.payload.currentFirmwareVersion, versionMapData],
  );

  return {
    tariffSupported,
  };
};
