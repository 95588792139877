import React from 'react';

export const IconCoffee: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3582 19.554C25.629 18.217 25.7403 16.9693 25.7403 15.9507H0.75C1.1665 21.2556 4.37359 31.6852 13.8699 30.9646C17.3532 30.7002 19.8783 29.2427 21.6794 27.2707M25.3582 19.554C26.9433 18.7533 30.1917 17.4521 30.7384 19.554C31.0507 20.7551 31.3631 25.6787 21.6794 27.2707M25.3582 19.554C24.837 22.1268 23.7252 25.0307 21.6794 27.2707"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2588 11.0329C14.9766 10.1411 15.1459 7.91158 18.0801 6.12794C21.0142 4.34431 20.2431 1.96613 19.4908 1"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5703 11.0329C8.28817 10.1411 8.45745 7.91158 11.3916 6.12794C14.3257 4.34431 13.5546 1.96613 12.8023 1"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
