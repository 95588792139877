import React from 'react';
import classNames from 'classnames';
import { ChannelTypeInternal } from '../../data-access/gql-types/graphql';
import { IconChevron, IconCirclePadlockClose, IconCirclePadlockOpen, IconCircleStop, IconPause } from '../icons';
import './index.scss';

interface PropsInterface {
  handleAction: (value: string) => void;
  type: ChannelTypeInternal.Blind | ChannelTypeInternal.Gate;
  isListItem?: boolean;
  action?: boolean;
  stopIcon?: boolean;
}

export const RollerControls: React.FC<PropsInterface> = ({ handleAction, isListItem, action, type }) => {
  const isGate = type === ChannelTypeInternal.Gate;

  return (
    <div
      className={classNames('cover-control-box', {
        'cover-control-box--list-view': isListItem,
        'cover-control-box--control-view': !action,
      })}
    >
      <div className="cover-control-box__controls">
        <div
          className="cover-control-box__control cover-control-box__control--left"
          onClick={() => handleAction(!isGate ? 'close' : 'open')}
        >
          {!isGate ? <IconChevron withCircle direction="down" /> : <IconCirclePadlockOpen />}
        </div>

        <div
          className="cover-control-box__control cover-control-box__control--stop"
          onClick={() => handleAction('stop')}
        >
          {!isGate ? <IconPause /> : <IconCircleStop />}
        </div>

        <div
          className="cover-control-box__control cover-control-box__control--right"
          onClick={() => handleAction(!isGate ? 'open' : 'close')}
        >
          {!isGate ? <IconChevron withCircle direction="up" /> : <IconCirclePadlockClose />}
        </div>
      </div>
    </div>
  );
};
