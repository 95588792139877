import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../components';
import { ROUTES } from '../../../../routes';
import { useExalusUsers } from '../../hooks';
import { UserItem } from '../../types/users.types';
import UserListItem from './components/user-item';
import './index.scss';

const ExalusUsersList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('profile');
  const { data, isLoading } = useExalusUsers();

  const users = useMemo(
    () =>
      data
        ?.filter(({ user }: UserItem) => user.AccessLevel !== AccessLevel.Support)
        .map(({ user, image }: UserItem) => <UserListItem key={user.Guid} user={user} image={image} />),
    [data],
  );

  const handleRedirect = () => history.push(ROUTES.UserCreate());

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.exalusUsers')} isUnderline />
        </>
      }
    >
      <div className="exalus-users">
        <div>{users}</div>
        <SubmitButton type="button" onClick={handleRedirect}>
          {t('exalus.createNew')}
        </SubmitButton>
      </div>
      <CustomBackdrop loading={isLoading} />
    </Page>
  );
};

export default ExalusUsersList;
