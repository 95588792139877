import React from 'react';
import './index.scss';

export const IconPhoto: React.FC = () => (
  <svg
    className="icon-photo"
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M4.33317 1.66666H25.6665C27.1332 1.66666 28.3332 3.07291 28.3332 4.79166V23.5417C28.3332 25.2604 27.1332 26.6667 25.6665 26.6667H4.33317C2.8665 26.6667 1.6665 25.2604 1.6665 23.5417V4.79166C1.6665 3.07291 2.8665 1.66666 4.33317 1.66666Z"
      stroke="#FF4016"
    />
    <path d="M2.27246 20.9091L7.72701 15.4546L12.5755 20.303L21.6664 11.2121L27.727 17.2727" stroke="#FF4016" />
    <circle cx="12.5756" cy="8.93938" r="2.42424" stroke="#FF4016" />
  </svg>
);
