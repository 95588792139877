import React from 'react';

export const IconInstallation6: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M5.5 24.9859C5.5 24.2284 5.928 23.5358 6.60557 23.1971L18.1667 17.4165L18.1667 34.8332H7.5C6.39543 34.8332 5.5 33.9377 5.5 32.8332L5.5 24.9859Z"
      stroke="#15141D"
    />
    <path
      d="M18.167 5.08716C18.167 4.27814 19.0777 3.80398 19.7405 4.26793L33.1472 13.6527C33.6819 14.0269 34.0003 14.6385 34.0003 15.2911L34.0003 32.8332C34.0003 33.9377 33.1049 34.8332 32.0003 34.8332H18.167L18.167 5.08716Z"
      stroke="#15141D"
    />
    <path d="M26.083 34.8335L26.083 22.9585" stroke="#15141D" />
  </svg>
);
