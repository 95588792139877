import React, { createContext, useContext, useState } from 'react';
import * as uuid from 'uuid';
import { OptimizerSchedule, OptimizerScheduleBody } from '../types';

interface OptimizerScheduleProviderInterface {
  schedules: OptimizerSchedule[];
  addScheduleToList: (schedule: OptimizerScheduleBody) => void;
  editSchedule: (scheduleId: string, schedule: OptimizerScheduleBody) => void;
}

const initialState: OptimizerScheduleProviderInterface = {
  schedules: [],
  addScheduleToList: () => null,
  editSchedule: () => null,
};

export const OptimizerScheduleContext = createContext<OptimizerScheduleProviderInterface>(initialState);

export const useOptimizerScheduleContext = (): OptimizerScheduleProviderInterface =>
  useContext(OptimizerScheduleContext);

export const OptimizerScheduleContextProvider: React.FC = ({ children }) => {
  const [schedules, setSchedules] = useState<OptimizerSchedule[]>([]);

  const addScheduleToList = (scheduleBody: OptimizerScheduleBody) => {
    setSchedules([...schedules, { ...scheduleBody, id: uuid.v4() }]);
  };

  const editSchedule = (scheduleId: string, scheduleBody: OptimizerScheduleBody) => {
    setSchedules((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((x) => x.id === scheduleId);

      if (index !== -1) {
        temp[index].from = scheduleBody.from;
        temp[index].to = scheduleBody.to;
        temp[index].action = scheduleBody.action;
        temp[index].actionValue = scheduleBody.actionValue;
      }

      return [...temp];
    });
  };

  const values: OptimizerScheduleProviderInterface = {
    schedules,
    addScheduleToList,
    editSchedule,
  };

  return <OptimizerScheduleContext.Provider value={values}>{children}</OptimizerScheduleContext.Provider>;
};
