import { Api } from 'lavva.exalushome';
import { ACCENT_COLOR_DEFAULT_INDEX_MAP, INSTALLATION_COLOR_LIST } from '../../const';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { StateCreateInstallationType } from '../../modules/installation-create/context';
import { InstallationIconTypeEnum, IntegrationDefaultEnum } from '../../types';
import { isLocalhost } from './environment';

const isLocalNetwork = () => {
  if (isLocalhost) return !!localStorage.getItem('local-app');
  return Api.IsRunningFromLocalNetwork() || !!localStorage.getItem('local-app');
};

export const isLocalApp = isLocalNetwork();
export const registerServiceWorkerShouldRegister = () => {
  if (isLocalhost) return false;
  return Api.IsRunningFromLocalNetwork();
};

export const installationExalusLocalState: StateCreateInstallationType = {
  integrationDefault: IntegrationDefaultEnum.Exalus,
  installationName: 'Exalus',
  iconName: InstallationIconTypeEnum.INSTALLATION_0,
  timeZone: '',
  locationLat: null,
  locationLong: null,
  hexColor: INSTALLATION_COLOR_LIST[ACCENT_COLOR_DEFAULT_INDEX_MAP[IntegrationDefaultEnum.Exalus]],
  integrationType: IntegrationType.Exalus,
  createLoading: false,
  exalusCredentials: {
    serialNumber: '',
    pin: '',
  },
};
