import React from 'react';

export const IconLocation: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25896 10.3618C1.51029 10.3618 1.27711 9.34927 1.95036 9.0218L17.8769 1.27506C18.4887 0.977491 19.1257 1.63282 18.811 2.23594L10.6288 17.9138C10.2874 18.5679 9.2978 18.3252 9.2978 17.5873L9.2978 11.0673C9.2978 10.6776 8.98193 10.3618 8.59228 10.3618L2.25896 10.3618Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
