import React from 'react';

export const IconExalusWindSensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.290039" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23111_32160)">
      <path
        d="M28.2897 28.8591C28.2897 24.0299 32.1689 20.1507 36.998 20.1507C41.8272 20.1507 45.7064 24.0299 45.7064 28.8591L28.2897 28.8591ZM28.2897 28.8591C28.2897 33.6882 24.4105 37.5674 19.5814 37.5674C14.7522 37.5674 10.873 33.6882 10.873 28.8591L28.2897 28.8591ZM28.2897 28.8591C23.4605 28.8591 19.5814 24.9799 19.5814 20.1507C19.5814 15.3215 23.4605 11.4424 28.2897 11.4424L28.2897 28.8591ZM28.2897 28.8591C33.1189 28.8591 36.998 32.7382 36.998 37.5674C36.998 42.3966 33.1189 46.2757 28.2897 46.2757L28.2897 28.8591Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.8785 18.2427C10.4425 16.7311 10.5969 15.0503 11.443 13.5799C12.3076 12.0772 13.7229 11.0924 15.2855 10.734"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M45.7084 38.897C46.1422 40.4093 45.9853 42.0898 45.1371 43.5591C44.2702 45.0605 42.8535 46.0432 41.2904 46.3993"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23111_32160">
        <rect width="38" height="38" fill="white" transform="translate(9.29004 9.85938)" />
      </clipPath>
    </defs>
  </svg>
);
