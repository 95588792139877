import * as React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import './index.scss';

const CircularProgressWithLabel = (props: CircularProgressProps) => (
  <div className="progress-with-label">
    <CircularProgress variant={props.value ? 'determinate' : 'indeterminate'} {...props} />
    {props.value ? <div className="percentage">{`${Math.round(props.value)}%`}</div> : null}
  </div>
);

export default CircularProgressWithLabel;
