import React, { ReactNode, useMemo } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import { IconClose } from '../../icons';
import './index.scss';

interface Props {
  show?: boolean;
  setShow: (show: boolean) => void;
  children?: ReactNode;
  title?: string;
  isBottomPosition?: boolean;
  closeBtn?: boolean;
  className?: string;
  backdropCloseDisabled?: boolean;
  titleIcon?: React.ReactNode;
}

export const Dialog: React.FC<Props> = ({
  show,
  setShow,
  children,
  isBottomPosition,
  className = false,
  title,
  closeBtn,
  titleIcon,
  backdropCloseDisabled = false,
}) => {
  const dialogRootElement = useMemo(() => document.querySelector('#modal-root'), []);

  const handleClose = () => {
    if (!backdropCloseDisabled) setShow(false);
  };

  if (!show) return null;

  return (
    dialogRootElement &&
    ReactDOM.createPortal(
      <div
        className={classNames('dialog', {
          'dialog--active': show,
          'dialog--bottom': isBottomPosition,
          [className as string]: className,
        })}
      >
        <div className="dialog__backdrop" onClick={handleClose} />
        <div className="dialog__content">
          {closeBtn && (
            <div className="dialog__closeBtn">
              <IconButton onClick={() => setShow(false)}>
                <IconClose />
              </IconButton>
            </div>
          )}
          {title && (
            <div className="dialog__title">
              {title} {titleIcon ? titleIcon : null}
            </div>
          )}
          {children}
        </div>
      </div>,
      dialogRootElement,
    )
  );
};
