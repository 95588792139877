import React from 'react';
import { useTranslation } from 'react-i18next';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import TextInfo from '../../../../../../components/text-info';
import './index.scss';

interface ComponentProps {
  user?: IUser | null;
}

const ExalusUserInfo: React.FC<ComponentProps> = ({ user }) => {
  const { t } = useTranslation('profile');

  return (
    <div className="rest-info">
      <div className="row-container m-b-24">
        <TextInfo label={t('exalus.firstName')} value={user?.Name || '-'} reverse column />
        <TextInfo label={t('exalus.lastName')} value={user?.Surname || '-'} reverse column />
      </div>
      <TextInfo label={t('exalus.email')} value={user?.Email || '-'} reverse column />
      <hr className="m-t-24 m-b-24" />
      <TextInfo label={t('exalus.phone')} value={user?.Phone || '-'} reverse column className="m-b-24" />
      <TextInfo label={t('exalus.gender')} value={t(`exalus.gender_${user?.Gender}`)} reverse column />
      <hr className="m-t-24 m-b-24" />
      <TextInfo label={t('exalus.access')} value={t(`exalus.role_${user?.AccessLevel}`)} reverse column />
    </div>
  );
};

export default ExalusUserInfo;
