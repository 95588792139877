import { gql } from '@apollo/client';

export const DEVICES_GET_ALL = gql`
  query DevicesGetAll($installationId: UUID!) {
    allUserDevices(installationId: $installationId) {
      id
      installationId
      payload {
        id: deviceId
        deviceId
        model
        name
        connectedToSsid
        currentFirmwareVersion
        macAddress
        lastConnectedAtUtc
        registeredAtUtc
        newestFirmwareVersion
        timeZone
        isUpdateAvailable
        channelInfos {
          channelId
          channelNumber
          channelType
        }
      }
    }
  }
`;

export const DEVICE_GET_BINDINGS = gql`
  query DeviceGetBindings($installationId: UUID!, $deviceId: UUID!) {
    channelBindings: allUserChannels(
      installationId: $installationId
      where: { deviceId: { eq: $deviceId } }
      order: { deviceId: ASC, alias: ASC }
    ) {
      id
      alias
      deviceId
      channelType
      payload {
        id: channelId
        deviceId
        channelId
        ... on GenericInputChannelStateResponse {
          deviceId
          channelId
          genericInputType
          actionBindings {
            isDefaultAction
            slot
            inputTrigger
            destination {
              deviceId
              channelId
            }
            ... on SwitchActionConfigResponse {
              setOnTimeValue
            }
            ... on LightActionConfigResponse {
              setOnTimeValue
              brightness
              temperature
              r
              g
              b
            }
            ... on BlindActionConfigResponse {
              position
            }
            ... on GateActionConfigResponse {
              position
              dir
            }
            ... on CloudActionConfigResponse {
              onSignalActionId
              offSignalActionId
            }
          }
        }
      }
    }
  }
`;

export const VERSION_MAP = gql`
  query VersionMap {
    versionMap {
      key
      versionedFeatures {
        feature
        minVersion {
          major
          minor
          build
          asString
        }
      }
    }
  }
`;
