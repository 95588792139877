export const vapidKey = 'BJerSUEO57gxJJaLUYLbrcREGbpz7vJia_8aC8oOK1qRDjosRmrOupsNPqki3qej9S7YXNcPUip8pP6Z4YXZfJU';

export const firebaseConfig = {
  apiKey: 'AIzaSyAG4D5VyqSHN_nD3_IK7A5BokgW7pkSOLA',
  authDomain: 'zamel-lavva.firebaseapp.com',
  projectId: 'zamel-lavva',
  storageBucket: 'zamel-lavva.appspot.com',
  messagingSenderId: '358971972011',
  appId: '1:358971972011:web:09453b7987a207330d1aee',
  measurementId: 'G-89TFTTJFVB',
};
