import React from 'react';

export const IconGym: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="33"
      height="21"
      viewBox="0 0 33 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93511 3.80834H3.03019C2.18509 3.80834 1.5 4.49343 1.5 5.33853V8.12887V11.5853V14.5917C1.5 15.4368 2.18509 16.1219 3.03019 16.1219H4.93511M4.93511 3.80834V16.1219M4.93511 3.80834V3.51908C4.93511 2.12783 6.06295 1 7.4542 1V1C8.84545 1 9.97328 2.12783 9.97328 3.51909V7.91285M4.93511 16.1219V16.8432C4.93511 18.2344 6.06295 19.3622 7.4542 19.3622V19.3622C8.84545 19.3622 9.97328 18.2344 9.97328 16.8432V11.8013M9.97328 7.91285H23.2557M9.97328 7.91285V11.8013M23.2557 7.91285V3.51908C23.2557 2.12783 24.3836 1 25.7748 1V1C27.1661 1 28.2939 2.12783 28.2939 3.51908V4.02437M23.2557 7.91285V11.8013M23.2557 11.8013V16.8432C23.2557 18.2344 24.3836 19.3622 25.7748 19.3622V19.3622C27.1661 19.3622 28.2939 18.2344 28.2939 16.8432V16.1219M23.2557 11.8013H9.97328M28.2939 16.1219H29.9698C30.8149 16.1219 31.5 15.4368 31.5 14.5917V11.3693V8.3449V5.55456C31.5 4.70946 30.8149 4.02437 29.9698 4.02437H28.2939M28.2939 16.1219V4.02437"
        stroke="#15141D"
        strokeWidth="1.5"
      />
    </svg>
  );
};
