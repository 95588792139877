import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../../components/arrow-button';
import { ROUTES } from '../../../../../../../routes';

interface ComponentProps {
  channel: IDeviceChannel;
}

const AutoCalibration: React.FC<ComponentProps> = ({ channel }) => {
  const history = useHistory();
  const { t } = useTranslation('device-info');

  const goToAutoCalibration = () => {
    if (channel) {
      history.push(ROUTES.BlindAutoCalibration(channel.ChannelId));
    }
  };

  return (
    <ArrowButton
      title={`${t('exalus.calibration')} ${t('exalus.autoCalibration')}`}
      onClick={goToAutoCalibration}
      className="m-b-24"
    />
  );
};

export default AutoCalibration;
