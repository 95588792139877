import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StatusScreen } from '../../../../../../components';
import { ROUTES } from '../../../../../../routes';
import { StatusTypeEnum } from '../../../../../../types';
import * as storage from '../../../../../../utils/storage/lavva';

const CreateAccountOffer: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('dashboard');

  const handleCreateAccount = () => {
    localStorage.removeItem('local-app');
    window.location.href = ROUTES.Root();
  };

  const handleMaybeLater = () => {
    storage.setItem('remindCreateAccount', new Date());
    history.goBack();
  };

  return (
    <StatusScreen
      header={t('createAccountOffer.header')}
      subTitle={t('createAccountOffer.content')}
      type={StatusTypeEnum.MIGRATION}
      primaryBtnText={t('createAccountOffer.createAccount')}
      onPrimaryBtnClick={handleCreateAccount}
      secondaryBtnText={t('createAccountOffer.maybeLater')}
      onSecondaryBtnClick={handleMaybeLater}
      secondaryBackground
      noBackButton
    />
  );
};

export default CreateAccountOffer;
