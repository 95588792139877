import { ReactNode } from 'react';

export interface ConditionDetails {
  description: string;
  title?: string;
  secondLine?: string;
  icon?: string;
}

export enum SetParametersType {
  Edit,
  Duplicate,
}

export interface DeviceConditionState {
  icon: ReactNode | null;
  state?: string;
}