import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { IconInitial, IconPhoto, EditIconControl, CustomBackdrop } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { useInstallation, useProfile } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { useExalusUser, useExalusUserImg } from '../../exalus/hooks';
import ExalusUserMainInfo from '../../exalus/pages/users-list/user-details/user-data/main-info';
import EditAvatar from '../edit-avatar';
import './index.scss';
import { LogoutExalus, LogoutLavva } from './logout';
import { ProfileDelete } from './profile-delete';
import SettingsForm from './settings-form';

const ProfileSettings: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('profile');
  const { user, userInitial, loadingUser } = useProfile();
  const [useUserInitial, setUseUserInitial] = useState<boolean>(false);
  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>();
  const [profileImageUrlTemp, setProfileImageUrlTemp] = useState<string | undefined>(undefined);
  const [openEditAvatar, setOpenEditAvatar] = useState<boolean>(false);
  const { integrationType } = useInstallation();
  const { data: loggedUser, isLoading: loggedUserLoading } = useExalusUser();
  const { data: image, isLoading: imageLoading } = useExalusUserImg(loggedUser as IUser);

  useEffect(() => {
    const profileImage = user?.profileImage?.imageUrl;
    setProfileImageUrl(profileImage || undefined);
  }, [user]);

  useEffect(() => {
    if (user) setUseUserInitial(!profileImageUrl);
  }, [profileImageUrl]);

  const currentIconControl = useMemo(() => {
    if (useUserInitial) return <IconInitial initial={userInitial} />;
    else if (profileImageUrl)
      return (
        <img
          src={profileImageUrl.includes('blob:') ? profileImageUrl : `${profileImageUrl}?t=${new Date().getTime()}`}
          alt="avatar"
          className="edit-avatar__avatar"
        />
      );
    return <IconPhoto />;
  }, [profileImageUrl, useUserInitial, userInitial]);

  const openEdition = (value: boolean) => {
    setProfileImageUrlTemp(profileImageUrl);
    setOpenEditAvatar(value);
  };

  const createAccountOffer = useMemo(() => localStorage.getItem('local-app'), [localStorage]);

  const handleCreateLavvaAccount = () => {
    localStorage.removeItem('local-app');
    window.location.href = ROUTES.Root();
  };

  if (openEditAvatar) {
    return (
      <EditAvatar
        setOpen={openEdition}
        setUseUserInitial={setUseUserInitial}
        useUserInitial={useUserInitial}
        setProfileImageUrl={setProfileImageUrl}
        profileImageUrlTemp={profileImageUrlTemp}
        setProfileImageUrlTemp={setProfileImageUrlTemp}
      />
    );
  }

  return (
    <>
      {!isLocalApp && (
        <>
          <EditIconControl setOpen={openEdition} caption={t('avatar.current')} subCaption={t('avatar.change')}>
            {currentIconControl}
          </EditIconControl>
          <SettingsForm />
          <LogoutLavva />
        </>
      )}

      {integrationType === IntegrationType.Exalus && loggedUser && (
        <>
          {!isLocalApp && <hr className="m-t-24 m-b-24" />}

          {createAccountOffer && (
            <div className="create-account-offer">
              <button onClick={handleCreateLavvaAccount}>{t('exalus.createLavvaAccount')}</button>
            </div>
          )}
          <h3 className="m-t-24 m-b-16">{t('exalus.exalusController')}</h3>
          <ExalusUserMainInfo user={loggedUser} image={image} />
          <div className="grid-list-24 align-end m-t-24">
            <ArrowButton
              title={t('exalus.editUser')}
              onClick={() => history.push(ROUTES.UserEdit('me'))}
              small
              className="m-b-24"
            />
            <ArrowButton
              title={t('exalus.changePassword')}
              label={t('exalus.password')}
              onClick={() => history.push(ROUTES.ChangeUserPassword(loggedUser.Guid))}
              small
              className="m-b-24"
            />
          </div>
          <LogoutExalus />
        </>
      )}
      {!isLocalApp && <ProfileDelete />}

      <CustomBackdrop loading={loadingUser || loggedUserLoading || imageLoading} />
    </>
  );
};

export default ProfileSettings;
