import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useGetDeviceStatus } from '../../../api/modules/recovery/recovery.hooks';
import { DialogConfirmation, Header, IconAutomatic, IconEdit } from '../../../components';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  RecoveryDeviceStatus,
  UserPermissionType,
} from '../../../data-access/gql-types/graphql';
import { useChannelsState, useDevicesAndChannels } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { AvailableLanguage } from '../../../types';
import { convertDateToFormat } from '../../../utils/helpers';
import DeviceRestart from '../../device-settings/device-restart';
import RemoveDevice from '../../device-settings/remove-device';
import ConfigurationName from '../configuration-form/components/configuration-name';
import { useConfiguration } from '../hooks/use-configuration';
import Detail from './components/detail';
import UpdateNetwork from './components/update-network';
import { RecoveryDeviceProgress } from './components/update-progress';
import './index.scss';

const ConfigurationDetails: React.FC = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const { t: ta } = useTranslation('action');
  const [updateNetwork, setUpdateNetwork] = useState<boolean>(false);
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const [nameDisabled, setNameDisabled] = useState<boolean>(true);
  const { deviceId } = useParams<{ channelType: ChannelTypeInternal; deviceId: string }>();
  const { device, deviceChannels } = useDevicesAndChannels({ deviceId });
  const { channelState } = useChannelsState();
  const { updateDevice, setDeviceName, updateDeviceLoading, updateRecoveryDevice } = useConfiguration({ device });
  const { mutate } = useGetDeviceStatus();
  const { permissions } = usePermissionsContext();
  const formMethods = useForm({
    defaultValues: {
      name: '',
    },
  });

  const isDeviceInRecovery = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
      ),
    [deviceChannels, channelState],
  );

  useEffect(() => {
    if (isDeviceInRecovery && device) {
      mutate({ deviceId: device.id });
    }
  }, [deviceChannels]);

  useEffect(() => {
    if (device) {
      formMethods.setValue('name', device.payload.name || '');
    }
  }, [device]);

  const status = device?.recoveryMode?.status;
  const progress = device?.recoveryMode?.progress;

  const onSubmit = formMethods.handleSubmit((values) => {
    setNameDisabled(true);
    setDeviceName(values.name);
    setShowSaveDialog(false);
  });

  const onCancel = useCallback(() => {
    setShowSaveDialog(false);
    history.goBack();
  }, []);

  const onSave = useCallback(() => {
    onSubmit();
  }, []);

  const isDeviceConnected = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Connected,
      ),
    [deviceChannels, channelState],
  );

  const isDeviceDisconnected = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Disconnected,
      ),
    [deviceChannels, channelState],
  );

  const buttonContent = useMemo(() => {
    if (status === RecoveryDeviceStatus.DownloadingFirmware) {
      return tc('buttons.loading');
    } else {
      return t('updateSoftware.update');
    }
  }, [status, progress]);

  const openNetworkSettings = () => setUpdateNetwork(true);

  const lastConnectedAt = useMemo(() => {
    return device?.payload.lastConnectedAtUtc
      ? convertDateToFormat(
          new Date(device.payload.lastConnectedAtUtc),
          'dd.MM.yy, HH:mm:ss',
          i18n.language as AvailableLanguage,
        )
      : '-';
  }, [device?.payload.lastConnectedAtUtc]);

  const registeredAt = useMemo(() => {
    return device?.payload.registeredAtUtc
      ? convertDateToFormat(
          new Date(device.payload.registeredAtUtc),
          'dd.MM.yy, HH:mm:ss',
          i18n.language as AvailableLanguage,
        )
      : '-';
  }, [device?.payload.registeredAtUtc]);

  if (!device) return null;

  return (
    <>
      {!updateNetwork ? (
        <div className="configuration_details">
          <FormProvider {...formMethods}>
            <form onSubmit={onSubmit}>
              <Header subheader title={t('device_identification')} />
              <ConfigurationName
                label={t('device_name')}
                placeholder={t('device_name')}
                edited
                disabled={nameDisabled}
                {...(permissions[UserPermissionType.DeviceList] ? { setDisabled: setNameDisabled } : {})}
              />
              <Detail label="type" value={tc(`types.${device?.type}`) || tc('types.UNKNOWN')} />
              <hr />
              <div className="edit_section max-width-desktop">
                <Header subheader title={t('network_settings')} />
                {isDeviceConnected && permissions[UserPermissionType.DeviceList] && (
                  <button onClick={openNetworkSettings} type="button">
                    <IconEdit />
                  </button>
                )}
              </div>
              <div className="grid-list-24">
                <Detail label="SSID" value={device.payload.connectedToSsid} />
                <Detail label={t('mac-address')} value={device.payload.macAddress || '-'} />
                <Detail label={t('lastConnected')} value={lastConnectedAt} />
                <Detail label={t('registeredAt')} value={registeredAt} />
              </div>
              <hr />
              <Header subheader title={t('updateSoftware.version')} />
              <p>
                {device.payload.isUpdateAvailable
                  ? t('updateSoftware.versionAvailable', {
                      current: device?.payload.currentFirmwareVersion,
                      available: device?.payload.newestFirmwareVersion,
                    })
                  : device?.payload.currentFirmwareVersion || '-'}
                {isDeviceDisconnected ? <span> ({t('updateSoftware.lastKnownVersion')})</span> : null}
              </p>
              {isDeviceInRecovery ? (
                <>
                  <hr />
                  <div className="recovery-section">
                    <Header subheader title={t('recoveryMode.header')} />
                    <p>{t('recoveryMode.content')}</p>
                    <div className="recovery-section__box">
                      <RecoveryDeviceProgress progress={progress} status={status} />
                      <button
                        className="button update m-t-32"
                        onClick={updateRecoveryDevice}
                        type="button"
                        disabled={status !== RecoveryDeviceStatus.FirmwareDownloaded}
                      >
                        <IconAutomatic className="update-icon" />
                        {buttonContent}
                      </button>
                    </div>
                    <div className="recovery-section__progress"></div>
                  </div>
                </>
              ) : null}
              <div className="buttons_container grid-list-24">
                {!isDeviceInRecovery && permissions[UserPermissionType.DeviceList] ? (
                  <button
                    className="button update m-b-24"
                    disabled={!device.payload.isUpdateAvailable || updateDeviceLoading || !isDeviceConnected}
                    onClick={updateDevice}
                    type="button"
                  >
                    <IconAutomatic className="update-icon" />
                    {!updateDeviceLoading
                      ? isDeviceDisconnected
                        ? t('updateSoftware.noData')
                        : device.payload.isUpdateAvailable
                        ? t('updateSoftware.update')
                        : t('updateSoftware.updated')
                      : tc('buttons.loading')}
                  </button>
                ) : null}
                {permissions[UserPermissionType.DeviceRemove] && !isDeviceInRecovery && (
                  <RemoveDevice deviceId={deviceId} />
                )}
                {permissions[UserPermissionType.DeviceList] && !isDeviceInRecovery && (
                  <DeviceRestart deviceId={deviceId} />
                )}
              </div>
            </form>
          </FormProvider>
          <DialogConfirmation
            show={showSaveDialog}
            setShow={setShowSaveDialog}
            header={ta('confirmDialog.header')}
            primaryBtnText={tc('buttons.save')}
            secondaryBtnText={tc('buttons.cancel')}
            primaryBtnAction={onSave}
            secondaryBtnAction={onCancel}
          />
        </div>
      ) : (
        <UpdateNetwork setUpdateNetwork={setUpdateNetwork} />
      )}
    </>
  );
};

export default ConfigurationDetails;
