import React from 'react';

export const IconExalusIntercom: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.318359" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23128_13974)">
      <circle cx="28.6289" cy="25.2481" r="3.6387" stroke="#15141D" strokeWidth="1.5" />
      <path
        d="M28.8304 31.3926H28.5031C25.4956 31.3926 22.8221 33.3078 21.8545 36.1553L21.6152 36.8597H36.2055L35.8821 35.9834C34.8641 33.2247 32.235 31.3926 29.2945 31.3926H28.8304Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <circle cx="39.1868" cy="20.7278" r="0.868421" fill="#15141D" />
      <rect
        x="26.3184"
        y="40.1406"
        width="5"
        height="0"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <rect
        x="34.3184"
        y="40.1406"
        width="3"
        height="0"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <rect
        x="20.3184"
        y="40.1406"
        width="3"
        height="0"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <rect x="15.9697" y="17.5107" width="25.6974" height="19.6974" stroke="#15141D" strokeWidth="1.30263" />
    </g>
    <rect
      x="12.9697"
      y="14.5107"
      width="31.6974"
      height="29.0921"
      rx="2.82237"
      stroke="#15141D"
      strokeWidth="1.30263"
    />
    <defs>
      <clipPath id="clip0_23128_13974">
        <rect x="12.3184" y="13.8594" width="33" height="30.3947" rx="3.47368" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
