import React from 'react';
import c from 'classnames';

interface IconSwitchProps {
  isOn: boolean;
  big?: boolean;
  noState?: boolean;
}

export const IconThermometer: React.FC<IconSwitchProps> = ({ isOn = true, big, noState }) => {
  return isOn ? (
    <svg
      className={c('icon-sensor icon-thermometer', { big: big, 'no-state': noState })}
      width="23"
      height="37"
      viewBox="0 0 23 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.58 22.3699C13.2138 22.7831 13.7807 23.2904 14.261 23.8718C15.3231 25.158 15.9612 26.8072 15.9612 28.6055C15.9612 32.7138 12.6309 36.0442 8.52261 36.0442C4.41437 36.0442 1.08398 32.7138 1.08398 28.6055C1.08398 25.9942 2.42956 23.6971 4.46518 22.3699"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.83008 21.8906L4.83008 15.1215L4.83008 5.2756C4.83008 3.23644 6.48314 1.58337 8.52231 1.58337V1.58337C10.5615 1.58337 12.2145 3.23644 12.2145 5.2756L12.2145 15.1215L12.2145 21.8906"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.52203"
        cy="28.6597"
        r="2.46148"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.52344 25.5829L8.52344 5.54175"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.084 17.583H22.007" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.084 11.4293H20.1608"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.084 5.27563L22.007 5.27563"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      className={c('icon-sensor icon-thermometer', { big: big, 'no-state': noState })}
      width="17"
      height="37"
      viewBox="0 0 17 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.58 22.3699C13.2138 22.7831 13.7807 23.2904 14.261 23.8718C15.3231 25.158 15.9612 26.8072 15.9612 28.6055C15.9612 32.7138 12.6309 36.0442 8.52261 36.0442C4.41437 36.0442 1.08398 32.7138 1.08398 28.6055C1.08398 25.9942 2.42956 23.6971 4.46518 22.3699"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.83008 21.8906L4.83008 15.1215L4.83008 5.2756C4.83008 3.23644 6.48314 1.58337 8.52231 1.58337V1.58337C10.5615 1.58337 12.2145 3.23644 12.2145 5.2756L12.2145 15.1215L12.2145 21.8906"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.52203"
        cy="28.6597"
        r="2.46148"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.52344 25.5829L8.52344 5.54175"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
