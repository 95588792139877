import React from 'react';
import { FormChannelsContextProvider } from '../context';
import { Create } from './create';

export const CreateGroup: React.FC = () => (
  <FormChannelsContextProvider>
    <Create />
  </FormChannelsContextProvider>
);

export default CreateGroup;
