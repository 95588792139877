import React from 'react';

export const IconShopping: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.195 1.28252L24.269 6.29145L26 8.02203" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M13.339 1.28249L8.26504 6.29142L6.35553 8.20068"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1.54343 11.1121C1.23117 9.70696 2.30042 8.37402 3.73985 8.37402H28.2602C29.6996 8.37402 30.7688 9.70696 30.4566 11.1121L27.5232 24.3121C27.2945 25.3416 26.3814 26.074 25.3268 26.074H6.67318C5.61861 26.074 4.70553 25.3416 4.47676 24.3121L1.54343 11.1121Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
    </svg>
  );
};
