import React from 'react';

export const IconChat: React.FC = () => {
  return (
    <svg
      className="icon-chat"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.19076 2.32776H19.8092C21.0193 2.32776 22 3.30003 22 4.50003V17.0984C22 18.2975 21.0193 19.2706 19.8092 19.2706H18.7143V22.5L12.5 19.2706H4.19076C2.98068 19.2706 2 18.2984 2 17.0984V4.50003C2 3.30087 2.98068 2.32776 4.19076 2.32776Z"
        stroke="#009EE3"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
