import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../../components';

interface ComponentProps {
  serialNumber: string;
}

export const SerialNumber: React.FC<ComponentProps> = ({ serialNumber }) => {
  const { control } = useFormContext();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');

  return (
    <Controller
      name="serialNumber"
      control={control}
      rules={{
        required: {
          value: true,
          message: tc('isRequired'),
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <Input
          defaultValue={serialNumber}
          value={serialNumber}
          label={t('exalus.serialNumber')}
          onChange={field.onChange}
          hasError={!!error}
        />
      )}
    />
  );
};
