import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useTransitionValue from 'react-transition-value';
import classNames from 'classnames';
import { DonutChartWrapper } from '../../../../../../components/donut-chart';
import PopoverCustom from '../../../../../../components/popover';
import { OptimizerVectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { measurementParametersOptimizer } from '../../../../measurement/utils';
import { getOptimizerCurrentState, getOptimizerLoadState, getOptimizerPowerState } from '../../../utils';
import { OptimizerCurrentDataMap } from '../../types';
import './index.scss';

interface PropsInterface {
  value: number;
  dataMax: number;
  phase: number;
  activeTabIndex: number;
  selectedParameter: OptimizerVectorParameterTypeInternal;
  data: OptimizerCurrentDataMap[];
  disabled?: boolean;
}

export const EnergyDonut: React.FC<PropsInterface> = ({
  value,
  phase,
  activeTabIndex,
  selectedParameter,
  data,
  disabled,
}) => {
  const { t } = useTranslation('channel-details');
  const dataMax = measurementParametersOptimizer[selectedParameter].dataMax;
  const unit = measurementParametersOptimizer[selectedParameter].unit;
  const fixed = measurementParametersOptimizer[selectedParameter].fixed;
  const [lazyFormattedSum, setLazyFormattedSum] = useTransitionValue(0, { duration: 800, from: 0 });

  useEffect(() => {
    if (value) {
      setTimeout(() => setLazyFormattedSum(Math.abs(value)), 200);
    } else {
      setLazyFormattedSum(0.000000001, 200);
    }
  }, [value]);

  const percentageRatioActualToMaximum = useMemo(() => {
    if (value !== undefined) {
      if (dataMax !== 0) return (value * 100) / dataMax;
    }

    return 0;
  }, [value, dataMax]);

  const state = useMemo(() => {
    if (selectedParameter === OptimizerVectorParameterTypeInternal.Voltage) {
      return getOptimizerCurrentState(value, data);
    } else if (selectedParameter === OptimizerVectorParameterTypeInternal.ActivePower) {
      return getOptimizerPowerState(value);
    }

    return getOptimizerLoadState(value);
  }, [value, data, selectedParameter]);

  return (
    <div className={classNames('energy-donut', { disabled: disabled })}>
      <p className="phase-number">{`${t('phase')} ${phase}`}</p>
      <DonutChartWrapper
        value={Math.abs(Math.round((value + Number.EPSILON) * 1000) / 1000)}
        lazyValue={lazyFormattedSum}
        averageValue={+(value / 1).toFixed(2)}
        maxScaleValue={dataMax || 0.1}
        label={''}
        percentageRatioActualToMaximum={percentageRatioActualToMaximum}
        unit={unit}
        showEco={false}
        selectedPhases={[]}
        phaseMeasurements={[]}
        ticks={false}
        indicator={false}
        axis={false}
        color={state?.color}
        fixed={fixed}
        arrow={activeTabIndex === 1 && value !== 0}
        arrowDirection={value < 0 ? 'up' : 'down'}
      />
      {selectedParameter === OptimizerVectorParameterTypeInternal.Voltage && state && (
        <div className="energy-donut--icon">
          <PopoverCustom id={`icon-${unit}`} buttonContent={state.icon} className={state.className}>
            {t(`optimizer.configuration.voltageTooltip.${state.label}`)}
          </PopoverCustom>
        </div>
      )}
    </div>
  );
};
