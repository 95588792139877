import { useTranslation } from 'react-i18next';

export const useBrightnessScale = () => {
  const { t } = useTranslation('action');

  const brightnessOptions = [
    {
      value: t('action.create.tasks.brightnessScale.totalDarkness'),
      label: t('action.create.tasks.brightnessScale.totalDarkness'),
    },
    {
      value: t('action.create.tasks.brightnessScale.veryDark'),
      label: t('action.create.tasks.brightnessScale.veryDark'),
    },
    {
      value: t('action.create.tasks.brightnessScale.darkRoom'),
      label: t('action.create.tasks.brightnessScale.darkRoom'),
    },
    {
      value: t('action.create.tasks.brightnessScale.darkenedRoom'),
      label: t('action.create.tasks.brightnessScale.darkenedRoom'),
    },
    {
      value: t('action.create.tasks.brightnessScale.normalLightingInTheRoom'),
      label: t('action.create.tasks.brightnessScale.normalLightingInTheRoom'),
    },
    {
      value: t('action.create.tasks.brightnessScale.brightInTheRoom'),
      label: t('action.create.tasks.brightnessScale.brightInTheRoom'),
    },
    {
      value: t('action.create.tasks.brightnessScale.tintedOutside'),
      label: t('action.create.tasks.brightnessScale.tintedOutside'),
    },
    {
      value: t('action.create.tasks.brightnessScale.cloudyOutside'),
      label: t('action.create.tasks.brightnessScale.cloudyOutside'),
    },
    {
      value: t('action.create.tasks.brightnessScale.fullSun'),
      label: t('action.create.tasks.brightnessScale.fullSun'),
    },
  ];

  const getBrightnessStateByScale = (scale?: number) => {
    if (scale) {
      switch (true) {
        case scale >= 0.0 && scale < 0.21:
          return t('action.create.tasks.brightnessScale.totalDarkness');
        case scale >= 0.21 && scale < 0.34:
          return t('action.create.tasks.brightnessScale.veryDark');
        case scale >= 0.34 && scale < 0.46:
          return t('action.create.tasks.brightnessScale.darkRoom');
        case scale >= 0.46 && scale < 0.52:
          return t('action.create.tasks.brightnessScale.darkenedRoom');
        case scale >= 0.52 && scale < 0.6:
          return t('action.create.tasks.brightnessScale.normalLightingInTheRoom');
        case scale >= 0.6 && scale < 0.74:
          return t('action.create.tasks.brightnessScale.brightInTheRoom');
        case scale >= 0.74 && scale < 0.8:
          return t('action.create.tasks.brightnessScale.tintedOutside');
        case scale >= 0.8 && scale < 0.89:
          return t('action.create.tasks.brightnessScale.cloudyOutside');
        case scale >= 0.89 && scale <= 1:
          return t('action.create.tasks.brightnessScale.fullSun');
        default:
          return '';
      }
    }

    return t('action.create.tasks.brightnessScale.totalDarkness');
  };

  const linearScaleMedium = {
    [t('action.create.tasks.brightnessScale.totalDarkness')]: 0.1,
    [t('action.create.tasks.brightnessScale.veryDark')]: 0.27,
    [t('action.create.tasks.brightnessScale.darkRoom')]: 0.4,
    [t('action.create.tasks.brightnessScale.darkenedRoom')]: 0.48,
    [t('action.create.tasks.brightnessScale.normalLightingInTheRoom')]: 0.58,
    [t('action.create.tasks.brightnessScale.brightInTheRoom')]: 0.67,
    [t('action.create.tasks.brightnessScale.tintedOutside')]: 0.77,
    [t('action.create.tasks.brightnessScale.cloudyOutside')]: 0.85,
    [t('action.create.tasks.brightnessScale.fullSun')]: 0.95,
  };

  return {
    getBrightnessStateByScale,
    brightnessOptions,
    linearScaleMedium,
  };
};
