import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetTimeZones } from '../../api/modules/installation/installation.hooks';
import { Header, InstallationColorBtn, NavHeader, Page, SubmitButton } from '../../components';
import { environment } from '../../environment';
import { ROUTES } from '../../routes';
import { IntegrationDefaultEnum } from '../../types';
import { setBodyStyle } from '../../utils/theme';
import InstallationIconEdit from './components/installation-icon';
import InstallationName from './components/installation-name';
import LocationEdit from './components/location';
import { DialogContextProvider } from './components/location/context/location.context';
import TimeZoneEdit from './components/time-zone';
import { useCreateInstallationContext } from './context';
import { useCreateInstallation } from './hooks/use-create-installation';
import './index.scss';
import { CreateInstallationControlForm } from './types';

const InstallationCreate: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { handleSaveCreateInstallationForm } = useCreateInstallation();
  const { state } = useCreateInstallationContext();
  const { data, isLoading } = useGetTimeZones();

  const form = useForm<CreateInstallationControlForm>({
    defaultValues: {
      installation_name: state.installationName,
      icon_name: state.iconName,
      hex_color: state.hexColor,
      time_zone: state.timeZone,
      location: state.locationLat && state.locationLong ? `${state.locationLat}, ${state.locationLong}` : '',
    },
  });

  const { hex_color } = form.watch();

  useEffect(() => {
    if (data) {
      if (state.timeZone) form.setValue('time_zone', state.timeZone);
      else form.setValue('time_zone', 'Europe/Warsaw');
    }
  }, [data, state]);

  useEffect(() => setBodyStyle('--color-accent', hex_color), [hex_color]);

  const onSubmit = form.handleSubmit((values: CreateInstallationControlForm) => {
    handleSaveCreateInstallationForm(values);
  });

  const handleBack = useCallback(() => history.replace(ROUTES.Installation()), []);

  return (
    <Page
      header={
        <>
          <NavHeader onClick={handleBack} />
          <Header title={t('newInstallation')} />
        </>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} className="installation_create">
          <InstallationIconEdit />

          <div className="grid-list-24">
            <InstallationName />
            <InstallationColorBtn />
            <TimeZoneEdit />
            {environment.INTEGRATION_DEFAULT !== IntegrationDefaultEnum.Exalus && (
              <DialogContextProvider>
                <LocationEdit />
              </DialogContextProvider>
            )}
          </div>
          <SubmitButton disabled={isLoading}>{tc('buttons.next')}</SubmitButton>
        </form>
      </FormProvider>
    </Page>
  );
};

export default InstallationCreate;
