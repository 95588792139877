import React from 'react';

export const IconExalusFlushMountedBlindController: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.738281" y="0.579102" width="56" height="56" rx="12" fill="white" />
    <rect
      x="14.6621"
      y="12.7197"
      width="27.8446"
      height="31.3251"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1836 12.7197L45.9893 12.7197"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1836 44.0469L45.9893 44.0469"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6641 31.8652H42.5086"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6641 38.8271H42.5086"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6641 24.9014H42.5086"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6641 17.9443H42.5086"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="26.8613" y="26" width="19.7188" height="19.7188" rx="2.07566" fill="white" />
    <path
      d="M30.528 43.6426L42.8849 43.6426C43.762 43.6426 44.4736 42.9326 44.4754 42.0555L44.5016 29.6691C44.5034 28.7894 43.7908 28.0752 42.9111 28.0752L36.6865 28.0752L30.528 28.0752C29.6496 28.0752 28.9375 28.7873 28.9375 29.6657L28.9375 42.0521C28.9375 42.9305 29.6496 43.6426 30.528 43.6426Z"
      fill="white"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect width="9.34046" height="0.778372" transform="matrix(0 1 -1 0 32.7949 31.1885)" fill="white" />
    <circle
      cx="32.3531"
      cy="31.6303"
      r="1.0918"
      transform="rotate(90 32.3531 31.6303)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="36.0922"
      r="1.0918"
      transform="rotate(90 32.3531 36.0922)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="40.5541"
      r="1.0918"
      transform="rotate(90 32.3531 40.5541)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
  </svg>
);
