import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { OptimizerError, OptimizerErrorGlobal } from '../../../../../api/modules/optimizer/optimizer.types';
import { useErrors } from '../../../../../hooks/use-errors';
import { convertErrorsToOneString } from '../../../../../utils/helpers';
import { toastError } from '../../../../../utils/toast';

export const useOptimizerErrors = () => {
  const { t } = useTranslation('backend-validation');
  const { t: td } = useTranslation('channel-details');
  const { handleRestErrors } = useErrors();

  const handleOptimizerErrors = (error: AxiosError) => {
    handleRestErrors(error, t((error.response?.data as OptimizerErrorGlobal).errorCode));

    const response = error.response;

    if (response) {
      if ('errors' in (response as AxiosResponse).data) {
        const errors: string[] = [];
        const errorObject = (response as AxiosResponse).data.errors as Record<string, OptimizerError[]>;

        Object.keys(errorObject).map((x) => {
          if (x.includes('RelaysConfig[0].ThresholdValue')) {
            errors.push(td('optimizer.configuration.errors.powerThreshold_1'));
          } else if (x.includes('RelaysConfig[1].ThresholdValue')) {
            errors.push(td('optimizer.configuration.errors.powerThreshold_2'));
          } else if (x.includes('RelaysConfig[2].ThresholdValue')) {
            errors.push(td('optimizer.configuration.errors.powerThreshold_3'));
          } else if (x.includes('RelaysConfig[3].ThresholdValue')) {
            errors.push(td('optimizer.configuration.errors.powerThreshold_4'));
          } else if (x.includes('RelaysConfig[0].TimeOn')) {
            errors.push(td('optimizer.configuration.errors.timeOn_1'));
          } else if (x.includes('RelaysConfig[1].TimeOn')) {
            errors.push(td('optimizer.configuration.errors.timeOn_2'));
          } else if (x.includes('RelaysConfig[2].TimeOn')) {
            errors.push(td('optimizer.configuration.errors.timeOn_3'));
          } else if (x.includes('RelaysConfig[3].TimeOn')) {
            errors.push(td('optimizer.configuration.errors.timeOn_4'));
          } else if (x.includes('RelaysConfig[0].TimeOff')) {
            errors.push(td('optimizer.configuration.errors.timeOff_1'));
          } else if (x.includes('RelaysConfig[1].TimeOff')) {
            errors.push(td('optimizer.configuration.errors.timeOff_2'));
          } else if (x.includes('RelaysConfig[2].TimeOff')) {
            errors.push(td('optimizer.configuration.errors.timeOff_3'));
          } else if (x.includes('RelaysConfig[3].TimeOff')) {
            errors.push(td('optimizer.configuration.errors.timeOff_4'));
          } else {
            errorObject[x].map((error) => {
              if (!errors.find((x) => x === error.message)) {
                errors.push(error.message);
              }
            });
          }
        });

        toastError({ content: convertErrorsToOneString(errors) });
      }
    }
  };

  return {
    handleOptimizerErrors,
  };
};
