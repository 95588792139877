// TODO It looks like our Header component is not that usefull anymore.  We
//       should create new one for pages with arrow button, exite button and
//       other repeated in application scenarios that are currentyl created by
//       hand in every page.
import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { Checkbox } from '..';
import './index.scss';

interface HeaderProps {
  title?: string;
  subheader?: boolean;
  subtitle?: string;
  isUnderline?: boolean;
  installationLogoTitleHeight?: boolean;
  column?: boolean;
  className?: string;
  titleClassName?: string;
  checkboxValue?: string;
  checkbox?: boolean;
  checked?: boolean;
  center?: boolean;
  handleToggle?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  subheader = false,
  subtitle,
  children,
  isUnderline,
  installationLogoTitleHeight,
  titleClassName,
  column = false,
  className,
  checkbox = false,
  checkboxValue,
  checked = false,
  center = false,
  handleToggle,
}) => {
  const titleClassNames = classNames('header__title-main', {
    'header__title-main--with-subtitle': subheader,
    'header__title-main--fixed-height': installationLogoTitleHeight,
    [titleClassName as string]: titleClassName,
  });

  return (
    <>
      <div className={classNames('header', { column: column, center: center, [className as string]: className })}>
        {title && (
          <hgroup className={classNames('header__title', { center: center })}>
            {subheader ? <h3 className={titleClassNames}>{title}</h3> : <h2 className={titleClassNames}>{title}</h2>}
            {subtitle && <h5 className="header__title-subtitle">{subtitle}</h5>}
            {checkbox && <Checkbox id={checkboxValue} checked={checked} onChange={handleToggle} />}
          </hgroup>
        )}
        {children && <div className="header__children">{children}</div>}
      </div>
      {isUnderline && <hr className="m-b-24" />}
    </>
  );
};
