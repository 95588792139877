import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowButton from '../../../../../../components/arrow-button';
import ActionTasksVariants from '../../../../../action-create/components/task-variants';
import { getChannelType } from '../../../../utils';
import { useChannels } from '../../../channel-list/hooks/use-channels';
import { useActionTaskVariants } from '../../hooks/use-action-task-variants';
import { actionTaskVariants } from '../../utils/action-tasks';

const ExalusActionTasksVariants: React.FC = () => {
  const { t } = useTranslation('action');
  const { selectTaskVariant } = useActionTaskVariants();
  const { channels } = useChannels();

  const filteresActionTasks = useMemo(() => {
    return actionTaskVariants.filter(
      (variant) => channels?.filter((ch) => variant.types.includes(getChannelType(ch))).length,
    );
  }, [actionTaskVariants, channels]);

  return (
    <ActionTasksVariants listCount={filteresActionTasks.length}>
      {filteresActionTasks.map((x) => (
        <ArrowButton
          key={x.title}
          title={t(x.title)}
          icon={x.icon}
          iconAccent
          className="m-b-24"
          onClick={() => selectTaskVariant(x.variant)}
        />
      ))}
    </ActionTasksVariants>
  );
};

export default ExalusActionTasksVariants;
