import { useContext, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useInstallation } from '../installation';
import { UserPermissionsContext } from './provider';

export const usePermissionsContext = (installationId?: string) => {
  const { userPermissions, refetchUserMe } = useContext(UserPermissionsContext);
  const { selectedInstallationId } = useInstallation();

  useEffect(() => {
    if (selectedInstallationId) refetchUserMe();
  }, [selectedInstallationId]);

  const permissions = useMemo(() => {
    let id = '';

    if (isEmpty(userPermissions)) return {};
    if (installationId) id = installationId;
    else if (selectedInstallationId) id = selectedInstallationId;

    if (!id) return {};
    return userPermissions[id] || {};
  }, [selectedInstallationId, installationId, userPermissions]);

  return {
    permissions,
  };
};
