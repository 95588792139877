import React from 'react';

export const IconMargarita: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="31"
      height="41"
      viewBox="0 0 31 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 13L22.4867 4.46664C22.8086 3.5469 23.5571 2.84082 24.4941 2.57312L30 1"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 38.75C7.58579 38.75 7.25 39.0858 7.25 39.5C7.25 39.9142 7.58579 40.25 8 40.25V38.75ZM21 40.25C21.4142 40.25 21.75 39.9142 21.75 39.5C21.75 39.0858 21.4142 38.75 21 38.75V40.25ZM1 13V12.25C0.682718 12.25 0.399763 12.4497 0.293399 12.7486C0.187035 13.0475 0.280277 13.381 0.526245 13.5814L1 13ZM27.5 13L27.9845 13.5725C28.2247 13.3692 28.3125 13.0376 28.2042 12.742C28.096 12.4465 27.8147 12.25 27.5 12.25V13ZM8 40.25H14.5V38.75H8V40.25ZM14.5 40.25H21V38.75H14.5V40.25ZM15.25 39.5V24H13.75V39.5H15.25ZM14.9738 23.4186L1.47375 12.4186L0.526245 13.5814L14.0262 24.5814L14.9738 23.4186ZM27.0155 12.4275L14.0155 23.4275L14.9845 24.5725L27.9845 13.5725L27.0155 12.4275ZM1 13.75H5.5V12.25H1V13.75ZM5.5 13.75H27.5V12.25H5.5V13.75Z"
        fill="black"
      />
      <path
        d="M16.3504 10.9909C16.5243 11.4108 16.6511 12.1121 16.7111 12.75H11.5H6.28888C6.34892 12.1121 6.47571 11.4108 6.64963 10.9909C6.91347 10.354 7.30018 9.7752 7.78769 9.28769C8.2752 8.80018 8.85395 8.41347 9.49091 8.14963C10.1279 7.8858 10.8106 7.75 11.5 7.75C12.1894 7.75 12.8721 7.8858 13.5091 8.14963C14.146 8.41347 14.7248 8.80018 15.2123 9.28769C15.6998 9.7752 16.0865 10.354 16.3504 10.9909Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};
