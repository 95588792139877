import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper, OnOffControl } from '../../../../../../../components';
import { useCentralActionSwitchControl } from '../../../hooks/use-central-action-switch-control';

interface ComponentProps {
  channels: IDeviceChannel[];
}

export const CentralActionSwitchDetailsControlBox: React.FC<ComponentProps> = ({ channels }) => {
  const { turnOn, turnOff } = useCentralActionSwitchControl(channels);

  return (
    <ControlWrapper>
      <OnOffControl actionOn={turnOn} actionOff={turnOff} />
    </ControlWrapper>
  );
};
