import React from 'react';

export const IconExalusWallMountedRGBLightController: React.FC = () => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.579102" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23257_4319)">
      <path
        d="M25.7148 17.002L25.7148 12.7659"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7139 16.999L25.7139 12.763"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1826 28.2979L15.4187 28.2979"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5371 28.2979L41.7732 28.2979"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7324 17.3154L17.7278 20.3108"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.6963 17.3154L33.7009 20.3108"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4551 43.1198C27.727 43.1198 28.758 42.2295 28.758 41.1312V39.1426M28.758 39.1426C29.3939 39.1426 29.9094 38.6974 29.9094 38.1483C29.9094 36.4598 30.3079 34.9433 30.8342 33.56C31.3913 32.0954 33.0126 28.7709 32.7053 27.2344V27.2344C31.5404 21.4098 25.7158 21.9393 25.7158 21.9393M28.758 39.1426H25.3037"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.758 39.1475V41.1361C28.758 42.2344 27.727 43.1247 26.4551 43.1247H25.3037M28.758 39.1475C29.3939 39.1475 29.9094 38.7023 29.9094 38.1532C29.9094 36.4647 30.3079 34.9482 30.8342 33.5649C31.3913 32.1003 33.0126 28.7758 32.7053 27.2392V27.2392C31.5404 21.4147 25.7158 21.9442 25.7158 21.9442M28.758 39.1475H25.3037"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7674 39.1475V41.1361C22.7674 42.2344 23.7984 43.1247 25.0702 43.1247H26.2217M22.7674 39.1475C22.1315 39.1475 21.6159 38.7023 21.6159 38.1532C21.6159 36.4647 21.2175 34.9482 20.6912 33.5649C20.1341 32.1003 18.5128 28.7758 18.8201 27.2392V27.2392C19.985 21.4147 25.8096 21.9442 25.8096 21.9442M22.7674 39.1475H26.2217"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8824 27.4574L19.6604 28.1738L19.6604 28.1738L19.8824 27.4574ZM32.3094 28.0065C32.7018 27.874 32.9126 27.4485 32.7801 27.056C32.6476 26.6636 32.2221 26.4528 31.8296 26.5853L32.3094 28.0065ZM19.1393 28.0123L19.6604 28.1738L20.1045 26.741L19.5834 26.5795L19.1393 28.0123ZM19.6604 28.1738C23.8407 29.4695 28.145 29.4121 32.3094 28.0065L31.8296 26.5853C27.9628 27.8905 23.9818 27.9429 20.1045 26.741L19.6604 28.1738Z"
        fill="#15141D"
      />
      <rect x="36" y="23.7197" width="8.5" height="2" rx="1" fill="white" />
      <rect x="24" y="32" width="21.1549" height="12.7033" rx="1.18095" fill="white" />
      <path
        d="M42.8347 33.1807H26.3196C25.6906 33.1807 25.1807 33.6906 25.1807 34.3196V35.766V36.5853C25.1807 36.9111 25.3278 37.2194 25.5811 37.4244C26.1105 37.8527 26.1158 38.6583 25.5921 39.0936L25.5762 39.1068C25.3256 39.315 25.1807 39.624 25.1807 39.9499V40.9367V42.3831C25.1807 43.0121 25.6906 43.522 26.3196 43.522H42.8347C43.4637 43.522 43.9736 43.0121 43.9736 42.3831V40.9367V40.0078C43.9736 39.7972 43.9152 39.5907 43.8049 39.4113L43.5234 38.9534C43.2879 38.5703 43.2998 38.0845 43.5539 37.7134L43.7744 37.3913C43.9042 37.2018 43.9736 36.9775 43.9736 36.7478V35.766V34.3196C43.9736 33.6906 43.4637 33.1807 42.8347 33.1807Z"
        stroke="black"
      />
      <path
        d="M30.7393 34.6572H28.1631C27.7737 34.6572 27.458 34.9729 27.458 35.3623V36.5039V37.2514C27.458 37.3857 27.4963 37.5172 27.5685 37.6304L27.7678 37.9431C27.9243 38.1887 27.9138 38.5052 27.7413 38.7398L27.5951 38.9386C27.506 39.0597 27.458 39.206 27.458 39.3563V40.1972V41.3388C27.458 41.7282 27.7737 42.0439 28.1631 42.0439H30.7393C31.1287 42.0439 31.4444 41.7282 31.4444 41.3388V40.1972V39.3037V38.3506V37.457V36.5039V35.3623C31.4444 34.9729 31.1287 34.6572 30.7393 34.6572Z"
        stroke="black"
      />
      <path
        d="M38.414 34.6572H40.9902C41.3796 34.6572 41.6953 34.9729 41.6953 35.3623V36.5039V37.2514C41.6953 37.3857 41.657 37.5172 41.5848 37.6304L41.3855 37.9431C41.229 38.1887 41.2395 38.5052 41.4121 38.7398L41.5583 38.9386C41.6473 39.0597 41.6953 39.206 41.6953 39.3563V40.1972V41.3388C41.6953 41.7282 41.3796 42.0439 40.9902 42.0439H38.414C38.0246 42.0439 37.7089 41.7282 37.7089 41.3388V40.1972V39.3037V38.3506V37.457V36.5039V35.3623C37.7089 34.9729 38.0246 34.6572 38.414 34.6572Z"
        stroke="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_23257_4319">
        <rect width="35.2441" height="35.2441" fill="white" transform="translate(10 10.5791)" />
      </clipPath>
    </defs>
  </svg>
);
