import {
  IDevicesSearchScreenView,
  NativeBluetoothDeviceInfo,
  NativeEvent,
  NativeTypedEvent,
} from 'lavva.webbluetooth';

export class FindWebBluetoothDevicesView implements IDevicesSearchScreenView {
  public OnSelectionCancelled: NativeEvent = new NativeEvent();
  public onDeviceSelected: NativeTypedEvent<NativeBluetoothDeviceInfo> =
    new NativeTypedEvent<NativeBluetoothDeviceInfo>('FindWebBluetoothDevicesView.onDeviceSelected');

  public AddFoundDevice(device: NativeBluetoothDeviceInfo) {
    console.log(device);
    /// this method is called when internal API finds a new device
  }

  public UpdateFoundDevice(device: NativeBluetoothDeviceInfo) {
    console.log(device);
    /// this method is called when internal API finds a new device
  }

  public async StoppedScanning() {
    // this method is called when internal API stops scanning for devices
  }

  public async SelectionCancelled() {
    // this method is called when internal API stops scanning for devices
  }

  public async StartedScanning(scanTime: number) {
    console.log(scanTime);
    // this method is called when internal API start scanning for devices
    // the scanTime value tells us how long the internal API will be scanning for devices
    // this can be used to create a progress bar taking a scanTime as a delay time when the search will be completed
  }
}
