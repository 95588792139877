import React from 'react';

export const IconExalusLightBrightnessSensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.869141" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M35.3271 27.5725C37.4708 27.0666 38.7984 24.9186 38.2925 22.7749C37.7865 20.6312 35.6385 19.3035 33.4948 19.8095C31.3511 20.3154 30.0234 22.4634 30.5294 24.6071C31.0354 26.7508 33.1833 28.0785 35.3271 27.5725Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.4102 17.5392V15.2803"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.7598 19.3409L40.3567 17.7432"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5625 23.6914H42.8207"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.7598 28.0391L40.3567 29.636"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M34.4102 29.8428V32.101" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M30.0606 28.0391L28.4629 29.636"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M28.259 23.6914H26" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M30.0606 19.3409L28.4629 17.7432"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.6344 32.6475C19.3911 27.4042 18.4246 19.5043 21.735 13.2803C20.329 14.0277 19.007 14.9942 17.8216 16.1796C11.3928 22.6084 11.3928 33.0315 17.8216 39.4587C24.2503 45.8875 34.6735 45.8875 41.1006 39.4587C42.2861 38.2732 43.251 36.9529 44 35.5453C37.7775 38.8557 29.8761 37.8892 24.6328 32.6459L24.6344 32.6475Z"
      fill="white"
      stroke="#15141D"
      strokeWidth="1.58537"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
