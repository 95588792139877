import React from 'react';

export const IconInstallation14: React.FC = () => (
  <svg
    className="icon-installation"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M13.4587 20.5835H3.16699L3.16699 34.8335L36.417 34.8335V20.5835L26.1253 20.5835" stroke="#15141D" />
    <path
      d="M14.25 10.2917C14.25 7.23109 16.7311 4.75 19.7917 4.75V4.75C22.8522 4.75 25.3333 7.23109 25.3333 10.2917L25.3333 34.8333H14.25L14.25 10.2917Z"
      stroke="#15141D"
    />
    <path d="M3.16699 22.9582L3.16699 17.4165" stroke="#15141D" />
    <path d="M36.417 22.9582V17.4165" stroke="#15141D" />
    <path d="M19.792 15.0418V11.0835" stroke="#15141D" />
    <path d="M19.792 26.1248V22.1665" stroke="#15141D" />
  </svg>
);
