import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './index.scss';

export const BackdropSuspense: React.FC = () => {
  return (
    <>
      <Backdrop classes={{ root: 'backdrop' }} open>
        <CircularProgress color="inherit" size={70} />
      </Backdrop>
    </>
  );
};
