import React from 'react';
import { useHistory } from 'react-router-dom';
import { SupportedDevice } from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { ROUTES } from '../../../../../../routes';
import './index.scss';

interface ComponentProps {
  device: SupportedDevice;
}

export const MoreListItem: React.FC<ComponentProps> = ({ device }) => {
  const history = useHistory();

  return (
    <div className="more-list-item" onClick={() => history.push(ROUTES.ExalusStoreDeviceDetails(device.ModelGuid))}>
      <div className="image">
        {device.Pictures.length && <img src={device.Pictures[0]} alt="product image" width="100%" height="100%" />}
      </div>
      <p className="name">{device.Name}</p>
      <p className="model-name">{device.ModelName}</p>
    </div>
  );
};
