import {
  ComfortPositionParameters,
  InputOutputBlindParameters,
  StateOfTurnOnBlindDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';

export enum SrpParamsEnum {
  COMFORT_POSITION = 'ComfortPosition',
  INPUTS_MODE = 'InputsMode',
  TURN_ON_BEHAVIOUR = 'TurnOnBehaviour',
}

export interface SrpConfigParams {
  [SrpParamsEnum.COMFORT_POSITION]: ComfortPositionParameters | null;
  [SrpParamsEnum.INPUTS_MODE]: InputOutputBlindParameters | null;
  [SrpParamsEnum.TURN_ON_BEHAVIOUR]: StateOfTurnOnBlindDeviceParameters | null;
}

export const initialConfig: SrpConfigParams = {
  [SrpParamsEnum.COMFORT_POSITION]: null,
  [SrpParamsEnum.INPUTS_MODE]: null,
  [SrpParamsEnum.TURN_ON_BEHAVIOUR]: null,
};
