import React from 'react';

export const RgbSceneIcon0: React.FC = () => {
  return (
    <svg
      className="icon-rgb-scene"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0428 2.705C20.5245 2.16446 19.909 1.73567 19.2316 1.44312C18.5542 1.15058 17.8282 1 17.0949 1C16.3617 1 15.6356 1.15058 14.9582 1.44312C14.2808 1.73567 13.6654 2.16446 13.147 2.705L12.0712 3.82627L10.9954 2.705C9.9484 1.61367 8.52829 1.00057 7.04754 1.00057C5.56679 1.00057 4.14668 1.61367 3.09963 2.705C2.05258 3.79633 1.46436 5.27649 1.46436 6.81986C1.46436 8.36323 2.05258 9.84339 3.09963 10.9347L4.17541 12.056L12.0712 20.2857L19.967 12.056L21.0428 10.9347C21.5614 10.3944 21.9728 9.75295 22.2535 9.04691C22.5342 8.34087 22.6786 7.58411 22.6786 6.81986C22.6786 6.05561 22.5342 5.29884 22.2535 4.5928C21.9728 3.88676 21.5614 3.24528 21.0428 2.705V2.705Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
