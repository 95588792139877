import React from 'react';
import ActionCreateNameIconForm from '../../components/name-icon-form';
import { useLavvaCreateActionContext } from '../../context';
import { useActionCreate } from '../../hooks/use-action-create';
import { useActionEdit } from '../../hooks/use-action-edit';

const LavvaActionCreate: React.FC = () => {
  const { onSubmitNameIcon } = useActionCreate();
  const { name, icon } = useLavvaCreateActionContext();
  useActionEdit();

  return <ActionCreateNameIconForm onSubmit={onSubmitNameIcon} name={name} icon={icon} />;
};

export default LavvaActionCreate;
