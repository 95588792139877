import React from 'react';

export const IconExalusWallMountedDimmer: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.290039" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23111_32363)">
      <path
        d="M26.0879 15.4199L26.0879 11.4199"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0879 15.4199L26.0879 11.4199"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4209 26.0869L15.4209 26.0869"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7168 15.7158L18.5452 18.5442"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.458 15.7158L33.6296 18.5442"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7855 40.0863C27.9865 40.0863 28.9601 39.2456 28.9601 38.2085V36.3307M28.9601 36.3307C29.5605 36.3307 30.0473 35.9103 30.0473 35.3918C30.0473 33.7974 30.4236 32.3655 30.9205 31.0592C31.4466 29.6762 32.9775 26.537 32.6874 25.0861V25.0861C31.5874 19.5861 26.0874 20.0861 26.0874 20.0861M28.9601 36.3307H25.6982"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9601 36.3317V38.2095C28.9601 39.2465 27.9865 40.0873 26.7855 40.0873H25.6982M28.9601 36.3317C29.5605 36.3317 30.0473 35.9113 30.0473 35.3928C30.0473 33.7984 30.4236 32.3664 30.9205 31.0602C31.4466 29.6772 32.9775 26.538 32.6874 25.087V25.087C31.5874 19.587 26.0874 20.087 26.0874 20.087M28.9601 36.3317H25.6982"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3036 36.3317V38.2095C23.3036 39.2465 24.2772 40.0873 25.4782 40.0873H26.5654M23.3036 36.3317C22.7031 36.3317 22.2164 35.9113 22.2164 35.3928C22.2164 33.7984 21.8401 32.3664 21.3432 31.0602C20.8171 29.6772 19.2861 26.538 19.5763 25.087V25.087C20.6763 19.587 26.1763 20.087 26.1763 20.087M23.3036 36.3317H26.5654"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5799 26.2394L20.3579 26.9558L20.3579 26.9558L20.5799 26.2394ZM32.3277 26.7975C32.7202 26.6651 32.931 26.2395 32.7985 25.8471C32.666 25.4546 32.2405 25.2438 31.848 25.3763L32.3277 26.7975ZM19.8658 26.8033L20.3579 26.9558L20.802 25.5231L20.3099 25.3705L19.8658 26.8033ZM20.3579 26.9558C24.3137 28.182 28.3872 28.1276 32.3277 26.7975L31.848 25.3763C28.205 26.606 24.4548 26.6553 20.802 25.5231L20.3579 26.9558Z"
        fill="#15141D"
      />
      <path
        d="M43.0859 30.7549L39.587 27.2559L36.0881 30.7549"
        stroke="#15141D"
        strokeWidth="1.37931"
        strokeLinecap="round"
      />
      <path
        d="M36.0879 35.7549L39.5868 39.2538L43.0858 35.7549"
        stroke="#15141D"
        strokeWidth="1.37931"
        strokeLinecap="round"
      />
      <path d="M39.6992 27.5877V38.9766" stroke="#15141D" strokeWidth="1.37931" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_23111_32363">
        <rect width="38" height="38" fill="white" transform="translate(9.4209 9.41992)" />
      </clipPath>
    </defs>
  </svg>
);
