import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconLightSwitch: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-switch icon-light-switch"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isOn ? (
        <path
          d="M14.9945 23.2398L15.5324 17.1153L15.5324 7.43457L23.0618 7.43457L23.0618 17.1153L23.5996 23.2398M14.9945 23.2398H23.5996M14.9945 23.2398L15.9364 27.3338L22.7648 27.3338L23.5996 23.2398"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M14.9945 11.5287L15.5324 17.6533V27.334H23.0618V17.6533L23.5996 11.5287M14.9945 11.5287H23.5996M14.9945 11.5287L15.9364 7.43475H22.7648L23.5996 11.5287"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <path d="M33.25 33.25H6.33333V1.58333H33.25V33.25Z" stroke="#15141D" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
};
