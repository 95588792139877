import React, { createContext, useState } from 'react';
import { DeviceInterface, ChannelInterface, DevicesAndChannelsStateInterface } from '../../types';

const initialState: DevicesAndChannelsStateInterface = {
  deviceList: [],
  setDeviceList: () => null,
  channelList: [],
  setChannelList: () => null,
};

export const DevicesAndChannelsContext = createContext(initialState);

const DevicesAndChannelsProvider: React.FC = ({ children }) => {
  const [deviceList, setDeviceList] = useState<DeviceInterface[]>(initialState.deviceList);
  const [channelList, setChannelList] = useState<ChannelInterface[]>(initialState.channelList);

  const values: DevicesAndChannelsStateInterface = {
    deviceList,
    setDeviceList,
    channelList,
    setChannelList,
  };

  return <DevicesAndChannelsContext.Provider value={values}>{children}</DevicesAndChannelsContext.Provider>;
};

export default DevicesAndChannelsProvider;
