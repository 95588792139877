import { AvailableIcon, InstallationIconTypeEnum } from '../../types';
import {
  IconInstallation0,
  IconInstallation1,
  IconInstallation10,
  IconInstallation11,
  IconInstallation12,
  IconInstallation13,
  IconInstallation14,
  IconInstallation2,
  IconInstallation3,
  IconInstallation4,
  IconInstallation5,
  IconInstallation6,
  IconInstallation7,
  IconInstallation8,
  IconInstallation9,
} from '../icons/installation';

export const availableInstallationIcons: AvailableIcon[] = [
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_0,
    component: <IconInstallation0 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_1,
    component: <IconInstallation1 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_2,
    component: <IconInstallation2 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_3,
    component: <IconInstallation3 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_4,
    component: <IconInstallation4 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_5,
    component: <IconInstallation5 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_6,
    component: <IconInstallation6 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_7,
    component: <IconInstallation7 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_8,
    component: <IconInstallation8 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_9,
    component: <IconInstallation9 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_10,
    component: <IconInstallation10 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_11,
    component: <IconInstallation11 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_12,
    component: <IconInstallation12 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_13,
    component: <IconInstallation13 />,
  },
  {
    iconName: InstallationIconTypeEnum.INSTALLATION_14,
    component: <IconInstallation14 />,
  },
];
