import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useOptimizerSetConfig, useOptimizerSetMode } from '../../../../../api/modules/optimizer/optimizer.hooks';
import { OptimizerMode } from '../../../../../api/modules/optimizer/optimizer.types';
import { OptimizerModeInternal } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../hooks';
import { ChannelInterface } from '../../../../../types';
import { convertErrorsToOneString, getEnumKeyByValue } from '../../../../../utils/helpers';
import { toastError, toastSuccess } from '../../../../../utils/toast';
import { LimitConsumptionFormBody } from '../types';
import {
  prepareHoursRangesToSend,
  prepareOperatorAndTariffForRequest,
  prepareRelaysToSend,
  prepareWorkingHoursToSend,
} from '../utils';
import { useOptimizerErrors } from './use-optimizer-errors';

export const useOptimizerLimitConsumption = (channel: ChannelInterface) => {
  const { t } = useTranslation('channel-details');
  const { mutate: setMode } = useOptimizerSetMode();
  const { mutate: setConfig } = useOptimizerSetConfig();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleOptimizerErrors } = useOptimizerErrors();

  const submitConfiguration = (mode: OptimizerModeInternal, formValues: LimitConsumptionFormBody) => {
    const {
      tempMinEnabled,
      tempMax,
      tempMin,
      tempSelfConsumption,
      heaterPowerLimit,
      powerLimit,
      voltageThreshold1,
      voltageThreshold2,
      voltageThreshold3,
      relayConfig1,
      relayConfig2,
      relayConfig3,
      relayConfig4,
      from,
      to,
      wholeWeekendCheaper,
      wholeHolidaysCheaper,
      ignoreSummerTimeChanges,
      hoursRanges,
      operator,
      tariffName,
    } = formValues;

    const errors = [];
    if (tempMinEnabled && tempMin >= tempMax) {
      errors.push(t('optimizer.configuration.errors.minTempHasToBeLowerThanMax'));
    }
    if (from >= to) errors.push(t('optimizer.configuration.errors.fromHasToBeLowerThanTo'));
    if (errors.length > 0) {
      toastError({ content: convertErrorsToOneString(errors) });
      return;
    }

    turnOnBackdrop();
    setMode(
      {
        deviceId: channel.deviceId,
        channelId: channel.id,
        mode: OptimizerMode[getEnumKeyByValue(OptimizerModeInternal, mode)],
      },
      {
        onSuccess: ({ data }) => {
          if (data) {
            turnOnBackdrop();

            setConfig(
              {
                deviceId: channel.deviceId,
                channelId: channel.id,
                selfConsumptionWithPowerLimit: {
                  tempMinEnabled,
                  tempMax,
                  tempMin,
                  tempSelfConsumption,
                  heaterPowerLimit,
                  powerLimit,
                  voltageThreshold: [
                    { index: 1, value: voltageThreshold1 },
                    { index: 2, value: voltageThreshold2 },
                    { index: 3, value: voltageThreshold3 },
                  ],
                  relaysConfig: prepareRelaysToSend({ relayConfig1, relayConfig2, relayConfig3, relayConfig4 }),
                  workingHours: prepareWorkingHoursToSend(from, to),
                  ...(tempMinEnabled
                    ? {
                        tariff: {
                          wholeWeekendCheaper,
                          wholeHolidaysCheaper,
                          ignoreSummerTimeChanges,
                          hoursRanges: prepareHoursRangesToSend(hoursRanges),
                          kind: prepareOperatorAndTariffForRequest(operator, tariffName),
                        },
                      }
                    : {}),
                },
              },
              {
                onSuccess: () => {
                  turnOffBackdrop();
                  toastSuccess({ content: t('optimizer.configuration.configurationSaved') });
                },
                onError: (error: AxiosError) => {
                  handleOptimizerErrors(error);
                },
              },
            );
          } else {
            turnOffBackdrop();
          }
        },
      },
    );
  };

  return {
    submitConfiguration,
  };
};
