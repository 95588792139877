import React from 'react';

export const IconTableChairs: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-table-chairs"
  >
    <g clipPath="url(#clip0_24261_27468)">
      <path
        d="M9.2832 12.7363H28.7233"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0039 12.7363V29.9328"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7598 29.9328H21.2452"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.789 22.4583H35.2563V20.2146H24.789C24.789 20.2146 24.7871 21.2197 24.7871 22.4583H24.789Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4727 22.4583V29.9234"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5723 22.4583V29.9234"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5007 9C36.7579 10.2071 35.6872 12.8297 35.6872 12.8297C34.9024 15.0958 34.5098 17.5583 34.5098 20.2165"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.211 22.4583H2.7437V20.2146H13.211C13.211 20.2146 13.2129 21.2197 13.2129 22.4583H13.211Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5293 22.4583V29.9234"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42969 22.4583V29.9234"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.499305 9C1.2421 10.2071 2.31278 12.8297 2.31278 12.8297C3.0976 15.0958 3.49023 17.5583 3.49023 20.2165"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_24261_27468">
        <rect width="38" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
