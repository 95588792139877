import React, { useEffect, useState } from 'react';
import * as uuid from 'uuid';
import { OnOffStateTask } from '../../../../../exalus/pages/action-create/types';
import { OnOffChannelSettings } from '../../../../components/channel-settings/on-off';
import { useLavvaCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, SceneTaskType, Task } from '../../../../types';

export const OnOffSettings: React.FC = () => {
  const { channels, taskVariant } = useLavvaCreateActionContext();
  const [isOn, setIsOn] = useState<boolean>(false);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      setIsOn(((task.taskParams as DeviceTaskParams).state as OnOffStateTask).isOn);
    }
  }, [task]);

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task: Task = {
        id: uuid.v4(),
        taskType: SceneTaskType.ChannelState,
        taskParams: {
          state: {
            isOn,
          } as OnOffStateTask,
          channel: channels[i],
          variant: taskVariant,
        } as DeviceTaskParams,
      };

      finalizeDeviceTaskSubmit(task, i === channels.length - 1);
    }
  };

  return <OnOffChannelSettings isOn={isOn} handleSubmit={handleSubmit} setIsOn={setIsOn} />;
};
