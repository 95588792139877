import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, NavHeader } from '../../../components';

export const GroupSortHeader: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('groups');

  return (
    <>
      <NavHeader isIconClose onClick={history.goBack} />
      <Header title={t('sort')} />
    </>
  );
};
