import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconThunder: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-thunder"
      width="29"
      height="34"
      viewBox="0 0 29 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33398 1.71429L28.2506 1.71429L28.2506 17.0952V32.4762H14.7923H1.33398L1.33398 1.71429Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66602 18.7521L15.1509 6.91669V15.15H20.3327L12.8478 27.5L13.4236 18.7521H7.66602Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {isOn ? (
        <path d="M19 24.5L20.8333 21" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      ) : null}
    </svg>
  );
};
