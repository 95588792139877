import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as uuid from 'uuid';
import { SubmitButton } from '../../../../components';
import HeaderWithHelp from '../../../../components/header-with-help';
import { InstallationAccessType, IntegrationType, UserInstallation } from '../../../../data-access/gql-types/graphql';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { isLocalApp } from '../../../../utils/helpers/local-app';
import { getParameterByName } from '../../../../utils/location';
import { toastError } from '../../../../utils/toast';
import { useCreateInstallationContext } from '../../../installation-create/context';
import { useCreateInstallation } from '../../../installation-create/hooks/use-create-installation';
import { useExalusContext } from '../../context';
import { useExalusConnection } from '../../hooks';
import { useExalusRemoteStorage } from '../../hooks/storage.hooks';
import { Email } from '../integration-login/components/email';
import { Password } from '../integration-login/components/password';
import { LoginForm } from '../integration-login/types';

const ExalusLogin: React.FC = () => {
  const { t } = useTranslation('installation');
  const history = useHistory();
  const { hardRedirect, selectInstallation } = useInstallation();
  const { setSelectedExalusCredentials, setConnected, setConnectionExists } = useExalusContext();
  const { connect } = useExalusConnection();
  const serialNumber = getParameterByName('serialNumber');
  const pin = getParameterByName('pin');
  const installationId = getParameterByName('installationId');
  const { finishCreatingInstallation } = useCreateInstallation();
  const { setSelectedInstallationId } = useInstallation();
  const { state } = useCreateInstallationContext();
  const { readExalusDataStorage } = useExalusRemoteStorage();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const form = useForm<LoginForm>({
    mode: 'onChange',
    defaultValues: {
      email: 'installator@installator',
      password: serialNumber && pin ? `${serialNumber}${pin}` : '',
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    if (values.email && values.password) {
      const localInstallationId = uuid.v4();

      turnOnBackdrop();
      const credentials = {
        id: !isLocalApp ? installationId : installationId || localInstallationId,
        ...values,
        serialNumber: serialNumber || '',
        pin: pin || '',
      };

      const res = await connect(credentials);
      setConnected(res);
      setConnectionExists(res);

      if (res) {
        if (!isLocalApp) {
          selectInstallation(installationId, () => {
            setSelectedExalusCredentials(credentials);
            turnOffBackdrop();

            hardRedirect ? (window.location.href = ROUTES.Root()) : history.push(ROUTES.Root());
          });
        } else {
          setSelectedExalusCredentials(credentials);

          if (!installationId) {
            const iconName: string | undefined = await readExalusDataStorage('iconName');
            const hexColor: string | undefined = await readExalusDataStorage('hexColor');
            const name: string | undefined = await readExalusDataStorage('name');

            finishCreatingInstallation(
              {
                accessType: InstallationAccessType.Owner,
                createdAt: new Date(),
                externalPayload: {
                  installationId: localInstallationId,
                  pin: state.exalusCredentials.pin,
                  serialNumber: state.exalusCredentials.serialNumber,
                },
                hexColor: hexColor || state.hexColor,
                iconName: iconName || state.iconName,
                installationId: localInstallationId,
                integrationType: IntegrationType.Exalus,
                isSelected: true,
                name: name || state.installationName,
                order: 1,
                partialAccess: false,
                userId: '',
              } as UserInstallation,
              values,
            );
          } else {
            setSelectedInstallationId(installationId);
            turnOffBackdrop();
            history.push(ROUTES.Root());
          }
        }
      }
    } else {
      toastError({ content: t('errors.somethingWentWrong') });
    }
  });

  return (
    <HeaderWithHelp
      kind="main"
      name="integration-login"
      headerTitle={t('exalus.integration-login.header')}
      {...(serialNumber && pin ? { headerSubtitle: t('exalus.integration-login.subheader') } : {})}
      helpTitle={t('exalus.integration-login.helpLogin.title')}
      helpContent={t('exalus.integration-login.helpLogin.content')}
      handleClose={() => history.goBack()}
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Email />
          <Password />
          <SubmitButton>{t('exalus.login')}</SubmitButton>
        </form>
      </FormProvider>
    </HeaderWithHelp>
  );
};

export default ExalusLogin;
