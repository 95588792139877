import React from 'react';

export const IconMarker: React.FC = () => (
  <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.37504 1C6.48063 1 4.66382 1.75255 3.32427 3.09209C1.98473 4.43164 1.23218 6.24845 1.23218 8.14286C1.23218 12.4897 3.93148 16.2967 6.98338 19.5638C7.73739 20.371 9.01269 20.3711 9.77462 19.5714C12.8233 16.3716 15.5179 12.7962 15.5179 8.14286C15.5179 7.20484 15.3331 6.27602 14.9742 5.4094C14.6152 4.54279 14.0891 3.75537 13.4258 3.09209C12.7625 2.42882 11.9751 1.90268 11.1085 1.54372C10.2419 1.18476 9.31305 1 8.37504 1Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <circle cx="8.37508" cy="8.1429" r="3.33333" stroke="#15141D" strokeWidth="1.5" strokeLinejoin="bevel" />
  </svg>
);
