import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Input, InputEdit } from '../../../../components';
import {
  ChangeInstallationNameMutation,
  ChangeInstallationNameMutationVariables,
  InstallationModelResponse,
  UserInstallation,
} from '../../../../data-access/gql-types/graphql';
import { CHANGE_INSTALLATION_NAME } from '../../../../data-access/mutations/installations';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { useInstallationErrors } from '../../../../hooks/backend-errors/use-installation-errors';
import { isLocalApp } from '../../../../utils/helpers/local-app';
import { toastSuccess } from '../../../../utils/toast';
import { patterns } from '../../../../utils/validation';
import { CreateInstallationControlForm } from '../../types';

interface ComponentProps {
  disabled?: boolean;
  setDisabled?: Dispatch<SetStateAction<boolean>>;
  updateInstallation?: (key: keyof UserInstallation, value: string | InstallationModelResponse) => void;
}

const InstallationName: React.FC<ComponentProps> = ({ disabled = false, setDisabled, updateInstallation }) => {
  const { control, getValues } = useFormContext<CreateInstallationControlForm>();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId, selectedInstallation } = useInstallation();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useInstallationErrors();
  const [changeInstallationName] = useMutation<ChangeInstallationNameMutation, ChangeInstallationNameMutationVariables>(
    CHANGE_INSTALLATION_NAME,
  );

  const onSaveName = useCallback(() => {
    const name = getValues('installation_name');

    if (updateInstallation && selectedInstallation?.name !== name) {
      if (!isLocalApp) {
        turnOnBackdrop();
        changeInstallationName({
          variables: {
            input: {
              installationId: selectedInstallationId,
              name,
            },
          },
          onCompleted: (data) => {
            turnOffBackdrop();
            if (data.changeInstallationName.result?.ok) {
              updateInstallation('name', name);
              toastSuccess({ content: t('nameEditSuccess') });
              if (setDisabled) setDisabled(true);
            } else {
              handleErrors(data.changeInstallationName.errors || []);
            }
          },
          onError: () => turnOffBackdrop(),
        });
      } else {
        updateInstallation('name', name);
        toastSuccess({ content: t('nameEditSuccess') });
        if (setDisabled) setDisabled(true);
      }
    } else {
      if (setDisabled) setDisabled(true);
    }
  }, [selectedInstallationId, selectedInstallation, setDisabled]);

  return (
    <Controller
      name="installation_name"
      control={control}
      rules={{
        required: {
          value: true,
          message: tc('isRequired'),
        },
        pattern: {
          value: patterns.name,
          message: tc('invalidName'),
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return !setDisabled ? (
          <Input
            defaultValue={field.value}
            placeholder={t('namePlaceholder')}
            onChange={field.onChange}
            label={t('name')}
            {...(error ? { errorMessage: error.message, hasError: true } : {})}
          />
        ) : (
          <InputEdit
            disabled={disabled}
            defaultValue={field.value}
            onChange={field.onChange}
            setDisabled={setDisabled}
            onSave={onSaveName}
            label={t('name')}
            {...(error ? { errorMessage: error.message } : {})}
          />
        );
      }}
    />
  );
};

export default InstallationName;
