import { gql } from '@apollo/client';

export const ON_NOTIFIED = gql`
  subscription OnNotified {
    onNotified {
      correlationId
      code
      wasDisplayed
      isHidden
      createdAt
      params {
        name
        value
      }
    }
  }
`;
