const LegendCustom = (props) => {
  const { payload } = props;

  return (
    <ul className="legend-custom">
      {payload.map((entry, index) => (
        <li key={`item-${index}`}>
          <div className="dot" style={{ backgroundColor: entry.color }} />
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

export default LegendCustom;
