import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const useFetchDocuments = (type: string) => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    const lang = new URLSearchParams(search).get('lang');

    fetch(`/documents/${type}/${lang || i18n.language}.md`)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [search]);

  return { content };
};
