import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UpdateStatus } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { LinearProgress } from '@mui/material';
import { convertSecondsToMinutes } from '../../../../utils/helpers';
import './index.scss';

interface ComponentProps {
  updateName: string;
  isCorrectLoader: boolean;
  progress?: number;
  status?: UpdateStatus;
  speedMbps?: number;
}

const LinearProgressCustom: React.FC<ComponentProps> = ({
  progress,
  isCorrectLoader,
  status,
  updateName,
  speedMbps,
}) => {
  const { t } = useTranslation('device-info');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [time, setTime] = useState<number>(0);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [currentStatus, setCurrentStatus] = useState<UpdateStatus | null>(null);

  useEffect(() => {
    if (isCorrectLoader) {
      intervalRef.current = setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);
    }
  }, [isCorrectLoader]);

  useEffect(() => {
    if (isCorrectLoader && progress) setCurrentProgress(progress);
  }, [isCorrectLoader, progress]);

  useEffect(() => {
    if (isCorrectLoader && status) setCurrentStatus(status);
  }, [isCorrectLoader, status]);

  useEffect(() => {
    if (
      isCorrectLoader &&
      currentStatus &&
      intervalRef.current !== null &&
      (currentStatus < 0 || currentStatus === UpdateStatus.Upgraded)
    ) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [isCorrectLoader, currentStatus]);

  const isSuccess = useMemo(() => currentStatus === UpdateStatus.Upgraded, [currentStatus]);
  const isError = useMemo(() => currentStatus && currentStatus < 0, [currentStatus]);

  return (
    <div className="linear-progress">
      <p className="update-name">{updateName}</p>
      <div className="linear-progress--container">
        <p>{`${t('exalus.params.Version.durationTime')}: ${convertSecondsToMinutes(time)}`}</p>
        <p className={classNames('percentage', { success: isSuccess, error: isError })}>{`${currentProgress}%`}</p>
      </div>
      <LinearProgress
        className={classNames('linear-progress--line', {
          success: isSuccess,
          error: isError,
        })}
        variant="determinate"
        value={currentProgress}
      />
      {currentStatus && <p
        className={classNames('status-message', {
          success: isSuccess,
          error: isError,
        })}
      >
        {currentStatus && `${t(`exalus.updateResult.${UpdateStatus[currentStatus]}`)}`}
      </p>}
      {speedMbps !== undefined && <p className="speedMbps">{`${speedMbps} Mb/s`}</p>}
    </div>
  );
};

export default LinearProgressCustom;
