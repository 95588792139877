import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ChannelTypeInternal, GateModeInternal, LightModeTypeInternal } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { ChannelLightInterface } from '../../../types';
import { ChannelGateInterface } from '../../../types/channel/gate';
import { useLavvaCreateActionContext } from '../context';
import { TaskVariant } from '../types';

export const useActionTaskVariants = () => {
  const history = useHistory();
  const { channelList } = useDevicesAndChannels();
  const { taskVariant, setTaskVariant, name } = useLavvaCreateActionContext();

  useEffect(() => {
    if (!name) history.replace(ROUTES.ActionList());
  }, []);

  const selectTaskVariant = (taskVariant: TaskVariant) => {
    setTaskVariant(taskVariant);
    history.push(ROUTES.DeviceVariants());
  };

  const getChannelsByVariant = (v?: TaskVariant) => {
    const all = [];

    switch (v || taskVariant) {
      case TaskVariant.OnOff: {
        return channelList.filter(
          (x) => x.data.type === ChannelTypeInternal.Switch || x.data.type === ChannelTypeInternal.Light,
        );
      }
      case TaskVariant.Dim: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Light &&
            (x.data as ChannelLightInterface).supportedModes.includes(LightModeTypeInternal.Brightness),
        );
      }
      case TaskVariant.SetRGBW: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Light &&
            (x.data as ChannelLightInterface).supportedModes.includes(LightModeTypeInternal.Colors),
        );
      }
      case TaskVariant.SetBlind: {
        return channelList.filter((x) => x.data.type === ChannelTypeInternal.Blind);
      }
      case TaskVariant.SetGate: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Gate &&
            (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp,
        );
      }
      case TaskVariant.SetVentilation: {
        return channelList.filter(
          (x) =>
            x.data.type === ChannelTypeInternal.Gate &&
            (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp,
        );
      }

      default: {
        return all;
      }
    }
  };

  return {
    selectTaskVariant,
    getChannelsByVariant,
  };
};
