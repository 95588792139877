import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceStateComparisonMethod } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { InputSelect } from '../../../../../../components';
import { useExalusComparisonContext } from '../../context/comparison';
import { useActionParameterOptions } from '../../utils/use-action-parameter-options';
import { InputString } from '../input-string';
import { TimeInput } from '../time-input';
import './index.scss';

interface ComponentProps {
  unit?: string;
  step?: string;
  maxPercent?: number;
  valueParamLabel?: string;
}

export const ComparisonMethodSelect: React.FC<ComponentProps> = ({ unit, step, maxPercent, valueParamLabel }) => {
  const { t } = useTranslation('action');
  const { comparisonMethod, setComparisonMethod, days, setDays, timespan, setTimeSpan, paramValue, setParamValue } =
    useExalusComparisonContext();
  const { comparisonMethodsOptions } = useActionParameterOptions();

  const onChangeComparisonMethod = (method: DeviceStateComparisonMethod) => {
    setComparisonMethod(method);
  };

  return (
    <div className="comparison-select">
      <InputSelect
        options={comparisonMethodsOptions}
        value={comparisonMethod}
        onChange={onChangeComparisonMethod}
        label={t('action.create.conditions.comparisonMethod.label')}
      />
      {comparisonMethod !== DeviceStateComparisonMethod.Default && (
        <>
          <div className="row-container">
            <TimeInput
              label={t(
                `action.create.conditions.comparisonMethod.${
                  comparisonMethod === DeviceStateComparisonMethod.Delta ? 'deltaTimeRange' : 'repeatTimeRange'
                }`,
              )}
              time={timespan}
              setTime={setTimeSpan}
            />
            <InputString
              value={days}
              setValue={setDays}
              label={t('action.create.conditions.comparisonMethod.days')}
              inputType="number"
              min={0}
            />
          </div>
          <InputString
            value={paramValue}
            setValue={setParamValue}
            {...(step ? { step } : {})}
            {...(maxPercent ? { max: maxPercent } : {})}
            label={
              valueParamLabel ||
              `${t(
                `action.create.conditions.comparisonMethod.${
                  comparisonMethod === DeviceStateComparisonMethod.Delta ? 'deltaValue' : 'repeatQuantity'
                }`,
              )} ${unit ? `(${unit})` : ''}`
            }
            inputType="number"
            percentValue={unit === '%'}
          />
        </>
      )}
    </div>
  );
};
