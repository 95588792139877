import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import {
  CopyToClipboardButton,
  CustomBackdrop,
  DialogConfirmation,
  Header,
  IconEye,
  NavHeader,
  Page,
} from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';
import { ROUTES } from '../../../../../routes';
import { ControllerQrCode } from '../../../components/controller-qr-code';
import { PopupType } from '../../../enums/controller';
import { useExalusUserImg } from '../../../hooks';
import { useLoggedUser } from '../../../hooks/common/logged-user';
import { ExalusIntegrationForm } from '../../integration-create/types';
import ExalusUserMainInfo from '../../users-list/user-details/user-data/main-info';
import { useControllerSettings } from '../hooks/use-controller-settings';
import { useImportExport } from '../hooks/use-import-export';
import { useUpdates } from '../hooks/use-updates';
import '../index.scss';

interface ComponentProps {
  setView: Dispatch<SetStateAction<string>>;
  header?: boolean;
}

const ExalusControllerMainSettings: React.FC<ComponentProps> = ({ setView, header }) => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const [showPin, setShowPin] = useState<boolean>(false);
  const [exalusQrCode, setExalusQrCode] = useState<ExalusIntegrationForm>({ serialNumber: '', pin: '' });
  const { popup, popupType, controllerInfo, loading, setPopup, showDialog, handleConfirm, fastSyncEnabled } =
    useControllerSettings();
  const { ControllerVersionButton } = useUpdates();
  const { importExportEnabled } = useImportExport();
  const { user: userExalus, isLoading: userExalusLoading, hasAccessToControllerSettings } = useLoggedUser();
  const { data: imageExalus, isLoading: imageExalusLoading } = useExalusUserImg(userExalus as IUser);

  const additionalControllerButtonsVisible = useMemo(() => {
    if (userExalus?.AccessLevel) return userExalus.AccessLevel >= 30;
    return false;
  }, [userExalus]);

  const handleRedirect = (view: string) => {
    setView(view);

    history.push(`${ROUTES.ExalusControllerSettings()}?view=${view}`);
  };

  const togglePin = () => setShowPin(!showPin);

  const handleShare = () => {
    setExalusQrCode({
      serialNumber: controllerInfo?.serialNumber || '',
      pin: controllerInfo?.pin || '',
    });
  };

  return (
    <Page
      isStickyHeader={!!header}
      className={classNames('controller-settings', { 'no-padding': !header })}
      {...(header
        ? {
            header: (
              <>
                <NavHeader />
                <Header title={t('exalus.settingsController')} isUnderline />
              </>
            ),
          }
        : {})}
    >
      <ExalusUserMainInfo user={userExalus} image={imageExalus} />
      <div className="controller-info">
        <div className="readonly-info">
          <p className="label">
            {t('exalus.serialNumber')}
            {controllerInfo?.serialNumber && <CopyToClipboardButton text={controllerInfo.serialNumber} />}
          </p>
          <p className="value">{controllerInfo?.serialNumber || '-'}</p>
        </div>
        <div className="readonly-info">
          <p className="label">
            {t('exalus.pin')}
            {controllerInfo?.pin && <CopyToClipboardButton text={controllerInfo.pin} />}
          </p>
          {showPin ? (
            <p className="value clickable" onClick={togglePin}>
              {controllerInfo?.pin || '-'}
            </p>
          ) : (
            <button onClick={togglePin} className="show_pin">
              <IconEye />
            </button>
          )}
        </div>
      </div>

      {hasAccessToControllerSettings && (
        <>
          <div className="grid-list-24">
            <ArrowButton title={t('share')} onClick={handleShare} disabled={!controllerInfo} className="m-b-24" />
          </div>
          <hr />

          <div className="grid-list-24 m-t-24">
            <ArrowButton title={t('exalus.location')} onClick={() => handleRedirect('location')} className="m-b-24" />
            <ArrowButton
              title={t('networkSettings')}
              onClick={() => history.push(ROUTES.ExalusNetworkSettings())}
              className="m-b-24"
            />
            <ArrowButton
              title={t('exalusUsers')}
              onClick={() => history.push(ROUTES.ExalusUsersList())}
              className="m-b-24"
            />
            <ArrowButton title={t('softUpdate')} onClick={() => handleRedirect('updates')} className="m-b-24" />
            <ArrowButton
              title={t('updatesSettings')}
              onClick={() => handleRedirect('updates-settings')}
              className="m-b-24"
            />
            {ControllerVersionButton}
            <ArrowButton
              title={t('exalus.syncStatesMethod')}
              onClick={() => showDialog(PopupType.StateSync)}
              className="m-b-24"
            />
            {importExportEnabled && (
              <ArrowButton
                title={t('exalus.importExport')}
                onClick={() => handleRedirect('import-export')}
                className="m-b-24"
              />
            )}
            {additionalControllerButtonsVisible && (
              <>
                <ArrowButton
                  title={t('exalus.restartController')}
                  onClick={() => showDialog(PopupType.Restart)}
                  className="m-b-24"
                />
                <ArrowButton title={t('exalus.resetController')} onClick={() => showDialog(PopupType.Reset)} />
              </>
            )}
          </div>
        </>
      )}

      <CustomBackdrop loading={userExalusLoading || imageExalusLoading} />
      <DialogConfirmation
        show={popup}
        setShow={setPopup}
        header={t(`exalus.${popupType?.toLowerCase()}DialogTitle`)}
        content={t(
          `exalus.${popupType?.toLowerCase()}${
            popupType === PopupType.StateSync ? (fastSyncEnabled ? 'Off' : 'On') : ''
          }DialogContent`,
        )}
        primaryBtnText={!loading ? t(`exalus.${popupType?.toLowerCase()}Controller`) : tc('buttons.loading')}
        secondaryBtnText={tc('buttons.cancel')}
        secondaryBtnAction={() => setPopup(false)}
        primaryBtnAction={handleConfirm}
        direction="column-reverse"
        primaryBtnDisabled={loading}
        timer={popupType === PopupType.Reset || popupType === PopupType.Restart}
        {...(popupType === PopupType.Reset &&
          controllerInfo && { confirmInput: { label: t('exalus.pin'), value: controllerInfo.pin } })}
      />
      <ControllerQrCode exalusQrCode={exalusQrCode} setShow={setExalusQrCode} />
    </Page>
  );
};

export default ExalusControllerMainSettings;
