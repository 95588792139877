import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { useInstallation } from '../../hooks';
import { useLoggedUser } from '../../modules/exalus/hooks/common/logged-user';
import { ROUTES } from '../../routes';
import { IconActions, IconDashboard, IconDeviceList, IconProfile, IconChat } from '../icons';
import './index.scss';

export const Navigation: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation('common');
  const { integrationType } = useInstallation();
  const { hasAccessToScenarios } = useLoggedUser();

  const links = useMemo(
    () => [
      {
        href: ROUTES.Root(),
        Icon: IconDashboard,
        title: t('navigation.dashboard'),
      },
      {
        href: ROUTES.Channel(),
        Icon: IconDeviceList,
        title: t('navigation.channels'),
      },
      ...(integrationType === IntegrationType.Exalus
        ? [
            {
              href: ROUTES.Chat(),
              Icon: IconChat,
              title: t('navigation.chat'),
            },
          ]
        : []),
      ...(integrationType === IntegrationType.Lavva || hasAccessToScenarios
        ? [
            {
              href: ROUTES.ActionList(),
              Icon: IconActions,
              title: t('navigation.actions'),
            },
          ]
        : []),
      {
        href: ROUTES.Settings(),
        Icon: IconProfile,
        title: t('navigation.settings'),
      },
    ],
    [integrationType, i18n.language, hasAccessToScenarios],
  );

  return (
    <nav className={classNames('navigation', { 'with-chat': integrationType === IntegrationType.Exalus })}>
      <ul className="navigation__list">
        {links.map((link, i) => (
          <li key={i} className="navigation__list-item">
            <button
              onClick={() => history.push(link.href)}
              {...(pathname === link.href ? { className: 'navigation__list-item--active' } : {})}
            >
              <link.Icon />
              <p className="nav_title">{link.title}</p>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
