import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { Carousel, CarouselItem, Device, Header, IconAdd, NavHeader, Page, Tabs } from '../../components';
import { Tip } from '../../components/tip';
import { LearningScenarios } from '../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../hooks';
import { DeviceInterface } from '../../types';
import * as storage from '../../utils/storage/lavva';
import CheckContentWrapper, { PageType } from '../check-content-wrapper';
import { useAddDevice } from '../device-add/hooks/use-add-device';
import { UpdateDevices } from './update-devices';

interface Element {
  label: string;
  ids: string[];
}

const DeviceList: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('device-list');
  const { deviceList } = useDevicesAndChannels();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const lastActiveTab = useRef(storage.getItem('lastActiveTabInDeviceList'));
  const { addDevice } = useAddDevice();

  useEffect(() => {
    setActiveTabIndex(Number(lastActiveTab.current) || 0);
  }, [setActiveTabIndex, lastActiveTab]);
  useEffect(() => storage.setItem('lastActiveTabInDeviceList', String(activeTabIndex)), [activeTabIndex]);

  const deviceListCount = useMemo(() => deviceList.filter(Boolean).length, [deviceList]);

  const typeList: string[] = useMemo(
    () =>
      uniq(
        (deviceList.filter((device) => device && device.type) as DeviceInterface[]).map(
          (device) => device.type as string,
        ),
      ),
    [deviceList],
  );

  const itemGroupsList = useMemo(() => {
    const elementsList = typeList.map((type) => ({
      label: tc(`typesPlural.${type}`),
      ids: (deviceList.filter((device) => device && device.type == type) as DeviceInterface[]).map(
        (device) => device.id || '',
      ),
    }));
    // .sort((a, b) => a.label.localeCompare(b.label));

    const allListElements = {
      label: tc('all'),
      elements: elementsList.map((element) => ({ label: element.label, ids: element.ids })),
    };

    return [allListElements, ...elementsList.map((element) => ({ label: element.label, elements: [element] }))];
  }, [typeList, deviceList]);

  const deviceListGrouped = useMemo(
    () =>
      itemGroupsList?.map((item: { label: string; elements: Element[] }, typeIndex: number) => (
        <CarouselItem key={typeIndex}>
          {item.elements.map((element: Element, i: number) => (
            <div className="page-list__item" key={i}>
              <Header title={element.label} subheader />
              <ul className="page-list__items-list items-list">
                {element.ids.map((id: string, i: number) => {
                  return (
                    <li key={i} className="items-list__item">
                      <Device id={id} />
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </CarouselItem>
      )),
    [itemGroupsList],
  );

  return (
    <Page
      containerClassName={'page__container--flex-height'}
      isStickyHeader={deviceListCount !== 0}
      header={
        <>
          <NavHeader>
            <IconAdd onClick={addDevice} />
          </NavHeader>
          <Header title={t('devices')}>
            {/* {deviceList.filter((device) => device.payload.isUpdateAvailable).length > 0 ? (
              <UpdateDevices devicesToUpdate={deviceList.filter((device) => device.payload.isUpdateAvailable)} />
            ) : null} */}
            <UpdateDevices devicesToUpdate={deviceList} />
          </Header>
          {deviceListCount !== 0 && (
            <Tabs
              tabList={itemGroupsList}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
              tabsType="pills"
              moreButton="normal"
              moreListFullHeight
            />
          )}
        </>
      }
    >
      <CheckContentWrapper page={PageType.Devices}>
        {
          <Carousel setActiveIndex={setActiveTabIndex} activeIndex={activeTabIndex}>
            {deviceListGrouped}
          </Carousel>
        }
      </CheckContentWrapper>
      <Tip tip={t('DeviceAddTip')} learningKey={LearningScenarios.AddDevice} point={<IconAdd />} top={30} right={16} />
    </Page>
  );
};

export default DeviceList;
