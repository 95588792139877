import { useQuery, UseQueryResult } from 'react-query';
import { Api } from 'lavva.exalushome';
import {
  ISupportedDevicesService,
  SupportedDevice,
} from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { SupportedDevicesService } from 'lavva.exalushome/build/js/Services/SupportedDevices/SupportedDevicesService';
import { useBackdropContext } from '../../../hooks';
import { useExalusContext } from '../context';

export const useExalusSupportedDevices = (): UseQueryResult<SupportedDevice[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<SupportedDevice[]>(
    ['exalus-device-userManual', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const supportedService = Api.Get<ISupportedDevicesService>(SupportedDevicesService.ServiceName);
      const result = await supportedService.GetSupportedDevicesAsync();
      turnOffBackdrop();
      return result;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => {
        turnOffBackdrop();
      },
    },
  );
};

export const useExalusSupportedDeviceById = (id: string): UseQueryResult<SupportedDevice | null> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<SupportedDevice | null>(
    ['exalus-device-userManual', selectedExalusCredentials?.id, synchronized, id],
    async () => {
      turnOnBackdrop();
      const supportedService = Api.Get<ISupportedDevicesService>(SupportedDevicesService.ServiceName);
      const supportedDevices = (await supportedService.GetSupportedDevicesAsync()) || [];
      turnOffBackdrop();
      return supportedDevices.find((x) => x.ModelGuid === id) || null;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => {
        turnOffBackdrop();
      },
    },
  );
};
