import React from 'react';

export const IconInstallation2: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="8.41699" y="3.1665" width="23.75" height="31.6667" stroke="#15141D" />
    <path d="M3.66699 34.8335L36.917 34.8335" stroke="#15141D" />
    <path d="M13.958 11.0835V15.8335" stroke="#15141D" />
    <path d="M20.7666 11.0835V15.8335" stroke="#15141D" />
    <path d="M27.0996 11.0835V15.8335" stroke="#15141D" />
    <path d="M13.958 22.1665V26.9165" stroke="#15141D" />
    <path d="M20.7666 22.1665V26.9165" stroke="#15141D" />
    <path d="M27.0996 22.1665V26.9165" stroke="#15141D" />
  </svg>
);
