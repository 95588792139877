import React, { useMemo } from 'react';
import classNames from 'classnames';

interface IconCoverProps {
  size?: number;
  position?: number;
  tilt?: number;
  fullRange?: boolean;
  className?: string;
}

export const IconFacadeBlind: React.FC<IconCoverProps> = ({
  size = 32,
  position = 0,
  tilt = 50,
  fullRange,
  className,
}) => {
  const firstStep = 25;
  const secondStep = 50;
  const lastStep = 75;

  const tiltHeadingUp = fullRange && tilt <= 33;

  const verticalBars = useMemo(() => {
    if (position > lastStep) {
      return (
        <>
          <path
            d="M7.70996 6.62683V8.37807"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.70996 6.62683V28"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.71 6.62683V27.8555"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    } else if (position > secondStep && position <= lastStep) {
      return (
        <>
          <path
            d="M7.70996 6.62683V8.37807"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.70996 6.62683V22.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.71 6.62683V22.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    } else if (position > firstStep && position <= secondStep) {
      return (
        <>
          <path
            d="M7.70996 6.62683V8.37807"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.70996 6.62683V18"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.71 6.62683V17.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    } else {
      return (
        <>
          <path
            d="M7.70996 6.74017V8.54614"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.70996 6.74017V13"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M23.71 6.74017V13" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </>
      );
    }
  }, [position]);

  const tiltCenteredElements = useMemo(() => {
    return (
      <>
        <path
          d="M4.91642 10.0861C4.91642 10.0861 4.0425 10.5718 3.75098 11.5433H7.72601H27.6372C27.3457 10.5718 26.4718 10.0861 26.4718 10.0861"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {position > firstStep ? (
          <path
            d="M4.91642 15.6708C4.91642 15.6708 4.0425 16.1354 3.75098 17.0647H7.72601H27.6372C27.3457 16.1354 26.4718 15.6708 26.4718 15.6708"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : null}
        {position > secondStep ? (
          <path
            d="M4.91642 21.1922C4.91642 21.1922 4.0425 21.6568 3.75098 22.5861H7.72601H27.6372C27.3457 21.6568 26.4718 21.1922 26.4718 21.1922"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : null}
        {position > lastStep ? (
          <path
            d="M4.91642 26.7135C4.91642 26.7135 4.0425 27.157 3.75098 28.044H7.72601H27.6372C27.3457 27.157 26.4718 26.7135 26.4718 26.7135"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : null}
      </>
    );
  }, [position]);

  const tiltCoveredElements = useMemo(() => {
    return (
      <>
        {tiltHeadingUp ? (
          <path
            d="M4.92197 13.0861C4.92197 13.0861 4.04388 11.7527 3.75098 9.08606H7.74494H27.751C27.4581 11.7527 26.58 13.0861 26.58 13.0861"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : (
          <>
            <path
              d="M27.751 13.0861H7.74494H3.75098C4.04388 10.4194 4.92197 9.08606 4.92197 9.08606"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.58 9.08606C26.58 9.08606 27.4581 10.4194 27.751 13.0861Z"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )}

        {position > firstStep ? (
          tiltHeadingUp ? (
            <path
              d="M4.92197 18.0721C4.92197 18.0721 4.04388 16.7387 3.75098 14.0721H7.74494H27.751C27.4581 16.7387 26.58 18.0721 26.58 18.0721"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          ) : (
            <path
              d="M4.92197 14.0721C4.92197 14.0721 4.04388 15.4054 3.75098 18.0721H7.74494H27.751C27.4581 15.4054 26.58 14.0721 26.58 14.0721"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )
        ) : null}

        {position > secondStep ? (
          tiltHeadingUp ? (
            <path
              d="M4.92197 23.0581C4.92197 23.0581 4.04388 21.7247 3.75098 19.0581H7.74494H27.751C27.4581 21.7247 26.58 23.0581 26.58 23.0581"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          ) : (
            <path
              d="M4.92197 19.0581C4.92197 19.0581 4.04388 20.3914 3.75098 23.0581H7.74494H27.751C27.4581 20.3914 26.58 19.0581 26.58 19.0581"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )
        ) : null}

        {position > lastStep ? (
          tiltHeadingUp ? (
            <>
              <path
                d="M27.751 24.0441H7.74494H3.75098C4.04388 26.7107 4.92197 28.0441 4.92197 28.0441"
                stroke="#15141D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M26.58 28.0441C26.58 28.0441 27.4581 26.7107 27.751 24.0441Z"
                stroke="#15141D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </>
          ) : (
            <path
              d="M4.92197 24.0441C4.92197 24.0441 4.04388 25.3774 3.75098 28.0441H7.74494H27.751C27.4581 25.3774 26.58 24.0441 26.58 24.0441"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )
        ) : null}
      </>
    );
  }, [position, tiltHeadingUp]);

  return (
    <div
      className={classNames('icon-cover icon-facade-blind', {
        [className as string]: className,
      })}
    >
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.1423 4H2V6.62686H29.1423V4Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {(fullRange ? tilt >= 33 && tilt <= 66 : tilt <= 50) ? tiltCenteredElements : tiltCoveredElements}
        {verticalBars}
      </svg>
    </div>
  );
};
