import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getToken, onMessage } from 'firebase/messaging';
import * as firebaseMessaging from 'firebase/messaging';
import Cookies from 'js-cookie';
import { NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { useMutation } from '@apollo/client';
import { IconLavva } from '../../components';
import {
  DeregisterPushNotificationReceiverByNameMutation,
  DeregisterPushNotificationReceiverByNameMutationVariables,
  PushReceiverRegistration,
  PushReceiverType,
  RegisterPushNotificationReceiverMutation,
  RegisterPushNotificationReceiverMutationVariables,
} from '../../data-access/gql-types/graphql';
import { REGISTER_PUSH_NOTIFICATION_RECEIVER } from '../../data-access/mutations/notification';
import { DEREGISTER_PUSH_NOTIFICATION_RECEIVERBY_NAME } from '../../data-access/mutations/user';
import { firebaseConfig, vapidKey } from '../../utils/firebase';
import { toastInfo } from '../../utils/toast';
import { useNativeFunctionsContext } from '../native/native-functions';
import { useProfile } from '../user';

export const usePushNotification = () => {
  const { user } = useProfile();
  const { getNotificationToken } = useNativeFunctionsContext();
  const [registerPushNotificationReceiver] = useMutation<
    RegisterPushNotificationReceiverMutation,
    RegisterPushNotificationReceiverMutationVariables
  >(REGISTER_PUSH_NOTIFICATION_RECEIVER);
  const [deregisterPushNotificationReceiverByNameMutation] = useMutation<
    DeregisterPushNotificationReceiverByNameMutation,
    DeregisterPushNotificationReceiverByNameMutationVariables
  >(DEREGISTER_PUSH_NOTIFICATION_RECEIVERBY_NAME);

  useEffect(() => {
    if ('permissions' in navigator && user.profile.id) {
      navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
        notificationPerm.onchange = () => {
          if (notificationPerm.state !== 'granted') {
            const devicesNames = Cookies.get('devicesNames');

            if (devicesNames) {
              const devicesNamesParsed: Record<string, string> = JSON.parse(devicesNames);

              if (devicesNamesParsed[user.profile.id]) {
                deregisterPushNotificationReceiverByNameMutation({
                  variables: {
                    name: devicesNamesParsed[user.profile.id],
                  },
                });
              }
            }
          }
        };
      });
    }
  }, [user.profile.id]);

  const addPushNotificationListener = async (userId: string) => {
    const isFirebaseSupported = await firebaseMessaging.isSupported();
    if (!isFirebaseSupported) return;

    const app = initializeApp(firebaseConfig);

    onMessage(firebaseMessaging.getMessaging(app), (payload) => {
      if (userId === payload.data?.ReceiverId) {
        console.log('WEB_PUSH', payload);
        toastInfo({ content: payload.notification?.body || '', icon: <IconLavva /> });
      }
    });
  };

  const requestForPushToken = async (
    registrations: PushReceiverRegistration[],
    deviceName: string,
    permission: string,
  ) => {
    const isFirebaseSupported = await firebaseMessaging.isSupported();
    if (!isFirebaseSupported) return;

    const found = registrations.find(
      (registration) => registration.pushReceiverType === PushReceiverType.Web && registration.name === deviceName,
    );

    const swRegistrations = await navigator.serviceWorker.getRegistrations();

    if (!found || !swRegistrations.length) {
      if ('Notification' in window) {
        const app = initializeApp(firebaseConfig);

        if (permission === 'granted') {
          getToken(firebaseMessaging.getMessaging(app), {
            vapidKey,
          })
            .then((currentToken) => {
              if (currentToken) {
                console.log('Client Token: ', currentToken);

                registerPushNotificationReceiver({
                  variables: {
                    input: {
                      token: currentToken,
                      pushReceiverName: deviceName,
                      pushReceiverType: PushReceiverType.Web,
                    },
                  },
                });
              } else {
                console.log('Failed to generate the registration token.');
              }
            })
            .catch((err) => {
              console.log('An error occurred when requesting to receive the token.', err);
            });
        }
      }
    }
  };

  const requestForNativeToken = async (deviceName: string, nativeCtx: NativeContext) => {
    const nativeToken = await getNotificationToken();

    if (nativeToken) {
      registerPushNotificationReceiver({
        variables: {
          input: {
            token: nativeToken,
            pushReceiverName: deviceName,
            pushReceiverType:
              nativeCtx === NativeContext.AndroidGecko ? PushReceiverType.Android : PushReceiverType.Ios,
          },
        },
      });
    }
  };

  return {
    requestForPushToken,
    requestForNativeToken,
    addPushNotificationListener,
  };
};
