import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../../components/edit-icon';
import { ChannelType, LightType, SensorType } from '../../../../enums';
import { availableIcons } from './available-icons';

interface ComponentProps {
  channelType: ChannelType | LightType | SensorType;
  disabled?: boolean;
}

const ConfigIconEdit: React.FC<ComponentProps> = ({ channelType, disabled }) => {
  const { t } = useTranslation('configuration');
  const { setValue, watch } = useFormContext();
  const { icon_name } = watch();

  const handleChangeIcon = (value: string) => {
    setValue('icon_name', value);
  };

  return (
    <EditIcon
      currentIcon={icon_name}
      iconList={availableIcons[channelType] || []}
      handleChangeIcon={handleChangeIcon}
      header={t('channelIcon')}
      disabled={disabled}
    />
  );
};

export default ConfigIconEdit;
