import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ROUTES } from '../../../../../routes';
import { ActionType } from '../../../../action-create/types';
import { canChannelDo } from '../../../utils';
import { useExalusCreateActionContext } from '../context';
import { TaskVariant } from '../types';

export const useActionTaskVariants = () => {
  const history = useHistory();
  const { actionType, taskVariant, setTaskVariant } = useExalusCreateActionContext();

  useEffect(() => {
    if (actionType === ActionType.Undefined) history.replace(ROUTES.ActionList());
  }, []);

  const selectTaskVariant = (taskVariant: TaskVariant) => {
    setTaskVariant(taskVariant);
    history.push(ROUTES.DeviceVariants());
  };

  const getChannelsByVariant = (channels: IDeviceChannel[]) => {
    const all = [];

    switch (taskVariant) {
      case TaskVariant.OnOff: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.TurnOn, DeviceTaskType.TurnOff]));
      }
      case TaskVariant.Dim: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.SetLightBrightness]));
      }
      case TaskVariant.SetMicrowave: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.SetBlindMicroventilation]));
      }
      case TaskVariant.SetRGBW: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.LightRGBW]));
      }
      case TaskVariant.SetBlind: {
        return channels.filter(
          (x) =>
            canChannelDo(x, [DeviceTaskType.SetBlindPosition]) ||
            canChannelDo(x, [DeviceTaskType.SetBlindPositionSimple]),
        );
      }
      case TaskVariant.SetFacade: {
        return channels.filter((x) => canChannelDo(x, [DeviceTaskType.SetFacadePosition]));
      }

      default: {
        return all;
      }
    }
  };

  return {
    selectTaskVariant,
    getChannelsByVariant,
  };
};
