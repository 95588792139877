import React from 'react';

export const IconSadFace: React.FC = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-sad-face"
    >
      <circle
        cx="19.5"
        cy="18.5"
        r="15.5"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="14.7812"
        cy="14.4062"
        r="1.72917"
        fill="#15141D"
        stroke="black"
        strokeWidth="1.72917"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="24.2188"
        cy="14.4062"
        r="1.72917"
        fill="#15141D"
        stroke="black"
        strokeWidth="1.72917"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 25.5V25.5C23.9209 19.5013 15.0791 19.5013 11 25.5V25.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
