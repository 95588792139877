import React from 'react';
import './index.scss';

export const IconToastError: React.FC = () => {
  return (
    <div className={'icon-toast-error'}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11" fill="#CC0909" />
        <path d="M8.5 8.5L16 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M8.5 16L16 8.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </div>
  );
};
