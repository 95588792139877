import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, NavHeader, Page, PopUpNav } from '../../../../components';
import { IconDelete, IconOrder, IconSettings } from '../../../../components/popup-nav/icons';
import { RgbColorListItem } from '../../components/color-list-item';
import { RgbSceneDetails } from '../../components/scene-details';
import { useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';
import './index.scss';

export const RgbSceneFormList: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const history = useHistory();
  const { colors, setSelectedView } = useRgbSceneFormContext();

  const popupLinks = [
    {
      onClick: () => setSelectedView(RgbSceneFormView.SETTINGS),
      label: t('rgbScenes.settings'),
      icon: <IconSettings />,
    },
    {
      onClick: () => setSelectedView(RgbSceneFormView.CHANGE_ORDER),
      label: t('rgbScenes.changeOrder'),
      icon: <IconOrder />,
    },
    {
      onClick: () => setSelectedView(RgbSceneFormView.REMOVE_COLOR),
      label: t('rgbScenes.removeColor'),
      icon: <IconDelete />,
    },
  ];

  return (
    <Page
      header={
        <>
          <NavHeader
            onClick={() => {
              history.goBack();
            }}
          >
            <PopUpNav links={[...popupLinks]} />
          </NavHeader>
          <Header title={t('rgbScenes.addScene')} />
        </>
      }
      containerClassName={'p-t-0 p-r-0 p-b-0 p-l-0'}
      headerClassName={'p-t-24 p-r-24 p-b-0 p-l-24'}
      contentClassName={'p-r-24 p-b-24 p-l-24 page__content--background-element page__content--scrollable'}
    >
      <div className="rgb-scene-form-list">
        <RgbSceneDetails />

        {colors.map((color) => (
          <RgbColorListItem id={color.id} colorItem={color} key={color.id} />
        ))}
      </div>

      <div className="rgb-scene-form-list-buttons">
        <button className="button" onClick={() => setSelectedView(RgbSceneFormView.SET_COLOR)}>
          {t('rgbScenes.addColor')}
        </button>
        <button className="button button--secondary">{t('rgbScenes.save')}</button>
      </div>
    </Page>
  );
};
