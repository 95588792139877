import React from 'react';
import c from 'classnames';
import './index.scss';

interface ComponentProps {
  normal?: boolean;
  big?: boolean;
  details?: boolean;
}

export const IconCalibrateInfo: React.FC<ComponentProps> = ({ normal, big, details }) => (
  <div className={c('calibrate-info', { normal, big, details })}>i</div>
);
