import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, NavHeader, Page, SubmitButton } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { ShareInfoModificationType, ModifyShareInfoChannelInput } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { SharedItemType, SharingType } from '../../../types';
import { RepeatCountDialog } from '../../trigger-form/condition-types/time-condition/components';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import { useModify } from '../hooks/use-modify';
import './index.scss';

const ShareAccessCount: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('installation');
  const [dialogCounter, showDialogCounter] = useState<boolean>(false);
  const { state, hasInstallation, methods } = useInstallationShare();
  const { modyfyChannelAccess, modifyUserAccessesLoading } = useModify();
  const { refetchSharingInfo } = useMember();

  const handleEditCount = () => showDialogCounter(true);

  const handleNext = () => {
    switch (state.shareInfoModificationType) {
      case ShareInfoModificationType.Undefined: {
        history.push(ROUTES.InstallationSummaryShare());
        break;
      }
      case ShareInfoModificationType.Modify: {
        const channels: ModifyShareInfoChannelInput[] = [
          {
            id: state.editedId,
            shareInfoModificationType: ShareInfoModificationType.Modify,
            grantedUses: state.quantity,
          },
        ];

        modyfyChannelAccess(state.userId, channels, () => {
          refetchSharingInfo();
          history.go(-2);
        });

        break;
      }
      case ShareInfoModificationType.Add: {
        if (!hasInstallation) {
          history.push(ROUTES.InstallationSummaryShare());
        } else {
          const channels: ModifyShareInfoChannelInput[] = state.channels.map((d) => ({
            id: d.id,
            grantedUses: state.quantity,
            shareInfoModificationType: ShareInfoModificationType.Add,
          }));

          modyfyChannelAccess(state.userId, channels, () => {
            refetchSharingInfo();
            history.go(-3);
          });
        }

        break;
      }
      default:
        break;
    }
  };

  const submitBtn = useMemo(() => {
    if (
      (state.shareInfoModificationType === ShareInfoModificationType.Modify &&
        state.access === SharingType.Quantitative) ||
      (state.shareInfoModificationType === ShareInfoModificationType.Add &&
        state.access === SharingType.Quantitative &&
        [SharedItemType.Group, SharedItemType.Channel].includes(state.subject) &&
        hasInstallation)
    ) {
      return { title: tc('buttons.save'), loading: modifyUserAccessesLoading };
    }

    return { title: tc('buttons.next'), loading: false };
  }, [state.access, state.shareInfoModificationType, modifyUserAccessesLoading, hasInstallation]);

  return (
    <Page
      className="share-installation-count"
      header={
        <>
          <NavHeader />
          <Header title={t('share_path.access_count')} isUnderline column>
            <div className="share-installation-count--info">{t('share_path.access_count_info')}</div>
          </Header>
        </>
      }
    >
      <ArrowButton title={state.quantity.toString()} label={t('share_path.count')} onClick={handleEditCount} />
      <RepeatCountDialog
        open={dialogCounter}
        setOpen={showDialogCounter}
        title={t('share_path.count')}
        onSave={(value) => methods.handleSetShareQuantity(value)}
        count={state.quantity}
      />
      <SubmitButton type="button" onClick={handleNext} isLoading={submitBtn.loading}>
        {submitBtn.title}
      </SubmitButton>
    </Page>
  );
};

export default ShareAccessCount;
