import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconSmallCamera: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-small-camera"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="16.625"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {!isOn ? (
        <path
          d="M29.875 18C29.875 11.4416 24.5584 6.125 18 6.125C11.4416 6.125 6.125 11.4416 6.125 18"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : null}
      <ellipse
        cx="18"
        cy="19.1875"
        rx="4.75"
        ry="4.75"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
