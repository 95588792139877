import { gql } from '@apollo/client';

export const NOTIFICATION_GET_ALL = gql`
  query NotificationGetAll {
    allNotifications(where: { isHidden: { eq: false }, senderType: { eq: QUERY_ONLY } }) {
      correlationId
      code
      wasDisplayed
      isHidden
      createdAt
      params {
        name
        value
      }
    }
  }
`;
