import React from 'react';
import { useTranslation } from 'react-i18next';
import { FromToInputForm, Header } from '../../../../../../components';

export const WorkingHours: React.FC = () => {
  const { t } = useTranslation('channel-details');

  return (
    <>
      <Header title={t('optimizer.configuration.workingHours')} className="m-b-16" />
      <FromToInputForm />
    </>
  );
};
