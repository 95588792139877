import React, { useState, PointerEvent, useEffect } from 'react';
import * as uuid from 'uuid';
import { BlindStateDirectionInternal } from '../../../../../../data-access/gql-types/graphql';
import { BlindStateTask } from '../../../../../exalus/pages/action-create/types';
import { BlindChannelSettings } from '../../../../components/channel-settings/blind';
import { useLavvaCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, SceneTaskType, Task } from '../../../../types';

export const BlindSettings: React.FC = () => {
  const { channels, taskVariant } = useLavvaCreateActionContext();
  const [blindAction, setBlindAction] = useState<BlindStateDirectionInternal>(BlindStateDirectionInternal.Open);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      const blindTaskState = (task.taskParams as DeviceTaskParams).state as BlindStateTask<BlindStateDirectionInternal>;
      const action = blindTaskState.action;
      setBlindAction(blindTaskState.action);

      switch (action) {
        case BlindStateDirectionInternal.Open:
          setSliderValue(0);
          break;
        case BlindStateDirectionInternal.Closed:
          setSliderValue(100);
          break;
        default:
          setBlindAction(BlindStateDirectionInternal.Unknown);
          if (blindTaskState.position !== null) setSliderValue(blindTaskState.position);
      }
    }
  }, [task]);

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    setBlindAction(BlindStateDirectionInternal.Unknown);
    setSliderValue(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlindAction(BlindStateDirectionInternal.Unknown);
    setSliderValue(Number(event.target.value));
  };

  const handleConstPercentageChange = (pos: number) => {
    setBlindAction(BlindStateDirectionInternal.Unknown);
    setSliderValue(pos);
  };

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task: Task = {
        id: uuid.v4(),
        taskType: SceneTaskType.ChannelState,
        taskParams: {
          state: {
            action: blindAction,
            position: blindAction === BlindStateDirectionInternal.Unknown ? sliderValue : null,
          } as BlindStateTask<BlindStateDirectionInternal>,
          channel: channels[i],
          variant: taskVariant,
        } as DeviceTaskParams,
      };

      finalizeDeviceTaskSubmit(task, i === channels.length - 1);
    }
  };

  return (
    <BlindChannelSettings
      handleConstPercentageChange={handleConstPercentageChange}
      handleSliderEvent={handleSliderEvent}
      handleSubmit={handleSubmit}
      sliderValue={sliderValue}
      blindAction={blindAction}
      onChange={onChange}
      setBlindAction={setBlindAction}
      setSliderValue={setSliderValue}
    />
  );
};
