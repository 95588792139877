import { gql } from '@apollo/client';

export const USER_ME = gql`
  query UserMe {
    me {
      profileImage {
        imageUrl
      }
      profile {
        id
        firstName
        email
      }
      permissions {
        installationId
        userPermissions
      }
    }
  }
`;

export const USER_PROFILE = gql`
  query UserProfile {
    userProfile {
      userLanguage
      automationEmailNotificationPermission
      automationInAppNotificationPermission
      automationPushNotificationPermission
      channelsEmailNotificationPermission
      channelsInAppNotificationPermission
      channelsPushNotificationPermission
      sharingEmailNotificationPermission
      sharingPushNotificationPermission
      userId
      displayedLearningScenarios
    }
  }
`;
