import React from 'react';

export const IconBluetooth: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_25265_165750)">
      <rect width="32" height="32" rx="4.8" fill="#EBEDF0" />
    </g>
    <path
      d="M10.4004 10.3277L21.336 21.2633C21.343 21.2703 21.343 21.2817 21.336 21.2887L16.2558 26.3689C16.2445 26.3802 16.2251 26.3722 16.2251 26.3562V5.64301C16.2251 5.627 16.2445 5.61897 16.2558 5.6303L21.336 10.7105C21.343 10.7175 21.343 10.7289 21.336 10.7359L10.4004 21.6715"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <defs>
      <filter
        id="filter0_b_25265_165750"
        x="-10.4"
        y="-10.4"
        width="52.8"
        height="52.8"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_25265_165750" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_25265_165750" result="shape" />
      </filter>
    </defs>
  </svg>
);
