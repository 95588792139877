import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, IconLogoWisniowscy, NavHeader, Page } from '../../../../../components';
import { ROUTES } from '../../../../../routes';
import { useBluetoothContext } from '../../../context';
import { DriveFlowType } from '../../types';
import './index.scss';

const WisniowskiDriveAddStart: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('drive-add');
  const { checkIfConnected } = useBluetoothContext();

  useEffect(() => {
    checkIfConnected();
  }, []);

  const handleRemote = () => {
    history.push(ROUTES.DeviceAddBluetoothWisniowskiTutorialStep0(DriveFlowType.Remote));
  };

  const handleDrive = () => {
    history.push(ROUTES.DeviceAddBluetoothWisniowskiTutorialStep0(DriveFlowType.Drive));
  };

  return (
    <Page contentClassName="drive-add start" isStickyHeader header={<NavHeader isIconClose />}>
      <IconLogoWisniowscy className="m-b-64" />
      <Header title={t('driveAddTitle')} column subtitle={t('driveAddSubTitle')} center className="m-b-48" />
      <button className="button max-width-desktop m-b-24" onClick={handleRemote}>
        {t('remote')}
      </button>
      <button className="button button--secondary max-width-desktop" onClick={handleDrive}>
        {t('drive')}
      </button>
    </Page>
  );
};

export default WisniowskiDriveAddStart;
