import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowButton from '../../../../components/arrow-button';
import ActionTasksTypes from '../../components/task-types';
import { useActionTasks } from '../../hooks/use-action-tasks';
import { actionTasks } from '../../utils/action-tasks';

const LavvaActionTasksTypes: React.FC = () => {
  const { t } = useTranslation('action');
  const { selectTaskType } = useActionTasks();

  return (
    <ActionTasksTypes>
      {actionTasks.map((x) => (
        <ArrowButton
          key={x.title}
          title={t(x.title)}
          icon={x.icon}
          className="m-b-24"
          iconAccent
          onClick={() => selectTaskType(x.type)}
        />
      ))}
    </ActionTasksTypes>
  );
};

export default LavvaActionTasksTypes;
