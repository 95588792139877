import React from 'react';

export const IconExalusDinReceiver: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.289062" y="0.579102" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23191_242782)">
      <path
        d="M27.0039 17.002L27.0039 12.7659"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0039 16.999L27.0039 12.763"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4727 28.2979L15.7087 28.2979"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8262 28.2979L43.0622 28.2979"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0215 17.3154L19.0168 20.3108"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.9863 17.3154L34.991 20.3108"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7452 43.1198C29.017 43.1198 30.0481 42.2295 30.0481 41.1312V39.1426M30.0481 39.1426C30.684 39.1426 31.1995 38.6974 31.1995 38.1483C31.1995 36.4598 31.5979 34.9433 32.1242 33.56C32.6814 32.0954 34.3026 28.7709 33.9953 27.2344V27.2344C32.8304 21.4098 27.0058 21.9393 27.0058 21.9393M30.0481 39.1426H26.5938"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0481 39.1485V41.1371C30.0481 42.2353 29.017 43.1257 27.7452 43.1257H26.5938M30.0481 39.1485C30.684 39.1485 31.1995 38.7033 31.1995 38.1542C31.1995 36.4657 31.5979 34.9492 32.1242 33.5658C32.6814 32.1013 34.3026 28.7768 33.9953 27.2402V27.2402C32.8304 21.4156 27.0058 21.9451 27.0058 21.9451M30.0481 39.1485H26.5938"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0574 39.1485V41.1371C24.0574 42.2353 25.0884 43.1257 26.3603 43.1257H27.5117M24.0574 39.1485C23.4215 39.1485 22.906 38.7033 22.906 38.1542C22.906 36.4657 22.5075 34.9492 21.9813 33.5658C21.4241 32.1013 19.8028 28.7768 20.1102 27.2402V27.2402C21.2751 21.4156 27.0996 21.9451 27.0996 21.9451M24.0574 39.1485H27.5117"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1715 27.4574L20.9494 28.1738L20.9494 28.1738L21.1715 27.4574ZM33.5984 28.0065C33.9909 27.874 34.2016 27.4485 34.0692 27.056C33.9367 26.6636 33.5112 26.4528 33.1187 26.5853L33.5984 28.0065ZM20.4283 28.0123L20.9494 28.1738L21.3935 26.741L20.8724 26.5795L20.4283 28.0123ZM20.9494 28.1738C25.1298 29.4695 29.4341 29.4121 33.5984 28.0065L33.1187 26.5853C29.2519 27.8905 25.2709 27.9429 21.3935 26.741L20.9494 28.1738Z"
        fill="#15141D"
      />
      <rect x="36.2891" y="23.7197" width="8.5" height="2" rx="1" fill="white" />
      <rect x="28.9688" y="25" width="18.0585" height="20.2458" rx="2.07372" fill="white" />
      <path
        d="M32.8803 37.0472V31.1688C32.8803 31.1296 32.9121 31.0978 32.9513 31.0978H35.7362C35.7754 31.0978 35.8072 31.066 35.8072 31.0268V29.1566C35.8072 29.1173 35.839 29.0855 35.8783 29.0855H39.212C39.2512 29.0855 39.283 29.0537 39.283 29.0145V27.1443C39.283 27.105 39.3148 27.0732 39.354 27.0732H44.883C44.9222 27.0732 44.954 27.105 44.954 27.1443V31.5931C44.954 31.6248 44.933 31.6526 44.9025 31.6613L43.725 31.9978C43.6945 32.0065 43.6735 32.0344 43.6735 32.0661V38.1855C43.6735 38.2139 43.6904 38.2396 43.7165 38.2508L44.911 38.7627C44.9371 38.7739 44.954 38.7995 44.954 38.8279V43.1005C44.954 43.1398 44.9222 43.1716 44.883 43.1716H39.354C39.3148 43.1716 39.283 43.1398 39.283 43.1005V41.2303C39.283 41.1911 39.2512 41.1593 39.212 41.1593H35.8783C35.839 41.1593 35.8072 41.1275 35.8072 41.0883V39.218C35.8072 39.1788 35.7754 39.147 35.7362 39.147H32.9513C32.9121 39.147 32.8803 39.1152 32.8803 39.076V37.0472ZM32.8803 37.0472H31.114C31.0748 37.0472 31.043 37.0154 31.043 36.9762V28.3856"
        stroke="#15141D"
        strokeWidth="1.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_23191_242782">
        <rect width="35.2441" height="35.2441" fill="white" transform="translate(10.2891 10.5791)" />
      </clipPath>
    </defs>
  </svg>
);
