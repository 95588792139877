import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { toastInfo } from '../../../utils/toast';
import { useLocationContext } from '../components/location/context/location.context';
import { LocationCoordsHook, LocationViews } from '../types';

export const useLocationCoords = (setLoader: Dispatch<SetStateAction<boolean>>): LocationCoordsHook => {
  const { t } = useTranslation('installation');
  const { setCoords, setCurrentLocation, setCenter, setZoom, goTo, currPage } = useLocationContext();

  const getCoords = () => {
    setLoader(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const crd: GeolocationCoordinates = pos.coords;
          setCurrentLocation({ latitude: crd.latitude, longitude: crd.longitude });
          setCenter({ lat: crd.latitude, lng: crd.longitude });
          setZoom(16);
          setLoader(false);

          if (currPage === LocationViews.ADD_LOCATION) {
            setCoords({ latitude: crd.latitude, longitude: crd.longitude });
            goTo(LocationViews.MAP);
          }
        },
        (error: GeolocationPositionError) => {
          setLoader(false);

          switch (error.code) {
            case error.PERMISSION_DENIED:
              toastInfo({ content: t('locationNeedPermissions') });
              break;
            case error.POSITION_UNAVAILABLE:
              toastInfo({ content: t('locationUnavailable') });
              break;
            case error.TIMEOUT:
              toastInfo({ content: t('locationTimeout') });
              break;
            default:
              toastInfo({ content: t('locationUnknownError') });
              break;
          }
        },
      );
    } else {
      setLoader(false);
      toastInfo({ content: 'Geolocation is not supported' });
    }
  };

  return {
    getCoords,
  };
};
