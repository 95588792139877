import React from 'react';

export const IconBabyBed: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-baby-bed"
  >
    <path
      d="M35.5 31H2.5C1.67157 31 1 31.6716 1 32.5C1 33.3284 1.67157 34 2.5 34H35.5C36.3284 34 37 33.3284 37 32.5C37 31.6716 36.3284 31 35.5 31Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M36.4585 31.4691V17.7536C36.4585 16.606 35.5282 15.6757 34.3806 15.6757V15.6757C33.233 15.6757 32.3027 16.606 32.3027 17.7536V31.4691"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.69682 31.4691V17.7536C5.69682 16.606 4.76651 15.6757 3.61892 15.6757V15.6757C2.47132 15.6757 1.54102 16.606 1.54102 17.7536V31.4691"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M6 21H32.6046" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M25.6504 21V31.4691" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M19.002 21V31.4691" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M12.3496 20.5V31.4691" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M5.69727 20.3078V9.50003C5.69727 7.6643 7.18753 6.17404 9.02326 6.17404H16.5051C18.3408 6.17404 19.8311 7.6643 19.8311 9.50003V15.3188"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M14.8438 13.6867V12.0237C14.8438 10.188 16.334 8.69775 18.1697 8.69775H21.4957C23.3315 8.69775 24.8217 10.188 24.8217 12.0237V13.6867"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M21.4959 14.5165C21.4959 15.4344 20.7508 16.1795 19.8329 16.1795C18.9151 16.1795 18.1699 15.4344 18.1699 14.5165C18.1699 13.5986 18.9151 12.8535 19.8329 12.8535C20.7508 12.8535 21.4959 13.5986 21.4959 14.5165Z"
      fill="#15141D"
    />
    <path
      d="M26.4822 14.5165C26.4822 15.4344 25.7371 16.1795 24.8192 16.1795C23.9014 16.1795 23.1562 15.4344 23.1562 14.5165C23.1562 13.5986 23.9014 12.8535 24.8192 12.8535C25.7371 12.8535 26.4822 13.5986 26.4822 14.5165Z"
      fill="#15141D"
    />
    <path
      d="M16.5057 14.5165C16.5057 15.4344 15.7606 16.1795 14.8427 16.1795C13.9248 16.1795 13.1797 15.4344 13.1797 14.5165C13.1797 13.5986 13.9248 12.8535 14.8427 12.8535C15.7606 12.8535 16.5057 13.5986 16.5057 14.5165Z"
      fill="#15141D"
    />
  </svg>
);
