import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import { useMutation } from '@apollo/client';
import { ACCENT_COLOR_DEFAULT_INDEX_MAP, INSTALLATION_COLOR_LIST } from '../../../const';
import {
  ChangeInstallationOrderMutation,
  ChangeInstallationOrderMutationVariables,
  ChangeInstallationsOrderItemInput,
  IntegrationType,
  UserInstallation,
} from '../../../data-access/gql-types/graphql';
import { CHANGE_INSTALLATION_ORDER } from '../../../data-access/mutations/installations';
import { environment } from '../../../environment';
import { useBackdropContext, useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { InstallationSortable, IntegrationDefaultEnum } from '../../../types';
import { setBodyStyle } from '../../../utils/theme';
import { toastError } from '../../../utils/toast';
import { useExalusContext } from '../../exalus/context';
import { useCreateInstallation } from '../../installation-create/hooks/use-create-installation';

export const useInstallationList = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { installationList, selectedInstallation, updateInstallationList } = useInstallation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [sortMode, setSortMode] = useState<boolean>(false);
  const { resetInstallationCreate } = useCreateInstallation();
  const { setSelectedInstallationId } = useInstallation();
  const { setSelectedExalusCredentials } = useExalusContext();
  const [sortedInstallationList, setSortedInstallationList] = useState<InstallationSortable[]>([]);
  const [changeInstallationOrderVariables, setChangeInstallationVariables] = useState<
    ChangeInstallationsOrderItemInput[]
  >([]);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const [changeInstallationOrder, { loading }] = useMutation<
    ChangeInstallationOrderMutation,
    ChangeInstallationOrderMutationVariables
  >(CHANGE_INSTALLATION_ORDER);

  useEffect(() => {
    if (sortedInstallationList.length > 0 && selectedInstallation) {
      setBodyStyle('--color-accent', selectedInstallation?.hexColor);
    } else {
      setBodyStyle(
        '--color-accent',
        INSTALLATION_COLOR_LIST[ACCENT_COLOR_DEFAULT_INDEX_MAP[environment.INTEGRATION_DEFAULT]],
      );
    }
  }, [sortedInstallationList, selectedInstallation, loading]);

  useEffect(() => {
    setSortedInstallationList(
      orderBy(installationList, 'order').map((x) => ({ id: x.installationId, installation: x })),
    );
  }, [installationList]);

  const onSortSave = useCallback(() => {
    if (changeInstallationOrderVariables.length) {
      turnOnBackdrop();
      changeInstallationOrder({
        variables: { input: { orders: changeInstallationOrderVariables } },
        onCompleted: (data) => {
          if (data.changeInstallationOrder.ok) {
            const list = installationList.map((installation: UserInstallation) => {
              const order = changeInstallationOrderVariables.find(
                (x) => x.installationId === installation.installationId,
              )?.order;

              return {
                ...installation,
                order: order !== undefined ? order : installation.order,
              };
            });

            updateInstallationList(list);
            setIsValid(false);
            setSortMode(false);

            turnOffBackdrop();
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
            // TODO errors
          }
        },
        onError: () => turnOffBackdrop(),
      });
    }
  }, [changeInstallationOrderVariables, installationList]);

  const toggleSort = useCallback(() => setSortMode(!sortMode), [sortMode]);

  const newInstallation = () => {
    resetInstallationCreate();

    if (selectedInstallation?.integrationType === IntegrationType.Exalus) {
      setSelectedInstallationId(undefined);
      setSelectedExalusCredentials(null);

      environment.INTEGRATION_DEFAULT === IntegrationDefaultEnum.Exalus
        ? (window.location.href = ROUTES.InstallationCreate())
        : history.push(ROUTES.InstallationCreate());
    } else {
      history.push(ROUTES.InstallationCreate());
    }
  };

  return {
    newInstallation,
    toggleSort,
    onSortSave,
    setChangeInstallationVariables,
    setIsValid,
    setSortedInstallationList,
    sortedInstallationList,
    sortMode,
    isValid,
    loading,
  };
};
