import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconSmallBulb: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-switch icon-small-bulb"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isOn && (
        <>
          <path
            d="M18.8521 6.89795L18.8521 2.11119"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.8521 6.89795L18.8521 2.11119"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.6172 19.6626L35.4039 19.6626"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.30078 19.6626L7.08754 19.6626"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.44141 7.25195L9.82616 10.6367"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.2632 7.25195L27.8784 10.6367"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
      <path
        d="M12.75 18C12.75 14.5482 15.5482 11.75 19 11.75C22.4518 11.75 25.25 14.5482 25.25 18V25.25H12.75V18Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M8.74463 25.5V24.75C8.33042 24.75 7.99463 25.0858 7.99463 25.5H8.74463ZM29.2533 25.5H30.0033C30.0033 25.0858 29.6675 24.75 29.2533 24.75V25.5ZM29.2533 28.5V29.25C29.6675 29.25 30.0033 28.9142 30.0033 28.5H29.2533ZM8.74463 28.5H7.99463C7.99463 28.9142 8.33042 29.25 8.74463 29.25V28.5ZM13.7501 34C13.7501 34.4142 14.0859 34.75 14.5001 34.75C14.9143 34.75 15.2501 34.4142 15.2501 34H13.7501ZM22.0001 37C22.0001 37.4142 22.3359 37.75 22.7501 37.75C23.1643 37.75 23.5001 37.4142 23.5001 37H22.0001ZM8.74463 26.25H29.2533V24.75H8.74463V26.25ZM28.5033 25.5V28.5H30.0033V25.5H28.5033ZM9.49463 28.5V25.5H7.99463V28.5H9.49463ZM14.5001 27.75H8.74463V29.25H14.5001V27.75ZM13.7501 28.5V34H15.2501V28.5H13.7501ZM29.2533 27.75H22.7501V29.25H29.2533V27.75ZM22.7501 27.75H14.5001V29.25H22.7501V27.75ZM22.0001 28.5V37H23.5001V28.5H22.0001Z"
        fill="#15141D"
      />
    </svg>
  );
};
