import { useMutation, UseMutationResult } from 'react-query';
import { AuthorizationInfo, ConnectionResult } from 'lavva.exalushome/build/js/Services/IExalusConnectionService';
import { LoginError } from 'lavva.exalushome/build/js/Services/Session/ISessionService';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { useBackdropContext } from '../../../hooks';
import { useExalusServicesContext } from '../context/services';
import { AuthorizeControllerBody, LoginBody } from '../types/auth.types';

export const useExalusIntegrationLogin = (): UseMutationResult<IUser | LoginError, unknown, LoginBody> => {
  const { sessionApi } = useExalusServicesContext();

  return useMutation<IUser | LoginError, unknown, LoginBody>(['exalus-integration-login'], async (body) => {
    return await sessionApi.UserLogInAsync(body.email, body.password);
  });
};

export const useExalusIntegrationAuthorizeController = (): UseMutationResult<
  ConnectionResult,
  unknown,
  AuthorizeControllerBody
> => {
  const { connectionApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useMutation<ConnectionResult, unknown, AuthorizeControllerBody>(
    ['exalus-installation-authorizeController'],
    async (body) => {
      turnOnBackdrop();
      return await connectionApi.ConnectAndAuthorizeAsync(new AuthorizationInfo(body.serialNumber, body.pin));
    },
    { onSuccess: () => turnOffBackdrop(), onError: () => turnOffBackdrop() },
  );
};
