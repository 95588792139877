import React from 'react';
import { useTranslation } from 'react-i18next';
import { INSTALLATION_COLOR_LIST } from '../../../const';
import { Header } from '../../header';
import { NavHeader } from '../../nav-header';
import { Page } from '../../page';
import { RgbColor } from '../../rgb-color';
import './index.scss';

interface PropsInterface {
  onChoose: (color: string) => void;
  onClose: () => void;
  activeColor?: string;
}

export const InstallationColor: React.FC<PropsInterface> = ({ onClose, onChoose, activeColor }) => {
  const { t } = useTranslation('installation');

  return (
    <Page
      className="installation-color"
      header={
        <>
          <NavHeader onClick={onClose} />
          <Header title={t('mainColor')} isUnderline />
        </>
      }
    >
      <RgbColor colors={INSTALLATION_COLOR_LIST} onClick={onChoose} activeColor={activeColor || ''} />
    </Page>
  );
};
