import { IconBigDrop, IconLeak, IconThreeDrops } from '../../../../../../../components/icons';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const humidityIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconThreeDrops isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_28',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_LEAK,
    component: <IconLeak isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_24',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BIG_DROP,
    component: <IconBigDrop isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_27',
  },
];
