import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import './index.scss';

interface InputProps {
  value: string;
  name?: string;
  disabled?: boolean;
  label?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  errorMessage?: string;
}

export const InputIp: React.FC<InputProps> = ({
  value,
  name,
  disabled = false,
  label = '',
  onChange,
  placeholder,
  errorMessage = '',
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.target.value);
    if ((val >= 0 && val <= 255) || isNaN(val)) onChange(event);
  };

  return (
    <div className={classNames('input', { 'input--disabled': disabled })}>
      {label && <label className={classNames('input__label')}>{label}</label>}
      <div className="input__wrapper">
        <input
          className={classNames('input__input', {
            'has-error': errorMessage,
          })}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          type="number"
          min={0}
          max={255}
          maxLength={3}
          {...(name ? { name } : {})}
          {...(placeholder ? { placeholder } : {})}
        />
      </div>
      {errorMessage && <div className="input__error-message">{errorMessage}</div>}
    </div>
  );
};
