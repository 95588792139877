import { IconType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import {
  IconExalusUnknown,
  IconExalusBlindMotor,
  IconExalusDinMoble,
  IconExalusDinModuleWithAntenna,
  IconExalusRemote,
  IconExalusRemoteForBlinds,
  IconExalusRemoteForFacadeBlinds,
  IconExalusMovementSensor,
  IconExalusTemperatureSensor,
  IconExalusHumiditySensor,
  IconExalusDinPowerMeter,
  IconExalusIntercom,
  IconExalusSecurityCamera,
  IconExalusMultisensor,
  IconExalusFloodSensor,
  IconExalusFlushMountedReceiver,
  IconExalusFlushMountedBlindController,
  IconExalusFlushMountedDimmer,
  IconExalusFlushMountedRelay,
  IconExalusFlushMountedGateController,
  IconExalusWindSensor,
  IconExalusFlushMountedGateAndGatewayController,
  IconExalusAirPressureSensor,
  IconExalusLightBrightnessSensor,
  IconExalusReedSensor,
  IconExalusFlushMountedTransceiver,
  IconExalusFlushMountedRGBLightController,
  IconExalusWallMountedThermoregulator,
  IconExalusImpulseCounter,
  IconExalusFlushMountedFacadeController,
  IconExalusWallMountedGateController,
  IconExalusWallMountedRGBLightController,
  IconExalusWallMountedDimmer,
  IconExalusWallMountedRemote,
  IconExalusDinRelay,
  IconExalusDinReceiver,
  IconExalusDinBlindController,
  IconExalusDinTransceiver,
  IconExalusSocketPlugIn,
  IconExalusWallMountedRelay,
  IconExalusRetransmitter,
} from '../../../../../components/icons/device/exalus';

export const availableIcon = {
  [IconType.Unknown]: <IconExalusUnknown />,
  [IconType.BlindMotor]: <IconExalusBlindMotor />,
  [IconType.DinModule]: <IconExalusDinMoble />,
  [IconType.DinModuleWithAntenna]: <IconExalusDinModuleWithAntenna />,
  [IconType.Remote]: <IconExalusRemote />,
  [IconType.RemoteForBlinds]: <IconExalusRemoteForBlinds />,
  [IconType.RemoteForFacadeBlinds]: <IconExalusRemoteForFacadeBlinds />,
  [IconType.MovementSensor]: <IconExalusMovementSensor />,
  [IconType.TemperatureSensor]: <IconExalusTemperatureSensor />,
  [IconType.HumiditySensor]: <IconExalusHumiditySensor />,
  [IconType.DinPowerMeter]: <IconExalusDinPowerMeter />,
  [IconType.Intercom]: <IconExalusIntercom />,
  [IconType.SecurityCamera]: <IconExalusSecurityCamera />,
  [IconType.Multisensor]: <IconExalusMultisensor />,
  [IconType.FloodSensor]: <IconExalusFloodSensor />,
  [IconType.FlushMountedReceiver]: <IconExalusFlushMountedReceiver />,
  [IconType.FlushMountedBlindController]: <IconExalusFlushMountedBlindController />,
  [IconType.FlushMountedDimmer]: <IconExalusFlushMountedDimmer />,
  [IconType.FlushMountedRelay]: <IconExalusFlushMountedRelay />,
  [IconType.FlushMountedGateController]: <IconExalusFlushMountedGateController />,
  [IconType.WindSensor]: <IconExalusWindSensor />,
  [IconType.FlushMountedGateAndGatewayController]: <IconExalusFlushMountedGateAndGatewayController />,
  [IconType.AirPressureSensor]: <IconExalusAirPressureSensor />,
  [IconType.LightBrightnessSensor]: <IconExalusLightBrightnessSensor />,
  [IconType.ReedSensor]: <IconExalusReedSensor />,
  [IconType.FlushMountedTransceiver]: <IconExalusFlushMountedTransceiver />,
  [IconType.FlushMountedRGBLightController]: <IconExalusFlushMountedRGBLightController />,
  [IconType.WallMountedThermoregulator]: <IconExalusWallMountedThermoregulator />,
  [IconType.ImpulseCounter]: <IconExalusImpulseCounter />,
  [IconType.FlushMountedFacadeController]: <IconExalusFlushMountedFacadeController />,
  [IconType.WallMountedGateController]: <IconExalusWallMountedGateController />,
  [IconType.WallMountedRGBLightController]: <IconExalusWallMountedRGBLightController />,
  [IconType.WallMountedDimmer]: <IconExalusWallMountedDimmer />,
  [IconType.WallMountedRemote]: <IconExalusWallMountedRemote />,
  [IconType.DinRelay]: <IconExalusDinRelay />,
  [IconType.DinReceiver]: <IconExalusDinReceiver />,
  [IconType.DinBlindController]: <IconExalusDinBlindController />,
  [IconType.DinTransceiver]: <IconExalusDinTransceiver />,
  [IconType.SocketPlugIn]: <IconExalusSocketPlugIn />,
  [IconType.WallMountedRelay]: <IconExalusWallMountedRelay />,
  [IconType.Retransmitter]: <IconExalusRetransmitter />,
};
