import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../components';
import { useNative } from '../../../../hooks';
import { isAndroid, isIos } from '../../../../utils/helpers/operation-system';
import { DeviceType } from '../../types';

const link = {
  [DeviceType.Desktop]: {
    href: '',
    store: '',
  },
  [DeviceType.Android]: {
    href: 'https://play.google.com/store/apps/details?id=com.zamel.lavva',
    store: 'Google Play',
  },
  [DeviceType.Ios]: {
    href: 'https://apps.apple.com/pl/app/lavva-home/id6475717795',
    store: 'App Store',
  },
};

export const BluetoothNotSupportedDialog: React.FC = () => {
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const [system, setSystem] = useState<DeviceType>(DeviceType.Desktop);
  const { showBluetoothNotSupportedDialog, setShowBluetoothNotSupportedDialog } = useNative();

  useEffect(() => {
    if (isIos) setSystem(DeviceType.Ios);
    else if (isAndroid) setSystem(DeviceType.Android);
  }, []);

  return (
    <DialogConfirmation
      show={showBluetoothNotSupportedDialog}
      setShow={setShowBluetoothNotSupportedDialog}
      header={t('bluetoothNotSupported.header')}
      primaryBtnText={tc('buttons.understand')}
      primaryBtnAction={() => {
        setShowBluetoothNotSupportedDialog(false);
      }}
    >
      <Trans
        t={t}
        i18nKey={`bluetoothNotSupported.${system.toLowerCase()}Content`}
        values={{ linkText: link[system].store }}
      >
        <a href={link[system].href}>{link[system].store}</a>
      </Trans>
    </DialogConfirmation>
  );
};
