import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../../components';
import ExalusFormCreate from '../components/form-create-edit';

const ExalusUserCreate: React.FC = () => {
  const { t } = useTranslation('profile');

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.createNew')} isUnderline />
        </>
      }
    >
      <ExalusFormCreate />
    </Page>
  );
};

export default ExalusUserCreate;
