import React from 'react';

export const IconDevice: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-device"
  >
    <rect width="32" height="32" rx="6" fill="#FF4016" />
    <path
      d="M6.04233 23.5033L25 23.5033L24.9577 17.5406L24.9577 13.814C24.9577 12.995 24.6229 12.2117 24.031 11.6457L21.0881 8.83172C20.5298 8.29791 19.7872 8 19.0148 8L15.4788 8L11.9429 8C11.1705 8 10.4279 8.29791 9.86961 8.83171L6.92671 11.6457C6.3348 12.2117 6 12.995 6 13.814L6 17.5406L6.04233 23.5033Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="10.5" cy="18.5" r="1.25" stroke="white" strokeWidth="1.5" />
    <circle cx="15.5" cy="18.5" r="1.25" stroke="white" strokeWidth="1.5" />
    <circle cx="20.5" cy="18.5" r="1.25" stroke="white" strokeWidth="1.5" />
  </svg>
);
