import React from 'react';

export const IconTV2: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-tv"
  >
    <path
      d="M16.2746 27.5762L15.1094 32.8197H22.6833L21.5181 27.5762"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M12.7793 32.8197H25.0141" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M5.093 27.5762H32.8481C34.5553 27.5762 35.9392 26.1923 35.9392 24.4851V8.09108C35.9392 6.38392 34.5553 5 32.8481 5L5.09138 5.00002C3.38411 5.00002 2.00014 6.38412 2.00031 8.0914L2.00192 24.4854C2.00209 26.1924 3.38597 27.5762 5.093 27.5762Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M35.7909 24.0806H2.58203" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);
