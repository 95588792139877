import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { INetworkService } from 'lavva.exalushome/build/js/INetworkService';
import { useBackdropContext } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import { useHandleDataFrameErrors } from '../../../hooks';
import { isIpValid } from '../../../utils';
import { IpAddress } from '../types';

export const useAddDns = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const [dns, setDns] = useState<IpAddress>({ first: '', second: '', third: '', fourth: '' });
  const { handleError } = useHandleDataFrameErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleSubmit = async () => {
    turnOnBackdrop();
    const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
    const result: Status = await networkService.AddDnsServerAsync(
      [dns.first, dns.second, dns.third, dns.fourth].join('.'),
    );

    if (result === Status.OK) {
      toastSuccess({ content: t('exalusNetwork.dnsAdded') });
      history.goBack();
    } else {
      handleError(result);
    }

    turnOffBackdrop();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 3) {
      setDns({ ...dns, [event.target.name]: event.target.value });
    }
  };

  const submitDisabled = useMemo(() => !isIpValid(dns), [dns]);

  return {
    handleSubmit,
    handleChange,
    dns,
    submitDisabled,
  };
};
