import React from 'react';
import c from 'classnames';
import './index.scss';

interface IconSwitchProps {
  isOn: boolean;
  big?: boolean;
  noState?: boolean;
}

export const IconThreeDrops: React.FC<IconSwitchProps> = ({ isOn = true, big, noState }) => {
  return (
    <svg
      className={c('icon-sensor icon-sensor-5 icon-three-drops', { big: big, 'no-state': noState })}
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3799 22.2944C25.3799 27.6548 21.1631 32.0003 15.9614 32.0003C10.7598 32.0003 6.54297 27.6548 6.54297 22.2944C6.54297 16.934 9.36851 13.2945 15.9614 6.0592C22.3831 13.3827 25.3799 16.934 25.3799 22.2944Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M10.5422 19.5943C10.5422 22.3097 8.40608 24.511 5.77109 24.511C3.13609 24.511 1 22.3097 1 19.5943C1 16.8789 2.43133 15.0353 5.77109 11.3701C9.0241 15.08 10.5422 16.8789 10.5422 19.5943Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      {isOn ? (
        <path
          d="M21.8263 21.2627C21.8263 22.9624 21.2439 24.5219 20.2734 25.7408M14.9707 28.3275C16.2513 28.3275 17.4498 27.9657 18.4754 27.3358"
          stroke="#15141D"
          strokeWidth="1.5"
        />
      ) : null}
      <path
        d="M25.2077 21.9411C29.2063 21.9411 32.6667 18.6007 32.6667 14.4801C32.6667 10.3596 30.3631 7.62963 25.4267 2C22.2908 5.44138 20.2637 7.82454 19.1738 10.1176"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      {isOn ? (
        <path
          d="M29.7238 15.4351C29.7238 14.8889 29.668 14.3764 29.555 13.8786M26.5254 8.76184C27.636 10.0945 28.4474 11.1966 28.9751 12.2693"
          stroke="#15141D"
          strokeWidth="1.5"
        />
      ) : null}
    </svg>
  );
};
