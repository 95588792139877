import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables,
  UserProfileError,
} from '../../../data-access/gql-types/graphql';
import { UPDATE_USER_LANGUAGE } from '../../../data-access/mutations/user';
import { useBackdropContext } from '../../../hooks';
import { useUserProfileErrors } from '../../../hooks/backend-errors/use-user-profile-errors';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { toastSuccess } from '../../../utils/toast';

export const useLanguageSettings = () => {
  const { t, i18n } = useTranslation('profile');
  const { handleErrors } = useUserProfileErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [updateLanguage] = useMutation<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(
    UPDATE_USER_LANGUAGE,
  );

  const optionsLocale = [
    { value: 'en', label: t('english'), icon: <img src="/images/en-flag.svg" alt={t('english')} /> },
    { value: 'pl', label: t('polish'), icon: <img src="/images/pl-flag.svg" alt={t('polish')} /> },
  ];

  const optionsLocaleDefaultIndex = useMemo(() => {
    const optionIndex = optionsLocale.findIndex((option) => option.value === i18n.language);
    return optionIndex < 0 ? 0 : optionIndex;
  }, [optionsLocale, i18n.language]);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);

    if (!isLocalApp) {
      turnOnBackdrop();
      updateLanguage({
        variables: {
          input: {
            language: lang,
          },
        },
        onCompleted: (data) => {
          turnOffBackdrop();
          if (data.updateUserLanguage.result?.ok) {
            toastSuccess({ content: t('editLanguageSuccess') });
          } else {
            handleErrors((data.updateUserLanguage.errors as UserProfileError[]) || []);
          }
        },
      });
    } else {
      toastSuccess({ content: t('editLanguageSuccess') });
    }
  };

  return {
    optionsLocale,
    optionsLocaleDefaultIndex,
    changeLanguage,
  };
};
