import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputSwitcher } from '../../../../components';
import { useVibrationSettings } from '../../hooks/use-vibration-settings';

export const VibrationSettings: React.FC = () => {
  const { t } = useTranslation('profile');
  const { optionsVibration, optionsVibrationDefaultIndex, changeVibration } = useVibrationSettings();

  return (
    <InputSwitcher
      options={optionsVibration}
      defaultOptionIndex={optionsVibrationDefaultIndex}
      label={t('app-vibration')}
      onChange={(inputElement) => changeVibration(inputElement.value)}
    />
  );
};
