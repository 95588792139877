import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  onClick?: () => void;
  className?: string;
}

export const IconDelete: React.FC<PropsInterface> = ({ onClick, className }) => {
  return (
    <div
      className={classNames('icon-delete', {
        clickable: onClick,
        [className as string]: className,
      })}
      onClick={onClick}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.66016 9.13086L9.66016 17.9054"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="4.98047"
          y="4.45117"
          width="14.0392"
          height="17.549"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="9.66016"
          y="0.941406"
          width="4.67975"
          height="3.50981"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3398 9.13086L14.3398 17.9054"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.05566 4.45117L21.9446 4.45117"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
