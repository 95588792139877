import { IconWindFan, IconBorderFan } from '../../../../../../../components/icons';
import { AvailableIcon, ChannelIconTypeEnum } from '../../../../../../../types';

export const windIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconWindFan isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_29',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BORDER_FAN,
    component: <IconBorderFan isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_30',
  },
];
