import React from 'react';

export const IconExalusHumiditySensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.738281" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23111_32020)">
      <path
        d="M28.7383 9.85926L29.2902 10.3545C29.2902 10.3545 29.2902 10.3546 29.2901 10.3546L28.7383 9.85926ZM28.4199 10.4883C28.4198 10.488 28.4233 10.4902 28.4306 10.4957C28.4237 10.4913 28.42 10.4886 28.4199 10.4883ZM28.8393 10.8795C29.2097 11.2574 29.7441 11.8499 30.3929 12.6147C31.715 14.1729 33.4824 16.4109 35.2503 18.9183C37.0196 21.4277 38.7784 24.1915 40.0916 26.8037C41.4177 29.4413 42.2383 31.8265 42.2383 33.6093C42.2383 41.0651 36.1941 47.1093 28.7383 47.1093C21.2824 47.1093 15.2383 41.0651 15.2383 33.6093C15.2383 31.8294 16.0569 29.4952 17.3817 26.9214C18.694 24.3719 20.4519 21.6825 22.2222 19.2209C23.9905 16.762 25.7604 14.5451 27.0891 12.9421C27.7532 12.141 28.3062 11.4942 28.6927 11.0482C28.7447 10.9883 28.7936 10.932 28.8393 10.8795Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M36.5939 34.8594C36.5939 36.5591 36.0115 38.1185 35.041 39.3375M29.7383 41.9242C31.0188 41.9242 32.2174 41.5624 33.243 40.9325"
        stroke="#15141D"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_23111_32020">
        <rect width="38" height="38" fill="white" transform="translate(9.73828 9.85938)" />
      </clipPath>
    </defs>
  </svg>
);
