import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, dragOverlayStyle, Header, NavHeader, Page, SubmitButton } from '../../components';
import {
  ActionDetailsQueryVariables,
  EditActionMutation,
  EditActionMutationVariables,
  EditActionInput,
  Query,
} from '../../data-access/gql-types/graphql';
import { EDIT_ACTION } from '../../data-access/mutations/action';
import { ACTION_DETAILS } from '../../data-access/queries/actions';
import { useInstallation } from '../../hooks';
import { SortChannelActionInterface } from '../../types';
import { sortByOrder } from '../../utils/helpers';
import './index.scss';
import Item from './item/item';
import ItemSortable from './item/item-sortable';

const ActionChannelSort: React.FC = () => {
  const { t } = useTranslation('action');
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const { selectedInstallationId } = useInstallation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);
  const [channelActions, setChannelActions] = useState<SortChannelActionInterface[]>([]);
  const [editActionVariables, setEditActionVariables] = useState<EditActionInput>({
    id: '',
    name: '',
    installationId: selectedInstallationId,
    channels: [],
    iconName: '',
  });

  const { data } = useQuery<Query, ActionDetailsQueryVariables>(ACTION_DETAILS, {
    variables: { actionId, installationId: selectedInstallationId },
  });

  const [editAction, { loading }] = useMutation<EditActionMutation, EditActionMutationVariables>(EDIT_ACTION, {
    refetchQueries: [
      {
        query: ACTION_DETAILS,
        variables: {
          actionId,
          installationId: selectedInstallationId,
        },
      },
    ],
    onCompleted: (response) => {
      if (response?.editAction?.idResponse?.id) {
        history.goBack();
      }
    },
  });

  useEffect(() => {
    if (data && data.userAction?.action) {
      const userAction = data.userAction;
      const channels = userAction.action.channelActions
        .map((channel) => ({
          channelId: channel.channelId,
          channelType: channel.channelType,
          order: channel.order,
          parameter: { activityType: channel.activityType, value: channel.value },
        }))
        .sort(sortByOrder);

      setChannelActions(
        channels.map((channel) => ({ ...channel, id: channel.channelId })) as SortChannelActionInterface[],
      );

      setEditActionVariables({
        id: userAction.action.id,
        name: userAction.action.name,
        installationId: selectedInstallationId,
        iconName: userAction.iconName,
        channels,
      });
    }
  }, [data, selectedInstallationId]);

  const onDragStart = useCallback((event: dndCore.DragStartEvent) => setDndActiveId(event.active.id), [setDndActiveId]);

  const onDragEnd = useCallback(
    (event: dndCore.DragEndEvent) => {
      if (!event.active || !event.over) {
        return;
      }

      if (event.active.id !== event.over.id && channelActions) {
        const newList = Array.from(channelActions);
        const oldIndex = newList.findIndex((item) => item.id === event.active.id);
        const newIndex = newList.findIndex((item) => item.id === event.over?.id);

        const sortedChannels = dndSortable.arrayMove(newList, oldIndex, newIndex);
        const changeOrderItems = sortedChannels.map((item, index: number) => ({
          channelId: item.id,
          channelType: item.channelType,
          order: index,
          parameter: item.parameter,
        }));

        setEditActionVariables((variables) => ({ ...variables, channels: changeOrderItems }));
        setChannelActions(sortedChannels);
      }

      setIsValid(true);
      setDndActiveId(null);
    },
    [channelActions],
  );

  const onSubmit = useCallback(() => {
    editAction({
      variables: { input: editActionVariables },
    });
  }, [editActionVariables]);

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('sort')} />
        </>
      }
    >
      <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={channelActions}>
        {channelActions.map((item) => (
          <ItemSortable key={item.id} list={channelActions} id={item.id} />
        ))}
        <dndCore.DragOverlay>
          {dndActiveId && <Item isDraggable style={dragOverlayStyle} id={dndActiveId} list={channelActions} />}
        </dndCore.DragOverlay>
      </DragAndDrop>
      <SubmitButton disabled={!isValid} isLoading={loading} onClick={onSubmit} />
    </Page>
  );
};
export default ActionChannelSort;
