import React from 'react';

export const IconInstallation13: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="8.16699" y="12.6665" width="23.75" height="14.25" stroke="#15141D" />
    <path
      d="M9.75032 3.1665L20.042 3.1665L30.3337 3.1665L36.667 12.6665L3.41699 12.6665L9.75032 3.1665Z"
      stroke="#15141D"
    />
    <path d="M19.25 26.9165V34.0415" stroke="#15141D" />
    <path d="M11.333 26.9165L11.333 34.0415" stroke="#15141D" />
    <path d="M27.167 26.9165V34.0415" stroke="#15141D" />
    <path d="M3.41699 26.9165L35.8753 26.9165" stroke="#15141D" />
    <path d="M15.292 19.7915H24.792" stroke="#15141D" />
  </svg>
);
