import React from 'react';
import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { useInstallation, useNative } from '../../../hooks';
import { AboutSettings, LanguageSettings, ThemeSettings, VibrationSettings } from './sections';
import { ReadingChatSettings } from './sections/reading-chat';

const ApplicationSettings: React.FC = () => {
  const { integrationType } = useInstallation();
  const { vibrationSupported } = useNative();

  return (
    <>
      <div className="grid-list-24">
        <LanguageSettings />
        <ThemeSettings />
        {vibrationSupported && <VibrationSettings />}
        {integrationType === IntegrationType.Exalus && <ReadingChatSettings />}
      </div>
      <AboutSettings />
    </>
  );
};

export default ApplicationSettings;
