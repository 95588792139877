import React from 'react';
import { IconAutomatic, IconErrorSmall } from '../../icons';
import './index.scss';

interface PropsInferface {
  isValid: boolean;
}

export const TriggerIcon: React.FC<PropsInferface> = ({ isValid }) => {
  return (
    <div className="trigger-icon">
      <div className="trigger-icon__icon">
        <IconAutomatic className="icon-automatic--accent" />
        {!isValid && <IconErrorSmall size={18} />}
      </div>
    </div>
  );
};
