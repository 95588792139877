import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowButton from '../../../../components/arrow-button';
import { useDevicesAndChannels } from '../../../../hooks';
import { useActionTaskVariants } from '../../hooks/use-action-task-variants';
import { actionTaskVariants } from '../../utils/action-tasks';
import ActionTasksVariants from '../../components/task-variants';

const LavvaActionTasksVariants: React.FC = () => {
  const { t } = useTranslation('action');
  const { selectTaskVariant, getChannelsByVariant } = useActionTaskVariants();
  const { channelList } = useDevicesAndChannels();

  const filteresActionTasks = useMemo(() => {
    return actionTaskVariants.filter((v) => getChannelsByVariant(v.variant).length);
  }, [actionTaskVariants, channelList]);

  return (
    <ActionTasksVariants listCount={filteresActionTasks.length}>
      {filteresActionTasks.map((x) => (
        <ArrowButton
          key={x.title}
          title={t(x.title)}
          icon={x.icon}
          iconAccent
          className="m-b-24"
          onClick={() => selectTaskVariant(x.variant)}
        />
      ))}
    </ActionTasksVariants>
  );
};

export default LavvaActionTasksVariants;
