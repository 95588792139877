import { gql } from '@apollo/client';

export const MOBILE_REGISTRATIONS = gql`
  query PushReceiversRegistrations {
    allPushReceiversRegistrations {
      id
      name
      pushReceiverType
    }
  }
`;
