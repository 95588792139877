import React from 'react';
import '../index.scss';

export const IconListView: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--list-view"
  >
    <rect x="3" y="3" width="14" height="6" rx="1" stroke="#15141D" />
    <rect x="3" y="11" width="14" height="6" rx="1" stroke="#15141D" />
  </svg>
);
