import { gql } from '@apollo/client';

export const ALL_INVITATIONS = gql`
  query AllInvitations($includeCreatedByMe: Boolean!, $includeInvitedMe: Boolean!) {
    allInvitations(includeCreatedByMe: $includeCreatedByMe, includeInvitedMe: $includeInvitedMe) {
      id
      installationId
      invitedUserEmail
      createdByUserId
      invitedUserId
      status
      createdAt
      sharingPayload {
        wholeInstallationShare
        accessType
        sharedItems {
          id
          name
          itemType
          grantedUses
        }
      }
    }
  }
`;
