import React from 'react';

export const IconPhone: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.04269 2.3193C5.35323 2.08571 5.67425 1.88762 5.99774 1.72404C6.41215 1.51448 6.90398 1.63405 7.23235 1.96242L13.4602 8.19028C14.2889 9.01898 14.3598 10.3269 13.6214 11.237C11.2551 14.1538 9.62414 16.6782 14.5624 21.6165C18.076 25.13 21.9688 24.2563 24.5997 22.5883C25.6836 21.9011 27.149 21.8791 28.0566 22.7866L34.0487 28.7788C34.3963 29.1264 34.4172 29.8018 34.1476 30.2129C24.4864 44.9439 -9.03571 12.9089 5.04269 2.3193Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
