import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import {
  OnGateConfigurationChangeSubscription,
  OnGateConfigurationChangeSubscriptionVariables,
  OnGateElectricStrikeStatusChangedSubscription,
  OnGateElectricStrikeStatusChangedSubscriptionVariables,
  OnGatePositionChangeSubscription,
  OnGatePositionChangeSubscriptionVariables,
} from '../../../../data-access/gql-types/graphql';
import {
  ON_GATE_CALIBRATE_STATUS_CHANGE,
  ON_GATE_CONFIGURATION_CHANGE,
  ON_GATE_ELECTRIC_STRIKE_STATUS_CHANGED,
  ON_GATE_POSITION_CHANGE,
  ON_REQUEST_ACK_RECEIVED,
} from '../../../../data-access/subscriptions/lavva-devices';
import { useInstallation } from '../../../../hooks';
import { useUpdateCommonLavvaState } from '../common-lavva-state';
import { ChannelSubscriptionParams } from '../types';
import { useLavvaDeviceGateUpdate } from './update';

export const useGateSubscriptions = ({ channelId }: ChannelSubscriptionParams): void => {
  const {
    updateLavvaGatePosition,
    updateLavvaGateElectricalStrike,
    onGateCalibrateStatusChange,
    updateLavvaGateConfiguration,
  } = useLavvaDeviceGateUpdate();
  const { onRequestAckReceived } = useUpdateCommonLavvaState();
  const { skipLavvaFetch } = useInstallation();
  const { data: gatePosition } = useSubscription<
    OnGatePositionChangeSubscription,
    OnGatePositionChangeSubscriptionVariables
  >(ON_GATE_POSITION_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: gateElectricStrike } = useSubscription<
    OnGateElectricStrikeStatusChangedSubscription,
    OnGateElectricStrikeStatusChangedSubscriptionVariables
  >(ON_GATE_ELECTRIC_STRIKE_STATUS_CHANGED, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: gateConfiguration } = useSubscription<
    OnGateConfigurationChangeSubscription,
    OnGateConfigurationChangeSubscriptionVariables
  >(ON_GATE_CONFIGURATION_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });
  const { data: gateCalibration } = useSubscription(ON_GATE_CALIBRATE_STATUS_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (gatePosition) updateLavvaGatePosition(gatePosition.onGatePositionChange);
  }, [gatePosition]);

  useEffect(() => {
    if (gateElectricStrike) updateLavvaGateElectricalStrike(gateElectricStrike.onGateElectricStrikeStatusChanged);
  }, [gateElectricStrike]);

  useEffect(() => {
    if (gateConfiguration) updateLavvaGateConfiguration(gateConfiguration.onGateConfigurationChange);
  }, [gateConfiguration]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);

  useEffect(() => {
    if (gateCalibration) onGateCalibrateStatusChange(gateCalibration.onGateCalibrateStatusChange);
  }, [gateCalibration]);
};
