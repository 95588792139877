import React from 'react';

export const IconBarrel: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-barrel"
  >
    <path
      d="M31.7295 7.19075L27.2161 7.17773L23.186 7.16472L19.1164 7.15171L15.0603 7.14049L11.045 7.12748L6.54688 7.11447C7.2051 5.88551 7.9737 4.69738 8.84999 3.56714H29.3851C30.2802 4.72161 31.0619 5.93397 31.7295 7.19075Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.7295 30.8092C31.0619 32.066 30.2802 33.2784 29.3851 34.4328H8.84999C7.97325 33.3026 7.2051 32.1145 6.54688 30.8855L11.045 30.8725L15.0603 30.8595L19.1164 30.8483L23.186 30.8353L27.2161 30.8222L31.7295 30.8092Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.315 27.1592C33.1845 27.0829 33.0297 27.038 32.8601 27.038L28.7662 27.051L24.7379 27.0622L19.1186 27.0788L13.5126 27.0972L9.49553 27.1085L5.37209 27.1215C5.21549 27.1215 5.07371 27.1605 4.94852 27.2278C3.10801 21.9032 3.10801 16.0941 4.95032 10.7695C5.07326 10.8368 5.21684 10.8758 5.37164 10.8758L9.49508 10.8888L13.5122 10.9L19.1181 10.9189L24.7352 10.9355L28.7636 10.9467L32.8592 10.9597C33.027 10.9597 33.1818 10.9166 33.3124 10.8386C35.1233 16.1219 35.1233 21.8754 33.3142 27.1592H33.315Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1191 27.0788V10.9189"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1191 34.4328V30.8483"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1191 7.15171V3.56714"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7602 31.0108L26 31C25.4947 32.0353 24.9042 33.0393 24.2279 34H14.7512C14.0853 33.0516 13.5007 32.0629 13 31.0418L16.2279 31.0309L19.4886 31.0216L22.7602 31.0108Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9509 6.89144L13.1055 6.8813C14.0056 5.76691 15.057 4.68579 16.2552 3.65051H21.6493C22.8559 4.69269 23.9125 5.7803 24.8152 6.90321L18.9503 6.89144H18.9509Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.2523 26.9654L19.4988 26.9818L11.7639 27C9.41202 21.805 9.41202 16.195 11.7639 11L19.4988 11.0186L27.2492 11.035C29.5832 16.2083 29.5832 21.7921 27.2517 26.965L27.2523 26.9654Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.3816 7H6.61763C6.23882 7 5.89253 7.21404 5.72316 7.55287L4.72339 9.55287C4.39102 10.2178 4.87452 11 5.61786 11H32.3818C33.1252 11 33.6087 10.2176 33.2762 9.5527L32.2759 7.5527C32.1065 7.21396 31.7603 7 31.3816 7Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M31.3816 31H6.61763C6.23882 31 5.89253 30.786 5.72316 30.4471L4.72339 28.4471C4.39102 27.7822 4.87452 27 5.61786 27H32.3818C33.1252 27 33.6087 27.7824 33.2762 28.4473L32.2759 30.4473C32.1065 30.786 31.7603 31 31.3816 31Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
