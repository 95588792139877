import React from 'react';

export const IconExalusFlushMountedReceiver: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.580078" y="0.71875" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23191_242630)">
      <g clipPath="url(#clip1_23191_242630)">
        <path
          d="M27.293 17.1416L27.293 12.9055"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.293 17.1387L27.293 12.9026"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7617 28.4375L15.9978 28.4375"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1152 28.4375L43.3513 28.4375"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3105 17.4551L19.3059 20.4504"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.2754 17.4551L35.28 20.4504"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0342 43.2595C29.3061 43.2595 30.3371 42.3691 30.3371 41.2709V39.2822M30.3371 39.2822C30.973 39.2822 31.4885 38.8371 31.4885 38.2879C31.4885 36.5994 31.887 35.083 32.4133 33.6996C32.9704 32.235 34.5917 28.9106 34.2844 27.374V27.374C33.1195 21.5494 27.2949 22.0789 27.2949 22.0789M30.3371 39.2822H26.8828"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.3371 39.2881V41.2767C30.3371 42.375 29.3061 43.2653 28.0342 43.2653H26.8828M30.3371 39.2881C30.973 39.2881 31.4885 38.8429 31.4885 38.2938C31.4885 36.6053 31.887 35.0889 32.4133 33.7055C32.9704 32.2409 34.5917 28.9164 34.2844 27.3799V27.3799C33.1195 21.5553 27.2949 22.0848 27.2949 22.0848M30.3371 39.2881H26.8828"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3465 39.2881V41.2767C24.3465 42.375 25.3775 43.2653 26.6493 43.2653H27.8008M24.3465 39.2881C23.7106 39.2881 23.195 38.8429 23.195 38.2938C23.195 36.6053 22.7966 35.0889 22.2703 33.7055C21.7132 32.2409 20.0919 28.9164 20.3992 27.3799V27.3799C21.5641 21.5553 27.3887 22.0848 27.3887 22.0848M24.3465 39.2881H27.8008"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4605 27.5971L21.2385 28.3134L21.2385 28.3134L21.4605 27.5971ZM33.8875 28.1462C34.2799 28.0137 34.4907 27.5881 34.3582 27.1957C34.2258 26.8032 33.8002 26.5925 33.4078 26.7249L33.8875 28.1462ZM20.7174 28.1519L21.2385 28.3134L21.6826 26.8807L21.1615 26.7192L20.7174 28.1519ZM21.2385 28.3134C25.4188 29.6092 29.7232 29.5518 33.8875 28.1462L33.4078 26.7249C29.541 28.0301 25.5599 28.0825 21.6826 26.8807L21.2385 28.3134Z"
          fill="#15141D"
        />
      </g>
      <rect x="26.8613" y="26" width="19.7188" height="19.7188" rx="2.07566" fill="white" />
      <path
        d="M30.528 43.6426L42.8849 43.6426C43.762 43.6426 44.4736 42.9326 44.4754 42.0555L44.5016 29.6691C44.5034 28.7894 43.7908 28.0752 42.9111 28.0752L36.6865 28.0752L30.528 28.0752C29.6496 28.0752 28.9375 28.7873 28.9375 29.6657L28.9375 42.0521C28.9375 42.9305 29.6496 43.6426 30.528 43.6426Z"
        fill="white"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect width="9.34046" height="0.778372" transform="matrix(0 1 -1 0 32.7949 31.1885)" fill="white" />
      <circle
        cx="32.3531"
        cy="31.6303"
        r="1.0918"
        transform="rotate(90 32.3531 31.6303)"
        stroke="#15141D"
        strokeWidth="1.3"
      />
      <circle
        cx="32.3531"
        cy="36.0922"
        r="1.0918"
        transform="rotate(90 32.3531 36.0922)"
        stroke="#15141D"
        strokeWidth="1.3"
      />
      <circle
        cx="32.3531"
        cy="40.5541"
        r="1.0918"
        transform="rotate(90 32.3531 40.5541)"
        stroke="#15141D"
        strokeWidth="1.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_23191_242630">
        <rect width="38" height="38" fill="white" transform="translate(9.58008 9.71875)" />
      </clipPath>
      <clipPath id="clip1_23191_242630">
        <rect width="35.2441" height="35.2441" fill="white" transform="translate(9.58008 10.7188)" />
      </clipPath>
    </defs>
  </svg>
);
