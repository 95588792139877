import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  FacadeRemoteButtonState,
  IDeviceState as IChannelState,
  WindThresholdStateEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { IconToastWarning2 } from '../../../../../../../components/icons/icon-toast-warning-2';
import { BlindControlButtons } from '../../../../control-buttons/blind';
import { useFacadeControl } from '../../../hooks/use-facade-control';
import { useWindControl } from '../../../hooks/use-wind-control';

interface ComponentProps {
  channel: IDeviceChannel;
  isListItem?: boolean;
}

const FacadeControls: React.FC<ComponentProps> = ({ channel, isListItem }) => {
  const { t } = useTranslation('channel-details');
  const { open, close, stop } = useFacadeControl(channel);
  const { windThreshold } = useWindControl(channel);

  const remoteButtonState = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState,
    ) as IChannelState<FacadeRemoteButtonState>;
  }, [channel.States]);

  if (windThreshold === WindThresholdStateEnum.Exceeded) {
    return (
      <div className="device-box__warning device-box__warning--no-padding small-line-height">
        <IconToastWarning2 size={16} colorError />
        <span className={'m-l-8'}>{t('exalus.windThresholdExceeded.controlBlock')}</span>
      </div>
    );
  }

  return (
    <BlindControlButtons
      close={close}
      stop={stop}
      open={open}
      isListItem={isListItem}
      remoteButtonState={remoteButtonState}
    />
  );
};

export default FacadeControls;
