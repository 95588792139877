import React from 'react';

export const IconApple: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7227 4.62473C11.5547 4.62473 12.5977 4.04494 13.2188 3.2719C13.7812 2.57133 14.1914 1.59294 14.1914 0.614555C14.1914 0.481688 14.1797 0.348822 14.1562 0.240112C13.2305 0.276349 12.1172 0.88029 11.4492 1.68957C10.9219 2.30559 10.4414 3.2719 10.4414 4.26236C10.4414 4.40731 10.4648 4.55226 10.4766 4.60057C10.5352 4.61265 10.6289 4.62473 10.7227 4.62473ZM7.79297 19.2401C8.92969 19.2401 9.43359 18.455 10.8516 18.455C12.293 18.455 12.6094 19.216 13.875 19.216C15.1172 19.216 15.9492 18.0322 16.7344 16.8727C17.6133 15.544 17.9766 14.2395 18 14.1791C17.918 14.1549 15.5391 13.1524 15.5391 10.338C15.5391 7.89809 17.4141 6.79892 17.5195 6.71437C16.2773 4.87838 14.3906 4.83007 13.875 4.83007C12.4805 4.83007 11.3438 5.69974 10.6289 5.69974C9.85547 5.69974 8.83594 4.87838 7.62891 4.87838C5.33203 4.87838 3 6.83515 3 10.5313C3 12.8263 3.86719 15.2541 4.93359 16.8243C5.84766 18.153 6.64453 19.2401 7.79297 19.2401Z"
      fill="black"
    />
  </svg>
);
