import { IconCurtainBlind, IconRollerBlind, IconWoodenBlind } from '../../../../../../../components/icons';
import { AvailableIcon, ChannelIconTypeEnum } from '../../../../../../../types';

export const blindIcons = (position?: number): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconWoodenBlind position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_14',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_ROLLER_BLIND,
    component: <IconRollerBlind position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_15',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_CURTAIN_BLIND,
    component: <IconCurtainBlind position={position !== undefined ? position : 50} />,
    oldIconName: 'DEVICE_ICON_17',
  },
];
