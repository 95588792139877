import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInTimeZone } from 'date-fns-tz';
import * as duration from 'duration-fns';
import { IconBullet } from '../../../../components';
import { Trigger } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';
import { leadingZero } from '../../../../utils/helpers';

interface PropsInterface {
  trigger: Trigger;
}

export const TriggerNextExecution: React.FC<PropsInterface> = ({ trigger }) => {
  const { t } = useTranslation('action');
  const { selectedInstallation } = useInstallation();

  const nextFireTime = useMemo(() => {
    const time = trigger?.timeCondition?.nextFireTime?.time;
    if (time) {
      const { hours, minutes } = duration.parse(time);
      const parsedTime = `${leadingZero(hours)}:${leadingZero(minutes)}`;
      return formatInTimeZone(
        `${trigger?.timeCondition?.nextFireTime?.date}T${parsedTime}Z`,
        selectedInstallation?.payload?.timeZone || 'Europe/Warsaw',
        'dd.MM.yyyy, HH:mm',
      );
    }
    return t('trigger.nextFireTimeNull');
  }, [trigger?.timeCondition]);

  return (
    <div className="trigger-details__execution--next">
      <IconBullet />
      <p>
        {t('trigger.nextFireTime')}: {nextFireTime}
      </p>
    </div>
  );
};
