import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { CustomBackdrop, DialogConfirmation, Header, NavHeader, Page, PopUpNav } from '../../../../../components';
import { IconDelete, IconPadlock, IconUserEdit } from '../../../../../components/popup-nav/icons';
import { ROUTES } from '../../../../../routes';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusUser, useExalusUserImg } from '../../../hooks';
import { useUsers } from '../hooks/use-users';
import ExalusUserMainInfo from './user-data/main-info';
import ExalusUserInfo from './user-data/user-info';

const ExalusUserDetails: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const { userId } = useParams<{ userId: string }>();
  const { usersApi } = useExalusServicesContext();
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const { data: user, isLoading: userLoading } = useExalusUser(userId);
  const { data: image, isLoading: imageLoading } = useExalusUserImg(user as IUser);
  const { deleteUser } = useUsers();

  const canEdit = useMemo(() => {
    if (user) return usersApi.CanEditUser(user);
    return false;
  }, [user]);

  const handleDelete = () => deleteUser(userId, setDeletePopup);

  return (
    <Page
      isStickyHeader
      whiteContent
      className="exalus-user-details"
      header={
        <>
          <NavHeader>
            {canEdit && (
              <PopUpNav
                dark
                links={[
                  {
                    onClick: () => history.push(ROUTES.UserEdit(userId)),
                    label: t('exalus.editUser'),
                    icon: <IconUserEdit />,
                  },
                  {
                    onClick: () => history.push(ROUTES.ChangeUserPassword(userId)),
                    label: t('exalus.changePassword'),
                    hasLineBelow: true,
                    icon: <IconPadlock />,
                  },
                  ...(canEdit
                    ? [
                        {
                          onClick: () => setDeletePopup(true),
                          label: t('exalus.deleteUser'),
                          icon: <IconDelete />,
                        },
                      ]
                    : []),
                ]}
              />
            )}
          </NavHeader>
          <Header>
            <ExalusUserMainInfo user={user} image={image} />
          </Header>
        </>
      }
    >
      <ExalusUserInfo user={user} />
      <DialogConfirmation
        show={deletePopup}
        setShow={setDeletePopup}
        header={t('exalus.deleteTitle')}
        content={t('exalus.deleteConfirmation')}
        primaryBtnText={t('exalus.deleteUser')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleDelete}
        secondaryBtnAction={() => setDeletePopup(false)}
      />
      <CustomBackdrop loading={userLoading || imageLoading} />
    </Page>
  );
};

export default ExalusUserDetails;
