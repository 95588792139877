import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { convertErrorsToOneString } from '../../../../utils/helpers';
import { toastError } from '../../../../utils/toast';
import { MeterTariffError } from '../types/meter';

export const useMeterTariffErrors = () => {
  const { t } = useTranslation('backend-validation');

  const handleMeterTariffErrors = (error: AxiosError) => {
    const response = error.response;

    if (response) {
      if ('errors' in (response as AxiosResponse).data) {
        const errors: string[] = [];
        const errorObject = (response as AxiosResponse).data.errors as Record<string, string>;

        Object.keys(errorObject).map((x) => {
          const errorParsed = JSON.parse(errorObject[x]) as MeterTariffError;
          errors.push(t(errorParsed.ErrorCode));
        });

        toastError({ content: convertErrorsToOneString(errors) });
      }
    }
  };

  return {
    handleMeterTariffErrors,
  };
};
