import React from 'react';

export const IconUmbrella: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-umbrella"
  >
    <path
      d="M9.41165 14.2326C7.26368 14.2326 5.47432 15.7836 5.08081 17.8375H5C5 9.91092 11.0853 3.48538 18.5913 3.48538C26.0972 3.48538 32.182 9.91092 32.182 17.8375H32.1012C31.7077 15.7836 29.9184 14.2326 27.7704 14.2326C25.6224 14.2326 23.8331 15.7836 23.4391 17.8375H22.9216C22.5281 15.7836 20.7388 14.2326 18.5908 14.2326C16.4428 14.2326 14.6535 15.7836 14.2595 17.8375H13.742C13.3485 15.7836 11.5592 14.2326 9.41119 14.2326H9.41165Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M18.5898 2.94955V2" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M18.5898 3.48538C18.5898 3.48538 24.3877 8.71802 23.2329 17.8375"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.5923 3.48538C18.5923 3.48538 12.7935 8.71802 13.9492 17.8375"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M18.5547 14.2941V35.2687" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
