import {
  IconBarrier,
  IconBulb,
  IconDoor,
  IconHorizontalDoubleGate,
  IconHorizontalGate,
  IconRGBCircles,
  IconSmallBulb,
  IconSquareMeter,
  IconSun,
  IconVerticalGate,
  IconWoodenBlind,
} from '../../../components/icons';

export const deviceTypeIcon = {
  Switch: <IconSun isOn />,
  Blind: <IconWoodenBlind position={33} />,
  Meter: <IconSquareMeter isOn />,
  Optimizer: <IconSquareMeter isOn />,
  Light: <IconSun isOn />,
  Gate: <IconHorizontalGate position={33} />,
  GateModule: <IconVerticalGate position={33} />,
  TwoGates: <IconHorizontalDoubleGate position={33} />,
  GateAndWicket: <IconDoor position={33} />,
  TwoDoorsWithElectricStrikes: <IconDoor position={33} />,
  RisingArmBarrier: <IconBarrier position={33} />,
  W: <IconBulb isOn />,
  Rgb: <IconRGBCircles isOn />,
  Ww: <IconSmallBulb isOn />,
};
