import React from 'react';

interface IconCoverProps {
  position?: number; // In percents from 0 to 100
}

export const IconHorizontalGate: React.FC<IconCoverProps> = ({ position = 100 }) => {
  return (
    <svg
      className="icon-cover icon-horizontal-gate"
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5625"
        y="0.75"
        width="30.875"
        height="28.5"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {position > 10 && (
        <path
          d="M8.6875 1.34375L8.6875 29.25"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {position > 40 && (
        <path
          d="M17 1.34375L17 29.25"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {position > 75 && (
        <path
          d="M25.3125 1.34375L25.3125 29.25"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};
