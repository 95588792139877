import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStatePage, Header, NavHeader, Page } from '../../../../components';
import FixedTwoButtons from '../../../../components/fixed-two-buttons';
import { StatusTypeEnum } from '../../../../types';

interface ComponentProps {
  addNewTask: () => void;
  handleSubmit: () => void;
  taskListLength: number;
  tasks: ReactNode[];
}

const ActionCreateTasks: React.FC<ComponentProps> = ({ addNewTask, handleSubmit, taskListLength, tasks }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.tasks.header')} isUnderline />
        </>
      }
    >
      <>
        {taskListLength ? (
          tasks
        ) : (
          <EmptyStatePage type={StatusTypeEnum.INTEGRATION} caption={t('action.create.tasks.taskList.empty')} />
        )}

        <FixedTwoButtons
          titlePrimary={t('action.create.tasks.taskList.button')}
          titleSecondary={tc('buttons.save')}
          handleClickPrimary={addNewTask}
          handleClickSecondary={handleSubmit}
          disabledSecondary={!taskListLength}
        />
      </>
    </Page>
  );
};

export default ActionCreateTasks;
