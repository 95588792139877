import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { MeasuredTemperatureState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { SubmitButton } from '../../../../../../../../components';
import { ComparisonMethodSelect } from '../../../../components/comparison-method-select';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { InputString } from '../../../../components/input-string';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';

export const TemperatureParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { channels } = useExalusCreateActionContext();
  const [temperature, setTemperature] = useState<string>('0');
  const {
    triggerMethod,
    seq,
    timeSec,
    conditionType,
    onlyParametersEdit,
    changeableAtMeetCondition,
    setConditionType,
    setTimeSec,
    setTriggerMethod,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  } = useConditionEdit({ supportedParam: SupportedArgumentTypes.ArgumentAsDeviceState });

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsDeviceState;

    if (argument && onlyParametersEdit) {
      const state = argument.GetCheckDeviceState<MeasuredTemperatureState>();
      setTemperature(state.Temperature.toString());
    } else {
      const data = channels[0].States.find((x) => x.TypeAsEnum === DeviceResponseType.MeasuredTemperature);
      const temp = (data?.Data as MeasuredTemperatureState)?.Temperature;
      if (temp) setTemperature(temp.toFixed(2));
    }
  }, [seq, onlyParametersEdit]);

  const handleSubmit = () => {
    if (channels.length) {
      const deviceArgument = createDeviceArgumentWithComparison();
      deviceArgument.Type = DeviceResponseType.MeasuredTemperature;
      deviceArgument.DeviceGuid = channels[0].GetDevice().Guid;
      const sensorState = new MeasuredTemperatureState();
      sensorState.Channel = channels[0].Number;
      sensorState.Temperature = parseFloat(temperature);
      deviceArgument.SetCheckDeviceState<MeasuredTemperatureState>(sensorState);

      saveConditionParams(deviceArgument);
    }
  };

  return (
    <>
      {changeableAtMeetCondition ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      {triggerMethod === TriggerMethodType.WhenChangeOn && changeableAtMeetCondition && (
        <InputString
          value={timeSec}
          setValue={setTimeSec}
          label={t('action.create.conditions.sources.device.requiredTime')}
          inputType="number"
          min={0}
        />
      )}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={[
          ConditionsTypes.Equal,
          ConditionsTypes.NotEqueal,
          ConditionsTypes.BiggerThan,
          ConditionsTypes.BiggerThanOrEqual,
          ConditionsTypes.SmallerThan,
          ConditionsTypes.SmallerThanOrEqual,
        ]}
      />
      <ComparisonMethodSelect unit="°C" step="0.01" />
      <InputString
        value={temperature}
        setValue={setTemperature}
        label={`${t('action.create.conditions.sources.device.temperature')} (°C)`}
        inputType="number"
        step="0.01"
      />
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
