import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import c from 'classnames';
import { SubmitButton } from '../../../../components';
import HeaderWithHelp from '../../../../components/header-with-help';
import { ROUTES } from '../../../../routes';
import { getParameterByName } from '../../../../utils/location';
import { useCreateInstallationContext } from '../../../installation-create/context';
import { Email } from './components/email';
import { Password } from './components/password';
import { useIntegrationLogin } from './hooks/use-integration-login';

const ExalusIntegrationLogin: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { state: installationState } = useCreateInstallationContext();
  const serialNumber = getParameterByName('serialNumber');
  const pin = getParameterByName('pin');
  const { form, onSubmit, customMode, setCustomMode } = useIntegrationLogin(serialNumber, pin);

  useEffect(() => {
    if (!installationState.installationName) history.replace(ROUTES.InstallationCreate());
  }, []);

  return (
    <HeaderWithHelp
      kind="main"
      name="exalus-login"
      headerTitle={customMode ? t('exalus.integration-login.custom.header') : t('exalus.integration-login.header')}
      headerSubtitle={
        customMode ? t('exalus.integration-login.custom.subheader') : t('exalus.integration-login.subheader')
      }
      helpTitle={t('exalus.integration-login.help.title')}
      helpContent={t('exalus.integration-login.help.content')}
      handleClose={() => (customMode ? setCustomMode(false) : history.goBack())}
    >
      <div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={c('integration_form m-b-48')}>
            <Email disabled={!customMode} />
            <Password disabled={!customMode} />
            <SubmitButton>{t('exalus.integration-login.submit')}</SubmitButton>
          </form>
          {!customMode ? (
            <button className="button button--secondary max-width-desktop" onClick={() => setCustomMode(true)}>
              {t('exalus.integration-login.setManual')}
            </button>
          ) : null}
        </FormProvider>
      </div>
    </HeaderWithHelp>
  );
};

export default ExalusIntegrationLogin;
