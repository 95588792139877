import { gql } from '@apollo/client';

export const CREATE_INSTALLATION = gql`
  mutation CreateInstallation($input: CreateInstallationRequestInput!) {
    createInstallation(input: $input) {
      installationId
      name
      hexColor
      iconName
      isSelected
      accessType
      integrationType
      payload {
        ... on InstallationModelResponse {
          timeZone
          installationId
          coordinates {
            latitude
            longitude
          }
        }
      }
      externalPayload {
        ... on ExalusInstallationPayload {
          installationId
          pin
          serialNumber
        }
      }
    }
  }
`;

export const ADD_MULTIPLE_INSTALLATIONS_AFTER_MIGRATION = gql`
  mutation AddMultipleInstallationsAfterMigration($inputs: [CreateInstallationRequestInput!]!) {
    addMultipleInstallationsAfterMigration(inputs: $inputs) {
      installationId
      name
      hexColor
      iconName
      isSelected
      accessType
      integrationType
      payload {
        ... on InstallationModelResponse {
          timeZone
          installationId
          coordinates {
            latitude
            longitude
          }
        }
      }
      externalPayload {
        ... on ExalusInstallationPayload {
          installationId
          pin
          serialNumber
        }
      }
    }
  }
`;

export const CHANGE_INSTALLATION_NAME = gql`
  mutation ChangeInstallationName($input: ChangeInstallationNameInput!) {
    changeInstallationName(input: $input) {
      result {
        ok
      }
      errors {
        ... on InstallationError {
          error
          message
        }
      }
    }
  }
`;

export const CHANGE_INSTALLATION_ICON = gql`
  mutation ChangeInstallationIcon($input: ChangeInstallationIconInput!) {
    changeInstallationIcon(input: $input) {
      result {
        ok
      }
      errors {
        ... on InstallationError {
          error
          message
        }
      }
    }
  }
`;

export const CHANGE_INSTALLATION_COLOR = gql`
  mutation ChangeInstallationColor($input: ChangeInstallationColorInput!) {
    changeInstallationColor(input: $input) {
      result {
        ok
      }
      errors {
        ... on InstallationError {
          error
          message
        }
      }
    }
  }
`;

export const DELETE_INSTALLATION = gql`
  mutation DeleteInstallation($input: DeleteInstallationInput!) {
    deleteInstallation(input: $input) {
      result {
        ok
      }
      errors {
        ... on InstallationError {
          error
          message
        }
      }
    }
  }
`;

export const REMOVE_USER_FROM_INSTALLATION = gql`
  mutation RemoveUserFromInstallation($input: RemoveUserFromInstallationInput!) {
    removeUserFromInstallation(input: $input) {
      result {
        ok
      }
      errors {
        ... on InvitationError {
          message
          error
        }
      }
    }
  }
`;

export const SELECT_INSTALLATION = gql`
  mutation SelectInstallation($installationId: UUID!) {
    selectInstallation(installationId: $installationId) {
      installationId
      brokerUrl
    }
  }
`;

export const CHANGE_INSTALLATION_ORDER = gql`
  mutation ChangeInstallationOrder($input: ChangeInstallationsOrderRequestInput!) {
    changeInstallationOrder(input: $input) {
      ok
    }
  }
`;

export const CHANGE_GEOLOCATION = gql`
  mutation ChangeGeolocation($input: ChangeGeolocationInput!) {
    changeGeolocation(input: $input) {
      result {
        ok
      }
      errors {
        __typename
        ... on InstallationError {
          message
          error
        }
        ... on InstallationAttachmentError {
          message
          installationId
          validBrokerUrl
        }
      }
    }
  }
`;

export const CHANGE_TIMEZONE = gql`
  mutation ChangeTimeZone($input: ChangeTimeZoneInput!) {
    changeTimeZone(input: $input) {
      result {
        ok
      }
      errors {
        __typename
        ... on InstallationError {
          message
          error
        }
        ... on InstallationAttachmentError {
          message
          installationId
          validBrokerUrl
        }
      }
    }
  }
`;

