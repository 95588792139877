import React from 'react';

export const IconInstallation0: React.FC = () => (
  <svg
    className="icon-installation"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M32.0109 10.9051V31.2549C32.0109 33.2313 30.7164 34.8335 29.1196 34.8335L24.0598 34.8335L24.0598 22.5835C24.0598 21.4789 23.1644 20.5835 22.0598 20.5835H15.9402C14.8356 20.5835 13.9402 21.4789 13.9402 22.5835L13.9402 34.8335H8.88043C7.28361 34.8335 5.98913 33.2313 5.98913 31.2549L5.98913 10.9051M2.375 13.0559L19 1.5835L35.625 13.0559"
      stroke="#15141D"
    />
  </svg>
);
