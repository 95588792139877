import * as Sentry from '@sentry/react';
import { isLocalApp } from '../../utils/helpers/local-app';

export const useSentry = () => {
  const setSentryUser = (id: string, email: string) => {
    if (isLocalApp) return;

    Sentry.setUser({
      id,
      email,
    });
  };

  const setSentryTags = (tags: Record<string, string>) => {
    if (isLocalApp) return;

    Sentry.setTags(tags);
  };

  return { setSentryUser, setSentryTags };
};
