export enum GenericInput {
  Monostable = 1,
  Bistable = 2,
}

export enum GenericInputMonostableMode {
  Normal = 0,
  Reversed = 1,
}

export enum GenericInputBistableMode {
  Normal = 0,
  TwoWay = 1,
}

export interface GenericInputTypeBody {
  deviceId: string;
  channelId: string;
  genericInputType: GenericInput;
  monostableMode?: number;
  bistableMode?: number;
}

interface InputTypesBatchItem {
  channelId: string;
  genericInputType: GenericInput;
  monostableMode?: number;
  bistableMode?: number;
}

export interface GenericInputTypesBatchBody {
  deviceId: string;
  items: InputTypesBatchItem[];
}
