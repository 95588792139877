import React from 'react';

export const IconDashboard: React.FC = () => {
  return (
    <svg
      className="icon-dashboard"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M20.0497 7.99554V20.1486C20.0497 21.3289 19.2488 22.2857 18.2609 22.2857H5.73913C4.75119 22.2857 3.95031 21.3289 3.95031 20.1486V7.99554M1.71429 9.28002L12 2.42859L22.2857 9.28002"
        stroke="#15141D"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
