import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputSliderDialog } from '../../input-slider-dialog';

interface Props {
  name: string;
  label: string;
}

export const OptimizerConfigurationPhaseLoadInput: React.FC<Props> = ({ name, label }) => {
  const { watch } = useFormContext();

  return (
    <InputSliderDialog
      propertyName={name}
      propertyValue={parseInt(watch()[name])}
      unit="%"
      dialogTitle={label}
      inputLabel={label}
      min={0}
      max={100}
      step={1}
    />
  );
};
