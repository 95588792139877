import React from 'react';

export const IconWrenchTool: React.FC = () => (
  <svg
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-wrench-tool"
  >
    <path
      d="M32.9306 8.17213C33.2689 7.83376 33.849 8.0064 33.9388 8.47596C34.3116 10.3542 33.773 12.3706 32.316 13.8276C30.6863 15.4573 28.3454 15.9407 26.2945 15.2847C26.0804 15.2156 25.8387 15.264 25.6799 15.4228L15.9295 25.1732C15.7707 25.332 15.7223 25.5737 15.7914 25.7878C16.4474 27.8456 15.964 30.1796 14.3343 31.8093C12.8842 33.2594 10.8609 33.8049 8.98266 33.4321C8.52 33.3354 8.34046 32.7622 8.67882 32.4239L10.2019 30.6842C10.9994 29.7733 10.9469 28.3982 10.0822 27.5507C9.20087 26.6869 7.79252 26.6801 6.90285 27.5354L5.3159 29.0609C4.97753 29.3993 4.39748 29.2267 4.30771 28.7571C3.93482 26.8788 4.47344 24.8625 5.93048 23.4054C7.56015 21.7758 9.90108 21.2924 11.952 21.9484C12.166 22.0174 12.4077 21.9691 12.5666 21.8103L22.317 12.0599C22.4758 11.901 22.5241 11.6593 22.4551 11.4453C21.7991 9.38748 22.2824 7.05345 23.9121 5.42378C25.3623 3.97365 27.3855 3.42812 29.2638 3.80101C29.7265 3.89769 29.906 4.47084 29.5676 4.8092L28.0264 6.41233C27.1786 7.29419 27.1923 8.69233 28.0573 9.55733C28.8755 10.3755 30.1814 10.4383 31.0743 9.70226L32.9306 8.17213Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
