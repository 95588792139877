import React from 'react';

export const IconOutside: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="33"
      height="30"
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.322 25.7778C30.322 25.3636 29.9862 25.0278 29.572 25.0278C29.1577 25.0278 28.822 25.3636 28.822 25.7778H30.322ZM17.3368 1.62039L16.8722 2.20914L17.3368 1.62039ZM13.2552 1.65165L12.7816 1.07009L13.2552 1.65165ZM2.20255 10.652L1.72897 10.0705L2.20255 10.652ZM20.1783 29.75H27.9405V28.25H20.1783V29.75ZM17.7969 24.2464V27.3686H19.2969V24.2464H17.7969ZM12.8041 27.3686V24.2464H11.3041V27.3686H12.8041ZM2.63142 29.75H10.4227V28.25H2.63142V29.75ZM0.25 13.1821V27.3686H1.75V13.1821H0.25ZM16.8722 2.20914L21.9644 6.22764L22.8936 5.05013L17.8014 1.03163L16.8722 2.20914ZM2.67613 11.2336L13.7288 2.23322L12.7816 1.07009L1.72897 10.0705L2.67613 11.2336ZM30.322 27.3686V25.7778H28.822V27.3686H30.322ZM17.8014 1.03163C16.3257 -0.132929 14.2393 -0.116948 12.7816 1.07009L13.7288 2.23322C14.6416 1.4899 15.9481 1.47989 16.8722 2.20914L17.8014 1.03163ZM1.75 13.1821C1.75 12.4264 2.09012 11.7108 2.67613 11.2336L1.72897 10.0705C0.793152 10.8325 0.25 11.9753 0.25 13.1821H1.75ZM2.63142 28.25C2.14463 28.25 1.75 27.8554 1.75 27.3686H0.25C0.25 28.6838 1.3162 29.75 2.63142 29.75V28.25ZM11.3041 27.3686C11.3041 27.8554 10.9095 28.25 10.4227 28.25V29.75C11.7379 29.75 12.8041 28.6838 12.8041 27.3686H11.3041ZM12.8041 24.2464C12.8041 22.8677 13.9218 21.75 15.3005 21.75V20.25C13.0933 20.25 11.3041 22.0393 11.3041 24.2464H12.8041ZM19.2969 24.2464C19.2969 22.0393 17.5076 20.25 15.3005 20.25V21.75C16.6792 21.75 17.7969 22.8677 17.7969 24.2464H19.2969ZM20.1783 28.25C19.6915 28.25 19.2969 27.8554 19.2969 27.3686H17.7969C17.7969 28.6838 18.8631 29.75 20.1783 29.75V28.25ZM27.9405 29.75C29.2558 29.75 30.322 28.6838 30.322 27.3686H28.822C28.822 27.8554 28.4273 28.25 27.9405 28.25V29.75Z"
        fill="#15141D"
      />
      <path
        d="M27.083 19.123L31.7062 13.9745L27.083 8.82594"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1926 13.9741H19.6348"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
