import { RelayConfig, TimeZoneKindInternal } from '../../../../../data-access/gql-types/graphql';

export const initialRelay = {
  enabled: true,
  index: 0,
  timeOff: '00:00:00',
  timeOn: '00:00:00',
  thresholdValue: 0,
} as RelayConfigCustom;

export interface TestFormBody {
  phase1: number;
  phase2: number;
  phase3: number;
}

export type RelayConfigCustom = RelayConfig & {
  timeOn: string;
  timeOff: string;
};

export interface EcoFormBody {
  tempMax: number;
  voltageThreshold1: number;
  voltageThreshold2: number;
  voltageThreshold3: number;
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
}

interface BaseConsumptionFormBody {
  tempMax: number;
  tempMin: number;
  heaterPowerLimit: number;
  voltageThreshold1: number;
  voltageThreshold2: number;
  voltageThreshold3: number;
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
}

export interface HoursRangeFront {
  from: string;
  timeZoneKind: TimeZoneKindInternal;
  to: string;
  pricingTier: number;
  error?: string;
}

export interface TariffBody {
  operator: string;
  tariffName: string;
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  hoursRanges: HoursRangeFront[];
}

export type ComfortFormBody = BaseConsumptionFormBody & TariffBody;

export interface FullConsumptionFormBody extends BaseConsumptionFormBody, TariffBody {
  relayConfig4: Omit<RelayConfigCustom, '__typename'>;
  tempMinEnabled: boolean;
  tempSelfConsumption: number;
  from: string;
  to: string;
}

export interface LimitConsumptionFormBody extends BaseConsumptionFormBody, TariffBody {
  relayConfig4: Omit<RelayConfigCustom, '__typename'>;
  tempMinEnabled: boolean;
  tempSelfConsumption: number;
  powerLimit: number;
  from: string;
  to: string;
}

export interface RelaysConsumptionFormBody {
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
  relayConfig4: Omit<RelayConfigCustom, '__typename'>;
  from: string;
  to: string;
}

export interface PrepareRelaysParams {
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
  relayConfig4?: Omit<RelayConfigCustom, '__typename'>;
}

export interface TariffFormDefault {
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  hoursRanges: HoursRangeFront[];
}