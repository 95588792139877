import React, { ChangeEvent, KeyboardEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { IconEdit } from '../icons';
import { Input } from './input';

interface InputEditProps {
  defaultValue: string;
  value?: string;
  inputType?: string;
  id?: string;
  disabled?: boolean;
  setDisabled?: (value: boolean) => void;
  label: string;
  isBtnSave?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  step?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSave?: () => void;
}

export const InputEdit: React.FC<InputEditProps> = ({
  isBtnSave = true,
  inputType,
  disabled = false,
  defaultValue,
  value,
  label,
  id,
  hasError = false,
  errorMessage = '',
  setDisabled,
  onChange,
  onSave,
  step,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation('common');

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (onSave) onSave();
      else buttonRef.current?.click();
    }
  };

  return (
    <div className={c('input-edit max-width-desktop', { btn_save: isBtnSave })}>
      <Input
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        required
        label={label}
        id={id}
        inputType={inputType}
        hasError={hasError}
        step={step}
        {...(onChange ? { onChange } : {})}
        onKeyDown={handleKeyDown}
      >
        {!disabled && isBtnSave && (
          <>
            {!onSave ? (
              <button className="input-edit__btn input-edit__btn--submit" type="submit" ref={buttonRef}>
                {t('buttons.save')}
              </button>
            ) : (
              <button
                className="input-edit__btn input-edit__btn--submit"
                type="button"
                onClick={onSave}
                ref={buttonRef}
              >
                {t('buttons.save')}
              </button>
            )}
          </>
        )}
      </Input>
      {(disabled || !isBtnSave) && (
        <button type="button" className="input-edit__btn" onClick={() => setDisabled && setDisabled(false)}>
          <IconEdit />
        </button>
      )}
      {errorMessage && <div className="input__error-message">{errorMessage}</div>}
    </div>
  );
};
