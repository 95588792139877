import React from 'react';
import { TriggerFormContextProvider } from './context';
import './index.scss';
import { TriggerFormSteps } from './steps';

const TriggerForm: React.FC = () => {
  return (
    <TriggerFormContextProvider>
      <TriggerFormSteps />
    </TriggerFormContextProvider>
  );
};

export default TriggerForm;
