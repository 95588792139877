import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputSwitcher } from '../../../../components';
import { useThemeSettings } from '../../hooks/use-theme-settings';

export const ThemeSettings: React.FC = () => {
  const { t } = useTranslation('profile');
  const { changeTheme, optionsTheme, optionsThemeDefaultIndex } = useThemeSettings();

  return (
    <InputSwitcher
      options={optionsTheme}
      defaultOptionIndex={optionsThemeDefaultIndex}
      label={t('app-theme')}
      onChange={(inputElement) => changeTheme(inputElement.value)}
    />
  );
};
