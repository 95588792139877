import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  ActivityType,
  BlindStateDirectionInternal,
  ChannelActionParameterInput,
  CreateAdvancedActionMutation,
  CreateAdvancedActionMutationVariables,
  EditActionMutation,
  EditActionMutationVariables,
} from '../../../data-access/gql-types/graphql';
import { CREATE_ADVANCED_ACTION, EDIT_ACTION } from '../../../data-access/mutations/action';
import { useBackdropContext, useInstallation } from '../../../hooks';
import { useRefetchData } from '../../../hooks/refresh-data';
import { ROUTES } from '../../../routes';
import { toastSuccess } from '../../../utils/toast';
import {
  BlindStateTask,
  DimStateTask,
  GateStateTask,
  OnOffStateTask,
  RGBWStateTask,
} from '../../exalus/pages/action-create/types';
import { useLavvaCreateActionContext } from '../context';
import { DeviceTaskParams, GateActionInternal, SceneTaskType, TaskVariant } from '../types';

export const useActionTasks = () => {
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const { t } = useTranslation('action');
  const { selectedInstallationId } = useInstallation();
  const { name, icon, taskList, setTaskType, isDuplicate } = useLavvaCreateActionContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchActions, refetchDashboard } = useRefetchData();
  const [createAdvancedAction] = useMutation<CreateAdvancedActionMutation, CreateAdvancedActionMutationVariables>(
    CREATE_ADVANCED_ACTION,
  );
  const [editAction] = useMutation<EditActionMutation, EditActionMutationVariables>(EDIT_ACTION);

  useEffect(() => {
    if (!name) history.replace(ROUTES.ActionList());
  }, []);

  const selectTaskType = (taskType: SceneTaskType) => {
    setTaskType(taskType);

    switch (taskType) {
      case SceneTaskType.ChannelState:
        history.push(ROUTES.ActionTasksVariants());
        break;
      default:
        break;
    }
  };

  const addNewTask = () => {
    history.push(ROUTES.ActionTasksVariants());
  };

  const getBlindActivityByTaskAction = (action: BlindStateDirectionInternal) => {
    switch (action) {
      case BlindStateDirectionInternal.Open:
        return ActivityType.Open;
      case BlindStateDirectionInternal.Closed:
        return ActivityType.Close;
      case BlindStateDirectionInternal.Stopped:
        return ActivityType.Stop;
      default:
        return ActivityType.SetPosition;
    }
  };

  const getGateActivityByTaskAction = (action: GateActionInternal) => {
    switch (action) {
      case GateActionInternal.Open:
        return ActivityType.Open;
      case GateActionInternal.Closed:
        return ActivityType.Close;
      case GateActionInternal.Stopped:
        return ActivityType.Stop;
      case GateActionInternal.Tilt:
        return ActivityType.Tilt;
      default:
        return ActivityType.SetPosition;
    }
  };

  const getParameter = (params: DeviceTaskParams): ChannelActionParameterInput => {
    switch (params.variant) {
      case TaskVariant.OnOff: {
        return { activityType: (params.state as OnOffStateTask).isOn ? ActivityType.On : ActivityType.Off, value: '' };
      }
      case TaskVariant.Dim: {
        return { activityType: ActivityType.SetDim, value: (params.state as DimStateTask).brightness.toString() };
      }
      case TaskVariant.SetRGBW: {
        const taskParams = params.state as RGBWStateTask;

        return {
          activityType: ActivityType.SetLightColor,
          value: `${taskParams.R},${taskParams.G},${taskParams.B}`,
        };
      }
      case TaskVariant.SetBlind: {
        const state = params.state as BlindStateTask<BlindStateDirectionInternal>;

        return {
          activityType: getBlindActivityByTaskAction(state.action),
          value: state.position?.toString() || '',
        };
      }
      case TaskVariant.SetGate: {
        const state = params.state as GateStateTask;

        return {
          activityType: getGateActivityByTaskAction(state.action),
          value: state.position?.toString() || '',
        };
      }
      case TaskVariant.SetVentilation: {
        return { activityType: ActivityType.Tilt, value: '' };
      }
    }

    return { activityType: ActivityType.On, value: '' };
  };

  const requestFinally = async (message: string) => {
    await refetchActions();
    await refetchDashboard();
    toastSuccess({ content: message });
  };

  const handleSubmit = () => {
    turnOnBackdrop();

    const taskChannels = taskList.map((x, i) => ({
      channelId: x.taskParams.channel.id,
      channelType: x.taskParams.channel.data.type,
      order: i,
      parameter: getParameter(x.taskParams),
    }));

    const actionBody = {
      installationId: selectedInstallationId,
      iconName: icon,
      name,
      channels: taskChannels,
    };

    if (!actionId) {
      createAdvancedAction({
        variables: {
          input: {
            ...actionBody,
          },
        },
        onCompleted: async (response) => {
          if (response?.createAdvancedAction.id) {
            await requestFinally(t('advanced.createSuccess'));
            turnOffBackdrop();
            history.push({
              pathname: ROUTES.ActionDetails(response?.createAdvancedAction?.id),
              state: { back: !isDuplicate ? 3 : 4 },
            });
          } else turnOffBackdrop();
        },
        onError: () => turnOffBackdrop(),
      });
    } else {
      editAction({
        variables: {
          input: {
            id: actionId,
            ...actionBody,
          },
        },
        onCompleted: async (response) => {
          if (response?.editAction.idResponse?.id) {
            await requestFinally(t('advanced.editSuccess'));
            turnOffBackdrop();
            history.go(-2);
          } else turnOffBackdrop();
        },
        onError: () => turnOffBackdrop(),
      });
    }
  };

  return {
    addNewTask,
    handleSubmit,
    selectTaskType,
  };
};
