import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ConnectionResult } from 'lavva.exalushome/build/js/Services/IExalusConnectionService';
import { IntegrationType } from '../../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../../routes';
import { isLocalApp } from '../../../../../utils/helpers/local-app';
import { useCreateInstallation } from '../../../../installation-create/hooks/use-create-installation';
import { useExalusIntegrationAuthorizeController } from '../../../hooks';
import { ExalusIntegrationForm, IntegrationCreateHook } from '../types';

export const useIntegrationCreate = (): IntegrationCreateHook => {
  const history = useHistory();
  const { editIntegration } = useCreateInstallation();
  const { mutate } = useExalusIntegrationAuthorizeController();
  const [qrPageOpen, setQrPageOpen] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const form = useForm<ExalusIntegrationForm>({
    defaultValues: {
      serialNumber: '',
      pin: '',
    },
  });

  const onSubmit = (formData: ExalusIntegrationForm) => {
    mutate(formData, {
      onSuccess: (response) => {
        if (response !== ConnectionResult.Connected) {
          form.setError('serialNumber', {});
          form.setError('pin', {});
          setShowError(true);
          return;
        }
        editIntegration(IntegrationType.Exalus, formData);

        if (!isLocalApp) history.push(ROUTES.ExalusAuthorization(formData.serialNumber, formData.pin));
        else history.push(ROUTES.ExalusLogin(formData.serialNumber, formData.pin, ''));
      },
    });
  };

  const handleScan = (data: string) => {
    const [serialNumber, pin] = data.split('=')[1].split(':');

    form.setValue('serialNumber', serialNumber);
    form.setValue('pin', pin);
    setQrPageOpen(false);
  };

  return {
    qrPageOpen,
    setQrPageOpen,
    form,
    onSubmit,
    handleScan,
    showError,
  };
};
