import React from 'react';
import { useRgbSceneFormContext } from '../context';
import { RgbSceneFormView } from '../enums';
import { RgbSceneChangeIcon } from './change-icon';
import { RgbSceneChangeName } from './change-name';
import { RgbSceneChangeOrder } from './change-order';
import { RgbSceneFormList } from './list';
import { RgbSceneRemoveColor } from './remove-color';
import { RgbSceneFormSetColor } from './set-color';
import { RgbSceneFormSettings } from './settings';

export const RgbSceneFormWrapper: React.FC = () => {
  const { selectedView, setIcon, setSelectedView, setName } = useRgbSceneFormContext();

  if (selectedView === RgbSceneFormView.SETTINGS) {
    return <RgbSceneFormSettings />;
  } else if (selectedView === RgbSceneFormView.CHANGE_ORDER) {
    return <RgbSceneChangeOrder />;
  } else if (selectedView === RgbSceneFormView.REMOVE_COLOR) {
    return <RgbSceneRemoveColor />;
  } else if (selectedView === RgbSceneFormView.CHANGE_NAME) {
    return (
      <RgbSceneChangeName
        onNameChange={(name) => {
          setName(name);
          setSelectedView(RgbSceneFormView.SETTINGS);
        }}
      />
    );
  } else if (selectedView === RgbSceneFormView.CHANGE_ICON) {
    return (
      <RgbSceneChangeIcon
        onIconChange={(icon) => {
          setIcon(icon);
          setSelectedView(RgbSceneFormView.SETTINGS);
        }}
      />
    );
  } else if (selectedView === RgbSceneFormView.LIST) {
    return <RgbSceneFormList />;
  } else {
    return <RgbSceneFormSetColor />;
  }
};
