import { useEffect, useMemo, useRef, useState } from 'react';
import { ExtendedWebBluetooth, NativeBluetoothDeviceInfo } from 'lavva.webbluetooth';
import { useBackdropContext } from '../../../../hooks';
import { FindWebBluetoothDevicesView } from '../utils/FindWebBluetoothDevicesView';

export const useNativeWebBluetooth = () => {
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [percentage, setPercentage] = useState<number>(0);
  const [isCustomBluetooth, setIsCustomBluetooth] = useState<boolean>(false);
  const [foundDevices, setFoundDevices] = useState<NativeBluetoothDeviceInfo[]>([]);
  const [dialogScanning, setDialogScanning] = useState<boolean>(false);
  const [scanning, setScanning] = useState<boolean>(false);
  const { turnOnBackdrop } = useBackdropContext();

  const WebBluetooth = useMemo(() => new FindWebBluetoothDevicesView(), []);

  const updatePercentage = () => {
    setPercentage((prev) => {
      if (prev + 1 === 100) stoppedScanning();
      return prev + 1;
    });
  };

  const addFoundDevice = (device: NativeBluetoothDeviceInfo) => {
    console.log('FOUND DEVICE', { device });
    setFoundDevices((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((x) => x.deviceAddress === device.deviceAddress);

      if (index !== -1) temp.splice(index, 1);
      return [...temp, device];
    });
  };

  const updateFound = (device: NativeBluetoothDeviceInfo) => {
    if (device.rssi) {
      console.log('UPDATE DEVICE RSSI', { device });
      setFoundDevices((prev) => {
        const foundIndex = prev.findIndex(
          (x) => x.deviceName === device.deviceName && x.deviceAddress === device.deviceAddress,
        );

        if (foundIndex !== -1) prev[foundIndex].rssi = device.rssi;
        return [...prev];
      });
    }
  };

  const startedScanning = async (scanTime: number) => {
    console.log('STARTED SCANNING', { scanTime });
    interval.current = setInterval(updatePercentage, scanTime / 100);
  };

  const stoppedScanning = async () => {
    console.log('STOPPED SCANNING');
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
      setPercentage(0);
      setScanning(false);
    }
  };

  const selectionCancelled = async () => {
    console.log('SELECTION CANCELLED');
  };

  const handleDeviceConnect = async (device: NativeBluetoothDeviceInfo) => {
    turnOnBackdrop();
    console.log('DEVICE SELECTED', { device });
    WebBluetooth.onDeviceSelected.Invoke(device);
    await WebBluetooth.StoppedScanning();
  };

  useEffect(() => {
    if (WebBluetooth) {
      if (ExtendedWebBluetooth.IsCustomSearchScreenSupported()) {
        console.log('WEB BLUETOOTH SUPPORTED');
        setIsCustomBluetooth(true);
        WebBluetooth.AddFoundDevice = addFoundDevice;
        WebBluetooth.UpdateFoundDevice = updateFound;
        WebBluetooth.StartedScanning = startedScanning;
        WebBluetooth.StoppedScanning = stoppedScanning;
        WebBluetooth.SelectionCancelled = selectionCancelled;
        ExtendedWebBluetooth.SetSearchScreen(WebBluetooth);
      }
    }
  }, [WebBluetooth]);

  return {
    percentage,
    isCustomBluetooth,
    foundDevices,
    dialogScanning,
    setDialogScanning,
    setFoundDevices,
    handleDeviceConnect,
    stoppedScanning,
    setScanning,
    scanning,
    WebBluetooth,
  };
};
