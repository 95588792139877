import React from 'react';
import c from 'classnames';
import { IconMarkerFill } from '../../../../../../components/icons/icon-marker-fill';
import './index.scss';

interface ComponentProps {
  lat: number;
  lng: number;
  type?: 'custom' | 'current_location';
}

const Marker: React.FC<ComponentProps> = ({ type = 'custom' }) => {
  return (
    <div className={c('marker', { current_loc: type === 'current_location' })} style={{ cursor: 'pointer' }}>
      {type === 'custom' && <IconMarkerFill />}
    </div>
  );
};

export default Marker;
