import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ChannelBox } from '../../../components/channel/box';
import { DeviceChannelsBox } from '../../../components/channel/device-channels-box';
import { useExalusStorageContext } from '../../../context/storage';
import { DeviceChannelsGrouped } from '../../channel-list/types';

interface HookParams {
  groupChannels?: IDeviceChannel[];
  isDraggable?: boolean;
}

export const useDashboardChannels = ({ groupChannels, isDraggable }: HookParams) => {
  const { isDashboardGridView } = useExalusStorageContext();

  const groupChannelsItems = () => {
    if (groupChannels) {
      const listToMap: IDeviceChannel[] = [];

      for (const channel of groupChannels) {
        if (channel.GetDevice().ShouldChannelsBeGrouped) {
          if (!listToMap.find((x) => x.GetDevice().Guid === channel.GetDevice().Guid)) {
            listToMap.push(channel);
          }
        } else {
          listToMap.push(channel);
        }
      }

      return listToMap
        .map((channel) => {
          if (!channel.GetDevice().ShouldChannelsBeGrouped) {
            return (
              <ChannelBox
                key={channel.ChannelId}
                channel={channel}
                isListItem={!isDashboardGridView}
                isDraggable={isDraggable}
                hidden={channel.IsHidden}
              />
            );
          } else {
            const item: DeviceChannelsGrouped = {
              id: channel.GetDevice().Guid,
              deviceName: channel.GetDevice().Name,
              channels: channel
                .GetDevice()
                .Channels.filter((x) => groupChannels.find((y) => y.ChannelId === x.ChannelId && !x.IsHidden)),
            };

            if (item.channels.length > 0) {
              return (
                <li key={item.id} className="items-list__item">
                  <DeviceChannelsBox
                    deviceChannelsGrouped={item}
                    isListItem={!isDashboardGridView}
                    isDraggable={isDraggable}
                  />
                </li>
              );
            }
          }
        })
        .filter((x) => x?.key);
    }
  };

  return {
    groupChannelsItems,
  };
};
