import React, { useMemo } from 'react';
import TaskItem from '../../components/task-item';
import ActionCreateTasks from '../../components/task-list';
import { useLavvaCreateActionContext } from '../../context';
import { useActionTasks } from '../../hooks/use-action-tasks';
import { Task } from '../../types';

const LavvaActionCreateTasks: React.FC = () => {
  const { taskList } = useLavvaCreateActionContext();
  const { addNewTask, handleSubmit } = useActionTasks();

  const tasks = useMemo(() => taskList.map((task: Task) => <TaskItem key={task.id} task={task} />), [taskList]);

  return (
    <ActionCreateTasks
      handleSubmit={handleSubmit}
      addNewTask={addNewTask}
      tasks={tasks}
      taskListLength={taskList.length}
    />
  );
};

export default LavvaActionCreateTasks;
