import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NetworkInterfaceType, OperationalStatus } from 'lavva.exalushome/build/js/INetworkService';
import { CustomBackdrop, DialogConfirmation, Header, NavHeader, Page, PopUpNav } from '../../../../../components';
import { IconAdd, IconSettings } from '../../../../../components/popup-nav/icons';
import TextInfo from '../../../../../components/text-info';
import { ROUTES } from '../../../../../routes';
import { useExalusDNSAddresses, useExalusNetworkSettings } from '../../../hooks';
import { useNetworkSettings } from '../hooks/use-network-settings';
import './index.scss';

const ExalusNetworkSettings: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const [resetDialog, setResetDialog] = useState<boolean>(false);
  const [dhcpDialog, setDhcpDialog] = useState<boolean>(false);
  const { resetToDefault, activateDHCP } = useNetworkSettings();
  const { data: network, isLoading: networkLoading } = useExalusNetworkSettings();
  const { data: dns, isLoading: dnsLoading } = useExalusDNSAddresses();

  const dnsAddresses = useMemo(() => dns?.reduce((result, current) => `${result}${current}\n`, ''), [dns]);

  const IsDhcpEnabled = useMemo(() => network?.IsDhcpEnabled, [network?.IsDhcpEnabled]);

  return (
    <Page
      isStickyHeader
      className="network-settings"
      header={
        <>
          <NavHeader>
            {IsDhcpEnabled && (
              <PopUpNav
                links={[
                  {
                    label: t('exalusNetwork.dnsAdd'),
                    onClick: () => history.push(ROUTES.ExalusDnsAdd()),
                    icon: <IconAdd />,
                  },
                  {
                    label: t('exalusNetwork.staticSettings'),
                    onClick: () => history.push(ROUTES.ExalusNetworkStaticSettings()),
                    icon: <IconSettings />,
                  },
                ]}
              />
            )}
          </NavHeader>
          <Header title={t('networkSettings')} isUnderline />
        </>
      }
    >
      {network?.InterfaceType && (
        <TextInfo
          label={t('exalusNetwork.interfaceType')}
          value={NetworkInterfaceType[network?.InterfaceType] || '-'}
          column
        />
      )}
      <TextInfo
        label={t('exalusNetwork.networkConfiguration')}
        value={t(`exalusNetwork.configuration${IsDhcpEnabled ? 'Dynamic' : 'Static'}`)}
        column
      />
      <TextInfo label={t('exalusNetwork.ipv4Address')} value={network?.Ipv4Address || '-'} column />
      <TextInfo label={t('exalusNetwork.ipv4SubnetMask')} value={network?.Ipv4SubnetMask || '-'} column />
      <TextInfo label={t('exalusNetwork.ipv4DefaultGateway')} value={network?.Ipv4DefaultGateway || '-'} column />
      <TextInfo label={t('exalusNetwork.ipv4PublicAddress')} value={network?.Ipv4PublicAddress || '-'} column />
      <TextInfo label={t('exalusNetwork.macAddress')} value={network?.MacAddress || '-'} column />
      <TextInfo
        label={t('exalusNetwork.interfaceSpeed')}
        value={network?.InterfaceSpeed ? `${(network.InterfaceSpeed / 1000000).toString()} Mb/s` : '-'}
        column
      />
      {network?.OperationalStatus && (
        <TextInfo
          label={t('exalusNetwork.operationalStatus')}
          value={t(`exalusNetwork.operationalStatusEnum.${OperationalStatus[network?.OperationalStatus]}`) || '-'}
          column
        />
      )}
      <TextInfo label={t('exalusNetwork.dnsAddresses')} value={dnsAddresses || '-'} column />
      {!IsDhcpEnabled && (
        <div className="max-width-desktop">
          <button onClick={() => setResetDialog(true)} className="button button--secondary m-t-24">
            {t('exalusNetwork.resetToDefault')}
          </button>
          <button onClick={() => setDhcpDialog(true)} className="button button--secondary m-t-24">
            {t('exalusNetwork.activateDHCP')}
          </button>
        </div>
      )}

      <CustomBackdrop loading={networkLoading || dnsLoading} />
      <DialogConfirmation
        show={dhcpDialog}
        setShow={setDhcpDialog}
        header={t('exalusNetwork.activateDHCP')}
        content={t('exalusNetwork.DHCPConfirmation')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={() => activateDHCP(setDhcpDialog)}
        secondaryBtnAction={() => setDhcpDialog(false)}
      />
      <DialogConfirmation
        show={resetDialog}
        setShow={setResetDialog}
        header={t('exalusNetwork.resetToDefault')}
        content={t('exalusNetwork.resetToDefaultConfirmation')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={() => resetToDefault(setResetDialog)}
        secondaryBtnAction={() => setResetDialog(false)}
      />
    </Page>
  );
};

export default ExalusNetworkSettings;
