import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { environment } from '../../../environment';
import { isWisniowski } from '../../../utils/helpers/wisniowski';
import { getSelectedTheme, removeBodyClass } from '../../../utils/theme';

export const useLogin = () => {
  const { keycloak } = useKeycloak();
  const { i18n } = useTranslation('login');
  const [wisniowski, setWisniowski] = useState<boolean>(false);
  const theme = getSelectedTheme();
  const redirectUrl = `${location.protocol}${environment.PUBLIC_URL}?theme=${theme}`;

  useEffect(() => {
    if (isWisniowski) {
      removeBodyClass('dark');
      setWisniowski(true);
    }
  }, []);

  const handleSocialLogin = (type: 'facebook' | 'google' | 'apple') => {
    keycloak.login({
      locale: i18n.language,
      redirectUri: redirectUrl,
      scope: 'openid profile email',
      idpHint: type,
    });
  };

  const handleLogin = () => {
    keycloak.login({
      locale: i18n.language,
      redirectUri: redirectUrl,
      scope: 'openid profile email',
    });
  };

  const handleRegister = () => {
    keycloak.register({
      locale: i18n.language,
      redirectUri: redirectUrl,
    });
  };

  return {
    wisniowski,
    handleLogin,
    handleSocialLogin,
    handleRegister,
  };
};
