import React from 'react';
import './index.scss';

export const IconRepeat: React.FC = () => (
  <svg
    className="icon-repeat"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="#FF4016"
      strokeWidth="1.5"
      strokeLinecap="round"
      d="M12 12C12 12 9.5 17 6 17C3.23858 17 1 14.7614 1 12C1 9.23858 3.23858 7 6 7C9.5 7 12 12 12 12Z"
    />
    <path
      stroke="#FF4016"
      strokeWidth="1.5"
      strokeLinecap="round"
      d="M12 12C12 12 14.5 7 18 7C20.7614 7 23 9.23858 23 12C23 14.7614 20.7614 17 18 17C14.5 17 12 12 12 12Z"
    />
  </svg>
);
