import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SortChannelActionInterface } from '../../../types';
import Item from './item';

interface PropsInterface {
  list: SortChannelActionInterface[];
  id: string;
}

const ItemSortable: React.FC<PropsInterface> = ({ list, id }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return (
    <Item
      id={id}
      list={list}
      isDraggable
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
    />
  );
};

export default ItemSortable;
