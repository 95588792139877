import React from 'react';

export const IconInfinity: React.FC = () => (
  <svg width="34" height="17" viewBox="0 0 34 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 8.27273C17 8.27273 13.3636 15.5455 8.27273 15.5455C4.25611 15.5455 1 12.2893 1 8.27273C1 4.25611 4.25611 1 8.27273 1C13.3636 1 17 8.27273 17 8.27273Z"
      stroke="#15141D"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
    <path
      d="M17 8.27269C17 8.27269 20.6364 0.999959 25.7273 0.999959C29.7439 0.999958 33 4.25607 33 8.27268C33 12.2893 29.7439 15.5454 25.7273 15.5454C20.6364 15.5454 17 8.27269 17 8.27269Z"
      stroke="#15141D"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
  </svg>
);
