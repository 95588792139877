import React from 'react';

export const IconCallButtonCircle: React.FC = () => {
  return (
    <svg
      className="icon-call-button-circle"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.25" stroke="white" strokeWidth="1.5" />
      <path
        d="M7.05584 16.4101C7.11814 17.084 7.34963 17.5576 7.53474 17.8381C7.67232 18.0466 7.92642 18.1204 8.17223 18.0761L11.8247 17.4175C12.1971 17.3504 12.4715 17.0315 12.4824 16.6532L12.4998 16.0468C12.52 15.3629 13.0449 14.7872 13.7262 14.7077C14.2557 14.6466 14.9623 14.5973 15.8503 14.6048C16.7383 14.6123 17.443 14.6728 17.9689 14.7423C18.6461 14.832 19.139 15.4155 19.1212 16.1002L19.1064 16.6823C19.0964 17.0717 19.3685 17.4117 19.7506 17.4874L23.342 18.1989C23.5827 18.2466 23.8337 18.1806 23.9785 17.9824C24.1801 17.7066 24.4435 17.2328 24.5438 16.5506C24.7392 15.2342 24.17 13.9188 23.0896 13.1654C21.7871 12.2578 19.5831 11.6563 15.9326 11.6264C12.2821 11.5966 10.0462 12.1623 8.69434 13.049C7.57401 13.7835 6.93308 15.0897 7.05504 16.4093L7.05584 16.4101Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
