import { UseQueryResult, useQuery } from 'react-query';
import { Api } from 'lavva.exalushome';
import { IWektaIntercomsService } from 'lavva.exalushome.wektaintercoms/build/js/IWektaIntercomsService';
import { WektaIntercomsService } from 'lavva.exalushome.wektaintercoms/build/js/WektaIntercomsService';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { useBackdropContext } from '../../../hooks';
import { useExalusContext } from '../context';

export const useExalusEntryPhoneStream = (channel?: IDeviceChannel): UseQueryResult<MediaStream | null> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<MediaStream | null>(
    ['exalus-entry-phone', selectedExalusCredentials?.id, synchronized],
    async () => {
      if (channel) {
        turnOnBackdrop();
        const cameraApi = Api.Get<IWektaIntercomsService>(WektaIntercomsService.ServiceName);
        const cam = await cameraApi.GetIntercomAsync(channel.GetDevice());
        const mediaStream = await cam.GetRTCMediaStreamAsync();
        turnOffBackdrop();
        return mediaStream;
      }

      return null;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized && !!channel?.GetDevice,
      retry: false,
      cacheTime: 0,
      staleTime: 0,
      onError: () => turnOffBackdrop(),
    },
  );
};
