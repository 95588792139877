import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { usePressureControl } from '../../../hooks/use-pressure-control';
import { SensorControlBox } from '../../../sensor-control-box';

interface ComponentProps {
  channel: IDeviceChannel;
  isListItem?: boolean;
}

const PressureControl: React.FC<ComponentProps> = ({ channel, isListItem }) => {
  const { pressure } = usePressureControl(channel);

  return <SensorControlBox isListItem={isListItem} value={pressure?.Pressure?.toFixed(1) || '-'} unit={'hPa'} />;
};

export default PressureControl;
