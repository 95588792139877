import React from 'react';

export const IconChest: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-chest"
  >
    <path
      d="M3.04102 16.6559C3.04102 9.96836 6.65066 5 13.3382 5H25.698C32.3856 5 35.9952 9.96836 35.9952 16.6559"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.03711 16.0757V30.6171C3.03711 31.46 3.72054 32.1434 4.56339 32.1434H34.4736C35.3165 32.1434 35.9999 31.46 35.9999 30.6171V16.0757"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 20C20.3284 20 21 19.5523 21 19C21 18.4477 20.3284 18 19.5 18C18.6716 18 18 18.4477 18 19C18 19.5523 18.6716 20 19.5 20Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5385 20.0391L19.5 21.1718"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1309 15.5775V19.9671C15.1309 22.3915 17.096 24.3566 19.5204 24.3566C21.9448 24.3566 23.9099 22.3915 23.9099 19.9671V15.5775"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.28516 15.5775H35.7552"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.88 32.1434C30.8157 31.8572 30.7812 31.5596 30.7812 31.2544C30.7812 29.032 32.5829 27.2303 34.8053 27.2303C35.2206 27.2303 35.6214 27.2933 35.9987 27.4101"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.11872 32.1434C8.18303 31.8572 8.21745 31.5596 8.21745 31.2544C8.21745 29.032 6.4158 27.2303 4.1934 27.2303C3.77809 27.2303 3.37727 27.2933 3 27.4101"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
