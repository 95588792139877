import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Accordion, Header, NavHeader, Page } from '../../../../../components';
import { InstallationAccessType } from '../../../../../data-access/gql-types/graphql';
import { SharedItemType, SharingType } from '../../../../../types';
import { useInstallationShare } from '../../../hooks/use-installation-share';
import SummaryItem from '../../summary-item';

interface ComponentProps {
  children: ReactNode;
}

const SummaryShareInvitation: React.FC<ComponentProps> = ({ children }) => {
  const { t } = useTranslation('installation');
  const { state } = useInstallationShare();

  const renderGroups = useCallback(() => state.groups.map((group) => <p key={group.id}>{group.name}</p>), []);
  const renderChannels = useCallback(() => state.channels.map((channel) => <p key={channel.id}>{channel.name}</p>), []);

  return (
    <Page
      className="share-summary"
      header={
        <>
          <NavHeader />
          <Header title={t('share_path.summary')} />
        </>
      }
    >
      <SummaryItem label={t('share_path.share_email')} value={state.email} />
      {state.subject === SharedItemType.Installation ? (
        <SummaryItem label={t('share_path.share_subject')} value={t(`share_path.${state.subject}`)} />
      ) : (
        <SummaryItem label={t('share_path.share_subject')}>
          <Accordion
            kind="custom"
            title={`${t(`share_path.${state.subject}`)} (${
              state.subject === SharedItemType.Group ? state.groups.length : state.channels.length
            })`}
          >
            <div className="list">{state.subject === SharedItemType.Group ? renderGroups() : renderChannels()}</div>
          </Accordion>
        </SummaryItem>
      )}
      <SummaryItem label={t('share_path.role_title')}>
        <div className="list">
          <p>{t(`share_path.role_${state.permission.toLowerCase()}`)}</p>
          {state.permission === InstallationAccessType.Admin && (
            <div className="admin_role">
              <div className="icon">!</div>
              <p>{t('share_path.admin_summary')}</p>
            </div>
          )}
        </div>
      </SummaryItem>
      {state.access !== SharingType.Undefined && (
        <SummaryItem label={t('share_path.access_restriction')}>
          <div className="list">
            <p>{`${t('share_path.access')} ${t(`share_path.access_${state.access.toLowerCase()}`)}`}</p>
            {state.quantity !== 0 && <p>{`${state.quantity} ${t('share_path.action_per_channel')}`}</p>}
            {state.access === SharingType.Timed && (
              <p>
                {`${t('share_path.from')} ${format(state.dateStart, 'dd.MM.yyyy')}, ${state.timeStart} ${t(
                  'share_path.to',
                )} ${format(state.dateEnd, 'dd.MM.yyyy')}, ${state.timeEnd}`}
              </p>
            )}
          </div>
        </SummaryItem>
      )}
      {children}
    </Page>
  );
};

export default SummaryShareInvitation;
