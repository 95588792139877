import React from 'react';

export const IconInstallation5: React.FC = () => (
  <svg
    className="icon-installation"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="34.8335" y="14.25" width="19" height="31.6667" transform="rotate(90 34.8335 14.25)" stroke="#15141D" />
    <path
      d="M9.19825 6.3335L18.9998 6.3335L28.8014 6.3335L34.8332 14.2502L3.1665 14.2502L9.19825 6.3335Z"
      stroke="#15141D"
    />
    <path d="M15.8333 23.75H9.5" stroke="#15141D" />
    <rect x="22.1665" y="20.5835" width="6.33333" height="12.6667" stroke="#15141D" />
  </svg>
);
