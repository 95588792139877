import React from 'react';

export const IconExalusFlushMountedDimmer: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.869141" y="0.71875" width="56" height="56" rx="12" fill="white" />
    <path
      d="M27.582 17.1416L27.582 12.9055"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.582 17.1387L27.582 12.9026"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0508 28.4375L16.2868 28.4375"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.4043 28.4375L43.6404 28.4375"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5996 17.4551L19.595 20.4504"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3233 43.2595C29.5951 43.2595 30.6262 42.3691 30.6262 41.2709V39.2822M30.6262 39.2822C31.2621 39.2822 31.7776 38.8371 31.7776 38.2879C31.7776 36.5994 32.1761 35.083 32.7023 33.6996C33.2595 32.235 34.8808 28.9106 34.5734 27.374V27.374C33.4085 21.5494 27.584 22.0789 27.584 22.0789M30.6262 39.2822H27.1719"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6262 39.2881V41.2767C30.6262 42.375 29.5951 43.2653 28.3233 43.2653H27.1719M30.6262 39.2881C31.2621 39.2881 31.7776 38.8429 31.7776 38.2938C31.7776 36.6053 32.1761 35.0889 32.7023 33.7055C33.2595 32.2409 34.8808 28.9164 34.5734 27.3799V27.3799C33.4085 21.5553 27.584 22.0848 27.584 22.0848M30.6262 39.2881H27.1719"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.6355 39.2881V41.2767C24.6355 42.375 25.6666 43.2653 26.9384 43.2653H28.0898M24.6355 39.2881C23.9996 39.2881 23.4841 38.8429 23.4841 38.2938C23.4841 36.6053 23.0857 35.0889 22.5594 33.7055C22.0022 32.2409 20.381 28.9164 20.6883 27.3799V27.3799C21.8532 21.5553 27.6778 22.0848 27.6778 22.0848M24.6355 39.2881H28.0898"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7496 27.5971L21.5275 28.3134L21.5276 28.3134L21.7496 27.5971ZM34.1765 28.1462C34.569 28.0137 34.7798 27.5881 34.6473 27.1957C34.5148 26.8032 34.0893 26.5925 33.6968 26.7249L34.1765 28.1462ZM21.0065 28.1519L21.5275 28.3134L21.9716 26.8807L21.4506 26.7192L21.0065 28.1519ZM21.5276 28.3134C25.7079 29.6092 30.0122 29.5518 34.1765 28.1462L33.6968 26.7249C29.83 28.0301 25.849 28.0825 21.9716 26.8807L21.5276 28.3134Z"
      fill="#15141D"
    />
    <rect x="35" y="18" width="10.3691" height="7.15024" rx="1" fill="white" />
    <path
      d="M44.3691 24.0215V19.2304C44.3691 19.1297 44.2587 19.068 44.1729 19.1208L36.3874 23.9118C36.2769 23.9799 36.3251 24.1502 36.4549 24.1502H44.2404C44.3115 24.1502 44.3691 24.0926 44.3691 24.0215Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <rect x="26.8613" y="26" width="19.7188" height="19.7188" rx="2.07566" fill="white" />
    <path
      d="M30.528 43.6426L42.8849 43.6426C43.762 43.6426 44.4736 42.9326 44.4754 42.0555L44.5016 29.6691C44.5034 28.7894 43.7908 28.0752 42.9111 28.0752L36.6865 28.0752L30.528 28.0752C29.6496 28.0752 28.9375 28.7873 28.9375 29.6657L28.9375 42.0521C28.9375 42.9305 29.6496 43.6426 30.528 43.6426Z"
      fill="white"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect width="9.34046" height="0.778372" transform="matrix(0 1 -1 0 32.7949 31.1885)" fill="white" />
    <circle
      cx="32.3531"
      cy="31.6303"
      r="1.0918"
      transform="rotate(90 32.3531 31.6303)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="36.0922"
      r="1.0918"
      transform="rotate(90 32.3531 36.0922)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="40.5541"
      r="1.0918"
      transform="rotate(90 32.3531 40.5541)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
  </svg>
);
