import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MainLayout } from '../layouts/main-layout';
import { BluetoothNotSupportedDialog } from '../modules/device-add/components/bluetooth-not-supported-dialog';
import { BluetoothContextProvider } from '../modules/device-add/context';
import RouterHistoryStackProvider from '../modules/history-stack/provider';
import { isLocalApp } from '../utils/helpers/local-app';
import { RouterSwitch } from './deafult-router';

// import { NativeAppDialog } from '../modules/common/native-app-dialog';

export const AppRoute: React.FC = () => (
  <Router>
    <RouterHistoryStackProvider>
      {!isLocalApp ? (
        <BluetoothContextProvider>
          <MainLayout>
            <RouterSwitch />
            <BluetoothNotSupportedDialog />
            {/* <NativeAppDialog /> */}
          </MainLayout>
        </BluetoothContextProvider>
      ) : (
        <MainLayout>
          <RouterSwitch />
          <BluetoothNotSupportedDialog />
          {/* <NativeAppDialog /> */}
        </MainLayout>
      )}
    </RouterHistoryStackProvider>
  </Router>
);
