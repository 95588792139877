import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../routes';
import { installationExalusLocalState } from '../../../../utils/helpers/local-app';
import { getParameterByName } from '../../../../utils/location';

const ExalusQRCodeAuth: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const serialNumber = getParameterByName('serialNumber');
    const pin = getParameterByName('pin');

    history.push(
      ROUTES.ExalusIntegrationCreate({
        ...installationExalusLocalState,
        exalusCredentials: {
          serialNumber,
          pin,
        },
      }),
    );
  }, []);

  return null;
};

export default ExalusQRCodeAuth;
