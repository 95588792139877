import { useTranslation } from 'react-i18next';
import { ChannelError, ChannelErrorDictionary } from '../../data-access/gql-types/graphql';
import { toastError } from '../../utils/toast';

export const useChannelErrors = () => {
  const { t: tr } = useTranslation('errors');

  const handleErrors = (errors: ChannelError[]) => {
    if (errors.length > 0) {
      const errorItem = errors[0];

      switch (errorItem.error) {
        case ChannelErrorDictionary.ChannelNotFound: {
          toastError({
            content: tr(`ChannelErrorDictionary.${ChannelErrorDictionary.ChannelNotFound}`),
          });
          break;
        }
        case ChannelErrorDictionary.Undefined: {
          toastError({ content: tr(`ChannelErrorDictionary.${ChannelErrorDictionary.Undefined}`) });
          break;
        }
        default: {
          toastError({ content: errorItem.message });
          break;
        }
      }
    }
  };

  return {
    handleErrors,
  };
};
