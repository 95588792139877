import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { StatusScreen } from '../../components';
import { useApi, useBackdropContext } from '../../hooks';
import { useRefetchData } from '../../hooks/refresh-data';
import { ROUTES } from '../../routes';
import { NotificationTypeEnum, StatusTypeEnum } from '../../types';
import InvitationReceivedConfirmation from './confirmation';

const InvitationReceived: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { t } = useTranslation('invitation');
  const history = useHistory();
  const { notificationId } = useParams<{ notificationId: string }>();
  const { notificationList, invitationAcceptById, invitationRejectById, markAsHidden } = useApi();
  const { refetchAll } = useRefetchData();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const data = useMemo(() => {
    const notification = notificationList.find((x) => x.id === notificationId);

    return notification && notification.data.type === NotificationTypeEnum.INVITATION
      ? notification.data
      : null;
  }, [notificationList, notificationId]);

  const onAccept = () => {
    if (data?.invitationId) {
      turnOnBackdrop();
      invitationAcceptById(data?.invitationId, notificationId, () => {
        markAsHidden([notificationId], {
          onSuccess: () => {
            setTimeout(() => {
              refetchAll();
              setShowConfirmation(true);
              turnOffBackdrop();
            }, 3000);
          },
        });
      });
    }
  };

  const onReject = () => {
    if (data?.invitationId) {
      turnOnBackdrop();
      invitationRejectById(data?.invitationId, notificationId, () => {
        markAsHidden([notificationId], {
          onSuccess: () => {
            turnOffBackdrop();
            history.goBack();
          },
        });
      });
    }
  };

  if (showConfirmation) return <InvitationReceivedConfirmation creatorName={data?.userName || ''} />;

  if (data === null) return <Redirect to={ROUTES.Root()} />;

  return (
    <StatusScreen
      header={t('received.title')}
      subTitle={t('received.message', { creatorName: data.userName, installationName: data.installationName })}
      note={t('received.expirationMessage')}
      type={StatusTypeEnum.JOIN}
      secondaryBackground
      onPrimaryBtnClick={onAccept}
      primaryBtnText={t('received.acceptInvitation')}
      onSecondaryBtnClick={onReject}
      secondaryBtnText={t('received.rejectInvitation')}
    />
  );
};

export default InvitationReceived;
