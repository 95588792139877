import React from 'react';

export const IconNight: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02563 4.68693C11.1366 7.6088 12.7427 11.7891 11.9935 16.0379L13.4707 16.2984C14.3169 11.4996 12.4924 6.82431 9.05254 3.59356L8.02563 4.68693ZM11.9935 16.0379C11.2443 20.2869 8.30512 23.6659 4.38224 25.3475L4.97322 26.7261C9.3108 24.8668 12.6245 21.0974 13.4707 16.2984L11.9935 16.0379ZM4.47377 29.2783C12.7954 30.7457 20.7366 25.6795 22.1218 17.8238L20.6446 17.5633C19.4211 24.502 12.345 29.1431 4.73424 27.8011L4.47377 29.2783ZM22.1218 17.8238C23.5069 9.96814 17.7773 2.49142 9.45576 1.0241L9.19528 2.50131C16.806 3.84329 21.868 10.6247 20.6446 17.5633L22.1218 17.8238ZM4.38224 25.3475C3.50637 25.7229 3.01023 26.5562 2.96675 27.3546C2.94465 27.7604 3.0377 28.1934 3.29837 28.5601C3.56666 28.9375 3.97498 29.1904 4.47377 29.2783L4.73424 27.8011C4.59494 27.7766 4.54453 27.7242 4.5209 27.6909C4.48965 27.647 4.45756 27.5641 4.46453 27.4361C4.4792 27.1669 4.65802 26.8612 4.97322 26.7261L4.38224 25.3475ZM9.45576 1.0241C8.9569 0.936138 8.48603 1.03241 8.10353 1.29432C7.73147 1.54908 7.49478 1.92436 7.3766 2.31429C7.144 3.08177 7.33032 4.03388 8.02563 4.68693L9.05254 3.59356C8.79742 3.35395 8.73492 3.00408 8.81212 2.74935C8.84885 2.62818 8.90672 2.5623 8.951 2.53198C8.98484 2.5088 9.0521 2.47606 9.19528 2.50131L9.45576 1.0241Z"
        fill="#15141D"
      />
    </svg>
  );
};
