import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogConfirmation, Header, NavHeader, Page, PopUpNav, SubmitButton } from '../../../../../components';
import { availableGroupIcons } from '../../../../../components/action/edit-icon/available-icons';
import { EditIcon } from '../../../../../components/edit-icon';
import { IconDelete } from '../../../../../components/popup-nav/icons';
import { useExalusGroup } from '../../../hooks';
import GroupName from '../components/group-name';
import GroupedChannels from '../components/grouped-channels';
import { useConfirmPopups } from '../hooks/use-confirm-popups';
import { useGroups } from '../hooks/use-groups';
import { GroupedDeviceChannelsCheckbox } from '../types';

const Edit: React.FC = () => {
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { groupId } = useParams<{ groupId: string }>();
  const { editGroup, deleteGroup, error } = useGroups();
  const { data: group } = useExalusGroup(groupId);
  const { saveDialog, deleteDialog, setSaveDialog, setDeleteDialog, openRemoveDialog, closeRemoveDialog, onCancel } =
    useConfirmPopups();
  const [items, setItems] = useState<GroupedDeviceChannelsCheckbox[]>([]);
  const form = useForm({
    defaultValues: {
      name: '',
      iconName: '',
    },
  });
  const { iconName } = form.watch();

  useEffect(() => {
    if (group) {
      form.setValue('name', group.Name);
      form.setValue('iconName', group.IconName);
    }
  }, [group]);

  const handleRemoveGroup = useCallback(async () => deleteGroup(groupId), [group]);

  const handleEditGroup = () => {
    const { name, iconName } = form.getValues();

    if (group) {
      editGroup(
        { name, iconName, groupedChannels: items, group },
        {
          onError: () => {
            setSaveDialog(false);
          },
        },
      );
    }
  };

  const handleChangeIcon = (value: string) => form.setValue('iconName', value);

  const onSubmit = form.handleSubmit(() => setSaveDialog(true));

  return (
    <>
      <Page
        isStickyHeader
        header={
          <>
            <NavHeader>
              <PopUpNav links={[{ label: t('remove'), onClick: openRemoveDialog, icon: <IconDelete /> }]} />
            </NavHeader>
            <Header title={t('editGroup')} />
          </>
        }
      >
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <GroupName errorMessage={error} />
            <EditIcon currentIcon={iconName} iconList={availableGroupIcons} handleChangeIcon={handleChangeIcon} />
            <hr className="m-t-24 m-b-28" />
            <GroupedChannels items={items} setItems={setItems} />
            <SubmitButton />
          </form>
        </FormProvider>
      </Page>
      <DialogConfirmation
        show={saveDialog}
        setShow={setSaveDialog}
        header={t('confirmDialog.header')}
        primaryBtnText={tc('buttons.save')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleEditGroup}
        secondaryBtnAction={onCancel}
      />
      <DialogConfirmation
        show={deleteDialog}
        setShow={setDeleteDialog}
        header={t('removeDialog.header')}
        content={t('removeDialog.content', { name: group?.Name || '' })}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnAction={closeRemoveDialog}
        primaryBtnAction={handleRemoveGroup}
      />
    </>
  );
};

export default Edit;
