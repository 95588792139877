import React from 'react';

export const IconExalusWallMountedRemote: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.318359" y="1" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23131_14325)">
      <path
        d="M40.5146 15H15.2012C13.9855 15 13 16.0731 13 17.3968V40.1667C13 41.4904 13.9855 42.5635 15.2012 42.5635H40.5146C41.7302 42.5635 42.7157 41.4904 42.7157 40.1667V17.3968C42.7157 16.0731 41.7302 15 40.5146 15Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3878 18.5947H17.3299C16.7626 18.5947 16.3027 19.0546 16.3027 19.6219V37.9405C16.3027 38.5078 16.7626 38.9677 17.3299 38.9677H38.3878C38.9551 38.9677 39.415 38.5078 39.415 37.9405V19.6219C39.415 19.0546 38.9551 18.5947 38.3878 18.5947Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8574 18.5947V38.9677"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6035 35.373H24.0058"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.709 35.373H36.1113"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.8257 15.8252C46.7911 14.3242 46.1697 12.8499 44.9777 11.7766C43.7595 10.6798 42.1873 10.2194 40.6599 10.3707"
        stroke="#15141D"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M44.7284 16.2132C44.7047 15.1862 44.2796 14.1775 43.464 13.4432C42.6305 12.6927 41.5547 12.3777 40.5097 12.4812"
        stroke="#15141D"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23131_14325">
        <rect width="38" height="38" fill="white" transform="translate(9.31836 9.56055)" />
      </clipPath>
    </defs>
  </svg>
);
