import {
  EnergyMeasurementParameter,
  IDeviceStateData,
  MeasuredEnergyState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';

interface EnergyParams {
  unit: string;
  symbol: string;
  hidden?: boolean;
}

export const energyParams: Record<string, EnergyParams> = {
  [EnergyMeasurementParameter.Voltage]: {
    unit: 'V',
    symbol: 'U',
  },
  [EnergyMeasurementParameter.Current]: {
    unit: 'A',
    symbol: 'I',
  },
  [EnergyMeasurementParameter.PhaseShift]: {
    unit: '°',
    symbol: '∅',
  },
  [EnergyMeasurementParameter.ActivePower]: {
    unit: 'W',
    symbol: 'P',
  },
  [EnergyMeasurementParameter.ReactivePower]: {
    unit: 'var',
    symbol: 'Q',
  },
  [EnergyMeasurementParameter.ApparentPower]: {
    unit: 'VA',
    symbol: 'S',
  },
  [EnergyMeasurementParameter.PowerFactor]: {
    unit: '',
    symbol: 'cos (∅)',
  },
  [EnergyMeasurementParameter.ActiveForwardEnergy]: {
    unit: 'kWh',
    symbol: 'E',
  },
  [EnergyMeasurementParameter.ActiveReverseEnergy]: {
    unit: 'J',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.ApparentEnergy]: {
    unit: 'J',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.ReactiveForwardEnergy]: {
    unit: 'kvarh',
    symbol: 'J',
  },
  [EnergyMeasurementParameter.ReactiveReverseEnergy]: {
    unit: 'J',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.Frequency]: {
    unit: 'Hz',
    symbol: 'f',
  },
  [EnergyMeasurementParameter.SPIErrorRate]: {
    unit: '',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.CRCErrorRate]: {
    unit: '',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.Timestamp]: {
    unit: '',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.FailedMeasurementsRate]: {
    unit: '',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.FundamentalActivePower]: {
    unit: 'W',
    symbol: '',
    hidden: true,
  },
  [EnergyMeasurementParameter.HarmonicActivePower]: {
    unit: 'W',
    symbol: '',
    hidden: true,
  },
};

export const getMeasurementParameters = (argumentDeviceState?: IDeviceStateData) => {
  const allKeys = Object.values(EnergyMeasurementParameter);
  let key = '';
  let value: number | null = null;

  for (let i = 0; i < allKeys.length; i++) {
    key = allKeys[i];
    value =
      (argumentDeviceState as MeasuredEnergyState)?.MeasurementParameters.get(EnergyMeasurementParameter[allKeys[i]]) ||
      null;

    if (value) break;
  }

  const params: EnergyParams = energyParams[key];

  return {
    key,
    value,
    unit: params.unit,
    symbol: params.symbol,
  };
};
