import { useMemo } from 'react';
import { GateFeatureTypeInternal, GateModeInternal } from '../../../../data-access/gql-types/graphql';
import { ChannelGateInterface, ChannelInterface } from '../../../../types';

export const useGateSupportedFeatures = (channel?: ChannelInterface) => {
  const gateChannel = channel?.data as ChannelGateInterface | undefined;

  const ventilationSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.Tilt);
    }

    return gateChannel?.gateMode === GateModeInternal.RollUp;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const setDirectionSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir);
    }

    return gateChannel?.gateMode === GateModeInternal.RollUp;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const setPositionSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.SetPos);
    }

    return gateChannel?.gateMode === GateModeInternal.RollUp;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const calibrationSupported = useMemo(() => {
    return gateChannel?.supportedGateFeatures.includes(GateFeatureTypeInternal.Calibrate);
  }, [gateChannel?.supportedGateFeatures]);

  const stepByStepSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.StepByStep);
    }

    return gateChannel?.gateMode === GateModeInternal.StepByStep;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const electricStrikeSupported = useMemo(() => {
    return gateChannel?.supportedGateFeatures.includes(GateFeatureTypeInternal.ElectricStrike);
  }, [gateChannel?.supportedGateFeatures]);

  return {
    ventilationSupported,
    setDirectionSupported,
    setPositionSupported,
    calibrationSupported,
    electricStrikeSupported,
    stepByStepSupported,
  };
};
