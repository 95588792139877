import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useInstallation, useNative } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { toastError } from '../../../utils/toast';
import { AddDeviceHook } from '../types';

export const useAddDevice = (): AddDeviceHook => {
  const history = useHistory();
  const { t } = useTranslation('channel-settings');
  const { selectedInstallation } = useInstallation();
  const { setShowBluetoothNotSupportedDialog } = useNative();
  const { permissions } = usePermissionsContext();

  const addDevice = useCallback(() => {
    if (permissions[UserPermissionType.DeviceAdd]) {
      if ('bluetooth' in window.navigator) history.push(ROUTES.DeviceAddBluetooth());
      else setShowBluetoothNotSupportedDialog(true);
    } else {
      toastError({ content: t('toast.noPermissions') });
    }
  }, [selectedInstallation, permissions]);

  return {
    addDevice,
  };
};
