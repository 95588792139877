import React from 'react';

export const IconExalusFlushMountedGateController: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.158203" y="0.71875" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23191_242694)">
      <path
        d="M14.748 19.6353L28.6022 14.0264L28.6022 43.193L14.748 43.193L14.748 19.6353Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3672 17.6719V42.6318"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.457 19.6353L28.6029 14.0264L28.6029 43.193L42.457 43.193L42.457 19.6353Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9512 16.9434L35.9512 43.1934"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7486 27.4883H12.6172"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5884 27.4883H42.457"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7486 35.3408H12.6172"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5884 35.3408H42.457"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect x="26.8613" y="26" width="19.7188" height="19.7188" rx="2.07566" fill="white" />
    <path
      d="M30.528 43.6426L42.8849 43.6426C43.762 43.6426 44.4736 42.9326 44.4754 42.0555L44.5016 29.6691C44.5034 28.7894 43.7908 28.0752 42.9111 28.0752L36.6865 28.0752L30.528 28.0752C29.6496 28.0752 28.9375 28.7873 28.9375 29.6657L28.9375 42.0521C28.9375 42.9305 29.6496 43.6426 30.528 43.6426Z"
      fill="white"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect width="9.34046" height="0.778372" transform="matrix(0 1 -1 0 32.7949 31.1885)" fill="white" />
    <circle
      cx="32.3531"
      cy="31.6303"
      r="1.0918"
      transform="rotate(90 32.3531 31.6303)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="36.0922"
      r="1.0918"
      transform="rotate(90 32.3531 36.0922)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="40.5541"
      r="1.0918"
      transform="rotate(90 32.3531 40.5541)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <defs>
      <clipPath id="clip0_23191_242694">
        <rect width="35" height="35" fill="white" transform="translate(11.1582 11.1094)" />
      </clipPath>
    </defs>
  </svg>
);
