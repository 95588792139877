import React from 'react';

export const IconWardrobe: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-wardrobe"
  >
    <path
      d="M19.8738 1H9C8.44772 1 8 1.44772 8 2V35.97C8 36.5223 8.44772 36.97 9 36.97H19.8738"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.2677 1H19.9902V36.97H29.2677C29.82 36.97 30.2677 36.5223 30.2677 35.97V2C30.2677 1.44772 29.82 1 29.2677 1Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M27.6973 18.127V28.5" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 23.2667H19.9893" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 30.1183H19.9893" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12H19.9893" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.1387 33.5442H14.8526"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1387 26.6926H14.8526"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
