import React from 'react';

export const IconCallButton: React.FC = () => {
  return (
    <svg
      className="icon-call-button"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.0107422" width="32" height="32" rx="16" fill="#00B191" />
      <g clipPath="url(#clip0_21844_234381)">
        <path
          d="M7.52068 17.0039C7.58299 17.6778 7.81448 18.1514 7.99958 18.4319C8.13716 18.6403 8.39126 18.7141 8.63708 18.6698L12.2895 18.0113C12.6619 17.9441 12.9364 17.6252 12.9472 17.247L12.9647 16.6406C12.9849 15.9567 13.5098 15.381 14.191 15.3015C14.7206 15.2403 15.4272 15.191 16.3152 15.1985C17.2031 15.206 17.9079 15.2666 18.4338 15.336C19.111 15.4257 19.6039 16.0093 19.5861 16.6939L19.5712 17.276C19.5612 17.6655 19.8333 18.0055 20.2155 18.0812L23.8068 18.7926C24.0476 18.8403 24.2985 18.7743 24.4433 18.5761C24.6449 18.3003 24.9084 17.8266 25.0086 17.1443C25.204 15.828 24.6348 14.5126 23.5544 13.7592C22.2519 12.8516 20.048 12.25 16.3975 12.2202C12.747 12.1904 10.511 12.7561 9.15919 13.6427C8.03885 14.3772 7.39793 15.6834 7.51989 17.0031L7.52068 17.0039Z"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_21844_234381">
          <rect width="19.2" height="19.2" fill="white" transform="translate(6.90039 6.41113)" />
        </clipPath>
      </defs>
    </svg>
  );
};
