import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoginError } from 'lavva.exalushome/build/js/Services/Session/ISessionService';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { toastError } from '../../../../../utils/toast';
import { useCreateInstallationContext } from '../../../../installation-create/context';
import { useCreateInstallation } from '../../../../installation-create/hooks/use-create-installation';
import { useExalusIntegrationLogin } from '../../../hooks';
import { IntegrationLoginHook, LoginForm } from '../types';

export const useIntegrationLogin = (serialNumber = '', pin = ''): IntegrationLoginHook => {
  const { t } = useTranslation('login');
  const { mutate } = useExalusIntegrationLogin();
  const { submitInstallation } = useCreateInstallation();
  const { state: installationState } = useCreateInstallationContext();
  const [customMode, setCustomMode] = useState<boolean>(false);
  const defaultValues = {
    email: 'installator@installator',
    password: `${serialNumber}${pin}`,
  };

  const form = useForm<LoginForm>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (customMode) {
      form.setValue('email', '');
      form.setValue('password', '');
    } else {
      form.setValue('email', defaultValues.email);
      form.setValue('password', defaultValues.password);
    }
  }, [customMode]);

  const onSubmit = (formData: LoginForm) => {
    mutate(formData, {
      onSuccess: async (data: IUser | LoginError) => {
        if ((data as IUser).Guid) {
          submitInstallation(installationState, formData);
        } else {
          toastError({ content: t(`exalus.${LoginError[data as LoginError]}`) });
        }
      },
    });
  };

  return {
    form,
    onSubmit,
    customMode,
    setCustomMode,
  };
};
