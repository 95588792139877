import React from 'react';
import './index.scss';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconBulbGU10: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg width="38" height="38" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-switch-bigger icon-bulb-gu10">
      {isOn && (
        <>
          <path
            d="M27.8525 15.8979L27.8525 11.1112"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.8525 15.8979L27.8525 11.1112"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4419 16.252L18.8266 19.6367"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.2632 16.252L36.8784 19.6367"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
      <path
        d="M19 25H20M38 25H37M37 25H20M37 25L36.9735 25.3969C36.7369 28.9468 35.3408 32.3207 33 35V35M33 35H24M33 35V40.2174C33 40.6496 32.6496 41 32.2174 41H31M24 35V40.2174C24 40.6496 24.3504 41 24.7826 41H26M24 35V35C21.7723 32.5749 20.4048 29.4838 20.1087 26.2041L20 25M31 41V43M31 41H26M31 43H30M31 43H32M26 41V43M26 43H27M26 43H25"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
