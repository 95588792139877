import React, { ReactNode, useCallback, useMemo } from 'react';
import c from 'classnames';
import { orderBy } from 'lodash';
import { PHASES_COLORS } from '../../../const';
import { VectorMeasurementResponseItem } from '../../../data-access/gql-types/graphql';
import { SelectedPhase } from '../../../modules/channel-details/measurement/types';
import { getClosestValueFromArray } from '../../../utils/helpers';
import { IconTriangle } from '../../icons';
import './index.scss';

interface PropsInterface {
  value: number;
  maxScaleValue: number;
  averageValue: number;
  phaseMeasurements: VectorMeasurementResponseItem[] | undefined;
  selectedPhases: SelectedPhase[];
  children?: ReactNode;
  ticks?: boolean;
  indicator?: boolean;
  color?: string;
}

export const DonutChart: React.FC<PropsInterface> = ({
  value,
  maxScaleValue,
  averageValue,
  phaseMeasurements,
  selectedPhases,
  children,
  ticks = true,
  indicator = true,
  color,
}) => {
  const maxOffsetValue = 75;
  const donutRadius = 16;

  const calculatePercentageValue = useCallback(
    (value: number) => {
      const calculatedValue = (value / maxScaleValue) * maxOffsetValue;
      if (calculatedValue < 0 || calculatedValue > 75) return 75;
      return calculatedValue;
    },
    [maxScaleValue, maxOffsetValue],
  );
  const donutCircuit = useMemo(() => 2 * Math.PI * donutRadius, [donutRadius]);

  const isSummary = useMemo(() => {
    return !selectedPhases.length || (selectedPhases.length === 1 && selectedPhases[0].value === 0);
  }, [selectedPhases]);

  const avgIndicatorPositionStyle = useMemo(() => {
    const avgPercentage = (value / maxScaleValue) * 100;
    const angle = (avgPercentage / 100) * (360 * (maxOffsetValue / 100));
    const radian = (angle - 45) * (Math.PI / 180);

    const posX = Math.round(150 * Math.cos(radian));
    const posY = Math.round(150 * Math.sin(radian));

    const top = 150 - posY + 'px';
    const left = 150 - posX + 'px';

    const initialTriangleRotation = -45;

    return {
      '--left': left,
      '--top': top,
      '--rotation': initialTriangleRotation + angle + 'deg',
    } as React.CSSProperties;
  }, [value, maxScaleValue]);

  const percentageArray = Array.from(Array(67).keys()).map((number) => number / 0.67);
  const tickWidth = 9;
  const tickHeight = 1.5;
  const radius = 136;
  const step = (2 * (Math.PI * 0.75)) / percentageArray.length;
  const avgPercentage = (averageValue / maxScaleValue) * 100;

  return (
    <>
      {indicator && (
        <div className="donut-chart__avg-indicator" style={avgIndicatorPositionStyle}>
          <IconTriangle style={avgIndicatorPositionStyle} />
        </div>
      )}

      {ticks && (
        <div className="donut-chart__sunlight-ticks">
          {percentageArray.map((item, index) => {
            const valuePercentage = (value / maxScaleValue) * 100;
            const tickPercentage = index / 0.67;
            const closestTick = getClosestValueFromArray(avgPercentage, percentageArray) === tickPercentage;
            const deg = index * (270 / percentageArray.length);
            const angle = index * step;

            const positionX = Math.round(150 + radius * Math.cos(angle) - (closestTick ? 11 : tickWidth) / 2);
            const positionY = Math.round(150 + radius * Math.sin(angle) - tickHeight / 2);

            const tickStyle = {
              left: positionX,
              top: positionY,
              transform: `rotate(${deg}deg)`,
              '--background': isSummary
                ? PHASES_COLORS[0]
                : selectedPhases.length === 1
                ? selectedPhases[0].color
                : 'var(--color-type-primary)',
            } as React.CSSProperties;

            return (
              <div
                className={c('donut-chart__sunlight-tick', {
                  'donut-chart__sunlight-tick--active': tickPercentage <= valuePercentage,
                  'donut-chart__sunlight-tick--big':
                    getClosestValueFromArray(avgPercentage, percentageArray) === tickPercentage,
                })}
                key={index}
                style={tickStyle}
              ></div>
            );
          })}
        </div>
      )}

      <svg className="donut-chart" width="100%" height="100%" viewBox="0 0 40 40">
        <filter id="inner-shadow">
          <feOffset dx="-0.1" dy="-0.1" result="offset-blur" />
          <feGaussianBlur stdDeviation=".35" result="offset-blur" />
          <feFlood floodColor={PHASES_COLORS[0]} result="color" />
          <feComposite operator="in" in="SourceGraphic" in2="offset-blur" />
        </filter>

        <circle
          className="donut-chart__progress"
          cx="20"
          cy="20"
          r={donutRadius}
          fill="transparent"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeDasharray={donutCircuit}
          strokeDashoffset={donutCircuit * ((100 - 75) / 100)}
        />

        {isSummary ? (
          <circle
            className="donut-chart__chart"
            cx="20"
            cy="20"
            r={donutRadius}
            fill="transparent"
            stroke={color || PHASES_COLORS[1]}
            filter={'url(#inner-shadow)'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeDasharray={donutCircuit}
            strokeDashoffset={donutCircuit * ((100 - calculatePercentageValue(value)) / 100)}
          />
        ) : (
          <>
            {[
              ...orderBy(selectedPhases, 'value', 'asc').map((selectedPhase, index) => {
                const measurement = phaseMeasurements?.find((measurement) => measurement.index === selectedPhase.value);

                if (!measurement?.value) {
                  return null;
                }

                const previousMeasurementsSum =
                  phaseMeasurements
                    ?.filter((measurement) => selectedPhases.find((phase) => phase.value === measurement.index))
                    ?.splice(0, index)
                    ?.reduce((prev, curr) => prev + curr.value, 0) || 0;

                const style: React.CSSProperties = {
                  transform: `rotate(${-225 + 360 * (calculatePercentageValue(previousMeasurementsSum) / 100)}deg)`,
                };

                return (
                  <circle
                    key={selectedPhase.value}
                    className="donut-chart__chart"
                    cx="20"
                    cy="20"
                    r={donutRadius}
                    fill="transparent"
                    stroke={PHASES_COLORS[selectedPhase.value]}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeDasharray={donutCircuit}
                    strokeDashoffset={donutCircuit * ((100 - calculatePercentageValue(measurement.value)) / 100)}
                    {...(previousMeasurementsSum ? { style } : {})}
                  />
                );
              }),
            ].reverse()}
          </>
        )}

        <rect className="donut-chart__rect" width="30" height="30" />
        {children}
      </svg>
    </>
  );
};
