import React from 'react';

export const IconBird: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 16.4998C17.6667 17.4998 12.4 19.9998 6 21.9998M6 21.9998C2 23.9998 1 27.4998 1 28.9998C2.33333 27.1665 6.5 23.4998 12.5 23.4998C13.8587 23.4998 15.2009 23.385 16.5 23.1761M6 21.9998L10.472 17.8473C12.15 16.2892 13.5732 14.472 15.1274 12.7905C16.9377 10.8317 19.1016 9.04835 20.4876 7.91801C20.8211 7.64598 21.0551 7.27354 21.1638 6.85708C22.122 3.18423 23.2989 0.527005 28.5 0.999831C30.3717 1.16999 30.9828 2.19067 30.9259 3.49991M30.9259 3.49991C30.8583 5.05512 29.8482 7.01764 28.8891 8.44532C28.6608 8.78506 28.6465 9.22595 28.8277 9.59294C29.6061 11.1694 30.3992 14.0512 29 16.4998C27.3623 19.3658 22.3721 22.2317 16.5 23.1761M30.9259 3.49991H34.5M16.5 23.1761L19.5 27.9999"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
