import React, { useMemo } from 'react';
import {
  ChannelTypeInternal,
  GateFeatureTypeInternal,
  GateModeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelGateInterface, ChannelInterface } from '../../../../../types';
import { RollerControls } from '../../../../roller-controls';
import { useGateAction } from '../../../../../hooks/use-action-control/use-gate-action';

interface ComponentProps {
  channels: ChannelInterface[];
  isListItem?: boolean;
}

export const GateActionControlBox: React.FC<ComponentProps> = ({ isListItem, channels }) => {
  const { handleAction } = useGateAction(channels);

  const handleGateAction = (type: string) => handleAction(type);

  const isRollUpControls = useMemo(() => {
    if (channels.every((x) => (x.data as ChannelGateInterface).supportedGateFeatures.length)) {
      return channels.every((x) =>
        (x.data as ChannelGateInterface).supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir),
      );
    }

    return channels.every(
      (channel) =>
        channel.data.type === ChannelTypeInternal.Gate &&
        (channel.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp,
    );
  }, [channels]);

  return (
    <>
      {isRollUpControls ? (
        <RollerControls
          handleAction={handleGateAction}
          action
          isListItem={isListItem}
          type={ChannelTypeInternal.Gate}
        />
      ) : null}
    </>
  );
};
