import React from 'react';

export const IconMelody: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-melody"
  >
    <g clipPath="url(#clip0_24319_29360)">
      <path
        d="M22.0945 33.342C24.7971 31.874 26.0397 28.9381 24.8699 26.7845C23.7001 24.6309 20.5609 24.0751 17.8583 25.5431C15.1557 27.0111 13.9132 29.947 15.083 32.1006C16.2527 34.2542 19.3919 34.81 22.0945 33.342Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M25.3105 28.5098V3C25.3105 3 26.8718 7.41576 30.8575 11.2986C34.7466 15.0911 32.4499 22.107 30.8575 23.5"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.5 20L12.8485 23.3852"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 17L17.4609 21.3285"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 28.5396H10.7863" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_24319_29360">
        <rect width="38" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
