import React, { ChangeEvent, useCallback, useState } from 'react';
import { IconPassword } from '../icons';
import { Input } from './input';

interface InputPasswordProps {
  defaultValue?: string;
  value?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  label: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  hasError?: boolean;
  reverse?: boolean;
}

export const InputPassword: React.FC<InputPasswordProps> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = useCallback(
    (event: React.PointerEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowPassword(!showPassword);
    },
    [setShowPassword, showPassword],
  );

  return (
    <Input {...props} inputType={showPassword ? 'text' : 'password'} reverse={props.reverse}>
      <button onClick={toggleShowPassword} className="input__icon" type="button">
        <IconPassword isOn={!showPassword} />
      </button>
    </Input>
  );
};
