import React from 'react';

export const IconInstallation12: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M8.41699 30.0833L8.41699 14.25" stroke="#15141D" />
    <path d="M16.334 30.0833L16.334 14.25" stroke="#15141D" />
    <path d="M24.25 30.0833L24.25 14.25" stroke="#15141D" />
    <path d="M32.167 30.0833L32.167 14.25" stroke="#15141D" />
    <path d="M20.292 4.75L36.917 14.25L3.66699 14.25L20.292 4.75Z" stroke="#15141D" />
    <path d="M3.66699 34.8335L36.917 34.8335" stroke="#15141D" />
  </svg>
);
