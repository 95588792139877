import {
  ActionConfigResponse,
  ChannelTypeInternal,
  GenericInputChannelStateResponse,
  InputTriggerInternal,
  UserChannel,
} from '../../../../data-access/gql-types/graphql';
import { millisecondsToTime } from '../../../../utils/helpers';
import { ActionBindingType, BindingListItem, BindingsItem, InputBindingTrigger } from '../types';

export const actionBindingTypeByChannelType = (
  channelType: ChannelTypeInternal | 'Cloud' | undefined,
  binding: ActionConfigResponse,
) => {
  switch (channelType) {
    case ChannelTypeInternal.Switch: {
      return binding.isDefaultAction ? ActionBindingType.Default : ActionBindingType.Switch;
    }
    case ChannelTypeInternal.Light: {
      return binding.isDefaultAction ? ActionBindingType.Default : ActionBindingType.Light;
    }
    case ChannelTypeInternal.Blind: {
      switch (binding.slot) {
        case 1:
          return ActionBindingType.BlindDefault1;
        case 2:
          return ActionBindingType.BlindDefault2;
        case 1000:
          return ActionBindingType.Blind;
        default:
          return null;
      }
    }
    case ChannelTypeInternal.Gate: {
      switch (binding.slot) {
        case 1:
          return ActionBindingType.GateDefault1;
        case 2:
          return ActionBindingType.GateDefault2;
        case 3:
          return ActionBindingType.GateDefault3;
        case 4:
          return ActionBindingType.GateDefault4;
        case 1000:
          return ActionBindingType.Gate;
        default:
          return null;
      }
    }
    case 'Cloud': {
      return ActionBindingType.Cloud;
    }
    default: {
      return ActionBindingType.Default;
    }
  }
};

export const inputTriggerMap = {
  [InputTriggerInternal.SingleClick]: InputBindingTrigger.SingleClick,
  [InputTriggerInternal.DoubleClick]: InputBindingTrigger.DoubleClick,
};

const mapActionBindings = (binding: ActionConfigResponse, channels: UserChannel[]) => {
  let channelType: ChannelTypeInternal | 'Cloud' | undefined = undefined;

  if (binding.destination) {
    channelType = channels.find((x) => x.id === binding.destination?.channelId)?.channelType;
  } else {
    if (('onSignalActionId' in binding || 'offSignalActionId' in binding) && binding.slot === 1001) {
      channelType = 'Cloud';
    }
  }

  return {
    inputTrigger: inputTriggerMap[binding.inputTrigger],
    slot: binding.slot,
    channelType,
    actionType: actionBindingTypeByChannelType(channelType, binding),
    ...(binding.destination ? { channelId: binding.destination.channelId } : {}),
    ...('onSignalActionId' in binding || 'offSignalActionId' in binding ? { channelId: 'cloud' } : {}),
    ...('setOnTimeValue' in binding && binding.setOnTimeValue !== null
      ? { setOnTimeValue: millisecondsToTime(binding.setOnTimeValue as number) }
      : {}),
    ...('position' in binding && binding.position !== null ? { position: binding.position } : {}),
    ...('dir' in binding && binding.dir !== null ? { dir: binding.dir } : {}),
    ...('brightness' in binding && binding.brightness !== null ? { brightness: binding.brightness } : {}),
    ...('temperature' in binding && binding.temperature !== null ? { temperature: binding.temperature } : {}),
    ...('r' in binding && binding.r !== null ? { r: binding.r } : {}),
    ...('g' in binding && binding.g !== null ? { g: binding.g } : {}),
    ...('b' in binding && binding.b !== null ? { b: binding.b } : {}),
    ...('onSignalActionId' in binding && binding.onSignalActionId !== null
      ? { onSignalActionId: binding.onSignalActionId }
      : {}),
    ...('offSignalActionId' in binding && binding.offSignalActionId !== null
      ? { offSignalActionId: binding.offSignalActionId }
      : {}),
  } as BindingListItem;
};

export const mapBindingData = (inputChannel: UserChannel, channels: UserChannel[]) => {
  const list = ((inputChannel.payload as GenericInputChannelStateResponse).actionBindings || []).map((binding) =>
    mapActionBindings(binding, channels),
  );

  return {
    inputId: inputChannel.id,
    single: list.filter((binding) => binding.inputTrigger === InputBindingTrigger.SingleClick),
    double: list.filter((binding) => binding.inputTrigger === InputBindingTrigger.DoubleClick),
  } as BindingsItem;
};
