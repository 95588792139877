import React, { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Checkbox, ChannelIcon } from '../../components';
import { ChannelTypeInternal, GateModeInternal } from '../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../hooks';
import { ChannelGateInterface, ChannelInterface } from '../../types';

interface PropInterface {
  channelsType?: ChannelTypeInternal;
}

const ActionChannels: React.FC<PropInterface> = ({ channelsType }) => {
  const { t: tc } = useTranslation('common');
  const { channelList } = useDevicesAndChannels();
  const { control } = useFormContext();

  const list = useMemo(
    () =>
      channelList
        .filter((channel) => channel && channel.data.type === channelsType)
        .filter((x) => {
          if (x.data.type === ChannelTypeInternal.Gate) {
            return (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp;
          }

          return true;
        }) as ChannelInterface[],
    [channelsType, channelList],
  );

  return (
    <div className="action-form__devices">
      <div className="action-form__devices-header">{tc(`typesPlural.${channelsType}`)}</div>
      <div className="action-form__devices-list">
        {list.map((element) => (
          <Controller
            control={control}
            name={`channels.${element.id}` || 'channels.unknown'}
            key={element.id}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={element.id}
                key={element.id}
                onChange={(e) => {
                  field.onChange(e);
                }}
                checked={field.value}
              >
                <div className={classNames('action-form__devices-element', { invisible: !element.isVisible })}>
                  <div className="action-form__devices-icon">
                    <ChannelIcon channel={element} statusOn />
                  </div>
                  <span>{element.alias}</span>
                </div>
              </Checkbox>
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default ActionChannels;
