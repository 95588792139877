import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceStateArgument } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import {
  IconTime,
  IconCyclical,
  IconCalendar,
  IconWeather,
  IconNotification,
  IconRemote,
  IconActions,
  IconThermometer,
  IconThreeDrops,
  IconOneArrowPressure,
  IconWindFan,
  IconLeak,
  IconThunder,
  IconPadlock,
  IconWoodenGate,
  IconHumanMovement,
} from '../../../../../components/icons';
import { IconSun } from '../../../../../components/icons';
import { ConditionSourceType, ConditionSourceOption, ConditionDeviceSourceOption } from '../types';

export const deviceSources: ConditionDeviceSourceOption[] = [
  {
    title: 'action.create.conditions.sources.list.remotes',
    icon: <IconRemote />,
    sourceType: ConditionSourceType.Remotes,
    deviceResponses: [DeviceResponseType.RemoteButtonState],
  },
  {
    title: 'action.create.conditions.sources.list.blindRemotes',
    icon: <IconRemote />,
    sourceType: ConditionSourceType.BlindRemotes,
    deviceResponses: [DeviceResponseType.BlindRemoteButtonState],
  },
  {
    title: 'action.create.conditions.sources.list.facadeRemotes',
    icon: <IconRemote />,
    sourceType: ConditionSourceType.FacadeRemotes,
    deviceResponses: [DeviceResponseType.FacadeRemoteButtonState],
  },
  {
    title: 'action.create.conditions.sources.list.bells',
    icon: <IconNotification />,
    sourceType: ConditionSourceType.Bells,
    deviceResponses: [DeviceResponseType.DoorBellState],
  },
  {
    title: 'action.create.conditions.sources.list.temperature',
    icon: <IconThermometer isOn />,
    sourceType: ConditionSourceType.Temperature,
    deviceResponses: [DeviceResponseType.MeasuredTemperature],
  },
  {
    title: 'action.create.conditions.sources.list.humidity',
    icon: <IconThreeDrops isOn />,
    sourceType: ConditionSourceType.Humidity,
    deviceResponses: [DeviceResponseType.HumiditySensorState],
  },
  {
    title: 'action.create.conditions.sources.list.brightness',
    icon: <IconSun isOn />,
    sourceType: ConditionSourceType.Brightness,
    deviceResponses: [DeviceResponseType.MeasuredBrightness],
  },
  {
    title: 'action.create.conditions.sources.list.pressure',
    icon: <IconOneArrowPressure isOn />,
    sourceType: ConditionSourceType.Pressure,
    deviceResponses: [DeviceResponseType.PressureSensorState],
  },
  {
    title: 'action.create.conditions.sources.list.windSpeed',
    icon: <IconWindFan isOn />,
    sourceType: ConditionSourceType.WindSpeed,
    deviceResponses: [DeviceResponseType.MeasuredWindSpeed, DeviceResponseType.WindSpeedState],
  },
  {
    title: 'action.create.conditions.sources.list.windThreshold',
    icon: <IconWindFan isOn />,
    sourceType: ConditionSourceType.WindThreshold,
    deviceResponses: [DeviceResponseType.WindThreshold, DeviceResponseType.CurrentWindThreshold],
  },
  {
    title: 'action.create.conditions.sources.list.floodSensor',
    icon: <IconLeak isOn />,
    sourceType: ConditionSourceType.FloodSensor,
    deviceResponses: [DeviceResponseType.FloodSensorState],
  },
  {
    title: 'action.create.conditions.sources.list.energy',
    icon: <IconThunder isOn />,
    sourceType: ConditionSourceType.Energy,
    deviceResponses: [DeviceResponseType.MeasuredEnergy],
  },
  {
    title: 'action.create.conditions.sources.list.reed',
    icon: <IconPadlock isOn />,
    sourceType: ConditionSourceType.ReedSensor,
    deviceResponses: [DeviceResponseType.ReedState],
  },
  {
    title: 'action.create.conditions.sources.list.movementSensor',
    icon: <IconHumanMovement isOn />,
    sourceType: ConditionSourceType.MovementSensor,
    deviceResponses: [DeviceResponseType.MovementSensorState],
  },
  {
    title: 'action.create.conditions.sources.list.gate',
    icon: <IconWoodenGate position={50} />,
    sourceType: ConditionSourceType.Gate,
    deviceResponses: [DeviceResponseType.GatePosition, DeviceResponseType.GatewayPosition],
  },
];

export const scheduleSources: ConditionSourceOption[] = [
  {
    title: 'action.create.conditions.sources.list.time',
    icon: <IconTime />,
    sourceType: ConditionSourceType.Time,
    allowType: SupportedArgumentTypes.ArgumentAsTime,
  },
  {
    title: 'action.create.conditions.sources.list.daysWeek',
    icon: <IconCalendar />,
    sourceType: ConditionSourceType.DaysOfWeek,
    allowType: SupportedArgumentTypes.ArgumentAsDaysOfWeek,
  },
  {
    title: 'action.create.conditions.sources.list.timer',
    icon: <IconCyclical />,
    sourceType: ConditionSourceType.Timer,
    allowType: SupportedArgumentTypes.ArgumentAsTimeSpan,
  },
  {
    title: 'action.create.conditions.sources.list.astronomical',
    icon: <IconWeather />,
    sourceType: ConditionSourceType.Astronomical,
    allowType: SupportedArgumentTypes.ArgumentAsAstronomicalClockWithOffset,
  },
];

export const scenarioSources: ConditionSourceOption[] = [
  {
    title: 'action.create.conditions.sources.list.scenarioExecution',
    icon: <IconActions />,
    sourceType: ConditionSourceType.ScenarioExecution,
    allowType: SupportedArgumentTypes.ArgumentAsSceneExecuted,
  },
];

export const getSourceTypeByArgument = (argumentState?: DeviceStateArgument) => {
  switch (argumentState?.Type) {
    case DeviceResponseType.RemoteButtonState: {
      return ConditionSourceType.Remotes;
    }
    case DeviceResponseType.BlindRemoteButtonState: {
      return ConditionSourceType.BlindRemotes;
    }
    case DeviceResponseType.FacadeRemoteButtonState: {
      return ConditionSourceType.FacadeRemotes;
    }
    case DeviceResponseType.DoorBellState: {
      return ConditionSourceType.Bells;
    }
    case DeviceResponseType.MeasuredTemperature: {
      return ConditionSourceType.Temperature;
    }
    case DeviceResponseType.HumiditySensorState: {
      return ConditionSourceType.Humidity;
    }
    case DeviceResponseType.MeasuredBrightness: {
      return ConditionSourceType.Brightness;
    }
    case DeviceResponseType.PressureSensorState: {
      return ConditionSourceType.Pressure;
    }
    case DeviceResponseType.WindSpeedState: {
      return ConditionSourceType.WindSpeed;
    }
    case DeviceResponseType.CurrentWindThreshold: {
      return ConditionSourceType.WindThreshold;
    }
    case DeviceResponseType.MovementSensorState: {
      return ConditionSourceType.MovementSensor;
    }
    case DeviceResponseType.FloodSensorState: {
      return ConditionSourceType.FloodSensor;
    }
    case DeviceResponseType.MeasuredEnergy: {
      return ConditionSourceType.Energy;
    }
    case DeviceResponseType.ReedState: {
      return ConditionSourceType.ReedSensor;
    }
    case DeviceResponseType.GatePosition:
    case DeviceResponseType.GatewayPosition: {
      return ConditionSourceType.Gate;
    }
    default: {
      console.warn('Not supported yet', argumentState?.Type);
      return null;
    }
  }
};
