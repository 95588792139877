import React from 'react';

export const IconExalusTemperatureSensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.449219" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M29.889 31.876C30.4645 32.2512 30.9793 32.7119 31.4154 33.2399C32.38 34.4079 32.9595 35.9056 32.9595 37.5387C32.9595 41.2694 29.9351 44.2938 26.2043 44.2938C22.4736 44.2938 19.4492 41.2694 19.4492 37.5387C19.4492 35.1673 20.6712 33.0813 22.5197 31.876"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8516 31.4413V25.2942L22.8516 16.353C22.8516 14.5012 24.3527 13 26.2045 13V13C28.0563 13 29.5575 14.5012 29.5575 16.353V25.2942L29.5575 31.4413"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="26.2041"
      cy="37.5878"
      r="2.23531"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2051 34.7934L26.2051 16.5938"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.9785 27.5293H38.4491"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.9785 21.9404H36.7727"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.9785 16.3525H38.4491"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
