import React from 'react';

export const IconExalusFlushMountedGateAndGatewayController: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.449219" y="0.71875" width="56" height="56" rx="12" fill="white" />
    <path
      d="M15.0391 19.6353L28.8932 14.0264L28.8932 43.193L15.0391 43.193L15.0391 19.6353Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6582 17.6719V42.6318"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7461 19.6353L28.8919 14.0264L28.8919 43.193L42.7461 43.193L42.7461 19.6353Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.2402 16.9434L36.2402 43.1934"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0396 27.4883H12.9082"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M44.8795 27.4883H42.748" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.0396 35.3408H12.9082"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M44.8795 35.3408H42.748" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="26.8613" y="26" width="19.7188" height="19.7188" rx="2.07566" fill="white" />
    <path
      d="M30.528 43.6426L42.8849 43.6426C43.762 43.6426 44.4736 42.9326 44.4754 42.0555L44.5016 29.6691C44.5034 28.7894 43.7908 28.0752 42.9111 28.0752L36.6865 28.0752L30.528 28.0752C29.6496 28.0752 28.9375 28.7873 28.9375 29.6657L28.9375 42.0521C28.9375 42.9305 29.6496 43.6426 30.528 43.6426Z"
      fill="white"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect width="9.34046" height="0.778372" transform="matrix(0 1 -1 0 32.7949 31.1885)" fill="white" />
    <circle
      cx="32.3531"
      cy="31.6303"
      r="1.0918"
      transform="rotate(90 32.3531 31.6303)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="36.0922"
      r="1.0918"
      transform="rotate(90 32.3531 36.0922)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
    <circle
      cx="32.3531"
      cy="40.5541"
      r="1.0918"
      transform="rotate(90 32.3531 40.5541)"
      stroke="#15141D"
      strokeWidth="1.3"
    />
  </svg>
);
