import { DayOfWeekInternal } from '../../../data-access/gql-types/graphql';

export interface OptimizerScheduleBody {
  from: string;
  to: string;
  action: ScheduleAction;
  actionValue: number;
  selectedDays: DayOfWeekInternal[];
}

export interface OptimizerSchedule extends OptimizerScheduleBody {
  id: string;
}

export enum ScheduleAction {
  None = 'NONE',
  Teperature = 'TEMPERATURE',
}

export interface ActionValueParametersInterface {
  min: number | undefined;
  max: number | undefined;
  default: number | undefined;
  step: number | undefined;
  unit: string;
  icon: React.ReactNode | null;
}
