import React from 'react';
import { SubmitButton } from '../../../../components';

interface ComponentProps {
  handleSubmit: () => void;
}

export const VentilationChannelSettings: React.FC<ComponentProps> = ({ handleSubmit }) => (
  <>
    <SubmitButton onClick={handleSubmit} />
  </>
);
