import React from 'react';

export const IconHood: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-hood"
  >
    <path
      d="M4 25.2039C4 25.2039 7.80735 20.2357 18.9519 20.2188C30.0965 20.2357 33.9039 25.2039 33.9039 25.2039"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5986 3V19.6137"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3086 3V19.6137"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9224 23.5439V26.8674H8.98535V23.5439"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9522 29.3564C18.5359 29.7727 18.123 30.1856 18.123 31.0148C18.123 32.6765 19.7847 32.6765 19.7847 34.3382C19.7847 35.1673 19.3685 35.5836 18.9556 35.9999"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7691 29.3564C24.3528 29.7727 23.9399 30.1856 23.9399 31.0148C23.9399 32.6765 25.6017 32.6765 25.6017 34.3382C25.6017 35.1673 25.1854 35.5836 24.7725 35.9999"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9703 29.3564C13.554 29.7727 13.1411 30.1856 13.1411 31.0148C13.1411 32.6765 14.8028 32.6765 14.8028 34.3382C14.8028 35.1673 14.3865 35.5836 13.9736 35.9999"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7848 24.3731C19.7848 24.8334 19.4125 25.2023 18.9556 25.2023C18.4987 25.2023 18.1265 24.83 18.1265 24.3731C18.1265 23.9162 18.4987 23.5439 18.9556 23.5439C19.4125 23.5439 19.7848 23.9162 19.7848 24.3731Z"
      fill="#15141D"
    />
    <path
      d="M23.1075 24.3731C23.1075 24.8334 22.7352 25.2023 22.2784 25.2023C21.8215 25.2023 21.4492 24.83 21.4492 24.3731C21.4492 23.9162 21.8215 23.5439 22.2784 23.5439C22.7352 23.5439 23.1075 23.9162 23.1075 24.3731Z"
      fill="#15141D"
    />
    <path
      d="M14.7993 24.3731C14.7993 24.8334 15.1716 25.2023 15.6285 25.2023C16.0853 25.2023 16.4577 24.83 16.4577 24.3731C16.4577 23.9162 16.0853 23.5439 15.6285 23.5439C15.1716 23.5439 14.7993 23.9162 14.7993 24.3731Z"
      fill="#15141D"
    />
  </svg>
);
