import React, { createContext, useContext, useState } from 'react';
import { defaultState, useExalusRemoteStorage } from '../hooks/storage.hooks';

export type StateType = {
  isDashboardGridView: boolean;
  toggleGridView: () => void;
  activeGroupIndex: number;
  setActiveGroup: (activeGroupIndex: number) => void;
  activeChannelTabIndex: number;
  setActiveChannelTab: (activeChannelTabIndex: number) => void;
  activeDeviceTabIndex: number;
  setActiveDeviceTabIndex: (activeDeviceTabIndex: number) => void;
  isChannelsListView: boolean;
  toggleChannelsListView: () => void;
  readingChat: boolean;
  toggleReadingChat: () => void;
  getStorageData: () => void;
};

const ExalusStorageContext = createContext<StateType | undefined>(undefined);

export const useExalusStorageContext = (): StateType => useContext(ExalusStorageContext) as StateType;

export const ExalusStorageContextProvider: React.FC = ({ children }) => {
  const { saveExalusDataStorage, readExalusDataStorage } = useExalusRemoteStorage();
  const [isDashboardGridView, setIsDashboardGridView] = useState<boolean>(defaultState.isDashboardGridView);
  const [isChannelsListView, setIsChannelsListView] = useState<boolean>(defaultState.isChannelsListView);
  const [activeGroupIndex, setActiveGroupIndex] = useState<number>(defaultState.activeGroupIndex);
  const [activeChannelTabIndex, setActiveChannelTabIndex] = useState<number>(defaultState.activeChannelTabIndex);
  const [activeDeviceTabIndex, setActiveDeviceTabIndex] = useState<number>(0);
  const [readingChat, setReadingChat] = useState<boolean>(defaultState.readingChat);

  const toggleGridView = () => {
    setIsDashboardGridView(!isDashboardGridView);
    saveExalusDataStorage('isDashboardGridView', !isDashboardGridView);
  };

  const setActiveGroup = (activeGroupIndex: number) => {
    setActiveGroupIndex(activeGroupIndex);
    saveExalusDataStorage('activeGroupIndex', activeGroupIndex);
  };

  const setActiveChannelTab = (activeChannelTabIndex: number) => {
    setActiveChannelTabIndex(activeChannelTabIndex);
    saveExalusDataStorage('activeChannelTabIndex', activeChannelTabIndex);
  };

  const toggleChannelsListView = () => {
    setIsChannelsListView(!isChannelsListView);
    saveExalusDataStorage('isChannelsListView', !isChannelsListView);
  };

  const toggleReadingChat = () => {
    setReadingChat(!readingChat);
    saveExalusDataStorage('readingChat', !readingChat);
  };

  const getStorageData = async () => {
    const dashboardGridView = await readExalusDataStorage<boolean>('isDashboardGridView');
    if (dashboardGridView !== undefined) setIsDashboardGridView(dashboardGridView);

    const channelsListView = await readExalusDataStorage<boolean>('isChannelsListView');
    if (channelsListView !== undefined) setIsChannelsListView(channelsListView);

    const groupIndex = await readExalusDataStorage<number>('activeGroupIndex');
    if (groupIndex !== undefined) setActiveGroupIndex(groupIndex);

    const channelTabIndex = await readExalusDataStorage<number>('activeChannelTabIndex');
    if (channelTabIndex !== undefined) setActiveChannelTabIndex(channelTabIndex);

    const chat = await readExalusDataStorage<boolean>('readingChat');
    if (chat !== undefined) setReadingChat(chat);
  };

  const values = {
    isDashboardGridView,
    toggleGridView,
    activeGroupIndex,
    setActiveGroup,
    activeChannelTabIndex,
    setActiveChannelTab,
    activeDeviceTabIndex,
    setActiveDeviceTabIndex,
    isChannelsListView,
    toggleChannelsListView,
    readingChat,
    toggleReadingChat,
    getStorageData,
  };

  return <ExalusStorageContext.Provider value={{ ...values }}>{children}</ExalusStorageContext.Provider>;
};
