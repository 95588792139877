import React, { useMemo } from 'react';
import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { environment } from '../../../environment';
import { useInstallation } from '../../../hooks';
import { IntegrationDefaultEnum } from '../../../types';
import SupportContent from '../../support/content';

const Feedback: React.FC = () => {
  const { integrationType } = useInstallation();

  const type = useMemo(() => {
    if (integrationType === IntegrationType.Exalus) return IntegrationDefaultEnum.Exalus;
    return environment.INTEGRATION_DEFAULT;
  }, [integrationType]);

  return <SupportContent type={type} />;
};

export default Feedback;
