import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';

interface ComponentProps {
  channel: IDeviceChannel;
  isListItem?: boolean;
}

const CameraControls: React.FC<ComponentProps> = () => {
  return null;
};

export default CameraControls;
