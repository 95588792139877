import React from 'react';

export const IconArmchair: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-armchair"
  >
    <path
      d="M29.111 7.64689C29.3109 8.24662 29.3458 8.88905 29.2663 9.51595L28.9286 12.237C28.8276 13.0503 28.7054 13.8654 28.5656 14.6728C26.9663 15.5346 26.353 18.4323 26.353 18.4323C26.353 18.4323 25.3768 21.9103 25.2972 23.8842H12.9824C12.9028 21.9103 11.9266 18.4323 11.9266 18.4323C11.9266 18.4323 11.3132 15.5346 9.71397 14.6728C9.57617 13.8654 9.45195 13.0503 9.35103 12.237L9.01332 9.51595C8.93568 8.88905 8.96868 8.24662 9.16858 7.64689C9.48495 6.69975 10.2574 5.55075 12.1284 5.12764C15.5346 4.35712 19.1388 4.39011 19.1388 4.39011C19.1388 4.39011 22.743 4.35712 26.1492 5.12764C28.0202 5.55269 28.7927 6.69975 29.1091 7.64689H29.111Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.3791 14.6224C27.4135 13.4229 26.3557 18.4303 26.3557 18.4303C26.3557 18.4303 25.3756 21.9103 25.2979 23.8822H12.9831C12.9055 21.9103 11.9253 18.4303 11.9253 18.4303C11.9253 18.4303 10.8676 13.421 7.90193 14.6224C5.16725 15.7287 6.97419 20.4896 6.97419 20.4896C6.97419 20.4896 9.83698 29.3943 13.5266 29.3943H24.7564C28.446 29.3943 31.3088 20.4896 31.3088 20.4896C31.3088 20.4896 33.1138 15.7287 30.3811 14.6224H30.3791Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9981 21C25.7824 22.0111 25.584 23.1451 25.5534 24H13.4447C13.4141 23.1451 13.2137 22.0111 13 21H26H25.9981Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 9L14 10.499L12 12" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26 9L24 10.499L26 12" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.7852 10.3432H24.4949"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3791 33.6118L15.6853 29.3943H13.4844"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.8941 33.6118L22.5938 29.3943H24.7966"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
