import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { UserGroup, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useApi } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { availableGroupIcons } from '../../action/edit-icon/available-icons';
import { IconHeart } from '../../icons';
import { TabInterface, Tabs } from '../../tabs';

interface PropsInterface {
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  biggerTabs?: boolean;
}

export const GroupTabs: React.FC<PropsInterface> = ({ activeTabIndex, setActiveTabIndex, biggerTabs }) => {
  const history = useHistory();
  const { permissions } = usePermissionsContext();
  const { groups, dashboardLoading } = useApi();

  const tabs = useMemo(() => {
    if (dashboardLoading) return [];

    const items = groups?.map(
      (userGroup: UserGroup) =>
        ({
          label: userGroup.group.name,
          ...(userGroup.group.isDefaultGroup
            ? { icon: <IconHeart /> }
            : { icon: availableGroupIcons.find((x) => x.iconName === userGroup.group.iconName)?.component }),
        } as TabInterface),
    );

    return permissions[UserPermissionType.GroupCreate]
      ? [...items, { label: '+', onClick: () => history.push(ROUTES.GroupCreate()), addNew: true }]
      : [...items];
  }, [groups, dashboardLoading]);

  return (
    <Tabs
      tabList={tabs}
      activeTabIndex={activeTabIndex}
      setActiveTabIndex={setActiveTabIndex}
      tabsType="pills"
      isDivider={false}
      biggerTabs={biggerTabs}
      moreButton="secondary"
    />
  );
};
