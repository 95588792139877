import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { ChannelTypeInternal, MeterTypeInternal } from '../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { isDevelopment } from '../../../utils/helpers/environment';
import { MeterAnalysis } from './analysis';
import { MeterCurrent } from './current';
import { MeterMeasurements } from './measurements/meter';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsMeter: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { carouselDisabled } = useBackdropContext();

  const analizeTabShouldDisplay = useMemo(() => {
    if (isDevelopment) return true;

    const isStandalone =
      channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;

    return isStandalone;
  }, [channel]);

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={carouselDisabled}
      tabs={[
        { label: t('currently') },
        { label: t('measurement') },
        ...(analizeTabShouldDisplay ? [{ label: t('analysis') }] : []),
      ]}
      additionalTabs={
        analizeTabShouldDisplay
          ? [<MeterMeasurements key="2" channel={channel} />, <MeterAnalysis key="3" channel={channel} />]
          : [<MeterMeasurements key="2" channel={channel} />]
      }
    >
      <MeterCurrent channel={channel} />
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsMeter;
