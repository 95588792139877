import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceTaskType as ChannelTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconButton } from '@mui/material';
import {
  IconCallButton,
  IconChevron,
  IconDoorEntry,
  IconGateEntry,
  IconMicrophone,
  IconPause,
  IconPlay,
} from '../../../../../../../components';
import ArrowButton from '../../../../../../../components/arrow-button';
import LavvaDetailsWrapper from '../../../../../../../components/details-wrapper';
import { IconPip } from '../../../../../../../components/icons/icon-pip';
import { useExalusEntryPhoneStream } from '../../../../../hooks/entry-phone.hooks';
import { canChannelDo } from '../../../../../utils';
import { useHistoryState } from '../../../hooks/use-history-state';
import { useVideo } from '../../../hooks/use-video';
import SensorHistory from '../../../sensor-history';
import './index.scss';

interface ComponentProps {
  channel: IDeviceChannel;
}

export const EntryPhoneDetailsControls: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { tabs } = useHistoryState(channel);
  const { data } = useExalusEntryPhoneStream(channel);
  const { videoRef, pipSupported, handlePipClick, fullScreen, setFullScreen, toggleVideo, isPaused, buttonsVisible } =
    useVideo();

  useEffect(() => {
    if (data && videoRef.current && activeTabIndex === 0) {
      videoRef.current.srcObject = data;
    }
  }, [data, activeTabIndex]);

  const gate = useMemo(() => canChannelDo(channel, [ChannelTaskType.SetGatePosition]), [channel]);
  const gateway = useMemo(() => canChannelDo(channel, [ChannelTaskType.SetGatewayPosition]), [channel]);

  return (
    <LavvaDetailsWrapper
      tabs={tabs}
      additionalTabs={[<SensorHistory key={2} channel={channel} />]}
      overwriteTabIndex={activeTabIndex}
      setOverwriteActiveTabIndex={setActiveTabIndex}
    >
      <>
        <div className={classNames('entry-phone-details', { 'entry-phone-details--full-screen': fullScreen })}>
          {fullScreen && buttonsVisible && (
            <IconButton className="button-control full-screen" onClick={() => setFullScreen(false)}>
              <IconChevron />
            </IconButton>
          )}
          <video ref={videoRef} autoPlay playsInline muted style={{ width: '100vw' }} />
          {data && buttonsVisible && (
            <div className="video-controls">
              <IconButton className="button-control" onClick={() => alert('COMING SOON')}>
                <IconCallButton />
              </IconButton>
              {gateway && (
                <IconButton className="button-control" onClick={() => alert('COMING SOON')}>
                  <IconDoorEntry />
                </IconButton>
              )}
              {gate && (
                <IconButton className="button-control" onClick={() => alert('COMING SOON')}>
                  <IconGateEntry />
                </IconButton>
              )}
              <IconButton className="button-control" onClick={() => alert('COMING SOON')}>
                <IconMicrophone />
              </IconButton>
              <IconButton className="button-control" onClick={toggleVideo}>
                {!isPaused ? <IconPause /> : <IconPlay />}
              </IconButton>
            </div>
          )}
          {isPaused && (
            <div className="stopped-view">
              <span>{t('exalus.entryPhone.stopped')}</span>
            </div>
          )}
          {data && pipSupported && buttonsVisible && (
            <div className="pip-btn">
              <IconButton type="button" onClick={handlePipClick}>
                <IconPip />
              </IconButton>
            </div>
          )}
        </div>
        {data && (
          <div className="m-t-48 p-l-24 p-r-24">
            <ArrowButton title={t('exalus.entryPhone.fullScreen')} onClick={() => setFullScreen(true)} />
          </div>
        )}
      </>
    </LavvaDetailsWrapper>
  );
};
