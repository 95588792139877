import React from 'react';

export const IconMessage: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 21L7.72761 21.1819C7.78362 20.9578 7.73328 20.7205 7.59115 20.5384C7.44902 20.3564 7.23095 20.25 7 20.25V21ZM5.5 27L4.77239 26.8181C4.69322 27.1348 4.828 27.4664 5.1057 27.638C5.3834 27.8096 5.74023 27.7819 5.98809 27.5694L5.5 27ZM11.3765 21.963L11.8646 22.5324L11.3765 21.963ZM13.9797 21.75H25.5V20.25H13.9797V21.75ZM30.25 17V5H28.75V17H30.25ZM25.5 0.25H5V1.75H25.5V0.25ZM0.25 5V17H1.75V5H0.25ZM5 21.75H7V20.25H5V21.75ZM6.27239 20.8181L4.77239 26.8181L6.22761 27.1819L7.72761 21.1819L6.27239 20.8181ZM5.98809 27.5694L11.8646 22.5324L10.8884 21.3935L5.01191 26.4306L5.98809 27.5694ZM0.25 17C0.25 19.6234 2.37665 21.75 5 21.75V20.25C3.20507 20.25 1.75 18.7949 1.75 17H0.25ZM5 0.25C2.37665 0.25 0.25 2.37665 0.25 5H1.75C1.75 3.20507 3.20507 1.75 5 1.75V0.25ZM30.25 5C30.25 2.37665 28.1234 0.25 25.5 0.25V1.75C27.2949 1.75 28.75 3.20507 28.75 5H30.25ZM25.5 21.75C28.1234 21.75 30.25 19.6234 30.25 17H28.75C28.75 18.7949 27.2949 20.25 25.5 20.25V21.75ZM13.9797 20.25C12.8458 20.25 11.7493 20.6556 10.8884 21.3935L11.8646 22.5324C12.4537 22.0275 13.2039 21.75 13.9797 21.75V20.25Z"
        fill="#15141D"
      />
    </svg>
  );
};
