import React from 'react';

export const IconExalusRetransmitter: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.449219" y="0.579102" width="56" height="56" rx="12" fill="white" />
    <path
      d="M20.3117 42.016L37.2801 42.016C38.4526 42.016 39.4038 41.0668 39.4062 39.8944L39.4422 22.8855C39.4446 21.7095 38.492 20.7549 37.316 20.7549L28.7686 20.7549L20.3117 20.7549C19.1374 20.7549 18.1855 21.7068 18.1855 22.881L18.1855 39.8899C18.1855 41.0641 19.1374 42.016 20.3117 42.016Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="22.8645"
      cy="25.5974"
      r="1.50701"
      transform="rotate(90 22.8645 25.5974)"
      stroke="#15141D"
      strokeWidth="1.83285"
    />
    <circle
      cx="22.8645"
      cy="31.5623"
      r="1.50701"
      transform="rotate(90 22.8645 31.5623)"
      stroke="#15141D"
      strokeWidth="1.83285"
    />
    <circle
      cx="22.8645"
      cy="37.5271"
      r="1.50701"
      transform="rotate(90 22.8645 37.5271)"
      stroke="#15141D"
      strokeWidth="1.83285"
    />
    <g clipPath="url(#clip0_23191_242871)">
      <path
        d="M47.0445 18.5449C47.0099 17.0439 46.3885 15.5697 45.1964 14.4963C43.9783 13.3995 42.406 12.9391 40.8786 13.0904"
        stroke="#15141D"
        strokeLinecap="round"
      />
      <path
        d="M44.9472 18.9329C44.9235 17.906 44.4983 16.8973 43.6827 16.1629C42.8492 15.4124 41.7735 15.0974 40.7284 15.201"
        stroke="#15141D"
        strokeLinecap="round"
      />
      <path
        d="M42.98 19.8222C42.9636 19.1112 42.6692 18.4129 42.1046 17.9045C41.5275 17.3849 40.7828 17.1668 40.0593 17.2385"
        stroke="#15141D"
        strokeLinecap="round"
      />
      <path
        d="M18.0445 14.4717C18.0099 15.9727 17.3885 17.447 16.1964 18.5203C14.9783 19.6171 13.406 20.0775 11.8786 19.9262"
        stroke="#15141D"
        strokeLinecap="round"
      />
      <path
        d="M15.9472 14.0837C15.9235 15.1106 15.4983 16.1193 14.6827 16.8537C13.8492 17.6042 12.7735 17.9192 11.7284 17.8156"
        stroke="#15141D"
        strokeLinecap="round"
      />
      <path
        d="M13.98 13.1944C13.9636 13.9054 13.6692 14.6037 13.1046 15.1121C12.5275 15.6317 11.7828 15.8498 11.0593 15.7781"
        stroke="#15141D"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23191_242871">
        <rect width="38" height="38" fill="white" transform="translate(9.44922 9.5791)" />
      </clipPath>
    </defs>
  </svg>
);
