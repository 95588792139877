import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconFLoorLamp: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-switch icon-floor-lamp"
      width="22"
      height="33"
      viewBox="0 0 22 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.15565 2.10621C5.32564 1.63023 5.7765 1.3125 6.28192 1.3125H13.7178C14.2232 1.3125 14.6741 1.63023 14.8441 2.10621L18.5434 12.4643C18.8216 13.2432 18.2442 14.0625 17.4171 14.0625H2.5826C1.75556 14.0625 1.17816 13.2432 1.45632 12.4643L5.15565 2.10621Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      {isOn ? (
        <path
          d="M21.0796 6.88731C21.1503 6.41191 21.0425 5.82307 20.4626 4.81865C19.8827 3.81424 19.4611 3.48625 18.9796 3.25"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      ) : null}
      <path
        d="M10.0003 26.6875C6.85231 26.6875 4.30033 28.6587 4.30033 31.0902V31.4375L15.7003 31.3537V31.0902C15.7003 28.6587 13.1484 26.6875 10.0003 26.6875Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path d="M10 13.625V26.6875" stroke="#15141D" strokeWidth="1.5" />
    </svg>
  );
};
