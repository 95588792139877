import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlindWorkStats } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/VersionedOptions/^4.0.0.0/ISsrVersionedService';
import TextInfo from '../../../../../../components/text-info';

interface ComponentProps {
  params: BlindWorkStats | undefined;
}

const WorkStatistics: React.FC<ComponentProps> = ({ params }) => {
  const { t } = useTranslation('device-info');

  return (
    <div>
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.MakeVirginCount')}
        value={params?.MakeVirginCount || '-'}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.PowerOffCount')}
        value={params?.PowerOffCount || '-'}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.EnterCalibrationCount')}
        value={params?.EnterCalibrationCount || '-'}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.ObstacleUpCount')}
        value={params?.ObstacleUpCount || '-'}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.ObstacleDownCount')}
        value={params?.ObstacleDownCount || '-'}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.GoRideUpCount')}
        value={params?.GoRideUpCount || '-'}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.GoRideDownCount')}
        value={params?.GoRideDownCount || '-'}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.ManualAutoConfigFlag')}
        value={t(`exalus.params.SsrParams.Statistics.${params?.ManualAutoConfigFlag ? 'Manual' : 'Automatic'}`)}
        className="m-b-16"
        column
        big
      />
      <TextInfo
        label={t('exalus.params.SsrParams.Statistics.BadCrcFlag')}
        value={t(`exalus.params.SsrParams.${params?.BadCrcFlag ? 'On' : 'Off'}`)}
        className="m-b-16"
        column
        big
      />
    </div>
  );
};

export default WorkStatistics;
