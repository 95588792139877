import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDevicesAndChannels } from '../../../hooks';
import { ChannelBox } from '../box';

interface ChannelBoxSortableProps {
  channelId: string;
  isListItem?: boolean;
}

export const ChannelBoxSortable: React.FC<ChannelBoxSortableProps> = ({ channelId, isListItem = false }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id: channelId });
  const { channel } = useDevicesAndChannels({ channelId });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return channel ? (
    <ChannelBox
      isDraggable
      channel={channel}
      isListItem={isListItem}
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
    />
  ) : null;
};
