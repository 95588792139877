import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import {
  EnergyMeasurementParameter,
  MeasuredEnergyState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { InputSelect, SubmitButton } from '../../../../../../../../components';
import { ComparisonMethodSelect } from '../../../../components/comparison-method-select';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { InputString } from '../../../../components/input-string';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';
import { energyParams, getMeasurementParameters } from '../../../../utils/energy-parameters';
import { useActionParameterOptions } from '../../../../utils/use-action-parameter-options';

export const EnergyParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { energyOptions } = useActionParameterOptions();
  const { channels } = useExalusCreateActionContext();
  const [value, setValue] = useState<string>('0');
  const [energyOption, setEnergyOption] = useState<EnergyMeasurementParameter | null>(null);
  const {
    triggerMethod,
    seq,
    timeSec,
    conditionType,
    onlyParametersEdit,
    changeableAtMeetCondition,
    setConditionType,
    setTimeSec,
    setTriggerMethod,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  } = useConditionEdit({ supportedParam: SupportedArgumentTypes.ArgumentAsDeviceState });

  const paramsAvailable = useMemo(() => {
    if (channels.length) {
      const params = JSON.parse(channels[0].Configurations.EnergyMeasurmentParams);
      if (params.length) setEnergyOption(EnergyMeasurementParameter[params[0]]);
      return params;
    }
  }, [channels]);

  const options = useMemo(() => energyOptions.filter((x) => paramsAvailable.includes(x.value)), [paramsAvailable]);

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsDeviceState;

    if (argument && onlyParametersEdit) {
      const state = argument.GetCheckDeviceState<MeasuredEnergyState>();
      const params = getMeasurementParameters(state);

      setEnergyOption(EnergyMeasurementParameter[params.key]);
      if (params.value) setValue(params.value?.toString());
    }
  }, [seq, onlyParametersEdit]);

  const handleSubmit = () => {
    if (channels.length && energyOption) {
      const deviceArgument = createDeviceArgumentWithComparison();
      deviceArgument.Type = DeviceResponseType.MeasuredEnergy;
      deviceArgument.DeviceGuid = channels[0].GetDevice().Guid;
      const sensorState = new MeasuredEnergyState();
      sensorState.Channel = channels[0].Number;

      sensorState.MeasurementParameters.set(energyOption, parseInt(value));
      deviceArgument.SetCheckDeviceState<MeasuredEnergyState>(sensorState);

      saveConditionParams(deviceArgument);
    }
  };

  const unit = useMemo(() => {
    if (energyOption) return energyParams[energyOption].unit;
  }, [energyOption]);

  return (
    <>
      {energyOption ? (
        <InputSelect
          options={options}
          value={energyOption}
          onChange={setEnergyOption}
          label={t('action.create.conditions.sources.device.parameter')}
        />
      ) : null}
      {changeableAtMeetCondition ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      {triggerMethod === TriggerMethodType.WhenChangeOn && changeableAtMeetCondition && (
        <InputString
          value={timeSec}
          setValue={setTimeSec}
          label={t('action.create.conditions.sources.device.requiredTime')}
          inputType="number"
          min={0}
        />
      )}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={[
          ConditionsTypes.Equal,
          ConditionsTypes.NotEqueal,
          ConditionsTypes.BiggerThan,
          ConditionsTypes.BiggerThanOrEqual,
          ConditionsTypes.SmallerThan,
          ConditionsTypes.SmallerThanOrEqual,
        ]}
      />
      <ComparisonMethodSelect step="1" />
      {energyOption ? (
        <InputString
          value={value}
          setValue={setValue}
          label={`${t('action.create.conditions.sources.device.value')} ${unit ? `(${unit})` : ''}`}
          inputType="number"
          step="1"
          min={0}
        />
      ) : null}
      <SubmitButton disabled={!energyOption} onClick={handleSubmit} />
    </>
  );
};
