import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NavHeader, Page } from '../../../components';
import { IconAddDevice } from '../../../components/icons/status-screen-icon/add-device';
import { ROUTES } from '../../../routes';
import { useBluetoothContext } from '../context';
import './success.scss';

const DeviceUpdateBluetoothSuccess: React.FC = () => {
  const history = useHistory();
  const { disconnect, releaseLock, returnToStartPage } = useBluetoothContext();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');

  useEffect(() => {
    releaseLock();
    disconnect();
  }, []);

  const goToChannels = () => {
    history.push(ROUTES.Channel());
  };

  return (
    <Page
      className="bluetooth-success"
      containerClassName="page__container--flex-height"
      header={<NavHeader onClick={goToChannels} />}
    >
      <div>
        <div className="icon-container">
          <IconAddDevice />
        </div>
        <div className="text-container">
          <p className="text-header">{t('addDeviceUpdate.success.header')}</p>
          <p>{t('addDeviceUpdate.success.message')}</p>
        </div>
      </div>
      <div className="success-buttons">
        <button className="button" onClick={returnToStartPage}>
          {t('addDeviceUpdate.success.button')}
        </button>
        <button className="button button--secondary" onClick={goToChannels}>
          {tc('buttons.cancel')}
        </button>
      </div>
    </Page>
  );
};

export default DeviceUpdateBluetoothSuccess;
