import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DialogConfirmation } from '../../../../components';
import { useDevicesAndChannels, useInstallation } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { diffHours } from '../../../../utils/helpers';
import * as storage from '../../../../utils/storage/lavva';

export const UpdateDevicesDialog: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-list');
  const { t: tc } = useTranslation('common');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { selectedInstallationId } = useInstallation();
  const { deviceList } = useDevicesAndChannels();

  useEffect(() => {
    if (deviceList.length) {
      if (
        deviceList.find((x) => x.payload.isUpdateAvailable) &&
        deviceList[0].installationId === selectedInstallationId
      ) {
        const updateDevicesMemory = storage.getItem('updateDevicesMemory');

        if (updateDevicesMemory) {
          if (selectedInstallationId in updateDevicesMemory) {
            if (diffHours(new Date(updateDevicesMemory[selectedInstallationId]), 168)) setShowDialog(true);
          } else setShowDialog(true);
        } else setShowDialog(true);
      }
    }
  }, [deviceList, selectedInstallationId]);

  const handleClose = () => {
    if (selectedInstallationId) {
      let updateDevicesMemory = storage.getItem('updateDevicesMemory');

      if (updateDevicesMemory) {
        if (selectedInstallationId in updateDevicesMemory) {
          updateDevicesMemory[selectedInstallationId] = new Date();
        } else updateDevicesMemory = { ...updateDevicesMemory, [selectedInstallationId]: new Date() };
      } else updateDevicesMemory = { [selectedInstallationId]: new Date() };

      storage.setItem('updateDevicesMemory', updateDevicesMemory);
    }
    setShowDialog(false);
  };

  const handleAction = () => {
    handleClose();
    history.push(ROUTES.Device());
  };

  return (
    <DialogConfirmation
      show={showDialog}
      setShow={handleClose}
      header={t('updateDevicesInfo.header')}
      content={t('updateDevicesInfo.content')}
      primaryBtnText={tc('buttons.continue')}
      secondaryBtnText={tc('buttons.cancel')}
      primaryBtnAction={handleAction}
      secondaryBtnAction={handleClose}
    />
  );
};
