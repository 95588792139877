import React from 'react';

export const IconExalusUnknown: React.FC = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="12" fill="white" />
    <rect x="12.75" y="12.75" width="30.5" height="30.5" rx="2.25" stroke="#15141D" strokeWidth="1.5" />
    <circle cx="28" cy="34" r="1" fill="#15141D" />
    <path
      d="M24 24.0002C24 21.5 26 20 28 20C29.2871 20 30.16 20.4142 30.7521 20.9759C32.3702 22.5114 32.0079 25.4572 30.1586 26.7045C29.7007 27.0133 29.1324 27.2717 28.4284 27.4237C28.1852 27.4762 28 27.685 28 27.9338V31.0002"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
