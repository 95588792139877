import React from 'react';
import './index.scss';

export const IconBullet: React.FC = () => (
  <svg
    className="icon-bullet"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 16.1573L7 6.84269C7 6.04925 7.87974 5.57183 8.54499 6.00424L15.7101 10.6616C16.3168 11.0559 16.3168 11.9441 15.7101 12.3384L8.54499 16.9958C7.87974 17.4282 7 16.9508 7 16.1573Z"
      stroke="#FF4016"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
