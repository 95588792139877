import React from 'react';

export const IconExalusAirPressureSensor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.580078" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M28.5266 14.6094V26.7197M28.5266 26.7197L33.5102 21.7141M28.5266 26.7197L23.543 21.7141"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.1641 43.8145L46.2103 43.8145" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M42.6718 38.17L16.8096 38.17C14.2235 38.17 12.127 36.0735 12.127 33.4873V33.4873C12.127 30.9012 14.2235 28.8047 16.8096 28.8047H17.4321"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M21.4525 32.5255H40.2412C42.8273 32.5255 44.9238 30.429 44.9238 27.8428V27.8428C44.9238 25.2567 42.8273 23.1602 40.2412 23.1602H39.6187"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
