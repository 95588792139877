import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StatusScreen } from '../../components';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { useInstallation } from '../../hooks';
import { ROUTES } from '../../routes';
import { StatusTypeEnum } from '../../types';
import { useAddDevice } from '../device-add/hooks/use-add-device';
import { useExalusContext } from '../exalus/context';
import { getExalusInstallationCredentials } from '../exalus/utils';
import './index.scss';

const InstallationCreated: React.FC = () => {
  const { t } = useTranslation('installation');
  const { selectedInstallation, integrationType } = useInstallation();
  const { setSelectedExalusCredentials } = useExalusContext();
  const history = useHistory();
  const { addDevice } = useAddDevice();

  const goToDashboard = () => {
    if (integrationType === IntegrationType.Exalus) {
      const exalusCredentials = getExalusInstallationCredentials(selectedInstallation?.installationId);
      setSelectedExalusCredentials(exalusCredentials);
    }

    history.push(ROUTES.Root());
  };

  const handleAddDevice = () => {
    if (integrationType === IntegrationType.Exalus) history.push(ROUTES.AddExalusDevices());
    else addDevice();
  };

  return (
    <StatusScreen
      header={t('exalus.createdStatusScreen.header')}
      subTitle={t('exalus.createdStatusScreen.subheader')}
      type={StatusTypeEnum.INSTALLATION_CREATED}
      secondaryBackground
      primaryBtnText={t('exalus.createdStatusScreen.addDevices')}
      onPrimaryBtnClick={handleAddDevice}
      secondaryBtnText={t('exalus.createdStatusScreen.skipConfiguration')}
      onSecondaryBtnClick={goToDashboard}
    />
  );
};

export default InstallationCreated;
