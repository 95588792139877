import React from 'react';

export const IconCutlery: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-cutlery"
  >
    <path
      d="M15.1566 20.4199V32.7345C15.1566 33.8691 14.2387 34.787 13.1042 34.787C11.9697 34.787 11.0518 33.8691 11.0518 32.7345V20.4199"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1045 8.10352V20.4181"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2098 8.10352V18.3657C17.2098 19.5002 16.2919 20.4181 15.1573 20.4181H11.0524C9.9179 20.4181 9 19.5002 9 18.3657V8.10352"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.4716 20.4199V32.7345C27.4716 33.8691 26.5537 34.787 25.4191 34.787C24.2846 34.787 23.3667 33.8691 23.3667 32.7345V20.4199"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M25.4185 14.2617V20.419" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M25.4179 4C27.4703 6.05244 29.5227 10.8586 29.5227 14.2622V18.3671C29.5227 19.5016 28.6048 20.4195 27.4703 20.4195H23.3654C22.2309 20.4195 21.313 19.5016 21.313 18.3671V14.2622C21.313 10.8586 22.3392 7.07866 25.4179 4Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
