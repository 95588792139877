import React from 'react';

interface IconCoverProps {
  position?: number; // In percents from 0 to 100
}

export const IconCurtainBlind: React.FC<IconCoverProps> = ({ position = 0 }) => {
  return (
    <>
      {position < 50 && (
        <svg
          className="icon-cover icon-curtain-blind"
          width="41"
          height="13"
          viewBox="0 0 41 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40 4.5V5.25C40.3303 5.25 40.6216 5.03398 40.7176 4.71798C40.8136 4.40198 40.6916 4.06039 40.4171 3.87671L40 4.5ZM1.5 4.5L1.02065 3.92318C0.777651 4.12512 0.687267 4.45768 0.794622 4.75484C0.901978 5.05199 1.18405 5.25 1.5 5.25V4.5ZM35.3947 1.41791L34.9776 2.04121L35.3947 1.41791ZM34.9776 2.04121L39.5829 5.12329L40.4171 3.87671L35.8119 0.794621L34.9776 2.04121ZM1.97935 5.07682L5.5037 2.14801L4.54499 0.994367L1.02065 3.92318L1.97935 5.07682ZM6.60536 1.75H11.6374V0.25H6.60536V1.75ZM8 3.75H1.5V5.25H8V3.75ZM11.1173 0.459564L7.47997 3.95956L8.52003 5.04044L12.1574 1.54044L11.1173 0.459564ZM16 3.75H8V5.25H16V3.75ZM11.6374 1.75H17.2781V0.25H11.6374V1.75ZM16.7045 4.75727L17.9826 1.25727L16.5737 0.742729L15.2955 4.24273L16.7045 4.75727ZM17.2781 1.75H22.748V0.25H17.2781V1.75ZM24 3.75H16V5.25H24V3.75ZM22.0418 1.25261L23.2938 4.75261L24.7062 4.24739L23.4542 0.747391L22.0418 1.25261ZM40 3.75H32V5.25H40V3.75ZM32 3.75H24V5.25H32V3.75ZM22.748 1.75H28.5028V0.25H22.748V1.75ZM28.5028 1.75H34.0189V0.25H28.5028V1.75ZM32.5305 3.96988L29.0333 0.469879L27.9722 1.53012L31.4695 5.03012L32.5305 3.96988ZM35.8119 0.794621C35.2813 0.43955 34.6573 0.25 34.0189 0.25V1.75C34.3603 1.75 34.6939 1.85135 34.9776 2.04121L35.8119 0.794621ZM5.5037 2.14801C5.8132 1.8908 6.20294 1.75 6.60536 1.75V0.25C5.85274 0.25 5.12383 0.513337 4.54499 0.994367L5.5037 2.14801Z"
            fill="#15141D"
          />
          <path
            d="M1.51075 8.84628L1.51076 4.52881H4.88284H8.25491L8.2549 8.84629C8.2549 10.7187 6.72941 12.2326 4.85704 12.2183C3.00481 12.2041 1.51075 10.6986 1.51075 8.84628Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M9.75005 8.99759L9.75011 4.49121H16.2548L16.2548 8.99768C16.2548 10.7939 14.7986 12.25 13.0024 12.25C11.2062 12.25 9.75003 10.7938 9.75005 8.99759Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M17.7588 4.5166H24.2636L24.2635 8.99765C24.2635 10.7939 22.8074 12.25 21.0112 12.25C19.2149 12.25 17.7588 10.7939 17.7588 8.99762L17.7588 4.5166Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M25.7501 8.99759L25.7501 4.50391H32.2548L32.2548 8.99768C32.2548 10.7939 30.7986 12.25 29.0024 12.25C27.2062 12.25 25.75 10.7938 25.7501 8.99759Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M33.7695 4.479H39.784C39.8983 4.479 39.991 4.57168 39.991 4.68599V9.13927C39.991 10.8573 38.5983 12.25 36.8803 12.25C35.1623 12.25 33.7695 10.8573 33.7695 9.13927V4.479Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
        </svg>
      )}
      {position >= 50 && (
        <svg
          className="icon-cover icon-curtain-blind"
          width="41"
          height="25"
          viewBox="0 0 41 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.0002 15V15.75C40.2455 15.75 40.4753 15.6301 40.6155 15.4289C40.7558 15.2277 40.7888 14.9706 40.7039 14.7405L40.0002 15ZM1.5 15L0.788253 14.7635C0.712284 14.9922 0.750802 15.2434 0.891785 15.4388C1.03277 15.6342 1.25905 15.75 1.5 15.75V15ZM35.4342 2.61782L34.7305 2.87731L35.4342 2.61782ZM34.7305 2.87731L39.2966 15.2595L40.7039 14.7405L36.1378 2.35833L34.7305 2.87731ZM2.21175 15.2365L6.30032 2.93022L4.87683 2.45729L0.788253 14.7635L2.21175 15.2365ZM7.93609 1.75H12.001V0.25H7.93609V1.75ZM8.28871 14.25H1.5V15.75H8.28871V14.25ZM11.2761 0.807768L7.56376 14.8078L9.01365 15.1922L12.726 1.19223L11.2761 0.807768ZM16.3321 14.25H8.28871V15.75H16.3321V14.25ZM12.001 1.75H17.5696V0.25H12.001V1.75ZM17.0792 15.066L18.3166 1.06603L16.8225 0.933966L15.585 14.934L17.0792 15.066ZM17.5696 1.75H22.9693V0.25H17.5696V1.75ZM24.263 14.25H16.3321V15.75H24.263V14.25ZM22.2225 1.06901L23.5162 15.069L25.0098 14.931L23.7161 0.930989L22.2225 1.06901ZM40.0002 14.25H32.2502V15.75H40.0002V14.25ZM32.2502 14.25H24.263V15.75H32.2502V14.25ZM22.9693 1.75H28.6503V0.25H22.9693V1.75ZM28.6503 1.75H33.1132V0.25H28.6503V1.75ZM32.9765 14.8132L29.3767 0.813227L27.924 1.18677L31.5238 15.1868L32.9765 14.8132ZM36.1378 2.35833C35.6707 1.09148 34.4635 0.25 33.1132 0.25V1.75C33.8352 1.75 34.4807 2.19993 34.7305 2.87731L36.1378 2.35833ZM6.30032 2.93022C6.53444 2.22554 7.19354 1.75 7.93609 1.75V0.25C6.54734 0.25 5.31468 1.13938 4.87683 2.45729L6.30032 2.93022Z"
            fill="#15141D"
          />
          <path
            d="M1.51026 15.25H8.2544L8.25442 19.9093C8.25442 21.7817 6.72893 23.2956 4.85656 23.2813C3.00433 23.2671 1.51028 21.7616 1.51027 19.9093L1.51026 15.25Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M9.25 15.25H16.2547V19.7476C16.2547 21.6819 14.6867 23.25 12.7524 23.25C10.8181 23.25 9.25 21.6819 9.25 19.7476V15.25Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M17.755 20.0006L17.7507 15.25H24.2554L24.2597 19.9947C24.2613 21.7921 22.8047 23.25 21.0073 23.25C19.2123 23.25 17.7566 21.7957 17.755 20.0006Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M25.75 15.25H32.2547V19.9976C32.2547 21.7939 30.7986 23.25 29.0024 23.25C27.2061 23.25 25.75 21.7939 25.75 19.9976V15.25Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <path
            d="M33.769 15.25H39.9905V20.1393C39.9905 21.8573 38.5978 23.25 36.8798 23.25C35.1618 23.25 33.769 21.8573 33.769 20.1393V15.25Z"
            stroke="#15141D"
            strokeWidth="1.5"
          />
        </svg>
      )}
    </>
  );
};
