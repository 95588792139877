import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

export const LoadingState: React.FC = () => {
  const { t } = useTranslation('channel-details');

  return (
    <div className="loading-state">
      <p>{t('chartLoadingState.header')}</p>
    </div>
  );
};
