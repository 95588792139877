import React from 'react';
import '../index.scss';

export const IconAdd: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--add"
  >
    <path
      d="M17.1732 10.0866C17.1732 6.17279 14.0004 3 10.0866 3C6.17279 3 3 6.17279 3 10.0866C3 14.0004 6.17279 17.1732 10.0866 17.1732C14.0004 17.1732 17.1732 14.0004 17.1732 10.0866Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.6261 10.0864H7.54736" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.0867 12.6256V7.54688" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
