import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { Api } from 'lavva.exalushome';
import { NativeWebViewFunctions } from 'lavva.webview';
import { CommonService } from 'lavva.webview/build/js/Services/Common/CommonService';
import { ICommonService, NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { INotificationsService } from 'lavva.webview/build/js/Services/Notifications/INotificationsService';
import { NotificationsService } from 'lavva.webview/build/js/Services/Notifications/NotificationService';
import { IThemesService, Theme } from 'lavva.webview/build/js/Services/Themes/IThemesService';
import { ThemesService } from 'lavva.webview/build/js/Services/Themes/ThemesService';
import { environment } from '../../environment';
import { diffHours } from '../../utils/helpers';
import { isProd } from '../../utils/helpers/environment';
import * as storage from '../../utils/storage/lavva';

const initialState: useNativeFunctionsInterface = {
  getNativeContext: async () => undefined,
  getNotificationToken: () => null,
  setNativeTheme: () => null,
  nativeAppInstalledPopup: false,
  setNativeAppInstalledPopup: () => null,
  nativeInit: false,
};

export const NativeFunctionsContext = createContext<useNativeFunctionsInterface>(initialState);
export const useNativeFunctionsContext = (): useNativeFunctionsInterface => useContext(NativeFunctionsContext);

export interface useNativeFunctionsInterface {
  getNativeContext: () => Promise<NativeContext | undefined>;
  getNotificationToken: any;
  setNativeTheme: (theme: Theme) => void;
  nativeAppInstalledPopup: boolean;
  setNativeAppInstalledPopup: Dispatch<SetStateAction<boolean>>;
  nativeInit: boolean;
}

const NativeFunctionsProvider: React.FC = ({ children }) => {
  const [nativeAppInstalledPopup, setNativeAppInstalledPopup] = useState<boolean>(false);
  const [nativeInit, setNativeInit] = useState<boolean>(false);

  const checkIfNativeAppIsInstalled = async () => {
    if ('getInstalledRelatedApps' in window.navigator) {
      const relatedApps = await (window.navigator as any).getInstalledRelatedApps();
      const foundApp = relatedApps.find((app) => app.id === environment.NATIVE_APP_ID);

      if (foundApp) setNativeAppInstalledPopup(true);
    }
  };

  const getNativeContext = async () => {
    const nativeApi = Api.Get<NativeWebViewFunctions>(NativeWebViewFunctions.ServiceName);

    if (nativeApi) {
      try {
        const commonApi = await nativeApi.GetNativeFunctionServiceByServiceType<ICommonService>(
          CommonService.ServiceName,
        );

        return await commonApi.GetNativeContextAsync();
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }
  };

  const checkIfNativePopupShouldDisplay = async () => {
    const native = await getNativeContext();

    if (!isProd) {
      if (native === NativeContext.None) {
        const remind = storage.getItem('remindNative');
        if (!remind) checkIfNativeAppIsInstalled();
        else if (diffHours(new Date(remind), 72)) checkIfNativeAppIsInstalled();
      }
    }
  };

  const getNotificationToken = async () => {
    const nativeApi = Api.Get<NativeWebViewFunctions>(NativeWebViewFunctions.ServiceName);

    if (nativeApi) {
      try {
        const notificationsApi = await nativeApi.GetNativeFunctionServiceByServiceType<INotificationsService>(
          NotificationsService.ServiceName,
        );
        const token: string | null = await notificationsApi.GetDeviceFirebaseTokenAsync();
        return token;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const setNativeTheme = async (theme: Theme) => {
    const nativeApi = Api.Get<NativeWebViewFunctions>(NativeWebViewFunctions.ServiceName);

    if (nativeApi) {
      try {
        const themesApi = await nativeApi.GetNativeFunctionServiceByServiceType<IThemesService>(
          ThemesService.ServiceName,
        );
        await themesApi.SetThemeAsync(theme);

        console.log('Set theme success');
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    NativeWebViewFunctions.Init();
    setNativeInit(true);
    checkIfNativePopupShouldDisplay();
  }, []);

  const values = {
    getNativeContext,
    getNotificationToken,
    setNativeTheme,
    nativeAppInstalledPopup,
    setNativeAppInstalledPopup,
    nativeInit,
  };

  return <NativeFunctionsContext.Provider value={values}>{children}</NativeFunctionsContext.Provider>;
};

export default NativeFunctionsProvider;
