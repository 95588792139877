import React from 'react';

export const IconLeaf: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="33"
      height="28"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9995 1C21.9997 3.5 3.33734 12.2439 11.9996 24C17.8943 32 33.4991 21 30.9995 1Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1.5 26.5C6.66667 26.6667 18.7 23.7 25.5 10.5"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
