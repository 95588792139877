import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionConditionItemDetails from '../../../components/trigger/condition-item-details';
import { BlindStateDirectionInternal } from '../../../data-access/gql-types/graphql';
import { actionTaskVariants } from '../../action-create/utils/action-tasks';
import {
  BlindStateTask,
  DimStateTask,
  GateStateTask,
  OnOffStateTask,
  RGBWStateTask,
} from '../../exalus/pages/action-create/types';
import { useLavvaCreateActionContext } from '../context';
import { DeviceTaskParams, GateActionInternal, SceneTaskType, Task, TaskVariant } from '../types';

export const useActionDetailsPresentation = () => {
  const { t } = useTranslation('action');
  const { taskList } = useLavvaCreateActionContext();

  const getTaskState = (task: Task) => {
    switch (task.taskType) {
      case SceneTaskType.ChannelState: {
        const deviceParams = task.taskParams as DeviceTaskParams;

        switch (deviceParams.variant) {
          case TaskVariant.OnOff: {
            return t(`action.create.tasks.states.${(deviceParams.state as OnOffStateTask).isOn ? 'on' : 'off'}`);
          }
          case TaskVariant.Dim: {
            return `${t('action.create.tasks.states.brightness')} ${(deviceParams.state as DimStateTask).brightness}%`;
          }
          case TaskVariant.SetRGBW: {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{`${t('action.create.tasks.states.color')}: `}</span>
                <div
                  style={{
                    marginLeft: 6,
                    marginRight: 6,
                    height: 12,
                    width: 12,
                    borderRadius: '50%',
                    border: '1px solid #cbcbd1',
                    background: `rgb(${(deviceParams.state as RGBWStateTask).R},${
                      (deviceParams.state as RGBWStateTask).G
                    },${(deviceParams.state as RGBWStateTask).B})`,
                  }}
                />
              </div>
            );
          }
          case TaskVariant.SetBlind: {
            return (deviceParams.state as BlindStateTask<BlindStateDirectionInternal>).action ===
              BlindStateDirectionInternal.Unknown
              ? `${(deviceParams.state as BlindStateTask<BlindStateDirectionInternal>).position}%`
              : `${t(
                  `action.create.tasks.states.${(
                    deviceParams.state as BlindStateTask<BlindStateDirectionInternal>
                  ).action.toLowerCase()}`,
                )}`;
          }
          case TaskVariant.SetGate: {
            if ((deviceParams.state as GateStateTask).action === GateActionInternal.Tilt) {
              return t('action.create.tasks.states.ventilation');
            }

            return (deviceParams.state as GateStateTask).action === GateActionInternal.Unknown
              ? `${(deviceParams.state as GateStateTask).position}%`
              : `${t(`action.create.tasks.states.${(deviceParams.state as GateStateTask).action.toLowerCase()}`)}`;
          }
          case TaskVariant.SetVentilation: {
            return t('action.create.tasks.states.ventilation');
          }
          default: {
            return '';
          }
        }
      }
    }
  };

  const detailsTaskList = useMemo(
    () =>
      taskList.map((ts, i) => {
        const taskState = getTaskState(ts);

        switch (ts.taskType) {
          case SceneTaskType.ChannelState: {
            const taskVariant = actionTaskVariants.find(
              (x) => x.variant === (ts.taskParams as DeviceTaskParams).variant,
            );
            if (taskVariant) {
              return (
                <ActionConditionItemDetails
                  key={i}
                  icon={taskVariant.icon}
                  title={(ts.taskParams as DeviceTaskParams).channel.alias}
                  repeatOption={taskState}
                />
              );
            }

            return null;
          }
          default: {
            return null;
          }
        }
      }),
    [taskList],
  );

  return { detailsTaskList, getTaskState };
};
