import React, { useMemo } from 'react';
import c from 'classnames';
import {
  DeviceResponseType as ChannelResponseType,
  DeviceTaskType as ChannelTaskType,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindPostionState,
  BlindRemoteButtonState,
  BlindRemoteButtonStateEnum,
  ChannelOnOffState,
  FacadePositionState,
  FacadeRemoteButtonState,
  FacadeRemoteButtonStateEnum,
  FacadeType,
  FacadeTypeDeviceState,
  FloodSensorState,
  GatePositionState,
  GatewayPositionState,
  HumiditySensorState,
  IDeviceState as IChannelState,
  MeasuredBrightnessState,
  MeasuredTemperatureState,
  MovementSensorState,
  MovementStateEnum,
  OnlineCameraState,
  PressureSensorState,
  ReedState,
  RemoteButtonState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { IconChevron, IconMissing, IconPause } from '../../../../../components';
import { AvailableIcon } from '../../../../../types';
import { ChannelType, GateType, LightType, SensorType } from '../../../enums';
import {
  switchIcons,
  blindIcons,
  facadeIcons,
  cameraIcons,
  entryPhoneIcons,
  meterIcons,
  gateIcons,
  remoteIcons,
  dimmerIcons,
  rgbIcons,
  windIcons,
  movementIcons,
  floodIcons,
  humidityIcons,
  reedIcons,
  pressureIcons,
  measuredBrightnessIcons,
  temperatureIcons,
} from '../../../pages/channel-configuration/components/icon-edit/icons';
import { gatePreciseIcons } from '../../../pages/channel-configuration/components/icon-edit/icons/gate-precise';
import { canChannelDo, getPreciseChannelType } from '../../../utils';
import { useBrightnessControl } from '../hooks/use-brightness-control';
import { useGateControl } from '../hooks/use-gate-control';
import { useTemperatureControl } from '../hooks/use-temperature-control';
import { useWindControl } from '../hooks/use-wind-control';

interface ChannelBoxIconProps {
  channel: IDeviceChannel;
  className?: string;
}

export const ChannelBoxIcon: React.FC<ChannelBoxIconProps> = ({ channel, className }) => {
  const type = useMemo(() => getPreciseChannelType(channel), [channel]);
  const { windSpeed, windThreshold } = useWindControl(channel, type !== SensorType.Wind);
  const { temperature } = useTemperatureControl(channel, type !== SensorType.Wind);
  const { brightness } = useBrightnessControl(channel, type !== SensorType.MeasuredBrightness);
  const { getIconGatePosition } = useGateControl(channel);

  const state = useMemo(() => {
    return channel.States?.find(
      (state) =>
        state.TypeAsEnum === ChannelResponseType.BlindPosition ||
        state.TypeAsEnum === ChannelResponseType.FacadePosition ||
        state.TypeAsEnum === ChannelResponseType.ChannelOnOffState ||
        state.TypeAsEnum === ChannelResponseType.RemoteButtonState ||
        state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState ||
        state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState ||
        state.TypeAsEnum === ChannelResponseType.MeasuredBrightness ||
        state.TypeAsEnum === ChannelResponseType.GatePosition ||
        state.TypeAsEnum === ChannelResponseType.GatewayPosition ||
        state.TypeAsEnum === ChannelResponseType.MeasuredEnergy ||
        state.TypeAsEnum === ChannelResponseType.OnlineCamera ||
        state.TypeAsEnum === ChannelResponseType.PressureSensorState ||
        state.TypeAsEnum === ChannelResponseType.MeasuredTemperature ||
        state.TypeAsEnum === ChannelResponseType.MovementSensorState ||
        state.TypeAsEnum === ChannelResponseType.HumiditySensorState ||
        state.TypeAsEnum === ChannelResponseType.ReedState ||
        state.TypeAsEnum === ChannelResponseType.FloodSensorState,
    ) as
      | IChannelState<
          | ChannelOnOffState
          | BlindPostionState
          | FacadePositionState
          | RemoteButtonState
          | BlindRemoteButtonState
          | FacadeRemoteButtonState
          | GatePositionState
          | GatewayPositionState
          | MeasuredBrightnessState
          | PressureSensorState
          | FloodSensorState
          | MeasuredTemperatureState
          | MovementSensorState
          | HumiditySensorState
          | ReedState
        >
      | OnlineCameraState;
  }, [channel.States]);

  const hasWindState = useMemo(
    () => !!(windSpeed || windThreshold || temperature || brightness),
    [windSpeed, windThreshold, temperature, brightness],
  );

  const noState = useMemo(() => {
    if (type === ChannelType.Blind) return !canChannelDo(channel, [ChannelTaskType.SetBlindPositionSimple]) && !state;
    if (type === ChannelType.Facade)
      return (
        (state?.Data as FacadePositionState)?.Position === 255 ||
        (state?.Data as FacadePositionState)?.Position === 255 ||
        !state
      );
    if (type === SensorType.Temperature) return !state;
    if (type === SensorType.Wind) return !hasWindState;
    if (type === SensorType.Movement) return !state;
    if (type === SensorType.Flood) return !state;
    if (type === SensorType.Humidity) return !state;
    if (type === SensorType.MeasuredBrightness) return !state;
    if (type === SensorType.Reed) return !state;
    if (type === SensorType.Pressure) return !state;

    return !state;
  }, [channel, state?.Data, hasWindState]);

  const remoteState = (state: IChannelState<BlindRemoteButtonState | FacadeRemoteButtonState>) => {
    if (type === ChannelType.Remote) {
      return [
        BlindRemoteButtonStateEnum.OpenPressed,
        BlindRemoteButtonStateEnum.ClosePressed,
        BlindRemoteButtonStateEnum.StopPressed,
        FacadeRemoteButtonStateEnum.OpenPressed,
        FacadeRemoteButtonStateEnum.ClosePressed,
        FacadeRemoteButtonStateEnum.StopPressed,
      ].includes(state?.Data.State);
    }
  };

  const findIcon = (availableIcons: AvailableIcon[]): JSX.Element => {
    const icon = availableIcons.find((x) => x.iconName === channel.IconName || x.oldIconName === channel.IconName);
    if (icon) return icon.component;

    return availableIcons[0].component;
  };

  const getChannelIcon = () => {
    const preciseType = getPreciseChannelType(channel);

    switch (preciseType) {
      case ChannelType.Switch: {
        const onOffState = channel.States?.find(
          (state) => state.TypeAsEnum === ChannelResponseType.ChannelOnOffState,
        ) as IChannelState<ChannelOnOffState>;

        return findIcon(
          switchIcons(onOffState ? !(onOffState as IChannelState<ChannelOnOffState>)?.Data.State : false),
        );
      }
      case ChannelType.Blind: {
        const isBlindViewSimple = canChannelDo(channel, [ChannelTaskType.SetBlindPositionSimple]);

        return findIcon(
          blindIcons(isBlindViewSimple ? 100 : (state as IChannelState<BlindPostionState>)?.Data.Position ?? 33),
        );
      }
      case ChannelType.Facade: {
        const facadeType = channel.States?.find(
          (state) => state.TypeAsEnum === ChannelResponseType.FacadeType,
        ) as FacadeTypeDeviceState;

        return findIcon(
          facadeIcons({
            position: (state as IChannelState<FacadePositionState>)?.Data.Position ?? 33,
            tilt: (state as IChannelState<FacadePositionState>)?.Data.Tilt ?? 0,
            fullRange: facadeType?.Data.FType === FacadeType.Facade180,
          }),
        );
      }
      case ChannelType.Camera:
        return findIcon(cameraIcons());
      case ChannelType.EntryPhone:
        return findIcon(entryPhoneIcons(!!state));
      case ChannelType.Meter:
        return findIcon(meterIcons(!!state));
      case GateType.Gate:
        return findIcon(
          gateIcons(getIconGatePosition(state as IChannelState<GatePositionState | GatewayPositionState>)),
        );
      case GateType.GatePrecise:
        return findIcon(gatePreciseIcons((state as IChannelState<GatewayPositionState>)?.Data?.Position));
      case LightType.Dimmer:
        return findIcon(dimmerIcons(state ? !(state as IChannelState<ChannelOnOffState>)?.Data.State : false));
      case LightType.Rgb:
        return findIcon(rgbIcons(state ? !(state as IChannelState<ChannelOnOffState>)?.Data.State : false));
      case SensorType.Wind:
        return findIcon(windIcons(hasWindState));
      case SensorType.Humidity:
        return findIcon(humidityIcons(!state));
      case SensorType.MeasuredBrightness:
        return findIcon(measuredBrightnessIcons(!!state));
      case SensorType.Temperature:
        return findIcon(temperatureIcons(!!temperature));
      case SensorType.Movement:
        return findIcon(
          movementIcons((state as IChannelState<MovementSensorState>)?.Data.Movement === MovementStateEnum.Yes),
        );
      case SensorType.Flood:
        return findIcon(floodIcons(!state));
      case SensorType.Reed:
        return findIcon(reedIcons(!(state as IChannelState<ReedState>)?.Data.State ? 100 : 0));
      case SensorType.Pressure:
        return findIcon(pressureIcons(true));
      case ChannelType.Remote: {
        const remoteButtonState = channel.States?.find(
          (state) =>
            state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState ||
            state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState,
        ) as IChannelState<BlindRemoteButtonState | FacadeRemoteButtonState>;

        switch (remoteButtonState?.Data.State) {
          case BlindRemoteButtonStateEnum.OpenPressed:
          case FacadeRemoteButtonStateEnum.OpenPressed:
            return <IconChevron withCircle direction="up" size={80} />;
          case BlindRemoteButtonStateEnum.ClosePressed:
          case FacadeRemoteButtonStateEnum.ClosePressed:
            return <IconChevron withCircle direction="down" size={80} />;
          case BlindRemoteButtonStateEnum.StopPressed:
          case FacadeRemoteButtonStateEnum.StopPressed:
            return <IconPause size={80} />;
          default:
            return findIcon(
              remoteIcons(
                state && remoteState(state as IChannelState<BlindRemoteButtonState | FacadeRemoteButtonState>)
                  ? 100
                  : 6,
              ),
            );
        }
      }
      default:
        return <IconMissing />;
    }
  };

  return (
    <div
      className={c('device-box__head-icon', {
        'no-state': noState,
        [className as string]: className,
      })}
    >
      {getChannelIcon()}
    </div>
  );
};
