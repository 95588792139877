import React from 'react';

export const IconLeaf: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-leaf-simple"
  >
    <path
      d="M19.2578 35.0004V14.8711"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1289 17.9678L19.258 22.0969L23.3871 17.9678"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5164 18.4841C27.5164 23.0467 23.8208 26.7423 19.2582 26.7423C14.6955 26.7423 11 23.0467 11 18.4841C11 11.7743 19.2582 3 19.2582 3C19.2582 3 27.5164 11.7743 27.5164 18.4841Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
