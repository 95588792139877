import React from 'react';
import ActionCreateNameIconForm from '../../../action-create/components/name-icon-form';
import { useExalusCreateActionContext } from './context';
import { useActionCreate } from './hooks/use-action-create';

const ExalusActionCreate: React.FC = () => {
  const { onSubmitNameIcon } = useActionCreate();
  const { actionType, name, icon } = useExalusCreateActionContext();

  return <ActionCreateNameIconForm onSubmit={onSubmitNameIcon} name={name} icon={icon} actionType={actionType} />;
};

export default ExalusActionCreate;
