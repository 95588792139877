import React from 'react';

export const IconInstallation9: React.FC = () => (
  <svg
    className="icon-installation"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M13.4587 20.5835H3.16699L3.16699 34.8335L36.417 34.8335V20.5835L26.1253 20.5835" stroke="#15141D" />
    <path d="M19.792 1.5835L29.292 12.6668L10.292 12.6668L19.792 1.5835Z" stroke="#15141D" />
    <path d="M14.25 12.6665L14.25 33.2498" stroke="#15141D" />
    <path d="M25.333 12.6665L25.333 33.2498" stroke="#15141D" />
  </svg>
);
