import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { UserGroup, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { useShareRedirect } from '../../../modules/installation-share/hooks/use-share-redirect';
import { ROUTES } from '../../../routes';
import { IconSortHandle } from '../../icons';
import { PopUpNav } from '../../popup-nav';
import { IconEdit, IconShare } from '../../popup-nav/icons';
import './index.scss';

type Props = {
  id: string;
  groups: UserGroup[];
  isDraggable?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
};

export const GroupItem: React.FC<Props> = ({ groups, id, isDraggable, setNodeRef, style, attributes, listeners }) => {
  const history = useHistory();
  const { t } = useTranslation('groups');
  const { permissions } = usePermissionsContext();
  const userGroup = useMemo(() => groups?.find((group) => group.id === id), [groups, id]);
  const { shareGroup } = useShareRedirect();

  if (!userGroup) return null;

  const canShare = useMemo(
    () => permissions[UserPermissionType.GroupShare] && userGroup.group.isPublicGroup,
    [userGroup.group.isPublicGroup, permissions],
  );

  const linkToEditFrom = useMemo(
    () => (userGroup.group.isDefaultGroup ? ROUTES.FavouritesForm(userGroup?.id) : ROUTES.GroupEdit(userGroup?.id)),
    [userGroup],
  );

  const canEdit = useMemo(
    () =>
      (userGroup.group.isPublicGroup && permissions[UserPermissionType.PublicGroupEdit]) ||
      (!userGroup.group.isPublicGroup && permissions[UserPermissionType.GroupEdit]),
    [userGroup, permissions],
  );

  return (
    <div className="group-item" style={style} ref={setNodeRef}>
      {isDraggable ? (
        <>
          <div className="group-item__head">
            <span>{userGroup.group.name}</span>
          </div>
        </>
      ) : (
        <div className="group-item__head">
          <span className="group-item__title">{userGroup.group.name}</span>
        </div>
      )}
      <div className="group-item__body">
        {isDraggable ? (
          <div className="group-item__draggable">
            <div className="group-item__draggable-handle" {...attributes} {...listeners}>
              <IconSortHandle />
            </div>
          </div>
        ) : (
          <>
            {userGroup.group.isDefaultGroup ? (
              <PopUpNav
                className="popup-nav--reverse"
                links={[
                  {
                    label: t('editGroup'),
                    onClick: () => history.push(linkToEditFrom),
                    hasLineBelow: false,
                    icon: <IconEdit />,
                  },
                ]}
              />
            ) : (
              <PopUpNav
                className="popup-nav--reverse"
                links={[
                  {
                    label: canEdit ? t('editGroup') : '',
                    onClick: () => history.push(linkToEditFrom),
                    hasLineBelow: canShare,
                    icon: <IconEdit />,
                  },
                  ...(canShare
                    ? [
                        {
                          label: t('shareGroup'),
                          onClick: () => shareGroup(userGroup),
                          icon: <IconShare />,
                        },
                      ]
                    : []),
                ]}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
