import React from 'react';
import { RgbSceneFormContextProvider } from './context';
import { RgbSceneFormWrapper } from './steps';

const RgbSceneForm: React.FC = () => {
  return (
    <RgbSceneFormContextProvider>
      <RgbSceneFormWrapper />
    </RgbSceneFormContextProvider>
  );
};

export default RgbSceneForm;
