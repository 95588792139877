import React from 'react';
import './index.scss';

interface PropsInterface {
  big?: boolean;
}

export const IconEco2: React.FC<PropsInterface> = ({ big = false }) => {
  return (
    <svg
      className="icon-eco-2"
      width={big ? 73 : 57}
      height={big ? 99 : 58}
      viewBox="0 0 57 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.2932 41.9535C56.114 15.3685 35.0308 -0.811799 2.02003 4.53496C8.19497 16.2586 8.74487 29.9533 16.8991 43.3251C20.1602 48.6735 25.5314 52.5063 31.7416 53.3226C36.0206 53.8855 40.175 53.0593 44.2082 50.8802"
        stroke="#4CC8B2"
        strokeWidth="1.77631"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M56.5799 60.4851C47.908 44.5686 33.3156 28.8031 13.5057 13.1695"
        stroke="#4CC8B2"
        strokeWidth="1.77631"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.8691 23.2715L39.6283 37.1693L25.4009 39.592"
        stroke="#4CC8B2"
        strokeWidth="1.77631"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.3457 14.9209L25.4069 23.1813L17.3793 24.0454"
        stroke="#4CC8B2"
        strokeWidth="1.77631"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
