import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  onClick?: () => void;
  className?: string;
}

export const IconAdd: React.FC<PropsInterface> = ({ onClick, className }) => {
  return (
    <div
      className={classNames('icon-add', { clickable: onClick, [className as string]: className })}
      onClick={onClick}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
          fill="white"
        />
        <path d="M16 8.92892L16 16L8.92893 16" stroke="#15141D" vectorEffect="non-scaling-stroke" />
        <path d="M16 23.071V16H23.0711" stroke="#15141D" vectorEffect="non-scaling-stroke" />
      </svg>
    </div>
  );
};
