import React from 'react';
import './index.scss';

export const IconHistory: React.FC = () => (
  <svg
    className="icon-history"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11619_111539)">
      <path
        d="M4 10.417L5.39601 12.86C5.53232 13.0986 5.85814 13.1422 6.05241 12.9479L8.16667 10.8337"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-2-inside-1_11619_111539" fill="white">
        <path d="M8.14276 18.4181C9.48055 19.4595 11.1317 20.017 12.827 19.9996C14.5223 19.9822 16.1617 19.3909 17.4778 18.3222C18.7939 17.2535 19.7091 15.7703 20.0741 14.1147C20.4391 12.4591 20.2324 10.7286 19.4877 9.20557C18.743 7.6825 17.5042 6.45669 15.9734 5.72803C14.4426 4.99937 12.7101 4.81087 11.0584 5.19326C9.40668 5.57566 7.93327 6.50638 6.87844 7.83366C5.8236 9.16093 5.24959 10.8064 5.25 12.5018L6.5112 12.5015C6.51086 11.0912 6.98835 9.72243 7.8658 8.61835C8.74325 7.51427 9.96889 6.74005 11.3428 6.42196C12.7168 6.10387 14.158 6.26068 15.4314 6.8668C16.7048 7.47293 17.7352 8.49261 18.3547 9.75956C18.9742 11.0265 19.1461 12.466 18.8425 13.8432C18.5389 15.2204 17.7776 16.4541 16.6828 17.3431C15.588 18.2321 14.2243 18.724 12.8141 18.7385C11.4039 18.753 10.0303 18.2892 8.91751 17.4229L8.14276 18.4181Z" />
      </mask>
      <path
        d="M8.14276 18.4181C9.48055 19.4595 11.1317 20.017 12.827 19.9996C14.5223 19.9822 16.1617 19.3909 17.4778 18.3222C18.7939 17.2535 19.7091 15.7703 20.0741 14.1147C20.4391 12.4591 20.2324 10.7286 19.4877 9.20557C18.743 7.6825 17.5042 6.45669 15.9734 5.72803C14.4426 4.99937 12.7101 4.81087 11.0584 5.19326C9.40668 5.57566 7.93327 6.50638 6.87844 7.83366C5.8236 9.16093 5.24959 10.8064 5.25 12.5018L6.5112 12.5015C6.51086 11.0912 6.98835 9.72243 7.8658 8.61835C8.74325 7.51427 9.96889 6.74005 11.3428 6.42196C12.7168 6.10387 14.158 6.26068 15.4314 6.8668C16.7048 7.47293 17.7352 8.49261 18.3547 9.75956C18.9742 11.0265 19.1461 12.466 18.8425 13.8432C18.5389 15.2204 17.7776 16.4541 16.6828 17.3431C15.588 18.2321 14.2243 18.724 12.8141 18.7385C11.4039 18.753 10.0303 18.2892 8.91751 17.4229L8.14276 18.4181Z"
        stroke="#FF4016"
        strokeWidth="3"
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_11619_111539)"
      />
      <path d="M12.334 9.16699V12.917L14.501 14.5" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_11619_111539">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
