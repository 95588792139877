import React from 'react';
import './index.scss';

interface ComponentProps {
  big?: boolean;
}

export const IconCirclePadlockOpen: React.FC<ComponentProps> = ({ big }) => (
  <>
    {!big ? (
      <svg
        className="icon-circle-padlock-open"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.75" y="0.75" width="30.5" height="30.5" rx="15.25" stroke="#15141D" strokeWidth="1.5" />
        <g clipPath="url(#clip0_26712_34782)">
          <rect
            x="10"
            y="13"
            width="11.3333"
            height="8.66667"
            rx="4"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.3333 17.3335L13.9999 17.3335"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 13L18.7696 10.5421C18.4937 7.59904 14.3693 7.17476 13.5 10V10"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_26712_34782">
            <rect width="16" height="16" fill="white" transform="translate(8 7)" />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-circle-padlock-open"
      >
        <rect x="1.5" y="1.5" width="38" height="38" rx="19" stroke="#15141D" strokeWidth="2" />
        <g clipPath="url(#clip0_26734_164041)">
          <rect
            x="12.625"
            y="16.875"
            width="14.875"
            height="11.375"
            rx="4"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.25 22.5625L17.875 22.5625"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.4375 16.875L24.1351 13.649C23.7729 9.78624 18.3597 9.22937 17.2188 12.9375V12.9375"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_26734_164041">
            <rect width="21" height="21" fill="white" transform="translate(10 9)" />
          </clipPath>
        </defs>
      </svg>
    )}
  </>
);
