import React from 'react';

export const IconSynchronize: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-synchronize"
  >
    <path
      d="M19.3636 3.00034V7.09125"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.2731 7.09252H19.364" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21.0001 12.0003C21.0001 16.9749 16.9746 21.0003 12.0001 21.0003C7.02555 21.0003 3.00009 16.9749 3.00009 12.0003C3.00009 7.02579 7.02555 3.00034 12.0001 3.00034C15.1092 3.00034 17.7437 4.69397 19.3637 7.09124"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
