import { gql } from '@apollo/client';

export const DASHBOARD = gql`
  query DashboardItems($id: UUID!) {
    allUserGroups(installationId: $id) {
      installationId
      id
      order
      group {
        name
        id
        iconName
        isDefaultGroup
        isPublicGroup
        groupItems {
          id
          resourceType
          payload {
            __typename
            ... on UserChannel {
              id
              grantedUses
              usesCounter
            }
            ... on UserAction {
              id
              favouritePositions {
                ... on PositionFavourites {
                  value
                }
                ... on LightsFavourites {
                  brightness
                  color {
                    red
                    green
                    blue
                  }
                }
              }
              iconName
              action {
                installationId
                actionType
                channelType
                name
                id
                sourceGroupId
                channelActions {
                  channelId
                  channelType
                  activityType
                  value
                }
              }
            }
          }
        }
      }
      userGroupItems {
        itemId
        order
      }
    }
  }
`;
