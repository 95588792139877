import {
  LightTransitionTime,
  MaxLightLevel,
  MinLightLevel,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { FacadeDetection } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { TypeOfGateChannel } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigParams';
import { LedControllersLightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/SlrConfigParameters';
import {
  BrightnessThresholdStep,
  WindThresholdStep,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigParams';

export interface DeviceConfigurationDetails {
  name: string;
}

export interface BrightnessThresholdParams {
  BrightnessThreshold: BrightnessThresholdStep;
}

export interface WindThresholdParams {
  WindThreshold: WindThresholdStep;
}

export interface FacadeDetectionParams {
  FacadeDetection: FacadeDetection;
}

export interface DimmingBrightnessTimeParams {
  dimmingTime: LedControllersLightTransitionTime;
}

export interface DimmingTimeParams {
  dimmingTime: LightTransitionTime;
}

export interface TurnOnOffActivationParams {
  Activation: number;
}

export interface BrighteningTimeParams {
  brighteningTime: LightTransitionTime;
}

export interface MinLevelParams {
  minLevel: MinLightLevel;
}

export interface MaxLevelParams {
  maxLevel: MaxLightLevel;
}

export interface GateModeParams {
  mode: TypeOfGateChannel;
}

export interface ReactionWindParams {
  active: boolean;
  lockTime: number;
  blindLocked: boolean;
}

export interface GlobalTimeParams {
  time: string;
}

export interface GateTimeParams {
  time: string;
}

export enum LedTimeType {
  LedTime = 'LED_TIME',
  FloatingTime = 'FLOATING_TIME',
}
