import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../components';
import { useLocationContext } from '../location/context/location.context';

const messages = {
  NEED_LOCATION: {
    content: 'locationPopupMore',
  },
  REMOVE_LOCATION: {
    content: 'locationPopupMore',
  },
};

interface ComponentProps {
  type: 'NEED_LOCATION' | 'REMOVE_LOCATION';
}

const LocationLearn: React.FC<ComponentProps> = ({ type }) => {
  const { t } = useTranslation('installation');
  const { goBack } = useLocationContext();

  return (
    <Page
      kind="above"
      header={
        <>
          <NavHeader onClick={goBack} />
          <Header title={t('learnMore')} isUnderline />
        </>
      }
    >
      <div className="location_learn">
        <p>{t(messages[type].content)}</p>
      </div>
    </Page>
  );
};

export default LocationLearn;
