import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReedStateHistory, StateData } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { ControlWrapper, Header, NavHeader, Page } from '../../../../../../../components';
import ArrowButton from '../../../../../../../components/arrow-button';
import { useDetailsList } from '../../hooks/use-details-list';
import ListSection from '../list-section';
import './index.scss';

interface ComponentProps {
  data?: StateData<ReedStateHistory>[];
}

const DetailsList: React.FC<ComponentProps> = ({ data }) => {
  const { t } = useTranslation('channel-details');
  const [open, setOpen] = useState<boolean>(false);
  const { sections } = useDetailsList(data);

  const handleBack = () => setOpen(false);

  const listComponent = useMemo(() => sections.map((x) => <ListSection key={x.date} section={x} />), [sections]);

  return (
    <>
      {open ? (
        <Page
          kind="above"
          contentClassName="page__content--flex"
          header={
            <>
              <NavHeader onClick={handleBack} />
              <Header title={t('exalus.chartDetailsList')} />
            </>
          }
        >
          <ControlWrapper className="control-wrapper--full-space no-center">
            <div className="list-details">{listComponent}</div>
          </ControlWrapper>
        </Page>
      ) : (
        <ArrowButton title={t('exalus.chartDetailsList')} onClick={() => setOpen(true)} className="m-t-48" />
      )}
    </>
  );
};

export default DetailsList;
