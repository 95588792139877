import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  isOn?: boolean;
  className?: string;
}

export const IconStatus: React.FC<PropsInterface> = ({ isOn = false, className }) => {
  return (
    <svg
      className={classNames('icon-status', { 'icon-status--is-on': isOn, [className as string]: className })}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {isOn ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16c4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8zm3.3536-9.64645c.1952-.19526.1952-.51184 0-.7071-.1953-.19527-.5119-.19527-.7072 0L6.93629 9.3566 5.27735 8.25063c-.22976-.15317-.5402-.09109-.69337.13868-.15318.22976-.0911.54019.13867.69337l2 1.33332c.19831.1322.46237.1061.6309-.0624l4.00005-4.00005z"
            fill="#15141D"
          />
        </>
      ) : (
        <>
          <circle cx="8" cy="8" r="8" fill="#FF4016" />
          <path d="M5.48228 5.51758L10.5178 10.5531" stroke="#15141D" vectorEffect="non-scaling-stroke" />
          <path d="M10.5176 5.51764L5.48205 10.5532" stroke="#15141D" vectorEffect="non-scaling-stroke" />
        </>
      )}
    </svg>
  );
};
