import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import * as duration from 'duration-fns';
import { IconHistory } from '../../../../components';
import { Trigger } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';
import { leadingZero } from '../../../../utils/helpers';

interface PropsInterface {
  trigger: Trigger;
}

export const TriggerLastExecution: React.FC<PropsInterface> = ({ trigger }) => {
  const { t } = useTranslation('action');
  const { selectedInstallation } = useInstallation();

  const lastExecution = useMemo(() => {
    const time = trigger?.lastExecutionInfo?.executedAt?.time;
    const success = trigger?.lastExecutionInfo?.isSuccessful;
    if (time) {
      const { hours, minutes } = duration.parse(time);
      const parsedTime = `${leadingZero(hours)}:${leadingZero(minutes)}`;
      const fireTime = formatInTimeZone(
        `${trigger?.lastExecutionInfo?.executedAt?.date}T${parsedTime}Z`,
        selectedInstallation?.payload?.timeZone || 'Europe/Warsaw',
        'dd.MM.yyyy, HH:mm',
      );
      return {
        fireTime,
        success,
      };
    }
    return null;
  }, [trigger?.lastExecutionInfo]);

  return (
    <div
      className={c('trigger-details__execution--last', {
        'trigger-details__execution--last-failed': lastExecution && !lastExecution.success,
      })}
    >
      <IconHistory />
      <p>
        {t('trigger.lastExecution')}: {lastExecution?.fireTime ?? t('trigger.nextFireTimeNull')}
      </p>
    </div>
  );
};
