import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface ComponentProps {
  className?: string;
}

export const IconLogoWisniowskiSmall: React.FC<ComponentProps> = ({ className = '' }) => (
  <svg
    width="173"
    height="20"
    viewBox="0 0 173 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('icon-logo-wisniowski-small', className)}
  >
    <path
      d="M50.1025 4.2821H53.2595L56.0308 13.0299L59.0335 4.24707H61.5554L64.5581 13.0299L67.3295 4.2821H70.4093L65.8418 17.29H63.279L60.2582 8.83989L57.2374 17.29H54.6746L50.1071 4.2821H50.1025Z"
      fill="#3C3C3B"
    />
    <path d="M71.7792 4.2821H74.7229V17.198H71.7792V4.2821Z" fill="#3C3C3B" />
    <path
      d="M76.6914 15.3154L78.4332 13.3058C79.6351 14.2646 80.9006 14.8732 82.4292 14.8732C83.6357 14.8732 84.3614 14.4135 84.3614 13.656V13.621C84.3614 12.903 83.9033 12.5308 81.6672 11.9792C78.9729 11.3137 77.2312 10.5956 77.2312 8.02995V7.99492C77.2312 5.65254 79.1816 4.10263 81.9167 4.10263C83.867 4.10263 85.5317 4.6937 86.8879 5.74449L85.3593 7.88547C84.1755 7.093 83.0052 6.61139 81.8804 6.61139C80.7555 6.61139 80.1613 7.11051 80.1613 7.7366V7.77163C80.1613 8.62102 80.7328 8.89685 83.0461 9.4704C85.763 10.1534 87.2916 11.0947 87.2916 13.3452V13.3802C87.2916 15.9459 85.2641 17.3863 82.3793 17.3863C80.3518 17.3863 78.3062 16.7033 76.7005 15.3198L76.6914 15.3154ZM83.3273 0.184066L85.831 1.23485L83.232 3.07811H81.014L83.3273 0.179688V0.184066Z"
      fill="#3C3C3B"
    />
    <path
      d="M89.505 4.2821H92.222L98.495 12.2374V4.2821H101.398V17.198H98.8941L92.4125 8.98438V17.198H89.505V4.2821Z"
      fill="#3C3C3B"
    />
    <path d="M104.392 4.2821H107.335V17.198H104.392V4.2821Z" fill="#3C3C3B" />
    <path
      d="M109.354 10.7795V10.7445C109.354 7.07111 112.356 4.06323 116.484 4.06323C120.612 4.06323 123.578 7.03609 123.578 10.7051V10.7401C123.578 14.4135 120.575 17.4214 116.448 17.4214C112.32 17.4214 109.354 14.4485 109.354 10.7795ZM120.498 10.7795V10.7445C120.498 8.52908 118.815 6.68582 116.443 6.68582C114.071 6.68582 112.429 8.49406 112.429 10.7095V10.7445C112.429 12.9599 114.112 14.8032 116.484 14.8032C118.856 14.8032 120.498 12.9949 120.498 10.7795Z"
      fill="#3C3C3B"
    />
    <path
      d="M123.746 4.2821H126.903L129.674 13.0299L132.677 4.24707H135.199L138.201 13.0299L140.973 4.2821H144.053L139.485 17.29H136.922L133.902 8.83989L130.881 17.29H128.318L123.75 4.2821H123.746Z"
      fill="#3C3C3B"
    />
    <path
      d="M144.143 15.3154L145.885 13.3058C147.087 14.2647 148.353 14.8732 149.881 14.8732C151.083 14.8732 151.813 14.4135 151.813 13.6561V13.6211C151.813 12.903 151.355 12.5309 149.119 11.9792C146.425 11.3137 144.683 10.5957 144.683 8.02998V7.99496C144.683 5.65257 146.633 4.10266 149.369 4.10266C151.319 4.10266 152.984 4.69373 154.34 5.74452L152.811 7.8855C151.627 7.09303 150.457 6.61142 149.332 6.61142C148.207 6.61142 147.613 7.11054 147.613 7.73664V7.77166C147.613 8.62105 148.185 8.89688 150.502 9.47044C153.215 10.1534 154.748 11.0948 154.748 13.3452V13.3802C154.748 15.9459 152.721 17.3864 149.836 17.3864C147.808 17.3864 145.763 16.7034 144.157 15.3198L144.143 15.3154Z"
      fill="#3C3C3B"
    />
    <path
      d="M156.689 4.2821H159.633V9.93009L165.062 4.2821H168.618L163.171 9.76372L168.868 17.2024H165.33L161.18 11.7033L159.633 13.2532V17.2024H156.689V4.2821Z"
      fill="#3C3C3B"
    />
    <path d="M170.056 4.2821H173V17.198H170.056V4.2821Z" fill="#3C3C3B" />
    <path
      d="M24.9333 14.7898L19.318 9.36953L13.7027 14.7898L11.7749 11.9658L19.318 4.68476L26.861 11.9658L24.9333 14.7898ZM24.9061 0.718038L27.7863 3.49825H32.6396L28.7842 9.14186L19.3225 0L9.8563 9.14623L5.99633 3.50263H10.8542L13.7344 0.722417H0L13.1629 20.0044L19.318 14.0587L25.4731 20.0044L38.6405 0.718038H24.9061Z"
      fill="#3C3C3B"
    />
  </svg>
);
