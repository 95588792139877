import React from 'react';

export const IconMirror: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-mirror"
  >
    <rect x="8.75" y="3.75" width="21.5" height="30.5" rx="5.8" stroke="#15141D" strokeWidth="1.5" />
    <path d="M13 13L18 8" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M13 18.5L23.5 8" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
