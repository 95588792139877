import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import ArrowButton from '../../../../../../components/arrow-button';
import ActionTasksTypes from '../../../../../action-create/components/task-types';
import { useExalusCreateActionContext } from '../../context';
import { useActionTasks } from '../../hooks/use-action-tasks';
import { actionTasks } from '../../utils/action-tasks';

const ExalusActionTasksTypes: React.FC = () => {
  const { t } = useTranslation('action');
  const { selectTaskType } = useActionTasks();
  const { taskList } = useExalusCreateActionContext();

  const list = useMemo(() => {
    const index = taskList.findIndex((x) => x.taskType === SceneTaskType.LockExecution);
    if (index !== -1) return [...actionTasks].filter((x) => x.type !== SceneTaskType.LockExecution);
    return [...actionTasks];
  }, [taskList]);

  return (
    <ActionTasksTypes>
      {list.map((x) => (
        <ArrowButton
          key={x.title}
          title={t(x.title)}
          icon={x.icon}
          className="m-b-24"
          iconAccent
          onClick={() => selectTaskType(x.type)}
        />
      ))}
    </ActionTasksTypes>
  );
};

export default ExalusActionTasksTypes;
