import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Header, IconSortHandle, NavHeader } from '../../../components';
import { useInstallationListHeader } from '../hooks/use-installation-list-header';

interface PropsInterface {
  toggleSort: () => void;
  sortMode: boolean;
}

export const InstallationListHeader: React.FC<PropsInterface> = ({ sortMode, toggleSort }) => {
  const { t } = useTranslation('installation');
  const { sortButton, goToDashboard } = useInstallationListHeader();

  return (
    <>
      <NavHeader isIconClose onClick={goToDashboard}>
        {sortButton && (
          <button className="installation-list__btn-handle" onClick={toggleSort}>
            <IconSortHandle className={classNames({ 'icon-sort-handle--active': sortMode })} />
          </button>
        )}
      </NavHeader>
      <Header title={t('installationList')} />
    </>
  );
};
