import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconBorderFan: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-border-fan"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.916016"
        y="0.916687"
        width="28.5833"
        height="28.5833"
        rx="2.25"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.70898 7.29169L15.209 13.625L24.709 7.29169"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M15.209 13.625L15.209 24.7083" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      {isOn ? (
        <path
          d="M22.0442 26.0392C23.0733 25.7968 23.9977 25.1544 24.5617 24.1774C25.1381 23.1791 25.2239 22.0296 24.8947 21"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      ) : null}
    </svg>
  );
};
