import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNative } from '../../../hooks';
import { useErrors } from '../../../hooks/use-errors';
import { useRestClient } from '../../clients/rest-client';
import { DeviceResponse } from '../device/device.types';
import { SwitchReturnBehaviourBody, SwitchSetBody, SwitchSetOnTimeBody } from './switch.types';

export const useSwitchSetOn = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, SwitchSetBody> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, SwitchSetBody>(
    ['switch-setOn'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/switch/setOn', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useSwitchSetOff = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, SwitchSetBody> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, SwitchSetBody>(
    ['switch-setOff'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/switch/setOff', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useSwitchSetOnTime = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  SwitchSetOnTimeBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, SwitchSetOnTimeBody>(
    ['switch-setOnTime'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/switch/setOnTime', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve({ status: data.publishStatus, deviceId: body.deviceId });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useSwitchSupplyReturnBehaviour = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  SwitchReturnBehaviourBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, SwitchReturnBehaviourBody>(
    ['switch-supplyReturnBehavior'],
    async (body) => await restClient.post<DeviceResponse>('/switch/supplyReturnBehavior', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
