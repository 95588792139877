import React from 'react';

export const IconExalusDinModuleWithAntenna: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.580078" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M17.5801 32.8594V21.6613C17.5801 21.5866 17.6407 21.526 17.7154 21.526H23.0205C23.0953 21.526 23.1558 21.4655 23.1558 21.3907V17.828C23.1558 17.7533 23.2164 17.6927 23.2911 17.6927H29.6418C29.7165 17.6927 29.777 17.6321 29.777 17.5574V13.9947C29.777 13.9199 29.8376 13.8594 29.9123 13.8594H40.4448C40.5195 13.8594 40.5801 13.9199 40.5801 13.9947V22.4694C40.5801 22.5298 40.54 22.5829 40.482 22.5995L38.2388 23.2404C38.1807 23.257 38.1407 23.3101 38.1407 23.3705V35.0277C38.1407 35.0819 38.1729 35.1308 38.2227 35.1521L40.4981 36.1273C40.5478 36.1486 40.5801 36.1975 40.5801 36.2516V44.3907C40.5801 44.4655 40.5195 44.526 40.4448 44.526H29.9123C29.8376 44.526 29.777 44.4655 29.777 44.3907V40.828C29.777 40.7533 29.7165 40.6927 29.6418 40.6927H23.2911C23.2164 40.6927 23.1558 40.6321 23.1558 40.5574V36.9947C23.1558 36.9199 23.0953 36.8594 23.0205 36.8594H17.7154C17.6407 36.8594 17.5801 36.7988 17.5801 36.7241V32.8594ZM17.5801 32.8594H14.2154C14.1407 32.8594 14.0801 32.7988 14.0801 32.7241V16.3594"
      stroke="#15141D"
      strokeWidth="1.5"
    />
  </svg>
);
