export const getSignalStrength = (value?: number, signal?: boolean) => {
  if (value === undefined) return -1;

  if (signal) {
    switch (true) {
      case value <= -80: {
        return 1;
      }
      case value > -80 && value < -76: {
        return 1;
      }
      case value >= -76 && value < -72: {
        return 2;
      }
      case value >= -68 && value < -64: {
        return 3;
      }
      case value >= -64 && value < -60: {
        return 4;
      }
      case value >= -60: {
        return 5;
      }
      default: {
        return 1;
      }
    }
  }

  switch (true) {
    case value <= -80: {
      return 0;
    }
    case value > -80 && value < -70: {
      return 1;
    }
    case value >= -70 && value < -60: {
      return 2;
    }
    case value >= -60: {
      return 3;
    }
    default: {
      return 0;
    }
  }
};
