import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  onClick?: () => void;
  className?: string;
  noSpace?: boolean;
}

export const IconClose: React.FC<PropsInterface> = ({ onClick, className, noSpace }) => {
  return (
    <div
      className={classNames('icon-close', {
        clickable: onClick,
        'icon-close--no-space': noSpace,
        [className as string]: className,
      })}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7 7L12 12L7 17" stroke="white" vectorEffect="non-scaling-stroke" />
        <path d="M17 17L12 12L17 7" stroke="white" vectorEffect="non-scaling-stroke" />
      </svg>
    </div>
  );
};
