import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Header, NavHeader, Page, SubmitButton } from '../../../../components';
import { availableActionAdvancedIcons } from '../../../../components/action/edit-icon/available-icons';
import { EditIcon } from '../../../../components/edit-icon';
import { ActionAdvancedIconTypeEnum } from '../../../../types';
import { ActionName } from '../action-name';
import { ActionType, NewActionForm } from '../../types';

interface ComponentProps {
  name: string;
  icon: string;
  onSubmit: (formData: NewActionForm) => void;
  actionType?: ActionType;
}

const ActionCreateNameIconForm: React.FC<ComponentProps> = ({
  name,
  icon,
  onSubmit,
  actionType = ActionType.Action,
}) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { actionId } = useParams<{ actionId: string }>();
  const form = useForm<NewActionForm>({
    defaultValues: {
      name,
      icon_name: icon,
    },
  });
  const { icon_name } = form.watch();

  useEffect(() => {
    form.setValue('name', name);
    form.setValue('icon_name', icon);
  }, [name, icon]);

  const handleChangeIcon = (value: string) => form.setValue('icon_name', value);

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t(`action.${!actionId ? 'create' : 'edit'}.${actionType}Header`)} />
        </>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ActionName actionType={actionType} />
          <EditIcon
            currentIcon={icon_name || ActionAdvancedIconTypeEnum.DEFAULT}
            iconList={availableActionAdvancedIcons}
            handleChangeIcon={handleChangeIcon}
          />
          <SubmitButton>{tc('buttons.next')}</SubmitButton>
        </form>
      </FormProvider>
    </Page>
  );
};

export default ActionCreateNameIconForm;
