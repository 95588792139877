import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteUser } from '../../../../api/modules/user/user.hooks';
import { DialogConfirmation, IconDelete } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import { InstallationAccessType, IntegrationType } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';
import { useShareRedirect } from '../../../installation-share/hooks/use-share-redirect';
import './index.scss';

export const ProfileDelete: React.FC = () => {
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const { installationList } = useInstallation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { mutate } = useDeleteUser();
  const { shareInstallation } = useShareRedirect();

  const handleRemoveProfile = () => {
    mutate();
  };

  const handleShareInstallation = (installationId: string) => {
    shareInstallation(installationId);
  };

  const forwardInfoVisible = useMemo(
    () =>
      installationList.filter(
        (inst) => inst.accessType === InstallationAccessType.Owner && inst.integrationType === IntegrationType.Lavva,
      ).length > 0,
    [installationList],
  );

  return (
    <>
      <ArrowButton
        title={t('deleteProfile.delete')}
        onClick={() => setShowDialog(true)}
        type="delete"
        arrowIcon={<IconDelete />}
        small
      />
      <DialogConfirmation
        show={showDialog}
        setShow={setShowDialog}
        header={t('deleteProfile.deleting')}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleRemoveProfile}
        secondaryBtnAction={() => setShowDialog(false)}
        contentClassName="delete-profile-content"
        timer
      >
        {installationList.length ? <p>{t('deleteProfile.deleteInstallationInfo')}</p> : null}
        <div className="installation-content">
          {installationList
            .filter((inst) => inst.accessType === InstallationAccessType.Owner)
            .map((inst) => (
              <div key={inst.installationId} className="installation">
                <p>{`- ${inst.name}`}</p>
                {inst.integrationType === IntegrationType.Lavva && (
                  <button className="forward-btn" onClick={() => handleShareInstallation(inst.installationId)}>
                    {t('deleteProfile.forward')}
                  </button>
                )}
              </div>
            ))}
        </div>
        {forwardInfoVisible && (
          <div className="forward-content">
            <p>{t('deleteProfile.deleteShareInfo.info')}</p>
            <p>{t('deleteProfile.deleteShareInfo.step1')}</p>
            <p>{t('deleteProfile.deleteShareInfo.step2')}</p>
            <p>{t('deleteProfile.deleteShareInfo.step3')}</p>
          </div>
        )}
        <p>{`${t('deleteProfile.deleteConfirmation')}${
          installationList.length ? ` ${t('deleteProfile.deleteConfirmationRest')}` : '?'
        }`}</p>
      </DialogConfirmation>
    </>
  );
};
