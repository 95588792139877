import { useApolloClient } from '@apollo/client';

export const useRefetchData = () => {
  const apolloClient = useApolloClient();

  const refetchDevices = async () => {
    return await apolloClient.refetchQueries({ include: ['DevicesGetAll'] });
  };

  const refetchActions = async () => {
    return await apolloClient.refetchQueries({ include: ['AllActions'] });
  };

  const refetchDashboard = async () => {
    return await apolloClient.refetchQueries({ include: ['DashboardItems'] });
  };

  const refetchTriggers = async () => {
    return await apolloClient.refetchQueries({ include: ['AllTriggers'] });
  };

  const refetchChannelDetails = async () => {
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'userChannel',
    });
    apolloClient.cache.gc();
    return await apolloClient.refetchQueries({ include: ['ChannelsDetails'] });
  };

  const refetchAll = async () => {
    return await apolloClient.refetchQueries({
      include: ['InstallationGetAll', 'ChannelsGetAll', 'DevicesGetAll', 'DashboardItems', 'AllActions'],
    });
  };

  return {
    refetchDevices,
    refetchActions,
    refetchDashboard,
    refetchTriggers,
    refetchChannelDetails,
    refetchAll,
  };
};
