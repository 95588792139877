import { useTranslation } from 'react-i18next';
import { Switch } from '../../../../../../components';
import { useChannelsState } from '../../../../../../hooks';
import { ChannelGateStateInterface } from '../../../../../../types';
import './index.scss';

interface ComponentProps {
  channelId: string;
  handleChange: () => void;
}

export const ElectricalStrike: React.FC<ComponentProps> = ({ channelId, handleChange }) => {
  const { t } = useTranslation('channel-details');
  const { channelState } = useChannelsState();
  const state = channelState[channelId] as ChannelGateStateInterface | undefined;

  return (
    <div className="electrical-strike">
      <Switch checked={!!state?.electricStrikeStatus} onChange={handleChange} />
      <p>{t('electricalStrike')}</p>
    </div>
  );
};
