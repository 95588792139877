import React, { ChangeEvent, useCallback } from 'react';
import { useLight } from '../../../../hooks';
import { ChannelInterface } from '../../../../types';
import { Switch } from '../../../switch';

interface PropsInterface {
  channel: ChannelInterface;
}

export const LightControl: React.FC<PropsInterface> = ({ channel }) => {
  const { handleControlOn: onLight, isOn: isOnLight } = useLight(channel);

  const handleControl = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onLight(e.target.checked),
    [onLight, channel],
  );

  return <Switch checked={isOnLight} onChange={handleControl} />;
};
