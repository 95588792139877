import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  GatePositionDeviceState,
  ResistanceOrOpticBarStatusEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { useSomethingWrong } from '../../../../../../pages/channel-list/hooks/use-something-wrong';

export const useGatePreciseDetails = (channel: IDeviceChannel) => {
  const { t } = useTranslation('channel-details');
  const { connectionIcon, calibrationNeed, channelOrientedError, gateHealth } = useSomethingWrong(channel, {
    detailsView: true,
  });

  const errorMessage = useMemo(() => {
    if (gateHealth?.Data) {
      if (gateHealth.Data.AutotestSwitchOn === false) return t('exalus.gateHealth.AutotestSwitchOn');
      if (gateHealth.Data.LastAutotestFailed) return t('exalus.gateHealth.LastAutotestFailed');
      if (gateHealth.Data.InertionBreaksOk === false) return t('exalus.gateHealth.InertionBreaksOk');
      if (gateHealth.Data.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.Active) {
        return t('exalus.gateHealth.ResistanceOrOpticBarActive');
      }
      if (gateHealth.Data.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.UnconnectedOrMalfunction) {
        return t('exalus.gateHealth.ResistanceOrOpticBarUnconnected');
      }
      if (gateHealth.Data.PhotoCellsActive) return t('exalus.gateHealth.PhotoCellsActive');
    }

    return '';
  }, [gateHealth]);

  const isWarning = useMemo(() => gateHealth?.Data.PhotoCellsActive, [gateHealth]);

  const state = useMemo(
    () =>
      channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.GatePosition) as
        | GatePositionDeviceState
        | undefined,
    [channel.States],
  );

  const position = useMemo(() => state?.Data?.Position ?? null, [state]);

  const externalLimitSwitchActive = useMemo(
    () => !!(gateHealth?.Data.ExternalLimitSwitchAActive || gateHealth?.Data.ExternalLimitSwitchBActive),
    [gateHealth],
  );

  return {
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    errorMessage,
    isWarning,
    position,
    externalLimitSwitchActive,
  };
};
