import React from 'react';
import classNames from 'classnames';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { DeviceTaskExecutionResult as ChannelTaskExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/TaskExecutionResult';
import { IconOff, IconOn } from '../../../../../components';
import { PowerItem } from './power';

interface ComponentProps {
  turnOn: () =>
    | Promise<ChannelTaskExecutionResult>
    | Promise<ChannelTaskExecutionResult>[]
    | Promise<ChannelTaskPairExecutionResult[]>;
  turnOff: () =>
    | Promise<ChannelTaskExecutionResult>
    | Promise<ChannelTaskExecutionResult>[]
    | Promise<ChannelTaskPairExecutionResult[]>;
  isListItem?: boolean;
  power?: number;
}

export const SwitchControlButtons: React.FC<ComponentProps> = ({ turnOn, turnOff, isListItem, power }) => (
  <div
    className={classNames('cover-control-box', {
      'cover-control-box--list-view': isListItem,
    })}
  >
    <div className="cover-control-box__controls">
      {isListItem && <PowerItem power={power} />}
      <div className="cover-control-box__control" onClick={turnOn}>
        <IconOn />
      </div>
      {!isListItem && <PowerItem power={power} column />}
      <div className={classNames('cover-control-box__control', { 'no-margin': !isListItem })} onClick={turnOff}>
        <IconOff />
      </div>
    </div>
  </div>
);
