import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../../../../components';
import { OptimizerConfigurationPhaseVoltageThresholdSlider } from './slider';

export const OptimizerConfigurationPhaseVoltageThreshold: React.FC = () => {
  const { t } = useTranslation('channel-details');

  return (
    <>
      <Header title={t('optimizer.configuration.voltageThresholds.heading')} />
      <div className="grid-list-24">
        {[0, 1, 2].map((index) => (
          <OptimizerConfigurationPhaseVoltageThresholdSlider
            key={index}
            label={`L${index + 1}`}
            name={`voltageThreshold${index + 1}`}
          />
        ))}
      </div>
    </>
  );
};
