import React from 'react';

export const IconPuzzle2: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-puzzle-2"
  >
    <path
      d="M15.55 34.7515H5.85595C4.53842 34.7515 3.4707 33.6838 3.4707 32.3662V22.6708"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.68555 22.6722H8.07224V23.9132C7.39803 24.3725 6.95483 25.1474 6.95483 26.0258C6.95483 27.4897 8.1864 28.6662 9.67181 28.5749C10.9517 28.4956 11.9899 27.448 12.0584 26.1668C12.1081 25.2307 11.6555 24.398 10.945 23.9145V22.6735H15.5476V27.2761H16.7886C17.2734 26.5657 18.1061 26.1131 19.0409 26.1628C20.3208 26.2312 21.3684 27.2694 21.449 28.5493C21.5416 30.0334 20.3651 31.2663 18.8999 31.2663C18.0215 31.2663 17.2479 30.8231 16.7873 30.1489H15.5463V31.5893"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4707 22.6722V12.9767C3.4707 11.6592 4.53842 10.5915 5.85595 10.5915H15.5514V15.2492H14.3104C13.8269 14.54 12.9942 14.0861 12.0581 14.1358C10.7782 14.2043 9.72929 15.2411 9.65005 16.521C9.55738 18.0064 10.7339 19.2407 12.1992 19.2407C13.0775 19.2407 13.8511 18.7975 14.3118 18.1219H15.5527V22.6708"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5488 27.2761V22.6722"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5496 22.6722H20.1522V21.4312C19.478 20.9719 19.0348 20.1969 19.0348 19.3186C19.0348 17.8547 20.2663 16.6781 21.7517 16.7695C23.0317 16.8487 24.0698 17.8963 24.1383 19.1776C24.188 20.1137 23.7354 20.9463 23.0249 21.4298V22.6708H27.6276V32.3663C27.6276 33.6838 26.5598 34.7515 25.2423 34.7515H15.5469"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3914 9.00054H23.1504C22.6427 9.74593 21.7509 10.2093 20.7598 10.1045C19.5819 9.97962 18.6324 9.03412 18.5008 7.85761C18.3289 6.3158 19.5309 5.01036 21.0378 5.01036C21.9161 5.01036 22.6897 5.45356 23.1504 6.12777H24.3914V1.47009H34.0868C35.4043 1.47009 36.4721 2.53781 36.4721 3.85534V13.5508H31.8694V12.3098C32.5799 11.825 33.0325 10.9923 32.9828 10.0575C32.9143 8.7776 31.8762 7.73002 30.5962 7.64944C29.1122 7.55677 27.8793 8.73328 27.8793 10.1985C27.8793 11.0769 28.3225 11.8505 28.9967 12.3111V13.5521H24.3941V12.111"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
