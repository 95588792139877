import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import AddressIp from '../../../../../components/address-ip';
import { useAddDns } from '../hooks/use-add-dns';
import './index.scss';

const ExalusAddDns: React.FC = () => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { handleChange, handleSubmit, dns, submitDisabled } = useAddDns();

  return (
    <Page
      isStickyHeader
      contentClassName="exalus-add-dns"
      header={
        <>
          <NavHeader />
          <Header title={t('exalusNetwork.dnsAdd')} isUnderline />
        </>
      }
    >
      <div className="max-width-desktop">
        <AddressIp value={dns} label={t('exalusNetwork.addressDns')} handleChange={handleChange} />
      </div>
      <SubmitButton type="button" onClick={handleSubmit} disabled={submitDisabled}>
        {tc('buttons.add')}
      </SubmitButton>
    </Page>
  );
};

export default ExalusAddDns;
