import React from 'react';
import './index.scss';

export const IconHomeCircle: React.FC = () => {
  return (
    <svg
      className="icon-home-circle"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#009EE3" />
      <g clipPath="url(#clip0_22202_1645)">
        <path
          d="M22.574 11.91V22.192C22.574 23.1906 21.92 24.0001 21.1131 24.0001H18.5566V18.4001C18.5566 17.5164 17.8403 16.8001 16.9566 16.8001H15.0436C14.1599 16.8001 13.4436 17.5164 13.4436 18.4001V24.0001H10.8871C10.0802 24.0001 9.42618 23.1906 9.42618 22.192V11.91M7.6001 12.9967L16.0001 7.20007L24.4001 12.9967"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22202_1645">
          <rect width="19.2" height="19.2" fill="white" transform="translate(6.3999 6.40002)" />
        </clipPath>
      </defs>
    </svg>
  );
};
