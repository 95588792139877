import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  ConfigurationStateEnum,
  FacadePositionDeviceState,
  FacadeType,
  FacadeTypeDeviceState,
  WindThresholdStateEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import {
  BlindRemoteButtonState,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import VerticalSlider from '@mui/material/Slider';
import {
  ControlWrapper,
  EmptyStateBox,
  IconChevron,
  IconConnectionError,
  IconWarning,
  Slider,
} from '../../../../../../../components';
import LavvaDetailsWrapper from '../../../../../../../components/details-wrapper';
import { useSomethingWrong } from '../../../../../pages/channel-list/hooks/use-something-wrong';
import { BlindDetailsSimpleControlButtons } from '../../../../control-buttons/blind-details/simple';
import { useFacadeControl } from '../../../hooks/use-facade-control';
import { useWindControl } from '../../../hooks/use-wind-control';
import { Slats } from './components/slats';
import { Favourites } from './favourites';
import './index.scss';

interface ComponentProps {
  channel: IDeviceChannel;
  active: boolean;
}

export const FacadeDetailsControls: React.FC<ComponentProps> = ({ channel, active }) => {
  const { t: tc } = useTranslation('common');
  const { t: td } = useTranslation('channel-details');
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [tiltValue, setTiltValue] = useState<number>(0);
  const [targetTiltValue, setTargetTiltValue] = useState(0);
  const [targetValue, setTargetValue] = useState<number>(0);
  const [activeTarget, setActiveTarget] = useState<'blind' | 'tilts' | null>(null);
  const { handleSliderEvent, stop, open, close, handleTiltEvent } = useFacadeControl(channel);
  const { windThreshold } = useWindControl(channel);
  const { connectionIcon, calibrationNeed } = useSomethingWrong(channel, {
    detailsView: true,
  });

  const state = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.FacadePosition,
    ) as FacadePositionDeviceState;
  }, [channel.States]);

  const isUnknownFacadePosition = useMemo(() => {
    const facadeData = (
      channel.States?.find(
        (state) => state.TypeAsEnum === ChannelResponseType.FacadePosition,
      ) as FacadePositionDeviceState
    )?.Data;

    return facadeData?.Position === 255 || facadeData?.Tilt === 255;
  }, [channel.States]);

  const remoteButtonState = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState,
    ) as IChannelState<BlindRemoteButtonState>;
  }, [channel.States]);

  const facadeType = useMemo(() => {
    return channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.FacadeType,
    ) as FacadeTypeDeviceState;
  }, [channel?.States]);

  useEffect(() => {
    if (typeof state?.Data?.Position === 'number') {
      if (state?.Data?.Position === sliderValue && targetValue !== sliderValue) return;
      setSliderValue(state?.Data?.Position);
      setTargetValue(state?.Data?.Position);
    }
    if (typeof state?.Data?.Tilt === 'number' && !active) {
      if (state?.Data?.Tilt === tiltValue) return;
      setTiltValue(state?.Data?.Tilt);
      setTargetTiltValue(state?.Data?.Tilt);
    }
  }, [JSON.stringify(channel.States), active]);

  const position = useMemo(() => {
    return state?.Data?.Position ?? null;
  }, [channel.States]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTarget('blind');
    setTargetValue(Number(event.target.value));
  }, []);

  const handleDown = useCallback(() => {
    setActiveTarget('blind');
    close();
  }, [position]);

  const handleStop = useCallback(() => {
    setActiveTarget('blind');
    stop();
    setTargetValue(sliderValue);
  }, [sliderValue]);

  const handleUp = useCallback(() => {
    setActiveTarget('blind');
    open();
  }, []);

  const activeBlind = useMemo(() => activeTarget === 'blind' && active, [activeTarget, active]);
  const activeTilts = useMemo(() => activeTarget === 'tilts' && active, [activeTarget, active]);

  return (
    <div
      className={classNames('cover-detail-view facade', {
        'cover-detail-view--full-space': calibrationNeed,
      })}
    >
      {calibrationNeed ? (
        <LavvaDetailsWrapper>
          <ControlWrapper>
            {connectionIcon && <IconConnectionError big size={14} />}
            <div>
              {calibrationNeed && (
                <EmptyStateBox
                  header={tc('status.calibrate')}
                  content={td(
                    calibrationNeed !== ConfigurationStateEnum.OngoingConfiguration
                      ? 'calibrationInProgressInfo'
                      : 'calibrationNeededSimple',
                  )}
                  icon={<IconWarning />}
                  className="m-b-24"
                />
              )}
              <BlindDetailsSimpleControlButtons
                open={open}
                stop={stop}
                close={close}
                remoteButtonState={remoteButtonState}
                active={activeBlind}
              />
            </div>
          </ControlWrapper>
        </LavvaDetailsWrapper>
      ) : (
        <>
          {windThreshold !== WindThresholdStateEnum.Exceeded && (
            <LavvaDetailsWrapper>
              <ControlWrapper className="control-wrapper--space-evenly control-wrapper--full-space">
                <div className="max-width-desktop">
                  <div className={classNames('slider-section', { disabled: activeTilts })}>
                    {connectionIcon && <IconConnectionError big size={14} />}
                    <Slider
                      onPointerUp={handleSliderEvent}
                      value={sliderValue}
                      targetValue={targetValue}
                      onChange={onChange}
                      active={activeBlind}
                      showValue={false}
                      className="slider--width-auto"
                      disabled={activeTilts}
                    />
                  </div>
                  <div className={classNames('slats-section', { active: activeTilts, disabled: activeBlind })}>
                    <div className="slats-section__wrapper">
                      <Slats tilt={tiltValue} fullRange={facadeType?.Data.FType === FacadeType.Facade180} />
                      <VerticalSlider
                        classes={{
                          root: 'vertical-slider',
                          rail: 'vertical-slider__rail',
                          thumb: 'vertical-slider__thumb',
                        }}
                        disabled={activeBlind}
                        orientation="vertical"
                        track={false}
                        valueLabelDisplay="off"
                        defaultValue={100 - targetTiltValue}
                        value={100 - targetTiltValue}
                        onChange={(_, value) => {
                          setActiveTarget('tilts');
                          setTargetTiltValue(100 - (value as number));
                        }}
                        onChangeCommitted={() => {
                          handleTiltEvent(targetTiltValue);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ControlWrapper>
            </LavvaDetailsWrapper>
          )}
          {isUnknownFacadePosition && (
            <EmptyStateBox
              header={td('exalus.facade.unknownState.header')}
              content={td('exalus.facade.unknownState.content')}
              icon={<IconWarning />}
              className="m-t-24"
            />
          )}
          {windThreshold === WindThresholdStateEnum.Exceeded ? (
            <EmptyStateBox
              header={td('exalus.windThresholdExceeded.windExceeded')}
              content={td('exalus.windThresholdExceeded.blockDetailsInfo')}
              icon={<IconWarning />}
              className="m-t-24"
            />
          ) : (
            <>
              <div className="cover-detail-view__controls">
                <div onClick={handleDown} className="cover-detail-view__controls-down">
                  <IconChevron withCircle direction="down" />
                </div>
                <button className="cover-detail-view__controls-stop" onClick={handleStop}>
                  {tc('status.stop')}
                </button>
                <div onClick={handleUp} className="cover-detail-view__controls-up">
                  <IconChevron withCircle direction="up" />
                </div>
              </div>
              <Favourites channel={channel} setTargetValue={setTargetValue} position={position} />
            </>
          )}
        </>
      )}
    </div>
  );
};
