import { gql } from '@apollo/client';

export const ALL_ACTIONS = gql`
  query AllActions($installationId: UUID!) {
    allUserActions(installationId: $installationId) {
      favouritePositions {
        ... on PositionFavourites {
          value
        }
        ... on LightsFavourites {
          brightness
          color {
            red
            green
            blue
          }
        }
      }
      id
      iconName
      action {
        id
        sourceGroupId
        installationId
        ownerId
        actionType
        name
        channelType
        channelActions {
          channelId
          channelType
          activityType
          order
          value
        }
      }
    }
  }
`;

export const ACTION_DETAILS = gql`
  query ActionDetails($installationId: UUID!, $actionId: UUID!) {
    userAction(installationId: $installationId, actionId: $actionId) {
      id
      iconName
      favouritePositions {
        ... on PositionFavourites {
          value
        }
        ... on LightsFavourites {
          brightness
          color {
            red
            green
            blue
          }
        }
      }
      installationId
      userId
      action {
        id
        sourceGroupId
        installationId
        ownerId
        actionType
        name
        channelType
        channelActions {
          channelId
          channelType
          activityType
          order
          value
        }
      }
    }
  }
`;
