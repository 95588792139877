import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toastError } from '../../utils/toast';
import { useBackdropContext } from '../use-backdrop';

export const useTimeout = () => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const { t: tc } = useTranslation('common');
  const { turnOffBackdrop } = useBackdropContext();

  const onTimeoutError = () => {
    toastError({ content: tc('errors.somethingWentWrong') });
    turnOffBackdrop();
  };

  const clearTimeoutError = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  const setTimeoutError = () => {
    clearTimeoutError();
    timeout.current = setTimeout(onTimeoutError, 1000 * 5);
  };

  return {
    setTimeoutError,
    clearTimeoutError,
  };
};
