import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, CarouselItem, Header, Page, Tabs } from '../../components';
import { useSettings } from './hooks/use-settings';

const Settings: React.FC = () => {
  const { t } = useTranslation('profile');
  const { tabs, activeTabIndex, handleTabChange, components } = useSettings();

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height"
      contentClassName="page__content--unset-height"
      header={
        <>
          <Header title={t('profile')} />
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={handleTabChange}
            tabsType="pills"
            moreButton="normal"
          />
        </>
      }
    >
      <Carousel setActiveIndex={handleTabChange} activeIndex={activeTabIndex}>
        {components.map((Component: React.ReactNode, index: number) => (
          <CarouselItem key={index}>{Component}</CarouselItem>
        ))}
      </Carousel>
    </Page>
  );
};

export default Settings;
