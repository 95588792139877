import React, { ReactNode, useCallback, useMemo } from 'react';
import c from 'classnames';
import { orderBy } from 'lodash';
import { PHASES_COLORS } from '../../../const';
import { VectorMeasurementResponseItem } from '../../../data-access/gql-types/graphql';
import { SelectedPhase, SummaryBalance } from '../../../modules/channel-details/measurement/types';
import { getClosestValueFromArray } from '../../../utils/helpers';
import './index.scss';

interface PropsInterface {
  maxScaleValue: number;
  averageValue: number;
  phaseMeasurements: VectorMeasurementResponseItem[] | undefined;
  selectedPhases: SelectedPhase[];
  children?: ReactNode;
  ticks?: boolean;
  indicator?: boolean;
  twoDirections?: boolean;
  summaryBalance: SummaryBalance;
}

export const DonutChartTwoDirections: React.FC<PropsInterface> = ({
  maxScaleValue,
  averageValue,
  phaseMeasurements,
  selectedPhases,
  children,
  ticks = true,
  summaryBalance,
}) => {
  const maxOffsetValue = 75;
  const donutRadius = 16;

  const calculatePercentageValue = useCallback(
    (value: number) => {
      const calculatedValue = (Math.abs(value) / maxScaleValue) * maxOffsetValue;
      if (calculatedValue < 0 || calculatedValue > 75) return 75;
      return calculatedValue;
    },
    [maxScaleValue, maxOffsetValue],
  );
  const donutCircuit = useMemo(() => 2 * Math.PI * donutRadius, [donutRadius]);

  const isSummary = useMemo(() => {
    return !selectedPhases.length || (selectedPhases.length === 1 && selectedPhases[0].value === 0);
  }, [selectedPhases]);

  const percentageArray = Array.from(Array(67).keys()).map((number) => number / 0.67);
  const tickWidth = 9;
  const tickHeight = 1.5;
  const radius = 136;
  const step = (2 * (Math.PI * 0.75)) / percentageArray.length;
  const avgPercentage = (averageValue / maxScaleValue) * 100;

  return (
    <>
      {ticks && (
        <div className="donut-chart-2__sunlight-ticks">
          {percentageArray.map((item, index) => {
            const tickPercentage = index / 0.67;
            const closestTick = getClosestValueFromArray(avgPercentage, percentageArray) === tickPercentage;
            const deg = index * (270 / percentageArray.length);
            const angle = index * step;

            const positionX = Math.round(150 + radius * Math.cos(angle) - (closestTick ? 11 : tickWidth) / 2);
            const positionY = Math.round(150 + radius * Math.sin(angle) - tickHeight / 2);

            const tickStyle = {
              left: positionX,
              top: positionY,
              transform: `rotate(${deg}deg)`,
              '--background': isSummary
                ? PHASES_COLORS[0]
                : selectedPhases.length === 1
                ? selectedPhases[0].color
                : 'var(--color-type-primary)',
            } as React.CSSProperties;

            return (
              <div
                className={c('donut-chart-2__sunlight-tick', {
                  'donut-chart-2__sunlight-tick--big':
                    getClosestValueFromArray(avgPercentage, percentageArray) === tickPercentage,
                })}
                key={index}
                style={tickStyle}
              ></div>
            );
          })}
        </div>
      )}

      <svg className="donut-chart-2" width="100%" height="100%" viewBox="0 0 40 40">
        <filter id="inner-shadow">
          <feOffset dx="-0.1" dy="-0.1" result="offset-blur" />
          <feGaussianBlur stdDeviation=".35" result="offset-blur" />
          <feFlood floodColor={PHASES_COLORS[0]} result="color" />
          <feComposite operator="in" in="SourceGraphic" in2="offset-blur" />
        </filter>

        <circle
          className="donut-chart-2__progress"
          cx="20"
          cy="20"
          r={donutRadius}
          fill="transparent"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeDasharray={donutCircuit}
          strokeDashoffset={donutCircuit * ((100 - 75) / 100)}
        />

        {isSummary ? (
          <>
            <circle
              className="donut-chart-2__chart"
              cx="20"
              cy="20"
              r={donutRadius}
              fill="transparent"
              stroke="#00B191"
              filter={'url(#inner-shadow)'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeDasharray={donutCircuit}
              strokeDashoffset={donutCircuit * ((100 - calculatePercentageValue(summaryBalance.minus / 2)) / 100)}
              style={{ transform: `rotate(${-90 + 360}deg) scale(1, -1)` }}
            />
            <circle
              className="donut-chart-2__chart"
              cx="20"
              cy="20"
              r={donutRadius}
              fill="transparent"
              stroke="#CC0909"
              filter={'url(#inner-shadow)'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeDasharray={donutCircuit}
              strokeDashoffset={donutCircuit * ((100 - calculatePercentageValue(summaryBalance.plus / 2)) / 100)}
            />
          </>
        ) : (
          <>
            {[
              ...orderBy(selectedPhases, 'value', 'asc').map((selectedPhase) => {
                const measurement = phaseMeasurements?.find((measurement) => measurement.index === selectedPhase.value);

                if (!measurement?.value) return null;

                const style: React.CSSProperties = {
                  transform: `rotate(${-90 + 360}deg) scale(1${measurement.value < 0 ? ', -1' : ''})`,
                };

                return (
                  <circle
                    key={selectedPhase.value}
                    className="donut-chart-2__chart"
                    cx="20"
                    cy="20"
                    r={donutRadius}
                    fill="transparent"
                    stroke={measurement.value < 0 ? '#00B191' : '#CC0909'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeDasharray={donutCircuit}
                    strokeDashoffset={donutCircuit * ((100 - calculatePercentageValue(measurement.value / 2)) / 100)}
                    style={style}
                  />
                );
              }),
            ].reverse()}
          </>
        )}

        <rect className="donut-chart-2__rect" width="30" height="30" />
        {children}
      </svg>
    </>
  );
};
