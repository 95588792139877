import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceTask } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { DelayTask, LockExecutionTask, LockType } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { useExalusCreateActionContext } from '../context';
import { DelayTaskParams, DeviceTaskParams, LockTaskParams, Task, TaskState } from '../types';

export const useTaskEdit = () => {
  const history = useHistory();
  const { builder, taskList, taskVariant, addTaskToList, editDeviceTask, editDelayTask, editLockTask } =
    useExalusCreateActionContext();
  const [task, setTask] = useState<Task | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    if (id) {
      const index = taskList.findIndex((x) => x.id === id);

      if (index !== -1) {
        setTask(taskList[index]);
      }
    }
  }, []);

  const finalizeDeviceTaskSubmit = (
    channelTask: IDeviceTask,
    channel: IDeviceChannel,
    taskState: TaskState,
    back: boolean,
  ) => {
    if (!task) {
      const deviceTask = builder?.Commit().DeviceTask(channelTask);

      if (deviceTask) {
        deviceTask.AddTask();
        addTaskToList({
          id: deviceTask.TaskId,
          taskType: SceneTaskType.DeviceTask,
          taskParams: {
            state: taskState,
            channel,
            variant: taskVariant,
          } as DeviceTaskParams,
        });
      }

      if (back) history.go(-4);
    } else {
      const deviceTask = builder?.Commit().DeviceTask(channelTask);

      if (deviceTask) {
        deviceTask.EditTask(task.id);
        editDeviceTask(task.id, taskState);
        history.goBack();
      }
    }
  };

  const finalizeDelayTaskSubmit = (delay: DelayTask) => {
    if (!task) {
      const delayTask = builder?.Commit().DelayTask(delay);

      if (delayTask) {
        delayTask.AddTask();
        addTaskToList({
          id: delayTask.TaskId,
          taskType: SceneTaskType.DelayTask,
          taskParams: {
            delay: delay.Delay.Value,
          } as DelayTaskParams,
        });
      }

      history.go(-2);
    } else {
      const delayTask = builder?.Commit().DelayTask(delay);

      if (delayTask) {
        delayTask.EditTask(task.id);
        editDelayTask(task.id, delay.Delay.Value);
        history.goBack();
      }
    }
  };

  const finalizeLockTaskSubmit = (lock: LockExecutionTask) => {
    if (!task) {
      const lockTask = builder?.Commit().LockExecutionTask(lock);

      if (lockTask) {
        lockTask.AddTask();
        addTaskToList({
          id: lockTask.TaskId,
          taskType: SceneTaskType.LockExecution,
          taskParams: {
            lockType: lock.LockType,
            resetLockScenesGuids: lock.ResetLockScenesGuids,
            lockStart: lock.LockStartHour.Value,
            ...(lock.LockType === LockType.TimeRange && { lockEnd: lock.LockEndHour.Value }),
          } as LockTaskParams,
        });
      }

      history.go(-2);
    } else {
      const lockTask = builder?.Commit().LockExecutionTask(lock);

      if (lockTask) {
        lockTask.EditTask(task.id);
        editLockTask(task.id, lock);
        history.goBack();
      }
    }
  };

  return {
    task,
    finalizeDeviceTaskSubmit,
    finalizeDelayTaskSubmit,
    finalizeLockTaskSubmit,
  };
};
