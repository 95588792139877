import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../components';
import { getParameterByName } from '../../../../utils/location';
import FoundDevices from './found-devices';
import './index.scss';
import ManuallyPaired from './manually-paired';
import { PairedPage } from './types';

const AddDevices: React.FC = () => {
  const { t } = useTranslation('device-add');

  const content = useMemo(() => {
    const urlPage = getParameterByName('page');

    switch (urlPage) {
      case PairedPage.ManuallyPaired:
        return <ManuallyPaired />;
      default:
        return <FoundDevices />;
    }
  }, [location.search]);

  return (
    <Page
      isStickyHeader
      className="add-exalus-devices"
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.foundDevices')} />
        </>
      }
    >
      <div className="exalus-devices-content">{content}</div>
    </Page>
  );
};

export default AddDevices;
