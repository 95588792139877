import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import {
  FacadeRemoteButtonState,
  FacadeRemoteButtonStateEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { SubmitButton } from '../../../../../../../../components';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { InputString } from '../../../../components/input-string';
import { RemoteTriggerButtonState } from '../../../../components/remote-trigger-button-state';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';

export const FacadeRemotesParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { channels } = useExalusCreateActionContext();
  const [triggerMethodButton, setTriggerMethodButton] = useState<number>(FacadeRemoteButtonStateEnum.StopReleased);
  const {
    triggerMethod,
    timeSec,
    conditionType,
    changeableAtMeetCondition,
    setConditionType,
    setTimeSec,
    setTriggerMethod,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  } = useConditionEdit({ supportedParam: SupportedArgumentTypes.ArgumentAsDeviceState });

  const handleSubmit = () => {
    if (channels.length) {
      const deviceArgument = createDeviceArgumentWithComparison();
      deviceArgument.Type = DeviceResponseType.FacadeRemoteButtonState;
      deviceArgument.DeviceGuid = channels[0].GetDevice().Guid;
      const remoteState = new FacadeRemoteButtonState();
      remoteState.Channel = channels[0].Number;
      remoteState.State = triggerMethodButton as FacadeRemoteButtonStateEnum;
      deviceArgument.SetCheckDeviceState<FacadeRemoteButtonState>(remoteState);

      saveConditionParams(deviceArgument);
    }
  };

  return (
    <>
      {changeableAtMeetCondition ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      {triggerMethod === TriggerMethodType.WhenChangeOn && changeableAtMeetCondition && (
        <InputString
          value={timeSec}
          setValue={setTimeSec}
          label={t('action.create.conditions.sources.device.requiredTime')}
          inputType="number"
          min={0}
        />
      )}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={[ConditionsTypes.Equal, ConditionsTypes.NotEqueal]}
      />
      <RemoteTriggerButtonState
        triggerMethodButton={triggerMethodButton}
        setTriggerMethodButton={setTriggerMethodButton}
      />
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
