import React from 'react';

export const IconLaptop: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-laptop"
  >
    <g clipPath="url(#clip0_24319_28765)">
      <path
        d="M31.3212 25.1939V8.38182C31.3212 7.61866 30.7026 7 29.9394 7H7.3697C6.60654 7 5.98788 7.61866 5.98788 8.38182V25.1939M31.3212 25.1939H5.98788M31.3212 25.1939L34.6727 28.3305C35.5877 29.1868 34.9817 30.7212 33.7285 30.7212H3.5342C2.27632 30.7212 1.67265 29.1772 2.59695 28.324L5.98788 25.1939"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path d="M8 28H29.5" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_24319_28765">
        <rect width="38" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
