import React from 'react';

export const IconPhotoCamera: React.FC = () => {
  return (
    <svg
      className="icon-sensor icon-photo-camera"
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.527 7.99132V20.1275C28.527 21.3574 27.5289 22.3556 26.299 22.3556H3.70122C2.47129 22.3556 1.47314 21.3574 1.47314 20.1275V7.99132C1.47314 6.5769 2.6203 5.42974 4.03473 5.42974H6.67673C7.24912 5.42974 7.7955 5.19558 8.1905 4.78166L10.2909 2.57723C10.9626 1.87239 11.8945 1.47266 12.8666 1.47266H17.1312C18.1057 1.47266 19.0352 1.87239 19.707 2.57723L21.8073 4.78166C22.2023 5.19558 22.7487 5.42974 23.3211 5.42974H25.9631C27.3775 5.42974 28.5247 6.5769 28.5247 7.99132H28.527Z"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0002 20.0835C18.6487 20.0835 21.6064 17.1258 21.6064 13.4773C21.6064 9.82878 18.6487 6.87109 15.0002 6.87109C11.3517 6.87109 8.39404 9.82878 8.39404 13.4773C8.39404 17.1258 11.3517 20.0835 15.0002 20.0835Z"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
