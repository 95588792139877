import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, NavHeader, Page } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { InstallationAccessType } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { SharedItemType, SharingType } from '../../../types';
import { useInstallationShare } from '../hooks/use-installation-share';
import './index.scss';

const InstallationShareSubject: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const {
    methods: { handleSetShareSubject, handleSetSharePermission, handleSetShareAccess, handleSetShareQuantity },
  } = useInstallationShare();

  const handleClickInstallation = () => {
    handleSetShareSubject(SharedItemType.Installation);
    handleSetShareAccess(SharingType.Undefined);
    handleSetShareQuantity(0);
    history.push(ROUTES.InstallationShareRole());
  };

  const handleClickGroups = () => {
    handleSetShareSubject(SharedItemType.Group);
    handleSetShareAccess(SharingType.Undefined);
    handleSetSharePermission(InstallationAccessType.User);
    handleSetShareQuantity(0);
    history.push(ROUTES.InstallationGroupsShare());
  };

  const handleClickChannels = () => {
    handleSetShareSubject(SharedItemType.Channel);
    handleSetShareAccess(SharingType.Undefined);
    handleSetSharePermission(InstallationAccessType.User);
    handleSetShareQuantity(0);
    history.push(ROUTES.InstallationChannelsShare());
  };

  return (
    <Page
      className="share-subject"
      header={
        <>
          <NavHeader />
          <Header title={`${t('share_path.share_subject')}?`} isUnderline />
        </>
      }
    >
      <div className="grid-list-24">
        <ArrowButton title={t('share_path.whole_installation')} onClick={handleClickInstallation} />
        <ArrowButton title={t('share_path.selected_groups')} onClick={handleClickGroups} />
        <ArrowButton title={t('share_path.selected_channels')} onClick={handleClickChannels} />
      </div>
    </Page>
  );
};

export default InstallationShareSubject;
