import React from 'react';
import '../index.scss';

export const IconDeviceList: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--device-list"
  >
    <rect x="4.5" y="2.5" width="13" height="15" rx="1" stroke="#15141D" />
    <path d="M7.75 6.5H14.25" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.75 10H14.25" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.75 13.5H14.25" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
