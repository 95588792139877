import React from 'react';

export const IconCat: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00019 11C6.33352 11.1667 7.80019 13.5 3.00019 21.5C-2.99981 31.5 5.50019 31.5 10.5002 31.5M10.5002 31.5C13.7469 31.5 18.0916 31.5 20.8606 31.5C21.8139 31.5 22.632 30.6823 21.9294 30.0379C21.3115 29.4713 20.127 29 18 29C19.5043 28.346 21.2447 27.3275 22.2483 26.1621C23.8021 24.3577 23.5897 22.2012 18 20.5M10.5002 31.5C6.10034 16.3 15.3335 12.8333 20.5 13C20.5 6.6 25.8333 2.66667 28.5 1.5L29.5 5.5C30.5 5.83333 32.6 7.2 33 10C33.4 12.8 30.1667 13.1667 28.5 13V18.912C28.5 19.6315 28.3706 20.3451 28.118 21.0187L27.382 22.9813C27.1294 23.6549 27 24.3685 27 25.088V29L28.2111 29.6056C29.155 30.0775 28.8192 31.5 27.7639 31.5H26.2229C25.4722 31.5 24.7848 31.0797 24.4427 30.4115L22.26 26.1484"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
