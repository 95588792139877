import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Carousel,
  CarouselItem,
  EmptyStateBox,
  Header,
  IconAdd,
  IconWarning,
  Page,
  Tabs,
} from '../../../../components';
import { Tip } from '../../../../components/tip';
import { LearningScenarios } from '../../../../data-access/gql-types/graphql';
import ActionItem from './components/action-item';
import { useActions } from './hooks/use-actions';
import { tabNames } from './utils/common';

const ActionList: React.FC = () => {
  const { t } = useTranslation('action');
  const { tabs, actions, isFetched, activeTabIndex, setTab, addNewAction } = useActions();

  const actionList = useMemo(
    () =>
      (actions || []).filter((x) => !x.DoesHaveTriggers).map((scene) => <ActionItem key={scene.Guid} action={scene} />),
    [actions],
  );

  const automationList = useMemo(
    () =>
      (actions || []).filter((x) => x.DoesHaveTriggers).map((scene) => <ActionItem key={scene.Guid} action={scene} />),
    [actions],
  );

  return (
    <Page
      isStickyHeader
      header={
        <>
          <Header title={t('action.scenarios')}>
            <button onClick={addNewAction}>
              <IconAdd />
            </button>
          </Header>
          {isFetched && (
            <Tabs
              tabList={tabs}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setTab}
              tabsType="pills"
              isDivider={false}
            />
          )}
        </>
      }
    >
      {(activeTabIndex === 0 && actionList.length) || (activeTabIndex === 1 && automationList.length) ? (
        <Carousel setActiveIndex={setTab} activeIndex={activeTabIndex}>
          <CarouselItem>
            <div className="grid-list-16">{actionList}</div>
          </CarouselItem>
          <CarouselItem>
            <div className="grid-list-16">{automationList}</div>
          </CarouselItem>
        </Carousel>
      ) : (
        <>
          {isFetched && (
            <EmptyStateBox
              header={t(`action.tabs.${tabNames[activeTabIndex]}`)}
              content={t(`action.emptyList.${tabNames[activeTabIndex]}.content`)}
              btnText={t(`action.emptyList.${tabNames[activeTabIndex]}.button`)}
              icon={<IconWarning />}
              onClick={addNewAction}
            />
          )}
        </>
      )}
      <Tip
        tip={t(`${activeTabIndex === 0 ? 'Action' : 'Automation'}AddTip`)}
        learningKey={activeTabIndex === 0 ? LearningScenarios.AddAction : LearningScenarios.AddAutomation}
        point={<IconAdd />}
        top={38}
        right={22}
      />
    </Page>
  );
};

export default ActionList;
