import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  isOn?: boolean;
  onClick?: () => void;
  className?: string;
}

export const IconPassword: React.FC<PropsInterface> = ({ isOn = false, onClick, className }) => {
  return (
    <div
      className={classNames('icon-password', {
        clickable: onClick,
        [className as string]: className,
      })}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {isOn ? (
          <>
            <path
              d="M22.5 11.75C22.5 11.75 17.799 18.5 12 18.5C6.20101 18.5 1.5 11.75 1.5 11.75C1.5 11.75 6.20101 5 12 5C17.799 5 22.5 11.75 22.5 11.75Z"
              stroke="#15141D"
              vectorEffect="non-scaling-stroke"
            />
            <circle cx="12" cy="12" r="2" fill="#15141D" />
          </>
        ) : (
          <>
            <path
              d="M22.5 12C22.5 12 17.799 18.75 12 18.75C6.20101 18.75 1.5 12 1.5 12C1.5 12 6.20101 5.25 12 5.25C17.799 5.25 22.5 12 22.5 12Z"
              stroke="#15141D"
              vectorEffect="non-scaling-stroke"
            />
            <line x1="3.5057" y1="3.94614" x2="20.7557" y2="19.6961" stroke="#15141D" />
          </>
        )}
      </svg>
    </div>
  );
};
