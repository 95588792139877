import { useTranslation } from 'react-i18next';
import { DeviceTaskExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/TaskExecutionResult';
import { useBackdropContext } from '../../../../../hooks';
import { toastError, toastSuccess } from '../../../../../utils/toast';

export const useDeviceTaskExecuted = () => {
  const { t } = useTranslation('common');
  const { turnOffBackdrop } = useBackdropContext();

  const handleResult = (result: DeviceTaskExecutionResult, name: string, onSuccess: () => void) => {
    turnOffBackdrop();

    switch (result) {
      case DeviceTaskExecutionResult.Executed:
        onSuccess();
        toastSuccess({ content: t('exalus.taskResult.executed', { deviceName: name }) });
        break;
      case DeviceTaskExecutionResult.Failed:
        toastError({ content: t('exalus.taskResult.failed', { deviceName: name }) });
        break;
      case DeviceTaskExecutionResult.ControllerResponseTimeout:
        toastError({ content: t('exalus.taskResult.controllerResponseTimeout', { deviceName: name }) });
        break;
      case DeviceTaskExecutionResult.DeviceResponseTimeout:
        toastError({ content: t('exalus.taskResult.deviceResponseTimeout', { deviceName: name }) });
        break;
      default:
        break;
    }
  };

  return {
    handleResult,
  };
};
