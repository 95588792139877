import React from 'react';
import c from 'classnames';

interface IconSwitchProps {
  isOn: boolean;
  big?: boolean;
  noState?: boolean;
}

export const IconOneArrowPressure: React.FC<IconSwitchProps> = ({ isOn = true, big, noState }) => {
  return (
    <svg
      className={c('icon-sensor icon-one-arrow-pressure', { big: big, 'no-state': noState })}
      width="38"
      height="31"
      viewBox="0 0 38 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isOn ? (
        <path
          d="M19.6965 0.749878C19.6965 0.335664 19.3607 -0.00012207 18.9465 -0.00012207C18.5323 -0.00012207 18.1965 0.335664 18.1965 0.749878L19.6965 0.749878ZM18.9465 12.8602L18.415 13.3894C18.5558 13.5307 18.747 13.6102 18.9465 13.6102C19.146 13.6102 19.3373 13.5307 19.478 13.3894L18.9465 12.8602ZM24.4617 8.38376C24.7539 8.09022 24.7529 7.61535 24.4593 7.3231C24.1658 7.03086 23.6909 7.0319 23.3987 7.32544L24.4617 8.38376ZM14.4944 7.32544C14.2021 7.0319 13.7273 7.03086 13.4337 7.3231C13.1402 7.61535 13.1391 8.09022 13.4314 8.38376L14.4944 7.32544ZM18.1965 0.749878V12.8602L19.6965 12.8602V0.749878L18.1965 0.749878ZM19.478 13.3894L24.4617 8.38376L23.3987 7.32544L18.415 12.331L19.478 13.3894ZM19.478 12.331L14.4944 7.32544L13.4314 8.38376L18.415 13.3894L19.478 12.331Z"
          fill="#15141D"
        />
      ) : null}
      <path d="M1.58398 29.9551L36.6302 29.9551" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M33.0917 24.3107L7.22953 24.3107C4.64337 24.3107 2.54688 22.2142 2.54688 19.628V19.628C2.54688 17.0419 4.64337 14.9454 7.22953 14.9454H7.85204"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.8724 18.6663L30.6611 18.6663C33.2473 18.6663 35.3438 16.5698 35.3438 13.9836V13.9836C35.3438 11.3975 33.2473 9.30096 30.6611 9.30096H30.0386"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
