import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { ecoLevelsState } from '../../../modules/channel-details/meter/current/utils';
import { IconEco2 } from '../../icons/icon-eco-2';
import './index.scss';

interface EcoBoxProps {
  level: number;
  isListItem?: boolean;
}

export const EcoBox: React.FC<EcoBoxProps> = ({ level, isListItem }) => {
  const { t } = useTranslation('dashboard');

  const ecoLevel = useMemo(() => ecoLevelsState[level], [level]);

  const style = useMemo(() => {
    return {
      '--color': ecoLevel.value,
    } as React.CSSProperties;
  }, [ecoLevel]);

  return (
    <div
      style={style}
      className={c('eco-box', {
        'eco-box--list-item': isListItem,
      })}
    >
      <div className="eco-box__icon">
        <IconEco2 big={!isListItem} />
      </div>
      <div className="eco-box__content">
        <span className="eco-box__text">{t(`${ecoLevel.heading}.1`)}</span>
        <span className="eco-box__text eco-box__text-with-background">{t(`${ecoLevel.heading}.2`)}</span>
      </div>
    </div>
  );
};
