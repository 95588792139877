import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FacadeActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { CoverButton } from '../../../../../../../components';
import { IconFacadeBlind } from '../../../../../../../components/icons';
import { defaultFavouritePositionList } from '../../../../../../../const';
import { useFacadeControl } from '../../../hooks/use-facade-control';

interface ComponentProps {
  channel: IDeviceChannel;
  position: number;
  setTargetValue: Dispatch<SetStateAction<number>>;
}

export const Favourites: React.FC<ComponentProps> = ({ channel, position, setTargetValue }) => {
  const { handleAction } = useFacadeControl(channel);

  const onFavouriteClick = useCallback(
    (favoritePositionIndex) => {
      setTargetValue(defaultFavouritePositionList[favoritePositionIndex]);
      handleAction(FacadeActionEnum.Percentage, defaultFavouritePositionList[favoritePositionIndex]);
    },
    [defaultFavouritePositionList],
  );

  return (
    <div className="favourite">
      <div className="favourite__buttons">
        {[0, 1, 2].map((index) => (
          <CoverButton
            key={index}
            lastPosition={position}
            position={defaultFavouritePositionList[index]}
            onClick={() => onFavouriteClick(index)}
          >
            <IconFacadeBlind
              size={22}
              position={defaultFavouritePositionList[index]}
              tilt={50}
              className="icon-favorite"
            />
            <span>{defaultFavouritePositionList[index]}%</span>
          </CoverButton>
        ))}
      </div>
    </div>
  );
};
