import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { NavHeader, Page } from '../../../../components';
import { CentralActionDetailsChannels } from '../../components/central-action/details-channels';
import { CentralActionDetailsControl } from '../../components/central-action/details-control';
import { CentralActionDetailsHeader } from '../../components/central-action/details-header';
import { ChannelType } from '../../enums';
import { useExalusGroup, useExalusGroupChannels } from '../../hooks';
import { getChannelType } from '../../utils';
import { CentralActionType } from '../dashboard/types';

interface LocationStateType {
  back?: number;
}

const CentralActionDetails: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const { groupId, type } = useParams<{ groupId?: string; type?: ChannelType }>();
  const { data: group } = useExalusGroup(groupId || '');
  const { data: groupChannels } = useExalusGroupChannels();

  const onBack = () => {
    if (location.state?.back) history.go(-location.state.back);
    else history.goBack();
  };

  const filteredChannels = useMemo(() => {
    if (type === ChannelType.Switch) {
      return groupChannels?.filter(
        (channel) => getChannelType(channel) === ChannelType.Switch || getChannelType(channel) === ChannelType.Light,
      );
    }
    return groupChannels?.filter((channel) => getChannelType(channel) === type);
  }, [groupChannels]);

  const centralAction: CentralActionType = useMemo(
    () => ({
      id: uuid.v4(),
      items: filteredChannels ?? [],
      type: type ?? ChannelType.Unknown,
      name: group?.Name ?? '',
      groupId: group?.Guid ?? '',
    }),
    [group, groupChannels, groupId, type],
  );

  if (!filteredChannels || !group) {
    return null;
  }

  return (
    <Page
      containerClassName="p-t-0 p-r-0 p-b-0 p-l-0"
      headerClassName="p-t-24 p-r-24 p-b-0 p-l-24"
      contentClassName={classNames('p-r-24 p-b-24 p-l-24')}
      header={<NavHeader onClick={onBack} />}
    >
      <CentralActionDetailsHeader centralAction={centralAction} />
      <CentralActionDetailsControl centralAction={centralAction} />
      <CentralActionDetailsChannels channels={filteredChannels} />
    </Page>
  );
};

export default CentralActionDetails;
