import {
  Hours,
  ITime,
  Minutes,
  Seconds,
  Time,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { ZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { FirmwareVersion } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/FirmwareService';
import { Rcz21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FloodSensor/Rcz21ConfigService';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { SbrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/SbrConfigService';
import { Slr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr21ConfigService';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { Rcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigService';
import { Rcm21Channels } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigParams';
import {
  Rcm21ConfigService,
  VersionedOptions as Rcm21VersionOptions,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { Rog21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/PowerPlugSwitch/Rog21ConfigService';
import { Rck21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/ReedSwitch/Rck21ConfigService';
import { RomConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RomSwitches/RomConfigService';
import { RopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/RopConfigService';
import { Srp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp22ConfigService';
import { Srp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp30ConfigService';
import {
  SsrConfigService,
  VersionedOptions as SsrVersionOptions,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/SsrConfigService';
import { WszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigService';
import { SensorType } from '../../../enums';
import { GlobalTimeParams } from '../types';

export const sensorTypeToRcm21 = {
  [SensorType.MeasuredBrightness]: Rcm21Channels.BrightnessChannel,
  [SensorType.Humidity]: Rcm21Channels.HuminidityChannel,
  [SensorType.Pressure]: Rcm21Channels.PressureChannel,
  [SensorType.Temperature]: Rcm21Channels.TemperatureChannel,
  [SensorType.Unknown]: Rcm21Channels.Unknow,
};

export const parseFirmware = (currentFirmware: FirmwareVersion) =>
  `^${currentFirmware.Major}.${currentFirmware.Minor}.${currentFirmware.Build}.${currentFirmware.Revision}`;

export const getVersionService = (
  currentFirmware: string,
  versionedOptions: Rcm21VersionOptions | SsrVersionOptions,
) => {
  let serviceVersion = '';

  Object.entries(versionedOptions).forEach(([key]) => {
    if (currentFirmware >= key) {
      serviceVersion = key;
    }
  });

  return serviceVersion;
};

export const formatTime = (time: ITime) =>
  `${`${time.Hours}`.padStart(2, '0')}:${`${time.Minutes}`.padStart(2, '0')}:${`${time.Seconds}`.padStart(2, '0')}`;

export const prepareNewTime = (values: GlobalTimeParams) => {
  const newTime = new Time();
  newTime.Hours = parseInt(values.time.split(':')[0]) as Hours;
  newTime.Minutes = parseInt(values.time.split(':')[1]) as Minutes;
  newTime.Seconds = parseInt(values.time.split(':')[2]) as Seconds;

  return newTime;
};

export const detectionValues = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

export const sensitivityValues = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

export const deviceConfigServices = [
  Rcz21ConfigService,
  Rck21ConfigService,
  WszfBidiConfigService,
  Rcm21ConfigService,
  ZfBidiConfigService,
  Rob21ConfigService,
  Rcr21ConfigService,
  SsrConfigService,
  Rdp21ConfigService,
  Rog21ConfigService,
  RopConfigService,
  RomConfigService,
  Slr21ConfigService,
  Slr22ConfigService,
  Srp30ConfigService,
  Srp22ConfigService,
  SbrConfigService,
];
