import React from 'react';

export const IconPlay: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.021 2.94724V20.1883C1.021 21.571 2.46199 22.4819 3.71099 21.8886L18.1523 15.029C20.7069 13.8155 20.7792 10.206 18.275 8.89138L3.77833 1.28061C2.52493 0.622572 1.021 1.5316 1.021 2.94724Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
    </svg>
  );
};
