import React from 'react';
import c from 'classnames';

interface IconSwitchProps {
  isOn: boolean;
  big?: boolean;
  noState?: boolean;
}

export const IconWindFan: React.FC<IconSwitchProps> = ({ isOn = true, big, noState }) => {
  return (
    <svg
      className={c('icon-sensor icon-wind-fan', { big: big, 'no-state': noState })}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11085_102895)">
        <path
          d="M19.0007 19C19.0007 14.1708 22.8798 10.2917 27.709 10.2917C32.5382 10.2917 36.4173 14.1708 36.4173 19L19.0007 19ZM19.0007 19C19.0007 23.8292 15.1215 27.7083 10.2923 27.7083C5.46315 27.7083 1.58398 23.8292 1.58398 19L19.0007 19ZM19.0007 19C14.1715 19 10.2923 15.1208 10.2923 10.2917C10.2923 5.46251 14.1715 1.58334 19.0007 1.58334L19.0007 19ZM19.0007 19C23.8298 19 27.709 22.8792 27.709 27.7083C27.709 32.5375 23.8298 36.4167 19.0007 36.4167L19.0007 19Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        {isOn ? (
          <>
            <path
              d="M1.58944 8.38333C1.15342 6.87168 1.30781 5.19095 2.15393 3.72048C3.01856 2.21781 4.43388 1.23305 5.99643 0.874649"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M36.4183 29.0377C36.8521 30.5499 36.6953 32.2304 35.847 33.6997C34.9802 35.2011 33.5634 36.1838 32.0004 36.5399"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </>
        ) : null}
      </g>
      <defs>
        <clipPath id="clip0_11085_102895">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
