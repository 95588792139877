import React from 'react';

interface ComponentProps {
  step: 1 | 2 | 3;
}

export const GateStepIcon: React.FC<ComponentProps> = ({ step }) => {
  return (
    <>
      {step === 1 && (
        <svg className="icon" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="6.375"
            y="19.125"
            width="36.125"
            height="27.625"
            rx="8.5"
            stroke="white"
            strokeWidth="3.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.75 13.8125C12.75 7.35767 17.9827 2.125 24.4375 2.125V2.125C30.8923 2.125 36.125 7.35767 36.125 13.8125V19.125H12.75V13.8125Z"
            stroke="white"
            strokeWidth="3.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.4375 27.625V38.25"
            stroke="white"
            strokeWidth="3.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {step === 2 && (
        <svg className="icon" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M36.125 19.125V13.8125C36.125 7.35767 30.8923 2.125 24.4375 2.125C20.8685 2.125 17.6732 3.72472 15.5294 6.24623M29.75 32.9375H19.125M14.875 46.75H34C38.6944 46.75 42.5 42.9444 42.5 38.25V27.625C42.5 22.9306 38.6944 19.125 34 19.125H14.875C10.1806 19.125 6.375 22.9306 6.375 27.625V38.25C6.375 42.9444 10.1806 46.75 14.875 46.75Z"
            stroke="#15141D"
            strokeWidth="3.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {step === 3 && (
        <svg className="icon" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="6.375"
            y="19.125"
            width="36.125"
            height="27.625"
            rx="8.5"
            stroke="#15141D"
            strokeWidth="3.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.75 32.9375L19.125 32.9375"
            stroke="#15141D"
            strokeWidth="3.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.125 19.125V12.75C36.125 6.88197 40.882 2.125 46.75 2.125V2.125"
            stroke="#15141D"
            strokeWidth="3.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
