import { useTranslation } from 'react-i18next';
import {
  ControllerActivity,
  IGptChatMessage,
} from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/GptChat/IGptChat';
import { IMessage } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';

export const useChatUtils = () => {
  const { t } = useTranslation('chat');

  const getChatMessageText = <T,>(message: T, channels: IDeviceChannel[]) => {
    const names: string[] = [];
    let errors: string[] = [];

    if ((message as IGptChatMessage).ControllerActivities) {
      const map = (message as IGptChatMessage).ControllerActivities.get(ControllerActivity.ChangeDeviceState);

      if (map) {
        const ids = Array.from(map, ([key]) => key);

        ids.map((id) => {
          const foundChannel = channels.find((x) => x.ChannelId === id);
          if (foundChannel) {
            names.push(foundChannel.Name);
          }
        });
      }

      const errorsMap = (message as IGptChatMessage).Errors;

      if (errorsMap) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        errors = Array.from(errorsMap, ([key, value]) => value);
      }

      if (errors.length) return errors.join(', ');
      if (names.length) return t('actionExecuted', { names: names.join(', ') });
    }

    return (message as IMessage).MessageText;
  };

  return {
    getChatMessageText,
  };
};
