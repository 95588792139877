import React, { useMemo } from 'react';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { groupBy } from 'lodash';
import * as uuid from 'uuid';
import { CentralActionBox } from '../../../../components/central-action/box';
import { ChannelBox } from '../../../../components/channel/box';
import { DeviceChannelsBox } from '../../../../components/channel/device-channels-box';
import { useExalusStorageContext } from '../../../../context/storage';
import { ChannelType } from '../../../../enums';
import { getChannelType } from '../../../../utils';
import { CentralActionType } from '../../../dashboard/types';
import { GroupedChannel, DeviceChannelsGrouped } from '../../types';

interface ComponentProps {
  groupChannel: GroupedChannel;
}

const ChannelGroup: React.FC<ComponentProps> = ({ groupChannel }) => {
  const { isChannelsListView } = useExalusStorageContext();

  const groupCentralAction: CentralActionType | null = useMemo(() => {
    if (groupChannel.channels.length >= 2) {
      const type = getChannelType(groupChannel.channels[0]);
      if (
        type === ChannelType.Blind ||
        type === ChannelType.Switch ||
        type === ChannelType.Facade ||
        type === ChannelType.Light
      ) {
        return {
          id: uuid.v4(),
          items: groupChannel.channels,
          type,
          name: groupChannel.label,
        };
      }
    }
    return null;
  }, [groupChannel]);

  const renderDevicesChannelsGrouped = () => {
    const grouped: Record<string, IDeviceChannel[]> = groupBy(
      groupChannel.channels.filter((x) => x.GetDevice().ShouldChannelsBeGrouped),
      (x) => x.GetDevice().Guid,
    );

    return Object.entries(grouped)
      .map(([id, channels]) => {
        const deviceName = channels[0].GetDevice().Name;

        return { id, deviceName, channels: channels };
      })
      .map((deviceChannelsGrouped: DeviceChannelsGrouped) => (
        <li key={deviceChannelsGrouped.id} className="items-list__item">
          <DeviceChannelsBox deviceChannelsGrouped={deviceChannelsGrouped} isListItem={isChannelsListView} />
        </li>
      ));
  };

  const renderDevicesChannelsNotGrouped = () => {
    return groupChannel.channels
      .filter((x) => !x.GetDevice().ShouldChannelsBeGrouped)
      .map((channel: IDeviceChannel) => (
        <li key={channel.ChannelId} className="items-list__item">
          <ChannelBox channel={channel} isListItem={isChannelsListView} />
        </li>
      ));
  };

  return (
    <div
      className={classNames('p-b-24 dashboard__device-list', {
        'grid grid--2 grid--small-gap': !isChannelsListView,
        'grid-list-16': isChannelsListView,
      })}
    >
      {groupCentralAction && <CentralActionBox isListItem={isChannelsListView} centralAction={groupCentralAction} />}
      {renderDevicesChannelsNotGrouped()}
      {renderDevicesChannelsGrouped()}
    </div>
  );
};

export default ChannelGroup;
