import React, { ChangeEvent } from 'react';
import c from 'classnames';
import { IpAddress } from '../../modules/exalus/pages/network/types';
import { InputIp } from '../input';
import './index.scss';

interface ComponentProps {
  value: IpAddress;
  label: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const AddressIp: React.FC<ComponentProps> = ({ value, label, className, handleChange }) => {
  return (
    <>
      <label className={c('input__label', className)}>{label}</label>
      <div className="address-ip">
        <InputIp name="first" value={value.first} onChange={handleChange} />
        <InputIp name="second" value={value.second} onChange={handleChange} />
        <InputIp name="third" value={value.third} onChange={handleChange} />
        <InputIp name="fourth" value={value.fourth} onChange={handleChange} />
      </div>
    </>
  );
};

export default AddressIp;
