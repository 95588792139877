import React, { useEffect, useState } from 'react';
import * as uuid from 'uuid';
import { hsbToRgb, rgbToHsb } from '../../../../../../utils/helpers';
import { RGBWStateTask } from '../../../../../exalus/pages/action-create/types';
import { RgbwChannelSettings } from '../../../../components/channel-settings/rgbw';
import { useLavvaCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, SceneTaskType, Task } from '../../../../types';

export const RGBWSettings: React.FC = () => {
  const { channels, taskVariant } = useLavvaCreateActionContext();
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();
  const [sliderColor, setSliderColor] = useState('');
  const [activeColor, setActiveColor] = useState('');
  const [rgbValue, setRgbValue] = useState('');

  useEffect(() => {
    const R = 49;
    const G = 255;
    const B = 67;
    const hsb = rgbToHsb([R.toString(), G.toString(), B.toString()]);
    setRgbValue(`${R.toString()},${G.toString()},${B.toString()}`);
    setSliderColor(hsbToRgb([hsb[0], hsb[1] / 100, 1]).join(','));
    setActiveColor(hsbToRgb([hsb[0], hsb[1] / 100, 100 / 100]).join(','));
  }, []);

  useEffect(() => {
    if (task) {
      const params = (task.taskParams as DeviceTaskParams).state as RGBWStateTask;
      const hsb = rgbToHsb([params.R.toString(), params.G.toString(), params.B.toString()]);
      setRgbValue(`${params.R.toString()},${params.G.toString()},${params.B.toString()}`);
      setSliderColor(hsbToRgb([hsb[0], hsb[1] / 100, 1]).join(','));
      setActiveColor(hsbToRgb([hsb[0], hsb[1] / 100, 100 / 100]).join(','));
    }
  }, [task]);

  const handleColorPicked = (hue, saturation) => {
    const rgb = hsbToRgb([hue, saturation, 1]).join(',');

    setRgbValue(rgb);
    setActiveColor(rgb);
    setSliderColor(hsbToRgb([hue, saturation, 1]).join(','));
  };

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const values = rgbValue.split(',');
      const R = parseInt(values[0]);
      const G = parseInt(values[1]);
      const B = parseInt(values[2]);

      const task: Task = {
        id: uuid.v4(),
        taskType: SceneTaskType.ChannelState,
        taskParams: {
          state: {
            R,
            G,
            B,
          } as RGBWStateTask,
          channel: channels[i],
          variant: taskVariant,
        } as DeviceTaskParams,
      };

      finalizeDeviceTaskSubmit(task, i === channels.length - 1);
    }
  };

  return (
    <RgbwChannelSettings
      rgbValue={rgbValue}
      activeColor={activeColor}
      sliderColor={sliderColor}
      handleColorPicked={handleColorPicked}
      handleSubmit={handleSubmit}
    />
  );
};
