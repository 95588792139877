import React from 'react';

export const IconExalusRemoteForBlinds: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.580078" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <rect
      x="17.4121"
      y="15.375"
      width="23.3333"
      height="26.25"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4961 15.375L43.6628 15.375"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4961 41.625L43.6628 41.625"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4141 31.417H40.7474"
      stroke="#15141D"
      strokeWidth="1.64062"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4141 37.25H40.7474"
      stroke="#15141D"
      strokeWidth="1.64062"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4141 25.583H40.7474"
      stroke="#15141D"
      strokeWidth="1.64062"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4141 19.75H40.7474"
      stroke="#15141D"
      strokeWidth="1.64062"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="25" y="17.6143" width="20.8653" height="27.3857" rx="2.28214" fill="white" />
    <path
      d="M42.0365 42.7179H28.827C27.9733 42.7179 27.2812 42.0258 27.2812 41.1721V21.4423C27.2812 20.5886 27.9733 19.8965 28.827 19.8965H42.0365C42.8902 19.8965 43.5823 20.5886 43.5823 21.4423V41.1721C43.5823 42.0258 42.8902 42.7179 42.0365 42.7179Z"
      stroke="#15141D"
      strokeWidth="1.36929"
    />
    <path
      d="M34.5259 23.201L31.5776 24.7569C30.2062 25.4807 30.7168 27.5717 32.265 27.5717H39.0781C40.6263 27.5717 41.1369 25.4807 39.7655 24.7569L36.8172 23.201C36.0997 22.8223 35.2434 22.8223 34.5259 23.201Z"
      stroke="#15141D"
      strokeWidth="1.36929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="30.9727" y="29.5859" width="9.39706" height="3.35609" rx="1.67805" stroke="black" strokeWidth="1.36929" />
    <path
      d="M36.8172 39.3257L39.7655 37.7698C41.1369 37.0461 40.6263 34.9551 39.0781 34.9551H32.265C30.7168 34.9551 30.2062 37.0461 31.5776 37.7698L34.5259 39.3257C35.2434 39.7044 36.0997 39.7044 36.8172 39.3257Z"
      stroke="#15141D"
      strokeWidth="1.36929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
