import React from 'react';

export const IconSocket: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-socket"
  >
    <path d="M6.72656 4H31.272" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M34 6.72754V31.273" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M31.272 34H6.72656" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 31.273V6.72754" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.9995 28.545C24.2713 28.545 28.545 24.2713 28.545 18.9995C28.545 13.7277 24.2713 9.4541 18.9995 9.4541C13.7277 9.4541 9.4541 13.7277 9.4541 18.9995C9.4541 24.2713 13.7277 28.545 18.9995 28.545Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.9092 19H14.91" stroke="#15141D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.9092 19H22.91" stroke="#15141D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4 6.72727C4 5.21977 5.21977 4 6.72727 4"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0007 6.72727C34.0007 5.21977 32.7809 4 31.2734 4"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 31.2725C4 32.78 5.21977 33.9997 6.72727 33.9997"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0007 31.2725C34.0007 32.78 32.7809 33.9997 31.2734 33.9997"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
