import React, { useMemo } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { EnergyMeasurementParameter } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { SwitchControlButtons } from '../../../../control-buttons/switch';
import { useMeterControl } from '../../../hooks/use-meter-control';
import { useSwitchControl } from '../../../hooks/use-switch-control';

interface ComponentProps {
  channel: IDeviceChannel;
  isListItem?: boolean;
}

const SwitchControls: React.FC<ComponentProps> = ({ channel, isListItem }) => {
  const { turnOn, turnOff } = useSwitchControl(channel);
  const { state } = useMeterControl(channel);

  const power = useMemo(() => state?.Data.MeasurementParameters.get(EnergyMeasurementParameter.ActivePower), [state]);

  return <SwitchControlButtons turnOn={turnOn} turnOff={turnOff} isListItem={isListItem} power={power} />;
};

export default SwitchControls;
