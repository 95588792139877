import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  BlindRemoteButtonState,
  BlindRemoteButtonStateEnum,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { IconChevron } from '../../../../../../components';

interface ComponentProps {
  close: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void> | void;
  stop: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void> | void;
  open: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void> | void;
  remoteButtonState?: IChannelState<BlindRemoteButtonState>;
  active?: boolean;
  isOpened?: boolean;
  isClosed?: boolean;
  isStopped?: boolean;
}

export const BlindDetailsSimpleControlButtons: React.FC<ComponentProps> = ({
  close,
  stop,
  open,
  remoteButtonState,
  active,
  isOpened,
  isClosed,
  isStopped,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('on-off-control on-off-control--detail on-off-control--blind')}>
      <div className={classNames('on-off-control__bg-element', { active: active })} />
      <div className="on-off-control__btn-wrapper">
        <div className="on-off-control__divider" />
        <button
          onClick={close}
          className={classNames('on-off-control__btn on-off-control__btn--off', {
            'on-off-control__btn--active':
              remoteButtonState?.Data.State === BlindRemoteButtonStateEnum.ClosePressed || isClosed,
          })}
        >
          <IconChevron withCircle direction="down" size={40} />
        </button>
        <button
          onClick={open}
          className={classNames('on-off-control__btn on-off-control__btn--on', {
            'on-off-control__btn--active':
              remoteButtonState?.Data.State === BlindRemoteButtonStateEnum.OpenPressed || isOpened,
          })}
        >
          <IconChevron withCircle direction="up" size={40} />
        </button>
      </div>
      <div className="on-off-control__btn-wrapper">
        <button
          onClick={stop}
          className={classNames('on-off-control__btn on-off-control__btn--stop', {
            'on-off-control__btn--active':
              remoteButtonState?.Data.State === BlindRemoteButtonStateEnum.StopPressed || isStopped,
          })}
        >
          {t('status.stop').toUpperCase()}
        </button>
      </div>
    </div>
  );
};
