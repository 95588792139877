import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Header,
  IconInfinity,
  IconLimit,
  IconWatch,
  NavHeader,
  Page,
  SelectOptionInterface,
  SubmitButton,
} from '../../../components';
import RadiosGroup from '../../../components/radios-group';
import { ModifyShareInfoChannelInput, ShareInfoModificationType } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { SharedItemType, SharingType } from '../../../types';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import { useModify } from '../hooks/use-modify';
import './index.scss';

const ShareRestriction: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const {
    state,
    hasInstallation,
    methods: { handleSetShareAccess },
  } = useInstallationShare();
  const { modyfyGroupAccess, modyfyChannelAccess, modifyUserAccessesLoading } = useModify();
  const { refetchSharingInfo } = useMember();

  const permissions: SelectOptionInterface<SharingType>[] = useMemo(
    () => [
      {
        value: SharingType.Permanent,
        label: t('share_path.access_permanent'),
        subLabel: t('share_path.unlimited_info'),
        icon: <IconInfinity />,
      },
      {
        value: SharingType.Timed,
        label: t('share_path.access_timed'),
        subLabel: t('share_path.time_info'),
        icon: <IconWatch />,
      },
    ],
    [state.subject],
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    handleSetShareAccess(target.value as any);
  };

  const handleNext = () => {
    switch (state.access) {
      case SharingType.Permanent: {
        handleSetShareAccess(SharingType.Permanent);
        switch (state.shareInfoModificationType) {
          case ShareInfoModificationType.Undefined: {
            history.push(ROUTES.InstallationSummaryShare());
            break;
          }
          case ShareInfoModificationType.Modify: {
            if (state.subject === SharedItemType.Group) {
              const groups: ModifyShareInfoChannelInput[] = [
                { id: state.editedId, shareInfoModificationType: ShareInfoModificationType.Modify },
              ];
              modyfyGroupAccess(state.userId, groups, () => {
                refetchSharingInfo();
                history.go(-1);
              });
            } else {
              const channels: ModifyShareInfoChannelInput[] = [
                { id: state.editedId, shareInfoModificationType: ShareInfoModificationType.Modify },
              ];
              modyfyChannelAccess(state.userId, channels, () => {
                refetchSharingInfo();
                history.go(-1);
              });
            }
            break;
          }
          case ShareInfoModificationType.Add: {
            if (!hasInstallation) {
              history.push(ROUTES.InstallationSummaryShare());
            } else {
              if (state.subject === SharedItemType.Group) {
                const groups: ModifyShareInfoChannelInput[] = state.groups.map((g) => ({
                  id: g.id,
                  shareInfoModificationType: ShareInfoModificationType.Add,
                }));
                modyfyGroupAccess(state.userId, groups, () => {
                  refetchSharingInfo();
                  history.go(-2);
                });
              } else {
                const channels: ModifyShareInfoChannelInput[] = state.channels.map((d) => ({
                  id: d.id,
                  shareInfoModificationType: ShareInfoModificationType.Add,
                }));
                modyfyChannelAccess(state.userId, channels, () => {
                  refetchSharingInfo();
                  history.go(-2);
                });
              }
            }
            break;
          }
          default:
            break;
        }
        break;
      }
      case SharingType.Timed: {
        handleSetShareAccess(SharingType.Timed);
        history.push(ROUTES.InstallationShareAccessTime());
        break;
      }
      case SharingType.Quantitative: {
        handleSetShareAccess(SharingType.Quantitative);
        history.push(ROUTES.InstallationShareAccessCount());
        break;
      }
      default:
        break;
    }
  };

  const submitBtn = useMemo(() => {
    if (
      (state.shareInfoModificationType === ShareInfoModificationType.Modify &&
        state.access === SharingType.Permanent) ||
      (state.shareInfoModificationType === ShareInfoModificationType.Add &&
        state.access === SharingType.Permanent &&
        [SharedItemType.Group, SharedItemType.Channel].includes(state.subject) &&
        hasInstallation)
    ) {
      return { title: tc('buttons.save'), loading: modifyUserAccessesLoading };
    }

    return { title: tc('buttons.next'), loading: false };
  }, [state.access, state.shareInfoModificationType, modifyUserAccessesLoading, hasInstallation]);

  return (
    <Page
      className="share-restriction"
      header={
        <>
          <NavHeader />
          <Header title={t('share_path.access_restriction')} isUnderline />
        </>
      }
    >
      <div className="max-width-desktop">
        <RadiosGroup
          options={
            state.subject === SharedItemType.Channel
              ? [
                  ...permissions,
                  {
                    value: SharingType.Quantitative,
                    label: t('share_path.access_quantitative'),
                    subLabel: t('share_path.quantitative_info'),
                    icon: <IconLimit />,
                  },
                ]
              : [...permissions]
          }
          value={state.access}
          onChange={handleChange}
          withImage
          subLabelFontSize={12}
        />
      </div>
      <SubmitButton
        type="button"
        onClick={handleNext}
        isLoading={submitBtn.loading}
        disabled={state.access === SharingType.Undefined}
      >
        {submitBtn.title}
      </SubmitButton>
    </Page>
  );
};

export default ShareRestriction;
