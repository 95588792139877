import { InstallationPermissions, UserPermissionType } from '../../data-access/gql-types/graphql';

const mapUserPermissions = (userPermissions: UserPermissionType[]) => {
  return userPermissions.reduce((acc, permission) => {
    acc[permission] = true;
    return acc;
  }, {});
};

export const mapPermissions = (permissions: InstallationPermissions[]) => {
  return permissions.reduce((acc, permission) => {
    acc[permission?.installationId] = mapUserPermissions(permission.userPermissions);
    return acc;
  }, {});
};
