import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import AddressIp from '../../../../../components/address-ip';
import { useStaticSettings } from '../hooks/use-static-settings';

const ExalusNetworkStaticSettings: React.FC = () => {
  const { t } = useTranslation('installation');
  const { handleSubmit, handleChange, ipAddress, subnetMask, gateway, broadcast, submitDisabled } = useStaticSettings();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalusNetwork.staticSettings')} isUnderline />
        </>
      }
    >
      <AddressIp value={ipAddress} label={t('exalusNetwork.ipAddress')} handleChange={(e) => handleChange(e, 'ip')} />
      <AddressIp
        value={subnetMask}
        label={t('exalusNetwork.subnetMask')}
        handleChange={(e) => handleChange(e, 'mask')}
        className="m-t-24"
      />
      <AddressIp
        value={gateway}
        label={t('exalusNetwork.defaultGateway')}
        handleChange={(e) => handleChange(e, 'gateway')}
        className="m-t-24"
      />
      <AddressIp
        value={broadcast}
        label={t('exalusNetwork.broadcast')}
        handleChange={(e) => handleChange(e, 'broadcast')}
        className="m-t-24"
      />
      <SubmitButton type="button" onClick={handleSubmit} disabled={submitDisabled} />
    </Page>
  );
};

export default ExalusNetworkStaticSettings;
