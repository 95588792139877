import {
  MovementDetectorParams,
  PirSensorParams,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigParams';

export enum Rcr21ParamsEnum {
  RIP_SENSOR = 'RipSensorParams',
  MOVEMENT_DETECTOR = 'MovementDetectorParams',
}

export interface Rcr21ConfigParams {
  [Rcr21ParamsEnum.RIP_SENSOR]: PirSensorParams | null;
  [Rcr21ParamsEnum.MOVEMENT_DETECTOR]: MovementDetectorParams | null;
}

export const initialConfig: Rcr21ConfigParams = {
  [Rcr21ParamsEnum.RIP_SENSOR]: null,
  [Rcr21ParamsEnum.MOVEMENT_DETECTOR]: null,
};
