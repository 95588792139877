import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { uniq } from 'lodash';
import { TabInterface } from '../../../../../components';
import { ChannelType } from '../../../enums';
import { useExalusChannels } from '../../../hooks';
import { canChannelReturnAny, getChannelType } from '../../../utils';
import { GroupedChannel } from '../types';

export const useChannels = () => {
  const { t: tc } = useTranslation('common');
  const { data: channels, isFetched } = useExalusChannels();

  const sortedChannelTypes = [
    ChannelType.Blind,
    ChannelType.Facade,
    ChannelType.Switch,
    ChannelType.Light,
    ChannelType.Camera,
    ChannelType.Meter,
    ChannelType.EntryPhone,
    ChannelType.Gate,
    ChannelType.Sensor,
    ChannelType.Transmitter,
    ChannelType.Remote,
    ChannelType.Unknown,
  ];

  const typeList = useMemo(() => {
    return channels?.length ? uniq(channels?.map((channel) => channel && getChannelType(channel))) : [];
  }, [channels]);

  const groupedChannels: GroupedChannel[] = useMemo(() => {
    return typeList
      .sort(
        (a: string, b: string) =>
          sortedChannelTypes.findIndex((f) => f === a) - sortedChannelTypes.findIndex((f) => f === b),
      )
      .map((type: string) => ({
        label: tc(`typesPlural.exalus.channel.${type}`),
        type,
        channels:
          channels?.filter((channel: IDeviceChannel) => {
            const channelType = getChannelType(channel);

            if (
              channelType === ChannelType.Blind &&
              type === ChannelType.Remote &&
              canChannelReturnAny(channel, [ChannelResponseType.BlindRemoteButtonState])
            ) {
              return !channel.IsHidden;
            }

            return channelType === type && !channel.IsHidden;
          }) || [],
      }))
      .filter((x) => x.channels.length > 0);
  }, [typeList, channels]);

  const tabs: TabInterface[] = useMemo(
    () => groupedChannels.map((group) => ({ label: group.label })),
    [groupedChannels],
  );

  return {
    groupedChannels,
    isFetched: isFetched && !!channels,
    channels,
    typeList,
    tabs,
  };
};
