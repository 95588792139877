import React from 'react';

export const IconModuleDevice: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-module-device"
  >
    <path
      d="M30.9019 12.7402L28.6587 13.3811C28.6006 13.3976 28.5606 13.4507 28.5606 13.5111V25.1684C28.5606 25.2225 28.5929 25.2714 28.6426 25.2927L30.918 26.2679C30.9677 26.2892 31 26.3381 31 26.3922V34.5314C31 34.6061 30.9394 34.6667 30.8647 34.6667H20.3323C20.2575 34.6667 20.197 34.6061 20.197 34.5314V30.9686C20.197 30.8939 20.1364 30.8333 20.0617 30.8333H13.7111C13.6363 30.8333 13.5758 30.7728 13.5758 30.698V27.1353C13.5758 27.0606 13.5152 27 13.4405 27H8.13529C8.06057 27 8 26.9394 8 26.8647V11.802C8 11.7272 8.06057 11.6667 8.13529 11.6667H13.4405C13.5152 11.6667 13.5758 11.6061 13.5758 11.5314V7.96863C13.5758 7.89391 13.6363 7.83333 13.7111 7.83333H20.0617C20.1364 7.83333 20.197 7.77276 20.197 7.69804V4.13529C20.197 4.06057 20.2575 4 20.3323 4H30.8647C30.9394 4 31 4.06057 31 4.13529V12.6101C31 12.6705 30.96 12.7236 30.9019 12.7402Z"
      stroke="#15141D"
      strokeWidth="1.5"
    />
  </svg>
);
