const CustomizedTick = (props) => {
  const { x, y, payload, className } = props;

  return (
    <g className="recharts-layer recharts-cartesian-axis-tick">
      <text
        height="150"
        orientation="bottom"
        width="252"
        x={x}
        y={y}
        stroke="none"
        fill="#666"
        transform={`rotate(90, ${x}, ${y})`}
        className="recharts-text recharts-cartesian-axis-tick-value"
        textAnchor="start"
      >
        <tspan x={x} dy="0.71em" {...(className ? { className } : {})}>
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export default CustomizedTick;
