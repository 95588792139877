import React from 'react';
import { ChannelInterface, GateKindParam } from '../../../../types';
import { GateActionControlBox } from './action';
import { GateChannelControlBox } from './channel';

interface CommonInterface {
  isListItem?: boolean;
}

interface ChannelParams extends CommonInterface {
  kind: GateKindParam.Channel;
  channel: ChannelInterface;
}

interface ActionParams extends CommonInterface {
  kind: GateKindParam.Action;
  channels: ChannelInterface[];
}

type PropsInterface = ChannelParams | ActionParams;

export const GateControlBox: React.FC<PropsInterface> = (props) => {
  return props.kind === GateKindParam.Channel ? (
    <GateChannelControlBox isListItem={props.isListItem} channel={props.channel} />
  ) : (
    <GateActionControlBox isListItem={props.isListItem} channels={props.channels} />
  );
};
