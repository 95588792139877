import { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  ChannelOnOffState,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { TurnOff, TurnOn } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useNative } from '../../../../../hooks';
import { SwitchControlHook } from '../types';

export const useSwitchControl = (channel: IDeviceChannel): SwitchControlHook => {
  const { fireVibration } = useNative();

  const turnOn = async () => {
    fireVibration();
    return await channel.ExecuteTaskAsync(new TurnOn());
  };

  const turnOff = async () => {
    fireVibration();
    return await channel.ExecuteTaskAsync(new TurnOff());
  };

  const lightState = useMemo(() => {
    return channel.States?.find((state) => {
      return state.TypeAsEnum === ChannelResponseType.ChannelOnOffState;
    }) as IChannelState<ChannelOnOffState>;
  }, [channel.States]);

  const isOn = useMemo(() => {
    return lightState !== undefined && !(lightState as IChannelState<ChannelOnOffState>)?.Data.State;
  }, [lightState]);

  return {
    turnOn,
    turnOff,
    isOn,
  };
};
