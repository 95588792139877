import React from 'react';

export const IconExalusBlindMotor: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.290039" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23108_24181)">
      <path
        d="M23.2664 31.7346V37.8591H19.2675V26.7584M23.2664 31.7346V21.7822M23.2664 31.7346V31.7346C24.1237 33.5871 26.7427 33.6267 27.6555 31.801L27.6887 31.7346M27.6887 31.7346V21.7822M27.6887 31.7346V34.863H69.0967V33.0267H69.6842V30.7314M27.6887 21.7822V18.566H69.0967V20.1728H69.6842V22.6977M27.6887 21.7822L27.663 21.7222C26.8271 19.7717 24.0488 19.8096 23.2664 21.7822V21.7822M23.2664 21.7822V15.6577H19.2675V26.7584M69.6842 22.6977H73.6227V30.7314H69.6842M69.6842 22.6977V30.7314M19.2675 26.7584C17.4174 28.3239 14.6564 30.5157 14.998 25.0507C15.4249 18.2195 9.84039 11.3712 4.29004 14.3599"
        stroke="#15141D"
        strokeWidth="1.28085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23108_24181">
        <rect width="38" height="38" fill="white" transform="translate(9.29004 9.85938)" />
      </clipPath>
    </defs>
  </svg>
);
