import React from 'react';

export const IconGateEntry: React.FC = () => {
  return (
    <svg
      className="icon-gate-entry"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5281 10.3289C28.5281 8.47993 27.0315 6.9834 25.1826 6.9834"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8735 10.3296C31.8735 6.63118 28.881 3.63867 25.1826 3.63867"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_21844_234397)">
        <path
          d="M6.75586 12.3694L12.7142 8.84375L12.7142 27.1771L6.75586 27.1771L6.75586 12.3694Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 10.6768L10 26.7184"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.1738 12.3694L18.2155 8.84375L18.2155 27.1771L24.1738 27.1771L24.1738 12.3694Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.001 10.6768L21.001 27.1768"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75674 17.3057H5.41699"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5136 17.3057H24.1738"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75674 22.2412H5.41699"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5136 22.2412H24.1738"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_21844_234397">
          <rect width="22" height="22" fill="white" transform="translate(4.5 7.01074)" />
        </clipPath>
      </defs>
    </svg>
  );
};
