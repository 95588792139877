import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  CustomBackdrop,
  Header,
  IconInitial,
  Input,
  NavHeader,
  Page,
  SelectOptionInterface,
  SubmitButton,
} from '../../../components';
import RadiosGroup from '../../../components/radios-group';
import { InstallationAccessType } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { SharedItemType } from '../../../types';
import { toastError } from '../../../utils/toast';
import { patterns } from '../../../utils/validation';
import { Member } from '../context/types';
import { useInstallationShare } from '../hooks/use-installation-share';
import './index.scss';

const ShareEmail: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('installation');
  const {
    state,
    sharingInfosloading,
    members,
    shareInfos,
    installationOwner,
    methods: { handleSetUserId, handleSetShareEmail },
  } = useInstallationShare();
  const [email, setEmail] = useState<string>('');
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const [emailValidation, setEmailValidation] = useState<boolean>(false);

  useEffect(() => {
    setEmail(state.email);
  }, [state.email]);

  const handleNext = () => {
    const finalMail = (email || selectedEmail).trim();
    if (!finalMail.match(patterns.email)) {
      setEmailValidation(true);
      return;
    }

    const allMembers = [...members];
    if (installationOwner)
      allMembers.push({
        id: installationOwner.id,
        firstName: installationOwner.firstName,
        email: installationOwner.email,
        image: installationOwner.profileImage?.imageUrl || '',
        accessType: InstallationAccessType.Owner,
        sharedItemType: SharedItemType.Installation,
      });

    if (finalMail) {
      handleSetShareEmail(finalMail);
      if (!state.shareItem.itemId) {
        const foundMember = allMembers.find((x) => x.email === finalMail);

        if (foundMember) {
          toastError({ content: t('email_already_shared') });
          return;
        }

        handleSetUserId('');
        history.push(ROUTES.InstallationShareSubject());
      } else {
        const foundMember = allMembers.find((x) => x.email === finalMail);
        if (foundMember) handleSetUserId(foundMember.id);

        if (state.subject === SharedItemType.Group) {
          if (
            shareInfos.find(
              (x) => x.sharedToUser?.email === finalMail && x.groups.find((x) => x.id === state.shareItem.itemId),
            )
          ) {
            toastError({ content: t('email_already_shared') });
            return;
          }
        } else if (state.subject === SharedItemType.Channel) {
          if (
            shareInfos.find(
              (x) => x.sharedToUser?.email === finalMail && x.channels.find((x) => x.id === state.shareItem.itemId),
            )
          ) {
            toastError({ content: t('email_already_shared') });
            return;
          }
        }

        history.push(ROUTES.InstallationShareRestriction());
      }
    }
  };

  const onChangeSelectedEmail = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setEmail('');
    setSelectedEmail(target.value);
    if (emailValidation) setEmailValidation(false);
    const found = membersOptions.find((x) => x.value === target.value);

    if (found) handleSetUserId(found.id || '');
  };

  const onChangeEmail = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setEmail(target.value);
    setSelectedEmail('');
    handleSetUserId('');
    if (emailValidation) setEmailValidation(false);
  };

  const membersOptions: SelectOptionInterface<string>[] = useMemo(() => {
    let filteredMembers: Member[] = [];
    if (state.subject === SharedItemType.Group) {
      filteredMembers = [...members];
      if (shareInfos.length > 0) {
        filteredMembers = [...members]
          .filter((x) => x.sharedItemType === SharedItemType.Group)
          .filter(
            (member) =>
              !shareInfos.some(
                (sharedForMember) =>
                  member.id === sharedForMember.sharedToUserId &&
                  (sharedForMember.groups.find((x) => x.id === state.shareItem.itemId) ||
                    sharedForMember.installation.wholeInstallationShare),
              ),
          );
      }
    }
    if (state.subject === SharedItemType.Channel) {
      filteredMembers = [...members];
      if (shareInfos.length > 0) {
        filteredMembers = [...members]
          .filter((x) => x.sharedItemType === SharedItemType.Channel)
          .filter(
            (member) =>
              !shareInfos.some(
                (sharedForMember) =>
                  member.id === sharedForMember.sharedToUserId &&
                  (sharedForMember.channels.find((x) => x.id === state.shareItem.itemId) ||
                    sharedForMember.installation.wholeInstallationShare),
              ),
          );
      }
    }
    return filteredMembers
      .filter((x) => x.accessType !== InstallationAccessType.Owner)
      .map((member) => ({
        id: member.id,
        label: member.firstName,
        subLabel: member.email,
        value: member.email,
        icon: <IconInitial initial={member.firstName.charAt(0)} />,
        image: member.image,
      }));
  }, [members, state.subject, shareInfos]);

  const memberList = useMemo(
    () => (
      <RadiosGroup
        options={membersOptions}
        value={selectedEmail}
        onChange={onChangeSelectedEmail}
        withImage
        textEllipsis
        transparent
      />
    ),
    [selectedEmail, members, state.subject],
  );

  return (
    <Page
      className="share-installation-email"
      header={
        <>
          <NavHeader />
          <Header title={`${t('share_path.share_email')}?`} isUnderline column>
            <div className="share-installation-email--info">
              {t(`share_path.share_email_info_${state.subject.toLowerCase()}`)}
            </div>
          </Header>
        </>
      }
    >
      <form onSubmit={handleNext}>
        <Input
          defaultValue={email}
          placeholder={t('share_path.email')}
          onChange={onChangeEmail}
          label={t('share_path.email')}
          autoCapitalize="none"
          {...(emailValidation ? { errorMessage: tc('invalidEmail') } : {})}
        />
        {sharingInfosloading ? (
          <CustomBackdrop loading={sharingInfosloading} />
        ) : (
          <>
            {(state.subject === SharedItemType.Group || state.subject === SharedItemType.Channel) &&
              membersOptions.length > 0 && (
                <>
                  <div className="share-installation-email--info">{t('share_path.or_select_user')}</div>
                  {memberList}
                </>
              )}
            <SubmitButton onClick={handleNext} type="button" disabled={!email && !selectedEmail}>
              {tc('buttons.next')}
            </SubmitButton>
          </>
        )}
      </form>
    </Page>
  );
};

export default ShareEmail;
