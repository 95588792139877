import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../components';
import { LocationViews } from '../../types';
import { useLocationContext } from '../location/context/location.context';

interface ComponentProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: () => void;
}

const RemoveLocation: React.FC<ComponentProps> = ({ show, setShow, onConfirm }) => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { goTo } = useLocationContext();

  const onClose = () => setShow(false);

  const goLearn = () => goTo(LocationViews.LEARN_REMOVE_LOCATION);

  return (
    <DialogConfirmation
      show={show}
      setShow={onClose}
      header={t('locationRemovePopup')}
      secondaryBtnText={tc('buttons.cancel')}
      primaryBtnText={t('locationRemove')}
      secondaryBtnAction={onClose}
      primaryBtnAction={onConfirm}
      direction="column"
    >
      <>
        {t('locationRemoveMessage')}
        <span onClick={goLearn}>{t('learnMore')}.</span>
      </>
    </DialogConfirmation>
  );
};

export default RemoveLocation;
