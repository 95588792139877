import React from 'react';

export const IconUser: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.1582 27.4216C6.1582 23.322 9.48164 19.9985 13.5813 19.9985L26.4216 19.9985C30.5212 19.9985 33.8447 23.322 33.8447 27.4216V33.6035C33.8447 34.3722 33.2215 34.9954 32.4528 34.9954H7.55003C6.78135 34.9954 6.1582 34.3722 6.1582 33.6035L6.1582 27.4216Z"
      stroke="#15141D"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="20.0023"
      cy="12.4984"
      rx="7.49842"
      ry="7.49841"
      stroke="#15141D"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
