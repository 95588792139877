import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Checkbox, CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../components';
import { ROUTES } from '../../../routes';
import { ShareGroup } from '../context/types';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import './index.scss';

interface GroupShareItem {
  id: string;
  name: string;
  checked: boolean;
}

const GroupsShare: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('groups');
  const [shareGroups, setShareGroups] = useState<GroupShareItem[]>([]);
  const { groups, groupsLoading, methods } = useInstallationShare();
  const { userSharing } = useMember(true);

  useEffect(() => {
    let items: GroupShareItem[] = [];

    if (groups) {
      items = groups
        .filter((x) => x.name !== 'Favourites')
        .map((group: ShareGroup) => ({ id: group.id, name: group.name, checked: false }));
    }

    if (userSharing?.sharingInfo?.groups) {
      items = items.filter((group) => !userSharing.sharingInfo?.groups.some((userGroup) => group.id === userGroup.id));
    }

    setShareGroups(items);
  }, [groups, userSharing]);

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedGroups = [...shareGroups];
    const groupIndex = updatedGroups.findIndex((x) => x.id === e.target.value);

    if (groupIndex !== -1) {
      updatedGroups[groupIndex].checked = !updatedGroups[groupIndex].checked;
      setShareGroups(updatedGroups);
    }
  };

  const groupList = useMemo(() => {
    return shareGroups.length > 0 ? (
      shareGroups.map((group) => (
        <div key={group.id} className="share-group-item">
          <p>{group.name}</p>
          <Checkbox id={group.id} checked={group.checked} onChange={handleToggle} />
        </div>
      ))
    ) : (
      <p className="share-groups-empty">{t('shareGroupsEmpty')}</p>
    );
  }, [shareGroups]);

  const handleNext = () => {
    const filteredGroups: ShareGroup[] = shareGroups
      .filter((g) => g.checked)
      .map((group) => ({ id: group.id, name: group.name }));
    methods.handleUpdateGroups(filteredGroups);
    history.push(ROUTES.InstallationShareRestriction());
  };

  return (
    <Page
      className="share-groups"
      header={
        <>
          <NavHeader />
          <Header title={t('shareGroupsSelect')} isUnderline />
        </>
      }
    >
      <div className="max-width-desktop">
        <div className="share-groups-info">{t('shareGroupsSelectInfo')}</div>
        {!groupsLoading ? groupList : <CustomBackdrop loading={groupsLoading} />}
      </div>
      <SubmitButton type="button" onClick={handleNext} disabled={shareGroups.length === 0}>
        {tc('buttons.next')}
      </SubmitButton>
    </Page>
  );
};

export default GroupsShare;
