import React from 'react';

export const IconBlocks: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-blocks"
  >
    <path
      d="M18.1107 19.4657H4.84849C4.1005 19.4657 3.49414 20.0721 3.49414 20.8201V34.0822C3.49414 34.8302 4.1005 35.4366 4.84849 35.4366H18.1107C18.8587 35.4366 19.465 34.8302 19.465 34.0822V20.8201C19.465 20.0721 18.8587 19.4657 18.1107 19.4657Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4805 19V5.13895C11.4805 4.23133 12.2156 3.49478 13.1246 3.49478H25.8086C26.7162 3.49478 27.4528 4.22992 27.4528 5.13895V19"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8516 19.4657H33.7914C34.699 19.4657 35.4355 20.2008 35.4355 21.1099V33.7938C35.4355 34.7015 34.7004 35.438 33.7914 35.438H19"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6872 30.5093C14.4051 29.7978 15.2209 27.8283 14.5093 26.1105C13.7977 24.3926 11.8283 23.5768 10.1105 24.2884C8.3926 25 7.57683 26.9694 8.28839 28.6872C8.99995 30.4051 10.9694 31.2209 12.6872 30.5093Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9536 8.32015L16.0868 13.285C15.8404 13.7119 16.1479 14.2447 16.6407 14.2447H22.3742C22.867 14.2447 23.1745 13.7119 22.9281 13.285L20.0613 8.32015C19.815 7.89328 19.199 7.89328 18.9536 8.32015Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
