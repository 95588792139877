import React from 'react';

export const IconDesk: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon desk"
  >
    <path
      d="M35.4948 8.5036H3.50195C2.94967 8.5036 2.50195 8.95132 2.50195 9.5036V10.5944C2.50195 11.1467 2.94967 11.5944 3.50195 11.5944H35.4948C36.047 11.5944 36.4948 11.1467 36.4948 10.5944V9.5036C36.4948 8.95132 36.047 8.5036 35.4948 8.5036Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M4.04883 11.5944V28.362C4.04883 28.9142 4.49654 29.362 5.04883 29.362H13.8636C14.4159 29.362 14.8636 28.9142 14.8636 28.362V11.5944"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M4.04883 23.1834H14.8636" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M28.7695 23.1834H34.9512" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M7.13867 26.2712H11.7718" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M4.04883 17.0018H14.8636" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M7.13867 20.0927H11.7718" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M34.2012 30.1362C34.2012 30.5505 34.537 30.8862 34.9512 30.8862C35.3654 30.8862 35.7012 30.5505 35.7012 30.1362H34.2012ZM34.2012 11.5944V30.1362H35.7012V11.5944H34.2012Z"
      fill="#15141D"
    />
  </svg>
);
