import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IconAdd, NavHeader, NoInstallation, Page, PageStartFlow, SubmitButton } from '../../../components';
import { pathnameWithParameters } from '../../../utils/location';
import { ScheduleListItem } from '../components/schedule-list-item';
import { useOptimizerScheduleContext } from '../context';
import './index.scss';

export const OptimizerScheduleStep0: React.FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const { channelId } = useParams<{ channelId: string }>();
  const { schedules } = useOptimizerScheduleContext();

  const handleAdd = () => {
    history.push(pathnameWithParameters(pathname, search, { key: 'step', value: 1 }));
  };

  const handleSave = () => {
    console.log('SEND SCHEDULE REQUEST', { channelId, schedules });
    alert('TODO');
  };

  return (
    <>
      {!schedules.length ? (
        <PageStartFlow
          headerTitle={t('schedule.label')}
          title={t('schedule.createOwn')}
          subTitile={t('schedule.createMessage')}
          icon={<NoInstallation />}
          handleStart={handleAdd}
        />
      ) : (
        <Page
          isStickyHeader
          header={
            <NavHeader title={t('schedule.label')}>
              <button onClick={handleAdd}>
                <IconAdd />
              </button>
            </NavHeader>
          }
        >
          <div className="schedule-list">
            {schedules.map((schedule) => (
              <ScheduleListItem key={schedule.id} schedule={schedule} />
            ))}
          </div>
          <SubmitButton onClick={handleSave}>{tc('buttons.save')}</SubmitButton>
        </Page>
      )}
    </>
  );
};
