import React from 'react';
import { ChannelTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { useCover } from '../../../../../hooks';
import { ChannelInterface } from '../../../../../types';
import { RollerControls } from '../../../../roller-controls';

interface PropsInterface {
  channel: ChannelInterface;
  isListItem?: boolean;
}

export const CoverChannelControlBox: React.FC<PropsInterface> = ({ channel, isListItem }) => {
  const { handleAction } = useCover(channel);

  const handleCoverAction = (type: string) => handleAction(type);

  return <RollerControls handleAction={handleCoverAction} isListItem={isListItem} type={ChannelTypeInternal.Blind} />;
};
