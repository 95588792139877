import React from 'react';

export const IconSport: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="40"
      height="33"
      viewBox="0 0 40 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.63393 3.61769L8.49739 1M9.63393 3.61769L10.9807 6.35603C11.5843 7.58341 12.7903 8.40165 14.1538 8.50898L17.2161 8.75L17.7991 5.36968L21.2589 3.61769C22.0487 5.0507 23.137 6.93254 24.3728 8.93598M9.63393 3.61769L7.14286 6.77128M11.9543 16.9328L5.33658 11.9537C4.38194 11.2355 3.03795 11.3595 2.23091 12.2404V12.2404C1.30399 13.2521 1.45278 14.8429 2.55125 15.6652L19.518 28.3659C22.6695 30.725 26.5004 32 30.4371 32H34.0323C35.861 32 37.8683 31.3329 38.6185 29.6652V29.6652C39.2089 28.3526 39.3619 27.0148 38.7839 25.6967C38.1812 24.3221 36.8559 22.6622 34.0603 21.1376C32.8793 20.4936 31.3926 18.926 29.8303 16.9328M29.8303 16.9328L25.7567 19.736M29.8303 16.9328C28.9441 15.8022 28.0335 14.5347 27.1406 13.2211M29.8303 16.9328L27.1406 13.2211M27.1406 13.2211L23.3348 15.5312M27.1406 13.2211C26.1802 11.8083 25.2401 10.3422 24.3728 8.93598M24.3728 8.93598L20.567 11.3265"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
