import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { canOnlyChannelReturnAny } from '../../../../utils';
import { useChannels } from '../../../channel-list/hooks/use-channels';
import { useExalusCreateActionContext } from '../../context';
import { useActionConditions } from '../../hooks/use-action-conditions';
import { ConditionType } from '../../types';
import { deviceSources, scheduleSources, scenarioSources } from '../../utils/action-sources';
import './index.scss';

const ExalusActionSources: React.FC = () => {
  const { t } = useTranslation('action');
  const { conditionType, allowedArguments } = useExalusCreateActionContext();
  const { selectConditionSourceType } = useActionConditions();
  const { channels } = useChannels();

  const filteresDeviceSources = useMemo(() => {
    if (conditionType === ConditionType.Devices) {
      return deviceSources.filter(
        (src) => channels?.filter((ch) => canOnlyChannelReturnAny(ch, src.deviceResponses)).length,
      );
    }

    return deviceSources;
  }, [conditionType, channels]);

  const renderCurrentView = () => {
    switch (conditionType) {
      case ConditionType.Devices: {
        return filteresDeviceSources.map((x) => (
          <ArrowButton
            key={x.title}
            title={t(x.title)}
            icon={x.icon}
            className="m-b-24"
            onClick={() => selectConditionSourceType(x.sourceType)}
          />
        ));
      }
      case ConditionType.Schedule: {
        return scheduleSources
          .filter((x) => {
            if (!allowedArguments.length) return true;
            return allowedArguments.find((a) => a.ArgumentType === x.allowType);
          })
          .map((x) => (
            <ArrowButton
              key={x.title}
              title={t(x.title)}
              icon={x.icon}
              className="m-b-24"
              onClick={() => selectConditionSourceType(x.sourceType)}
            />
          ));
      }
      case ConditionType.Scenarios: {
        return scenarioSources.map((x) => (
          <ArrowButton
            key={x.title}
            title={t(x.title)}
            icon={x.icon}
            className="m-b-24"
            onClick={() => selectConditionSourceType(x.sourceType)}
          />
        ));
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Page
      isStickyHeader
      className="exalus-action-sources"
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.conditions.sources.header')} />
        </>
      }
    >
      <div className="grid-list-24">{renderCurrentView()}</div>
    </Page>
  );
};

export default ExalusActionSources;
