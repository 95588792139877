import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { INetworkService } from 'lavva.exalushome/build/js/INetworkService';
import { useBackdropContext } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import { useHandleDataFrameErrors } from '../../../hooks';

export const useNetworkSettings = () => {
  const { t } = useTranslation('installation');
  const { handleError } = useHandleDataFrameErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const resetToDefault = async (onSuccess: Dispatch<SetStateAction<boolean>>) => {
    turnOnBackdrop();
    const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
    const result: Status = await networkService.RestoreDefaultConfigurationAsync();

    if (result === Status.OK) {
      toastSuccess({ content: t('exalusNetwork.resetToDefaultSuccess') });
      onSuccess(false);
    } else {
      handleError(result);
    }

    turnOffBackdrop();
  };

  const activateDHCP = async (onSuccess: Dispatch<SetStateAction<boolean>>) => {
    turnOnBackdrop();
    const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
    const result: Status = await networkService.EnableDhcpAsync();

    if (result === Status.OK) {
      toastSuccess({ content: t('exalusNetwork.DHCPactivated') });
      onSuccess(false);
    } else {
      handleError(result);
    }

    turnOffBackdrop();
  };

  return {
    resetToDefault,
    activateDHCP,
  };
};
