import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { CustomBackdrop, Header, NavHeader, Page } from '../../../../../components';
import { useExalusUser, useExalusUserImg } from '../../../hooks';
import ExalusFormCreate from '../components/form-create-edit';
import { useLoggedUser } from '../../../hooks/common/logged-user';

const ExalusUserEdit: React.FC = () => {
  const { t } = useTranslation('profile');
  const { userId } = useParams<{ userId: string }>();
  const { data: user, isLoading: userLoading } = useExalusUser(userId !== 'me' ? userId : '');
  const { data: image, isLoading: imageLoading } = useExalusUserImg({ Guid: userId } as IUser);
  const { user: loggedUser } = useLoggedUser();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.editUser')} isUnderline />
        </>
      }
    >
      <>
        <ExalusFormCreate user={user || loggedUser} image={image} />
        <CustomBackdrop loading={userLoading || imageLoading} />
      </>
    </Page>
  );
};

export default ExalusUserEdit;
