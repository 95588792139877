import React from 'react';

export const IconExalusRemoteForFacadeBlinds: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.869141" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <path
      d="M43.7435 15.2344H14.0566V18.1075H43.7435V15.2344Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2474 21.8906C17.2474 21.8906 16.2915 22.4219 15.9727 23.4845H20.3204H42.0983C41.7794 22.4219 40.8236 21.8906 40.8236 21.8906"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2474 27.999C17.2474 27.999 16.2915 28.5072 15.9727 29.5236H20.3204H42.0983C41.7794 28.5072 40.8236 27.999 40.8236 27.999"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2474 34.0381C17.2474 34.0381 16.2915 34.5463 15.9727 35.5627H20.3204H42.0983C41.7794 34.5463 40.8236 34.0381 40.8236 34.0381"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2474 40.0771C17.2474 40.0771 16.2915 40.5622 15.9727 41.5324H20.3204H42.0983C41.7794 40.5622 40.8236 40.0771 40.8236 40.0771"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3027 18.1074V20.0228"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3027 18.1074V41.4843"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.8027 18.1074V41.3263"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="25" y="17.6143" width="20.8653" height="27.3857" rx="2.28214" fill="white" />
    <path
      d="M42.0365 42.7179H28.827C27.9733 42.7179 27.2812 42.0258 27.2812 41.1721V21.4423C27.2812 20.5886 27.9733 19.8965 28.827 19.8965H42.0365C42.8902 19.8965 43.5823 20.5886 43.5823 21.4423V41.1721C43.5823 42.0258 42.8902 42.7179 42.0365 42.7179Z"
      stroke="#15141D"
      strokeWidth="1.36929"
    />
    <path
      d="M34.5259 23.201L31.5776 24.7569C30.2062 25.4807 30.7168 27.5717 32.265 27.5717H39.0781C40.6263 27.5717 41.1369 25.4807 39.7655 24.7569L36.8172 23.201C36.0997 22.8223 35.2434 22.8223 34.5259 23.201Z"
      stroke="#15141D"
      strokeWidth="1.36929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="30.9727" y="29.5859" width="9.39706" height="3.35609" rx="1.67805" stroke="black" strokeWidth="1.36929" />
    <path
      d="M36.8172 39.3257L39.7655 37.7698C41.1369 37.0461 40.6263 34.9551 39.0781 34.9551H32.265C30.7168 34.9551 30.2062 37.0461 31.5776 37.7698L34.5259 39.3257C35.2434 39.7044 36.0997 39.7044 36.8172 39.3257Z"
      stroke="#15141D"
      strokeWidth="1.36929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
