import {
  ScalarParameterTypeInternal,
  VectorParameterTypeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { EcoLevel, EcoState } from '../types';

export const ecoLevels: EcoLevel[] = [
  {
    value: '#489B58',
    rgbValue: '72,155,88',
    name: 'eco',
    maxValue: 20,
  },
  {
    value: '#55B36E',
    rgbValue: '85,179,110',
    name: 'frugal',
    maxValue: 40,
  },
  {
    value: '#DEB342',
    rgbValue: '222,179,66',
    name: 'correct',
    maxValue: 60,
  },
  {
    value: '#D3352A',
    rgbValue: '211,53,42',
    name: 'elevated',
    maxValue: 80,
  },
  {
    value: '#9F271F',
    rgbValue: '159,39,31',
    name: 'high',
    maxValue: 100,
  },
];

export const ecoLevelsState: EcoState[] = [
  {
    value: '#00B191',
    heading: 'ecoState.low',
    maxValue: 40,
    iconCount: [1, 2, 3],
  },
  {
    value: '#DEB342',
    heading: 'ecoState.medium',
    maxValue: 60,
    iconCount: [1, 2],
  },
  {
    value: '#9F271F',
    heading: 'ecoState.high',
    maxValue: 100,
    iconCount: [1],
  },
];

export const getParameterAverageValue = (parameterType: VectorParameterTypeInternal) => {
  switch (parameterType) {
    case VectorParameterTypeInternal.ActivePower:
      return ScalarParameterTypeInternal.AverageActivePower;
    case VectorParameterTypeInternal.Current:
      return ScalarParameterTypeInternal.AverageCurrent;
    case VectorParameterTypeInternal.Voltage:
      return ScalarParameterTypeInternal.AverageVoltage;
    default:
      return null;
  }
};

export const getParameterMaxValue = (parameterType: VectorParameterTypeInternal) => {
  switch (parameterType) {
    case VectorParameterTypeInternal.ActivePower:
      return ScalarParameterTypeInternal.MaxActivePower;
    case VectorParameterTypeInternal.Current:
      return ScalarParameterTypeInternal.MaxCurrent;
    case VectorParameterTypeInternal.Voltage:
      return ScalarParameterTypeInternal.MaxVoltage;
    default:
      return null;
  }
};

export const getParameterMinValue = (parameterType: VectorParameterTypeInternal) => {
  switch (parameterType) {
    case VectorParameterTypeInternal.ActivePower:
      return ScalarParameterTypeInternal.MinActivePower;
    case VectorParameterTypeInternal.Current:
      return ScalarParameterTypeInternal.MinCurrent;
    case VectorParameterTypeInternal.Voltage:
      return ScalarParameterTypeInternal.MinVoltage;
    default:
      return null;
  }
};
