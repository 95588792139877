import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PHASES_COLORS } from '../../../../../const';
import { VectorParameterTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { SelectedPhase } from '../../types';
import './index.scss';
import { Phases } from './phases';

interface PropsInterface {
  onSelectedPhases: (options: SelectedPhase[]) => void;
  defaultPhases: number[];
  readonly?: boolean;
  singleChoice?: boolean;
  className?: string;
  itemPrefix?: string;
  selectedParameter?: string;
}

export const PhasesButtonGroup: React.FC<PropsInterface> = ({
  onSelectedPhases,
  defaultPhases,
  readonly = false,
  singleChoice = false,
  className,
  itemPrefix,
  selectedParameter,
}) => {
  const { t } = useTranslation('channel-details');
  const [selectedPhases, setSelectedPhases] = useState<number[]>(readonly ? defaultPhases : []);

  useEffect(() => {
    if (selectedParameter === VectorParameterTypeInternal.Voltage && defaultPhases.length > 1) {
      setSelectedPhases([1]);
    }

    if (
      selectedParameter === VectorParameterTypeInternal.ActivePower ||
      (selectedParameter === VectorParameterTypeInternal.Current && defaultPhases.length > 0)
    ) {
      setSelectedPhases([0]);
    }
  }, [selectedParameter, defaultPhases]);

  useEffect(() => {
    if (!readonly) {
      if (
        defaultPhases &&
        (!selectedPhases.length || (selectedPhases.length && !defaultPhases.includes(selectedPhases[0])))
      ) {
        setSelectedPhases([defaultPhases[0]]);
      }
    }
  }, [defaultPhases]);

  const mappedPhases: SelectedPhase[] = useMemo(() => {
    return defaultPhases?.map((phaseNumber) => {
      const label = !phaseNumber ? t('sumOfPhases') : `${itemPrefix ?? t('phase')} ${phaseNumber}`;
      return {
        value: phaseNumber,
        label,
        color: PHASES_COLORS[phaseNumber],
      };
    });
  }, [defaultPhases]);

  useEffect(() => {
    if (!selectedPhases.length && defaultPhases?.length) {
      setSelectedPhases([mappedPhases[0].value]);
    }

    const data: SelectedPhase[] = selectedPhases.map((phaseNumber) => {
      return {
        value: phaseNumber,
        label: !phaseNumber ? t('sumOfPhases') : `${itemPrefix} ${phaseNumber}`,
        color: mappedPhases?.find((option) => option.value === phaseNumber)?.color || '',
      };
    });
    onSelectedPhases(data);
  }, [selectedPhases]);

  const setActivePhases = useCallback(
    (value: number) => {
      if (singleChoice) {
        setSelectedPhases(() => [value]);
      } else {
        if (value === 0) {
          setSelectedPhases(() => [value]);
        } else {
          const isValue = selectedPhases.includes(value);
          if (!isValue) {
            setSelectedPhases((phases) => [...phases.filter((phase) => phase !== 0), value]);
          } else if (isValue) {
            setSelectedPhases((phases) => phases.filter((phase) => phase !== value));
          }
        }
      }
    },
    [selectedPhases],
  );

  if (!defaultPhases || defaultPhases.length === 0) return null;

  return (
    <Phases
      mappedPhases={mappedPhases}
      selectedPhases={selectedPhases}
      className={className}
      onClickPhase={(val) => {
        if (!readonly) setActivePhases(val);
      }}
    />
  );
};
