import React, { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  DeviceType,
  IRemoteDevice,
  RemoteMode as RemoteModeType,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RemoteService/RemoteParams';
import {
  CustomBackdrop,
  DialogConfirmation,
  EmptyStateBox,
  Header,
  IconAdd,
  IconWarning,
  InputSelect,
  NavHeader,
  Page,
  PopUpNav,
  SubmitButton,
} from '../../../../../../../components';
import { IconDelete, IconSettings } from '../../../../../../../components/popup-nav/icons';
import { ROUTES } from '../../../../../../../routes';
import ConfigurationInput from '../../../../device-details/components/configuration-input';
import { RemotePage, useRemotes } from '../hooks/use-remotes';
import './index.scss';

const DeviceRemotes: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const {
    channel,
    remotes,
    page,
    form,
    availableChannels,
    selectedRemote,
    remotesFetched,
    setSelectedRemote,
    submitDelete,
    submitAssign,
    finishConfiguration,
  } = useRemotes();

  const { Channel, KeyOn, KeyOff, KeyBrightening, KeyDimmer } = form.watch();

  const assignRemotes = useCallback(() => {
    history.push(`${ROUTES.DeviceRemotes(channel?.ChannelId)}?page=${RemotePage.Available}`);
  }, [channel?.ChannelId]);

  const remoteConfig = (r: IRemoteDevice, config: RemotePage) => {
    setSelectedRemote(r);
    const parameters = r.CurrentRemotePairingOptions[r.DeviceType].ModeParams[r.RemoteMode];

    if (config === RemotePage.ConfigAdd && !parameters) {
      submitAssign(r, -1);
    } else {
      history.push(`${ROUTES.DeviceRemotes(channel?.ChannelId)}?page=${config}`);
    }
  };

  const onChangeSelect = (key: string, value: string) => {
    form.setValue(key, parseInt(value));
  };

  const handleDelete = (r: IRemoteDevice) => {
    setSelectedRemote(r);
    setDeletePopup(true);
  };

  return (
    <Page
      isStickyHeader
      className="remotes"
      header={
        <>
          <NavHeader />
          <Header title={t(`exalus.params.RemotesParams.${page}Header`)} isUnderline />
        </>
      }
    >
      <>
        {page !== RemotePage.ConfigAdd && page !== RemotePage.ConfigEdit ? (
          <div className="grid-list-24">
            {remotes.length > 0 ? (
              remotes.map((r) => (
                <div key={`${r.Guid}_${r.Channel}`} className="remote-item">
                  <p>{`${r.Name} ${
                    page !== RemotePage.Available
                      ? r.CurrentRemotePairingOptions[r.DeviceType].ModeParams[r.RemoteMode]?.Channel || ''
                      : ''
                  }`}</p>
                  {page === RemotePage.Assigned ? (
                    <PopUpNav
                      dark
                      links={[
                        ...(r.RemoteChannelAmount > 1
                          ? [
                              {
                                label: t('exalus.configuration'),
                                onClick: () => remoteConfig(r, RemotePage.ConfigEdit),
                                icon: <IconSettings />,
                              },
                            ]
                          : []),
                        { label: tc('buttons.remove'), onClick: () => handleDelete(r), icon: <IconDelete /> },
                      ]}
                    />
                  ) : (
                    <button onClick={() => remoteConfig(r, RemotePage.ConfigAdd)}>
                      <IconAdd />
                    </button>
                  )}
                </div>
              ))
            ) : (
              <>
                {remotesFetched && (
                  <EmptyStateBox content={t(`exalus.params.RemotesParams.${page}Empty`)} icon={<IconWarning />} />
                )}
              </>
            )}
            {page === RemotePage.Assigned && (
              <SubmitButton type="button" onClick={assignRemotes}>
                {t('exalus.params.RemotesParams.AssignTransmitters')}
              </SubmitButton>
            )}
            {!channel && <CustomBackdrop loading />}
          </div>
        ) : (
          <FormProvider {...form}>
            <form onSubmit={finishConfiguration}>
              {(selectedRemote?.RemoteMode === RemoteModeType.Timer ||
                selectedRemote?.RemoteMode === RemoteModeType.TimerDimmer) && (
                <ConfigurationInput
                  name="TimeOn"
                  label={t('exalus.params.RemotesParams.enablingTime')}
                  placeholder={t('exalus.params.RemotesParams.enablingTime')}
                  inputType="number"
                />
              )}
              {availableChannels && Channel !== undefined && (
                <InputSelect
                  onChange={(val) => onChangeSelect('Channel', val)}
                  value={Channel.toString()}
                  options={availableChannels}
                  label={t('exalus.params.RemotesParams.selectChannel')}
                />
              )}
              {availableChannels &&
                selectedRemote?.DeviceType !== DeviceType.Blind &&
                selectedRemote?.DeviceType !== DeviceType.Facade && (
                  <InputSelect
                    onChange={(val) => onChangeSelect('KeyOn', val)}
                    value={KeyOn?.toString()}
                    options={availableChannels}
                    label={t('exalus.params.RemotesParams.onButton')}
                  />
                )}
              {availableChannels &&
                (selectedRemote?.RemoteMode === RemoteModeType.OnOff ||
                  selectedRemote?.RemoteMode === RemoteModeType.TwoButton ||
                  selectedRemote?.RemoteMode === RemoteModeType.Comfort) && (
                  <InputSelect
                    onChange={(val) => onChangeSelect('KeyOff', val)}
                    value={KeyOff?.toString()}
                    options={availableChannels}
                    label={t('exalus.params.RemotesParams.offButton')}
                  />
                )}
              {availableChannels && selectedRemote?.RemoteMode === RemoteModeType.Comfort && (
                <>
                  <InputSelect
                    onChange={(val) => onChangeSelect('KeyBrightening', val)}
                    value={KeyBrightening?.toString()}
                    options={availableChannels}
                    label={t('exalus.params.RemotesParams.keyBrightening')}
                  />
                  <InputSelect
                    onChange={(val) => onChangeSelect('KeyDimmer', val)}
                    value={KeyDimmer?.toString()}
                    options={availableChannels}
                    label={t('exalus.params.RemotesParams.keyDimmer')}
                  />
                </>
              )}
              <SubmitButton />
            </form>
          </FormProvider>
        )}
      </>
      <DialogConfirmation
        show={deletePopup}
        setShow={setDeletePopup}
        header={t('exalus.params.RemotesParams.DeleteRemoteHeader')}
        content={t('exalus.params.RemotesParams.DeleteRemoteConfirmation')}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={() => submitDelete(setDeletePopup)}
        secondaryBtnAction={() => setDeletePopup(false)}
      />
    </Page>
  );
};

export default DeviceRemotes;
