import React from 'react';

export const IconMarkerFill: React.FC = () => (
  <svg width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 1.66675C7.97413 1.66675 5.55172 2.67015 3.76566 4.45621C1.97959 6.24227 0.976196 8.66469 0.976196 11.1906C0.976196 17.2027 4.849 22.4403 9.10202 26.9034C9.86403 27.703 11.1358 27.7029 11.9042 26.9094C16.1548 22.5199 20.0238 17.629 20.0238 11.1906C20.0238 9.93987 19.7775 8.70144 19.2989 7.54595C18.8202 6.39047 18.1187 5.34057 17.2344 4.45621C16.35 3.57184 15.3001 2.87032 14.1446 2.3917C12.9891 1.91309 11.7507 1.66675 10.5 1.66675Z"
      fill="#15141D"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <ellipse
      cx="10.5"
      cy="11.1905"
      rx="4.44445"
      ry="4.44444"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="bevel"
    />
  </svg>
);
