import { useMemo } from 'react';
import { DeviceTasksInfo } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel, TaskExecution } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { useExalusDevicesContext } from '../../../context/devices';

export const useTasks = (channel?: IDeviceChannel) => {
  const { deviceTasks } = useExalusDevicesContext();
  const channelNr = channel?.Number;
  const deviceId = channel?.GetDevice().Guid;

  const active = useMemo(() => {
    return !!deviceTasks
      .filter((x) => x.Status === TaskExecution.ExecutingTasks)
      .find((x: DeviceTasksInfo) => x.Channel === channelNr && x.DeviceGuid === deviceId);
  }, [channelNr, deviceId, deviceTasks]);

  return {
    active,
  };
};
