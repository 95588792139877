import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { StateHistoryErrorCode } from 'lavva.exalushome/build/js/Services/StatesHistory/IStatesHistoryService';
import { BrightnessHistory, StateDataResponse } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ControlWrapper, DatePicker, TimeRanges } from '../../../../../../components';
import { useBackdropContext, useInstallation } from '../../../../../../hooks';
import { useExalusServicesContext } from '../../../../context/services';
import { useHistoryState } from '../../hooks/use-history-state';
import ChartPagination from '../components/chart-pagination';
import ChartSummary from '../components/chart-summary';
import CustomizedXTick from '../components/customized-x-tick';
import LegendCustom from '../components/legend';
import { useHistoryCharts } from '../hooks/use-history-charts';
import { stateInterval } from '../types';

interface ComponentProps {
  channel: IDeviceChannel;
  active?: boolean;
}

const limit = 744;

const BrightnessChart: React.FC<ComponentProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const [state, setState] = useState<StateDataResponse<BrightnessHistory> | null>(null);
  const { turnOnBackdrop, turnOffBackdrop, backdropOpen } = useBackdropContext();
  const { historyStatesApi } = useExalusServicesContext();
  const { handleHistoryError } = useHistoryState(channel);
  const { selectedInstallation } = useInstallation();
  const { offSet, setOffSet, activeRange, onClickActiveMeasurementRange, formatDate } = useHistoryCharts();

  useEffect(() => {
    const getStates = async () => {
      turnOnBackdrop();
      const result: StateDataResponse<BrightnessHistory> | ResponseResult<StateHistoryErrorCode> =
        await historyStatesApi.GetStatesByIntervalAsync<BrightnessHistory>(
          channel.GetDevice(),
          channel.Number,
          DeviceResponseType.MeasuredBrightness,
          stateInterval[activeRange],
          limit,
          offSet,
          true,
        );

      if ((result as ResponseResult<StateHistoryErrorCode>).Type) {
        handleHistoryError(result as ResponseResult<StateHistoryErrorCode>);
      } else {
        setState(result as StateDataResponse<BrightnessHistory>);
        turnOffBackdrop();
      }
    };

    getStates();
  }, [activeRange, offSet]);

  const data = useMemo(() => {
    return state?.Data.reverse().map((x) => ({
      date: formatDate(x.Time),
      [t('exalus.luxValue')]: x.Values?.LuxValue,
    }));
  }, [state]);

  const aggregatedData = useMemo(() => {
    return state?.AggregateDataList.find((x) => x.DataEntryIdentity === 'IBrightness')?.AggregateData;
  }, [state?.AggregateDataList]);

  return (
    <>
      <ControlWrapper className={classNames('control-wrapper--full-space no-center', { active: active })}>
        <div className="exalus-chart-container">
          <TimeRanges
            activeMeasurementRange={activeRange}
            setActiveMeasurementRange={onClickActiveMeasurementRange}
            disableYear
          />
          <DatePicker
            showDateButtons={false}
            activeDay={new Date()}
            activeMeasurementRange={activeRange}
            setActiveDay={() => null}
          />
          {data && data.length > 0 ? (
            <div className="binary-chart">
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  width={500}
                  height={250}
                  data={data}
                  margin={{
                    top: 15,
                    right: 20,
                    left: 5,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    dataKey="date"
                    tick={<CustomizedXTick />}
                    height={44}
                    interval="preserveStartEnd"
                    allowDuplicatedCategory={false}
                  />
                  <YAxis dataKey={t('exalus.luxValue')} unit=" lx" domain={['dataMin', 'dataMax + 1']} />
                  <Tooltip />
                  <Legend wrapperStyle={{ bottom: -20 }} content={<LegendCustom />} />
                  <Area
                    type="monotone"
                    dataKey={t('exalus.luxValue')}
                    stroke={selectedInstallation?.hexColor}
                    fill={selectedInstallation?.hexColor}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <ChartPagination
                offSet={offSet}
                limit={limit}
                setOffSet={setOffSet}
                isNextAvailable={!!state?.IsNextPageAvailable}
              />
            </div>
          ) : (
            <>{!backdropOpen && <p className="empty-states">{t('chartEmptyState.header')}</p>}</>
          )}
        </div>
      </ControlWrapper>
      {aggregatedData && <ChartSummary activeRange={activeRange} aggregateData={aggregatedData} unit="lx" />}
    </>
  );
};

export default BrightnessChart;
