import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  onClick?: () => void;
  className?: string;
}

export const IconInfo: React.FC<PropsInterface> = ({ onClick, className }) => {
  return (
    <div
      className={classNames('icon-info', { clickable: onClick, [className as string]: className })}
      onClick={onClick}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.89394 2.5C7.73232 2.5 7.64141 2.50528 7.62121 2.51583C7.26768 2.57914 6.98485 2.74269 6.77273 3.00648C6.64141 3.1753 6.55051 3.35468 6.5 3.5446C6.4899 3.60791 6.48485 3.71343 6.48485 3.86115C6.48485 4.08273 6.52525 4.27794 6.60606 4.44676C6.77778 4.81607 7.06566 5.06403 7.4697 5.19065C7.5505 5.21175 7.67677 5.2223 7.84848 5.2223C8.0101 5.2223 8.13636 5.21175 8.22727 5.19065C8.62121 5.06403 8.90404 4.81607 9.07576 4.44676C9.16667 4.25683 9.21212 4.06163 9.21212 3.86115C9.21212 3.67122 9.16667 3.47602 9.07576 3.27554C8.90404 2.91679 8.62121 2.6741 8.22727 2.54748C8.17677 2.53693 8.06566 2.5211 7.89394 2.5ZM8.9697 6.3777C9.0101 6.3777 8.52525 6.3988 7.51515 6.44101L6.09091 6.50432H6.04545V7.0741V7.64388H6.22727C6.4899 7.64388 6.69192 7.66499 6.83333 7.70719C6.92424 7.7283 6.9899 7.80216 7.0303 7.92878C7.0404 7.97098 7.04545 8.72542 7.04545 10.1921V12.3604H6.5303H6V12.9302V13.5H6.13636L7.16667 13.4683C7.76263 13.4578 8.33838 13.4578 8.89394 13.4683L9.86364 13.5H10V12.9302V12.3604H9.5303H9.06061V9.36907V6.3777H8.9697Z"
          fill="#FF4016"
        />
      </svg>
    </div>
  );
};
