import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { DialogConfirmation, Header, IconAdd, NavHeader, Page } from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';
import { useImportExport } from '../hooks/use-import-export';
import './index.scss';

const ImportExportConfiguration: React.FC = () => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const {
    handleImport,
    handleExport,
    setDialogImport,
    setDialogExport,
    handleDownload,
    handleOpenImport,
    onChangeFile,
    exportHref,
    dialogImport,
    dialogExport,
    file,
    dialogTitle,
    loading,
  } = useImportExport(true);

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.importExport')} isUnderline />
        </>
      }
    >
      <div className="grid-list-24">
        <ArrowButton title={t('exalus.exportConfiguration')} onClick={handleExport} className="m-b-24" />
        <ArrowButton title={t('exalus.importConfiguration')} onClick={handleOpenImport} className="m-b-24" />
      </div>
      <DialogConfirmation
        show={dialogExport}
        setShow={setDialogExport}
        header={dialogTitle}
        primaryBtnText={t('exalus.download')}
        primaryBtnAction={handleDownload}
        primaryBtnDisabled={!exportHref}
        secondaryBtnText={tc('buttons.cancel')}
        secondaryBtnAction={() => setDialogExport(false)}
        {...(loading ? { headerIcon: <CircularProgress color="inherit" size={20} /> } : {})}
      />
      <DialogConfirmation
        show={dialogImport}
        setShow={setDialogImport}
        header={t('exalus.importing')}
        primaryBtnText={t('exalus.import')}
        primaryBtnAction={handleImport}
        primaryBtnDisabled={!file}
        contentClassName="import-export"
        secondaryBtnText={tc('buttons.cancel')}
        secondaryBtnAction={() => setDialogImport(false)}
      >
        <label className="import-area">
          <input type="file" onChange={onChangeFile} hidden accept=".cexp, .bak" />
          <IconAdd />
          <p>{!file ? t('exalus.addFile') : file.name}</p>
        </label>
      </DialogConfirmation>
    </Page>
  );
};

export default ImportExportConfiguration;
