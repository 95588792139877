import React from 'react';
import c from 'classnames';

interface IconSwitchProps {
  isOn: boolean;
  big?: boolean;
  noState?: boolean;
}

export const IconBigDrop: React.FC<IconSwitchProps> = ({ isOn = true, big, noState }) => {
  return (
    <svg
      className={c('icon-sensor icon-big-drop', { big: big, 'no-state': noState })}
      width="30"
      height="38"
      viewBox="0 0 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8.89041e-06L15.5519 0.49527C15.5519 0.495285 15.5519 0.4953 15.5519 0.495315L15 8.89041e-06ZM14.6816 0.62906C14.6815 0.628783 14.685 0.630956 14.6924 0.636409C14.6854 0.632064 14.6817 0.629337 14.6816 0.62906ZM15.1011 1.02021C15.4714 1.3981 16.0058 1.99066 16.6547 2.75545C17.9767 4.31364 19.7442 6.55165 21.512 9.05904C23.2814 11.5685 25.0401 14.3322 26.3534 16.9444C27.6794 19.582 28.5 21.9672 28.5 23.75C28.5 31.2059 22.4558 37.25 15 37.25C7.54416 37.25 1.5 31.2059 1.5 23.75C1.5 21.9701 2.31864 19.6359 3.64341 17.0621C4.9557 14.5126 6.71361 11.8232 8.4839 9.36163C10.2522 6.90272 12.0222 4.68582 13.3509 3.08284C14.0149 2.28174 14.5679 1.63491 14.9545 1.18892C15.0064 1.12902 15.0553 1.07275 15.1011 1.02021Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      {isOn ? (
        <path
          d="M22.8556 25C22.8556 26.6997 22.2732 28.2592 21.3027 29.4781M16 32.0648C17.2806 32.0648 18.4791 31.703 19.5047 31.0731"
          stroke="#15141D"
          strokeWidth="1.5"
        />
      ) : null}
    </svg>
  );
};
