import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GroupItem } from '../../components/group-item';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';

interface DeviceBoxSortableProps {
  sortedGroupId: string;
  group: IChannelsGroup;
}

export const GroupItemSortable: React.FC<DeviceBoxSortableProps> = ({ group, sortedGroupId }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id: sortedGroupId });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return (
    <GroupItem
      group={group}
      isDraggable
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
    />
  );
};
