import React from 'react';

interface ComponentProps {
  isOn: boolean;
}

export const IconPlug: React.FC<ComponentProps> = ({ isOn }) => {
  return !isOn ? (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-group icon-plug"
    >
      <g clipPath="url(#clip0_22801_24050)">
        <path
          d="M14.6256 7.37437L16.4818 9.23052M29.4749 22.2236L27.6187 20.3675M25.7626 18.5113L22.0503 22.2236M25.7626 18.5113L18.3379 11.0867M25.7626 18.5113L27.6187 20.3675M18.3379 11.0867L14.6256 14.799M18.3379 11.0867L16.4818 9.23052M16.4818 9.23052C18.9567 6.75565 24.1539 0.815953 30.0936 6.75565M30.0936 6.75565C36.0333 12.6953 29.4749 18.5113 27.6187 20.3675M30.0936 6.75565L34.4246 2.42462"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.20103 14.7985L9.05718 16.6546M22.0503 29.6477L20.1941 27.7916M9.05718 16.6546L10.9133 18.5108L18.338 25.9354L20.1941 27.7916M9.05718 16.6546C6.58231 19.1295 0.642613 24.3267 6.58231 30.2664M6.58231 30.2664C12.522 36.2061 18.338 29.6477 20.1941 27.7916M6.58231 30.2664L2.25128 34.5975"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22801_24050">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-group icon-plug"
    >
      <g clipPath="url(#clip0_22801_24063)">
        <path
          d="M12.6256 10.3744L14.4818 12.2305M27.4749 25.2236L25.6187 23.3675M23.7626 21.5113L16.3379 14.0867M23.7626 21.5113L25.6187 23.3675M23.7626 21.5113L21.9064 23.3675M16.3379 14.0867L14.4818 12.2305M16.3379 14.0867L14.4818 15.9428M14.4818 12.2305C16.9567 9.75565 22.1539 3.81595 28.0936 9.75565M28.0936 9.75565C34.0333 15.6953 27.4749 21.5113 25.6187 23.3675M28.0936 9.75565L32.4246 5.42462"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1512 12.8502L12.0074 14.7064M25.0005 27.6995L23.1443 25.8433M12.0074 14.7064L13.8635 16.5626L21.2882 23.9872L23.1443 25.8433M12.0074 14.7064C9.53251 17.1813 3.59281 22.3785 9.53251 28.3182M9.53251 28.3182C15.4722 34.2579 21.2882 27.6995 23.1443 25.8433M9.53251 28.3182L5.20148 32.6492"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.5 7.975L5.22727 0.5V5.7H8.5L3.77273 13.5L4.13636 7.975H0.5Z"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22801_24063">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
