import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LinearProgress } from '@mui/material';
import { RecoveryDeviceStatus } from '../../../../../data-access/gql-types/graphql';

interface ComponentProps {
  progress?: number;
  status?: RecoveryDeviceStatus;
}

export const RecoveryDeviceProgress: FC<ComponentProps> = ({ progress = 0, status = RecoveryDeviceStatus.Unknown }) => {
  const { t } = useTranslation('device-info');
  const [currentProgress, setCurrentProgress] = useState<number>(progress);
  const [currentStatus, setCurrentStatus] = useState<RecoveryDeviceStatus>(status);

  useEffect(() => {
    if (progress) setCurrentProgress(progress);
  }, [progress]);

  useEffect(() => {
    if (status) {
      setCurrentStatus(status);

      if (status === RecoveryDeviceStatus.FirmwareDownloaded) {
        setCurrentProgress(100);
      }
    }
  }, [status]);

  const isSuccess = useMemo(() => currentStatus === RecoveryDeviceStatus.FirmwareDownloaded, [currentStatus]);
  const isError = useMemo(() => currentStatus === RecoveryDeviceStatus.FailedToDownload, [currentStatus]);

  if (currentStatus === RecoveryDeviceStatus.Unknown || currentStatus === RecoveryDeviceStatus.FirmwareIsUpToDate) {
    return null;
  }

  return (
    <div className="linear-progress p-l-0 p-r-0">
      <div className="linear-progress--container">
        <p className={classNames('percentage', { success: isSuccess, error: isError })}>{`${currentProgress}%`}</p>
      </div>
      <LinearProgress
        className={classNames('linear-progress--line', {
          success: isSuccess,
          error: isError,
        })}
        variant="determinate"
        value={currentProgress}
      />
      <p
        className={classNames('status-message', {
          success: isSuccess,
          error: isError,
        })}
      >
        {t(`recoveryMode.status.${currentStatus}`)}
      </p>
    </div>
  );
};
