import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserGroup } from '../../../data-access/gql-types/graphql';
import { useApi, useInstallation } from '../../../hooks';
import { useRefetchData } from '../../../hooks/refresh-data';
import * as storage from '../../../utils/storage/lavva';

export const useDashboard = () => {
  const { groups } = useApi();
  const { installationList } = useInstallation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isListView, setIsListView] = useState<boolean>(storage.getItem('isListView'));
  const { refetchAll } = useRefetchData();

  useEffect(() => {
    const index = groups.findIndex((group: UserGroup) => group.id === storage.getItem('lastActiveGroupId'));
    setActiveTabIndex(index < 0 ? 0 : index);
  }, [groups, setActiveTabIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!installationList.length) refetchAll();
    }, 300);

    return () => clearTimeout(timeout);
  }, [installationList.length]);

  const activeGroup = useMemo(() => groups[activeTabIndex], [activeTabIndex, groups]);

  const toggleListView = useCallback(() => {
    storage.setItem('isListView', !isListView);
    setIsListView(!isListView);
  }, [isListView, setIsListView]);

  const handleSetActiveGroupIndex = useCallback(
    (index: number) => {
      setActiveTabIndex(index);
      storage.setItem('lastActiveGroupId', String(groups[index]?.id));
    },
    [groups],
  );

  return {
    isListView,
    activeTabIndex,
    activeGroup,
    toggleListView,
    handleSetActiveGroupIndex,
  };
};
