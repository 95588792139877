import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper } from '../../../../../../components';

interface ComponentProps {
  channel: IDeviceChannel;
}

const CameraChart: React.FC<ComponentProps> = () => {
  return <ControlWrapper className="control-wrapper--full-space no-center">COMING SOON</ControlWrapper>;
};

export default CameraChart;
