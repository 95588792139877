import { useTranslation } from 'react-i18next';
import { DeviceConnectionError } from 'lavva.webbluetooth/build/js/LavvaBluetooth';
import { toastError } from '../../../utils/toast';

export const useConnectErrors = () => {
  const { t } = useTranslation('device-add');

  const handleConnectErrors = (result: DeviceConnectionError) => {
    switch (result) {
      case DeviceConnectionError.NoDeviceHasBeenSelected: {
        toastError({ content: t('bluetooth.connectErrors.NoDeviceHasBeenSelected') });
        break;
      }
      case DeviceConnectionError.AnotherDeviceIsAlreadyConnected: {
        toastError({ content: t('bluetooth.connectErrors.AnotherDeviceIsAlreadyConnected') });
        break;
      }
      case DeviceConnectionError.FailedToConnectToGattServer: {
        toastError({ content: t('bluetooth.connectErrors.FailedToConnectToGattServer') });
        break;
      }
      case DeviceConnectionError.FailedToGetPrimaryService: {
        toastError({ content: t('bluetooth.connectErrors.FailedToGetPrimaryService') });
        break;
      }
      case DeviceConnectionError.FailedToDiscoverService: {
        toastError({ content: t('bluetooth.connectErrors.FailedToDiscoverService') });
        break;
      }
      case DeviceConnectionError.FailedToGetCharacteristic: {
        toastError({ content: t('bluetooth.connectErrors.FailedToGetCharacteristic') });
        break;
      }
      case DeviceConnectionError.FailedToStartNotifications: {
        toastError({ content: t('bluetooth.connectErrors.FailedToStartNotifications') });
        break;
      }
    }
  };

  return { handleConnectErrors };
};
