import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceState, IDeviceStateData } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';

export const updateChannelsQueryCache = (oldChannels: any, Device: IDevice, State: IDeviceState<any>) => {
  let oldChannelsTemp = [] as IDeviceChannel[];

  if (oldChannels) {
    oldChannelsTemp = [...oldChannels];

    if (oldChannelsTemp.length > 0) {
      const foundChannelIndex = oldChannelsTemp.findIndex(
        (channel: IDeviceChannel) => channel.GetDevice().Guid === Device.Guid && channel.Number === State.Data.Channel,
      );

      if (foundChannelIndex !== -1) {
        const foundStateIndex = oldChannelsTemp[foundChannelIndex].States.findIndex(
          (x) => x.TypeAsEnum === State.TypeAsEnum,
        );

        if (foundStateIndex !== -1) {
          oldChannelsTemp[foundChannelIndex].States[foundStateIndex] = State;
        } else {
          oldChannelsTemp[foundChannelIndex].States.push(State);
        }
      }
    }

    return oldChannelsTemp;
  }

  return oldChannels;
};

export const updateChannelQueryCache = (oldChannel: any, state: IDeviceState<IDeviceStateData>) => {
  let oldChannelTemp = {} as IDeviceChannel;

  if (oldChannel) {
    oldChannelTemp = oldChannel;
    const foundStateIndex = oldChannelTemp.States.findIndex((x) => x.TypeAsEnum === state.TypeAsEnum);

    if (foundStateIndex !== -1) {
      oldChannelTemp.States[foundStateIndex] = state;
    } else {
      oldChannelTemp.States.push(state);
    }
  }

  return oldChannelTemp;
};
