import { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

interface Props {
  children?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit';
  className?: string;
  isLoading?: boolean;
  tranparent?: boolean;
  buttonComponent?: ReactNode;
  aboveAll?: boolean;
  backgroundElement?: boolean;
}

export const SubmitButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      children,
      buttonComponent,
      disabled,
      onClick,
      type = 'submit',
      isLoading,
      tranparent,
      aboveAll,
      backgroundElement,
    },
    ref,
  ) => {
    const { t } = useTranslation('common');

    return (
      <div
        id="submit-button"
        className={classNames('submit-button', {
          tranparent: tranparent,
          'above-all': aboveAll,
          'submit-button--background-element': backgroundElement,
        })}
      >
        {buttonComponent || (
          <button
            onClick={onClick}
            className={classNames('button', className)}
            type={type}
            disabled={disabled || isLoading}
            ref={ref}
          >
            {isLoading ? t('buttons.loading') : children || t('buttons.save')}
          </button>
        )}
      </div>
    );
  },
);

SubmitButton.displayName = 'SubmitButton';
