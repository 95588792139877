import React from 'react';

export const IconGateEntryCircle: React.FC = () => {
  return (
    <svg
      className="icon-gate-entry-circle"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.25" stroke="white" strokeWidth="1.5" />
      <g clipPath="url(#clip0_21857_234985)">
        <path
          d="M9.08398 11.0423L13.9251 8.17773L13.9251 23.0736L9.08398 23.0736L9.08398 11.0423Z"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7188 9.66797L11.7188 22.7018"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.2334 11.0413L18.3923 8.17676L18.3923 23.0726L23.2334 23.0726L23.2334 11.0413Z"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6553 9.66699L20.6553 23.0732"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.08268 15.0518H7.99414"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3219 15.0518H23.2334"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.08268 19.0625H7.99414"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3219 19.0625H23.2334"
          stroke="#15141D"
          strokeWidth="1.21875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_21857_234985">
          <rect width="17.875" height="17.875" fill="white" transform="translate(7.25 6.6875)" />
        </clipPath>
      </defs>
    </svg>
  );
};
