import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  onClick?: () => void;
  className?: string;
  size?: number;
}

export const IconAutomatic: React.FC<PropsInterface> = ({ onClick, className, size }) => {
  return (
    <div
      className={classNames('icon-automatic', {
        clickable: onClick,
        [className as string]: className,
      })}
      onClick={onClick}
    >
      <svg
        width={size || '22'}
        height={size || '18'}
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M.629477 1.457773v5.65665h5.656649M21.370523 16.54217v-5.656648h-5.656649"
          stroke="white"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M19.004158 6.171657c-.478175-1.351194-1.290753-2.559247-2.362122-3.51144-1.071275-.952184-2.366365-1.617472-3.764311-1.933782-1.397947-.316301-2.853308-.27332-4.230127.124936-1.376866.398256-2.630399 1.138816-3.643646 2.152562L.629477 7.114423m20.741046 3.771099l-4.374475 4.110498c-1.0132 1.013766-2.266808 1.754315-3.643636 2.152543-1.376829.398323-2.83219.441313-4.230137.125012-1.397974-.316395-2.693036-.981617-3.76434-1.93382-1.071312-.952202-1.883946-2.160274-2.362093-3.511459"
          stroke="white"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  );
};
