import React from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { IPicture } from 'lavva.exalushome/build/js/Services/Pictures/IPicture';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { IconInitial, IconPhoto } from '../../../../../../components';
import './index.scss';

interface ComponentProps {
  user?: IUser | null;
  image?: IPicture | ResponseResult<Status>;
}

const ExalusUserMainInfo: React.FC<ComponentProps> = ({ user, image }) => {
  const { t } = useTranslation('profile');

  return (
    <div className="exalus-user main-info">
      <div className="left-section text-ellipsis">
        {(image as IPicture)?.Base64Image ? (
          <img height={40} width={40} src={(image as IPicture)?.Base64Image} alt={''} />
        ) : (
          <>{user ? <IconInitial initial={`${user.Name.charAt(0)}${user.Surname.charAt(0)}`} /> : <IconPhoto />}</>
        )}
        <div className="text-ellipsis user-data">
          <p className="name text-ellipsis">
            {`${user?.Name || ''} ${user?.Surname || ''}`} <span className="separator">|</span>&nbsp;
            {user?.AccessLevel && <span className="access text-ellipsis">{t(`exalus.role_${user?.AccessLevel}`)}</span>}
          </p>
          {user?.Email && <p className="email text-ellipsis">{user?.Email}</p>}
        </div>
      </div>
    </div>
  );
};

export default ExalusUserMainInfo;
