import { isBefore } from 'date-fns';
import * as duration from 'duration-fns';
import { TriggerTimeConditionInterface } from '../../../types';
import { convertHoursToMinutes, getFormatInTimeZone } from '../../../utils/helpers';
import {
  RepeatType,
  SunAction,
  SunActionModification,
  TimeEndType,
  TimeType,
} from '../condition-types/time-condition/enums';

export const getTimeType = (timeCondition: TriggerTimeConditionInterface): TimeType => {
  return timeCondition.atSunset || timeCondition.atSunrise ? TimeType.DAYTIME : TimeType.HOUR;
};

export const getSunAction = (timeCondition: TriggerTimeConditionInterface): SunAction => {
  if (timeCondition.atSunset) {
    return SunAction.SUNSET;
  }
  return SunAction.SUNRISE;
};

export const getSunModification = (timeCondition: TriggerTimeConditionInterface): SunActionModification => {
  if (timeCondition.sunriseAdvance || timeCondition.sunsetAdvance) {
    return SunActionModification.ADVANCE;
  } else if (timeCondition.sunriseDelay || timeCondition.sunsetDelay) {
    return SunActionModification.DELAY;
  }
  return SunActionModification.NONE;
};

export const getSunTimeValue = (timeCondition: TriggerTimeConditionInterface): number => {
  const durationValue =
    timeCondition.sunriseAdvance ||
    timeCondition.sunsetAdvance ||
    timeCondition.sunriseDelay ||
    timeCondition.sunsetDelay;

  if (!durationValue) return 1;

  const { hours, minutes } = duration.parse(durationValue || '');
  return convertHoursToMinutes({ hours, minutes });
};

export const getRepeatType = (timeCondition: TriggerTimeConditionInterface): RepeatType => {
  if (timeCondition.daysOfWeek?.length) {
    return RepeatType.SELECTED;
  } else if (timeCondition.everyYear) {
    return RepeatType.YEARLY;
  } else if (timeCondition.everyMonth) {
    return RepeatType.MONTHLY;
  } else if (timeCondition.targetExecutionCount === 1) {
    return RepeatType.ONCE;
  } else {
    return RepeatType.DAILY;
  }
};

export const getTimeRepeat = (timeOfDay: string | null, initialValue: string, timeZone: string): string => {
  if (!timeOfDay) {
    return initialValue;
  }
  const { hours, minutes } = duration.parse(timeOfDay);

  const timeRepeatAsDate = new Date();
  timeRepeatAsDate.setUTCHours(hours);
  timeRepeatAsDate.setUTCMinutes(minutes);

  return getFormatInTimeZone(timeRepeatAsDate, timeZone, 'HH:mm');
};

export const getTimeEndType = (timeCondition: TriggerTimeConditionInterface): TimeEndType => {
  if (timeCondition.targetExecutionCount) {
    return TimeEndType.COUNT;
  } else if (timeCondition.endAt?.date) {
    if (isBefore(new Date(timeCondition?.endAt?.date), new Date())) {
      return TimeEndType.NEVER;
    }
    return TimeEndType.DATE;
  }
  return TimeEndType.NEVER;
};

export const getDate = ({ date }: { date: string }): Date => {
  if (isBefore(new Date(date), new Date())) {
    return new Date();
  }
  return new Date(date);
};
