import React, { ReactNode } from 'react';
import { StatusTypeEnum } from '../../types';
import { StatusIcon } from '../status-icon';
import './index.scss';

interface PropsInterface {
  type: StatusTypeEnum;
  header?: string;
  caption?: string;
  children?: ReactNode;
}

export const EmptyStatePage: React.FC<PropsInterface> = ({ type, caption, header, children }) => (
  <div className="empty-state-page">
    <StatusIcon type={type} />
    <div className="empty-state-page__text">
      {header && <div className="empty-state-page__header">{header}</div>}
      {caption && <div className="empty-state-page__caption">{caption}</div>}
    </div>
    {children}
  </div>
);
