import { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { MeasuredEnergyDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { MeterControlHook } from '../types';

export const useMeterControl = (channel?: IDeviceChannel): MeterControlHook => {
  const state = useMemo(() => {
    return channel?.States?.find((state) => state.TypeAsEnum === ChannelResponseType.MeasuredEnergy) as
      | MeasuredEnergyDeviceState
      | undefined;
  }, [channel?.States]);

  return {
    state: state ?? null,
  };
};
