import { useCallback } from 'react';
import { ActivityType } from '../../../../../data-access/gql-types/graphql';
import { ParametersType } from './parameters-type';

interface useOnOffControlInterface {
  setOn: () => ParametersType;
  setOff: () => ParametersType;
}

export const useOnOffControl = (channelParameters: ParametersType): useOnOffControlInterface => {
  const removedChannelParameters = useCallback(
    (param: ActivityType) => {
      const newChannelParameters = { ...channelParameters };
      delete newChannelParameters[param];
      return newChannelParameters;
    },
    [channelParameters],
  );

  const setOn = useCallback(() => {
    const newChannelParameters = removedChannelParameters(ActivityType.Off);

    newChannelParameters[ActivityType.On] = { activityType: ActivityType.On, value: '' };
    return newChannelParameters;
  }, [channelParameters]);

  const setOff = useCallback(() => {
    const newChannelParameters = removedChannelParameters(ActivityType.On);

    newChannelParameters[ActivityType.Off] = { activityType: ActivityType.Off, value: '' };
    return newChannelParameters;
  }, [channelParameters]);

  return { setOn, setOff };
};
