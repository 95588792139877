import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconArrowLink } from '../../../../../components';
import { IconPlug } from '../../../../../components/icons-group';
import { WRONG_MEASUREMENT_VALUE } from '../../../../../const';
import {
  VectorParameterTypeInternal,
  ScalarParameterTypeInternal,
  MeasurementChannelKind,
  OptimizerVectorParameterTypeInternal,
  OptimizerScalarParameterTypeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../../routes';
import './index.scss';

interface MeasurementParameters {
  shortcut?: string;
  unit: string;
  symbol: string;
  fixed: number;
}

export enum MeasurementGroup {
  SinglePhase = 'SinglePhase',
  Absolute = 'Absolute',
}

interface PropsInterface {
  channelId: string;
  measurement:
    | VectorParameterTypeInternal
    | ScalarParameterTypeInternal
    | OptimizerVectorParameterTypeInternal
    | OptimizerScalarParameterTypeInternal;
  measurementGroup: MeasurementGroup;
  text: string;
  value: number;
  parameters: MeasurementParameters;
  kind: MeasurementChannelKind;
  phase?: number;
  tariffsQuantity?: number;
}

export const MeasurementItem: React.FC<PropsInterface> = ({
  channelId,
  measurement,
  measurementGroup,
  text,
  value,
  parameters,
  kind,
  phase,
  tariffsQuantity = 0,
}) => {
  const { t } = useTranslation('channel-details');
  const history = useHistory();

  const iconValue = useMemo(() => {
    if (measurement === OptimizerVectorParameterTypeInternal.RelayState) return 'R';
    return parameters.shortcut ?? parameters.symbol ? parameters.symbol : parameters.unit;
  }, [parameters]);

  const iconFontSize = useMemo(() => {
    switch (iconValue.length) {
      case 1:
      case 2:
        return 20;
      case 3:
        return 16;
      case 4:
        return 14;
      default:
        return 12;
    }
  }, [iconValue]);

  const relayState = useMemo(() => {
    if (measurement === OptimizerVectorParameterTypeInternal.RelayState) {
      return <IconPlug isOn={!!value} />;
    }

    return null;
  }, [value]);

  const formatedValue = useMemo(() => {
    if (kind === MeasurementChannelKind.Optimizer && measurement === OptimizerVectorParameterTypeInternal.Current) {
      return Math.abs(value).toFixed(parameters.fixed);
    }

    return value.toFixed(parameters.fixed);
  }, [parameters, value, measurement, kind]);

  const subText = useMemo(() => {
    if (
      tariffsQuantity >= 2 &&
      [
        VectorParameterTypeInternal.ForwardActiveEnergyPeriod1,
        VectorParameterTypeInternal.ForwardActiveEnergyPeriod2,
        VectorParameterTypeInternal.ForwardActiveEnergyPeriod3,
      ].includes(measurement as VectorParameterTypeInternal)
    ) {
      return t(`energyMeasurements.subLabel.${measurement}${tariffsQuantity === 2 ? '_OF_2' : ''}`);
    }
  }, [measurement, tariffsQuantity, t]);

  return (
    <>
      {value !== WRONG_MEASUREMENT_VALUE ? (
        <div
          className="measurement-item clickable"
          onClick={() =>
            history.push(
              ROUTES.MeasurementDetails(channelId, measurementGroup, measurement, kind, phase ? `${phase}` : ''),
            )
          }
        >
          <span className="measurement-item__unit-icon" style={{ fontSize: iconFontSize }}>
            {iconValue}
          </span>
          <span className="measurement-item__name">
            <span>{text}</span>
            <span className="subname">{subText}</span>
          </span>
          <div className="measurement-item__value-wrapper">
            <span className="measurement-item__value">
              {kind === MeasurementChannelKind.Optimizer &&
                parseFloat(formatedValue) !== 0 &&
                (measurement === OptimizerVectorParameterTypeInternal.Current ||
                  measurement === OptimizerVectorParameterTypeInternal.ActivePower) && (
                  <IconArrowLink size={22} direction={value < 0 ? 'up' : 'down'} />
                )}
              {measurement !== OptimizerVectorParameterTypeInternal.RelayState ? formatedValue : relayState}
            </span>
            <span className="measurement-item__unit">{parameters.unit}</span>
          </div>
        </div>
      ) : null}
    </>
  );
};
