import { useCallback, PointerEvent } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SetLightBrightness } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { DimmerControlHook } from '../types';

export const useDimmerControl = (channel: IDeviceChannel): DimmerControlHook => {
  const handleAction = useCallback(
    async (brightness?: number) => {
      const task = new SetLightBrightness();
      if (typeof brightness === 'number' && brightness >= 0) {
        task.Brightness = brightness;
      }
      await channel.ExecuteTaskAsync(task);
    },
    [channel],
  );

  const handleSliderEvent = useCallback(
    (event: PointerEvent<HTMLInputElement>) => {
      handleAction(Number((event.target as HTMLInputElement).value));
    },
    [handleAction],
  );

  return {
    handleAction,
    handleSliderEvent,
  };
};
