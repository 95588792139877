import {
  ChannelTypeInternal,
  GateChannelStateResponse,
  GateFeatureTypeInternal,
  GateKindInternal,
  GateModeInternal,
  GatePersonalization,
  UserChannel,
} from '../../../data-access/gql-types/graphql';
import {
  ChannelIconTypeEnum,
  ChannelInterface,
  ChannelStateType,
  GateChannelStateResponseCustom,
} from '../../../types';

const getGateMode = (userChannel: UserChannel) => {
  const gateChannel = userChannel.payload as GateChannelStateResponse | undefined;

  if (gateChannel) {
    if (gateChannel.supportedGateFeatures.length) {
      if (gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.StepByStep)) {
        return GateModeInternal.StepByStep;
      } else if (gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir)) {
        return GateModeInternal.RollUp;
      }

      return GateModeInternal.Unknown;
    }
  }

  return gateChannel?.gateMode || GateModeInternal.Unknown;
};

export const parseGateChannel = (userChannel: UserChannel): ChannelInterface => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.ICON_VERTICAL_GATE,
  deviceId: userChannel.deviceId,
  data: {
    type: ChannelTypeInternal.Gate,
    gateMode: getGateMode(userChannel),
    supportedGateFeatures: (userChannel.payload as GateChannelStateResponseCustom)?.supportedGateFeatures || [],
    gateKind: (userChannel.payload as GateChannelStateResponseCustom)?.gateKind || GateKindInternal.Unknown,
    sbsSignalTimeInMs: (userChannel.payload as GateChannelStateResponseCustom)?.sbsSignalTimeInMs || null,
    alertConfig: (userChannel.payload as GateChannelStateResponseCustom)?.alertConfig || null,
    history: (userChannel.payload as GateChannelStateResponseCustom)?.history || undefined,
    isQuickControl: (userChannel.personalization as GatePersonalization)?.isQuickControl || false,
    dependantChannels: (userChannel.payload as GateChannelStateResponseCustom)?.dependantChannels || [],
  },
});

export const parseGateChannelState = (userChannel: UserChannel): ChannelStateType => ({
  direction: (userChannel.payload as GateChannelStateResponseCustom)?.gateDirection,
  position: (userChannel.payload as GateChannelStateResponseCustom)?.position,
  electricStrikeStatus: (userChannel.payload as GateChannelStateResponseCustom)?.electricStrikeStatus || null,
  calibrateState: (userChannel.payload as GateChannelStateResponseCustom)?.calibrateState || undefined,
  movingParams: {
    predictedTimeInMs: null,
    targetPosition: null,
  },
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
