import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IconActions, IconRepeat } from '../../../../components';
import { ROUTES } from '../../../../routes';
import { pathnameWithParameters } from '../../../../utils/location';
import { useSelectedDays } from '../../../trigger-form/hooks/use-selected-days';
import { useScheduleValues } from '../../hooks/use-schedule-values';
import { OptimizerSchedule } from '../../types';

interface ComponentProps {
  schedule: OptimizerSchedule;
}

export const ScheduleListItem: React.FC<ComponentProps> = ({ schedule }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { channelId } = useParams<{ channelId: string }>();
  const { actionValueParameters } = useScheduleValues();
  const { daysOfWeek } = useSelectedDays();

  const handleEdit = () => {
    history.push(
      pathnameWithParameters(ROUTES.OptimizerScheduleEdit(channelId, schedule.id), search, { key: 'step', value: 1 }),
    );
  };

  return (
    <div key={schedule.id} className="schedule-list-item" onClick={handleEdit}>
      <p className="hours">{`${schedule.from} - ${schedule.to}`}</p>
      <hr className="m-t-16 m-b-16" />
      <div className="schedule-list-item--details">
        <div>
          <div className="icon">
            <IconRepeat />
          </div>
          <p>
            {daysOfWeek
              .filter((x) => schedule.selectedDays.includes(x.value))
              .map((x) => x.short)
              .join(', ')}
          </p>
        </div>
        {schedule.actionValue && (
          <div>
            <div className="icon">{actionValueParameters[schedule.action].icon || <IconActions />}</div>
            <p>{`${schedule.actionValue.toFixed(1)} ${actionValueParameters[schedule.action].unit}`}</p>
          </div>
        )}
      </div>
    </div>
  );
};
