import { useMemo, useState } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindPositionDeviceState,
  BlindErrorDeviceState,
  BlindStatusCode,
  BlindErrorCode,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';

export const useGateErrors = (channel: IDeviceChannel) => {
  const [blindError, setBlindError] = useState<BlindStatusCode | BlindErrorCode | null>(null);

  const blindPositionState = useMemo(() => {
    return channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.BlindPosition) as
      | BlindPositionDeviceState
      | undefined;
  }, [channel.States]);

  const blindErrors = useMemo(() => {
    if (channel.GetDevice().AvailableResponseTypes.find((x) => x.Type === ChannelResponseType.BlindErrorState)) {
      return (
        channel.States?.filter(
          (state) => state.TypeAsEnum === ChannelResponseType.BlindErrorState,
        ) as BlindErrorDeviceState[]
      )
        .filter((x) => !x.Data.Reset)
        .map((x) => x.Data.ErrorCode);
    }

    return [];
  }, [channel.States]);

  return { blindPositionState, blindErrors, blindError, setBlindError };
};
