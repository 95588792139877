import React from 'react';
import { useTranslation } from 'react-i18next';

export const FormError: React.FC = () => {
  const { t } = useTranslation('installation');

  return (
    <div className="integration-create__error">
      <h5>{t('exalus.controller.error')}</h5>
    </div>
  );
};
