import React from 'react';

export const IconCross: React.FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-cross"
    >
      <g clipPath="url(#clip0_22351_16285)">
        <circle cx="6" cy="6" r="5.125" stroke="#CC0909" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M4.25 4.25L8 8" stroke="#CC0909" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M4.25 8L8 4.25" stroke="#CC0909" strokeWidth="0.75" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_22351_16285">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
