import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { GatePositionTypeEnum, GatewayPositionTypeEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import {
  GatePositionState,
  GatewayPositionState,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ChangeGatePositionPulse } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useNative } from '../../../../../hooks';
import { GateControlHook } from '../types';

export const useGateControl = (channel: IDeviceChannel): GateControlHook => {
  const { fireVibration } = useNative();

  const handlePositionPulse = async () => {
    const task = new ChangeGatePositionPulse();
    task.TaskType = DeviceTaskType.ChangeGatePositionPulse;
    fireVibration();
    await channel.ExecuteTaskAsync(task);
  };

  const getIconGatePosition = (state: IChannelState<GatePositionState | GatewayPositionState>) => {
    if (!state) return 0;

    if (state.TypeAsEnum === ChannelResponseType.GatePosition) {
      switch ((state as IChannelState<GatePositionState>).Data.PositionType) {
        case GatePositionTypeEnum.Open:
          return 0;
        case GatePositionTypeEnum.Close:
          return 100;
        case GatePositionTypeEnum.PartiallyOpen:
          return 50;
        default:
          return 0;
      }
    }

    if (state.TypeAsEnum === ChannelResponseType.GatewayPosition) {
      switch ((state as IChannelState<GatewayPositionState>).Data.PositionType) {
        case GatewayPositionTypeEnum.Open:
          return 0;
        case GatewayPositionTypeEnum.On:
          return 0;
        case GatewayPositionTypeEnum.Close:
          return 100;
        case GatewayPositionTypeEnum.Off:
          return 100;
        case GatewayPositionTypeEnum.PartiallyOpen:
          return 50;
        default:
          return 0;
      }
    }

    return 0;
  };

  return {
    handlePositionPulse,
    getIconGatePosition,
  };
};
