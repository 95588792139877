import React from 'react';

export const IconRain: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7448 20.7538H8.95981C7.04324 20.7538 5.05148 20.4872 3.54724 19.2995C-1.34088 15.4402 1.76735 9.33523 4.8755 7.78108C7.23411 -2.24317 16.6692 0.705201 18.4381 4.83281C21.7402 2.00244 26.1037 6.01204 26.1037 8.37069C33.7693 11.9087 29.6417 20.7538 23.7448 20.7538Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.5433 26.1766C11.5978 26.1108 11.6499 26.0484 11.6992 25.9897C11.724 26.0186 11.7496 26.0486 11.7759 26.0796C12.1766 26.5518 12.7157 27.2342 13.2551 27.9993C13.796 28.7664 14.3263 29.6012 14.7182 30.3808C15.1229 31.1858 15.3293 31.8347 15.3293 32.2606C15.3293 34.2888 13.6851 35.933 11.6568 35.933C9.62859 35.933 7.98438 34.2888 7.98438 32.2606C7.98438 31.8376 8.1888 31.2055 8.59223 30.4217C8.98319 29.6622 9.51266 28.8506 10.0545 28.0971C10.5944 27.3464 11.1359 26.6681 11.5433 26.1766Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.0044 26.0846C20.3514 25.6021 20.6997 25.1623 20.9742 24.8286C21.2339 25.1429 21.556 25.5572 21.8783 26.0144C22.2533 26.5463 22.6178 27.1207 22.8853 27.6528C23.1656 28.2104 23.2891 28.6249 23.2891 28.8676C23.2891 30.1631 22.2389 31.2133 20.9434 31.2133C19.6479 31.2133 18.5977 30.1631 18.5977 28.8676C18.5977 28.6278 18.7192 28.2255 18.9982 27.6834C19.2648 27.1655 19.6284 26.6074 20.0044 26.0846Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
