import React from 'react';

export const IconCar: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="40"
      height="23"
      viewBox="0 0 40 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5003 16.1963H14.6904"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.07785 16.1965C3.55175 16.1965 1.5 16.5261 1.5 14V10.3385C1.5 8.90969 2.66018 7.74951 4.08897 7.74951H34.2765C36.6083 7.74951 38.5 9.64123 38.5 11.973C38.5 14.3048 36.6083 16.1965 34.2765 16.1965H33.2694"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 16.8936C14.5 19.206 12.6245 21.0815 10.312 21.0815C7.99954 21.0815 6.12402 19.206 6.12402 16.8936C6.12402 14.5811 7.99954 12.7056 10.312 12.7056C12.6245 12.7056 14.5 14.5811 14.5 16.8936Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2305 16.8936C33.2305 19.3039 31.2756 21.2588 28.8652 21.2588C26.4549 21.2588 24.5 19.3039 24.5 16.8936C24.5 14.4832 26.4549 12.5283 28.8652 12.5283C31.2756 12.5283 33.2305 14.4832 33.2305 16.8936V16.8936Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5026 1H9.52316C8.49443 1 7.6543 1.83441 7.6543 2.86886V7.74961H31.3608L29.3262 3.90331C28.3832 2.11446 26.5258 1 24.5026 1Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
