import { useState } from 'react';
import { format } from 'date-fns';
import { MeasurementRange } from '../../../../../../types';

export const useHistoryCharts = () => {
  const [offSet, setOffSet] = useState<number>(0);
  const [activeRange, setActiveRange] = useState<MeasurementRange>(MeasurementRange.DayInHours);

  const onClickActiveMeasurementRange = (value: MeasurementRange) => {
    setActiveRange(value);
    setOffSet(0);
  };

  const formatDate = (time: string) => format(new Date(time), 'dd.MM, HH:mm');

  return {
    offSet,
    setOffSet,
    activeRange,
    formatDate,
    onClickActiveMeasurementRange,
  };
};
