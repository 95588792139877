import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { StatusScreen } from '../../components';
import { useApi } from '../../hooks';
import { ROUTES } from '../../routes';
import { NotificationTypeEnum, StatusTypeEnum } from '../../types';

const InvitationAccepted: React.FC = () => {
  const { t } = useTranslation('invitation');
  const history = useHistory();
  const { notificationId } = useParams<{ notificationId: string }>();
  const { notificationList } = useApi();

  const data = useMemo(() => {
    const notification = notificationList.find((x) => x.id === notificationId);

    return notification && notification.data.type === NotificationTypeEnum.INVITATION ? notification.data : null;
  }, [notificationList, notificationId]);

  const onAccept = () => history.replace(ROUTES.Root());

  return (
    <StatusScreen
      header={t('accepted.title')}
      subTitle={t('accepted.message', { inviteeName: data?.userName })}
      type={StatusTypeEnum.SHARE}
      secondaryBackground
      primaryBtnText={t('accepted.acceptInvitation')}
      onPrimaryBtnClick={onAccept}
    />
  );
};

export default InvitationAccepted;
