import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavHeader, Page } from '../../../../components';
import { isMac, isWebView } from '../../../../utils/helpers/operation-system';
import './index.scss';

interface PropsInterface {
  setOpen: (open: boolean) => void;
}

export const NotificationTutorial: React.FC<PropsInterface> = ({ setOpen }) => {
  const { t } = useTranslation('profile');

  const instructions = useMemo(() => {
    if (isWebView()) {
      return <li>{t('notificationIssues.instructionContent.mobileCommon')}</li>;
    } else {
      return !isMac ? (
        <li>{t('notificationIssues.instructionContent.browserCommon')}</li>
      ) : (
        <>
          <li>{t('notificationIssues.instructionContent.browserCommon')}</li>
          <li>{t('notificationIssues.instructionContent.browserMACOS')}</li>
        </>
      );
    }
  }, [t]);

  return (
    <Page
      kind="above"
      contentClassName="notification__tutorial"
      whiteContent
      header={
        <>
          <NavHeader onClick={() => setOpen(false)} title={t('notificationIssues.instructionHeader')} />
        </>
      }
    >
      <p className="notification__tutorial--header">{`${t('notificationIssues.instructionContent.commonMessage')}:`}</p>
      <ul>{instructions}</ul>
    </Page>
  );
};
