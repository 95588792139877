import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Markup } from 'react-render-markup';
import { useLocation } from 'react-router-dom';
import { Header, NavHeader, Page } from '../../components';
import { useFetchDocuments } from '../../hooks/documents/use-fetch-documents';
import './index.scss';

const PrivacyPage: React.FC = () => {
  const { i18n, t } = useTranslation('privacy');
  const { content } = useFetchDocuments('privacy');
  const { search } = useLocation();

  useEffect(() => {
    const lang = new URLSearchParams(search).get('lang');
    if (lang) i18n.changeLanguage(lang);
  }, [search]);

  return (
    <Page
      className="privacy"
      header={
        <>
          <NavHeader />
          <Header title={t('privacyHeader')} />
        </>
      }
    >
      <Markup markup={content} />
    </Page>
  );
};

export default PrivacyPage;
