import {
  IconDoor,
  IconHandle,
  IconHorizontalGate,
  IconVerticalGate,
  IconWoodenGate,
} from '../../../../../../../components/icons';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const gatePreciseIcons = (position?: number): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconVerticalGate position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_41',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_WOODEN_GATE,
    component: <IconWoodenGate position={position !== undefined ? position : 50} />,
    oldIconName: 'DEVICE_ICON_16',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_HORIZONTAL_GATE,
    component: <IconHorizontalGate position={position !== undefined ? position : 50} />,
    oldIconName: 'DEVICE_ICON_18',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_HANDLE,
    component: <IconHandle position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_20',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_DOOR,
    component: <IconDoor position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_21',
  },
];
