import React from 'react';

export const IconBaby: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-baby"
  >
    <path
      d="M5.50879 24.6374C7.20885 24.6374 7.20879 23.1279 8.90886 23.1279C10.6089 23.1279 10.6089 24.6374 12.309 24.6374C14.0091 24.6374 14.006 23.1279 15.7061 23.1279C17.4061 23.1279 17.4031 24.6374 19.1 24.6374C20.797 24.6374 20.797 23.1279 22.494 23.1279C24.191 23.1279 24.191 24.6374 25.8879 24.6374C27.5849 24.6374 27.5849 23.1279 29.2819 23.1279C30.9789 23.1279 30.9789 24.6374 32.6759 24.6374V16.3369H5.51187L5.50879 24.6374Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.0908 16.3383V2H14.5624C9.56064 2 5.50879 6.05494 5.50879 11.0537V16.3352"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.0903 11.8086H25.1281C26.7944 11.8086 28.1471 13.1613 28.1471 14.8275V16.337"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4 30.6953C4 30.6953 8.05189 35.2237 19.0915 35.2237C30.1312 35.2237 34.1831 30.6953 34.1831 30.6953"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M28.9019 26.9209V33.7119" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M9.28076 26.9209V33.7119" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>
);
