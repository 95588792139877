import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ROUTES } from '../../../../../routes';
import { ChannelBoxIcon } from '../../channel/box-icon';

interface PropsInterface {
  channels: IDeviceChannel[];
}

export const CentralActionDetailsChannels: React.FC<PropsInterface> = ({ channels }) => {
  const history = useHistory();
  const { t } = useTranslation('action');

  return (
    <div className="action-device-list">
      <div className="action-device-list__header">
        <h3>{t('basic.control')}</h3>
      </div>
      {channels && channels.length > 0 && (
        <div className="action-device-list__list">
          {channels.map((channel) => (
            <div onClick={() => history.push(ROUTES.ChannelDetails(channel.ChannelId))} key={channel.ChannelId}>
              <div className="device-item">
                <h4 className="device-item__header">
                  <div className="icon-box box">
                    <ChannelBoxIcon channel={channel} className="device-box__head-icon--no-margin" />
                  </div>
                  <div className="device-item__content-wrapper">
                    <span className="device-item__name">{channel.Name}</span>
                  </div>
                </h4>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
