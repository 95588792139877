import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ManualCalibration } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { getParameterByName } from '../../../../../../../utils/location';
import './index.scss';
import Srp22CalibrationStep0 from './step0';
import Srp22CalibrationStep1 from './step1';
import Srp22CalibrationStep2_3 from './step2_3';
import Srp22CalibrationSuccess from './success';

const Srp22Calibration: React.FC = () => {
  const location = useLocation();
  const [step, setStep] = useState<number>(0);
  const [manualService, setManualService] = useState<ManualCalibration | undefined>(undefined);

  useEffect(() => {
    const step = getParameterByName('step');
    if (step) setStep(parseInt(step));
    else setStep(0);
  }, [location.search]);

  const renderCurrentView = () => {
    switch (step) {
      case 0:
        return <Srp22CalibrationStep0 setStep={setStep} setManualService={setManualService} />;
      case 1:
        return <Srp22CalibrationStep1 setStep={setStep} manualService={manualService} />;
      case 2:
        return <Srp22CalibrationStep2_3 setStep={setStep} manualService={manualService} step={step} />;
      case 3:
        return <Srp22CalibrationStep2_3 setStep={setStep} manualService={manualService} step={step} />;
      case 4:
        return <Srp22CalibrationSuccess />;
      default:
        return <Srp22CalibrationStep0 setStep={setStep} setManualService={setManualService} />;
    }
  };

  return <>{renderCurrentView()}</>;
};

export default Srp22Calibration;
