import React from 'react';

export const IconHammer: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-hammer"
  >
    <path
      d="M29.5 14.987C29.0517 14.5387 27.985 14.3178 27.4419 14.643L26.8496 14.9982C26.3065 15.3234 25.7783 15.0697 25.3291 14.6214L22.4561 11.7484C22.0077 11.3 21.7532 10.7718 22.0793 10.2278L22.412 9.67304C22.7547 9.10159 22.6099 8.3929 22.1383 7.92127C18.8153 4.59824 15 5.00002 15 5.00002C17.0911 2.90888 20.2909 2.80738 22.5126 3.0486C25.2093 3.34139 27.7005 4.63983 29.6203 6.55962L31.5642 8.50353C31.9851 8.92442 32.0899 9.56657 31.8246 10.0989L31.7389 10.2711C31.4736 10.8026 31.9488 11.5791 32.3697 12C32.7382 12.3685 33.2705 12.5224 33.7754 12.3985C34.2296 12.287 34.806 12.3328 35.1612 12.6879L36.6277 14.1544C37.1675 14.6942 37.0926 15.6458 36.5536 16.184L33.6257 19.1119C33.0867 19.6509 32.2275 19.6351 31.6876 19.0944L30.2212 17.628C29.8452 17.252 29.7154 16.728 29.8627 16.253C30.0016 15.8055 29.8327 15.3172 29.5008 14.9862L29.5 14.987Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5 15.5L6.94542 34.5837C6.4139 35.1477 5.49643 35.1352 4.92831 34.5671L2.43292 32.0717C1.8648 31.5036 1.85232 30.5853 2.41628 30.0546L22 11.5"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
