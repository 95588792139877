import React from 'react';

export const IconTowel: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="33"
      height="38"
      viewBox="0 0 33 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14L2.14457 19.3757C1.439 19.7605 1 20.5001 1 21.3038V21.3038C1 22.5167 1.98329 23.5 3.19624 23.5H5.5M26.5 23.5H29.2853C30.5084 23.5 31.5 22.5084 31.5 21.2853V21.2853C31.5 20.4905 31.0741 19.7567 30.3841 19.3623L17.7862 12.1636C17.6092 12.0624 17.5 11.8742 17.5 11.6703V11.1208C17.5 10.7403 17.715 10.3925 18.0553 10.2224L18.9367 9.78165C20.9431 8.77844 21.8305 6.39325 20.9677 4.32256V4.32256C20.3706 2.88943 19.0423 1.89359 17.4993 1.72214L17.3486 1.7054C16.1676 1.57417 14.984 1.92999 14.0711 2.69074V2.69074C13.0756 3.52034 12.5 4.74926 12.5 6.04513V6.5"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M24.5 37H7.5C6.39543 37 5.5 36.1046 5.5 35V23C5.5 21.8954 6.39543 21 7.5 21H24.5C25.6046 21 26.5 21.8954 26.5 23V35C26.5 36.1046 25.6046 37 24.5 37Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
