import React from 'react';

export const IconExalusDinBlindController: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.869141" y="0.579102" width="56" height="56" rx="12" fill="white" />
    <path
      d="M14.7824 35.5348V26.2139C14.7824 26.1517 14.8329 26.1012 14.8951 26.1012H19.3109C19.3731 26.1012 19.4235 26.0508 19.4235 25.9886V23.0231C19.4235 22.9609 19.474 22.9105 19.5362 22.9105H24.8222C24.8844 22.9105 24.9348 22.8601 24.9348 22.7979V19.8323C24.9348 19.7701 24.9853 19.7197 25.0475 19.7197H33.8144C33.8765 19.7197 33.927 19.7701 33.927 19.8323V26.8865C33.927 26.9368 33.8936 26.981 33.8453 26.9948L31.9782 27.5282C31.9298 27.5421 31.8965 27.5862 31.8965 27.6365V37.3397C31.8965 37.3847 31.9233 37.4254 31.9647 37.4432L33.8587 38.2549C33.9001 38.2726 33.927 38.3133 33.927 38.3584V45.1332C33.927 45.1953 33.8765 45.2458 33.8144 45.2458H25.0475C24.9853 45.2458 24.9348 45.1953 24.9348 45.1332V42.1676C24.9348 42.1054 24.8844 42.055 24.8222 42.055H19.5362C19.474 42.055 19.4235 42.0046 19.4235 41.9424V38.9769C19.4235 38.9147 19.3731 38.8643 19.3109 38.8643H14.8951C14.8329 38.8643 14.7824 38.8138 14.7824 38.7516V35.5348ZM14.7824 35.5348H11.9818C11.9196 35.5348 11.8691 35.4844 11.8691 35.4222V21.8007"
      stroke="#15141D"
      strokeWidth="1.5"
    />
    <path
      d="M44.8676 21.8144C45.6517 19.7371 45.6164 17.3714 44.5765 15.2393C43.5138 13.0605 41.613 11.5582 39.4348 10.9196"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M41.7787 21.1828C42.3151 19.7615 42.291 18.1428 41.5794 16.684C40.8524 15.1932 39.5518 14.1654 38.0615 13.7284"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M38.5885 21.3127C38.9599 20.3288 38.9432 19.2082 38.4506 18.1982C37.9472 17.1662 37.0469 16.4545 36.0151 16.152"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
