import React from 'react';

interface IconSwitchProps {
  isOn: boolean;
}

export const IconPadlock: React.FC<IconSwitchProps> = ({ isOn = true }) => {
  if (isOn) {
    return (
      <svg
        className="icon-sensor icon-padlock"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 9V6.5C17.5 3.46243 15.0376 1 12 1C10.3205 1 8.81678 1.75281 7.80794 2.9394M14.5 15.5H9.5M7.5 22H16.5C18.7091 22 20.5 20.2091 20.5 18V13C20.5 10.7909 18.7091 9 16.5 9H7.5C5.29086 9 3.5 10.7909 3.5 13V18C3.5 20.2091 5.29086 22 7.5 22Z"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      className="icon-sensor icon-padlock"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.5"
        y="9"
        width="17"
        height="13"
        rx="4"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 6.5C6.5 3.46243 8.96243 1 12 1V1C15.0376 1 17.5 3.46243 17.5 6.5V9H6.5V6.5Z"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 13V18" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
