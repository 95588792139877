import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  GlobalTimeParameters,
  Hours,
  Minutes,
  Seconds,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { DialogTimePicker, IconEdit, Input, SubmitButton } from '../../../../../../../components';
import { useBackdropContext } from '../../../../../../../hooks';
import { useConfigurationService } from '../../../../device-details/hooks/use-configuration-service';
import { GlobalTimeParams, LedTimeType } from '../../../../device-details/types';
import { formatTime, prepareNewTime } from '../../../../device-details/utils';

interface ComponentProps {
  timeParams: GlobalTimeParameters | undefined;
  saveChanges: (params: GlobalTimeParameters) => Promise<Status>;
  paramsTitle: string;
  led?: LedTimeType;
}

const TimeForm: React.FC<ComponentProps> = ({ saveChanges, timeParams, paramsTitle, led }) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<string>('');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<GlobalTimeParams>();

  const { time } = form.watch();

  useEffect(() => {
    if (timeParams !== undefined) {
      if (timeParams.Time) form.setValue('time', formatTime(timeParams.Time));
      else form.setValue('time', '00:00:00');
    }
  }, [timeParams]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (led) {
      switch (led) {
        case LedTimeType.LedTime: {
          const minutes = parseInt(values.time.split(':')[1]) as Minutes;
          const seconds = parseInt(values.time.split(':')[2]) as Seconds;

          const secondsSum = minutes * 60 + seconds;

          if (secondsSum > 240) {
            setTimeError(tc('errors.maxNumber', { max: '00:04:00' }));
            return;
          }

          break;
        }
        case LedTimeType.FloatingTime: {
          const hours = parseInt(values.time.split(':')[0]) as Hours;
          const minutes = parseInt(values.time.split(':')[1]) as Minutes;
          const seconds = parseInt(values.time.split(':')[2]) as Seconds;

          const secondsSum = hours * 3600 + minutes * 60 + seconds;

          if (secondsSum > 64800) {
            setTimeError(tc('errors.maxNumber', { max: '18:00:00' }));
            return;
          }
          if (secondsSum < 1) {
            setTimeError(tc('errors.minNumber', { min: '00:00:01' }));
            return;
          }

          break;
        }
      }
    }

    if (timeParams) {
      turnOnBackdrop();

      const params: GlobalTimeParameters = {
        Time: prepareNewTime(values),
        Channel: timeParams.Channel,
      };

      const result = await saveChanges(params);
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <div onClick={() => setShowTimePicker(true)}>
          <Input
            defaultValue={time}
            value={time}
            required
            readOnly
            errorMessage={timeError}
            endIcon={<IconEdit />}
            label={t(`exalus.params.OnOffParams.${paramsTitle}`)}
          />
        </div>

        <SubmitButton />
      </form>
      {time && (
        <DialogTimePicker
          header={`${t(`exalus.params.OnOffParams.${paramsTitle}`)} (${!led ? 'HH:mm:ss' : 'mm:ss'})`}
          open={showTimePicker}
          setOpen={setShowTimePicker}
          onSave={(value) => form.setValue('time', value)}
          time={time}
          showSeconds
          {...(led === LedTimeType.FloatingTime ? { maxHours: 19 } : {})}
          {...(led === LedTimeType.LedTime ? { maxMinutes: 5 } : {})}
          {...(led === LedTimeType.LedTime ? { disableHours: true } : {})}
        />
      )}
    </FormProvider>
  );
};

export default TimeForm;
