import { GlobalTimeParameters, StateOfTurnOnSwitchDeviceParameters } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { InputOutputDimmerParameters, LightTransitionTime, MaxLightLevel, MinLightLevel } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';

export enum Rdp21ParamsEnum {
  INPUT_MODE = 'InputModeParams',
  BRIGHTENING_TIME = 'BrighteningTimeParams',
  DIMMING_TIME = 'DimmingTimeParams',
  MIN_LEVEL = 'MinLevelParams',
  MAX_LEVEL = 'MaxLevelParams',
  TURN_ON_BEHAVIOUR = 'TurnOnBehaviourParams',
  GLOBAL_TIME = 'GlobalTimeParams',
  ENABLE_TIME = 'EnableTimeParams',
}

export interface Rdp21ConfigParams {
  [Rdp21ParamsEnum.INPUT_MODE]: InputOutputDimmerParameters | null;
  [Rdp21ParamsEnum.BRIGHTENING_TIME]: LightTransitionTime | null;
  [Rdp21ParamsEnum.DIMMING_TIME]: LightTransitionTime | null;
  [Rdp21ParamsEnum.MIN_LEVEL]: MinLightLevel | null;
  [Rdp21ParamsEnum.MAX_LEVEL]: MaxLightLevel | null;
  [Rdp21ParamsEnum.TURN_ON_BEHAVIOUR]: StateOfTurnOnSwitchDeviceParameters | null;
  [Rdp21ParamsEnum.GLOBAL_TIME]: GlobalTimeParameters | null;
  [Rdp21ParamsEnum.ENABLE_TIME]: GlobalTimeParameters | null;
}

export const initialConfig: Rdp21ConfigParams = {
  [Rdp21ParamsEnum.INPUT_MODE]: null,
  [Rdp21ParamsEnum.BRIGHTENING_TIME]: null,
  [Rdp21ParamsEnum.DIMMING_TIME]: null,
  [Rdp21ParamsEnum.MIN_LEVEL]: null,
  [Rdp21ParamsEnum.MAX_LEVEL]: null,
  [Rdp21ParamsEnum.TURN_ON_BEHAVIOUR]: null,
  [Rdp21ParamsEnum.GLOBAL_TIME]: null,
  [Rdp21ParamsEnum.ENABLE_TIME]: null,
};
