import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../../../components';

interface ComponentProps {
  handleBack: () => void;
  children: ReactNode;
  header?: string;
}

const ConfigCustomPage: React.FC<ComponentProps> = ({ children, handleBack, header }) => {
  const { t } = useTranslation('device-info');

  return (
    <Page
      kind="above"
      header={
        <>
          <NavHeader isIconClose onClick={handleBack} />
          <Header title={header || t('exalus.configuration')} />
        </>
      }
    >
      {children}
    </Page>
  );
};

export default ConfigCustomPage;
