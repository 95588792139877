import React from 'react';

export const IconDrink: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="26"
      height="31"
      viewBox="0 0 26 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4445 17.9406L13.9633 18.5159L14.4445 17.9406ZM17.9756 19.4568L17.9391 20.206L17.9756 19.4568ZM17.9756 5.59406L17.5415 4.98242C17.3433 5.12307 17.2256 5.35103 17.2256 5.59406H17.9756ZM24.8833 1.61164C25.2211 1.37192 25.3006 0.903747 25.0609 0.565949C24.8212 0.228151 24.353 0.148643 24.0152 0.388363L24.8833 1.61164ZM8.51711 30.75H20.6661V29.25H8.51711V30.75ZM6.63232 26.2673V28.8652H8.13232V26.2673H6.63232ZM22.5509 28.8652V26.2673H21.0509V28.8652H22.5509ZM22.5509 26.2673V17.9406H21.0509V26.2673H22.5509ZM22.5509 17.9406V10.7486H21.0509V17.9406H22.5509ZM6.63232 10.7486V17.3663H8.13232V10.7486H6.63232ZM6.63232 17.3663V26.2673H8.13232V17.3663H6.63232ZM14.9257 17.3653C13.1467 15.8774 11.4404 15.4171 9.99589 15.5125C8.57623 15.6063 7.46915 16.2337 6.85854 16.8295L7.90611 17.9031C8.27636 17.5419 9.05252 17.0781 10.0948 17.0093C11.1123 16.942 12.4466 17.2473 13.9633 18.5159L14.9257 17.3653ZM20.6661 8.86386H17.9756V10.3639H20.6661V8.86386ZM17.9756 8.86386H8.51711V10.3639H17.9756V8.86386ZM21.2543 17.4271C20.6979 18.0193 19.4977 18.7801 18.0121 18.7077L17.9391 20.206C19.9625 20.3045 21.5653 19.2868 22.3476 18.4541L21.2543 17.4271ZM18.0121 18.7077C17.1169 18.6641 16.064 18.3173 14.9257 17.3653L13.9633 18.5159C15.3276 19.6569 16.6867 20.145 17.9391 20.206L18.0121 18.7077ZM17.2256 9.61386V19.4568H18.7256V9.61386H17.2256ZM17.2256 5.59406V9.61386H18.7256V5.59406H17.2256ZM18.4096 6.2057L24.8833 1.61164L24.0152 0.388363L17.5415 4.98242L18.4096 6.2057ZM8.13232 10.7486C8.13232 10.5361 8.3046 10.3639 8.51711 10.3639V8.86386C7.47617 8.86386 6.63232 9.70771 6.63232 10.7486H8.13232ZM22.5509 10.7486C22.5509 9.70771 21.7071 8.86386 20.6661 8.86386V10.3639C20.8786 10.3639 21.0509 10.5361 21.0509 10.7486H22.5509ZM20.6661 30.75C21.7071 30.75 22.5509 29.9062 22.5509 28.8652H21.0509C21.0509 29.0777 20.8787 29.25 20.6661 29.25V30.75ZM8.51711 29.25C8.3046 29.25 8.13232 29.0777 8.13232 28.8652H6.63232C6.63232 29.9062 7.47617 30.75 8.51711 30.75V29.25Z"
        fill="#15141D"
      />
      <path
        d="M12.2574 9.59254C12.2574 6.62656 9.79331 4.22217 6.7537 4.22217C3.71409 4.22217 1.25 6.62656 1.25 9.59254C1.25 12.5585 3.71409 14.9629 6.7537 14.9629"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
