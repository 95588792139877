import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  direction?: 'up' | 'right' | 'down' | 'left';
  disabled?: boolean;
  withCircle?: boolean;
  noSpace?: boolean;
  onClick?: () => void;
  className?: string;
  size?: number;
  channelNumber?: number;
}

export const IconChevron: React.FC<PropsInterface> = ({
  direction = 'left',
  disabled = false,
  withCircle = false,
  noSpace = false,
  onClick,
  className,
  size = 32,
  channelNumber,
}) => {
  return (
    <div
      className={classNames('icon-chevron', {
        'icon-chevron--disabled': disabled,
        'icon-chevron--no-space': noSpace,
        clickable: onClick,
        [className as string]: className,
      })}
      onClick={onClick}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {withCircle && <circle cx="16" cy="16" r="15.25" stroke="white" vectorEffect="non-scaling-stroke" />}
        <path
          className={`icon-chevron__chevron icon-chevron__chevron--direction-${direction}`}
          d="M22 19L16 13L10 19"
          stroke="white"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
      {channelNumber && <div className="channel-number">{channelNumber}</div>}
    </div>
  );
};
