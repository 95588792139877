import React, { createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useInstallation, useScroll } from '../../hooks';
import { hexToRgb } from '../../utils/helpers';
import { isWisniowski } from '../../utils/helpers/wisniowski';
import * as storage from '../../utils/storage/lavva';
import { getSelectedTheme } from '../../utils/theme';
import './index.scss';

interface CommonProps {
  children?: ReactNode;
  header?: ReactNode;
  centerContent?: boolean;
  className?: string;
  containerClassName?: string;
  headerClassName?: string;
  contentClassName?: string;
  carouselContent?: boolean;
  whiteContent?: boolean;
  headerAccent?: boolean;
}

interface CustomPageProps extends CommonProps {
  kind?: 'custom';
  isStickyHeader?: boolean;
}

interface AbovePageProps extends Omit<CommonProps, 'kind'> {
  kind: 'above';
}

type ComponentProps = CustomPageProps | AbovePageProps;

export const PageContext = createContext({ isScrollable: false });

export const Page: React.FC<ComponentProps> = (props) => {
  const {
    children,
    header,
    centerContent = false,
    className,
    containerClassName,
    headerClassName,
    contentClassName,
    carouselContent = false,
    whiteContent = false,
    headerAccent = false,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { scrollDown, isScrollable } = useScroll(ref, 0);
  const [hasSubmitButton, setHasSubmitButton] = useState<boolean>(false);
  const [hasTwoFixedButtons, setHasTwoFixedButtons] = useState<boolean>(false);
  const { selectedInstallation } = useInstallation();
  const isStickyHeader = props.kind !== 'above' ? props.isStickyHeader : false;
  const above = props.kind === 'above';

  useEffect(() => {
    setHasSubmitButton(Boolean(ref?.current?.querySelector('#submit-button')));
    setHasTwoFixedButtons(Boolean(ref?.current?.querySelector('#fixed-two-buttons')));
  }, [ref.current, header, className, containerClassName, contentClassName, isStickyHeader, above]);

  const pageStyle = useMemo(() => {
    const isDashboard = className?.includes('dashboard');

    if (!isDashboard) return undefined;

    const isDark = getSelectedTheme() === 'dark';

    return {
      backgroundImage: !isWisniowski
        ? `url(/images/${isDark ? 'dark' : 'light'}.svg)`
        : `url(/images/wisniowski${isDark ? '-dark' : ''}.svg)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right top',
    } as React.CSSProperties;
  }, [className, storage]);

  const pageHeaderStyle = useMemo(() => {
    const isDashboard = className?.includes('dashboard');

    if (!isDashboard || !isStickyHeader) return undefined;

    const isDark = getSelectedTheme() === 'dark';

    return {
      backgroundImage: !isWisniowski
        ? `url(/images/${isDark ? 'dark' : 'light'}.svg)`
        : `url(/images/wisniowski${isDark ? '-dark' : ''}.svg)`,
    } as React.CSSProperties;
  }, [className, storage, headerAccent, selectedInstallation?.hexColor]);

  return (
    <div className={classNames('page', { above, [className as string]: className })} style={pageStyle}>
      {isStickyHeader && (
        <div
          className={classNames('page__header m-t-0', {
            'page__header--sticky': isStickyHeader,
            'page__header--sticky-active': isStickyHeader && scrollDown,
            [headerClassName as string]: headerClassName,
          })}
          style={pageHeaderStyle}
        >
          {headerAccent && (
            <div
              className="header-accent"
              {...(selectedInstallation?.hexColor && headerAccent
                ? { style: { backgroundColor: `rgba(${hexToRgb(selectedInstallation.hexColor)},0.3)` } }
                : {})}
            />
          )}
          {header}
        </div>
      )}
      <div
        ref={ref}
        className={classNames('page__container', {
          'page__container--carousel': carouselContent,
          'page__container--white': whiteContent,
          [containerClassName as string]: containerClassName,
        })}
      >
        {!isStickyHeader && (
          <div
            className={classNames('page__header m-t-0', {
              [headerClassName as string]: headerClassName,
            })}
          >
            {header}
          </div>
        )}
        <div
          className={classNames('page__content', {
            'page__content--center': centerContent,
            [contentClassName as string]: contentClassName,
          })}
        >
          <PageContext.Provider value={{ isScrollable }}>
            {children}
            {hasSubmitButton && <div className="page__fixed-button-spacing" />}
            {hasTwoFixedButtons && <div className="page__fixed-two-buttons-spacing" />}
          </PageContext.Provider>
        </div>
      </div>
    </div>
  );
};
