import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PopUpNavLinkInterface } from '../../../components';
import {
  IconChannelsOrder,
  IconDelete,
  IconDuplicate,
  IconEdit,
  IconGroups,
} from '../../../components/popup-nav/icons';
import { ActionType, DashboardItemType, UserAction, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useProfile } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { useModifyMultipleGroups } from '../../channel-details/hooks/use-modify-multiple-groups';

export const useActionDetails = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { t: td } = useTranslation('channel-details');
  const { actionId } = useParams<{ actionId: string }>();
  const [userAction, setUserAction] = useState<UserAction | null>();
  const [showDuplicateDialog, setShowDuplicateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { permissions } = usePermissionsContext();
  const { userId } = useProfile();
  const { groupList, handleSaveGroup, toggleGroup, groupPopup, setGroupPopup } = useModifyMultipleGroups({
    itemId: actionId,
    itemType: DashboardItemType.Action,
  });

  const canRemoveAction = useMemo(
    () =>
      userAction?.action.ownerId === userId
        ? permissions[UserPermissionType.OwnActionsRemove]
        : permissions[UserPermissionType.ActionsRemove],
    [(userAction?.action.ownerId, userId, permissions)],
  );

  const canEditAction = useMemo(
    () =>
      userAction?.action.ownerId === userId
        ? permissions[UserPermissionType.OwnActionsEdit]
        : permissions[UserPermissionType.ActionsEdit],
    [(userAction?.action.ownerId, userId, permissions)],
  );

  const popupLinks: PopUpNavLinkInterface[] = useMemo(() => {
    if (!userAction) return [];
    switch (userAction?.action.actionType) {
      case ActionType.Central:
        return [
          ...(canEditAction
            ? [
                {
                  onClick: () => history.push(ROUTES.ActionChannelsSort(userAction.action.id)),
                  label: t('sort'),
                  icon: <IconChannelsOrder />,
                },
              ]
            : []),
        ];
      case ActionType.Basic:
        return [
          ...(canEditAction
            ? [
                {
                  onClick: () => history.push(ROUTES.ActionEditName(userAction.action.id)),
                  label: t('changeName'),
                  icon: <IconEdit />,
                },
                {
                  onClick: () => history.push(ROUTES.ActionEditChannels(userAction.action.id)),
                  label: t('edit'),
                  icon: <IconEdit />,
                },
                {
                  onClick: () => history.push(ROUTES.ActionChannelsSort(userAction.action.id)),
                  label: t('sort'),
                  hasLineBelow: true,
                  icon: <IconChannelsOrder />,
                },
              ]
            : []),
          ...(permissions[UserPermissionType.AddRemoveChannelForMultipleGroups] ||
          permissions[UserPermissionType.AddRemoveChannelForMultiplePublicGroups]
            ? [
                {
                  onClick: () => setGroupPopup(true),
                  label: td('groupAddRemove'),
                  icon: <IconGroups />,
                },
              ]
            : []),
          ...(canRemoveAction
            ? [{ onClick: () => setShowDeleteDialog(true), label: t('remove'), icon: <IconDelete /> }]
            : []),
        ];
      case ActionType.Advanced:
        return [
          ...(canEditAction
            ? [
                {
                  onClick: () => history.push(ROUTES.ActionEdit(userAction.action.id)),
                  label: t('edit'),
                  icon: <IconEdit />,
                },
                {
                  onClick: () => history.push(ROUTES.ActionChannelsSort(userAction.action.id)),
                  label: t('sort'),
                  hasLineBelow: true,
                  icon: <IconChannelsOrder />,
                },
              ]
            : []),
          ...(permissions[UserPermissionType.AddRemoveChannelForMultipleGroups] ||
          permissions[UserPermissionType.AddRemoveChannelForMultiplePublicGroups]
            ? [
                {
                  onClick: () => setGroupPopup(true),
                  label: td('groupAddRemove'),
                  icon: <IconGroups />,
                },
              ]
            : []),
          ...(permissions[UserPermissionType.ActionsCreate]
            ? [{ onClick: () => setShowDuplicateDialog(true), label: t('duplicate'), icon: <IconDuplicate /> }]
            : []),
          ...(canRemoveAction
            ? [{ onClick: () => setShowDeleteDialog(true), label: t('remove'), icon: <IconDelete /> }]
            : []),
        ];
      default:
        return [];
    }
  }, [userAction, canEditAction, canRemoveAction, permissions]);

  return {
    popupLinks,
    groupList,
    handleSaveGroup,
    toggleGroup,
    groupPopup,
    setGroupPopup,
    userAction,
    setUserAction,
    showDeleteDialog,
    showDuplicateDialog,
    setShowDeleteDialog,
    setShowDuplicateDialog,
  };
};
