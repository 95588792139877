import React from 'react';

export const IconBottle: React.FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-group icon-bottle"
  >
    <path
      d="M24.766 10.3845H12.4534C12.203 10.3845 12 10.5875 12 10.8379V12.3582C12 12.6086 12.203 12.8116 12.4534 12.8116H24.766C25.0164 12.8116 25.2194 12.6086 25.2194 12.3582V10.8379C25.2194 10.5875 25.0164 10.3845 24.766 10.3845Z"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M20.4043 6.94952C22.7017 7.2465 24.4863 8.13562 24.4863 9.83361"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.7324 9.83364C12.7324 8.13474 14.3833 7.24562 16.6825 6.9491"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.1373 4.21054C16.8195 3.86958 16.625 3.4121 16.625 2.90882C16.625 1.85466 17.4797 1 18.5338 1C19.588 1 20.4426 1.85466 20.4426 2.90882C20.4426 3.40167 20.2558 3.85099 19.9489 4.18968"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.1369 4.29218C17.1369 4.29218 17.4348 6.21913 16.8027 6.92508"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.9598 4.29218C19.9598 4.29218 19.7077 6.17379 20.3393 6.87974"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M21.9609 20.4074H24.7199" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M21.9609 23.4148H24.7199" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M21.9609 26.4222H24.7199" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M21.9609 29.4296H24.7199" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M21.9609 32.4375H24.7199" stroke="#15141D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M22.5 15.5H15C13.3431 15.5 12 16.8431 12 18.5V33.5C12 35.1569 13.3431 36.5 15 36.5H22.5C24.1569 36.5 25.5 35.1569 25.5 33.5V18.5C25.5 16.8431 24.1569 15.5 22.5 15.5Z"
      stroke="#15141D"
      strokeWidth="1.5"
    />
  </svg>
);
