import React, { ReactNode } from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { ApolloProvider } from '@apollo/client';
import * as storage from '../storage/lavva';
import { useGraphqlContext } from './graphql.context';

const { useApolloNetworkStatus } = createNetworkStatusNotifier();

type ComponentProps = {
  children: ReactNode;
};

export const GlobalLoadingIndicator: React.FC = () => {
  const status = useApolloNetworkStatus();

  return storage.getItem('debugMode') ? (
    <div className="global-loader">
      <div>
        Pending {status.numPendingQueries}/ {status.numPendingMutations} [queries/mutations]
      </div>
    </div>
  ) : null;
};

const ApolloProviderWrapper: React.FC<ComponentProps> = ({ children }) => {
  const { client } = useGraphqlContext();

  return (
    <ApolloProvider client={client}>
      <GlobalLoadingIndicator />
      {children}
    </ApolloProvider>
  );
};

export default ApolloProviderWrapper;
