import React from 'react';

export const IconSendMessage: React.FC = () => {
  return (
    <svg
      className="icon-send-message"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5417 17.64L6.24505 13.436C5.43781 13.0269 5.54575 11.8415 6.41358 11.585L25.2546 6.01591C26.011 5.79233 26.7164 6.49154 26.4995 7.24988L21.0956 26.1437C20.8492 27.0052 19.6814 27.1328 19.2548 26.3448L14.5417 17.64ZM14.5417 17.64L26.5208 5.9856"
        stroke="#15141D"
        strokeWidth="1.5"
      />
    </svg>
  );
};
