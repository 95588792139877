import React from 'react';
import '../index.scss';

export const IconCkeckMarks: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--check-marks"
  >
    <path
      d="M19.12 5L9.69761 14.4224L5.34863 10.0737"
      stroke="#15141D"
      strokeWidth="1.00375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 10.0737L5.34873 14.4224L6.43604 13.3353"
      stroke="#15141D"
      strokeWidth="1.00375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7715 5L8.61047 11.1607"
      stroke="#15141D"
      strokeWidth="1.00375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
