import { gql } from '@apollo/client';

export const REMOVE_DEVICE = gql`
  mutation RemoveDevice($input: RemoveDeviceInput!) {
    removeDevice(input: $input) {
      result {
        ok
      }
      errors {
        ... on DeviceError {
          error
          message
        }
      }
    }
  }
`;

export const CHANGE_DEVICE_NAME = gql`
  mutation ChangeDeviceName($installationId: UUID!, $deviceId: UUID!, $name: String!) {
    changeDeviceName(installationId: $installationId, deviceId: $deviceId, name: $name) {
      publishStatus
      correlationId
    }
  }
`;
