import { useState } from 'react';
import { ConfirmPopupsHook } from '../types';

export const useConfirmPopups = (): ConfirmPopupsHook => {
  const [saveDialog, setSaveDialog] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  const openRemoveDialog = () => setDeleteDialog(true);

  const closeRemoveDialog = () => setDeleteDialog(false);

  const onCancel = () => {
    setSaveDialog(false);
    setDeleteDialog(false);
  };

  return {
    saveDialog,
    deleteDialog,
    setSaveDialog,
    setDeleteDialog,
    openRemoveDialog,
    closeRemoveDialog,
    onCancel,
  };
};
