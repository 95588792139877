import React from 'react';

export const IconExalusWallMountedRelay: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.607422" y="0.859375" width="56" height="56" rx="12" fill="white" />
    <g clipPath="url(#clip0_23262_14214)">
      <path
        d="M27.3203 17.2822L27.3203 13.0462"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3203 17.2793L27.3203 13.0432"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7891 28.5781L16.0251 28.5781"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3398 17.5957L19.3352 20.591"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.334 17.5957L35.3386 20.591"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0616 43.4001C29.3334 43.4001 30.3645 42.5098 30.3645 41.4115V39.4229M30.3645 39.4229C31.0004 39.4229 31.5159 38.9777 31.5159 38.4286C31.5159 36.7401 31.9143 35.2236 32.4406 33.8403C32.9978 32.3757 34.619 29.0512 34.3117 27.5146V27.5146C33.1468 21.6901 27.3222 22.2196 27.3222 22.2196M30.3645 39.4229H26.9102"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3645 39.4287V41.4173C30.3645 42.5156 29.3334 43.4059 28.0616 43.4059H26.9102M30.3645 39.4287C31.0004 39.4287 31.5159 38.9836 31.5159 38.4344C31.5159 36.7459 31.9143 35.2295 32.4406 33.8461C32.9978 32.3815 34.619 29.057 34.3117 27.5205V27.5205C33.1468 21.6959 27.3222 22.2254 27.3222 22.2254M30.3645 39.4287H26.9102"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3738 39.4287V41.4173C24.3738 42.5156 25.4049 43.4059 26.6767 43.4059H27.8281M24.3738 39.4287C23.7379 39.4287 23.2224 38.9836 23.2224 38.4344C23.2224 36.7459 22.8239 35.2295 22.2977 33.8461C21.7405 32.3815 20.1192 29.057 20.4266 27.5205V27.5205C21.5915 21.6959 27.416 22.2254 27.416 22.2254M24.3738 39.4287H27.8281"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4879 27.7377L21.2658 28.4541L21.2658 28.4541L21.4879 27.7377ZM33.9148 28.2868C34.3073 28.1543 34.518 27.7288 34.3856 27.3363C34.2531 26.9439 33.8276 26.7331 33.4351 26.8656L33.9148 28.2868ZM20.7447 28.2925L21.2658 28.4541L21.7099 27.0213L21.1888 26.8598L20.7447 28.2925ZM21.2658 28.4541C25.4462 29.7498 29.7505 29.6924 33.9148 28.2868L33.4351 26.8656C29.5683 28.1707 25.5873 28.2231 21.7099 27.0213L21.2658 28.4541Z"
        fill="#15141D"
      />
      <rect x="26" y="30" width="20" height="16" rx="2" fill="white" />
      <path
        d="M41.1108 31.0762H28.1289C27.5054 31.0762 27 31.6265 27 32.3054V43.9828C27 44.6617 27.5054 45.212 28.1289 45.212H41.1108C41.7342 45.212 42.2396 44.6617 42.2396 43.9828V32.3054C42.2396 31.6265 41.7342 31.0762 41.1108 31.0762Z"
        stroke="#15141D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0196 32.9199H29.2202C28.9292 32.9199 28.6934 33.1558 28.6934 33.4467V42.8414C28.6934 43.1323 28.9292 43.3682 29.2202 43.3682H40.0196C40.3105 43.3682 40.5464 43.1323 40.5464 42.8414V33.4467C40.5464 33.1558 40.3105 32.9199 40.0196 32.9199Z"
        stroke="#15141D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M34.6191 32.9199V43.3682" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.3867 41.5244H32.6444" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M36.5957 41.5244H38.8534" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M45.349 30.5008C45.3313 29.731 45.0126 28.9749 44.4013 28.4245C43.7765 27.862 42.9702 27.6258 42.1869 27.7035"
        stroke="#15141D"
        strokeLinecap="round"
      />
      <path
        d="M43.8353 31.6931C43.8232 31.1664 43.6051 30.6491 43.1869 30.2724C42.7594 29.8876 42.2077 29.726 41.6718 29.7791"
        stroke="#15141D"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23262_14214">
        <rect width="35.2441" height="35.2441" fill="white" transform="translate(10.6074 10.8594)" />
      </clipPath>
    </defs>
  </svg>
);
