import React from 'react';
import './index.scss';

export const IconOn: React.FC = () => (
  <svg className="icon-on" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15.25" stroke="#15141D" strokeWidth="1.5" />
    <path
      d="M13.2623 13.8124L13.2623 13.8124L13.2627 13.813C13.6635 14.321 13.868 15.0889 13.868 16.125C13.868 17.161 13.6635 17.9328 13.2625 18.4483L13.2625 18.4484C12.87 18.9551 12.3099 19.21 11.575 19.21C10.8402 19.21 10.2764 18.9551 9.8765 18.4483C9.48281 17.9328 9.282 17.161 9.282 16.125C9.282 15.0887 9.48292 14.3207 9.87653 13.8126L9.837 13.782L9.87647 13.8127C10.2765 13.2984 10.8403 13.04 11.575 13.04C12.3097 13.04 12.8697 13.2984 13.2623 13.8124ZM8.91944 19.194L8.91963 19.1942C9.57576 19.9324 10.4628 20.3 11.575 20.3C12.6871 20.3 13.5705 19.9324 14.2194 19.1942C14.8752 18.4564 15.2 17.4311 15.2 16.125C15.2 14.819 14.8753 13.7937 14.2195 13.0559C13.5706 12.3176 12.6871 11.95 11.575 11.95C10.4628 11.95 9.57576 12.3176 8.91963 13.0558L8.91944 13.056C8.27102 13.7939 7.95 14.8191 7.95 16.125C7.95 17.4309 8.27102 18.4561 8.91944 19.194ZM18.9834 12.6022L18.9834 12.6022L18.9829 12.6014C18.7689 12.2422 18.4559 12.06 18.049 12.06C17.874 12.06 17.722 12.1242 17.5956 12.2506C17.4692 12.3771 17.405 12.529 17.405 12.704V19.546C17.405 19.721 17.4692 19.8729 17.5956 19.9994C17.722 20.1258 17.874 20.19 18.049 20.19C18.224 20.19 18.3759 20.1258 18.5023 19.9994C18.6287 19.8729 18.693 19.721 18.693 19.546V14.3053L21.7566 19.6479L21.7566 19.6479L21.757 19.6486C21.971 20.0078 22.284 20.19 22.691 20.19C22.866 20.19 23.0179 20.1258 23.1443 19.9994C23.2707 19.8729 23.335 19.721 23.335 19.546V12.704C23.335 12.529 23.2707 12.3771 23.1443 12.2506C23.0179 12.1242 22.866 12.06 22.691 12.06C22.516 12.06 22.364 12.1242 22.2376 12.2506C22.1112 12.3771 22.047 12.529 22.047 12.704V17.9635L18.9834 12.6022Z"
      fill="#15141D"
      stroke="#15141D"
      strokeWidth="0.1"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);
