import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface PropsInterface {
  className?: string;
}

export const IconSunUp: React.FC<PropsInterface> = ({ className }) => {
  return (
    <div
      className={classNames('icon-sun-up', {
        [className as string]: className,
      })}
    >
      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.6665 9.00033C5.6665 9.00032 5.6665 9.00033 5.6665 9.00033ZM5.6665 9.00033C5.66651 7.15938 7.15889 5.66699 8.99984 5.66699C10.8408 5.66699 12.3332 7.15937 12.3332 9.00032M5.6665 9.00033L12.3332 9.00032M5.6665 9.00033L1.6665 9.00032M12.3332 9.00032C12.3332 9.00032 12.3332 9.00031 12.3332 9.00032ZM12.3332 9.00032L16.3332 9.00032M8.99983 3.66699V1.66699M3.81438 3.81487L5.2286 5.22908M14.1853 3.81487L12.7711 5.22908M6.33316 13.667L8.99983 11.0003M8.99983 11.0003L11.6665 13.667M8.99983 11.0003L8.99983 16.0003"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
