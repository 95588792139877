import React, { ChangeEvent, useCallback, useState } from 'react';
import { IconPassword } from '../icons';
import { Input } from './input';

interface InputPinProps {
  defaultValue?: string;
  value?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  label: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  hasError?: boolean;
}

export const InputPin: React.FC<InputPinProps> = (props) => {
  const [showPin, setShowPin] = useState<boolean>(false);

  const toggleShowPin = useCallback(
    (event: React.PointerEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowPin(!showPin);
    },
    [setShowPin, showPin],
  );

  return (
    <Input {...props} inputType={showPin ? 'text' : 'password'} isPin>
      <button onClick={toggleShowPin} className="input__icon">
        <IconPassword isOn={!showPin} />
      </button>
    </Input>
  );
};
