import { ChannelInterface, DeviceInterface } from '../../../types';
import { ChannelGeneralConfiguration } from '../configuration-form/types';

export enum InfoPageType {
  UNDEFINED = 'UNDEFINED',
  CHANNEL = 'CHANNEL',
  INSERVICE = 'INSERVICE',
  VISIBILITY = 'VISIBILITY',
  INPUT_TYPE = 'INPUT_TYPE',
  CALIBRATION = 'CALIBRATION',
}

export interface ConfigurationHook {
  autoCalibrate: () => void;
  editChannel: (values: ChannelGeneralConfiguration) => void;
  swapInDevice: () => void;
  swapOut: () => void;
  updateRecoveryDevice: () => void;
  updateDevice: () => void;
  updateDevices: () => void;
  setDeviceName: (name: string) => void;
  calibrateLoading: boolean;
  updateDeviceLoading: boolean;
}

export interface ConfigurationHookParams {
  channel?: ChannelInterface;
  device?: DeviceInterface;
  devices?: DeviceInterface[];
}