import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../components';
import { OptimizerModeInternal } from '../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../types';
import { ChannelOptimizerInterface } from '../../../../types/channel/optimizer';
import { OptimizerConfigurationWorkModeSelect } from './components/work-mode';
import { useOptimizerModes } from './hooks/use-optimizer-modes';
import './index.scss';
import { OptimizerConfigurationWorkModeAutoConsumptionFull } from './work-mode/auto-consumption-full';
import { OptimizerConfigurationWorkModeAutoConsumptionLimited } from './work-mode/auto-consumption-limited';
import { OptimizerConfigurationWorkModeAutoConsumptionRelays } from './work-mode/auto-consumption-relays';
import { OptimizerConfigurationWorkModeComfort } from './work-mode/comfort';
import { OptimizerConfigurationWorkModeEco } from './work-mode/eco';
import { OptimizerConfigurationWorkModeTest } from './work-mode/test';

interface PropsInterface {
  channel?: ChannelInterface;
  temperature?: number;
}

export const OptimizerConfiguration: React.FC<PropsInterface> = ({ channel, temperature }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const [dialog, setDialog] = useState<OptimizerModeInternal | null>(null);
  const { workModeOptions } = useOptimizerModes();
  const [selectedWorkMode, setSelectedWorkMode] = useState(OptimizerModeInternal.TestMode);

  useEffect(() => {
    if ((channel?.data as ChannelOptimizerInterface)?.payload) {
      setSelectedWorkMode((channel?.data as ChannelOptimizerInterface).payload?.mode || OptimizerModeInternal.TestMode);
    }
  }, [channel?.data]);

  const workModeOnChange = (value) => {
    if (
      value === OptimizerModeInternal.FullSelfConsumption ||
      value === OptimizerModeInternal.SelfConsumptionWithPowerLimit ||
      value === OptimizerModeInternal.SelfConsumptionWithRelays
    ) {
      setDialog(value);
    } else setSelectedWorkMode(value);
  };

  const renderCurrentForm = () => {
    if (channel) {
      switch (selectedWorkMode) {
        case OptimizerModeInternal.TestMode:
          return <OptimizerConfigurationWorkModeTest mode={selectedWorkMode} channel={channel} />;
        case OptimizerModeInternal.Eco:
          return (
            <OptimizerConfigurationWorkModeEco mode={selectedWorkMode} channel={channel} temperature={temperature} />
          );
        case OptimizerModeInternal.Comfort:
          return (
            <OptimizerConfigurationWorkModeComfort
              mode={selectedWorkMode}
              channel={channel}
              temperature={temperature}
            />
          );
        case OptimizerModeInternal.FullSelfConsumption:
          return (
            <OptimizerConfigurationWorkModeAutoConsumptionFull
              mode={selectedWorkMode}
              channel={channel}
              temperature={temperature}
            />
          );
        case OptimizerModeInternal.SelfConsumptionWithPowerLimit:
          return (
            <OptimizerConfigurationWorkModeAutoConsumptionLimited
              mode={selectedWorkMode}
              channel={channel}
              temperature={temperature}
            />
          );
        case OptimizerModeInternal.SelfConsumptionWithRelays:
          return <OptimizerConfigurationWorkModeAutoConsumptionRelays mode={selectedWorkMode} channel={channel} />;
        default:
      }
    }

    return null;
  };

  const confirmChoiceMode = () => {
    if (dialog) {
      setSelectedWorkMode(dialog);
      handleCloseDialog();
    }
  };

  const handleCloseDialog = () => setDialog(null);

  return (
    <>
      <div className="optimizer-configuration-form p-l-24 p-r-24">
        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationWorkModeSelect
            workModeOptions={workModeOptions}
            selectedWorkMode={selectedWorkMode}
            onChange={workModeOnChange}
          />
        </div>

        <hr className="m-t-24 m-b-24" />

        {renderCurrentForm()}
        <DialogConfirmation
          show={!!dialog}
          setShow={handleCloseDialog}
          header={`${t('optimizer.configuration.consumptionWarningPopup.header')}!`}
          content={t(`optimizer.configuration.consumptionWarningPopup.${dialog}`)}
          primaryBtnText={t('optimizer.configuration.consumptionWarningPopup.iUnderstandAndAgree')}
          secondaryBtnText={tc('buttons.cancel')}
          primaryBtnAction={confirmChoiceMode}
          secondaryBtnAction={handleCloseDialog}
          direction="column-reverse"
          timer
        />
      </div>
    </>
  );
};
