import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconConnectionError, IconRecovery } from '../../../components';
import { IconDevice } from '../../../components/icons/device';
import { DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useBackdropContext, useChannelsState, useDevicesAndChannels } from '../../../hooks';
import { DeviceInterface } from '../../../types';
import { toastError } from '../../../utils/toast';

interface Props {
  device: DeviceInterface;
}

const DeviceInfo: React.FC<Props> = ({ device }) => {
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const { backdropOpen } = useBackdropContext();
  const { deviceChannels } = useDevicesAndChannels({ deviceId: device?.id });
  const { channelState } = useChannelsState();

  const isDeviceDisconnected = useMemo(() => {
    if (!deviceChannels.length) return false;

    return deviceChannels.every(
      (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Disconnected,
    );
  }, [deviceChannels, channelState]);

  const isDeviceInRecoveryState = useMemo(() => {
    if (!deviceChannels.length) return false;

    return deviceChannels.every((channel) => {
      return channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery;
    });
  }, [deviceChannels, channelState]);

  useEffect(() => {
    if (isDeviceDisconnected && !device.isUpdating && !backdropOpen) {
      toastError({ content: t('connectionError.message'), icon: <IconConnectionError iconToastError size={16} /> });
    }
  }, [isDeviceDisconnected, device.isUpdating, backdropOpen]);

  useEffect(() => {
    if (isDeviceInRecoveryState) {
      toastError({ content: t('recoveryError.message'), icon: <IconRecovery iconToastError /> });
    }
  }, [isDeviceInRecoveryState]);

  if (!device?.payload.name) return null;

  return (
    <div className="driver-settings">
      <div className="driver-settings__icon">
        <IconDevice />
        {isDeviceDisconnected ? (
          <IconConnectionError big details size={14} />
        ) : isDeviceInRecoveryState ? (
          <IconRecovery big details />
        ) : null}
      </div>
      <h4 className="driver-settings__info">
        <div className="driver-title text-ellipsis">{device.payload.name || 'N/A'}</div>
        <div className="driver-type text-ellipsis">{tc(`types.${device.type}`)}</div>
      </h4>
    </div>
  );
};

export default DeviceInfo;
