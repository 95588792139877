import { useTranslation } from 'react-i18next';
import { GroupErrorDictionary, GroupError } from '../../data-access/gql-types/graphql';
import { toastError } from '../../utils/toast';

export const useGroupErrors = () => {
  const { t: tr } = useTranslation('errors');

  const handleErrors = (errors: GroupError[]) => {
    if (errors.length > 0) {
      const errorItem = errors[0];

      switch (errorItem.error) {
        case GroupErrorDictionary.Undefined: {
          toastError({
            content: tr(`GroupErrorDictionary.${GroupErrorDictionary.Undefined}`),
          });
          break;
        }
        case GroupErrorDictionary.GroupNotFound: {
          toastError({
            content: tr(`GroupErrorDictionary.${GroupErrorDictionary.GroupNotFound}`),
          });
          break;
        }
        case GroupErrorDictionary.UserDoesNotHavePermission: {
          toastError({
            content: tr(`GroupErrorDictionary.${GroupErrorDictionary.UserDoesNotHavePermission}`),
          });
          break;
        }
        case GroupErrorDictionary.UserInstallationDoesNotExist: {
          toastError({
            content: tr(`GroupErrorDictionary.${GroupErrorDictionary.UserInstallationDoesNotExist}`),
          });
          break;
        }
        case GroupErrorDictionary.GroupNameAlreadyTaken: {
          toastError({
            content: tr(`GroupErrorDictionary.${GroupErrorDictionary.GroupNameAlreadyTaken}`),
          });
          break;
        }
        default: {
          toastError({ content: errorItem.message });
          break;
        }
      }
    }
  };

  return {
    handleErrors,
  };
};
