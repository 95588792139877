import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { IconChevron, IconStatus } from '../../icons';
import { Dialog } from '../base';
import { SwitcherOptionInterface } from '../helper';
import './switcher.scss';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  options: SwitcherOptionInterface[];
  selectedOptionIndex: number;
  onChange: (selectedOptionIndex: number) => void;
  info?: string;
  children?: ReactNode;
}

export const DialogSwitcher: React.FC<Props> = ({
  show,
  setShow,
  title,
  options,
  selectedOptionIndex,
  onChange,
  children,
}) => {
  return (
    <Dialog show={show} setShow={setShow} isBottomPosition>
      <div className="dialog-switcher">
        <div className="dialog-switcher__header">
          <button onClick={() => setShow(false)} className="dialog-switcher__button-back">
            <IconChevron direction="left" noSpace />
          </button>
          <h2>{title}</h2>
        </div>
        <ul>
          {options.map((option, optionIndex) => {
            const isSelected = optionIndex === selectedOptionIndex;
            return (
              <li
                key={option.label}
                className={classNames('dialog-switcher__option-item', {
                  'dialog-switcher__option-item--selected': isSelected,
                })}
                onClick={() => onChange(optionIndex)}
              >
                <span className="dialog-switcher__option-item-label">
                  {option.icon && <div className="dialog-switcher__option-item-label-icon">{option.icon}</div>}
                  {option.label}
                </span>
                {isSelected && <IconStatus isOn />}
              </li>
            );
          })}
        </ul>
        <span className="dialog-switcher__children">{children}</span>
      </div>
    </Dialog>
  );
};
