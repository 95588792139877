import { useEffect, useState } from 'react';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { uniqBy } from 'lodash';
import { GroupChannelsSortable } from '../../group-sort-channels/types';

interface HookParams {
  group: IChannelsGroup;
  groupChannels?: IDeviceChannel[];
  isDraggable?: boolean;
}

export const useSortedChannels = ({ groupChannels, isDraggable, group }: HookParams) => {
  const [sortableItems, setSortableItems] = useState<GroupChannelsSortable[]>([]);

  useEffect(() => {
    if (isDraggable && groupChannels) {
      const items: GroupChannelsSortable[] = groupChannels.map((channel: IDeviceChannel) => {
        if (channel.GetDevice().ShouldChannelsBeGrouped) {
          return {
            id: channel.GetDevice().Guid,
            name: channel.GetDevice().Name,
            channel,
            group,
            groupChannels: channel
              .GetDevice()
              .Channels.filter((ch) => groupChannels.find((c) => c.ChannelId === ch.ChannelId)),
          };
        } else {
          return { id: channel.ChannelId, name: channel.Name, channel, group };
        }
      });

      setSortableItems(uniqBy(items, 'id'));
    }
  }, [groupChannels, isDraggable, group]);

  return {
    sortableItems,
    setSortableItems,
  };
};
