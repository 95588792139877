import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditIconControl, Header, IconEdit, NavHeader, Page, SubmitButton } from '../../../../components';
import { RgbSceneIcon } from '../../../../components/rgb-scene';
import { useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';

export const RgbSceneFormSettings: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { icon, name, setSelectedView } = useRgbSceneFormContext();

  return (
    <Page
      header={
        <>
          <NavHeader onClick={() => setSelectedView(RgbSceneFormView.LIST)} />
          <Header title={t('rgbScenes.settings')} isUnderline />
        </>
      }
    >
      <div className="input m-t-8 m-b-24">
        <span className="input__label">{t('rgbScenes.sceneName')}</span>
        <div className="input__wrapper">
          <button type="button" className="input__input" onClick={() => setSelectedView(RgbSceneFormView.CHANGE_NAME)}>
            {name}
            <div className="end_icon">
              <IconEdit />
            </div>
          </button>
        </div>
      </div>

      <EditIconControl
        colorAccent={false}
        subCaption={tc('editIcon.change')}
        setOpen={() => setSelectedView(RgbSceneFormView.CHANGE_ICON)}
      >
        <RgbSceneIcon iconName={icon} />
      </EditIconControl>

      <SubmitButton type="button" onClick={() => setSelectedView(RgbSceneFormView.LIST)} />
    </Page>
  );
};
