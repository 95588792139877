import React, { useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import googleMapReact from 'google-map-react';
import { environment } from '../../../../environment';
import { useLocationContext } from '../location/context/location.context';
import Marker from './components/marker';

const MapComponent: React.FC = () => {
  const mapRef = useRef<googleMapReact>(null);
  const { coords, setCoords, currentLocation, center, zoom, setCenter, setZoom } = useLocationContext();

  const handleClick = (value: GoogleMapReact.ClickEventValue) => {
    setCoords({ latitude: value.lat, longitude: value.lng });
    setCenter({ lat: value.lat, lng: value.lng });
    setZoom(16);
  };

  const handleChange = (value: GoogleMapReact.ChangeEventValue) => {
    setZoom(value.zoom);
  };

  return (
    <div style={{ height: 'calc(100vh - 126px)', width: '100%' }}>
      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: environment.MAPS_API_KEY }}
        zoom={zoom}
        center={center}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}
        onClick={handleClick}
        onChange={handleChange}
      >
        {currentLocation.latitude && currentLocation.longitude && (
          <Marker lat={currentLocation.latitude} lng={currentLocation.longitude} type="current_location" />
        )}
        {coords.latitude && coords.longitude && <Marker lat={coords.latitude} lng={coords.longitude} />}
      </GoogleMapReact>
    </div>
  );
};

export default MapComponent;
