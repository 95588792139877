import React, { useMemo, useState } from 'react';
import * as uuid from 'uuid';

interface IconCoverProps {
  position?: number; // In percents from 0 to 100
}

export const IconWoodenBlind: React.FC<IconCoverProps> = ({ position = 0 }) => {
  const [svgClipPathId] = useState<string>(`cover-clip-path-${uuid.v4()}`);
  const svgClipPathHeight = useMemo(() => position * 0.01 * 11, [position]);

  return (
    <svg
      className="icon-cover icon-wooden-blind"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <clipPath id={svgClipPathId}>
        <rect x="0" y="5" width="24" height={svgClipPathHeight + 1.5} />
      </clipPath>

      <rect x="4" y="3" width="16" height="18" stroke="#fff" vectorEffect="non-scaling-stroke" />
      <path d="M2 3L22 3" stroke="#fff" vectorEffect="non-scaling-stroke" />
      <path d="M2 21L22 21" stroke="#fff" vectorEffect="non-scaling-stroke" />

      <g clipPath={`url(#${svgClipPathId})`}>
        <path d="M4 6H20" stroke="#fff" vectorEffect="non-scaling-stroke" />
        <path d="M4 10H20" stroke="#fff" vectorEffect="non-scaling-stroke" />
        <path d="M4 14H20" stroke="#fff" vectorEffect="non-scaling-stroke" />
        <path d="M4 18H20" stroke="#fff" vectorEffect="non-scaling-stroke" />
      </g>

      <line
        x1="4"
        y1={svgClipPathHeight + 6.5}
        x2="20"
        y2={svgClipPathHeight + 6.5}
        stroke="#fff"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
