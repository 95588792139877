import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelBox } from '../../../../components';
import { GateModeInternal, VersionedFeatureType } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../hooks';
import useVersionMap from '../../../../hooks/api/version-map';
import { useChannelDetailsContext } from '../../../../hooks/channel-details';
import { useGateSupportedFeatures } from '../../../channel-details/gate/hooks/use-gate-supported';
import { Calibration } from '../../../device-settings/device-settings-form/sections';
import { InfoPageType } from '../../types';
import AlertConfig from '../components/alert-config';
import Info from '../components/info';
import Pin from '../components/pin';
import SignalTime from '../components/signal-time';
import { useGateConfiguration } from '../hooks/use-gate-configuration';

const pinVisible = false;

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
}

const GateConfiguration: React.FC<ComponentProps> = ({ openInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useChannelDetailsContext();
  const { device } = useDevicesAndChannels({ deviceId: channel?.deviceId });
  const { calibrationSupported } = useGateSupportedFeatures(channel);
  const { checkSupport, versionMapData } = useVersionMap();
  const { handleSaveSignalTime, gateMode, handleSaveAlert } = useGateConfiguration();

  const alertSupported = useMemo(() => {
    return checkSupport({
      feature: VersionedFeatureType.GateAlerts,
      supportKey: 'Gate',
      currentVersion: device?.payload.currentFirmwareVersion,
    });
  }, [versionMapData, device?.payload.currentFirmwareVersion]);

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => openInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <hr />

      {pinVisible && gateMode === GateModeInternal.StepByStep && (
        <>
          <Pin />
          <hr />
        </>
      )}
      {gateMode === GateModeInternal.StepByStep && (
        <>
          <SignalTime channel={channel} handleSave={handleSaveSignalTime} />
          <hr />
        </>
      )}
      {alertSupported && <AlertConfig channel={channel} handleSave={handleSaveAlert} />}

      {calibrationSupported && (
        <>
          <hr />
          <Info title={t('calibration')} onClick={() => openInfoPage(InfoPageType.CALIBRATION)} />
          <Calibration channel={channel} />
        </>
      )}
    </>
  );
};

export default GateConfiguration;
