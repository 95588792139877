import { IpAddress } from '../pages/network/types';

export const isIpValid = (ip: IpAddress) => {
  if (!ip.first || !ip.second || !ip.third || !ip.fourth) return false;
  if (parseInt(ip.first) < 0 || parseInt(ip.first) > 255) return false;
  if (parseInt(ip.second) < 0 || parseInt(ip.second) > 255) return false;
  if (parseInt(ip.third) < 0 || parseInt(ip.third) > 255) return false;
  if (parseInt(ip.fourth) < 0 || parseInt(ip.fourth) > 255) return false;
  return true;
};
