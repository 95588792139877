import { useSwitchSetOff, useSwitchSetOn } from '../../api/modules/switch/switch.hooks';
import { SwitchStateInternal } from '../../data-access/gql-types/graphql';
import { ChannelSwitchStateInterface } from '../../types';
import { useChannelsState } from '../channels-state';

interface SwitchHook {
  channelId: string;
  deviceId: string;
}

interface UseSwitchActionInterface {
  handleControlOn: (status: boolean) => void;
  isOn: boolean;
}

export const useSwitch = ({ channelId, deviceId }: SwitchHook): UseSwitchActionInterface => {
  const { channelState } = useChannelsState();
  const switchOn = useSwitchSetOn();
  const switchOff = useSwitchSetOff();

  const isOn = (channelState[channelId] as ChannelSwitchStateInterface)?.lavvaState === SwitchStateInternal.On;

  const handleControlOn = (status: boolean) => {
    if (!status) {
      switchOff.mutate({ deviceId, channelId });
    } else {
      switchOn.mutate({ deviceId, channelId });
    }
  };

  return { handleControlOn, isOn };
};
