import React from 'react';
import './index.scss';

const DotsLoader: React.FC = () => (
  <div className="dots-loader">
    <div className="dot-typing" />
  </div>
);

export default DotsLoader;
