import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { orderBy, sumBy } from 'lodash';
import { IconEco } from '../../../../../../components';
import { IconPlug } from '../../../../../../components/icons-group';
import { OptimizerVectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { OptimizerCurrentDataMap } from '../../types';
import './index.scss';

// const phasesValues = [
//   { index: 1, value: 0, type: 'ACTIVE_POWER' },
//   { index: 2, value: 0, type: 'ACTIVE_POWER' },
//   { index: 3, value: 0, type: 'ACTIVE_POWER' },
// ];
// const phasesLoad = [
//   { index: 1, value: 30, type: 'SCR_FILLING' },
//   { index: 2, value: 30, type: 'SCR_FILLING' },
//   { index: 3, value: 30, type: 'SCR_FILLING' },
// ];
// const phaseRelays = [
//   { index: 1, value: 1, type: 'RELAY_STATE' },
//   { index: 2, value: 1, type: 'RELAY_STATE' },
//   { index: 3, value: 1, type: 'RELAY_STATE' },
// ];

// const loadActive = !!phasesLoad.find((x) => x.value > 0);
// const sumPower = 0;

interface ComponentProps {
  optimizerData: Record<OptimizerVectorParameterTypeInternal, OptimizerCurrentDataMap[]> | undefined;
  temperature: number | undefined;
}

export const Diagram: FC<ComponentProps> = ({ optimizerData, temperature }) => {
  const sumPower = useMemo(() => (optimizerData ? sumBy(optimizerData.ACTIVE_POWER, 'value') : 0), [optimizerData]);
  const phasesValues = optimizerData?.ACTIVE_POWER.length ? orderBy(optimizerData?.ACTIVE_POWER, 'index') : null;
  const phasesLoad = optimizerData?.SCR_FILLING.length ? orderBy(optimizerData?.SCR_FILLING, 'index') : null;
  const phaseRelays = optimizerData?.RELAY_STATE.length ? orderBy(optimizerData?.RELAY_STATE, 'index') : null;
  const phase1 = phasesValues?.length ? phasesValues[0] : undefined;
  const phase2 = phasesValues?.length ? phasesValues[1] : undefined;
  const phase3 = phasesValues?.length ? phasesValues[2] : undefined;
  const phase1Load = phasesLoad?.length ? phasesLoad[0] : undefined;
  const phase2Load = phasesLoad?.length ? phasesLoad[1] : undefined;
  const phase3Load = phasesLoad?.length ? phasesLoad[2] : undefined;
  const phase1Relays = phaseRelays?.length ? phaseRelays[0] : undefined;
  const phase2Relays = phaseRelays?.length ? phaseRelays[1] : undefined;
  const phase3Relays = phaseRelays?.length ? phaseRelays[2] : undefined;

  const plugOn = useMemo(() => !!optimizerData?.RELAY_STATE.find((x) => x.value), [optimizerData]);
  const loadActive = useMemo(() => !!optimizerData?.SCR_FILLING.find((x) => x.value > 0), [optimizerData, sumPower]);

  return (
    <div className="eco-diagram-container">
      <IconPlug isOn={plugOn} />
      {sumPower < 0 && <IconEco size={18} />}
      <svg
        width="344"
        height="218"
        viewBox="0 0 344 218"
        className={classNames('eco-diagram', {
          green: sumPower < 0 || loadActive,
          red: sumPower > 0,
          'load-active': loadActive,
        })}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M226.181 106V98.8H227.071V105.155H230.411V106H226.181ZM232.512 106V99.785L231.157 100.605V99.61L232.512 98.8H233.417V106H232.512Z"
          fill="#55555C"
        />
        <path
          d="M225.321 138V130.8H226.211V137.155H229.551V138H225.321ZM230.197 137.995L230.202 137.195L233.397 134.32C233.664 134.08 233.842 133.853 233.932 133.64C234.026 133.423 234.072 133.202 234.072 132.975C234.072 132.698 234.009 132.448 233.882 132.225C233.756 132.002 233.582 131.825 233.362 131.695C233.146 131.565 232.899 131.5 232.622 131.5C232.336 131.5 232.081 131.568 231.857 131.705C231.634 131.838 231.457 132.017 231.327 132.24C231.201 132.463 231.139 132.707 231.142 132.97H230.232C230.232 132.517 230.337 132.117 230.547 131.77C230.757 131.42 231.042 131.147 231.402 130.95C231.766 130.75 232.177 130.65 232.637 130.65C233.084 130.65 233.484 130.753 233.837 130.96C234.191 131.163 234.469 131.442 234.672 131.795C234.879 132.145 234.982 132.542 234.982 132.985C234.982 133.298 234.942 133.57 234.862 133.8C234.786 134.03 234.666 134.243 234.502 134.44C234.339 134.633 234.134 134.84 233.887 135.06L231.232 137.445L231.117 137.145H234.982V137.995H230.197Z"
          fill="#55555C"
        />
        <path
          d="M225.4 170V162.8H226.29V169.155H229.63V170H225.4ZM232.45 170.14C232.094 170.14 231.762 170.075 231.455 169.945C231.149 169.812 230.884 169.625 230.66 169.385C230.44 169.142 230.279 168.853 230.175 168.52L231.03 168.275C231.14 168.608 231.324 168.862 231.58 169.035C231.837 169.208 232.125 169.293 232.445 169.29C232.739 169.283 232.992 169.217 233.205 169.09C233.422 168.96 233.589 168.783 233.705 168.56C233.825 168.337 233.885 168.078 233.885 167.785C233.885 167.338 233.752 166.977 233.485 166.7C233.219 166.42 232.869 166.28 232.435 166.28C232.315 166.28 232.189 166.297 232.055 166.33C231.925 166.363 231.804 166.41 231.69 166.47L231.26 165.765L233.93 163.35L234.045 163.65H230.46V162.8H234.705V163.655L232.43 165.835L232.42 165.52C232.897 165.487 233.314 165.565 233.67 165.755C234.027 165.945 234.304 166.217 234.5 166.57C234.7 166.923 234.8 167.328 234.8 167.785C234.8 168.245 234.697 168.652 234.49 169.005C234.284 169.358 234.004 169.637 233.65 169.84C233.297 170.04 232.897 170.14 232.45 170.14Z"
          fill="#55555C"
        />
        <path
          d="M89 144L122 144"
          stroke="#D7D6D9"
          className={classNames('route-line', { green: phase2 && phase2.value < 0, red: phase2 && phase2.value > 0 })}
        />
        <path
          d="M122 144L207 144"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase2 && phase2.value < 0) || (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0),
            red: phase2 && phase2.value > 0,
          })}
        />
        <path
          d="M207 144L250 144"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase2 && phase2.value < 0) || (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0),
          })}
        />
        <path
          d="M89 112L135 112"
          stroke="#D7D6D9"
          className={classNames('route-line', { green: phase1 && phase1.value < 0, red: phase1 && phase1.value > 0 })}
        />
        <path
          d="M135 112L220 112"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase1 && phase1.value < 0) || (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0),
            red: phase1 && phase1.value > 0,
          })}
        />
        <path
          d="M220 112L250 112"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase1 && phase1.value < 0) || (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0),
          })}
        />
        <path
          d="M89 176L112 176"
          stroke="#D7D6D9"
          className={classNames('route-line', { green: phase3 && phase3.value < 0, red: phase3 && phase3.value > 0 })}
        />
        <path
          d="M112 176L197 176"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase3 && phase3.value < 0) || (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0),
            red: phase3 && phase3.value > 0,
          })}
        />
        <path
          d="M197 176L250 176"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase3 && phase3.value < 0) || (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0),
          })}
        />
        <path
          d="M111 176L111 56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green:
              (phase3 && phase3.value < 0 && phase3Load && phase3Load.value > 0) ||
              (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0),
          })}
        />
        <path
          d="M123 144L123 56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green:
              (phase2 && phase2.value < 0 && phase2Load && phase2Load.value > 0) ||
              (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0),
          })}
        />
        <path
          d="M135 111V56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green:
              (phase1 && phase1.value < 0 && phase1Load && phase1Load.value > 0) ||
              (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0),
          })}
        />
        <path
          d="M195 176L195 56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase3 && phase3.value < 0) || (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0),
            red: phase3 && phase3.value > 0,
          })}
        />
        <path
          d="M207 144L207 56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase2 && phase2.value < 0) || (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0),
            red: phase2 && phase2.value > 0,
          })}
        />
        <path
          d="M219 111V56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green: (phase1 && phase1.value < 0) || (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0),
            red: phase1 && phase1.value > 0,
          })}
        />
        <path
          d="M153 176L153 56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green:
              (phase3Relays?.value && phase3 && phase3.value < 0) ||
              (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0 && phase3Relays?.value),
            red: phase3Relays?.value && phase3 && phase3.value > 0,
          })}
        />
        <path
          d="M165 144L165 56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green:
              (phase2Relays?.value && phase2 && phase2.value < 0) ||
              (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0 && phase2Relays?.value),
            red: phase2Relays?.value && phase2 && phase2.value > 0,
          })}
        />
        <path
          d="M177 111V56"
          stroke="#D7D6D9"
          className={classNames('route-line', {
            green:
              (phase1Relays?.value && phase1 && phase1.value < 0) ||
              (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0 && phase1Relays?.value),
            red: phase1Relays?.value && phase1 && phase1.value > 0,
          })}
        />
        <circle
          cx="219"
          cy="112"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green: (phase1 && phase1.value < 0) || (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0),
            red: phase1 && phase1.value > 0,
          })}
        />
        <circle
          cx="177"
          cy="112"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green:
              (phase1Relays?.value && phase1 && phase1.value < 0) ||
              (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0 && phase1Relays?.value),
            red: phase1Relays?.value && phase1 && phase1.value > 0,
          })}
        />
        <circle
          cx="135"
          cy="112"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green:
              (phase1 && phase1.value < 0 && phase1Load && phase1Load.value > 0) ||
              (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0),
          })}
        />

        <circle
          cx="207"
          cy="144"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green: (phase2 && phase2.value < 0) || (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0),
            red: phase2 && phase2.value > 0,
          })}
        />
        <circle
          cx="165"
          cy="144"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green:
              (phase2Relays?.value && phase2 && phase2.value < 0) ||
              (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0 && phase2Relays?.value),
            red: phase2Relays?.value && phase2 && phase2.value > 0,
          })}
        />
        <circle
          cx="123"
          cy="144"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green:
              (phase2 && phase2.value < 0 && phase2Load && phase2Load.value > 0) ||
              (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0),
          })}
        />

        <circle
          cx="195"
          cy="176"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green: (phase3 && phase3.value < 0) || (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0),
            red: phase3 && phase3.value > 0,
          })}
        />
        <circle
          cx="153"
          cy="176"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green:
              (phase3Relays?.value && phase3 && phase3.value < 0) ||
              (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0 && phase3Relays?.value),
            red: phase3Relays?.value && phase3 && phase3.value > 0,
          })}
        />
        <circle
          cx="111"
          cy="176"
          r="2"
          fill="#D7D6D9"
          className={classNames('route-dot', {
            green:
              (phase3 && phase3.value < 0 && phase3Load && phase3Load.value > 0) ||
              (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0),
          })}
        />

        {phase1 && phase1?.value < 0 && <circle cx="0" cy="112" r="4" fill="#00B191" className="l1 line green" />}
        {(phase1?.value || (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0)) && (
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#00B191"
            className={classNames('l1 home', {
              green: phase1.value < 0 || (phase1.value === 0 && phase1Load && phase1Load.value > 0),
              red: phase1.value > 0,
            })}
          />
        )}
        {(phase1?.value && phase1Relays?.value) ||
          (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0 && phase1Relays?.value && (
            <circle
              cx="0"
              cy="0"
              r="4"
              fill="#00B191"
              className={classNames('l1 relay', {
                green:
                  phase1.value < 0 ||
                  (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0 && phase1Relays?.value),
                red: phase1.value > 0,
              })}
            />
          ))}
        {(phase1 && phase1.value < 0 && phase1Load && phase1Load.value > 0) ||
          (phase1 && phase1Load && phase1.value === 0 && phase1Load.value > 0 && (
            <circle cx="0" cy="0" r="4" fill="#00B191" className="l1 load green" />
          ))}
        {phase2 && phase2?.value < 0 && <circle cx="0" cy="144" r="4" fill="#00B191" className="l2 line green" />}
        {(phase2?.value || (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0)) && (
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#00B191"
            className={classNames('l2 home', {
              green: phase2.value < 0 || (phase2.value === 0 && phase2Load && phase2Load.value > 0),
              red: phase2.value > 0,
            })}
          />
        )}
        {(phase2?.value && phase2Relays?.value) ||
          (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0 && phase2Relays?.value && (
            <circle
              cx="0"
              cy="0"
              r="4"
              fill="#00B191"
              className={classNames('l2 relay', {
                green:
                  phase2.value < 0 ||
                  (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0 && phase2Relays?.value),
                red: phase2.value > 0,
              })}
            />
          ))}
        {(phase2 && phase2.value < 0 && phase2Load && phase2Load.value > 0) ||
          (phase2 && phase2Load && phase2.value === 0 && phase2Load.value > 0 && (
            <circle cx="0" cy="0" r="4" fill="#00B191" className="l2 load green" />
          ))}
        {phase3 && phase3?.value < 0 && <circle cx="0" cy="176" r="4" fill="#00B191" className="l3 line green" />}
        {(phase3?.value || (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0)) && (
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#00B191"
            className={classNames('l3 home', {
              green: phase3.value < 0 || (phase3.value === 0 && phase3Load && phase3Load.value > 0),
              red: phase3.value > 0,
            })}
          />
        )}
        {(phase3?.value && phase3Relays?.value) ||
          (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0 && phase3Relays?.value && (
            <circle
              cx="0"
              cy="0"
              r="4"
              fill="#00B191"
              className={classNames('l3 relay', {
                green:
                  phase3.value < 0 ||
                  (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0 && phase3Relays?.value),
                red: phase3.value > 0,
              })}
            />
          ))}
        {(phase3 && phase3.value < 0 && phase3Load && phase3Load.value > 0) ||
          (phase3 && phase3Load && phase3.value === 0 && phase3Load.value > 0 && (
            <circle cx="0" cy="0" r="4" fill="#00B191" className="l3 load green" />
          ))}
        <rect x="95" width="140" height="56" rx="8" fill="#EBEDF0" className="gray-box" />
        <rect x="103" y="8" width="40" height="40" rx="8.57143" fill="white" className="border-load" />

        <g clipPath="url(#clip0_22729_60989)">
          <path
            d="M113.703 14.9219C113.703 13.7472 114.653 12.7969 115.828 12.7969"
            stroke="#55555C"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M132.828 14.9219C132.828 13.7472 131.878 12.7969 130.703 12.7969"
            stroke="#55555C"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path d="M115.828 12.7969H130.703" stroke="#55555C" strokeMiterlimit="10" strokeLinecap="round" />
          <path
            d="M132.828 38.2969C132.828 39.4715 131.878 40.4219 130.703 40.4219"
            stroke="#55555C"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M113.703 38.2969C113.703 39.4715 114.653 40.4219 115.828 40.4219"
            stroke="#55555C"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path d="M130.703 40.4219H115.828" stroke="#55555C" strokeMiterlimit="10" strokeLinecap="round" />
          <path d="M113.703 14.9219V38.2969" stroke="#55555C" strokeMiterlimit="10" strokeLinecap="round" />
          <path d="M132.828 14.9219V38.2969" stroke="#55555C" strokeMiterlimit="10" strokeLinecap="round" />
          {temperature !== undefined && (
            <text x="116" y="21" fill="#55555C" style={{ fontSize: 6 }}>
              {`${temperature.toFixed(0)} °C`}
            </text>
          )}
          <path d="M120.078 40.4219V44.6719" stroke="#55555C" strokeMiterlimit="10" strokeLinecap="round" />
          <path d="M126.453 40.4219V44.6719" stroke="#55555C" strokeMiterlimit="10" strokeLinecap="round" />
        </g>
        <path
          d="M118.548 28.0238C117.864 28.0238 117.31 27.4694 117.31 26.7857C117.31 26.102 117.864 25.5476 118.548 25.5476"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.452 28.0239C129.136 28.0239 129.69 28.5783 129.69 29.262C129.69 29.9457 129.136 30.5001 128.452 30.5001"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M118.548 32.9762C117.864 32.9762 117.31 32.4218 117.31 31.7381C117.31 31.0544 117.864 30.5 118.548 30.5"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.452 32.9761C129.136 32.9761 129.69 33.5305 129.69 34.2142C129.69 34.8979 129.136 35.4523 128.452 35.4523"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.31 36.6905C117.31 36.0068 117.864 35.4524 118.548 35.4524"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.69 24.3096C129.69 24.9933 129.136 25.5477 128.452 25.5477"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M118.548 25.5476H128.453" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M121.319 28.0239H118.548" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M118.548 30.5H120.199" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M121.318 32.9761H118.548" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M118.548 35.4524H128.453" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M123.5 33.8017C125.323 33.8017 126.801 32.3235 126.801 30.5001C126.801 28.6767 125.323 27.1985 123.5 27.1985C121.676 27.1985 120.198 28.6767 120.198 30.5001C120.198 32.3235 121.676 33.8017 123.5 33.8017Z"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125 31C125 30.1719 124.25 29.2504 123.5 28.5C122.75 29.2496 122 30.1719 122 31C122 31.8281 122.672 32.5 123.5 32.5C124.328 32.5 125 31.8281 125 31Z"
          fill="#1AB1E0"
        />
        <path d="M128.453 28.0239H125.681" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M128.453 30.5H126.802" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M128.453 32.9761H125.681" stroke="#55555C" strokeLinecap="round" strokeLinejoin="round" />
        <rect
          x="145"
          y="8"
          width="40"
          height="40"
          rx="8.57143"
          fill="white"
          className={classNames('relay-rect', { 'border-relay': plugOn })}
        />
        <rect x="149" y="12" width="32" height="32" rx="6" fill="white" className="relay-rect-content" />
        <rect x="187" y="8" width="40" height="40" rx="8.57143" fill="white" className="border-home" />
        <path
          d="M194 26.6786L206.821 16L219.641 26.6786"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M213.438 18.0536V16.8214H217.573V21.75"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M196.068 25.0737V39H200.204V31.4702H205.166V39H217.573V25.0737"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M213.547 26.1765H208.584V31.1051H213.547V26.1765Z"
          stroke="#55555C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="1"
          y="86"
          width="88"
          height="116"
          rx="8"
          fill="white"
          className={classNames('border-network', { default: loadActive })}
        />
        <rect
          x="0.75"
          y="85.75"
          width="88.5"
          height="116.5"
          rx="8.25"
          stroke="#CBCBD1"
          strokeOpacity="0.5"
          strokeWidth="0.5"
        />
        <g clipPath="url(#clip1_22729_60989)">
          <path
            d="M51.1493 143.865H39.2495L42.7286 118H47.6703L51.1493 143.865Z"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.2502 143.866L28.5483 166.304H30.9687L45.1984 150.598"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.1494 143.866L61.8513 166.304H59.4309L45.1978 150.598"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.3418 150.598H54.0575"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M24 133.71H66.3999" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M66.3994 133.71L51.1494 143.865"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.25 143.865L24 133.71"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.5942 133.71L30.2476 126.818"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M24 138.039V133.71" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M66.3999 138.039V133.71"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.2476 126.818H45.1981"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.2476 126.818V129.869"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.8052 133.71L60.1518 126.818"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.1518 126.818H45.1978"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.1523 126.818V129.869"
            stroke="#55555C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        {sumPower && (
          <text x="20" y="192" fill="#55555C">
            {`${Math.abs(sumPower / 1000).toFixed(1)} kW`}
          </text>
        )}
        {sumPower && (
          <path
            d={
              sumPower < 0
                ? 'M76.6895 185.426L73.2013 181.938L69.7132 185.426'
                : 'M76.6895 188.426L73.2013 191.938L69.7132 188.426'
            }
            stroke={sumPower < 0 ? '#00B191' : '#cc0909'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
        {sumPower && (
          <path
            d="M73.2012 182.325V191.046"
            stroke={sumPower < 0 ? '#00B191' : '#cc0909'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
        <rect x="240" y="86" width="88" height="116" rx="8" fill="white" className="border-panel" />
        <rect
          x="239.75"
          y="85.75"
          width="88.5"
          height="116.5"
          rx="8.25"
          stroke="#CBCBD1"
          strokeOpacity="0.5"
          strokeWidth="0.5"
        />
        <path
          d="M284 170H258.8C257.769 170 256.933 169.164 256.933 168.133V165.403"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M284 170H309.2C310.23 170 311.066 169.164 311.066 168.133V167.701V165.403"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M263.664 145.987L256.933 165.248H311.066L304.336 145.987H263.664Z"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M270.502 165.248L273.826 145.987"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M284 165.248V145.987" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M297.496 165.248L294.172 145.987"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M306.055 150.91H261.943"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M308.518 157.955H259.481"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M283.95 132.422C286.857 132.422 289.213 130.065 289.213 127.158C289.213 124.251 286.857 121.895 283.95 121.895C281.043 121.895 278.687 124.251 278.687 127.158C278.687 130.065 281.043 132.422 283.95 132.422Z"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M283.949 116.391V114" stroke="#55555C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M283.949 136.981V139.371"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M294.513 126.686H296.966"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M273.386 126.686H270.933"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M291.419 133.965L293.154 135.656"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M276.481 119.406L274.746 117.715"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M291.418 119.406L293.154 117.715"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M276.481 133.965L274.746 135.656"
          stroke="#55555C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
