import React from 'react';

export const IconInstallation7: React.FC = () => (
  <svg
    className="icon-installation"
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="8.41699" y="3.1665" width="23.75" height="31.6667" stroke="#15141D" />
    <path d="M3.66699 34.8335L36.917 34.8335" stroke="#15141D" />
    <path d="M31.375 9.5L24.25 9.5" stroke="#15141D" />
    <path d="M31.375 15.8335L24.25 15.8335" stroke="#15141D" />
    <path d="M31.375 22.1665H24.25" stroke="#15141D" />
    <path d="M31.375 28.5H24.25" stroke="#15141D" />
  </svg>
);
