import React from 'react';
import './index.scss';

export const IconAlarm: React.FC = () => {
  return (
    <svg
      className="icon-scenario icon-scenario--alarm"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="14.5" r="12.75" stroke="#15141D" strokeWidth="1.5" />
      <mask id="path-2-inside-1_1769_20227" fill="white">
        <path d="M29.3359 7.55045C29.6872 7.81909 30.1964 7.7542 30.4056 7.36448C30.8496 6.53728 31.0543 5.59649 30.9877 4.64926C30.9022 3.43404 30.3761 2.29183 29.508 1.43713C28.6399 0.582431 27.4897 0.0740535 26.2733 0.00747318C25.3251 -0.0444245 24.3876 0.174889 23.5674 0.631633C23.181 0.846819 23.124 1.35697 23.3981 1.70412C23.6721 2.05128 24.1738 2.10019 24.5753 1.91477C25.0753 1.68388 25.6278 1.57625 26.1857 1.60679C27.0125 1.65204 27.7942 1.99757 28.3842 2.57847C28.9742 3.15937 29.3318 3.93569 29.3899 4.76162C29.4291 5.31898 29.3301 5.87307 29.107 6.37662C28.9278 6.78101 28.9845 7.2818 29.3359 7.55045Z" />
      </mask>
      <path
        d="M29.3359 7.55045C29.6872 7.81909 30.1964 7.7542 30.4056 7.36448C30.8496 6.53728 31.0543 5.59649 30.9877 4.64926C30.9022 3.43404 30.3761 2.29183 29.508 1.43713C28.6399 0.582431 27.4897 0.0740535 26.2733 0.00747318C25.3251 -0.0444245 24.3876 0.174889 23.5674 0.631633C23.181 0.846819 23.124 1.35697 23.3981 1.70412C23.6721 2.05128 24.1738 2.10019 24.5753 1.91477C25.0753 1.68388 25.6278 1.57625 26.1857 1.60679C27.0125 1.65204 27.7942 1.99757 28.3842 2.57847C28.9742 3.15937 29.3318 3.93569 29.3899 4.76162C29.4291 5.31898 29.3301 5.87307 29.107 6.37662C28.9278 6.78101 28.9845 7.2818 29.3359 7.55045Z"
        stroke="#15141D"
        strokeWidth="3.2"
        mask="url(#path-2-inside-1_1769_20227)"
      />
      <mask id="path-3-inside-2_1769_20227" fill="white">
        <path d="M1.66345 7.55097C1.31245 7.81932 0.803806 7.75451 0.594802 7.36524C0.150565 6.53786 -0.0543137 5.59678 0.0123167 4.64926C0.0977726 3.43404 0.623944 2.29183 1.49202 1.43713C2.36009 0.582431 3.51033 0.0740535 4.72673 0.00747318C5.67517 -0.0444403 6.61295 0.175023 7.43334 0.63205C7.81932 0.847072 7.87623 1.35666 7.60245 1.70345C7.32868 2.05024 6.82761 2.09911 6.42655 1.91374C5.92597 1.68237 5.37279 1.57451 4.81418 1.60508C3.98703 1.65036 3.20486 1.99605 2.61457 2.57725C2.02428 3.15844 1.66648 3.93515 1.60838 4.7615C1.56913 5.31957 1.6684 5.87435 1.89197 6.37846C2.07109 6.78235 2.01444 7.28261 1.66345 7.55097Z" />
      </mask>
      <path
        d="M1.66345 7.55097C1.31245 7.81932 0.803806 7.75451 0.594802 7.36524C0.150565 6.53786 -0.0543137 5.59678 0.0123167 4.64926C0.0977726 3.43404 0.623944 2.29183 1.49202 1.43713C2.36009 0.582431 3.51033 0.0740535 4.72673 0.00747318C5.67517 -0.0444403 6.61295 0.175023 7.43334 0.63205C7.81932 0.847072 7.87623 1.35666 7.60245 1.70345C7.32868 2.05024 6.82761 2.09911 6.42655 1.91374C5.92597 1.68237 5.37279 1.57451 4.81418 1.60508C3.98703 1.65036 3.20486 1.99605 2.61457 2.57725C2.02428 3.15844 1.66648 3.93515 1.60838 4.7615C1.56913 5.31957 1.6684 5.87435 1.89197 6.37846C2.07109 6.78235 2.01444 7.28261 1.66345 7.55097Z"
        stroke="#15141D"
        strokeWidth="3.2"
        mask="url(#path-3-inside-2_1769_20227)"
      />
      <circle cx="16" cy="15" r="2" fill="#15141D" />
      <path d="M16 5.5V15H8" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M24 24L29 29" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7 24L2 29" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
