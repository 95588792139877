import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindActionEnum,
  LightRGBW,
  SetBlindMicroventilation,
  SetBlindPosition,
  SetFacadePosition,
  SetLightBrightness,
} from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ISceneTask, SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import {
  BlindStateTask,
  DelayTaskParams,
  DeviceTaskParams,
  DimStateTask,
  FacadeStateTask,
  LockTaskParams,
  MicroventilationStateTask,
  OnOffStateTask,
  RGBWStateTask,
  Task,
  TaskVariant,
} from '../../action-create/types';

export const mapTask = (task: ISceneTask, channels: IDeviceChannel[]) => {
  const listItem: Pick<Task, 'id' | 'taskType'> = {
    id: task.TaskId,
    taskType: task.TaskType,
  };

  switch (task.TaskType) {
    case SceneTaskType.DelayTask: {
      return {
        ...listItem,
        taskParams: {
          delay: task.Task.DelayTask.Delay.Value,
        } as DelayTaskParams,
      };
    }
    case SceneTaskType.LockExecution: {
      return {
        ...listItem,
        taskParams: {
          lockType: task.Task.LockExecution.LockType,
          resetLockScenesGuids: task.Task.LockExecution.ResetLockScenesGuids,
          lockStart: task.Task.LockExecution.LockStartHour.Value,
          lockEnd: task.Task.LockExecution.LockEndHour.Value,
        } as LockTaskParams,
      };
    }
    case SceneTaskType.DeviceTask: {
      {
        const channel = channels?.find(
          (x) => x.GetDevice().Guid === task.Task.DeviceTask?.DeviceGuid && x.Number === task.Task.DeviceTask?.Channel,
        );

        if (channel) {
          switch (task.Task?.DeviceTask?.TaskType) {
            case DeviceTaskType.TurnOn:
            case DeviceTaskType.TurnOff: {
              return {
                ...listItem,
                taskParams: {
                  channel,
                  state: {
                    isOn: task.Task.DeviceTask.TaskType === DeviceTaskType.TurnOn,
                  } as OnOffStateTask,
                  variant: TaskVariant.OnOff,
                } as DeviceTaskParams,
              } as Task;
            }
            case DeviceTaskType.SetLightBrightness: {
              return {
                ...listItem,
                taskParams: {
                  channel,
                  state: {
                    brightness: (task.Task.DeviceTask as SetLightBrightness).Brightness,
                  } as DimStateTask,
                  variant: TaskVariant.Dim,
                } as DeviceTaskParams,
              };
            }
            case DeviceTaskType.SetBlindPositionSimple:
            case DeviceTaskType.SetBlindPosition: {
              return {
                ...listItem,
                taskParams: {
                  channel,
                  state: {
                    position: (task.Task.DeviceTask as SetBlindPosition).Position,
                    action: (task.Task.DeviceTask as SetBlindPosition).Action,
                  } as BlindStateTask<BlindActionEnum>,
                  variant: TaskVariant.SetBlind,
                } as DeviceTaskParams,
              };
            }
            case DeviceTaskType.SetBlindMicroventilation: {
              return {
                ...listItem,
                taskParams: {
                  channel,
                  state: {
                    position: (task.Task.DeviceTask as SetBlindMicroventilation).Position,
                  } as MicroventilationStateTask,
                  variant: TaskVariant.SetMicrowave,
                } as DeviceTaskParams,
              };
            }
            case DeviceTaskType.LightRGBW: {
              return {
                ...listItem,
                taskParams: {
                  channel,
                  state: {
                    R: (task.Task.DeviceTask as LightRGBW).Data.R,
                    G: (task.Task.DeviceTask as LightRGBW).Data.G,
                    B: (task.Task.DeviceTask as LightRGBW).Data.B,
                    Brightness: (task.Task.DeviceTask as LightRGBW).Data.Brightness,
                  } as RGBWStateTask,
                  variant: TaskVariant.SetRGBW,
                } as DeviceTaskParams,
              };
            }
            case DeviceTaskType.SetFacadePosition: {
              return {
                ...listItem,
                taskParams: {
                  channel,
                  state: {
                    position: (task.Task.DeviceTask as SetFacadePosition).Position,
                    tilt: (task.Task.DeviceTask as SetFacadePosition).Tilt,
                    action: (task.Task.DeviceTask as SetFacadePosition).FacadeAction,
                  } as FacadeStateTask,
                  variant: TaskVariant.SetFacade,
                } as DeviceTaskParams,
              };
            }
            default: {
              return null;
            }
          }
        }

        return null;
      }
    }
    default: {
      return null;
    }
  }

  return null;
};
