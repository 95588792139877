import React, { ChangeEvent, useEffect, useState } from 'react';
import { DeviceTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceControlFeature } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { LightRGBW } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { RgbwChannelSettings } from '../../../../../../../action-create/components/channel-settings/rgbw';
import { useExalusCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, RGBWStateTask } from '../../../../types';
import { hsbToRgb, rgbToHsb } from '../../../../../../../../utils/helpers';

export const RGBWSettings: React.FC = () => {
  const { channels } = useExalusCreateActionContext();
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();
  const [sliderColor, setSliderColor] = useState('');
  const [activeColor, setActiveColor] = useState('');
  const [rgbValue, setRgbValue] = useState('');
  const [brightness, setBrightness] = useState(100);
  const [wValue, setWValue] = useState(0);
  const [hue, setHue] = useState(0);
  const [saturation, setSaturation] = useState(0);

  useEffect(() => {
    const R = 49;
    const G = 255;
    const B = 67;
    const hsb = rgbToHsb([R.toString(), G.toString(), B.toString()]);
    setRgbValue(`${R.toString()},${G.toString()},${B.toString()}`);
    setSliderColor(hsbToRgb([hsb[0], hsb[1] / 100, 1]).join(','));
    setActiveColor(hsbToRgb([hsb[0], hsb[1] / 100, 100 / 100]).join(','));
  }, []);

  useEffect(() => {
    if (task) {
      const params = (task.taskParams as DeviceTaskParams).state as RGBWStateTask;
      const hsb = rgbToHsb([params.R.toString(), params.G.toString(), params.B.toString()]);
      setRgbValue(`${params.R.toString()},${params.G.toString()},${params.B.toString()}`);
      setSliderColor(hsbToRgb([hsb[0], hsb[1] / 100, 1]).join(','));
      setActiveColor(hsbToRgb([hsb[0], hsb[1] / 100, 100 / 100]).join(','));
      setBrightness(params.Brightness);
      setWValue(params.W || 0);
    }
  }, [task]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBrightness(Number(event.target.value));
  };

  const handleChangeW = (event: ChangeEvent<HTMLInputElement>) => {
    setWValue(Number(event.target.value));
  };

  const handleColorPicked = (hue, saturation) => {
    const brightnessVal = brightness || 100;
    const rgb = hsbToRgb([hue, saturation, 1]).join(',');

    setRgbValue(rgb);
    setBrightness(brightnessVal);
    setActiveColor(rgb);
    setSliderColor(hsbToRgb([hue, saturation, 1]).join(','));
    setHue(hue);
    setSaturation(saturation);
  };

  const handleBrightness = (event: React.PointerEvent<HTMLInputElement>) => {
    const brightness = Number((event.target as HTMLInputElement).value);
    setBrightness(brightness);

    const values = rgbValue.split(',');
    if (values.length) {
      const hsb = rgbToHsb([values[0].toString(), values[0].toString(), values[0].toString()]);
      const h = hue ? hue : hsb[0];
      const s = saturation ? saturation : hsb[1] / 100;
      setActiveColor(hsbToRgb([h, s, brightness / 100]).join(','));
    }
  };

  const handleWValue = (event: React.PointerEvent<HTMLInputElement>) => {
    const value = Number((event.target as HTMLInputElement).value);
    setWValue(value);
  };

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task = new LightRGBW();
      task.DeviceGuid = channels[i].GetDevice().Guid;
      task.Channel = channels[i].Number;
      task.TaskType = DeviceTaskType.LightRGBW;
      task.FeatureType = DeviceControlFeature.LightRGBW;
      const values = rgbValue.split(',');
      const R = parseInt(values[0]);
      const G = parseInt(values[1]);
      const B = parseInt(values[2]);
      const W = wValue;
      task.Data.R = R;
      task.Data.G = G;
      task.Data.B = B;

      if (W) task.Data.W = W;
      task.Data.Brightness = brightness;

      finalizeDeviceTaskSubmit(
        task,
        channels[i],
        { R, G, B, W, Brightness: brightness } as RGBWStateTask,
        i === channels.length - 1,
      );
    }
  };

  return (
    <RgbwChannelSettings
      rgbValue={rgbValue}
      activeColor={activeColor}
      sliderColor={sliderColor}
      handleColorPicked={handleColorPicked}
      handleSubmit={handleSubmit}
      wValue={wValue}
      handleChangeW={handleChangeW}
      handleWValue={handleWValue}
      handleChangeBrightness={handleChange}
      handleBrightness={handleBrightness}
    />
  );
};
