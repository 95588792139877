import { IconSun, IconBulb, IconRGBCircles } from '../../../../../../../components/icons';
import { IconPlug } from '../../../../../../../components/icons-group';
import { ChannelIconTypeEnum, AvailableIcon } from '../../../../../../../types';

export const rgbIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: '',
    component: <IconBulb isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_1',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_SUN,
    component: <IconSun isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_0',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_RGB_CIRCLES,
    component: <IconRGBCircles isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_13',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_PLUG,
    component: <IconPlug isOn={isOn !== undefined ? isOn : false} />,
    oldIconName: 'DEVICE_ICON_PLUG',
  },
];
