import { format } from 'date-fns';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';
import { pl, enGB } from 'date-fns/locale';
import { AvailableLanguage } from '../../types';

export const convertDateToString = (date: number, dateFormat: string): string =>
  format(date, dateFormat, {
    locale: pl,
  });

export const convertDateToFormat = (date: Date, dateFormat: string, language: AvailableLanguage): string =>
  format(date, dateFormat, {
    locale: language === AvailableLanguage.EN ? enGB : pl,
  });

export const getFormatInTimeZone = (date: Date, timezone: string, dateFormat: string): string =>
  formatTZ(utcToZonedTime(date, timezone), dateFormat, {
    timeZone: timezone,
    locale: pl,
  });

export const convertSecondsToMinutes = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return minutes > 0 ? `${minutes}min ${seconds.toString().padStart(2, '0')}s` : `${seconds}s`;
};
