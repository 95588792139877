import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceTask } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { TurnOff, TurnOn } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useNative } from '../../../../../hooks';
import { useExalusServicesContext } from '../../../context/services';
import { CentralActionSwitchControlHook } from '../types';

export const useCentralActionSwitchControl = (channels: IDeviceChannel[]): CentralActionSwitchControlHook => {
  const { devicesApi } = useExalusServicesContext();
  const { fireVibration } = useNative();

  const turnOn = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const turnOn = new TurnOn();
      turnOn.Channel = channel.Number;

      return [channel.GetDevice(), turnOn];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const turnOff = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const turnOff = new TurnOff();
      turnOff.Channel = channel.Number;

      return [channel.GetDevice(), turnOff];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  return {
    turnOn,
    turnOff,
  };
};
