import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGptChatMessage } from 'lavva.exalushome/build/js/Services/Chat/ChatImplementations/GptChat/IGptChat';
import { ChatType } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { cloneDeep } from 'lodash';
import * as uuid from 'uuid';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusGPTChatMessages } from '../../../hooks/chat.hooks';
import { ChatMessage } from '../types';
import { useChat } from './use-chat';

export const useGPTChat = () => {
  const { t } = useTranslation('chat');
  const [gptChat, setGPTChat] = useState<ChatMessage<IGptChatMessage>[]>([]);
  const { data } = useExalusGPTChatMessages();
  const { chatApi } = useExalusServicesContext();
  const { messageHandler, sendMessage } = useChat<IGptChatMessage>(gptChat);

  useEffect(() => {
    const chat = chatApi.GetChatImplementation(ChatType.GptChat);

    chat.SubscribeToMessages<IGptChatMessage>((message) => messageHandler<IGptChatMessage>(message, setGPTChat));
    return () => {
      chat.UnsubscribeFromMessages<IGptChatMessage>((message) => messageHandler<IGptChatMessage>(message, setGPTChat));
    };
  }, []);

  const handleSendtoGPT = (message: string, setMessage: Dispatch<SetStateAction<string>>, chatType: ChatType) => {
    sendMessage(message, setMessage, chatType);

    setGPTChat((chat) => {
      const chatMessagesTemp = cloneDeep(chat);
      const index = chatMessagesTemp.findIndex((x) => x.dateGroup === t('today'));

      const newMessage = {
        Guid: uuid.v4(),
        SenderName: '',
        MessageText: message,
        DateTime: new Date().toString(),
      } as IGptChatMessage;

      const dotsMessage = {
        Guid: uuid.v4(),
        SenderName: '',
        MessageText: 'DOTS',
        DateTime: new Date().toString(),
      } as IGptChatMessage;

      if (index !== -1) {
        chatMessagesTemp[index].messages.push(newMessage);
        chatMessagesTemp[index].messages.push(dotsMessage);
      } else {
        chatMessagesTemp.push({ dateGroup: t('today'), messages: [newMessage, dotsMessage] });
      }
      return chatMessagesTemp;
    });
  };

  return {
    gptChat,
    gptAvailable: !!data?.gptAvailable,
    sendGPTMessage: handleSendtoGPT,
  };
};
