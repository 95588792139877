import { gql } from '@apollo/client';

export const USER_SHARING_INFO = gql`
  query UserSharingInfo($installationId: UUID!, $userId: UUID!) {
    sharingInfo(installationId: $installationId, userId: $userId) {
      createdAt
      sharedToUserId
      sharingUserId
      sharedToUser {
        id
        firstName
        email
        lastActivity
        profileImage {
          imageUrl
        }
      }
      sharedToUser {
        id
        firstName
        email
        lastActivity
        profileImage {
          imageUrl
        }
      }
      sharingUser {
        id
        firstName
        email
        profileImage {
          imageUrl
        }
      }
      groups {
        id
        sharedFrom
        sharedUntil
      }
      channels {
        id
        grantedUses
        sharedFrom
        sharedUntil
      }
      installation {
        id
        accessType
        wholeInstallationShare
        sharedFrom
        sharedUntil      
      }
    }
  }
`;

export const SHARING_INFOS_BY_INSTALLATION = gql`
  query SharingInfosByInstallation($installationId: UUID!) {
    sharingInfosByInstallation(installationId: $installationId) {
      ownerInfo {
        ownerId
        owner {
          id
          firstName
          email
          profileImage {
            imageUrl
          }
        }
      }
      shareInfos {
        sharedToUserId
        sharedToUser {
          id
          firstName
          email
          lastActivity
          profileImage {
            imageUrl
          }
        }
        groups {
          id
          sharedFrom
          sharedUntil
        }
        channels {
          id
          grantedUses
          sharedFrom
          sharedUntil
        }
        installation {
          id
          accessType
          wholeInstallationShare
          sharedFrom
          sharedUntil          
        }
      }
    }
  }
`;
