import { ShareInfo } from '../../../data-access/gql-types/graphql';
import { SharedItemType } from '../../../types';

export const prepareShareDateTime = (date: Date, time: string): string => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    parseInt(time.split(':')[0]),
    parseInt(time.split(':')[1]),
  ).toISOString();
};

export const getSharedItemType = (shareInfo: ShareInfo) => {
  if (shareInfo.installation.wholeInstallationShare) return SharedItemType.Installation;
  if (shareInfo.channels.length > 0) return SharedItemType.Channel;
  if (shareInfo.groups.length > 0) return SharedItemType.Group;
  return SharedItemType.Undefined;
};
