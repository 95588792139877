import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconInitiate2, InitiateButton } from '../../../../../../../../../components';
import { useGateControl } from '../../../../../hooks/use-gate-control';

interface ComponentProps {
  channel: IDeviceChannel;
}

const GateCommonControls: React.FC<ComponentProps> = ({ channel }) => {
  const { handlePositionPulse } = useGateControl(channel);

  return (
    <InitiateButton isOn={false} onClickControl={handlePositionPulse} isSmall isChannelItem checkStatus>
      <IconInitiate2 />
    </InitiateButton>
  );
};

export default GateCommonControls;
