import { gql } from '@apollo/client';

export const INSTALLATION_GET_SETTINGS = gql`
  query InstallationGetSettings($id: UUID!) {
    allUserInstallations(where: { installationId: { eq: $id } }) {
      installationId
      name
      hexColor
      iconName
      isSelected
      accessType
      integrationType
      payload {
        ... on InstallationModelResponse {
          timeZone
          installationId
          coordinates {
            latitude
            longitude
          }
        }
      }
      externalPayload {
        ... on ExalusInstallationPayload {
          installationId
          pin
          serialNumber
        }
      }
    }
  }
`;

export const INSTALLATION_GET_ALL = gql`
  query InstallationGetAll {
    allUserInstallations(order: { order: ASC }) {
      installationId
      name
      hexColor
      iconName
      isSelected
      accessType
      order
      integrationType
      externalPayload {
        ... on ExalusInstallationPayload {
          installationId
          pin
          serialNumber
        }
      }
    }
  }
`;

export const CURRENT_INSTALLATION = gql`
  query CurrentInstallation {
    currentInstallation {
      installationId
      brokerUrl
      payload {
        ... on InstallationModelResponse {
          timeZone
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
