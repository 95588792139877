import React from 'react';

export const IconPuzzle: React.FC = () => {
  return (
    <svg
      className="icon-scenario"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.95749 8.5H3C1.89543 8.5 1 9.39543 1 10.5V27.5C1 28.6046 1.89543 29.5 3 29.5H7.95749C9.24159 29.5 10.076 28.1477 9.5 27C8 24 9.6 22 12 22C14.4 22 16 24.5 14.5 27C13.8399 28.1002 14.6324 29.5 15.9155 29.5H20.5C21.6046 29.5 22.5 28.6046 22.5 27.5V22.9155C22.5 21.6324 23.8998 20.8399 25 21.5C27.5 23 30 21.4 30 19C30 16.6 28 15 25 16.5C23.8523 17.076 22.5 16.2416 22.5 14.9575V10.5C22.5 9.39543 21.6046 8.5 20.5 8.5H15.9155C14.6324 8.5 13.8399 7.10024 14.5 6C16 3.5 14.4 1 12 1C9.6 1 8 3 9.5 6C10.076 7.14768 9.24159 8.5 7.95749 8.5Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
