import React from 'react';
import './index.scss';

export const IconMissing: React.FC = () => (
  <svg
    className="icon-missing"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
  >
    <circle cx="14.0001" cy="14.0001" r="11.4722" stroke="#FF4016" />
    <path d="M14 15.6668L14 7.3335" stroke="#FF4016" />
    <path
      d="M14.6109 19.5557C14.6109 19.8932 14.3373 20.1668 13.9998 20.1668C13.6623 20.1668 13.3887 19.8932 13.3887 19.5557C13.3887 19.2182 13.6623 18.9446 13.9998 18.9446C14.3373 18.9446 14.6109 19.2182 14.6109 19.5557Z"
      stroke="#FF4016"
    />
  </svg>
);
